import React, { useState, useEffect } from 'react';
import { Constant, CommonService, TournamentService } from '../../../services/services';
import { useTranslation } from 'react-i18next';
import { GetTableConfigs, StatusCell, ActionButtonsCell, DateTimeCell } from '../../../components/customTable/customTable'
import LoadingContext from '../../../components/loading/loadingContext'
import EnhancedSelect from '../../../components/enhancedSelect/enhancedSelect';
import { FilterAltIcon, EyeIcon, TrashIcon, CloseIcon, EditIcon } from '../../../components/icons/icons';
import { Row, Col, Table, Button, DatePicker, Modal, Form, Drawer, Space, Input, Select, Typography, Empty, Checkbox } from 'antd';
import { useGlobalState } from '../../../utilities/globalState'
import EditTournament from './editTournament'
import DateApplication from '../dateApplication/dateApplication'
import PubSub from 'pubsub-js'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import EditMultipleTournaments from './editMultipleTournaments';

const { confirm } = Modal;
const { Option } = Select;
const { Title, Text } = Typography;
const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const defaultGridOption = {
    pageNumber: 1,
    pageSize: CommonService.getPageSize(),
    sort: "StartDate-descend"
}

const TournamentList = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const emptyTableData = {
        ObjectList: [],
        TotalItems: 0
    }
    const [gridConfigOptions, setGridConfigOptionsValue] = useState(defaultGridOption)
    const [currentPosition] = useGlobalState('currentPosition');
    const [formFilter] = Form.useForm();
    const [visibleFilter, setVisibleFilter] = useState(false);
    const [tournamentData, setTournamentData] = useState(emptyTableData)
    const [sanctioningFilter, setSanctioningFilter] = useState({ StatusIds: Constant.StatusCode.Active });
    const [selectedObject, setSelectedObject] = useState({});
    const [visibleNote, setVisibleNote] = useState(false);
    const [visibleAddEditTournament, setVisibleAddEditTournament] = useState(false);
    const [visibleDateApplication, setVisibleDateApplication] = useState(false);
    const [filterData, setFilterData] = useState({})
    const [updateTournaments, setUpdateTournaments] = useState([])
    const [visibleUpdateTournaments, setVisibleUpdateTournaments] = useState(false)

    const viewDataType = {
        Notes: 1,
        Flyer: 2,
        Rules: 3,
        EntryForm: 4
    }

    const statusCodes = [
        {
            Value: Constant.StatusCode.Active,
            Description: t("common.active")
        },
        {
            Value: Constant.StatusCode.Inactive,
            Description: t("common.inactive")
        }
    ]

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
        }, 100)

        TournamentService.getFilterDatas()
            .then(result => {
                if (result.data) {
                    setFilterData(result.data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })

        showLoading()
        Promise.all([
            TournamentService.getTournamentList(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, sanctioningFilter)
        ])
            .finally(() => dismissLoading())
            .then(response => {
                if (response[0] && response[0].data) {
                    setTournamentData(response[0].data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);
        return () => {
            TournamentService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    const viewDataTypeSelection = (dataType, record) => {
        if (dataType == viewDataType.Notes) {
            setVisibleNote(true)
            setSelectedObject(record)
        }
        else if (dataType == viewDataType.Flyer) {

        }
        else if (dataType == viewDataType.Rules) {

        }
        else if (dataType == viewDataType.EntryForm) {

        }
    }

    const SelectedTournamentChange = (value, record) => {
        if (record && tournamentData && tournamentData.ObjectList) {
            let tempList = [...tournamentData.ObjectList]
            let tournament = tempList.find(p => p.Id == record.Id)
            if (tournament) {
                tournament.IsSelected = value;
            }

            tournamentData.ObjectList = tempList

            setTournamentData({ ...tournamentData })
        }
    }

    const columns = [
        {
            key: 'TournamentId',
            width: 50,
            sorter: false,
            render: (value, record) => {
                return (
                    <Checkbox checked={record.IsSelected} onChange={e => SelectedTournamentChange(e.target.checked, record)} />
                )
            }
        },
        {
            title: t('common.action'),
            key: 'Id',
            render: (value, record) => {
                return <ActionButtonsCell data={record} btnList={[
                    {
                        Description: t("common.edit"),
                        Icon: <EditIcon />,
                        ColorClass: Constant.ColorClass.LightBlue,
                        handleAction: editTournamentModal,
                        Hide: record.DatesApprovedId != Constant.TournamentDatesApprovedCode.DatesApproved
                    }
                ]} />
            },
            align: 'center',
            width: 70,
        },
        {
            title: t('tournament.club'),
            dataIndex: 'EntityAcronym',
            key: 'EntityAcronym',
            sorter: true,
            width: 90,
            hidden: currentPosition ? currentPosition.PositionTypeId != Constant.PositionType.StateExcutive : false
        },
        {
            title: t('tournament.zone'),
            dataIndex: 'StateZoneName',
            key: 'StateZoneName',
            sorter: true,
            width: 90,
            hidden: currentPosition ? currentPosition.PositionTypeId != Constant.PositionType.StateExcutive : false
        },
        {
            title: t('sanctioning_applications.title'),
            dataIndex: 'Title',
            key: 'Title',
            sorter: true,
            width: 200
        },
        {
            title: t('sanctioning_applications.start_date'),
            dataIndex: 'StartDate',
            key: 'StartDate',
            sorter: true,
            width: 110,
            defaultSortOrder: 'descend',
            render: (value, record) => (
                <DateTimeCell value={record.StartDate} />
            )
        },
        {
            title: t('sanctioning_applications.finish_date'),
            dataIndex: 'FinishDate',
            key: 'FinishDate',
            sorter: true,
            width: 110,
            render: (value, record) => (
                <DateTimeCell value={record.FinishDate} />
            )
        },
        {
            title: t('sanctioning_applications.approval_status'),
            dataIndex: 'DatesApprovalStatus',
            key: 'DatesApprovalStatus',
            sorter: true,
            width: 180,
            render: (value, record) => (
                <p dangerouslySetInnerHTML={{ __html: record.DatesApprovalStatus }}>{ }</p>
            )
        },
        {
            title: t('sanctioning_applications.notes'),
            key: 'Id',
            render: (value, record) => {
                return <ActionButtonsCell data={record} btnList={[
                    {
                        Description: t("common.view"),
                        Icon: <EyeIcon />,
                        ColorClass: Constant.ColorClass.LightBlue,
                        Hide: false,
                        NoMargin: true,
                        handleAction: () => viewDataTypeSelection(viewDataType.Notes, record)
                    }
                ]} />
            },
            align: 'center',
            width: 70,
        },
        {
            title: t('sanctioning_applications.flyer'),
            key: 'Id',
            render: (value, record) => {
                return <ActionButtonsCell data={record} btnList={[
                    {
                        Description: t("common.view"),
                        Icon: <EyeIcon />,
                        ColorClass: Constant.ColorClass.LightBlue,
                        Hide: !record.FlyerUrl,
                        NoMargin: true,
                        Type: Constant.ButtonType.Link,
                        Link: record.FlyerUrl
                    }
                ]} />
            },
            align: 'center',
            width: 60,
        },
        {
            title: t('sanctioning_applications.rules'),
            key: 'Id',
            render: (value, record) => {
                return <ActionButtonsCell data={record} btnList={[
                    {
                        Description: t("common.view"),
                        Icon: <EyeIcon />,
                        ColorClass: Constant.ColorClass.LightBlue,
                        Hide: !record.RulesUrl,
                        NoMargin: true,
                        Type: Constant.ButtonType.Link,
                        Link: record.RulesUrl
                    }
                ]} />
            },
            align: 'center',
            width: 70,
        },
        {
            title: t('sanctioning_applications.entry_form'),
            key: 'Id',
            render: (value, record) => {
                return <ActionButtonsCell data={record} btnList={[
                    {
                        Description: t("common.view"),
                        Icon: <EyeIcon />,
                        ColorClass: Constant.ColorClass.LightBlue,
                        Hide: !record.EntryFormUrl,
                        NoMargin: true,
                        Type: Constant.ButtonType.Link,
                        Link: record.EntryFormUrl
                    }
                ]} />
            },
            align: 'center',
            width: 70,
        },
        {
            title: t('common.status'),
            dataIndex: 'StatusId',
            key: 'StatusId',
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.StatusId)}
                    description={CommonService.getStatusDescription(record.StatusId)} />
            ),
            sorter: true,
            align: 'center',
            width: 100
        }
    ]

    const showFilterLayout = () => {
        setVisibleFilter(true)
    }

    const closeFilterLayout = () => {
        formFilter.setFieldsValue(sanctioningFilter);
        setVisibleFilter(false);
    }

    const resetFilter = () => {
        formFilter.resetFields();
        setSanctioningFilter({ StatusIds: Constant.StatusCode.Active, IsApplyForTournamentSanctioning: true });
    }

    const applyFilter = () => {
        setVisibleFilter(false);
        let filter = formFilter.getFieldsValue();
        setSanctioningFilter(filter)
        setGridConfigOptionsValue({
            ...gridConfigOptions,
            pageNumber: 1
        })
        getTournamentData(1, gridConfigOptions.pageSize, filter)
    }

    const onTableChange = (pagination, filters, sorter, extra) => {
        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = defaultGridOption.sort;
        }

        getTournamentData(pagination.current, pagination.pageSize, sanctioningFilter, sort)
        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        })
    }

    const getTournamentData = (pageNumber, pageSize, filter, sort) => {
        showLoading()
        if (!filter) {
            filter = { ...sanctioningFilter }
        }

        TournamentService.getTournamentList(pageNumber, pageSize, sort, filter)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    setTournamentData(result.data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    const closeNoteModal = () => {
        setVisibleNote(false)
        setSelectedObject({})
    }

    const editTournamentModal = (record) => {
        if (record) {
            setVisibleAddEditTournament(true)
            setSelectedObject(record)
        }
    }

    const closeAddEditTournamentModal = (isReload) => {
        setVisibleAddEditTournament(false)
        if (isReload) {
            getTournamentData(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, sanctioningFilter, gridConfigOptions.sort)
        }
    }

    const closeDateApplicationModal = (isReload) => {
        setVisibleDateApplication(false)
        if (isReload) {
            getTournamentData(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, sanctioningFilter, gridConfigOptions.sort)
        }
    }

    const applyForTournamentDate = () => {
        setVisibleDateApplication(true)
    }

    const hasTournamentSelected = () => {
        if (tournamentData && tournamentData.ObjectList && tournamentData.ObjectList.length > 0) {
            let selected = tournamentData.ObjectList.filter(p => p.IsSelected)
            if (selected && selected.length > 0) {
                return true
            }
        }
        return false
    }

    const deleteTournaments = () => {
        if (hasTournamentSelected()) {
            confirm({
                title: t("common.confirm"),
                icon: <ExclamationCircleOutlined />,
                content: (<>{t("tournament.archive_tournament_confirm")} </>),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    let selected = tournamentData.ObjectList.filter(p => p.IsSelected)
                    let ids = selected.map((n, index) => n.Id)
                    showLoading()
                    TournamentService.deleteTournaments(ids)
                        .finally(() => dismissLoading())
                        .then(result => {
                            getTournamentData(1, gridConfigOptions.pageSize, sanctioningFilter, gridConfigOptions.sort)
                            setGridConfigOptionsValue({
                                ...gridConfigOptions,
                                pageNumber: 1
                            })
                        })
                        .catch(error => CommonService.handleErrorResponse(error))
                },
                onCancel() {
                }

            });
        }
    }

    const editTournaments = () => {
        if (hasTournamentSelected()) {
            let selected = tournamentData.ObjectList.filter(p => p.IsSelected)
            setUpdateTournaments(selected)
            setVisibleUpdateTournaments(true)
        }
    }

    const handleUpdateTournaments = (isReload) => {
        setVisibleUpdateTournaments(false)
        if (isReload) {
            getTournamentData(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, sanctioningFilter, gridConfigOptions.sort)
        }
    }

    return (
        <>
            {currentPosition && (currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee || currentPosition.PositionTypeId == Constant.PositionType.StateExcutive) &&
                <div>
                    <div id={Constant.FixItemsContainerId}>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                            <Title className='color-dark-blue' level={5}>{t("tournament.tournaments")}</Title>
                        </Row>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                            <Col flex={100} className="text-right">
                                <div>
                                    <Button onClick={showFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon />}>{t("common.filter")}</Button>
                                    <Button className='m-l-10' danger disabled={!hasTournamentSelected()} onClick={deleteTournaments} >{t("common.archive")}</Button>
                                    <Button className='btn-outline-dark-blue m-l-10' disabled={!hasTournamentSelected()} onClick={editTournaments}>{t("common.edit")}</Button>
                                    {
                                        currentPosition && currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee &&
                                        <Button className='btn-solid-dark-blue m-l-10' onClick={applyForTournamentDate}>{t("tournament_list.apply_for_tournament_date")}</Button>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Table
                                {...GetTableConfigs(columns.filter(c => !c.hidden), tournamentData?.ObjectList, tournamentData?.TotalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                            />
                        </Col>
                    </Row>
                </div>
            }

            <Drawer
                title={t("common.filter")}
                width={378}
                onClose={closeFilterLayout}
                open={visibleFilter}
                closeIcon={false}
                styles={{
                    body: {
                        paddingBottom: 80,
                    }
                }}
                extra={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                        <Button onClick={closeFilterLayout} icon={<CloseIcon />} type="link" />
                    </Space>
                }
                footer={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button onClick={closeFilterLayout}>{t("common.cancel")}</Button>
                        <Button onClick={applyFilter} type="primary">
                            {t("common.apply")}
                        </Button>
                    </Space>
                }>
                <Form
                    layout="vertical"
                    {...layout}
                    form={formFilter}
                    name="formFilter"
                    key='formFilter'
                    initialValues={sanctioningFilter}
                >
                    {
                        currentPosition && currentPosition.PositionTypeId == Constant.PositionType.StateExcutive &&
                        <>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="EntityIds"
                                        label={t("tournament.club")}
                                    >
                                        <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={filterData && filterData.Entities ? filterData.Entities.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}
                                        >
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="StateZoneIds"
                                        label={t("tournament.zone")}
                                    >
                                        <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={filterData && filterData.StateZones ? filterData.StateZones.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}
                                        >
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    }

                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="Title"
                                label={t("sanctioning_applications.title")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="StartDate"
                                label={t("sanctioning_applications.start_date")}
                            >
                                <DatePicker format={Constant.DateFormat} allowClear style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="FinishDate"
                                label={t("sanctioning_applications.finish_date")}
                            >
                                <DatePicker format={Constant.DateFormat} allowClear style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="StatusIds"
                                label={t("common.status")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={statusCodes ? statusCodes.map(b => {
                                        return {
                                            label: b.Description,
                                            value: b.Value
                                        }
                                    }) : []}>
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>

            {
                visibleNote &&
                <Modal
                    title={t("sanctioning_applications.tournament_approval_notes")}
                    open={true}
                    onCancel={closeNoteModal}
                    confirmLoading={false}
                    maskClosable={false}
                    centered
                    width={850}
                    footer={null}
                >
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }} className={`${!selectedObject || !selectedObject.ApprovalNotes ? "text-center" : ""}`}>
                            <div>{selectedObject && selectedObject.ApprovalNotes ? selectedObject.ApprovalNotes : t("sanctioning_applications.no_notes_entered")}</div>
                        </Col>
                    </Row>
                </Modal>
            }

            {
                visibleAddEditTournament &&
                <EditTournament visible={visibleAddEditTournament} handleCancel={closeAddEditTournamentModal} selectedObject={selectedObject}></EditTournament>
            }

            {
                visibleDateApplication &&
                <DateApplication visible={visibleAddEditTournament} handleCancel={closeDateApplicationModal}></DateApplication>
            }

            {
                visibleUpdateTournaments &&
                <EditMultipleTournaments visible={visibleUpdateTournaments} handleCancel={handleUpdateTournaments} objectData={updateTournaments}></EditMultipleTournaments>
            }

            {currentPosition && currentPosition.PositionTypeId != Constant.PositionType.ClubCommittee && currentPosition.PositionTypeId != Constant.PositionType.StateExcutive &&
                <>
                    <Table
                        style={{ visibility: 'hidden', height: '0px' }}
                        {...GetTableConfigs(columns, [], 0, "Id", null, gridConfigOptions.pageNumber)}
                    />
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_access")} />
                </>
            }
        </>

    )

}

export default TournamentList;
import React, { useState, useEffect } from 'react';
import { Constant, CommonService, RecordService } from '../../services/services';
import { useTranslation } from 'react-i18next';
import { GetTableConfigs, ActionButtonsCell, EditableCell, StatusCell } from '../../components/customTable/customTable'
import LoadingContext from '../../components/loading/loadingContext'
import { FilterAltIcon, CloseIcon, EyeIcon } from '../../components/icons/icons';
import { Row, Col, Table, Button, Checkbox, Modal, Form, Drawer, Space, Input, Select, Typography, Dropdown } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useGlobalState } from './../../utilities/globalState'
import RecordDetail from './viewRecord'
import EnhancedSelect from '../../components/enhancedSelect/enhancedSelect';

import PubSub from 'pubsub-js'

const { confirm } = Modal;
const { Option } = Select;
const { Title } = Typography;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const defaultGridOption = {
    pageNumber: 1,
    pageSize: CommonService.getPageSize(),
    // sort: "RecordDate-ascend"
}

const StateRecord = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const emptyTableData = {
        ObjectList: [],
        TotalItems: 0
    }
    const [recordData, setRecordData] = useState(emptyTableData)
    const [gridConfigOptions, setGridConfigOptionsValue] = useState(defaultGridOption)
    const [currentPosition] = useGlobalState('currentPosition');
    const [formFilter] = Form.useForm();
    const [recordFilter, setRecordFilter] = useState({ StatusIds: [Constant.StatusCode.Active] });
    const [visibleFilter, setVisibleFilter] = useState(false);
    const [selectedObject, setSelectedObject] = useState({})
    const [visibleRecordDetail, setVisibleRecordDetail] = useState(false);
    const [fishSpecies, setFishSpecies] = useState([]);
    const [recordCategories, setRecordCategories] = useState([]);
    const [recordEnvironments, setRecordEnvironments] = useState([]);
    const [lineClasses, setLineClasses] = useState([]);
    const [entities, setEntities] = useState([]);
    const [genders, setGenders] = useState([]);
    const [statuses, setStatuses] = useState([]);

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
        }, 100)

        showLoading()
        Promise.all([
            RecordService.getStateRecords(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, recordFilter),
            RecordService.getRecordFilterData()
        ])
            .finally(() => dismissLoading())
            .then(response => {
                if (response[0] && response[0].data) {
                    setRecordData(response[0].data)
                    setRecordFilter({ StatusIds: [Constant.StatusCode.Active] });
                }

                if (response[1] && response[1].data) {
                    setRecordCategories(response[1].data.RecordCategories);
                    setRecordEnvironments(response[1].data.Environments);
                    setFishSpecies(response[1].data.FishingSpecies);
                    setLineClasses(response[1].data.LineClasses);
                    setGenders(response[1].data.Genders);
                    setEntities(response[1].data.Clubs);
                    setStatuses(response[1].data.Statuses);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);
        return () => {
            RecordService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    const getRecords = (pageNumber, pageSize, filter, sort) => {
        showLoading()
        if (!filter) {
            filter = { ...recordFilter }
        }

        RecordService.getStateRecords(pageNumber, pageSize, sort, filter)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    setRecordData(result.data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    const viewRecord = (record) => {
        if (record) {
            setSelectedObject(record)
            setVisibleRecordDetail(true)
        }
    }

    const exportRecords = (fileType) => {
        showLoading();
        RecordService.getExportRecords(gridConfigOptions.sort, formFilter.getFieldsValue(), fileType, true)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    if (fileType == Constant.FileType.Excel) {
                        const type = result.headers['content-type']
                        const blob = new Blob([result.data], { type: type })
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = `Records_Report_List.xlsx`
                        link.click()
                    } else if (fileType == Constant.FileType.Csv) {
                        const type = result.headers['content-type']
                        const blob = new Blob([result.data], { type: type })
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = `Records_Report_List.csv`
                        link.click()
                    }
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    const handleExport = (e) => {
        exportRecords(e.key)
    };

    const exportItems = [
        {
            label: t("common.excel"),
            key: Constant.FileType.Excel
        },
        {
            label: t("common.csv"),
            key: Constant.FileType.Csv
        }
    ];
    const menuExport = {
        items: exportItems,
        onClick: handleExport
    };

    const getViewButtonsInfo = (item) => {
        return [
            {
                Description: t("common.view"),
                Icon: <EyeIcon />,
                ColorClass: Constant.ColorClass.LightBlue,
                handleAction: viewRecord
            }
        ]
    }

    const columns = [
        {
            title: t('record_management.view'),
            render: (value, record) => {
                return <ActionButtonsCell btnList={getViewButtonsInfo(record)} data={record} />
            },
            align: "center",
            width: 50,
        },
        {
            title: t('fishing_activity.species'),
            dataIndex: 'FishSpeciesName',
            key: 'FishSpeciesName',
            sorter: true,
            width: 130,
        },
        {
            title: t('member.club'),
            dataIndex: 'ClubEntityAcronym',
            key: 'ClubEntityAcronym',
            sorter: true,
            width: 80,
        },
        {
            title: t('record_management.record_category'),
            dataIndex: 'RecordCategoryName',
            key: 'RecordCategoryName',
            sorter: true,
            width: 80,
        },
        {
            title: t('fishing_activity.line_class'),
            dataIndex: 'LineClassName',
            key: 'LineClassName',
            sorter: true,
            width: 80,
        },
        {
            title: t('record_management.record_environment'),
            dataIndex: 'RecordEnvironmentName',
            key: 'RecordEnvironmentName',
            sorter: true,
            width: 100,
        },
        {
            title: t('fishing_activity.weight'),
            dataIndex: 'FishWeight',
            key: 'FishWeight',
            sorter: true,
            cellType: Constant.CellType.DecimalInput,
            width: 80,
        },
        {
            title: t('record_management.record_date'),
            dataIndex: 'RecordDate',
            key: 'RecordDate',
            sorter: true,
            fixed: 'left',
            render: (value, record) => {
                if (record.RecordDate) {
                    let date = new Date(record.RecordDate)
                    var day = date.getDate();
                    var month = date.getMonth() + 1; //Month from 0 to 11
                    var year = date.getFullYear();

                    return `${day}/${month}/${year}`
                }

                return null
            },
            width: 100
        },
        {
            title: t('record_management.fishing_location'),
            dataIndex: 'LocationName',
            key: 'LocationName',
            sorter: true,
            width: 80,
        },
        {
            title: t('member.first_name'),
            dataIndex: 'FirstName',
            key: 'FirstName',
            sorter: true,
            width: 80,
        },
        {
            title: t('member.surname'),
            dataIndex: 'Surname',
            key: 'Surname',
            sorter: true,
            width: 80,
        },
        {
            title: t('common.status'),
            dataIndex: 'StatusId',
            key: 'StatusId',
            sorter: false,
            align: 'center',
            width: 50,
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.StatusId)}
                    description={CommonService.getStatusDescription(record.StatusId, false)} />
            )
        }
    ]

    const columnsMapping = columns.filter(c => !c.hidden).map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition
            }),
        };
    })

    const components = {
        body: {
            cell: EditableCell,
        }
    };

    const onTableChange = (pagination, filters, sorter, extra) => {
        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = defaultGridOption.sort;
        }

        getRecords(pagination.current, pagination.pageSize, recordFilter, sort)
        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        })
    }

    const showFilterLayout = () => {
        setVisibleFilter(true);
    }

    const closeFilterLayout = () => {
        formFilter.setFieldsValue(recordFilter);
        setVisibleFilter(false);
    }

    const resetFilter = () => {
        formFilter.resetFields();
        setRecordFilter({ StatusIds: [Constant.StatusCode.Active] });
    }

    const applyFilter = () => {
        setVisibleFilter(false);

        let filter = formFilter.getFieldsValue();
        if (!filter || (filter && !filter.StatusIds)) {
            filter.StatusIds = [Constant.StatusCode.Active];
        }
        setRecordFilter(filter)
        setGridConfigOptionsValue({
            ...gridConfigOptions,
            pageNumber: 1
        })
        getRecords(1, gridConfigOptions.pageSize, filter, gridConfigOptions.sort)
    }

    const handleCloseRecordDetail = () => {
        setVisibleRecordDetail(false)
    }

    return (
        <>
            <div id={Constant.FixItemsContainerId}>
                <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                    <Title className='color-dark-blue' level={5}>{t("record_management.state_records")}</Title>
                </Row>
                <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                    <Col flex={100} className="text-right">
                        <div>
                            <Button onClick={showFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon />}>{t("common.filter")}</Button>
                            <Dropdown className='m-l-10' menu={menuExport} trigger={['click']}>
                                <Button className='btn-outline-blue btn-icon' icon={<DownloadOutlined />}>
                                    {t("common.export")}
                                </Button>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>
            </div>
            <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                <Col xs={{ span: 24 }}>
                    <Table
                        components={components}
                        rowClassName={() => 'editable-row'}
                        {...GetTableConfigs(columnsMapping, recordData?.ObjectList, recordData?.TotalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                    />
                </Col>
            </Row>

            <Drawer
                title={t("common.filter")}
                width={378}
                onClose={closeFilterLayout}
                open={visibleFilter}
                closeIcon={false}
                styles={{
                    body: {
                        paddingBottom: 80,
                    }
                }}
                extra={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                        <Button onClick={closeFilterLayout} icon={<CloseIcon />} type="link" />
                    </Space>
                }
                footer={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button onClick={closeFilterLayout}>{t("common.cancel")}</Button>
                        <Button onClick={applyFilter} type="primary">
                            {t("common.apply")}
                        </Button>
                    </Space>
                }>
                <Form
                    layout="vertical"
                    {...layout}
                    form={formFilter}
                    name="formFilter"
                    key='formFilter'
                    initialValues={{ StatusIds: [Constant.StatusCode.Active] }}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="FishSpecyIds"
                                label={t("fishing_activity.species")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={fishSpecies ? fishSpecies.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="RecordCategoryIds"
                                label={t("record_management.record_category")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={recordCategories ? recordCategories.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="EnvironmentIds"
                                label={t("record_management.record_environment")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={recordEnvironments ? recordEnvironments.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="FirstName"
                                label={t("member.first_name")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="Surname"
                                label={t("member.surname")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="GenderIds"
                                label={t("member.gender")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={genders ? genders.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="ClubIds"
                                label={t("fishing_activity.club")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={entities ? entities.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}>
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="LineClassIds"
                                label={t("fishing_activity.line_class")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={lineClasses ? lineClasses.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="StatusIds"
                                label={t("common.status")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={statuses ? statuses.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}>
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
            {
                visibleRecordDetail &&
                <RecordDetail visible={visibleRecordDetail}
                    handleCancel={handleCloseRecordDetail}
                    objectData={selectedObject}></RecordDetail>
            }
        </>
    )
}

export default StateRecord;
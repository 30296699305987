import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Constant, CommonService, MembershipService, TrophyService } from '../../../services/services';
import { GetTableConfigs, StatusCell, ActionButtonsCell, EditableCell } from '../../../components/customTable/customTable'
import LoadingContext from '../../../components/loading/loadingContext'
import { FilterAltIcon, CloseIcon, EditIcon, TrashIcon, EyeIcon } from '../../../components/icons/icons';
import EnhancedSelect from '../../../components/enhancedSelect/enhancedSelect';
import { Row, Col, Table, Button, Checkbox, Modal, Form, Drawer, Space, Input, Select, InputNumber, Empty, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useGlobalState } from '../../../utilities/globalState'
import PubSub from 'pubsub-js'
import ViewBoats from './viewBoats'
import AddUpdateBoatGroup from './addUpdateBoatGroup'
const defaultGridOption = {
    pageNumber: 1,
    pageSize: CommonService.getPageSize(),
    sort: "Name-ascend"
}

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const { Text, Title } = Typography;
const { confirm } = Modal;
const { Option } = Select;

const BoatGroupingManagement = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const emptyTableData = {
        ObjectList: [],
        TotalItems: 0
    }
    const [boatGroupingData, setBoatGroupingData] = useState(emptyTableData)
    const [gridConfigOptions, setGridConfigOptionsValue] = useState(defaultGridOption)
    const [visibleFilter, setVisibleFilter] = useState(false);
    const [formFilter] = Form.useForm();
    const [visibleAddUpdateBoatGrouping, setVisibleAddUpdateBoatGrouping] = useState(false);
    const [boatGroupingFilter, setBoatGroupingFilter] = useState({ StatusIds: Constant.StatusCode.Active });
    const [currentPosition] = useGlobalState('currentPosition');
    const [selectedObject, setSelectedObject] = useState();
    const [visibleViewBoat, setVisibleViewBoat] = useState(false);

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
        }, 100)

        showLoading()
        Promise.all([
            TrophyService.getBoatGroups(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, { StatusIds: Constant.StatusCode.Active })
        ])
            .finally(() => dismissLoading())
            .then(response => {
                if (response[0] && response[0].data) {
                    setBoatGroupingData(response[0].data)
                }

            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);
        return () => {
            TrophyService.cancelRequest()
            MembershipService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    const getBoatGroupings = (pageNumber, pageSize, filter, sort) => {
        showLoading()
        if (!filter) {
            filter = { ...boatGroupingFilter }
        }

        TrophyService.getBoatGroups(pageNumber, pageSize, sort, filter)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    setBoatGroupingData(result.data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    const statusCodes = [
        {
            Value: Constant.StatusCode.Active,
            Description: t("common.active")
        },
        {
            Value: Constant.StatusCode.Inactive,
            Description: t("common.inactive")
        }
    ]

    const viewBoats = (record) => {
        if (record) {
            setVisibleViewBoat(true)
            setSelectedObject(record)
        }
    }

    const columns = [
        {
            title: t('common.action'),
            render: (value, record) => {
                return <ActionButtonsCell btnList={
                    [
                        {
                            Description: t("common.edit"),
                            Icon: <EditIcon />,
                            ColorClass: Constant.ColorClass.LightBlue,
                            handleAction: editBoatGrouping
                        },
                        {
                            Description: t("common.delete"),
                            Icon: <TrashIcon />,
                            ColorClass: Constant.ColorClass.LightRed,
                            Hide: record.StatusId == Constant.StatusCode.Inactive,
                            handleAction: deleteBoatGrouping
                        }
                    ]
                } data={record} />
            },
            align: "center",
            width: 90
        },
        {
            title: t('boat_grouping.view_boats'),
            render: (value, record) => {
                return <ActionButtonsCell btnList={[
                    {
                        Description: t("common.view"),
                        Icon: <EyeIcon />,
                        ColorClass: Constant.ColorClass.LightBlue,
                        handleAction: viewBoats
                    }
                ]} data={record} />
            },
            align: "center",
            width: 90,
        },
        {
            title: t('boat_grouping.boat_group_name'),
            dataIndex: 'Name',
            key: 'Name',
            sorter: true,
            width: 230,
            defaultSortOrder: 'ascend'
        },
        {
            title: t('common.status'),
            dataIndex: 'StatusId',
            key: 'StatusId',
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.StatusId)}
                    description={CommonService.getStatusDescription(record.StatusId)} />
            ),
            sorter: true,
            align: 'center',
            width: 100
        }
    ]

    const onTableChange = (pagination, filters, sorter, extra) => {
        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = defaultGridOption.sort;
        }

        getBoatGroupings(pagination.current, pagination.pageSize, boatGroupingFilter, sort)
        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        })
    }

    const showFilterLayout = () => {
        setVisibleFilter(true);
    }

    const closeFilterLayout = () => {
        formFilter.setFieldsValue(boatGroupingFilter);
        setVisibleFilter(false);
    }

    const resetFilter = () => {
        formFilter.resetFields();
        setBoatGroupingFilter({ StatusIds: Constant.StatusCode.Active });
    }

    const applyFilter = () => {
        setVisibleFilter(false);
        let filter = formFilter.getFieldsValue();
        setBoatGroupingFilter(filter)
        setGridConfigOptionsValue({
            ...gridConfigOptions,
            pageNumber: 1
        })
        getBoatGroupings(1, gridConfigOptions.pageSize, filter, gridConfigOptions.sort)
    }

    const handleAddUpdateBoatGroupingClose = (isReload) => {
        setVisibleAddUpdateBoatGrouping(false)
        if (isReload) {
            getBoatGroupings(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, boatGroupingFilter, gridConfigOptions.sort)
        }
    }

    const addBoatGrouping = () => {
        setVisibleAddUpdateBoatGrouping(true)
        setSelectedObject(null)
    }

    const editBoatGrouping = (record) => {
        if (record) {
            showLoading()
            TrophyService.getBoatGroupById(record.Id)
                .finally(() => dismissLoading())
                .then(result => {
                    if (result.data) {
                        setVisibleAddUpdateBoatGrouping(true)
                        setSelectedObject(result.data)
                    }
                })
                .catch(error => {
                    CommonService.handleErrorResponse(error);
                })

        }
    }

    const deleteBoatGrouping = (record) => {
        confirm({
            title: t("common.confirm"),
            icon: <ExclamationCircleOutlined />,
            content: t("boat_grouping.delete_boat_group_confirm"),
            okText: t("common.yes"),
            cancelText: t("common.no"),
            onOk() {
                showLoading()
                TrophyService.deleteBoatGroup(record.Id)
                    .finally(() => dismissLoading())
                    .then(result => {
                        CommonService.presentToast('success', t("boat_grouping.delete_successful"))
                        getBoatGroupings(1, gridConfigOptions.pageSize, boatGroupingFilter, gridConfigOptions.sort)
                        setGridConfigOptionsValue({
                            ...gridConfigOptions,
                            pageNumber: 1
                        })
                    })
                    .catch(error => CommonService.handleErrorResponse(error))
            },
            onCancel() {
            }

        });
    }

    const handleViewBoatClose = () => {
        setVisibleViewBoat(false)
    }



    return (
        <>
            {currentPosition && (currentPosition.PositionTypeId == Constant.PositionType.StateExcutive ||
                currentPosition.PositionTypeId == Constant.PositionType.NationalExecutive) &&
                <div>
                    <div id={Constant.FixItemsContainerId}>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                            <Col xs={{ span: 24 }}>
                                <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                    <Title className='color-dark-blue' level={5}>{t("boat_grouping.title")}</Title>
                                </Space>
                            </Col>
                        </Row>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                            <Col flex={100} className="text-right">
                                <div>
                                    <Button onClick={showFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon />}>{t("common.filter")}</Button>
                                    <Button className='btn-solid-dark-blue m-l-10' onClick={addBoatGrouping}>{t("common.add_new")}</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Table
                                {...GetTableConfigs(columns, boatGroupingData?.ObjectList, boatGroupingData?.TotalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                            />
                        </Col>
                    </Row>
                    <Drawer
                        title={t("common.filter")}
                        width={378}
                        onClose={closeFilterLayout}
                        open={visibleFilter}
                        closeIcon={false}
                        styles={{
                            body: {
                                paddingBottom: 80,
                            }
                        }}
                        extra={
                            <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                                <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                                <Button onClick={closeFilterLayout} icon={<CloseIcon />} type="link" />
                            </Space>
                        }
                        footer={
                            <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                                <Button onClick={closeFilterLayout}>{t("common.cancel")}</Button>
                                <Button onClick={applyFilter} type="primary">
                                    {t("common.apply")}
                                </Button>
                            </Space>
                        }>
                        <Form
                            layout="vertical"
                            {...layout}
                            form={formFilter}
                            name="formFilter"
                            key='formFilter'
                        >
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="Name"
                                        label={t("boat.boat_name")}
                                    >
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="BoatNo"
                                        label={t("boat.boat_no")}
                                    >
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="StatusIds"
                                        label={t("common.status")}
                                    >
                                        <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            defaultValue={1}
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={statusCodes ? statusCodes.map(b => {
                                                return {
                                                    label: b.Description,
                                                    value: b.Value
                                                }
                                            }) : []}>
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Drawer>
                    {
                        visibleViewBoat &&
                        <ViewBoats
                            handleCancel={handleViewBoatClose}
                            selectedObject={selectedObject} />
                    }
                    {
                        visibleAddUpdateBoatGrouping &&
                        <AddUpdateBoatGroup
                            handleCancel={handleAddUpdateBoatGroupingClose}
                            selectedObject={selectedObject} />
                    }
                </div>
            }

            {currentPosition && currentPosition.PositionTypeId != Constant.PositionType.StateExcutive &&
                currentPosition.PositionTypeId != Constant.PositionType.NationalExecutive &&
                <>
                    <Table
                        style={{ visibility: 'hidden', height: '0px' }}
                        {...GetTableConfigs(columns, [], 0, "Id", onTableChange, gridConfigOptions.pageNumber)}
                    />
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_access")} />
                </>
            }

        </>
    )
}

export default BoatGroupingManagement
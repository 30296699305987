import axios from 'axios';
import { axiosPortal } from './authService'
import moment from 'moment';

const source = [];
let baseURL = "api/Membership"

export const MembershipService = {
    searchMemberships: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/Search?PageNumber=${pageNumber ? pageNumber : 1}`
        if (sort) {
            url += `&Sort=${sort}`
        }
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (params) {
            if (params.EntityName) {
                url += `&EntityName=${params.EntityName}`;
            }
            if (params.LastName) {
                url += `&LastName=${params.LastName}`;
            }
            if (params.FirstName) {
                url += `&FirstName=${params.FirstName}`;
            }
            if (params.FamilyGroupIds) {
                url += `&FamilyGroupIds=${params.FamilyGroupIds}`;
            }
            if (params.GenderIds) {
                url += `&GenderIds=${params.GenderIds}`;
            }
            if (params.FishingTypeIds) {
                url += `&FishingTypeIds=${params.FishingTypeIds}`;
            }
            if (params.FishingCategoryIds) {
                url += `&FishingCategoryIds=${params.FishingCategoryIds}`;
            }
            if (params.FinancialTypeIds) {
                url += `&FinancialTypeIds=${params.FinancialTypeIds}`;
            }
            if (params.FinancialStatusIds) {
                url += `&FinancialStatusIds=${params.FinancialStatusIds}`;
            }
            if (params.FinancialEnd) {
                let dateFormat = "9999-06-30";
                if (moment(params.FinancialEnd, "YYYY-MM-DD", true).isValid()) {
                    dateFormat = moment(params.FinancialEnd).format("YYYY-MM-DD");
                }
                url += `&FinancialEnd=${dateFormat}`;
            }
            if (params.MemberStatusIds) {
                url += `&MemberStatusIds=${params.MemberStatusIds}`;
            }
            if (params.JoinedOrRenewedDateRange) {
                let dateRange;
                if (params.JoinedOrRenewedDateRange[0].$d)
                {
                    dateRange = moment(params.JoinedOrRenewedDateRange[0].$d).format("YYYY-MM-DD");
                }
                if (params.JoinedOrRenewedDateRange[1].$d)
                {
                    dateRange = dateRange + ',' + moment(params.JoinedOrRenewedDateRange[0].$d).format("YYYY-MM-DD");
                }
                url += `&JoinedOrRenewedDateRange=${dateRange}`;
            }
            if (params.FinancialYearId) {
                url += `&FinancialYearId=${params.FinancialYearId}`;
            }
            if (params.ClubIds) {
                url += `&ClubIds=${params.ClubIds}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    membersSearch: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/MembersSearch?PageNumber=${pageNumber ? pageNumber : 1}`
        if (sort) {
            url += `&Sort=${sort}`
        }
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (params) {
            if (params.Ids) {
                url += `&Ids=${params.Ids}`;
            }           
            if (params.EntityName) {
                url += `&EntityName=${params.EntityName}`;
            }
            if (params.MemberId) {
                url += `&MemberId=${params.MemberId}`;
            }
            if (params.LastName) {
                url += `&LastName=${params.LastName}`;
            }
            if (params.FirstName) {
                url += `&FirstName=${params.FirstName}`;
            }
            if (params.FamilyGroupIds) {
                url += `&FamilyGroupIds=${params.FamilyGroupIds}`;
            }
            if (params.GenderIds) {
                url += `&GenderIds=${params.GenderIds}`;
            }
            if (params.FishingTypeIds) {
                url += `&FishingTypeIds=${params.FishingTypeIds}`;
            }
            if (params.FishingCategoryIds) {
                url += `&FishingCategoryIds=${params.FishingCategoryIds}`;
            }
            if (params.FinancialTypeIds) {
                url += `&FinancialTypeIds=${params.FinancialTypeIds}`;
            }
            if (params.FinancialStatusIds) {
                url += `&FinancialStatusIds=${params.FinancialStatusIds}`;
            }
            if (params.FinancialEnd) {
                let dateFormat = moment(params.FinancialEnd).format("YYYY-MM-DD");
                url += `&FinancialEnd=${dateFormat}`;
            }
            if (params.MemberStatusIds) {
                url += `&MemberStatusIds=${params.MemberStatusIds}`;
            }
            if (params.FinancialIds) {
                url += `&FinancialIds=${params.FinancialIds}`;
            }
            if (params.FlaggedIds) {
                url += `&FlaggedIds=${params.FlaggedIds}`;
            }
            if (params.SeachAllClubs) {
                url += `&SeachAllClubs=${params.SeachAllClubs}`;
            }
            if (params.ClubIds) {
                url += `&ClubIds=${params.ClubIds}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getFilterDatas: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/FilterDatas`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getClubs: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/Clubs`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },


    getEntitiesByPositionTypeId: function (positionTypeId) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/EntitiesByPositionTypeId?PositionTypeId=${positionTypeId}`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    addUpdateMemberships: function (data) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    deleteMemberships: function (ids) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/DeleteMemberships`, ids, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    verifyDuplicateUserName: function (id, userName) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/VerifyDuplicateUserName`, { Id: id, UserName: userName }, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getNextUserName: function (id, userName, sequence) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/GetNextUserName`, { Id: id, UserName: userName, Sequence: sequence }, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getMembershipDetail: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetMembershipDetail`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getMembershipContactDetail: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetMembershipContactDetail`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    addUpdateMembershipContactDetail: function (data) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/AddUpdateMembershipContactDetail`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    cancelRequest: function () {
        if (source && source.length > 0) {
            for (var i = 0; i < source.length; i++) {
                source[i].cancel('Operation canceled.')
            }
            source.splice(0, source.length)
        }
    },
    getMemberClubCommittee: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetMemberClubCommittee?PageNumber=${pageNumber ? pageNumber : 1}`
        if (sort) {
            url += `&Sort=${sort}`
        }
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (params) {
            if (params.EntityId) {
                url += `&EntityId=${params.EntityId}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getMemberClubDetail: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetMemberClubDetail`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getMemberStateExecutive: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetMemberStateExecutive?PageNumber=${pageNumber ? pageNumber : 1}`
        if (sort) {
            url += `&Sort=${sort}`
        }
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (params) {
            if (params.EntityId) {
                url += `&EntityId=${params.EntityId}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getMemberStateDetail: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetMemberStateDetail`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getBoats: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/Boats?PageNumber=${pageNumber ? pageNumber : 1}`
        if(pageSize){
            url += `&PageSize=${pageSize}`
        }
        if (sort) {
            url += `&Sort=${sort}`
        }

        if (params) {
            if (params.Name) {
                url += `&Name=${params.Name}`;
            }
            if (params.RegoNo) {
                url += `&RegoNo=${params.RegoNo}`;
            }
            if (params.BoatNo) {
                url += `&BoatNo=${params.BoatNo}`;
            }
            if (params.Length) {
                url += `&Length=${params.Length}`;
            }
            if (params.BoatCategoryIds) {
                url += `&BoatCategoryIds=${params.BoatCategoryIds}`;
            }
            if (params.FuelTypeIds) {
                url += `&FuelTypeIds=${params.FuelTypeIds}`;
            }
            if (params.Make) {
                url += `&Make=${params.Make}`;
            }
            if (params.Model) {
                url += `&Model=${params.Model}`;
            }
            if (params.SkipperFinancialChecks) {
                url += `&SkipperFinancialChecks=${params.SkipperFinancialChecks}`;
            }
            if (params.Skipper1) {
                url += `&Skipper1=${params.Skipper1}`;
            }
            if (params.Skipper2) {
                url += `&Skipper2=${params.Skipper2}`;
            }
            if (params.StatusIds) {
                url += `&StatusIds=${params.StatusIds}`;
            }
            if (params.ClubName) {
                url += `&ClubName=${params.ClubName}`;
            }
            if (params.SeachAllClubs) {
                url += `&SeachAllClubs=${params.SeachAllClubs}`;
            }
            if (params.ClubIds) {
                url += `&ClubIds=${params.ClubIds}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    addBoats: function (boats) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/Boats`

        return axiosPortal.post(url, boats, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    updateBoats: function (boats) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/Boats`

        return axiosPortal.put(url, boats, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    deleteBoats: function (boatIds) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/DeleteBoats`

        return axiosPortal.post(url, boatIds, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getBoatCategories: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/BoatCategories`

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getFuelTypes: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/FuelTypes`

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getFamilyGroups: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/FamilyGroups?PageNumber=${pageNumber ? pageNumber : 1}`

        if (sort) {
            url += `&Sort=${sort}`
        }

        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (params) {
            if (params.Name) {
                url += `&Name=${params.Name}`;
            }
            if (params.PrimaryContact) {
                url += `&PrimaryContact=${params.PrimaryContact}`;
            }
            if (params.Member2) {
                url += `&Member2=${params.Member2}`;
            }
            if (params.Member3) {
                url += `&Member3=${params.Member3}`;
            }
            if (params.Member4) {
                url += `&Member4=${params.Member4}`;
            }
            if (params.Member5) {
                url += `&Member5=${params.Member5}`;
            }
            if (params.Member6) {
                url += `&Member6=${params.Member6}`;
            }
            if (params.StatusIds) {
                url += `&StatusIds=${params.StatusIds}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    addFamilyGroups: function (familyGroups) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/FamilyGroups`

        return axiosPortal.post(url, familyGroups, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    updateFamilyGroups: function (familyGroups) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/FamilyGroups`

        return axiosPortal.put(url, familyGroups, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    deleteFamilyGroups: function (familyGroupIds) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/DeleteFamilyGroups`

        return axiosPortal.post(url, familyGroupIds, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getExportMemberReport: function (sort, params, fileType, reportFormat) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/ExportMemberReport?fileType=${fileType}&reportFormat=${reportFormat}`
        if (sort) {
            url += `&Sort=${sort}`
        }

        if (params) {
            if (params.EntityName) {
                url += `&EntityName=${params.EntityName}`;
            }
            if (params.LastName) {
                url += `&LastName=${params.LastName}`;
            }
            if (params.FirstName) {
                url += `&FirstName=${params.FirstName}`;
            }
            if (params.FamilyGroupIds) {
                url += `&FamilyGroupIds=${params.FamilyGroupIds}`;
            }
            if (params.GenderIds) {
                url += `&GenderIds=${params.GenderIds}`;
            }
            if (params.FishingTypeIds) {
                url += `&FishingTypeIds=${params.FishingTypeIds}`;
            }
            if (params.FishingCategoryIds) {
                url += `&FishingCategoryIds=${params.FishingCategoryIds}`;
            }
            if (params.FinancialTypeIds) {
                url += `&FinancialTypeIds=${params.FinancialTypeIds}`;
            }
            if (params.FinancialStatusIds) {
                url += `&FinancialStatusIds=${params.FinancialStatusIds}`;
            }
            if (params.FinancialEnd) {
                let dateFormat = "9999-06-30";
                if (moment(params.FinancialEnd, "YYYY-MM-DD", true).isValid()) {
                    dateFormat = moment(params.FinancialEnd).format("YYYY-MM-DD");
                }
                url += `&FinancialEnd=${dateFormat}`;
            }
            if (params.FinancialYearId) {
                url += `&FinancialYearId=${params.FinancialYearId}`;
            }
            if (params.MemberStatusIds) {
                url += `&MemberStatusIds=${params.MemberStatusIds}`;
            }
            if (params.JoinedOrRenewedDateRange) {
                let dateRange;
                if (params.JoinedOrRenewedDateRange[0].$d)
                {
                    dateRange = moment(params.JoinedOrRenewedDateRange[0].$d).format("YYYY-MM-DD");
                }
                if (params.JoinedOrRenewedDateRange[1].$d)
                {
                    dateRange = dateRange + ',' + moment(params.JoinedOrRenewedDateRange[1].$d).format("YYYY-MM-DD");
                }
                url += `&JoinedOrRenewedDateRange=${dateRange}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token,
            responseType: 'blob'
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getPreviousFinancialYears: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `api/PreviousFinancialYears`

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getEoyMembershipInvoicing: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/EoyMembershipInvoicing`

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getExportEoyMembershipInvoicing: function (fileType) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/ExportEoyMembershipInvoicing?fileType=${fileType}`

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token,
            responseType: 'blob'
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getAllFinancialYears: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `api/GetAllFinancialYears`

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    searchEmailMembers: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/SearchEmailMembers?PageNumber=${pageNumber ? pageNumber : 1}`
        if (sort) {
            url += `&Sort=${sort}`
        }
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (params) {
            if (params.Ids) {
                url += `&Ids=${params.Ids}`;
            }
            if (params.FamilyGroupIds) {
                url += `&FamilyGroupIds=${params.FamilyGroupIds}`;
            }
            if (params.FishingTypeIds) {
                url += `&FishingTypeIds=${params.FishingTypeIds}`;
            }
            if (params.FishingCategoryIds) {
                url += `&FishingCategoryIds=${params.FishingCategoryIds}`;
            }
            if (params.FinancialTypeIds) {
                url += `&FinancialTypeIds=${params.FinancialTypeIds}`;
            }
            if (params.FinancialStatusIds) {
                url += `&FinancialStatusIds=${params.FinancialStatusIds}`;
            }
            if (params.SkipperIds) {
                url += `&SkipperIds=${params.SkipperIds}`;
            }
            if (params.PositionTitleIds) {
                url += `&PositionTitleIds=${params.PositionTitleIds}`;
            }
            if (params.UserName) {
                url += `&UserName=${params.UserName}`;
            }
            if (params.FirstName) {
                url += `&FirstName=${params.FirstName}`;
            }
            if (params.LastName) {
                url += `&LastName=${params.LastName}`;
            }
            if (params.MemberStatusIds) {
                url += `&MemberStatusIds=${params.MemberStatusIds}`;
            }
            if (params.ClubIds) {
                url += `&ClubIds=${params.ClubIds}`;
            }
            if (params.SkipperYesNoIds) {
                url += `&SkipperYesNoIds=${params.SkipperYesNoIds}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getEmailMembersFilterDatas: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetEmailMembersFilterDatas`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    sendEmailMembers: function (data) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/SendEmailMembers`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getClubsInState: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `api/GetClubsInState`

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getBoatSearchExport: function (sort, params, fileType) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/BoatSearchExport?fileType=${fileType}`

        if (sort) {
            url += `&Sort=${sort}`
        }

        if (params) {
            if (params.Name) {
                url += `&Name=${params.Name}`;
            }
            if (params.RegoNo) {
                url += `&RegoNo=${params.RegoNo}`;
            }
            if (params.BoatNo) {
                url += `&BoatNo=${params.BoatNo}`;
            }
            if (params.Length) {
                url += `&Length=${params.Length}`;
            }
            if (params.BoatCategoryIds) {
                url += `&BoatCategoryIds=${params.BoatCategoryIds}`;
            }
            if (params.FuelTypeIds) {
                url += `&FuelTypeIds=${params.FuelTypeIds}`;
            }
            if (params.Make) {
                url += `&Make=${params.Make}`;
            }
            if (params.Model) {
                url += `&Model=${params.Model}`;
            }
            if (params.SkipperFinancialChecks) {
                url += `&SkipperFinancialChecks=${params.SkipperFinancialChecks}`;
            }
            if (params.Skipper1) {
                url += `&Skipper1=${params.Skipper1}`;
            }
            if (params.Skipper2) {
                url += `&Skipper2=${params.Skipper2}`;
            }
            if (params.StatusIds) {
                url += `&StatusIds=${params.StatusIds}`;
            }
            if (params.ClubName) {
                url += `&ClubName=${params.ClubName}`;
            }
            if (params.SeachAllClubs) {
                url += `&SeachAllClubs=${params.SeachAllClubs}`;
            }
            if (params.ClubIds) {
                url += `&ClubIds=${params.ClubIds}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token,
            responseType: 'blob'
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getBoatManagementExport: function (sort, params, fileType) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/BoatManagementExport?fileType=${fileType}`

        if (sort) {
            url += `&Sort=${sort}`
        }

        if (params) {
            if (params.Name) {
                url += `&Name=${params.Name}`;
            }
            if (params.RegoNo) {
                url += `&RegoNo=${params.RegoNo}`;
            }
            if (params.BoatNo) {
                url += `&BoatNo=${params.BoatNo}`;
            }
            if (params.Length) {
                url += `&Length=${params.Length}`;
            }
            if (params.BoatCategoryIds) {
                url += `&BoatCategoryIds=${params.BoatCategoryIds}`;
            }
            if (params.FuelTypeIds) {
                url += `&FuelTypeIds=${params.FuelTypeIds}`;
            }
            if (params.Make) {
                url += `&Make=${params.Make}`;
            }
            if (params.Model) {
                url += `&Model=${params.Model}`;
            }
            if (params.SkipperFinancialChecks) {
                url += `&SkipperFinancialChecks=${params.SkipperFinancialChecks}`;
            }
            if (params.Skipper1) {
                url += `&Skipper1=${params.Skipper1}`;
            }
            if (params.Skipper2) {
                url += `&Skipper2=${params.Skipper2}`;
            }
            if (params.StatusIds) {
                url += `&StatusIds=${params.StatusIds}`;
            }
            if (params.ClubName) {
                url += `&ClubName=${params.ClubName}`;
            }
            if (params.ClubIds) {
                url += `&ClubIds=${params.ClubIds}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token,
            responseType: 'blob'
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    updateAccountSetting: function (data) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.put(`${baseURL}/UpdateAccountSetting`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
}
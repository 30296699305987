import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import './forgetPassword.scss';
import { Button, Form, Row, Col, Input, Typography, Space, Table } from 'antd';
import logo from './../../assets/images/logo.png';
import { UserService, AuthService, CommonService, Constant } from '../../services/services'
import { GetTableConfigs } from '../../components/customTable/customTable'
import LoadingContext from '../../components/loading/loadingContext'
const { Text } = Typography;

function ForgetPassword(props) {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [userAccountType, setUserAccountType] = useState(0)
    const [showResultScreen, setShowResultScreen] = useState(false)
    const [clubContactDetails, setClubContactDetails] = useState([])
    const [currentUser, setCurrentUser] = useState({})
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)

    const columns = [
        {
            title: t("member.club"),
            dataIndex: 'Name',
            key: 'Name',
            width: 200
        },
        {
            title: t("forget_password.position"),
            dataIndex: 'PositionTitleName',
            key: 'PositionTitleName',
            width: 250
        },
        {
            title: t("forget_password.contact_name"),
            dataIndex: 'MainContactName',
            key: 'MainContactName',
            width: 150
        },
        {
            title: t("family_group.phone"),
            dataIndex: 'MainContactPhone',
            key: 'MainContactPhone',
            width: 120
        },
        {
            title: t("member.email"),
            dataIndex: 'MainContactEmail',
            key: 'MainContactEmail',
            width: 150
        }
    ]

    const onForgetPassword = (data) => {
        showLoading()

        Promise.all([
            AuthService.forgotPassword(data.Username, userAccountType == 0),
            UserService.getClubContactDetails(data.Username, userAccountType == 0)
        ])
            .finally(() => dismissLoading())
            .then(response => {
                if (response[0] && response[0].data) {
                    setCurrentUser(response[0].data)
                    setShowResultScreen(true)
                } else {
                    CommonService.presentToast("warning", t("forget_password.user_not_found"))
                }

                if (response[1] && response[1].data) {
                    setClubContactDetails(response[1].data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    };

    const goToLogin = () => {
        history.push(Constant.Pages.Login)
    }

    useEffect(() => {
        if (showResultScreen) {
            let timeoutFn = setTimeout(() => {
                document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight())
            }, 100)
        }

    }, [showResultScreen])

    return (
        <div className={!showResultScreen ? `forget-password-page` : 'forget-password-result-page'}>
            <div style={{ 'display': showResultScreen && 'none' }}>
                <Space direction="vertical" className='full-width m-b-35' size={0}>
                    <img src={logo} />
                    <Text className='color-black'>{t("forget_password.instruct_submit_1")}</Text>
                    <div className="button-bar">
                        <button className={"button button-extended" + (userAccountType == 0 ? " button-positive" : "")} onClick={() => setUserAccountType(0)}>{t("forget_password.btn_member_number")}</button>
                        <button className={"button button-extended" + (userAccountType == 1 ? " button-positive" : "")} onClick={() => setUserAccountType(1)}>{t("forget_password.btn_username")}</button>
                    </div>
                    <Text className='color-black'>{t("forget_password.instruct_submit_2")}</Text>
                </Space>

                <Form
                    name="forgetPasswordForm"
                    layout="vertical"
                    initialValues={props.objectData}
                    onFinish={onForgetPassword}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                className='m-b-0'
                                name="Username" colon={false} labelAlign="left"
                                rules={[{ required: true, message: userAccountType == 0 ? t("forget_password.member_number_required") : t("forget_password.username_required") }]}
                                label={userAccountType == 0 ? t("forget_password.lbl_member_number") : t("forget_password.lbl_username")}
                            >
                                <Input size="large" allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={24} className='text-left m-b-10'>
                            <Text className='color-blue'>{userAccountType == 0 ? t("forget_password.user_number_format") : t("forget_password.username_format")}</Text>
                        </Col>
                    </Row>
                    <Space direction="vertical" className='full-width m-t-20'>
                        <Button className='blue-linear-gradient-bg' size="large" htmlType="submit" type="primary" block>
                            {t("forget_password.btn_send_password")}
                        </Button>
                    </Space>
                </Form>
            </div>
            <div style={{ 'display': !showResultScreen && 'none' }}>
                <div id="fixItemsContainerId">
                    <Space direction="vertical" className='full-width m-b-35' size={0}>
                        <img src={logo} />
                        <Text className='color-black'>{t("forget_password.member")} {currentUser ? (userAccountType == 0 ? currentUser.MemberNumber : currentUser.UserName) : ""}</Text>
                        <Text className='color-black'>{t("forget_password.forget_pass_success")} {currentUser ? currentUser.Email : ""}</Text>
                        <Button className='blue-linear-gradient-bg m-t-10' size="large" type="primary" onClick={goToLogin} block>
                            {t("forget_password.return_login")}
                        </Button>
                    </Space>
                    <Space>
                        <Text className='color-black'>{t("forget_password.instruction")}</Text>
                    </Space>
                </div>
                <Table
                    {...GetTableConfigs(columns, clubContactDetails, clubContactDetails.length, "Id", null, null, null, false)}
                />
            </div>
        </div>
    )
}

export default ForgetPassword;
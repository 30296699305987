import React, { useState, useEffect } from 'react';
import { Modal, Tree, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from './../../../utilities/globalState'
import { Constant } from '../../../services/services';

const ZoneList = (props) => {
    const { t } = useTranslation();
    const [zoneData, setZoneData] = useState([]);
    const [selectedZoneIds, setSelectedZoneIds] = useState([]);
    const [treeHeight, setTreeHeight] = useState(300);
    const [currentPosition] = useGlobalState('currentPosition');

    useEffect(() => {
        let bodyHeight = treeHeight
        let bodyElement = document.getElementsByClassName("ant-modal-body");
        if (bodyElement && bodyElement.length > 0) {
            bodyHeight = bodyElement[bodyElement.length - 1].offsetHeight
            setTreeHeight(bodyHeight)
        }
        
        if (props.allZones) {
            let zones = []

            props.allZones.forEach(item => {
                let name = item.Name + " - " + item.Description
                let zoneItem = getTreeItem(name, item.Id, false)
                zones.push(zoneItem)
            });
            setZoneData(zones)

            if (props.zones && props.zones.length > 0) {
                let ids = props.zones.map((n, index) => n.Id)
                setSelectedZoneIds(ids)
            }
        }
    }, [props.visible])

    const getTreeItem = (title, key, disabled, children) => {
        return {
            "title": title,
            "key": key,
            "disabled": disabled,
            "children": children
        };
    }

    const onCheck = (checkedKeys, info) => {
        setSelectedZoneIds(checkedKeys)
    };

    const closeForm = () => {
        props.handleCancel()
    }

    const onSubmit = () => {
        let selectedZones = props.allZones.filter(m => selectedZoneIds.indexOf(m.Id) > -1)

        props.handleCancel(selectedZones)
    }

    return (
        <div>
            <Modal
                title= "Display Zones"
                onOk={onSubmit}
                open={true}
                confirmLoading={false}
                onCancel={closeForm}
                maskClosable={false}
                className="vertical-modal"
            >
                <Tree
                    checkable
                    checkedKeys={selectedZoneIds}
                    onCheck={onCheck}
                    treeData={zoneData}
                    height={treeHeight}
                    key="Id"
                />
            </Modal>
        </div>
    )
}

export default ZoneList

import React, { useState, useEffect, useMemo } from 'react';
import { Constant, CommonService, StorageService, SettingService, FishingActivityService } from '../../../services/services';
import { useTranslation } from 'react-i18next';
import { GetTableConfigs, EditableCell } from '../../../components/customTable/customTable'
import LoadingContext from '../../../components/loading/loadingContext'
import { Row, Col, Table, Button, Typography, Form, Space, Drawer, Empty } from 'antd';
import { useGlobalState } from '../../../utilities/globalState'
import { FilterAltIcon, CloseIcon } from '../../../components/icons/icons';
import ZoneList from '../zoneList/zoneList';
import PubSub from 'pubsub-js'
import EnhancedSelect from '../../../components/enhancedSelect/enhancedSelect';
import BasePointScoreTable from './basePointScoreTable';

const { Title } = Typography;

const defaultGridOption = {
    pageNumber: 1,
    pageSize: null
}

const components = {
    body: {
        cell: EditableCell,
    },
};

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const BasePointScore = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const emptyTableData = {
        ObjectList: [],
        TotalItems: 0
    }
    const [pointScores, setPointScores] = useState([])
    const [pointData, setPointData] = useState(emptyTableData)
    const [gridConfigOptions, setGridConfigOptionsValue] = useState(defaultGridOption)
    const [currentPosition] = useGlobalState('currentPosition');
    const [visibleZoneList, setVisibleZoneList] = useState(false);
    const [visibleFilter, setVisibleFilter] = useState(false);
    const [formFilter] = Form.useForm();
    const [pointFilter, setPointFilter] = useState({ TagCaptureId: props.tagCaptureCode });
    const [zones, setZones] = useState([]);
    const [fishSpecies, setFishSpecies] = useState([]);
    const [lineClasses, setLineClasses] = useState([]);
    const [selectedZones, setSelectedZones] = useState([]);
    const [tagCodes, setTagCodes] = useState([]);


    useEffect(() => {
        if (currentPosition && currentPosition.PositionTypeId != Constant.PositionType.ClubMember) {
            let getPoints;
            let getZones;
            let position = StorageService.getCurrentPosition()
            if (position && position.EntityTypeId == Constant.EntityType.Club) {
                getPoints = SettingService.getClubPointScores
                getZones = SettingService.getClubZones
            } else if (position && position.EntityTypeId == Constant.EntityType.State) {
                getPoints = SettingService.getStatePointScores
                getZones = SettingService.getStatePointScoreZones
            }

            if (getPoints && getZones) {
                showLoading()
                let filter = Object.assign({}, pointFilter)
                filter.GetAllZones = true

                Promise.all([
                    getZones(),
                    getPoints(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, filter),
                    FishingActivityService.getActivityLineExportFilterData(),
                    SettingService.getTagCodes()
                ])
                    .finally(() => dismissLoading())
                    .then(response => {
                        if (response[0] && response[0].data) {
                            let activeZones = response[0].data.filter(z => z.StatusId == Constant.StatusCode.Active)
                            let oobZones = activeZones.filter(z => z.Name.toUpperCase().indexOf("OOB") >= 0)
                            let weipaZones = activeZones.filter(z => z.Name.toUpperCase().indexOf("WEIPA") >= 0)
                            activeZones = activeZones.filter(z => z.Name.toUpperCase().indexOf("OOB") < 0 && z.Name.toUpperCase().indexOf("WEIPA") < 0).sort((z1, z2) => { return z1.Name.toUpperCase() <= z2.Name.toUpperCase() ? -1 : 1 })
                            activeZones.push(...weipaZones)
                            activeZones.push(...oobZones)

                            setZones(activeZones)
                        }

                        if (response[1] && response[1].data) {
                            if (response[1].data.ObjectList) {
                                setPointScores(response[1].data.ObjectList)
                            }
                            populatePointScoreData(response[1].data)
                        }

                        if (response[2] && response[2].data) {
                            setFishSpecies(response[2].data.FishSpecies);
                            setLineClasses(response[2].data.LineClasses);
                        }

                        if (response[3] && response[3].data) {
                            setTagCodes(response[3].data);
                        }
                    })
                    .catch(error => {
                        CommonService.handleErrorResponse(error);
                    })
            }
        }

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);
        return () => {
            SettingService.cancelRequest()
            //clearTimeout(timeoutFn);
        }
    }, [])

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
        }, 100)
    }, [pointData])

    const populatePointScoreData = (pointData) => {
        let pointScoreData = {
            ObjectList: [],
            TotalItems: 0
        }

        if (pointData && pointData.ObjectList) {
            pointScoreData.TotalItems = pointData.TotalItems

            for (let i = 0; i < pointData.ObjectList.length; i++) {
                let scoreData = {
                    Id: pointData.ObjectList[i].Id,
                    FishSpecies: pointData.ObjectList[i].FishSpecies,
                    CapturePointEligible: pointData.ObjectList[i].CapturePointEligible ? 'Y' : 'N',
                    TagPointEligible: pointData.ObjectList[i].TagPointEligible ? 'Y' : 'N',
                    FishSizeBelow1: pointData.ObjectList[i].FishSizeBelow1,
                    FishSizeOver1: pointData.ObjectList[i].FishSizeOver1,
                    Timing: pointData.ObjectList[i].Timing,
                    TagCodeId: pointData.ObjectList[i].TagCodeId
                }

                if (pointData.ObjectList[i].ZonePointScores) {
                    let scoreZones = pointData.ObjectList[i].ZonePointScores
                    let oobZones = scoreZones.filter(z => z.ZoneName.toUpperCase().indexOf("OOB") >= 0)
                    let weipaZones = scoreZones.filter(z => z.ZoneName.toUpperCase().indexOf("WEIPA") >= 0)
                    scoreZones = scoreZones.filter(z => z.ZoneName.toUpperCase().indexOf("OOB") < 0 && z.ZoneName.toUpperCase().indexOf("WEIPA") < 0).sort((z1, z2) => { return z1.ZoneName.toUpperCase() <= z2.ZoneName.toUpperCase() ? -1 : 1 })
                    scoreZones.push(...weipaZones)
                    scoreZones.push(...oobZones)

                    for (let j = 0; j < scoreZones.length; j++) {
                        scoreData.ZoneName = scoreZones[j].ZoneName

                        if (scoreZones[j].LineClassPoints) {
                            for (let k = 0; k < scoreZones[j].LineClassPoints.length; k++) {
                                let key = scoreZones[j].ZoneId + "-" + scoreZones[j].LineClassPoints[k].LineClassId
                                scoreData[key + "-LineWeight"] = scoreZones[j].LineClassPoints[k].LineWeight
                                scoreData[key + "-Point"] = scoreZones[j].LineClassPoints[k].Point
                                scoreData[key + "-Id"] = scoreZones[j].LineClassPoints[k].Id
                            }
                        }
                    }
                }

                pointScoreData.ObjectList.push(scoreData)
            }
        }

        setPointData(pointScoreData)
    }

    const getPointScores = (pageNumber, pageSize, filter, sort) => {
        showLoading()
        if (!filter) {
            filter = { ...pointFilter }
        }

        let getPoints;
        if (currentPosition && currentPosition.EntityTypeId == Constant.EntityType.Club) {
            getPoints = SettingService.getClubPointScores
        } else if (currentPosition && currentPosition.EntityTypeId == Constant.EntityType.State) {
            getPoints = SettingService.getStatePointScores
        }

        if (getPoints) {
            getPoints(pageNumber, pageSize, sort, filter)
                .finally(() => dismissLoading())
                .then(result => {
                    if (result.data) {
                        if (result.data.ObjectList) {
                            setPointScores(result.data.ObjectList)
                        }
                        populatePointScoreData(result.data)
                    }
                })
        }
    }

    const handleOnRecordChange = (value, record, dataIndex) => {
        if(record && dataIndex == "TagCodeId") {
            let data = {
                Id: record.Id,
                TagCodeId: value,
            }
            showLoading()
            SettingService.UpdateFishSpeciesTagCode(data)
                .finally(() => dismissLoading())
                .then(() => {
                    showLoading()
                    SettingService.getStatePointScores(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, pointFilter)
                        .finally(() => dismissLoading())
                        .then(result => {
                            if (result.data && result.data.ObjectList) {
                                setPointScores(result.data.ObjectList)
                            }
                            populatePointScoreData(result.data)
                        })
                        .catch(error => CommonService.handleErrorResponse(error))
                })
                .catch(error => CommonService.handleErrorResponse(error))
        }
        else if (record && record[dataIndex] != value) {
            let ids = dataIndex.split("-");

            let data = {
                Id: record[dataIndex.replace("Point", "Id")],
                Point: value,
                ZoneId: ids[0],
                LineClassId: ids[1],
                TagCodeId: record.TagCodeId,
                TagCaptureId: props.tagCaptureCode
            }

            SettingService.updateStatePointScore(data)
                .finally(() => dismissLoading())
                .then(() => {
                    showLoading()
                    SettingService.getStatePointScores(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, pointFilter)
                        .finally(() => dismissLoading())
                        .then(result => {
                            if (result.data && result.data.ObjectList) {
                                setPointScores(result.data.ObjectList)
                            }
                            populatePointScoreData(result.data)
                        })
                        .catch(error => CommonService.handleErrorResponse(error))
                })
                .catch(error => CommonService.handleErrorResponse(error))

        }
    }

    const getColumns = (data) => {
        let columns = [
            {
                title: t('point_score.entity_point_score_zone', { entity_acronym: currentPosition.EntityAcronym }),
                fixed: 'left',
                children: [
                    {
                        title: t("point_score.fish_species_line_class"),
                        dataIndex: 'FishSpecies',
                        key: 'FishSpecies',
                        fixed: 'left',
                        width: 200
                    }
                ]
            },
            {
                title: '',
                fixed: 'left',
                children: [
                    {
                        title: t('point_score.tag_code'),
                        key: "TagCodeId",
                        fixed: 'left',
                        dataIndex: "TagCodeId",
                        width: 150,
                        selectionKey: "Id",
                        selectionDisplay: "Name",
                        validateAtStart: true,
                        handleOnChange: handleOnRecordChange,
                        onCell: (record) => ({
                            record,
                            editable: true,   
                            extraData: tagCodes,   
                            key: "TagCodeId",
                            dataIndex: "TagCodeId",              
                            title: t('point_score.tag_code'),
                            cellType: Constant.CellType.EnhancedSelect,
                            handleOnChange: handleOnRecordChange,
                            selectionKey: "Id",
                            selectionDisplay: "Name",
                            validateAtStart: true,
                            allowClear: false,                      
                        })
                    },
                ]
            },
            {
                title: '',
                fixed: 'left',
                hidden: props.tagCaptureCode == Constant.TagCaptureCode.T_R,
                children: [
                    {
                        title: t("point_score.capture_point_eligible"),
                        dataIndex: 'CapturePointEligible',
                        key: 'CapturePointEligible',
                        width: 80,
                        fixed: 'left',
                        align: 'center'
                    }
                ]
            },
            {
                title: '',
                fixed: 'left',
                children: [
                    {
                        title: t("point_score.tag_point_eligible"),
                        dataIndex: 'TagPointEligible',
                        key: 'TagPointEligible',
                        width: 80,
                        fixed: 'left',
                        align: 'center'
                    }
                ]
            },
            {
                title: t("point_score.fish_size"),
                align: 'center',
                fixed: 'left',
                children: [
                    {
                        title: t("point_score.size_factor_below_1"),
                        dataIndex: 'FishSizeBelow1',
                        key: 'FishSizeBelow1',
                        width: 75,
                        fixed: 'left',
                        align: 'center'
                    },
                    {
                        title: t("point_score.size_factor_over_1"),
                        dataIndex: 'FishSizeOver1',
                        key: 'FishSizeOver1',
                        width: 75,
                        fixed: 'left',
                        align: 'center'
                    }
                ]
            },
            {
                title: t("point_score.timing"),
                align: 'center',
                fixed: 'left',
                children: [
                    {
                        title: t("point_score.months_after_capture"),
                        dataIndex: 'Timing',
                        key: 'Timing',
                        width: 100,
                        fixed: 'left',
                        align: 'center'
                    }
                ]
            }
        ]

        columns = columns.filter(c => !c.hidden)

        if (pointScores && pointScores.length > 0) {
            let pointScore = pointScores[0]
            if (pointScore.ZonePointScores) {
                let scoreZones = pointScore.ZonePointScores
                let oobZones = scoreZones.filter(z => z.ZoneName.toUpperCase().indexOf("OOB") >= 0)
                let weipaZones = scoreZones.filter(z => z.ZoneName.toUpperCase().indexOf("WEIPA") >= 0)
                scoreZones = scoreZones.filter(z => z.ZoneName.toUpperCase().indexOf("OOB") < 0 && z.ZoneName.toUpperCase().indexOf("WEIPA") < 0).sort((z1, z2) => { return z1.ZoneName.toUpperCase() <= z2.ZoneName.toUpperCase() ? -1 : 1 })
                scoreZones.push(...weipaZones)
                scoreZones.push(...oobZones)

                for (let i = 0; i < scoreZones.length; i++) {
                    let column = {
                        title: scoreZones[i].ZoneName,
                        editable: true
                    }

                    if (scoreZones[i].LineClassPoints) {
                        let children = []
                        for (let j = 0; j < scoreZones[i].LineClassPoints.length; j++) {
                            children.push({
                                title: scoreZones[i].LineClassPoints[j].LineWeight,
                                dataIndex: scoreZones[i].ZoneId + "-" + scoreZones[i].LineClassPoints[j].LineClassId + "-Point",
                                key: scoreZones[i].ZoneId + "-" + scoreZones[i].LineClassPoints[j].LineClassId + "-Point",
                                width: 70,
                                align: 'center',
                                onCell: (record) => ({
                                    record,
                                    editable: true,
                                    key: scoreZones[i].ZoneId + "-" + scoreZones[i].LineClassPoints[j].LineClassId + "-Point",
                                    dataIndex: scoreZones[i].ZoneId + "-" + scoreZones[i].LineClassPoints[j].LineClassId + "-Point",
                                    title: scoreZones[i].LineClassPoints[j].LineWeight,
                                    cellType: Constant.CellType.ToggleNumberInput,
                                    handleOnChange: handleOnRecordChange,
                                    minValue: 0,
                                    tooltipText: t("point_score.click_to_edit")
                                }),
                            })
                        }

                        column.children = children
                    }

                    columns.push(column)
                }
            }
        }

        return columns
    }

    const getTable = (data) => {
        return (
            <BasePointScoreTable columns={getColumns()} pointData={pointData} />
        )
    }

    const pointTable = useMemo(() => getTable(pointData), [pointData]);

    const onTableChange = (pagination, filters, sorter, extra) => {
        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = defaultGridOption.sort;
        }

        getPointScores(pagination.current, pagination.pageSize, pointFilter, sort)
        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        })
    }

    const showZoneList = () => {
        setVisibleZoneList(true);
    }

    const handleZoneListCancel = (objectData) => {
        setVisibleZoneList(false)
        if (objectData) {
            setSelectedZones(objectData)

            setGridConfigOptionsValue({
                ...gridConfigOptions,
                pageNumber: 1
            })

            let filter = Object.assign({}, pointFilter)
            filter.ZoneIds = objectData.map(z => z.Id).join(",")
            setPointFilter(filter)

            getPointScores(1, gridConfigOptions.pageSize, filter, gridConfigOptions.sort)
        }
    }

    const getTitle = () => {
        if (currentPosition && currentPosition.EntityTypeId == Constant.EntityType.Club) {
            return props.clubTitle
        } else if (currentPosition && currentPosition.EntityTypeId == Constant.EntityType.State) {
            return props.stateTitle
        }

        return ""
    }

    const showFilterLayout = () => {
        setVisibleFilter(true);
    }

    const closeFilterLayout = () => {
        formFilter.setFieldsValue(pointFilter);
        setVisibleFilter(false);
    }

    const resetFilter = () => {
        formFilter.resetFields();
        setPointFilter({ TagCaptureId: props.tagCaptureCode });
    }

    const applyFilter = () => {
        setVisibleFilter(false);
        let filter = formFilter.getFieldsValue();
        filter.TagCaptureId = props.tagCaptureCode
        setPointFilter(filter)
        setGridConfigOptionsValue({
            ...gridConfigOptions,
            pageNumber: 1
        })
        getPointScores(1, gridConfigOptions.pageSize, filter, gridConfigOptions.sort)
    }

    return (
        <>
            {
                currentPosition && currentPosition.PositionTypeId != Constant.PositionType.ClubMember &&


                <div>
                    <div id={Constant.FixItemsContainerId}>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-b-10'>
                            <Col xs={{ span: 24 }}>
                                <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                    <Title className='color-dark-blue' level={5}>{getTitle()}</Title>
                                </Space>
                            </Col>
                        </Row>

                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                            <Col xs={{ span: 24 }} className="text-right">
                                <Button onClick={showFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon />}>{t("common.filter")}</Button>
                            </Col>
                        </Row>
                    </div>

                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            {pointTable}
                        </Col>
                    </Row>

                    {
                        visibleZoneList &&
                        <ZoneList handleCancel={handleZoneListCancel} visible={visibleZoneList} allZones={zones} zones={selectedZones}></ZoneList>
                    }

                    <Drawer
                        title={t("common.filter")}
                        width={478}
                        onClose={closeFilterLayout}
                        open={visibleFilter}
                        closeIcon={false}
                        styles={{
                            body: {
                                paddingBottom: 80,
                            }
                        }}
                        extra={
                            <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                                <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                                <Button onClick={closeFilterLayout} icon={<CloseIcon />} type="link" />
                            </Space>
                        }
                        footer={
                            <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                                <Button onClick={closeFilterLayout}>{t("common.cancel")}</Button>
                                <Button onClick={applyFilter} type="primary">
                                    {t("common.apply")}
                                </Button>
                            </Space>
                        }>
                        <Form
                            layout="vertical"
                            {...layout}
                            form={formFilter}
                            name="formFilter"
                            key='formFilter'
                        >
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="ZoneIds"
                                        label={t("point_score.display_zones")}
                                    >
                                        <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={zones ? zones.map(b => {
                                                return {
                                                    label: b.Name + " - " + b.Description,
                                                    value: b.Id
                                                }
                                            }) : []}
                                        >
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="FishSpeciesIds"
                                        label={t("fishing_activity.species")}
                                    >
                                        <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={fishSpecies ? fishSpecies.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}
                                        >
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="LineClassIds"
                                        label={t("fishing_activity.line_class")}
                                    >
                                        <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={lineClasses ? lineClasses.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}
                                        >
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Drawer>
                </div>
            }
            {
                currentPosition && currentPosition.PositionTypeId == Constant.PositionType.ClubMember &&
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_access")} />
            }
        </>
    )
}

export default BasePointScore;
import React, { useEffect, useState } from 'react';
import './activityEntriesManagement.scss';
import { useTranslation } from 'react-i18next';
import {
    Constant,
    CommonService,
    FishingActivityService,
    StorageService
} from '../../../services/services'
import { Table, Button, Row, Col, Select, Form, Input, Tabs, Modal, Drawer, Space, DatePicker, Dropdown, InputNumber, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { GetTableConfigs, ActionButtonsCell, EditableCell } from '../../../components/customTable/customTable'
import { FilterAltIcon, CloseIcon } from '../../../components/icons/icons'
import LoadingContext from '../../../components/loading/loadingContext'
import EditActivityEntry from './editActivityEntry';
import PubSub from 'pubsub-js';
import { useGlobalState } from '../../../utilities/globalState'
import { EditIcon, EyeIcon } from '../../../components/icons/icons';
import TagNumber from '../../../components/tagNumber/tagNumber';
import EnhancedSelect from '../../../components/enhancedSelect/enhancedSelect';
import { useHistory } from "react-router-dom";

const { confirm } = Modal;
const { TabPane } = Tabs;
const _ = require("lodash");
const { Title } = Typography;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const ActivityEntriesManagement = (props) => {
    const { t } = useTranslation();
    const [formFilter] = Form.useForm();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext);
    const [totalItems, setTotalItems] = useState(0);
    const [boats, setBoats] = useState([]);
    const [activityLines, setActivityLines] = useState([]);
    const [approvalStatuses, setApprovalStatuses] = useState([]);
    const [tagCaptures, setTagCaptures] = useState([]);
    const [fishingMethods, setFishingMethods] = useState([]);
    const [anglers, setAnglers] = useState([]);
    const [fishSpecies, setFishSpecies] = useState([]);
    const [lineClasses, setLineClasses] = useState([]);
    const [financialYears, setFinancialYears] = useState([]);
    const [visibleModal, setVisibleModal] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [searchActivityLines, setSearchActivityLines] = useState();
    const [entities, setEntities] = useState([]);
    const [createdBys, setCreatedBys] = useState([]);
    const [selectedActivityLine, setSelectedActivityLine] = useState([]);
    const [currentUser, setCurrentUser] = useState();
    const history = useHistory();

    const [gridConfigOptions, setGridConfigOptionsValue] = useState({
        pageNumber: 1,
        pageSize: CommonService.getPageSize(),
        sort: "ActivityDate-descend",
    })
    const [currentPosition] = useGlobalState('currentPosition');

    const search = (pageNumber, pageSize, sort, query, isShowLoading = false) => {
        if (isShowLoading) {
            showLoading();
        }

        let params = { ...query };
        if (!params) params = { MemberStatusIds: [Constant.MemberStatusCode.Active] };

        if (params.FinancialEnd) {
            params.FinancialEnd = params.FinancialEnd.format(Constant.DateFormatYYYMMDD);
        }

        FishingActivityService.getAllActivityLines(pageNumber, pageSize, sort, params)
            .finally(() => {
                if (isShowLoading) {
                    dismissLoading();
                }
            })
            .then(result => {
                if (result.data) {
                    setActivityLines(result.data.ObjectList);
                    setTotalItems(result.data.TotalItems);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const exportActivityEntriesReport = (fileType) => {
        showLoading();
        FishingActivityService.getExportActivityLines(gridConfigOptions.sort, formFilter.getFieldsValue(), fileType)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    if (fileType == Constant.FileType.Excel) {
                        const type = result.headers['content-type']
                        const blob = new Blob([result.data], { type: type })
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = `Activity_Entries_List.xlsx`
                        link.click()
                    } else if (fileType == Constant.FileType.Csv) {
                        const type = result.headers['content-type']
                        const blob = new Blob([result.data], { type: type })
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = `Activity_Entries_List.csv`
                        link.click()
                    }
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    const handleExport = (e) => {
        exportActivityEntriesReport(e.key)
    };

    const exportItems = [
        {
            label: t("common.excel"),
            key: Constant.FileType.Excel
        },
        {
            label: t("common.csv"),
            key: Constant.FileType.Csv
        }
    ];
    const menuExport = {
        items: exportItems,
        onClick: handleExport
    };

    const editActivityLine = (record) => {
        // setIsEdit(true);
        // setSelectedActivityLine(record);
        // setVisibleModal(true);
        if (record) {
            history.push(Constant.Pages.CreateDayLog, {
                DayLogId: record.ActivityHeaderId,
                GridConfigOptions: gridConfigOptions,
                Filter: formFilter.getFieldsValue(),
                PreviousUrl: Constant.Pages.ActivityEntriesList,
                ViewOnly: false
            });
        }
    }

    const viewActivityLine = (record) => {
        // setIsEdit(true);
        // setSelectedActivityLine(record);
        // setVisibleModal(true);
        if (record) {
            history.push(Constant.Pages.CreateDayLog, {
                DayLogId: record.ActivityHeaderId,
                GridConfigOptions: gridConfigOptions,
                Filter: formFilter.getFieldsValue(),
                PreviousUrl: Constant.Pages.ActivityEntriesList,
                ViewOnly: true
            });
        }
    }

    const deleteActivityLine = (record) => {
        if (!record.IsAddNew && record.Id > 0) {
            confirm({
                title: t("fishing_activity.delete_activity_line_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    showLoading();

                    FishingActivityService.deleteActivityLines([record.Id])
                        .finally(() => {
                            dismissLoading();
                        })
                        .then(result => {
                            const newActivityLines = activityLines.filter((m) => m.Id !== record.Id);
                            setActivityLines(newActivityLines);
                        })
                        .catch(error => {
                            CommonService.handleErrorResponse(error);
                        });
                }
            });
        } else {
            const newActivityLines = activityLines.filter((m) => m.Id !== record.Id);
            setActivityLines(newActivityLines);
        }
    }

    const handleCancel = (refresh) => {
        setVisibleModal(false);
        if (refresh) {
            refreshData();
        }
    }

    const visibleViewBtn = (item) => {
        if (!currentPosition || !item || currentPosition.PositionTypeId != Constant.PositionType.ClubMember) {
            return false
        }
        if (item.CreationBy == currentUser && item.ClubApprovalId) {
            return true
        }
        if (!item.CanView)
            return false
        return false
    }

    const getActionButtonsInfo = (item) => {
        return [
            {
                Description: t("common.edit"),
                Icon: <EditIcon />,
                ColorClass: Constant.ColorClass.LightBlue,
                Hide: currentPosition && currentPosition.PositionTypeId == Constant.PositionType.ClubMember && item && item.ClubApprovalId,
                handleAction: editActivityLine
            },
            {
                Description: t("common.view"),
                Icon: <EyeIcon />,
                ColorClass: Constant.ColorClass.LightBlue,
                Hide: currentPosition && (currentPosition.PositionTypeId != Constant.PositionType.ClubMember || (currentPosition.PositionTypeId == Constant.PositionType.ClubMember && item && !item.ClubApprovalId)),
                handleAction: viewActivityLine
            }
        ]
    }
    const isClub = () => {
        return currentPosition != null && currentPosition.EntityTypeId === Constant.EntityType.Club;
    }

    const stateColumns = [
        {
            title: t('common.action'),
            render: (value, record) => {
                return <ActionButtonsCell btnList={getActionButtonsInfo(record)} data={record} />
            },
            align: "center",
            width: 80,
            fixed: 'left',
        },
        {
            title: t('fishing_activity.day_log_id'),
            dataIndex: 'DayLogId',
            key: 'DayLogId',
            sorter: true,
            width: 170,
            fixed: 'left',
        },
        {
            title: t('member.club'),
            dataIndex: 'ClubAcronym',
            key: 'ClubAcronym',
            sorter: true,
            width: 100,
            fixed: 'left',

        },
        {
            title: t('fishing_activity.asn'),
            dataIndex: 'ActivitySheetNumber',
            key: 'ActivitySheetNumber',
            sorter: true,
            width: 100,
            fixed: 'left',
        },
        {
            title: t('fishing_activity.date'),
            dataIndex: 'ActivityDate',
            key: 'ActivityDate',
            defaultSortOrder: 'descend',
            sorter: true,
            fixed: 'left',
            render: (value, record) => {
                if (record.ActivityDate) {
                    let date = new Date(record.ActivityDate)
                    var day = date.getDate();
                    var month = date.getMonth() + 1; //Month from 0 to 11
                    var year = date.getFullYear();

                    return `${day}/${month}/${year}`
                }

                return null
            },
            width: 100
        },
        {
            title: t('fishing_activity.boat_name'),
            dataIndex: 'BoatName',
            key: 'BoatName',
            sorter: true,
            width: 120,
            fixed: 'left',
        },
        {
            title: t('fishing_activity.tag_capt'),
            dataIndex: 'TagCaptureName',
            key: 'TagCaptureName',
            sorter: true,
            width: 120,
        },
        {
            title: t('fishing_activity.method'),
            dataIndex: 'FishingMethodId',
            key: 'FishingMethodId',
            sorter: true,
            width: 100,
        },
        {
            title: t('fishing_activity.time'),
            dataIndex: 'CaughtTime',
            key: 'CaughtTime',
            sorter: true,
            width: 100,
        },
        {
            title: t('fishing_activity.species'),
            dataIndex: 'FishSpeciesName',
            key: 'FishSpeciesName',
            sorter: true,
            width: 100,
        },
        {
            title: t('fishing_activity.tag_number'),
            dataIndex: 'TagNumber',
            key: 'TagNumber',
            sorter: false,
            width: 120,
        },
        {
            title: t('fishing_activity.weight'),
            dataIndex: 'FishWeight',
            key: 'FishWeight',
            sorter: false,
            width: 110,
        },
        {
            title: t('fishing_activity.line_class'),
            dataIndex: 'LineClassName',
            key: 'LineClassName',
            sorter: false,
            width: 100,
        },
        {
            title: t('fishing_activity.angler'),
            dataIndex: 'AnglerName',
            key: 'AnglerName',
            sorter: false,
            width: 160,
        },
        {
            title: t('fishing_activity.club_points'),
            dataIndex: 'ClubPoints',
            key: 'ClubPoints',
            sorter: false,
            ellipsis: true,
            width: 110,
        },
        {
            title: t('fishing_activity.club_approval'),
            dataIndex: 'ClubApprovalName',
            key: 'ClubApprovalName',
            sorter: false,
            ellipsis: true,
            width: 130,
        },
        {
            title: t('fishing_activity.state_points'),
            dataIndex: 'StatePoints',
            key: 'StatePoints',
            sorter: false,
            ellipsis: true,
            width: 110,
        },
        {
            title: t('fishing_activity.state_approval'),
            dataIndex: 'StateApprovalName',
            key: 'StateApprovalName',
            sorter: false,
            ellipsis: true,
            width: 130,
        }
    ]

    const stateColumnsMapping = stateColumns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                disabled: col.disabled,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition,
                selectionKey: col.selectionKey,
                selectionDisplay: col.selectionDisplay,
                validateAtStart: col.validateAtStart,
                allowClear: col.allowClear
            }),
        };
    })

    const clubColumns = [
        {
            title: t('common.action'),
            render: (value, record) => {
                return <ActionButtonsCell btnList={getActionButtonsInfo(record)} data={record} />
            },
            align: "center",
            width: 80,
            fixed: 'left',
        },
        {
            title: t('fishing_activity.day_log_id'),
            dataIndex: 'DayLogId',
            key: 'DayLogId',
            sorter: true,
            defaultSortOrder: 'ascend',
            width: 170,
            fixed: 'left',
        },
        {
            title: t('fishing_activity.asn'),
            dataIndex: 'ActivitySheetNumber',
            key: 'ActivitySheetNumber',
            sorter: true,
            width: 100,
            fixed: 'left',
        },
        {
            title: t('fishing_activity.date'),
            dataIndex: 'ActivityDate',
            key: 'ActivityDate',
            sorter: true,
            fixed: 'left',
            render: (value, record) => {
                if (record.ActivityDate) {
                    let date = new Date(record.ActivityDate)
                    var day = date.getDate();
                    var month = date.getMonth() + 1; //Month from 0 to 11
                    var year = date.getFullYear();

                    return `${day}/${month}/${year}`
                }

                return null
            },
            width: 50
        },
        {
            title: t('fishing_activity.boat_name'),
            dataIndex: 'BoatName',
            key: 'BoatName',
            sorter: true,
            width: 120,
            fixed: 'left',
        },
        {
            title: t('fishing_activity.tag_capt'),
            dataIndex: 'TagCaptureName',
            key: 'TagCaptureName',
            sorter: true,
            width: 110,
        },
        {
            title: t('fishing_activity.method'),
            dataIndex: 'FishingMethodCode',
            key: 'FishingMethodCode',
            sorter: true,
            width: 110,
        },
        {
            title: t('fishing_activity.time'),
            dataIndex: 'CaughtTime',
            key: 'CaughtTime',
            sorter: true,
            width: 80,
        },
        {
            title: t('fishing_activity.species'),
            dataIndex: 'FishSpeciesName',
            key: 'FishSpeciesName',
            sorter: true,
            width: 180,
        },
        {
            title: t('fishing_activity.tag_number'),
            dataIndex: 'TagNumber',
            key: 'TagNumber',
            sorter: false,
            width: 120,
        },
        {
            title: t('fishing_activity.weight'),
            dataIndex: 'FishWeight',
            key: 'FishWeight',
            sorter: false,
            width: 110,
        },
        {
            title: t('fishing_activity.line_class'),
            dataIndex: 'LineClassName',
            key: 'LineClassName',
            sorter: false,
            width: 100,
        },
        {
            title: t('fishing_activity.angler'),
            dataIndex: 'AnglerName',
            key: 'AnglerName',
            sorter: false,
            width: 160,
        },
        {
            title: t('fishing_activity.club_points'),
            dataIndex: 'ClubPoints',
            key: 'ClubPoints',
            sorter: false,
            ellipsis: true,
            width: 110,
        },
        {
            title: t('fishing_activity.club_approval'),
            dataIndex: 'ClubApprovalName',
            key: 'ClubApprovalName',
            sorter: false,
            ellipsis: true,
            width: 130,
        },
        {
            title: t('fishing_activity.state_points'),
            dataIndex: 'StatePoints',
            key: 'StatePoints',
            sorter: false,
            ellipsis: true,
            width: 110,
        },
        {
            title: t('fishing_activity.state_approval'),
            dataIndex: 'StateApprovalName',
            key: 'StateApprovalName',
            sorter: false,
            ellipsis: true,
            width: 130,
        }
    ]

    const clubColumnsMapping = clubColumns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                disabled: col.disabled,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition,
                selectionKey: col.selectionKey,
                selectionDisplay: col.selectionDisplay,
                validateAtStart: col.validateAtStart,
                allowClear: col.allowClear
            }),
        };
    })

    const getColumnMapping = () => {
        if (isClub()) {
            return clubColumnsMapping;
        } else {
            return stateColumnsMapping
        }
    }


    const expandedRowRender = (record, index, indent, expanded) => {
        return <Tabs defaultActiveKey="1">
            <TabPane tab={t("fishing_activity.fish_info")} key="fish_info">
                <Row>
                    <Col xs={{ span: 24 }}>
                        {_getTableFishInfo(record)}
                    </Col>
                </Row>
            </TabPane>
            <TabPane tab={t("fishing_activity.club_approval")} key="club_approval">
                <Row>
                    <Col xs={{ span: 24 }}>
                        {_getTableClubApproval(record)}
                    </Col>
                </Row>
            </TabPane>
            <TabPane tab={t("fishing_activity.state_approval")} key="state_approval">
                <Row>
                    <Col xs={{ span: 24 }}>
                        {_getTableStateApproval(record)}
                    </Col>
                </Row>
            </TabPane>
        </Tabs>
    }

    const _getTableFishInfo = (activityEntry) => {
        var columns = [
            {
                title: t('fishing_activity.tag_number'),
                dataIndex: 'TagNumber',
                key: 'TagNumber',
                sorter: false,
                ellipsis: true,
                width: 100,
            },
            {
                title: t('fishing_activity.weight'),
                dataIndex: 'FishWeight',
                key: 'FishWeight',
                sorter: false,
                ellipsis: true,
                width: 80,
            },
            {
                title: t('fishing_activity.line_class'),
                dataIndex: 'LineClassName',
                key: 'LineClassName',
                sorter: false,
                ellipsis: true,
                width: 80,
            },
            {
                title: t('fishing_activity.angler'),
                dataIndex: 'AnglerName',
                key: 'AnglerName',
                sorter: false,
                ellipsis: true,
                width: 50,
            }
        ]
        return <Table className='custom-activity-entry-table' columns={columns} dataSource={[activityEntry]} pagination={false} rowKey="Id" />
    }

    const _getTableClubApproval = (activityEntry) => {
        var columns = [
            {
                title: t('fishing_activity.club_points'),
                dataIndex: 'ClubPoints',
                key: 'ClubPoints',
                sorter: false,
                ellipsis: true,
                width: 80,
            },
            {
                title: t('fishing_activity.club_approval'),
                dataIndex: 'ClubApprovalName',
                key: 'ClubApprovalName',
                sorter: false,
                ellipsis: true,
                width: 60,
            },
        ]
        return <Table className='custom-activity-entry-table' columns={columns} dataSource={[activityEntry]} pagination={false} rowKey="Id" />
    }


    const _getTableStateApproval = (activityEntry) => {
        var columns = [
            {
                title: t('fishing_activity.state_points'),
                dataIndex: 'StatePoints',
                key: 'StatePoints',
                sorter: false,
                ellipsis: true,
                width: 80,
            },
            {
                title: t('fishing_activity.state_approval'),
                dataIndex: 'StateApprovalName',
                key: 'StateApprovalName',
                sorter: false,
                ellipsis: true,
                width: 60,
            },
        ]
        return <Table className='custom-activity-entry-table' columns={columns} dataSource={[activityEntry]} pagination={false} rowKey="Id" />
    }

    const onTableChange = (pagination, filters, sorter, extra) => {
        let data = formFilter.getFieldsValue();
        setSearchActivityLines(data);

        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = gridConfigOptions.sort;
        }
        search(pagination.current, pagination.pageSize, sort, data);

        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        });
    }

    const showFilterLayout = () => {
        setOpenFilter(true);
    }

    const closeFilterLayout = () => {
        formFilter.setFieldsValue(searchActivityLines);
        setOpenFilter(false);
    }

    const refreshData = (isShowLoading = false) => {

        let data = formFilter.getFieldsValue();
        setSearchActivityLines(data);

        search(1, gridConfigOptions.pageSize, gridConfigOptions.sort, data, isShowLoading);

        setGridConfigOptionsValue(
            {
                ...gridConfigOptions,
                pageNumber: 1
            }
        );

    }

    const resetFilter = () => {
        formFilter.resetFields();
        setSearchActivityLines();
    }

    const applyFilter = () => {

        refreshData(true);

        setOpenFilter(false);
    }

    const components = {
        body: {
            cell: EditableCell,
        }
    };

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
        }, 100)

        let authData = StorageService.getAuthData()
        if (authData && authData.name) {
            setCurrentUser(authData.name)
        }

        // if (history.location && history.location.state) {
        //     if (history.location.state.Filter) {
        //         let filter = history.location.state.Filter
        //         setOpenFilter(history.location.state.Filter)
        //         formFilter.setFieldsValue(filter)
        //     }

        //     if (history.location.state.GridConfigOptions) {
        //         setGridConfigOptionsValue(history.location.state.GridConfigOptions)
        //     }
        // }

        if (history.location && history.location.state
            && history.location.state.GridConfigOptions
            && history.location.state.Filter) {
            setGridConfigOptionsValue(history.location.state.GridConfigOptions)
            formFilter.setFieldsValue(history.location.state.Filter)

            showLoading();
            Promise.all([
                FishingActivityService.getAllActivityLines(history.location.state.GridConfigOptions.pageNumber, history.location.state.GridConfigOptions.pageSize, history.location.state.GridConfigOptions.sort, history.location.state.Filter),
                FishingActivityService.getActivityLineExportFilterData()
            ])
                .finally(() => {
                    dismissLoading();
                    setSearchActivityLines();
                })
                .then((response) => {
                    if (response[0].data) {
                        setActivityLines(response[0].data.ObjectList);
                        setTotalItems(response[0].data.TotalItems);
                    }

                    if (response[1].data) {
                        setBoats(response[1].data.Boats);
                        setTagCaptures(response[1].data.TagCaptures);
                        setFishingMethods(response[1].data.FishingMethods);
                        setAnglers(response[1].data.Anglers);
                        setFishSpecies(response[1].data.FishSpecies);
                        setLineClasses(response[1].data.LineClasses);
                        setFinancialYears(response[1].data.FinancialYears);
                        setApprovalStatuses(response[1].data.ApprovalStatuses);
                        setEntities(response[1].data.Entities);
                        setCreatedBys(response[1].data.CreatedBys);
                    }
                })
                .catch(error => {
                    CommonService.handleErrorResponse(error);
                });
        } else {
            showLoading();
            Promise.all([
                FishingActivityService.getAllActivityLines(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort),
                FishingActivityService.getActivityLineExportFilterData()
            ])
                .finally(() => {
                    dismissLoading();
                    setSearchActivityLines();
                })
                .then((response) => {
                    if (response[0].data) {
                        setActivityLines(response[0].data.ObjectList);
                        setTotalItems(response[0].data.TotalItems);
                    }

                    if (response[1].data) {
                        setBoats(response[1].data.Boats);
                        setTagCaptures(response[1].data.TagCaptures);
                        setFishingMethods(response[1].data.FishingMethods);
                        setAnglers(response[1].data.Anglers);
                        setFishSpecies(response[1].data.FishSpecies);
                        setLineClasses(response[1].data.LineClasses);
                        setFinancialYears(response[1].data.FinancialYears);
                        setApprovalStatuses(response[1].data.ApprovalStatuses);
                        setEntities(response[1].data.Entities);
                        setCreatedBys(response[1].data.CreatedBys);
                    }
                })
                .catch(error => {
                    CommonService.handleErrorResponse(error);
                });
        }



        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);

        return () => {
            FishingActivityService.cancelRequest();
            clearTimeout(timeoutFn);
        }
    }, [])

    return (
        <div className="activity-entries-management">
            <div id={Constant.FixItemsContainerId}>
                <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                    <Title className='color-dark-blue' level={5}>{t("fishing_activity.list_activity_entries")}</Title>
                </Row>
                <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                    <Col flex={100} className="text-right">
                        <div>
                            <Button onClick={showFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon />}>{t("common.filter")}</Button>
                            <Dropdown className='m-l-10' menu={menuExport} trigger={['click']}>
                                <Button className='btn-outline-blue btn-icon' icon={<DownloadOutlined />}>
                                    {t("common.export")}
                                </Button>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>
            </div>

            <Row className={`${isClub() ? 'm-t-10 club-section-table' : 'm-t-10 state-section-table'}`} gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                <Col xs={{ span: 24 }}>
                    <Table
                        components={components}
                        rowClassName={() => 'editable-row'}
                        {...GetTableConfigs(getColumnMapping(), activityLines, totalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                        scroll={{ x: "max-content", y: "max-content" }}
                    />
                </Col>
            </Row>

            {/* Filter Layout  */}
            <Drawer
                title={t("common.filter")}
                width={478}
                onClose={closeFilterLayout}
                open={openFilter}
                closeIcon={false}
                styles={{
                    body: {
                        paddingBottom: 80,
                    }
                }}
                extra={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                        <Button onClick={closeFilterLayout} icon={<CloseIcon />} type="link" />
                    </Space>
                }
                footer={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button onClick={closeFilterLayout}>{t("common.cancel")}</Button>
                        <Button onClick={applyFilter} type="primary">
                            {t("common.apply")}
                        </Button>
                    </Space>
                }>
                <Form
                    layout="vertical"
                    {...layout}
                    form={formFilter}
                    name="formFilter"
                    key='formFilter'
                    initialValues={{ StatusIds: [Constant.StatusCode.Active] }}
                >
                    {
                        currentPosition && (currentPosition.PositionTypeId == Constant.PositionType.StateExcutive || currentPosition.PositionTypeId == Constant.PositionType.NationalExecutive) &&
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="ClubIds"
                                    label={t("fishing_activity.club")}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={entities ? entities.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                    }
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="BoatIds"
                                label={t("fishing_activity.boat_name")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={boats ? boats.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="AnglerName"
                                label={t("fishing_activity.angler_name")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="ActivityDateFrom"
                                label={t('fishing_activity.date_from')}
                            >
                                <DatePicker />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="ActivityDateTo"
                                label={t('fishing_activity.date_to')}
                            >
                                <DatePicker />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="DayLogId"
                                label={t("fishing_activity.day_log_id")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="ActivitySheetNumber"
                                label={t("activity_approvals.activity_sheet")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="CreatedBys"
                                label={t("fishing_activity.created_by")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={createdBys ? createdBys.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Name
                                        }
                                    }) : []}>
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="FinancialYearIds"
                                label={t('fishing_activity.financial_year')}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={financialYears ? financialYears.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}>
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="TagCaptureIds"
                                label={t("fishing_activity.tag_capt")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={tagCaptures ? tagCaptures.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="FishingMethodIds"
                                label={t("fishing_activity.method")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={fishingMethods ? fishingMethods.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="FishSpeciesIds"
                                label={t("fishing_activity.species")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={fishSpecies ? fishSpecies.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="TagNumber"
                                label={t("fishing_activity.tag_number")}
                            >
                                <TagNumber mask={"a999999"} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="FishWeight"
                                label={t("fishing_activity.weight")}
                            >
                                <InputNumber precision={2} min={0} max={9999} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="LineClassIds"
                                label={t("fishing_activity.line_class")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={lineClasses ? lineClasses.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="AnglerIds"
                                label={t("fishing_activity.angler")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={anglers ? anglers.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="ClubApprovalIds"
                                label={t("fishing_activity.club_approval")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={approvalStatuses ? approvalStatuses.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="StateApprovalIds"
                                label={t("fishing_activity.state_approval")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={approvalStatuses ? approvalStatuses.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>

            {/* Create/Edit Activity Entry Layout  */}
            {
                visibleModal &&
                <EditActivityEntry
                    handleCancel={handleCancel}
                    isEdit={isEdit}
                    fishSpecies={fishSpecies}
                    lineClasses={lineClasses}
                    approvalStatuses={approvalStatuses}
                    selectedActivityLine={selectedActivityLine} >

                </EditActivityEntry>
            }
        </div>
    )

}

export default ActivityEntriesManagement;
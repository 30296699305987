import React, { useState, useEffect } from 'react';
import { Constant, CommonService, MembershipService, SettingService, FishingActivityService } from '../../../services/services';
import { useTranslation } from 'react-i18next';
import { GetTableConfigs, StatusCell, ActionButtonsCell, EditableCell } from '../../../components/customTable/customTable'
import LoadingContext from '../../../components/loading/loadingContext'
import { FilterAltIcon, CloseIcon } from '../../../components/icons/icons';
import { Row, Col, Table, Button, Typography, Modal, Form, Drawer, Space, Input, Select, InputNumber, Empty, Dropdown } from 'antd';
import { ExclamationCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { useGlobalState } from './../../../utilities/globalState'
import EnhancedSelect from '../../../components/enhancedSelect/enhancedSelect'
import PubSub from 'pubsub-js'

const { Text, Title } = Typography;

const { confirm } = Modal;
const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const defaultGridOption = {
    pageNumber: 1,
    pageSize: CommonService.getPageSize(),
    sort: "Name-ascend"
}

const CapturePointScore = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const emptyTableData = {
        ObjectList: [],
        TotalItems: 0
    }
    const [gridConfigOptions, setGridConfigOptionsValue] = useState(defaultGridOption)
    const [currentPosition] = useGlobalState('currentPosition');
    const [entityDetail, setEntityDetail] = useState({})
    const [capturePointScoreData, setCapturePointScoreData] = useState(emptyTableData)
    const [visibleFilter, setVisibleFilter] = useState(false);
    const [formFilter] = Form.useForm();
    const [pointFilter, setPointFilter] = useState({});
    const [fishSpecies, setFishSpecies] = useState([]);

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
        }, 100)

        showLoading()
        Promise.all([
            SettingService.getCapturePointScores(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, pointFilter),
            SettingService.getStateEntityDetail(),
            FishingActivityService.getActivityLineExportFilterData()
        ])
            .finally(() => dismissLoading())
            .then(response => {
                if (response[0] && response[0].data) {
                    setCapturePointScoreData(response[0].data)
                }

                if (response[1] && response[1].data) {
                    setEntityDetail(response[1].data)
                }

                if (response[2].data) {
                    setFishSpecies(response[2].data.FishSpecies);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);
        return () => {
            SettingService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    const handleOnRecordChange = (value, record, dataIndex) => {
        if(record && dataIndex == "CaptureBase") {
            let data = {
                Id: record.Id,
                Point: value,
            }
            showLoading()
            SettingService.UpdateFishSpeciesCaptureBase(data)
                .finally(() => dismissLoading())
                .then(() => {
                    showLoading()
                    SettingService.getCapturePointScores(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, pointFilter)
                        .finally(() => dismissLoading())
                        .then(result => {
                            if (result.data) {
                                setCapturePointScoreData(result.data)
                            }
                        })
                        .catch(error => CommonService.handleErrorResponse(error))
                })
                .catch(error => CommonService.handleErrorResponse(error))
        }
    }

    const components = {
        body: {
            cell: EditableCell,
        }
    };

    let columns = [
        {
            title: t('point_score.fish_species'),
            dataIndex: 'Name',
            key: 'Name',
            sorter: true,
            width: 150,
            defaultSortOrder: 'ascend'
        },
        {
            title: t('point_score.capture_point_eligible'),
            dataIndex: 'IsCapturePointEligibleText',
            key: 'IsCapturePointEligibleText',
            sorter: true,
            align: 'center',
            width: 130
        },
        {
            title: t('point_score.capture_base'),
            dataIndex: 'CaptureBase',
            key: 'CaptureBase',
            sorter: true,
            align: 'center',
            width: 130,
            onCell: (record) => ({
                record,
                editable: true,
                key: "CaptureBase",
                dataIndex: "CaptureBase",
                title: t('point_score.capture_base'),
                cellType: Constant.CellType.ToggleNumberInput,
                handleOnChange: handleOnRecordChange,
                minValue: 0,
                tooltipText: t("point_score.click_to_edit")
            })
        },
        {
            title: t('point_score.braid'),
            dataIndex: 'Braid',
            key: 'Braid',
            sorter: false,
            align: 'center',
            width: 130
        },
        {
            title: t('point_score.size_factor_only_below_1'),
            dataIndex: 'SizeFactorBelow1',
            key: 'SizeFactorBelow1',
            sorter: false,
            align: 'center',
            width: 130
        },
        {
            title: t('point_score.size_factor_equal_over_1'),
            dataIndex: 'SizeFactorOver1',
            key: 'SizeFactorOver1',
            sorter: false,
            align: 'center',
            width: 140
        },
        {
            title: t('point_score.months_after_capture'),
            dataIndex: 'Timing',
            key: 'Timing',
            sorter: false,
            align: 'center',
            width: 130,
        }

    ]

    const getCapturePointScores = (pageNumber, pageSize, sort, filter) => {
        showLoading()

        if (!filter) {
            filter = { ...pointFilter }
        }

        SettingService.getCapturePointScores(pageNumber, pageSize, sort, filter)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    setCapturePointScoreData(result.data)
                }
            })
    }

    const onTableChange = (pagination, filters, sorter, extra) => {
        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = defaultGridOption.sort;
        }

        getCapturePointScores(pagination.current, pagination.pageSize, sort, pointFilter)
        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        })
    }

    const showFilterLayout = () => {
        setVisibleFilter(true);
    }

    const closeFilterLayout = () => {
        formFilter.setFieldsValue(pointFilter);
        setVisibleFilter(false);
    }

    const resetFilter = () => {
        formFilter.resetFields();
        setPointFilter({});
    }

    const applyFilter = () => {
        setVisibleFilter(false);
        let filter = formFilter.getFieldsValue();
        setPointFilter(filter)
        setGridConfigOptionsValue({
            ...gridConfigOptions,
            pageNumber: 1
        })
        getCapturePointScores(1, gridConfigOptions.pageSize, gridConfigOptions.sort, filter)
    }

    return (
        <>
            {currentPosition && currentPosition.PositionTypeId != Constant.PositionType.ClubMember &&
                <div>
                    <div id={Constant.FixItemsContainerId}>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-b-10'>
                            <Col xs={{ span: 24 }}>
                                <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                    <Title className='color-dark-blue' level={5}>{currentPosition.PositionTypeId != Constant.PositionType.ClubCommittee ? t("point_score.state_point_score_calculation_tables_capture") : t("point_score.club_point_score_calculation_tables_capture")}</Title>
                                </Space>
                            </Col>
                        </Row>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                            <Col xs={{ span: 24 }} className="text-right">
                                <Button onClick={showFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon />}>{t("common.filter")}</Button>
                            </Col>
                        </Row>
                    </div>
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Table components={components}
                                {...GetTableConfigs(columns, capturePointScoreData?.ObjectList, capturePointScoreData?.TotalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                            />
                        </Col>
                    </Row>

                    <Drawer
                        title={t("common.filter")}
                        width={478}
                        onClose={closeFilterLayout}
                        open={visibleFilter}
                        closeIcon={false}
                        styles={{
                            body: {
                                paddingBottom: 80,
                            }
                        }}
                        extra={
                            <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                                <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                                <Button onClick={closeFilterLayout} icon={<CloseIcon />} type="link" />
                            </Space>
                        }
                        footer={
                            <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                                <Button onClick={closeFilterLayout}>{t("common.cancel")}</Button>
                                <Button onClick={applyFilter} type="primary">
                                    {t("common.apply")}
                                </Button>
                            </Space>
                        }>
                        <Form
                            layout="vertical"
                            {...layout}
                            form={formFilter}
                            name="formFilter"
                            key='formFilter'
                        >
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="FishSpeciesIds"
                                        label={t("fishing_activity.species")}
                                    >
                                        <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={fishSpecies ? fishSpecies.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}
                                        >
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Drawer>
                </div>
            }

            {currentPosition && currentPosition.PositionTypeId == Constant.PositionType.ClubMember &&
                <>
                    <Table
                        style={{ visibility: 'hidden', height: '0px' }}
                        {...GetTableConfigs(columns, [], 0, "Id", onTableChange, gridConfigOptions.pageNumber)}
                    />
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_access")} />
                </>
            }

        </>
    )
}

export default CapturePointScore;
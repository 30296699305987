import React, { useEffect, useState } from 'react';
import './emailMemberIDLogIn.scss';
import { useTranslation } from 'react-i18next';
import {
    Constant,
    CommonService,
    MembershipService
} from '../../../services/services'
import { Table, Button, Row, Col, Select, Form, Input, Breadcrumb, Drawer, Space } from 'antd';
import { GetTableConfigs, YesNoCell } from '../../../components/customTable/customTable'
import { FilterAltIcon, CloseIcon } from '../../../components/icons/icons'
import LoadingContext from '../../../components/loading/loadingContext'
import EnhancedSelect from '../../../components/enhancedSelect/enhancedSelect';
import PubSub from 'pubsub-js';
import { useHistory } from "react-router-dom";
import { useGlobalState } from '../../../utilities/globalState'

const { Option } = Select;
const _ = require("lodash");

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const EmailMemberIDLogIn = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [currentPosition] = useGlobalState('currentPosition');
    const [filterForm] = Form.useForm();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext);
    const [totalItems, setTotalItems] = useState(0);
    const [members, setMembers] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [totalInvalidEmails, setTotalInvalidEmails] = useState(0);
    const [yesNos, setYesNos] = useState([]);

    const [familyGroups, setFamilyGroups] = useState([]);
    const [fishingTypes, setFishingTypes] = useState([]);
    const [fishingCategories, setFishingCategories] = useState([]);
    const [financialTypes, setFinancialTypes] = useState([]);
    const [financialStatuses, setFinancialStatuses] = useState([]);
    const [positionTitles, setPositionTitles] = useState([]);
    const [skippers, setSkippers] = useState([]);
    const [memberIds, setMemberIds] = useState([]);
    const [openFilter, setOpenFilter] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [searchMembership, setSearchMembership] = useState({ FinancialStatusIds: [Constant.FinancialStatus.Financial] });

    const [gridConfigOptions, setGridConfigOptionsValue] = useState({
        pageNumber: 1,
        pageSize: CommonService.getPageSize(),
        sort: "LastName-ascend",
    })

    const search = (pageNumber, pageSize, sort, query, isShowLoading = false) => {
        if (isShowLoading) {
            showLoading();
        }
        let params = { ...query };

        MembershipService.searchEmailMembers(pageNumber, pageSize, sort, params)
            .finally(() => {
                if (isShowLoading) {
                    dismissLoading();
                }

                setSelectedRowKeys([]);
                setSelectedMembers([]);
            })
            .then(result => {
                if (result.data) {
                    setMembers(result.data.ObjectList);
                    setTotalItems(result.data.TotalItems);
                    setTotalInvalidEmails(result.data.ObjectList != null && result.data.ObjectList.length > 0 ? result.data.ObjectList[0].TotalInvalidEmails : 0);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const onCancel = () => {
        setSelectedRowKeys([]);
        setSelectedMembers([]);
    }

    const onSend = () => {
        showLoading();
        MembershipService.sendEmailMembers(selectedMembers)
            .finally(() => {
                dismissLoading();
            })
            .then(result => {
                setSelectedRowKeys([]);
                setSelectedMembers([]);
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const columns = [
        {
            title: t('member.member_id'),
            dataIndex: 'MemberId',
            key: 'MemberId',
            sorter: true,
            width: 100,
            defaultSortOrder: 'ascend',
            fixed: 'left',
        },
        {
            title: t('member.user_name'),
            dataIndex: 'UserName',
            key: 'UserName',
            sorter: true,
            width: 160,
            fixed: 'left',
        },
        {
            title: t('member.first_name'),
            dataIndex: 'FirstName',
            key: 'FirstName',
            sorter: true,
            width: 140,
            fixed: 'left',
        },
        {
            title: t('member.surname'),
            dataIndex: 'LastName',
            key: 'LastName',
            sorter: true,
            width: 140,
            fixed: 'left',
        },
        {
            title: t('member.email'),
            dataIndex: 'Email',
            key: 'Email',
            sorter: true,
            ellipsis: true,
            width: 220,
            fixed: 'left',
        },
        ...(currentPosition && currentPosition.PositionTypeId === Constant.PositionType.StateExcutive ? [
            {
                title: t('member.club'),
                dataIndex: 'EntityAcronym',
                key: 'EntityAcronym',
                sorter: true,
                width: 80,
            }
        ] : []),
        {
            title: t('member.financial_membership_type'),
            dataIndex: 'FinancialTypeName',
            key: 'FinancialTypeName',
            sorter: false,
            width: 140,
        },
        {
            title: t('member.fishing_membership_type'),
            dataIndex: 'FishingTypeName',
            key: 'FishingTypeName',
            sorter: false,
            ellipsis: true,
            width: 140,
        },
        {
            title: t('member.fishing_membership_category'),
            dataIndex: 'FishingCategoryName',
            key: 'FishingCategoryName',
            sorter: false,
            ellipsis: true,
            width: 140,
        },
        {
            title: t('member.financial_status'),
            dataIndex: 'FinancialStatusName',
            key: 'FinancialStatusName',
            sorter: false,
            ellipsis: true,
            width: 100,
        },
        {
            title: t('member.skipper'),
            dataIndex: 'IsSkipper',
            key: 'IsSkipper',
            sorter: false,
            ellipsis: true,
            width: 80,
            render: (value, record) => (
                <YesNoCell value={record.IsSkipper} />
            )
        },
        {
            title: t('member.gamebase_executive_position'),
            dataIndex: 'PositionTitleName',
            key: 'PositionTitleName',
            sorter: false,
            ellipsis: true,
            width: 150,
        },
        {
            title: t('member.family_group'),
            dataIndex: 'FamilyGroupName',
            key: 'FamilyGroupName',
            sorter: false,
            width: 120,
        },
    ]

    const onTableChange = (pagination, filters, sorter, extra) => {
        let data = filterForm.getFieldsValue();
        if (!data || (data && !data.FinancialStatusIds)) {
            data.FinancialStatusIds = [Constant.FinancialStatus.Financial];
        }

        setSearchMembership(data);
        
        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = gridConfigOptions.sort;
        }
        search(pagination.current, pagination.pageSize, sort, data);

        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        });
    }

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (newSelectedRowKeys, newSelectedRows) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setSelectedMembers(newSelectedRows);            
        },
        fixed: true
    }

    const showFilterLayout = () => {        
        setOpenFilter(true);
    }
  
    const closeFilterLayout = () => {
        filterForm.setFieldsValue(searchMembership);
        setOpenFilter(false);
    }

    const refreshData = (isShowLoading = false) => {
        setSelectedRowKeys([]);
        setSelectedMembers([]);

        let data = filterForm.getFieldsValue();
        if (!data || (data && !data.FinancialStatusIds)) {
            data.FinancialStatusIds = [Constant.FinancialStatus.Financial];
        }

        setSearchMembership(data);
        
        search(1, gridConfigOptions.pageSize, gridConfigOptions.sort, data, isShowLoading);

        setGridConfigOptionsValue(
            {
                ...gridConfigOptions,
                pageNumber: 1
            }
        );
        
    }

    const resetFilter = () => {
        filterForm.resetFields();
        setSearchMembership({ FinancialStatusIds: [Constant.FinancialStatus.Financial] });
    }

    const applyFilter = () => {
       
        refreshData(true);

        setOpenFilter(false);
    }


    useEffect(() => {
        if (currentPosition && currentPosition.PositionTypeId === Constant.PositionType.ClubMember) {
            history.push('/unauthorised');
            return;
        }
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight())
        }, 100);

        showLoading();
        setYesNos([{
            Id: 1,
            Name: t("common.yes")
        },
        {
            Id: 2,
            Name: t("common.no")
        }]);
        Promise.all([
            MembershipService.searchEmailMembers(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, { FinancialStatusIds: [Constant.FinancialStatus.Financial] }),
            MembershipService.getEmailMembersFilterDatas()
        ])
        .finally(() => {
            dismissLoading();

            setSelectedRowKeys([]);
            setSelectedMembers([]);
            setSearchMembership({});
        })
        .then((response) => {
            if (response[0].data) {
                setMembers(response[0].data.ObjectList);
                setTotalItems(response[0].data.TotalItems);
                setTotalInvalidEmails(response[0].data.ObjectList != null && response[0].data.ObjectList.length > 0 ? response[0].data.ObjectList[0].TotalInvalidEmails : 0);
            }

            if (response[1].data) {
                setFamilyGroups(response[1].data.FamilyGroups);
                setFishingTypes(response[1].data.FishingTypes);
                setFishingCategories(response[1].data.FishingCategories);
                setFinancialTypes(response[1].data.FinancialTypes);
                setFinancialStatuses(response[1].data.FinancialStatuses);
                setPositionTitles(response[1].data.PositionTitles);
                setSkippers(response[1].data.Skippers);
                setMemberIds(response[1].data.MemberIds);
            }
        })
        .catch(error => {
            CommonService.handleErrorResponse(error);
        });

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);
        return () => {
            MembershipService.cancelRequest();
            clearTimeout(timeoutFn);
        }
    }, [])

    return (
        <div className="email-management">
            <Breadcrumb>
                <Breadcrumb.Item>{t("menu.email_member_id_log_in")}</Breadcrumb.Item>
            </Breadcrumb>
            <Row>
                <span>{t("member.email_member_id_log_in_header_title_1")}</span>
            </Row>
            <Row>
                <span className='sub-title'>{t("member.email_member_id_log_in_header_title_2")}</span>
            </Row>
            <Row id={Constant.FixItemsContainerId} className="fix-item-container">
                <Col xs={{ span: 24 }} className="text-right extra-btn-m-t">
                    <div>
                        <Button onClick={showFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon />}>{t("common.filter")}</Button>
                    </div>
                </Col>
            </Row>

            <Row className="m-t-5 section-table" gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                <Col xs={{ span: 24 }}>
                    <Table
                        {...GetTableConfigs(columns, members, totalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                        rowSelection={rowSelection}
                        scroll={{ x: "max-content", y: "max-content" }}
                    />
                </Col>
            </Row>

            <Row className="fix-item-container">
                <Col xs={{ span: 16 }} className="text-left extra-btn-m-t">
                    <Row>
                        <span>{t("member.members_match_your_criteria", { total_members_match: totalItems })}</span>
                    </Row>
                    <Row>
                        <span>{t("member.emails_are_invalid_or_do_not_exist", { total_invalid_emails: totalInvalidEmails })}</span>
                    </Row>
                </Col>
                <Col xs={{ span: 8 }} className="text-right extra-btn-m-t">
                    <div className="m-t-10">
                        <Button size="large" className="m-r-5" onClick={onCancel} disabled={selectedMembers.length === 0}>{t("common.cancel")}</Button>
                        <Button size="large" type="primary" htmlType="submit" onClick={onSend} disabled={selectedMembers.length === 0}>{t("common.send")}</Button>
                    </div>
                </Col>
            </Row>

            {/* Filter Layout  */}
            <Drawer
                title={t("common.filter")}
                width={378}
                onClose={closeFilterLayout}
                open={openFilter}
                closeIcon={false}
                styles={{
                    body: {
                        paddingBottom: 80,
                    }
                }}
                extra={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                        <Button onClick={closeFilterLayout} icon={<CloseIcon />} type="link" />
                    </Space>
                }
                footer={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button onClick={closeFilterLayout}>{t("common.cancel")}</Button>
                        <Button onClick={applyFilter} type="primary">
                            {t("common.apply")}
                        </Button>
                    </Space>
                }>
                <Form 
                    layout="vertical" 
                    {...layout}
                    form={filterForm}
                    name="filterForm"
                    key='filterForm'
                    initialValues={ { FinancialStatusIds: [Constant.FinancialStatus.Financial] } }>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="FinancialTypeIds"
                                label={t('member.financial_membership_type')}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) => (optionA?.value == optionB?.value)}
                                        options={financialTypes ? financialTypes.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="FishingTypeIds"
                                label={t("member.fishing_membership_type")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) => (optionA?.value == optionB?.value)}
                                        options={fishingTypes ? fishingTypes.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="FishingCategoryIds"
                                label={t("member.fishing_membership_category")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) => (optionA?.value == optionB?.value)}
                                        options={fishingCategories ? fishingCategories.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="FinancialStatusIds"
                                label={t("member.financial_status")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) => (optionA?.value == optionB?.value)}
                                        options={financialStatuses ? financialStatuses.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="SkipperYesNoIds"
                                label={t("member.skipper")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        options={yesNos ? yesNos.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="PositionTitleIds"
                                label={t("member.gamebase_executive_position")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={positionTitles ? positionTitles.map(b => {
                                            return {
                                                label: b.PositionTitleName,
                                                value: b.PositionTitleId
                                            }
                                        }) : []}>
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="FamilyGroupIds"
                                label={t('member.family_group')}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={familyGroups ? familyGroups.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="Ids"
                                label={t("member.member_id")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={memberIds ? memberIds.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="UserName"
                                label={t("member.user_name")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="FirstName"
                                label={t("member.first_name")}
                            >
                               <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="LastName"
                                label={t("member.surname")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>                    
        </div>
    )

}

export default EmailMemberIDLogIn;
export const Constant = {
    Pages: {
        Home: '/',
        Login: '/login',
        ForgetPassword: '/forget-password',
        ForgetPasswordResult: '/forget-password-result',
        MembershipManagement: '/membership-management',
        MembershipSearch: '/membership-search',
        EditMemberDetails: '/edit-member-details',
        PositionTitleManagement: '/position-title-management',
        MemberViewClubContacts: '/member-view-club-contacts',
        MemberViewStateContacts: '/member-view-state-contacts',
        BoatCategoryManagement: '/boat-category-management',
        ClubDetail: '/club-detail',
        StateDetail: '/state-detail',
        ContactsNotification: '/contacts-notification',
        PointScoreZoneManagement: '/point-score-zone-management',
        SpecialEventManagement: '/special-event-management',
        BoatManagement: '/boat-management',
        CreateDayLog: '/create-day-log',
        Sample: '/sample',
        FishingLocationsManagement: '/fishing-location-management',
        BoatSearch: '/boat-search',
        DayLogList: '/day-log-list',
        MemberFinancialDateManagement: '/member-financial-date',
        FamilyGroupManagement: '/family-group-management',
        MemberReport: '/member-report',
        TagCardSearch: '/tag-card-search',
        ExportActivity: '/export-activity',
        ActivityApprovals: '/activity-approvals',
        MembershipInvoicing: '/membership-invoicing',
        ViewTrophy: '/view-trophy',
        TrophyManagement: '/trophy-management',
        ClubDefaults: '/club-defaults',
        StateDefaults: '/state-defaults',
        EmailMemberIDLogIn: '/email-memberid-login',
        AnglerGroupManagement: '/angler-group-management',
        ActivityEntriesList: '/activity-entries-list',
        StateApprovalsStatus: '/state-approvals-status',
        DateApprovals: '/date-approvals',
        DateApplication: '/date-application',
        SanctioningApplications: '/sanctioning-applications',
        TournamentList: '/tournament-list',
        DocumentManagement: '/document-management',
        ViewDocuments: '/view-documents',
        TagRPointScore: '/tag-r-pointscore',
        CapturePointScore: '/capture-point-score',
        MRPointScore: '/m-r-pointscore',
        LRPointScore: '/l-r-pointscore',
        SanctioningApprovals: '/sanctioning-approvals',
        DayLogSummary: '/day-log-summary',
        EmailMembers: '/email-members',
        RecordManagement: '/records',
        BoatGroupManagement: '/boat-group-management',
        StateRecord: '/state-records',
        ClubRecord: '/club-records',
        TournamentCalendar: '/tournament-calendar',
        ClubApprovalsStatus: '/club-approvals-status',
        Unauthorised: '/unauthorised'
    },
    PubSupType: {
        NotificationMessage: "Notification",
        UserLogin: "UserLogin",
        UserLogout: "UserLogout",
        RefreshToken: "RefreshToken",
        PageChanged: "PageChanged"
    },
    PageSize: 25,
    DateFormat: "DD/MM/YYYY",
    DateFileFormat: "DD-MM-YYYY",
    DateFormatYYYMMDD: "YYYY-MM-DD",
    TimeFormat: "HH:mm",
    SpaceConstant: {
        HorizontalGutter: 30,
        VerticalGutter: 15,
        TextFieldGutter: 3
    },
    CustomizeTheme: {
        ColorBlue: '#0067FF',
        ColorDarkBlue: '#264A80',
        ColorDarkBlue1: '#172C4D',
        ColorBlack: '#111111',
        ColorMediumGrey: '#646A7C',
        ColorTransparentGrey: '#00000014',
        ColorInputBorder: '#C1C3C8',
        FontFamily: 'Nunito Sans',
        ButtonBorderRadius: '10px',
        ColorDarkRed: '#C71720',
        ControlHeight: 38,
        FontSize: 16
    },
    ColorClass: {
        LightRed: "--light-red-color",
        LightGreen: "--light-green-color",
        LightYellow: "--light-yellow-color",
        LightBlue: "--light-blue-color"
    },
    SuperUserName: "gamebase",
    EntityType: {
        Club: 1,
        State: 2,
        National: 3,
        Gamebase: 4
    },
    PositionType: {
        ClubMember: 1,
        ClubCommittee: 2,
        StateExcutive: 3,
        NationalExecutive: 4
    },
    MenuType: {
        Member: 1,
        Club: 2,
        State: 3,
    },
    CellType: {
        Checkbox: 1,
        Selection: 2,
        Input: 3,
        NumberInput: 4,
        Date: 5,
        FamilyGroupSelection: 6,
        StatusSelection: 8,
        MemberDateOfBirth: 9,
        InputPassword: 11,
        ClubSelection: 12,
        GPSLatitudeInput: 13,
        GPSLongitudeInput: 14,
        DecimalInput: 15,
        Time: 16,
        TagNumber: 17,
        Upload: 18,
        CustomTimePicker: 19,
        EnhancedSelect: 20,
        FishingMethodEnhancedSelect: 21,
        ToggleNumberInput: 22,
        EmailInput: 23
    },
    Title: {
        Mr: 1,
        Mrs: 2,
        Ms: 3,
        Miss: 4,
        Mst: 5,
        Dr: 6,
        Capt: 7,
    },
    Gender: {
        Male: 1,
        Female: 2,
    },
    FishingType: {
        Senior: 1,
        Junior: 2,
        NonFishing: 3,
    },
    FishingCategory: {
        Men: 1,
        Women: 2,
        JuniorMale: 3,
        JuniorFemale: 4,
        SmallFryMale: 5,
        SmallFryFemale: 6,
        NonFishing: 7,
    },
    FinancialStatus: {
        Financial: 1,
        UnFinancial: 2
    },
    MemberStatusCode: {
        Active: 1,
        Inactive: 2
    },
    StatusCode: {
        Active: 1,
        Inactive: 2
    },
    CssVariables: {
        FixItemsContainerHeight: "--fix-items-container-height",
        ModalFixItemsContainerHeight: "--modal-fix-items-container-height",
        ButtonGroupWidth: "--button-group-width"
    },
    FixItemsContainerId: "fixItemsContainerId",
    HomeDetailsType: {
        FinancialMembers: 1,
        UnFinancialMembers: 2,
        FishingMembers: 3,
        JuniorsWithErrors: 4,
        ActiveMembers: 5,
        MembersWithAdminAccess: 6,
        CurrentBoat: 7,
        SkipperOfBoats: 8
    },
    MemberListType: {
        Skipper1: 1,
        Skipper2: 2,
        FamilyGroup: 3
    },
    FileType: {
        Excel: 1,
        Csv: 2,
        PositionHolder: 3,
        FamilyGroup: 4
    },
    TagApprovalStatus: {
        Approved: 1,
        Disqualified: 2
    },
    TrophyType: {
        Angler: 1,
        Boat: 2,
        Club: 3
    },
    ButtonType: {
        Link: "link"
    },
    TournamentDatesApprovedCode:
    {
        DatesNotApproved: 1,
        DatesApproved: 2,
        NoChange: 3
    },
    TrophyTableLayoutType: {
        Trophies: 1,
        IndividualTrophy: 2
    },
    TagCaptureCode: {
        T_R: 1,
        Capt: 2,
        Strike: 3,
        H_Up: 4,
        M_R: 5,
        L_R: 6,
        Sharked: 7
    },
    TournamentCalendarDefaultLink: "https://qgfa.com.au/calendar/"
}
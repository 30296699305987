import React, { useState } from 'react';
import './sample.scss';
import { Button, Row, Col, Form, Input, Select, DatePicker, Table, Space, Divider } from 'antd';
import { PlusIcon, TrashIcon } from '../../components/icons/icons';
import { GetTableConfigs, StatusCell, ActionButtonsCell, EditableCell } from '../../components/customTable/customTable'
import { Constant, CommonService } from '../../services/services';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { PoweroffOutlined } from '@ant-design/icons';

const { Option } = Select;

function Sample(props) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const dropdownItems = [
        {
            Code: 1,
            Description: "A"
        },
        {
            Code: 2,
            Description: "B"
        }
    ]
    const testData = [
        {
            Id: 1,
            Username: "HienNguyen",
            FirstName: "Hien",
            LastName: "Nguyen",
            PhoneNumber: "124568",
            StatusCode: 1,
            Birthday: '26/02/2023'
        },
        {
            Id: 2,
            Username: "HienNguyen1",
            FirstName: "Hien",
            LastName: "Nguyen",
            PhoneNumber: "124568",
            StatusCode: 2
        },
        {
            Id: 3,
            Username: "HienNguyen2",
            FirstName: "Hien",
            LastName: "Nguyen",
            PhoneNumber: "124568",
            StatusCode: 1
        },
        {
            Id: 4,
            Username: "HienNguyen3",
            FirstName: "Hien",
            LastName: "Nguyen",
            PhoneNumber: "124568",
            StatusCode: 1
        }
    ]

    const getStatusTagColorClass = (item) => {
        if (item) {
            if (item.StatusCode == Constant.StatusCode.Active)
                return Constant.ColorClass.LightGreen
            return Constant.ColorClass.LightBlue
        }
        return ''
    }

    const getStatusDescription = (item) => {
        if (item) {
            if (item.StatusCode == Constant.StatusCode.Active)
                return t("common.active")
            return t("common.inactive")
        }
    }

    const columns = [
        {
            title: "Username",
            dataIndex: 'Username',
            key: 'Username',
            ellipsis: true,
            // width: 200,
            // fixed: true
        },
        {
            title: "First Name",
            dataIndex: 'FirstName',
            key: 'FirstName',
            sorter: true,
            ellipsis: true
        },
        {
            title: "Last Name",
            dataIndex: 'LastName',
            key: 'LastName',
            sorter: true,
            ellipsis: true
        },
        {
            title: "Phone Number",
            dataIndex: 'PhoneNumber',
            key: 'PhoneNumber',
            sorter: true,
            ellipsis: true
        },
        {
            title: "Link",
            key: 'Id',
            render: (value, record) => {
                return (
                    <Button size="small" type="link">
                        Link
                    </Button>
                )
            },
            width: 150
        },
    ]

    const onTableChange = (pagination, filters, sorter, extra) => {
        // getUsers(pagination.current, pagination.pageSize, CommonService.getSortString(sorter))
        // setGridConfigOptionsValue({
        //     pageNumber: pagination.current,
        //     pageSize: pagination.pageSize,
        //     sort: CommonService.getSortString(sorter)
        // })
    }

    const handleAction = () => {

    }

    const getActionButtonsInfo = (item) => {
        return [
            {
                Description: "View",
                Icon: <TrashIcon />,
                ColorClass: Constant.ColorClass.LightBlue,
                Hide: false,
                handleAction: handleAction
            },
            {
                Description: "Delete",
                Icon: <TrashIcon />,
                ColorClass: Constant.ColorClass.LightRed,
                Hide: false,
                handleAction: handleAction
            }
        ]
    }

    const tempList = [
        {
            Id: "1",
            Name: "Test 1"
        },
        {
            Id: "2",
            Name: "Test 2"
        }
    ]

    const handleOnChange = (value, record) => {
        console.log(record)
    }

    const editable_columns = [
        {
            title: "Action",
            key: 'Id',
            render: (value, record) => {
                return <ActionButtonsCell btnList={getActionButtonsInfo(record)} data={record} />
            },
            align: "center",
            width: 90
        },
        {
            title: "Username",
            dataIndex: 'Username',
            key: 'Username',
            width: 70
        },
        {
            title: <span className='required'>FirstName</span>,
            dataIndex: 'FirstName',
            key: 'FirstName',
            editable: true,
            extraData: tempList,
            required: true,
            cellType: Constant.CellType.Selection,
            width: 280
        },
        {
            title: 'LastName',
            dataIndex: 'LastName',
            key: 'LastName',
            editable: true,
            cellType: Constant.CellType.Checkbox,
            width: 200
        },
        {
            title: 'Birthday',
            dataIndex: 'Birthday',
            key: 'Birthday',
            editable: true,
            cellType: Constant.CellType.Date,
            width: 200,
            handleOnChange: handleOnChange
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            width: 80,
            align: "center",
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.StatusCode)}
                    description={CommonService.getStatusDescription(record.StatusCode, false)} />
            )
        }
    ]

    const columnsMapping = editable_columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition,
                selectionKey: col.selectionKey,
                selectionDisplay: col.selectionDisplay
            }),
        };
    });

    const components = {
        body: {
            cell: EditableCell,
        },
    };


    return (
        <div className="supplier-dashboard-page p-10">
            <Divider orientation="left" plain>
                Small Sizes
            </Divider>
            <Space wrap>
                <Button
                    size="small"
                    type="primary"
                    icon={<PlusIcon />}
                    className='btn-icon'
                >
                    Blue Solid Icon Button
                </Button>
                <Button size="small" type="primary">Blue Solid Button</Button>
                <Button size="small" className="btn-outline-blue">Blue Outline Button</Button>
                <Button size="small" className='btn-solid-dark-blue'>Dark Blue Solid Button</Button>
                <Button size="small" className="btn-outline-dark-blue">Dark Blue Outline Button</Button>
                <Button size="small">Default Button</Button>
                <Button size="small" disabled>Disabled</Button>
                <Button size="small" type="text">Text Button</Button>
                <Button size="small" type="link">Link Button</Button>
            </Space>

            <Divider orientation="left" plain>
                Medium Sizes
            </Divider>
            <Space wrap>
                <Button
                    type="primary"
                    icon={<PlusIcon />}
                    className='btn-icon'
                >
                    Blue Solid Icon Button
                </Button>
                <Button type="primary">Blue Solid Button</Button>
                <Button className='btn-outline-blue btn-icon' icon={<PlusIcon />}>Blue Outline Icon Button</Button>
                <Button className="btn-outline-blue">Blue Outline Button</Button>
                <Button className='btn-solid-dark-blue btn-icon' icon={<PlusIcon />}>Dark Blue Solid Icon Button</Button>
                <Button className='btn-solid-dark-blue'>Dark Blue Solid Button</Button>
                <Button className='btn-outline-dark-blue btn-icon' icon={<PlusIcon />}>Dark Blue Outline Icon Button</Button>
                <Button className='btn-outline-dark-blue'>Dark Blue Outline Button</Button>
                <Button className='btn-icon' icon={<PlusIcon />}>Default Button</Button>
                <Button>Default Button</Button>
                <Button disabled className='btn-icon' icon={<PlusIcon />}>Disabled</Button>
                <Button disabled>Disabled</Button>
                <Button type="text">Text Button</Button>
                <Button type="link">Link Button</Button>
            </Space>

            <Divider orientation="left" plain>
                Large Sizes
            </Divider>
            <Space wrap>
                <Button
                    size="large"
                    type="primary"
                    icon={<PlusIcon />}
                    className='btn-icon'
                >
                    Blue Solid Icon Button
                </Button>
                <Button size="large" type="primary">Blue Solid Button</Button>
                <Button size="large" className="btn-outline-blue">Blue Outline Button</Button>
                <Button size="large" className='btn-solid-dark-blue'>Dark Blue Solid Button</Button>
                <Button size="large" className="btn-outline-dark-blue">Dark Blue Outline Button</Button>
                <Button size="large">Default Button</Button>
                <Button size="large" disabled>Disabled</Button>
                <Button size="large" type="text">Text Button</Button>
                <Button size="large" type="link">Link Button</Button>
            </Space>

            <Divider orientation="left" plain>
                Danger Buttons
            </Divider>
            <Space wrap>
                <Button type="primary" danger>
                    Primary
                </Button>
                <Button danger>Default</Button>
                <Button type="text" danger>
                    Text
                </Button>
                <Button type="link" danger>
                    Link
                </Button>
            </Space>

            <Divider orientation="left" plain>
                Components
            </Divider>
            <Row>
                <Col xs={{ span: 24 }}>
                    <Form
                        form={form}
                        name="formName"
                        layout="vertical"
                    >
                        <Row>
                            <Col xs={{ span: 5 }}>
                                <Form.Item
                                    label="Input"
                                    name="inputName" colon={false} labelAlign="left"
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 5, offset: 1 }}>
                                <Form.Item
                                    label="Search Input"
                                    name="searchInputName" colon={false} labelAlign="left"
                                    initialValue="test"
                                >
                                    <Input.Search allowClear />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 5, offset: 1 }}>
                                <Form.Item
                                    label="Date Picker"
                                    name="datePickerName" colon={false} labelAlign="left"
                                >
                                    <DatePicker />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 5, offset: 1 }}>
                                <Form.Item
                                    label="Dropdown"
                                    name="dropdownName" colon={false}
                                    rules={[{ required: true, message: "Field is required" }]}
                                >
                                    <Select>
                                        {
                                            dropdownItems.map((n, index) => <Option key={index} value={n.Code}>{n.Description}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={{ span: 5 }}>
                                <Form.Item
                                    label="Multiple Select"
                                    name="multipleSelectName" colon={false} labelAlign="left"
                                >
                                    <Select
                                        mode="multiple"
                                        allowClear>
                                        {
                                            dropdownItems.map((n, index) => <Option key={index} value={n.Code}>{n.Description}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Divider orientation="left" plain>
                Table
            </Divider>
            <Row>
                <Col xs={{ span: 24 }}>
                    <Table
                        {...GetTableConfigs(columns, testData, testData.length, "Id", onTableChange)}
                    />
                </Col>
            </Row>
            <Divider orientation="left" plain>
                Editable Table
            </Divider>
            <Row>
                <Col xs={{ span: 24 }}>
                    <Table
                        components={components}
                        rowClassName={() => 'editable-row'}
                        {...GetTableConfigs(columnsMapping, testData, testData.length, "Id", onTableChange)}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default Sample;
import React, { useEffect, useState } from 'react';
import './fishingLocationManagement.scss';
import { useTranslation } from 'react-i18next';
import {
    Constant,
    CommonService,
    SettingService
} from '../../services/services'
import { Table, Button, Row, Col, Form, Modal } from 'antd';
import { GetTableConfigs, EditableCell, ActionButtonsCell } from '../../components/customTable/customTable'
import { TrashIcon } from '../../components/icons/icons'
import LoadingContext from '../../components/loading/loadingContext'

const { confirm } = Modal;
const _ = require("lodash");

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const components = {
    body: {
        cell: EditableCell,
    },
};

const CreateEditFishingLocation = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const {showLoading, dismissLoading} = React.useContext(LoadingContext);
    const [originalFishingLocations, setOriginalFishingLocations] = useState([]);
    const [fishingLocations, setFishingLocations] = useState([]);
    const [allFishingLocations, setAllFishingLocations] = useState([]);
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [form] = Form.useForm();
   
    const onSubmit = () => {
        // Validate
        var allErrorMessages = [];
        setFishingLocations(current =>
            current.map(obj => {
                var errorMessages = [];
                if (!obj.Name) {
                    errorMessages.push({
                        key: 'Name',
                        value: t('fishing_location.location_name_is_required')
                    })
                }

                if (!obj.GpsLongitude) {
                    errorMessages.push({
                        key: 'GpsLongitude',
                        value: t('fishing_location.gps_longitude_is_required')
                    })
                }
                else if (obj.GpsLongitude.replace(/[^0-9]/g, '').length != 8){
                    errorMessages.push({
                        key: 'GpsLongitude',
                        value: t('fishing_location.gps_longitude_is_required_8_digits')
                    })
                }

                if (!obj.GpsLatitude) {
                    errorMessages.push({
                        key: 'GpsLatitude',
                        value: t('fishing_location.gps_latitude_is_required')
                    })
                }
                else if (obj.GpsLatitude.replace(/[^0-9]/g, '').length != 7){
                    errorMessages.push({
                        key: 'GpsLatitude',
                        value: t('fishing_location.gps_latitude_is_required_7_digits')
                    })
                }

                if (!obj.StatePointScoreZoneId) {
                    errorMessages.push({
                        key: 'StatePointScoreZoneId',
                        value: t('fishing_location.state_point_score_zone_is_required')
                    })
                }

                if (!obj.StatusId) {
                    errorMessages.push({
                        key: 'StatusId',
                        value: t('common.status_is_required')
                    })
                }

                // Exists
                var existFishingLocations = allFishingLocations.filter(x => x.Id !== obj.Id && x.Name === obj.Name);
                if (existFishingLocations !== null && existFishingLocations.length > 0) {
                    errorMessages.push({
                        key: 'Name',
                        value: t('fishing_location.fishing_location_exist')
                    })
                }

                allErrorMessages.push(...errorMessages);
                return {
                    ...obj,
                    ErrorMessages: errorMessages,
                };
            }),
        );

        if (allErrorMessages.length > 0) {
            return;
        }

        // Add/Update
        showLoading();
        SettingService.addUpdateFishingLocations(fishingLocations)
            .finally(() => {
                dismissLoading();
            })
            .then(result => {
                if (result.data) {
                    CommonService.presentToast('success', props.isEdit ? t('fishing_location.update_successful') : t('fishing_location.create_successful'));
                    props.handleCancel(true);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const canSubmit = (newFishingLocations) => {
        if (newFishingLocations && newFishingLocations.length > 0) {
            let inValid = newFishingLocations.filter(x => !x.Name);
            if (inValid && inValid.length > 0) {
                return false
            }
            return true;
        }
        return false;
    }

    const submitConfirmation = () => {
        function customizer(newFishingLocations, oldFishingLocations) {
            if (newFishingLocations.length !== oldFishingLocations.length)
                return true;

            for (var i = 0; i < newFishingLocations.length; i ++) {
                if (((newFishingLocations[i].Name !== null || oldFishingLocations[i].Name !== null) && newFishingLocations[i].Name !== oldFishingLocations[i].Name) ||
                    ((newFishingLocations[i].GpsLatitude !== null || oldFishingLocations[i].GpsLatitude !== null) && newFishingLocations[i].GpsLatitude !== oldFishingLocations[i].GpsLatitude) ||
                    ((newFishingLocations[i].GpsLongitude !== null || oldFishingLocations[i].GpsLongitude !== null) && newFishingLocations[i].GpsLongitude !== oldFishingLocations[i].GpsLongitude) ||
                    ((newFishingLocations[i].NominalWaterDepth !== null || oldFishingLocations[i].NominalWaterDepth !== null) && newFishingLocations[i].NominalWaterDepth !== oldFishingLocations[i].NominalWaterDepth) ||
                    ((newFishingLocations[i].IsInsideClubWaters !== null || oldFishingLocations[i].IsInsideClubWaters !== null) && newFishingLocations[i].IsInsideClubWaters !== oldFishingLocations[i].IsInsideClubWaters) ||
                    ((newFishingLocations[i].ClubZoneId !== null || oldFishingLocations[i].ClubZoneId !== null) && newFishingLocations[i].ClubZoneId !== oldFishingLocations[i].ClubZoneId) ||
                    ((newFishingLocations[i].StatePointScoreZoneId !== null || oldFishingLocations[i].StatePointScoreZoneId !== null) && newFishingLocations[i].StatePointScoreZoneId !== oldFishingLocations[i].StatePointScoreZoneId)
                    ) {
                    return true;
                }
            }

            return false;
        }
        
        var newFishingLocations = _.sortBy(fishingLocations, [function(m) { return m.Id; }]);
        var oldFishingLocations = _.sortBy(originalFishingLocations, [function(m) { return m.Id; }]);
        var changed = _.isEqualWith(newFishingLocations, oldFishingLocations, customizer);
        if (changed) {
            confirm({
                title: t("common.submit_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    onSubmit();
                },
                onCancel() {
                    props.handleCancel(true);
                }
            });
        }
        else {
            props.handleCancel(false);
        }
    }

    const onClose = () => {
        submitConfirmation();
    }

    const addFishingLocation = () => {
        var recordId = (fishingLocations.length + 1);
        var id = -recordId;
        setFishingLocations([...fishingLocations, newFishingLocation(null, id)]);
    }
    
    const deleteFishingLocation = (record) => {
        if (!record.IsAddNew && record.Id > 0) {
            confirm({
                title: t("fishing_location.delete_club_fishing_location_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    showLoading();
    
                    SettingService.deleteFishingLocations([record.Id])
                    .finally(() => {
                        dismissLoading();
                    })
                    .then(result => {
                        const newFishingLocations = fishingLocations.filter((m) => m.Id !== record.Id);
                        setFishingLocations(newFishingLocations);
                        setRefresh(true);
                    })
                    .catch(error => {
                        CommonService.handleErrorResponse(error);
                    });
                }
            });
        } else {
            const newFishingLocations = fishingLocations.filter((m) => m.Id !== record.Id);
            setFishingLocations(newFishingLocations);
        }
    }

    const handleOnChange = (value, record, dataIndex) => {
        if (record) {
            var index = fishingLocations.findIndex(x => x.Id === record.Id);
            _.update(fishingLocations[index], dataIndex, function (n) { 
                return value;
            });

            if (dataIndex === 'Name') {
                setFishingLocations(current =>
                    current.map(obj => {
                        var errorMessages = [];
                        if (obj.Id === record.Id) {
                            var existFishingLocations = allFishingLocations.filter(x => x.Id !== obj.Id && x.Name === obj.Name);
                            if (existFishingLocations !== null && existFishingLocations.length > 0) {
                                errorMessages.push({
                                    key: 'Name',
                                    value: t('fishing_location.fishing_location_exist')
                                })
                            }
                        }
                        return {
                            ...obj,
                            ErrorMessages: errorMessages,
                        };
                    }),
                );
            }
        }
        setEnableSubmit(canSubmit(fishingLocations));
    }

    var columns = [
        {
            title: t('common.record'),
            dataIndex: 'RecordId',
            key: 'RecordId',
            editable: false,
            required: false,
            width: 50,
        },
        {
            title: <span className='required'>{ t('fishing_location.location_name') }</span>,
            dataIndex: 'Name',
            key: 'Name',
            editable: true,
            required: true,
            duplicated: true,
            cellType: Constant.CellType.Input,
            width: 250,
            handleOnChange: handleOnChange,
        },
        {
            title: (<div>
                <span>{ t('fishing_location.gps') }</span><br />
                <span className='required'>{ t('fishing_location.latitude') }</span>
            </div>),
            dataIndex: 'GpsLatitude',
            key: 'GpsLatitude',
            editable: true,
            required: true,
            cellType: Constant.CellType.GPSLatitudeInput,
            width: 75,
            handleOnChange: handleOnChange,
        },
        {
            title: (<div>
                <span>{ t('fishing_location.gps') }</span><br />
                <span className='required'>{ t('fishing_location.longitude') }</span>
            </div>),
            dataIndex: 'GpsLongitude',
            key: 'GpsLongitude',
            editable: true,
            required: true,
            cellType: Constant.CellType.GPSLongitudeInput,
            width: 81,
            handleOnChange: handleOnChange,
        },
        {
            title: t('fishing_location.nominal_water_depth'),
            dataIndex: 'NominalWaterDepth',
            key: 'NominalWaterDepth',
            editable: true,
            required: false,
            cellType: Constant.CellType.Input,
            width: 83,
            handleOnChange: handleOnChange,
        },
        {
            title: t('fishing_location.is_inside_club_waters'),
            dataIndex: 'IsInsideClubWaters',
            key: 'IsInsideClubWaters',
            editable: true,
            required: false,
            cellType: Constant.CellType.Checkbox,
            width: 71,
            handleOnChange: handleOnChange,
        },
        {
            title: <span>{ t('fishing_location.club_zone') }</span>,
            dataIndex: 'ClubZoneId',
            key: 'ClubZoneId',
            editable: true,
            extraData: props.clubZones,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "Name",
            width: 95,
            handleOnChange: handleOnChange
        },
        {
            title: <span className='required'>{ t('fishing_location.state_point_score_zone') }</span>,
            dataIndex: 'StatePointScoreZoneId',
            key: 'StatePointScoreZoneId',
            required: true,
            editable: true,
            extraData: props.statePointScoreZones,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "Name",
            width: 105,
            handleOnChange: handleOnChange
        },
        {
            title: <span className='required'>{ t('common.status') }</span>,
            dataIndex: 'StatusId',
            key: 'StatusId',
            editable: true,
            extraData: props.statuses,
            required: true,
            cellType: Constant.CellType.StatusSelection,
            selectionKey: "Id",
            selectionDisplay: "Name",
            width: 65,
            handleOnChange: handleOnChange
        },
        {
            title: t('common.action'),
            key: 'Id',
            render: (value, record) => {
                return <ActionButtonsCell data={record} btnList={[
                    {
                        Description: t("common.delete"),
                        Icon: <TrashIcon />,
                        ColorClass: Constant.ColorClass.LightRed,
                        Hide: false,
                        NoMargin: true,
                        handleAction: deleteFishingLocation
                    }
                ]} />
            },
            align: 'center',
            width: 50
        },
    ]

    const fishingLocationColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                duplicated: col.duplicated,
                disabled: col.disabled,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition,
                selectionKey: col.selectionKey,
                selectionDisplay: col.selectionDisplay,
                validateAtStart: col.validateAtStart
            }),
        };
    })

    useEffect(() => {
        showLoading();

        var newFishingLocations = [];
        if (!props.isEdit) {
            var recordId = (fishingLocations.length + 1);
            var id = -recordId;
            newFishingLocations.push(newFishingLocation(null, id));
        } else {
            for (var i = 0; i < props.selectedFishingLocations.length; i ++) {
                props.selectedFishingLocations[i].RecordId = props.selectedFishingLocations[i].Id;
            }
            newFishingLocations = [...newFishingLocations, ...props.selectedFishingLocations];
        }
        setFishingLocations(newFishingLocations);
        setOriginalFishingLocations(_.cloneDeep(newFishingLocations));
        setEnableSubmit(canSubmit(newFishingLocations));
        setRefresh(false);

        Promise.all([
            SettingService.getAllFishingLocations()
        ])
        .finally(() => {
            dismissLoading();
        })
        .then((values) => {
            if (values && values.length > 0) {
                setAllFishingLocations(values[0].data);
            }
        });

        return () => {
        }
    }, [])

    const newFishingLocation = (recordId, id) => {
        return {
            IsAddNew: true,
            RecordId: recordId,
            Id: id,
            Name: null,
            GpsLatitude: null,
            GpsLongitude: null,
            NominalWaterDepth: null,
            IsInsideClubWaters: false,
            ClubZoneId: null,
            StatePointScoreZoneId: null,
            StatusId: Constant.StatusCode.Active
        };
    }

    return (
        <div>
            <Modal
                title={props.isEdit ? t("fishing_location.edit_club_fishing_locations") : t("fishing_location.insert_club_fishing_locations")}
                centered
                forceRender
                open={true}
                onOk={onSubmit}
                confirmLoading={false}
                onCancel={onClose}
                okText={props.isEdit ? t("common.submit") : t("common.insert")}
                cancelText={t("common.cancel")}
                maskClosable={false}
                width={1560}
                footer={[
                    <Button key="back" onClick={onClose}>
                      {t("common.cancel")}
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} disabled={!enableSubmit} onClick={onSubmit}>
                      {props.isEdit ? t("common.submit") : t("common.insert")}
                    </Button>
                  ]}
            >
                <Form
                    {...layout}
                    name='insertEditFishingLocation'
                    key='insertEditFishingLocation'
                    onFinish={onSubmit}
                    component={false}
                    form={form}>
                    <Row id={Constant.FixItemsContainerId} className="fix-item-container">
                        <Col xs={{ span: 24 }} className="text-right extra-btn-m-t">
                            <div className="m-t-10">
                                <Button className="m-l-10 secondary-btn" type="primary" onClick={addFishingLocation}>
                                    {t("common.add")}
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    <Row className="m-t-10" gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Table
                                components={components}
                                rowClassName={() => 'editable-row'}
                                {...GetTableConfigs(fishingLocationColumns, fishingLocations, fishingLocations.length, "Id", null)}
                                pagination={false}
                                rowKey="Id"
                            />
                        </Col>
                    </Row>
                </Form>

                
            </Modal>
        </div>
    )
}

export default CreateEditFishingLocation
import React, { useEffect, useState } from 'react';
import './clubDefaults.scss';
import { useTranslation } from 'react-i18next';
import {
    Constant,
    CommonService,
    SettingService
} from '../../../services/services'
import { Table, Button, Row, Col, Form, Input, Modal, Breadcrumb, Drawer, Space, Select, Tabs, Empty } from 'antd';
import { GetTableConfigs, StatusCell, YesNoCell } from '../../../components/customTable/customTable'
import { FilterAltIcon, CloseIcon } from '../../../components/icons/icons'
import LoadingContext from '../../../components/loading/loadingContext'
import EnhancedSelect from '../../../components/enhancedSelect/enhancedSelect';
import PubSub from 'pubsub-js';
import { MaskedInput } from 'antd-mask-input';
import { useGlobalState } from './../../../utilities/globalState'

const { confirm } = Modal;
const { TabPane } = Tabs;
const { Option } = Select;
const _ = require("lodash");

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const DUMB_GPS_LATTITUDE_MASK = '0[0]°0[0].0[0][0]\'';
const DUMB_GPS_LONGITUDE_MASK = '0[0][0]°0[0].0[0][0]\'';

const ClubDefaults = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext);

    // Filter Data
    const [statuses, setStatuses] = useState([]);
    const [clubZones, setClubZones] = useState([]);
    const [statePointScoreZones, setStatePointScoreZones] = useState([]);
    const [yesNos, setYesNos] = useState([]);
    const [currentPosition] = useGlobalState('currentPosition');

    // 1. Fishing Locations
    const [fishingLocationsForm] = Form.useForm();
    const [fishingLocations, setFishingLocations] = useState([]);
    const [totalFishingLocations, setTotalFishingLocations] = useState(0);
    const [openFishingLocationsFilter, setOpenFishingLocationsFilter] = useState(false);
    const [searchFishingLocation, setSearchFishingLocation] = useState({ StatusIds: [Constant.StatusCode.Active] });
    const [fishingLocationsGridConfigOptions, setFishingLocationsGridConfigOptionsValue] = useState({
        pageNumber: 1,
        pageSize: CommonService.getPageSize(),
        sort: "LocationName-ascend",
    });

    // 2. Club Point Score Zones
    const [pointScoreZonesForm] = Form.useForm();
    const [pointScoreZones, setPointScoreZones] = useState([]);
    const [totalPointScoreZones, setTotalPointScoreZones] = useState(0);
    const [openPointScoreZonesFilter, setOpenPointScoreZonesFilter] = useState(false);
    const [searchPointScoreZone, setSearchPointScoreZone] = useState({ StatusIds: [Constant.StatusCode.Active] });
    const [pointScoreZonesGridConfigOptions, setPointScoreZonesGridConfigOptionsValue] = useState({
        pageNumber: 1,
        pageSize: CommonService.getPageSize(),
        sort: "Name-ascend",
    });

    // 3. Special Events
    const [specialEventsForm] = Form.useForm();
    const [specialEvents, setSpecialEvents] = useState([]);
    const [totalSpecialEvents, setTotalSpecialEvents] = useState(0);
    const [openSpecialEventsFilter, setOpenSpecialEventsFilter] = useState(false);
    const [searchSpecialEvent, setSearchSpecialEvent] = useState({ StatusIds: [Constant.StatusCode.Active] });
    const [specialEventsGridConfigOptions, setSpecialEventsGridConfigOptionsValue] = useState({
        pageNumber: 1,
        pageSize: CommonService.getPageSize(),
        sort: "Name-ascend",
    });


    // 1. Fishing Locations
    const searchFishingLocations = (pageNumber, pageSize, sort, query, isShowLoading = false) => {
        if (isShowLoading) {
            showLoading();
        }

        let params = { ...query };
        if (!params) params = { StatusIds: [Constant.StatusCode.Active] };

        SettingService.getFishingLocations(pageNumber, pageSize, sort, params, true)
            .finally(() => {
                if (isShowLoading) {
                    dismissLoading();
                }
            })
            .then(result => {
                if (result.data) {
                    setFishingLocations(result.data.ObjectList);
                    setTotalFishingLocations(result.data.TotalItems);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const fishingLocatonsColumns = [
        {
            title: t('fishing_location.location_name'),
            dataIndex: 'LocationName',
            key: 'LocationName',
            sorter: true,
            defaultSortOrder: 'ascend',
            width: 200,
        },
        {
            title: t('fishing_location.gps_latitude'),
            dataIndex: 'GpsLatitude',
            key: 'GpsLatitude',
            sorter: true,
            width: 100,
        },
        {
            title: t('fishing_location.gps_longitude'),
            dataIndex: 'GpsLongitude',
            key: 'GpsLongitude',
            sorter: true,
            width: 100,
        },
        {
            title: t('fishing_location.nominal_water_depth'),
            dataIndex: 'NominalWaterDepth',
            key: 'NominalWaterDepth',
            sorter: true,
            width: 130,
        },
        {
            title: t('fishing_location.is_inside_club_waters'),
            dataIndex: 'IsInsideClubWaters',
            key: 'IsInsideClubWaters',
            sorter: true,
            editable: false,
            align: "center",
            width: 100,
            render: (value, record) => (
                <YesNoCell value={record.IsInsideClubWaters} />
            )
        },
        {
            title: t('fishing_location.club_zone'),
            dataIndex: 'ClubZoneName',
            key: 'ClubZoneName',
            sorter: true,
            width: 100,
        },
        {
            title: t('fishing_location.state_point_score_zone'),
            dataIndex: 'StatePointScoreZoneName',
            key: 'StatePointScoreZoneName',
            sorter: true,
            width: 130,
        },
        {
            title: t('common.status'),
            dataIndex: 'StatusId',
            key: 'StatusId',
            sorter: true,
            align: "center",
            width: 65,
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.StatusId)}
                    description={CommonService.getStatusDescription(record.StatusId, false)} />
            )
        }
    ]

    const onFishingLocationsTableChange = (pagination, filters, sorter, extra) => {
        let data = fishingLocationsForm.getFieldsValue();
        if (!data || (data && !data.StatusIds)) {
            data.StatusIds = [Constant.StatusCode.Active];
        }

        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = fishingLocationsGridConfigOptions.sort;
        }
        searchFishingLocations(pagination.current, pagination.pageSize, sort, data);

        setFishingLocationsGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        });
    }

    const showFishingLocationsFilterLayout = () => {
        setOpenFishingLocationsFilter(true);
    }

    const closeFishingLocationsFilterLayout = () => {
        fishingLocationsForm.setFieldsValue(searchFishingLocation);
        setOpenFishingLocationsFilter(false);
    }

    const refreshFishingLocationsData = (isShowLoading = false) => {
        let data = fishingLocationsForm.getFieldsValue();
        if (!data || (data && !data.StatusIds)) {
            data.StatusIds = [Constant.StatusCode.Active];
        }
        setSearchFishingLocation(data);

        searchFishingLocations(1, fishingLocationsGridConfigOptions.pageSize, fishingLocationsGridConfigOptions.sort, data, isShowLoading);

        setFishingLocationsGridConfigOptionsValue(
            {
                ...fishingLocationsGridConfigOptions,
                pageNumber: 1
            }
        );
    }

    const resetFishingLocationsFilteFilter = () => {
        fishingLocationsForm.resetFields();
        setSearchFishingLocation({ StatusIds: [Constant.StatusCode.Active] });
    }

    const applyFishingLocationsFilter = () => {
        refreshFishingLocationsData(true);
        setOpenFishingLocationsFilter(false);
    }


    // 2. Club Point Score Zones
    const searchPointScoreZones = (pageNumber, pageSize, sort, query, isShowLoading = false) => {
        if (isShowLoading) {
            showLoading();
        }

        let params = { ...query };
        if (!params) params = { StatusIds: [Constant.StatusCode.Active] };

        SettingService.getPointScoreZones(pageNumber, pageSize, sort, params)
            .finally(() => {
                if (isShowLoading) {
                    dismissLoading();
                }
            })
            .then(result => {
                if (result.data) {
                    setPointScoreZones(result.data.ObjectList);
                    setTotalPointScoreZones(result.data.TotalItems);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const pointScoreZonesColumns = [
        {
            title: t('point_score_zone.zone_name'),
            dataIndex: 'Name',
            key: 'Name',
            sorter: true,
            defaultSortOrder: 'ascend',
            width: 100,
        },
        {
            title: t('point_score_zone.zone_description'),
            dataIndex: 'Description',
            key: 'Description',
            sorter: true,
            width: 160,
        },
        {
            title: t('common.status'),
            dataIndex: 'StatusId',
            key: 'StatusId',
            sorter: false,
            align: "center",
            width: 35,
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.StatusId)}
                    description={CommonService.getStatusDescription(record.StatusId, false)} />
            )
        }
    ]

    const onPointScoreZonesTableChange = (pagination, filters, sorter, extra) => {
        let data = pointScoreZonesForm.getFieldsValue();
        if (!data || (data && !data.StatusIds)) {
            data.StatusIds = [Constant.StatusCode.Active];
        }

        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = pointScoreZonesGridConfigOptions.sort;
        }
        searchPointScoreZones(pagination.current, pagination.pageSize, sort, data);

        setPointScoreZonesGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        });
    }

    const showPointScoreZonesFilterLayout = () => {
        setOpenPointScoreZonesFilter(true);
    }

    const closePointScoreZonesFilterLayout = () => {
        pointScoreZonesForm.setFieldsValue(searchPointScoreZone);
        setOpenPointScoreZonesFilter(false);
    }

    const refreshPointScoreZonesData = (isShowLoading = false) => {
        let data = pointScoreZonesForm.getFieldsValue();
        if (!data || (data && !data.StatusIds)) {
            data.StatusIds = [Constant.StatusCode.Active];
        }
        setSearchPointScoreZone(data);

        searchPointScoreZones(1, pointScoreZonesGridConfigOptions.pageSize, pointScoreZonesGridConfigOptions.sort, data, isShowLoading);

        setPointScoreZonesGridConfigOptionsValue(
            {
                ...pointScoreZonesGridConfigOptions,
                pageNumber: 1
            }
        );
    }

    const resetPointScoreZonesFilter = () => {
        pointScoreZonesForm.resetFields();
        setSearchPointScoreZone({ StatusIds: [Constant.StatusCode.Active] });
    }

    const applyPointScoreZonesFilter = () => {
        refreshPointScoreZonesData(true);
        setOpenPointScoreZonesFilter(false);
    }


    // 3. Special Events
    const searchSpecialEvents = (pageNumber, pageSize, sort, query, isShowLoading = false) => {
        if (isShowLoading) {
            showLoading();
        }

        let params = { ...query };
        if (!params) params = { StatusIds: [Constant.StatusCode.Active] };

        SettingService.getSpecialEvents(pageNumber, pageSize, sort, params)
            .finally(() => {
                if (isShowLoading) {
                    dismissLoading();
                }
            })
            .then(result => {
                if (result.data) {
                    setSpecialEvents(result.data.ObjectList);
                    setTotalSpecialEvents(result.data.TotalItems);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const specialEventsColumns = [
        {
            title: t('special_event.event_name'),
            dataIndex: 'Name',
            key: 'Name',
            sorter: true,
            defaultSortOrder: 'ascend',
            width: 150,
        },
        {
            title: t('special_event.event_description'),
            dataIndex: 'Description',
            key: 'Description',
            sorter: true,
            width: 260,
        },
        {
            title: t('common.status'),
            dataIndex: 'StatusId',
            key: 'StatusId',
            sorter: false,
            align: 'center',
            width: 35,
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.StatusId)}
                    description={CommonService.getStatusDescription(record.StatusId, false)} />
            )
        }
    ]

    const onSpecialEventsTableChange = (pagination, filters, sorter, extra) => {
        let data = specialEventsForm.getFieldsValue();
        if (!data || (data && !data.StatusIds)) {
            data.StatusIds = [Constant.StatusCode.Active];
        }

        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = specialEventsGridConfigOptions.sort;
        }
        searchSpecialEvents(pagination.current, pagination.pageSize, sort, data);

        setSpecialEventsGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        });
    }

    const showSpecialEventsFilterLayout = () => {
        setOpenSpecialEventsFilter(true);
    }

    const closeSpecialEventsFilterLayout = () => {
        specialEventsForm.setFieldsValue(searchSpecialEvent);
        setOpenSpecialEventsFilter(false);
    }

    const refreshSpecialEventsData = (isShowLoading = false) => {
        let data = specialEventsForm.getFieldsValue();
        if (!data || (data && !data.StatusIds)) {
            data.StatusIds = [Constant.StatusCode.Active];
        }
        setSearchSpecialEvent(data);

        searchSpecialEvents(1, specialEventsGridConfigOptions.pageSize, specialEventsGridConfigOptions.sort, data, isShowLoading);

        setSpecialEventsGridConfigOptionsValue(
            {
                ...specialEventsGridConfigOptions,
                pageNumber: 1
            }
        );
    }

    const resetSpecialEventsFilter = () => {
        specialEventsForm.resetFields();
        setSearchSpecialEvent({ StatusIds: [Constant.StatusCode.Active] });
    }

    const applySpecialEventsFilter = () => {
        refreshSpecialEventsData(true);
        setOpenSpecialEventsFilter(false);
    }

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight())
        }, 100)

        showLoading();
        setYesNos([{
            Id: 1,
            Name: t("common.yes")
        },
        {
            Id: 2,
            Name: t("common.no")
        }]);

        Promise.all([
            SettingService.getFishingLocations(fishingLocationsGridConfigOptions.pageNumber, fishingLocationsGridConfigOptions.pageSize, fishingLocationsGridConfigOptions.sort, { StatusIds: [Constant.StatusCode.Active] }, true),
            SettingService.getPointScoreZones(pointScoreZonesGridConfigOptions.pageNumber, pointScoreZonesGridConfigOptions.pageSize, pointScoreZonesGridConfigOptions.sort, { StatusIds: [Constant.StatusCode.Active] }),
            SettingService.getSpecialEvents(specialEventsGridConfigOptions.pageNumber, specialEventsGridConfigOptions.pageSize, specialEventsGridConfigOptions.sort, { StatusIds: [Constant.StatusCode.Active] }),

            SettingService.getStatusCodes(),
            SettingService.getClubZones(),
            SettingService.getStatePointScoreZones()
        ])
            .finally(() => {
                dismissLoading();
            })
            .then((response) => {
                if (response[0].data) {
                    setFishingLocations(response[0].data.ObjectList);
                    setTotalFishingLocations(response[0].data.TotalItems);
                }

                if (response[1].data) {
                    setPointScoreZones(response[1].data.ObjectList);
                    setTotalPointScoreZones(response[1].data.TotalItems);
                }

                if (response[2].data) {
                    setSpecialEvents(response[2].data.ObjectList);
                    setTotalSpecialEvents(response[2].data.TotalItems);
                }

                if (response[3].data) {
                    setStatuses(response[3].data);
                }

                if (response[4].data) {
                    setClubZones(response[4].data);
                }

                if (response[5].data) {
                    setStatePointScoreZones(response[5].data);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);

        return () => {
            SettingService.cancelRequest();
            clearTimeout(timeoutFn);
        }
    }, [])

    const isClubMemberOrCommittee = () => {
        return currentPosition != null && (currentPosition.PositionTypeId === Constant.PositionType.ClubMember || currentPosition.PositionTypeId === Constant.PositionType.ClubCommittee);
    }

    return (
        <>
        {isClubMemberOrCommittee() &&
        <div className="club-defaults">
            <Breadcrumb>
                <Breadcrumb.Item>{t("menu.club_day_log_activity_defaults_lists")}</Breadcrumb.Item>
            </Breadcrumb>

            <Tabs defaultActiveKey="1">
                <TabPane className='fishing-location-management' tab={t("menu.club_fishing_Locations")} key="club_fishing_Locations">
                    <Row className="fix-item-container">
                        <Col xs={{ span: 24 }} className="text-right extra-btn-m-t">
                            <div className="m-t-10">
                                <Button onClick={showFishingLocationsFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon className="anticon anticon-search" />}>{t("common.filter")}</Button>
                            </div>
                        </Col>
                    </Row>
                    <Row className="m-t-10 section-table" gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Table
                                {...GetTableConfigs(fishingLocatonsColumns, fishingLocations, totalFishingLocations, "Id", onFishingLocationsTableChange, fishingLocationsGridConfigOptions.pageNumber)}
                            />
                        </Col>
                    </Row>
                </TabPane>
                <TabPane className='point-score-zone-management' tab={t("menu.point_score_zones")} key="club_point_score_zones">
                    <Row className="fix-item-container">
                        <Col xs={{ span: 24 }} className="text-right extra-btn-m-t">
                            <div className="m-t-10">
                                <Button onClick={showPointScoreZonesFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon className="anticon anticon-search" />}>{t("common.filter")}</Button>
                            </div>
                        </Col>
                    </Row>
                    <Row className="m-t-10 section-table" gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Table
                                {...GetTableConfigs(pointScoreZonesColumns, pointScoreZones, totalPointScoreZones, "Id", onPointScoreZonesTableChange, pointScoreZonesGridConfigOptions.pageNumber)}
                            />
                        </Col>
                    </Row>
                </TabPane>
                <TabPane className='special-event-management' tab={t("menu.special_events")} key="club_special_events">
                    <Row className="fix-item-container">
                        <Col xs={{ span: 24 }} className="text-right extra-btn-m-t">
                            <div className="m-t-10">
                                <Button onClick={showSpecialEventsFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon className="anticon anticon-search" />}>{t("common.filter")}</Button>
                            </div>
                        </Col>
                    </Row>
                    <Row className="m-t-10 section-table" gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Table
                                {...GetTableConfigs(specialEventsColumns, specialEvents, totalSpecialEvents, "Id", onSpecialEventsTableChange, specialEventsGridConfigOptions.pageNumber)}
                            />
                        </Col>
                    </Row>
                </TabPane>
            </Tabs>



            {/* Fishing Locations  */}
            <Drawer
                title={t("common.filter")}
                width={378}
                onClose={closeFishingLocationsFilterLayout}
                open={openFishingLocationsFilter}
                closeIcon={false}
                styles={{
                    body: {
                        paddingBottom: 80,
                    }
                }}
                extra={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button type="link" onClick={resetFishingLocationsFilteFilter}>{t("common.reset_filter")}</Button>
                        <Button onClick={closeFishingLocationsFilterLayout} icon={<CloseIcon />} type="link" />
                    </Space>
                }
                footer={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button onClick={closeFishingLocationsFilterLayout}>{t("common.cancel")}</Button>
                        <Button onClick={applyFishingLocationsFilter} type="primary">
                            {t("common.apply")}
                        </Button>
                    </Space>
                }>
                <Form
                    layout="vertical"
                    {...layout}
                    form={fishingLocationsForm}
                    name="fishingLocationsForm"
                    key='fishingLocationsForm'
                    initialValues={{ StatusIds: [Constant.StatusCode.Active] }}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="Name"
                                label={t("fishing_location.location_name")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="GpsLatitude"
                                label={t("fishing_location.gps_latitude")}
                            >
                                <MaskedInput mask={DUMB_GPS_LATTITUDE_MASK} allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="GpsLongitude"
                                label={t("fishing_location.gps_longitude")}
                            >
                                <MaskedInput mask={DUMB_GPS_LONGITUDE_MASK} allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="NominalWaterDepth"
                                label={t("fishing_location.nominal_water_depth")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="InsideClubWaterIds"
                                label={t("fishing_location.is_inside_club_waters")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={yesNos ? yesNos.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="ClubZoneIds"
                                label={t("fishing_location.club_zone")}
                            >
                                <EnhancedSelect mode="single" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={clubZones ? clubZones.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="StatePointScoreZoneIds"
                                label={t("fishing_location.state_point_score_zone")}
                            >
                                <EnhancedSelect mode="single" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={statePointScoreZones ? statePointScoreZones.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="StatusIds"
                                label={t("common.status")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={statuses ? statuses.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>

            {/* Club Pont Score Zones  */}
            <Drawer
                title={t("common.filter")}
                width={378}
                onClose={closePointScoreZonesFilterLayout}
                open={openPointScoreZonesFilter}
                closeIcon={false}
                styles={{
                    body: {
                        paddingBottom: 80,
                    }
                }}
                extra={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button type="link" onClick={resetPointScoreZonesFilter}>{t("common.reset_filter")}</Button>
                        <Button onClick={closePointScoreZonesFilterLayout} icon={<CloseIcon />} type="link" />
                    </Space>
                }
                footer={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button onClick={closePointScoreZonesFilterLayout}>{t("common.cancel")}</Button>
                        <Button onClick={applyPointScoreZonesFilter} type="primary">
                            {t("common.apply")}
                        </Button>
                    </Space>
                }>
                <Form
                    layout="vertical"
                    {...layout}
                    form={pointScoreZonesForm}
                    name="pointScoreZonesForm"
                    key='pointScoreZonesForm'
                    initialValues={{ StatusIds: [Constant.StatusCode.Active] }}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="Name"
                                label={t("point_score_zone.zone_name")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="Description"
                                label={t("point_score_zone.zone_description")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="StatusIds"
                                label={t("common.status")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={statuses ? statuses.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>

            {/* Special Events  */}
            <Drawer
                title={t("common.filter")}
                width={378}
                onClose={closeSpecialEventsFilterLayout}
                open={openSpecialEventsFilter}
                closeIcon={false}
                styles={{
                    body: {
                        paddingBottom: 80,
                    }
                }}
                extra={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button type="link" onClick={resetSpecialEventsFilter}>{t("common.reset_filter")}</Button>
                        <Button onClick={closeSpecialEventsFilterLayout} icon={<CloseIcon />} type="link" />
                    </Space>
                }
                footer={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button onClick={closeSpecialEventsFilterLayout}>{t("common.cancel")}</Button>
                        <Button onClick={applySpecialEventsFilter} type="primary">
                            {t("common.apply")}
                        </Button>
                    </Space>
                }>
                <Form
                    layout="vertical"
                    {...layout}
                    form={specialEventsForm}
                    name="specialEventsForm"
                    key='specialEventsForm'
                    initialValues={{ StatusIds: [Constant.StatusCode.Active] }}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="Name"
                                label={t("special_event.event_name")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="Description"
                                label={t("special_event.event_description")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="StatusIds"
                                label={t("common.status")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={statuses ? statuses.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </div>
        }
        
        {!isClubMemberOrCommittee() &&
            <>
                <Table
                    style={{ visibility: 'hidden', height: '0px' }}
                    {...GetTableConfigs([], [], 0, "Id", null, null)}
                />
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_access")} />
            </>
        }
        </>
    )

}

export default ClubDefaults;
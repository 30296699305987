import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import './documentManagement.scss';
import { Constant, CommonService, StorageService, DocumentService } from '../../../services/services';
import { useTranslation } from 'react-i18next';
import { GetTableConfigs, ActionButtonsCell, DateTimeCell, YesNoCell, StatusCell } from '../../../components/customTable/customTable'
import LoadingContext from '../../../components/loading/loadingContext'
import EnhancedSelect from '../../../components/enhancedSelect/enhancedSelect';
import { FilterAltIcon, EyeIcon, CloseIcon } from '../../../components/icons/icons';
import { Row, Col, Table, Button, DatePicker, Modal, Form, Drawer, Space, Input, Select, Typography, Empty } from 'antd';
import CreateEditDocument from './createEditDocument';
import { useGlobalState } from '../../../utilities/globalState'
import PubSub from 'pubsub-js'

const { confirm } = Modal;
const { Option } = Select;
const { Title } = Typography;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const DocumentManagement = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext);
    const [filterForm] = Form.useForm();
    const [entities, setEntities] = useState([]);
    const [documentCategories, setDocumentCategories] = useState([]);

    const [totalItems, setTotalItems] = useState(0);
    const [documents, setDocuments] = useState([]);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [searchDocument, setSearchDocument] = useState({ StatusIds: Constant.StatusCode.Active });
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [openFilter, setOpenFilter] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isCopy, setIsCopy] = useState(false);

    const [gridConfigOptions, setGridConfigOptionsValue] = useState({
        pageNumber: 1,
        pageSize: CommonService.getPageSize(),
        sort: "DocumentDate-descend",
    })
    var [currentPosition] = useGlobalState('currentPosition');

    const search = (pageNumber, pageSize, sort, query, isShowLoading = false) => {
        if (isShowLoading) {
            showLoading();
        }

        let params = { ...query };
        if (!params) params = { StatusIds: [Constant.StatusCode.Active] };

        if (params.DocumentDate) {
            if (typeof params.DocumentDate === 'string' || params.DocumentDate instanceof String) {
                var length = params.DocumentDate.split('/').length;
                if (length >= 3) {
                    params.DocumentDate = dayjs(params.DocumentDate, Constant.DateFormatYYYMMDD);
                }
            } else {
                params.DocumentDate = params.DocumentDate.format(Constant.DateFormatYYYMMDD);
            }
        }

        DocumentService.getDocuments(pageNumber, pageSize, sort, params)
        .finally(() => {
            if (isShowLoading) {
                dismissLoading();
            }
        })
        .then(result => {
            if (result.data) {
                setDocuments(result.data.ObjectList);
                setTotalItems(result.data.TotalItems);
            }
        })
        .catch(error => {
            CommonService.handleErrorResponse(error);
        })
    }

    const addDocuments = () => {
        setIsEdit(false);
        setIsCopy(false);
        setVisibleModal(true);
    }

    const editDocuments = () => {
        setIsEdit(true);
        setIsCopy(false);
        setVisibleModal(true);
    }

    const deleteDocuments = async () => {
        confirm({
            title: t("document.delete_documents_confirm"),
            okText: t("common.yes"),
            cancelText: t("common.no"),
            onOk() {
                if (selectedRowKeys.length === 0) {
                    return;
                }
                var ids = selectedRowKeys;
                showLoading();

                DocumentService.deleteDocuments(ids)
                    .finally(() => {
                        dismissLoading();
                    })
                    .then(result => {
                        refreshData();
                    })
                    .catch(error => {
                        CommonService.handleErrorResponse(error);
                    });
            }
        });
    }

    const handleCancel = (refresh) => {
        setVisibleModal(false);
        if (refresh) {
            refreshData();
        }
    }

    const statusCodes = [
        {
            Value: Constant.StatusCode.Active,
            Description: t("common.active")
        },
        {
            Value: Constant.StatusCode.Inactive,
            Description: t("common.inactive")
        }
    ]

    const clubColumns = [
        {
            title: t('document.view_document'),
            key: 'Id',
            render: (value, record) => {
                return <>
                    {
                        record.Url &&
                        <ActionButtonsCell data={record} btnList={[
                            {
                                Description: t("common.view"),
                                Icon: <EyeIcon />,
                                ColorClass: Constant.ColorClass.LightBlue,
                                Hide: false,
                                NoMargin: true,
                                Type: Constant.ButtonType.Link,
                                Link: record.Url
                            }
                        ]} />
                    }
                </>

            },
            align: 'center',
            width: 35,
            fixed: 'left',
        },
        {
            title: t('document.title'),
            dataIndex: 'Title',
            key: 'Title',
            sorter: true,
            width: 150,
        },
        {
            title: t('document.file_name'),
            dataIndex: 'FileName',
            key: 'FileName',
            sorter: true,
            width: 150,
        },
        {
            title: t('document.category'),
            dataIndex: 'DocumentCategoryName',
            key: 'DocumentCategoryName',
            sorter: true,
            width: 100,
        },
        {
            title: t('document.date'),
            dataIndex: 'DocumentDate',
            key: 'DocumentDate',
            defaultSortOrder: 'descend',
            sorter: true,
            width: 45,
            ellipsis: true,
            render: (value, record) => (
                <DateTimeCell value={record.DocumentDate} />
            )
        },
        {
            title: t('document.club_members'),
            dataIndex: 'AccessClubMembers',
            key: 'AccessClubMembers',
            align: 'center',
            sorter: false,
            ellipsis: true,
            width: 82,
            render: (value, record) => (
                <YesNoCell value={record.AccessClubMembers} />
            )
        },
        {
            title: t('document.club_committee'),
            dataIndex: 'AccessClubCommittee',
            key: 'AccessClubCommittee',
            align: 'center',
            sorter: false,
            ellipsis: true,
            width: 92,
            render: (value, record) => (
                <YesNoCell value={record.AccessClubCommittee} />
            )
        },
        {
            title: t('document.state_members'),
            dataIndex: 'AccessStateMembers',
            key: 'AccessStateMembers',
            align: 'center',
            sorter: false,
            ellipsis: true,
            width: 82,
            render: (value, record) => (
                <YesNoCell value={record.AccessStateMembers} />
            )
        },
        {
            title: t('document.state_committee'),
            dataIndex: 'AccessStateCommittee',
            key: 'AccessStateCommittee',
            align: 'center',
            sorter: false,
            ellipsis: true,
            width: 92,
            render: (value, record) => (
                <YesNoCell value={record.AccessStateCommittee} />
            )
        },
        {
            title: t('document.public'),
            dataIndex: 'AccessPublic',
            key: 'AccessPublic',
            align: 'center',
            sorter: false,
            ellipsis: true,
            width: 40,
            render: (value, record) => (
                <YesNoCell value={record.AccessPublic} />
            )
        },
        {
            title: t('common.status'),
            dataIndex: 'StatusId',
            key: 'StatusId',
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.StatusId)}
                    description={CommonService.getStatusDescription(record.StatusId)} />
            ),
            sorter: false,
            width: 40
        }
    ]

    const stateColumns = [
        {
            title: t('document.view_document'),
            key: 'Id',
            render: (value, record) => {
                return <>
                    {
                        record.Url &&
                        <ActionButtonsCell data={record} btnList={[
                            {
                                Description: t("common.view"),
                                Icon: <EyeIcon />,
                                ColorClass: Constant.ColorClass.LightBlue,
                                Hide: false,
                                NoMargin: true,
                                Type: Constant.ButtonType.Link,
                                Link: record.Url
                            }
                        ]} />
                    }
                </>

            },
            align: 'center',
            width: 35,
            fixed: 'left',
        },
        {
            title: t('document.title'),
            dataIndex: 'Title',
            key: 'Title',
            sorter: true,
            width: 140,
        },
        {
            title: t('document.file_name'),
            dataIndex: 'FileName',
            key: 'FileName',
            sorter: true,
            width: 140,
        },
        {
            title: t('document.category'),
            dataIndex: 'DocumentCategoryName',
            key: 'DocumentCategoryName',
            sorter: true,
            width: 100,
        },
        {
            title: t('document.date'),
            dataIndex: 'DocumentDate',
            key: 'DocumentDate',
            defaultSortOrder: 'descend',
            sorter: true,
            width: 45,
            ellipsis: true,
            render: (value, record) => (
                <DateTimeCell value={record.DocumentDate} />
            )
        },
        {
            title: t('document.club_committee'),
            dataIndex: 'AccessClubCommittee',
            key: 'AccessClubCommittee',
            align: 'center',
            sorter: false,
            ellipsis: true,
            width: 90,
            render: (value, record) => (
                <YesNoCell value={record.AccessClubCommittee} />
            )
        },
        {
            title: t('document.state_members'),
            dataIndex: 'AccessStateMembers',
            key: 'AccessStateMembers',
            align: 'center',
            sorter: false,
            ellipsis: true,
            width: 80,
            render: (value, record) => (
                <YesNoCell value={record.AccessStateMembers} />
            )
        },
        {
            title: t('document.state_committee'),
            dataIndex: 'AccessStateCommittee',
            key: 'AccessStateCommittee',
            align: 'center',
            sorter: false,
            ellipsis: true,
            width: 90,
            render: (value, record) => (
                <YesNoCell value={record.AccessStateCommittee} />
            )
        },
        {
            title: t('document.public'),
            dataIndex: 'AccessPublic',
            key: 'AccessPublic',
            align: 'center',
            sorter: false,
            ellipsis: true,
            width: 50,
            render: (value, record) => (
                <YesNoCell value={record.AccessPublic} />
            )
        },
        {
            title: t('common.status'),
            dataIndex: 'StatusId',
            key: 'StatusId',
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.StatusId)}
                    description={CommonService.getStatusDescription(record.StatusId)} />
            ),
            sorter: false,
            width: 45
        }
    ]

    const isClub = () => {
        if (typeof (currentPosition.PositionTypeId) === 'undefined') {
            currentPosition = StorageService.getCurrentPosition();
        }

        if (currentPosition && currentPosition.EntityTypeId === Constant.EntityType.Club) {
            return true;
        } else {
            return false;
        }
    }

    const mainColumns = (!isClub() ? stateColumns : clubColumns).map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                disabled: col.disabled,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition,
                selectionKey: col.selectionKey,
                selectionDisplay: col.selectionDisplay,
                validateAtStart: col.validateAtStart,
                allowClear: col.allowClear
            }),
        };
    })

    const onTableChange = (pagination, filters, sorter, extra) => {
        let data = filterForm.getFieldsValue();
        setSearchDocument(data);

        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = gridConfigOptions.sort;
        }
        search(pagination.current, pagination.pageSize, sort, data);

        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        });
    }

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (newSelectedRowKeys, newSelectedRows) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setSelectedDocuments(newSelectedRows);            
        },
        fixed: true
    }

    // Filters
    const showFilterLayout = () => {
        setOpenFilter(true);
    }

    const closeFilterLayout = () => {
        filterForm.setFieldsValue(searchDocument);
        setOpenFilter(false);
    }

    const resetFilter = () => {
        filterForm.resetFields();
        setSearchDocument({ StatusIds: Constant.StatusCode.Active });
    }

    const applyFilter = () => {

        refreshData(true);

        setOpenFilter(false);
    }
    
    const refreshData = (isShowLoading = false) => {
        setSelectedRowKeys([]);
        setSelectedDocuments([]);

        let data = filterForm.getFieldsValue();
        if (!data || (data && !data.StatusIds)) {
            data.StatusIds = [Constant.StatusCode.Active];
        }
        setSearchDocument(data);

        search(1, gridConfigOptions.pageSize, gridConfigOptions.sort, data, isShowLoading);

        setGridConfigOptionsValue(
            {
                ...gridConfigOptions,
                pageNumber: 1
            }
        );

    }

    const isClubMember = () => {
        return currentPosition != null && currentPosition.PositionTypeId === Constant.PositionType.ClubMember;
    }

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
        }, 100)

        showLoading();
        Promise.all([
            DocumentService.getDocuments(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, { StatusIds: Constant.StatusCode.Active }),
            DocumentService.getFilterDatas(false)
        ])
        .finally(() => {
            dismissLoading();

            setSelectedRowKeys([]);
            setSelectedDocuments([]);
            setSearchDocument({ StatusIds: [Constant.StatusCode.Active] });
        })
        .then(response => {
            if (response[0].data) {
                setDocuments(response[0].data.ObjectList);
                setTotalItems(response[0].data.TotalItems);
            }

            if (response[1].data) {
                setEntities(response[1].data.Entities);
                setDocumentCategories(response[1].data.DocumentCategories);
            }
        })
        .catch(error => {
            CommonService.handleErrorResponse(error);
        })

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);
        return () => {
            DocumentService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    return (
        <>
        {!isClubMember() &&
        <>
            <div className='document-view'>
                <div id={Constant.FixItemsContainerId}>
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                        <Title className='color-dark-blue' level={5}>{t("document.document_management")}</Title>
                    </Row>
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                        <Col xs={{ span: 24 }} className="text-right extra-btn-m-t">
                            <div>
                                <Button onClick={showFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon />}>{t("common.filter")}</Button>

                                <Button id='btnDelete' disabled={selectedDocuments.length === 0} danger className="m-l-10 secondary-btn" onClick={deleteDocuments}>
                                    {t("common.delete")}
                                </Button>

                                <Button id='btnEdit' disabled={selectedDocuments.length === 0} className={selectedDocuments.length > 0 ? "m-l-10 secondary-btn btn-outline-dark-blue" : "m-l-10 secondary-btn"} onClick={editDocuments}>
                                    {t("common.edit")}
                                </Button>

                                <Button className="m-l-10 secondary-btn btn-solid-dark-blue" type="primary" onClick={addDocuments}>
                                    {t("common.add_new")}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row className='custom-section-table' gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                    <Col xs={{ span: 24 }}>
                        <Table
                            className=''
                            {...GetTableConfigs(mainColumns, documents, totalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                            rowSelection={rowSelection}
                        />
                    </Col>
                </Row>
            </div>

            {/* Filter */}
            <Drawer
                title={t("common.filter")}
                width={378}
                onClose={closeFilterLayout}
                open={openFilter}
                closeIcon={false}
                styles={{
                    body: {
                        paddingBottom: 80,
                    }
                }}
                extra={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                        <Button onClick={closeFilterLayout} icon={<CloseIcon />} type="link" />
                    </Space>
                }
                footer={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button onClick={closeFilterLayout}>{t("common.cancel")}</Button>
                        <Button onClick={applyFilter} type="primary">
                            {t("common.apply")}
                        </Button>
                    </Space>
                }>
                <Form
                    layout="vertical"
                    {...layout}
                    form={filterForm}
                    name="filterForm"
                    key='filterForm'
                    initialValues={{ StatusIds: [Constant.StatusCode.Active] }}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="Title"
                                label={t("document.title")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="FileName"
                                label={t("document.file_name")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="DocumentCategoryIds"
                                label={t("document.category")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) => (optionA?.value == optionB?.value)}
                                        options={documentCategories ? documentCategories.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="DocumentDate"
                                label={t('document.date')}
                            >
                                <DatePicker format={Constant.DateFormat} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="StatusIds"
                                label={t("common.status")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={statusCodes ? statusCodes.map(b => {
                                            return {
                                                label: b.Description,
                                                value: b.Value
                                            }
                                        }) : []}>
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>

            {/* Create/Edit Document Layout  */}
            {
                visibleModal &&
                <CreateEditDocument
                    handleCancel={handleCancel} 
                    isEdit={isEdit}
                    isCopy={isCopy}
                    documentCategories={documentCategories}
                    selectedRowKeys={selectedRowKeys}
                    selectedDocuments={selectedDocuments} >

                </CreateEditDocument>
            }
        </>}

        {isClubMember() &&
            <>
                <Table
                    style={{ visibility: 'hidden', height: '0px' }}
                    {...GetTableConfigs(mainColumns, [], 0, "Id", onTableChange, gridConfigOptions.pageNumber)}
                />
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_access")} />
            </>
        }
        </>

    )

}

export default DocumentManagement;
import React, { useState, useEffect } from 'react';
import { Modal, Table, Row, Col, Button, Checkbox, Input, Form, Select, Tabs, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import LoadingContext from '../../../components/loading/loadingContext'
import { useGlobalState } from './../../../utilities/globalState'
import { Constant, CommonService, TrophyService } from '../../../services/services';
import { GetTableConfigs, StatusCell, EditableCell, TextAndButtonCell } from '../../../components/customTable/customTable'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import './addUpdateTrophy.scss';
import EnhancedSelect from '../../../components/enhancedSelect/enhancedSelect';

const { confirm } = Modal;
const { TextArea } = Input;
const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};
const { TabPane } = Tabs;

const AddUpdateTrophy = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [isEdit, setIsEdit] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [dateRestrictionData, setDateRestrictionData] = useState([]);
    const [form] = Form.useForm();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [currentPosition] = useGlobalState('currentPosition');
    const [selectedSumCountTrophiesRowKeys, setSelectedSumCountTrophiesRowKeys] = useState([]);
    const [selectedIndividualEntityTrophiesRowKeys, setSelectedIndividualEntityTrophiesRowKeys] = useState([]);
    const [sumCountTrophiesData, setSumCountTrophiesData] = useState([]);
    const [individualEntityTrophiesData, setIndividualEntityTrophiesData] = useState([]);
    const [trophyTableLayoutData, setTrophyTableLayoutData] = useState([]);
    const [sumCountTrophiesColumns, setSumCountTrophiesColumns] = useState([]);
    const [individualEntityTrophiesColumns, setIndividualEntityTrophiesColumns] = useState([]);
    const [canSubmit, setCanSubmit] = useState(false);

    const statusCodes = [
        {
            Value: Constant.StatusCode.Active,
            Description: t("common.active")
        },
        {
            Value: Constant.StatusCode.Inactive,
            Description: t("common.inactive")
        }
    ]

    const getTrophyTableLayoutColumns = (data) => {
        if (data && data.length > 0) {
            let cols = []
            for (var i = 1; i <= 9; i++) {
                let fieldName = `Field${i}`
                if (data[0][fieldName]) {
                    cols.push({
                        dataIndex: fieldName,
                        key: fieldName
                    })
                }
            }
            return cols

        }
        return []
    }

    const getTrophyTableLayoutData = () => {
        showLoading()
        TrophyService.getTrophyTableLayoutData()
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    setTrophyTableLayoutData(result.data)
                    let sumCountTrophies = result.data.filter(x => x.TrophyTableLayoutTypeId == Constant.TrophyTableLayoutType.Trophies)
                    let individualEntityTrophies = result.data.filter(x => x.TrophyTableLayoutTypeId == Constant.TrophyTableLayoutType.IndividualTrophy)

                    setSumCountTrophiesColumns(getTrophyTableLayoutColumns(sumCountTrophies))
                    setSumCountTrophiesData(sumCountTrophies)
                    setIndividualEntityTrophiesColumns(getTrophyTableLayoutColumns(individualEntityTrophies))
                    setIndividualEntityTrophiesData(individualEntityTrophies)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }


    useEffect(() => {
        if (props.visible) {
            if (!props.objectData || !props.objectData.Id) {
                setIsEdit(false)
                if (props.trophySelectionData && props.trophySelectionData.AdditionalDateRestrictions) {
                    setDateRestrictionData(props.trophySelectionData.AdditionalDateRestrictions.map(x => Object.assign({}, x)))
                }
            } else {
                form.setFieldsValue(props.objectData)
                setIsEdit(true)

                setTimeout(() => {
                    onFormValuesChange(null, null, true)
                }, 100);

                if (props.objectData.AdditionDateRestrictions) {
                    setDateRestrictionData(props.objectData.AdditionDateRestrictions.map(x => Object.assign({}, x)))

                    let selected = props.objectData.AdditionDateRestrictions.filter(d => d.IsSelected).map(d => d.Id)
                    setSelectedRowKeys(selected)
                }

                if (props.objectData.TrophyTableLayoutId) {
                    setSelectedSumCountTrophiesRowKeys([props.objectData.TrophyTableLayoutId])
                }

                if (props.objectData.IndividualTrophyTableLayoutId) {
                    setSelectedIndividualEntityTrophiesRowKeys([props.objectData.IndividualTrophyTableLayoutId])
                }
            }
        }
    }, [props.visible])

    useEffect(() => {
        getTrophyTableLayoutData()
    }, [])

    const getErrorMessage = () => {
        let trophy = form.getFieldsValue()
        if (!trophy.Name) {
            return t("trophy_management.trophy_name_required")
        }

        if (!trophy.TrophyTypeId) {
            return t("trophy_management.trophy_awarded_to_required")
        }

        if (!trophy.FirstSortOrderId) {
            return t("trophy_management.first_sort_order_required")
        }

        if (!trophy.SecondSortOrderId) {
            return t("trophy_management.second_sort_order_required")
        }

        if (!trophy.ThirdSortOrderId) {
            return t("trophy_management.third_sort_order_required")
        }

        return null
    }

    const closeForm = () => {
        if (isDirty) {
            confirm({
                title: t("common.confirm"),
                icon: <ExclamationCircleOutlined />,
                content: t("common.submit_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    if (canSubmit) {
                        onSubmit()
                    } else {
                        let message = getErrorMessage()
                        if (message) {
                            CommonService.presentToast('error', message)
                        }
                    }

                },
                onCancel() {
                    props.handleCancel()
                }

            });
        } else {
            props.handleCancel()
        }
    }

    const onSubmit = () => {
        if (canSubmit) {
            if (isEdit) {
                showLoading()
                let trophy = form.getFieldsValue()
                trophy.AdditionDateRestrictions = dateRestrictionData

                if (selectedSumCountTrophiesRowKeys && selectedSumCountTrophiesRowKeys.length > 0) {
                    trophy.TrophyTableLayoutId = selectedSumCountTrophiesRowKeys[0]
                } else {
                    trophy.TrophyTableLayoutId = null
                }

                if (selectedIndividualEntityTrophiesRowKeys && selectedIndividualEntityTrophiesRowKeys.length > 0) {
                    trophy.IndividualTrophyTableLayoutId = selectedIndividualEntityTrophiesRowKeys[0]
                } else {
                    trophy.IndividualTrophyTableLayoutId = null
                }

                Object.assign(props.objectData, trophy)
                TrophyService.updateTrophy(props.objectData)
                    .finally(() => dismissLoading())
                    .then(result => {
                        CommonService.presentToast('success', t("trophy_management.update_trophy_successful"))
                        props.handleCancel(true)
                    })
                    .catch(error => CommonService.handleErrorResponse(error))
            } else {
                showLoading()
                let trophy = form.getFieldsValue()
                trophy.AdditionDateRestrictions = dateRestrictionData
                if (selectedSumCountTrophiesRowKeys && selectedSumCountTrophiesRowKeys.length > 0) {
                    trophy.TrophyTableLayoutId = selectedSumCountTrophiesRowKeys[0]
                }
                if (selectedIndividualEntityTrophiesRowKeys && selectedIndividualEntityTrophiesRowKeys.length > 0) {
                    trophy.IndividualTrophyTableLayoutId = selectedIndividualEntityTrophiesRowKeys[0]
                }

                TrophyService.addTrophy(trophy)
                    .finally(() => dismissLoading())
                    .then(result => {
                        CommonService.presentToast('success', t("trophy_management.create_trophy_successful"))
                        props.handleCancel(true)
                    })
                    .catch(error => CommonService.handleErrorResponse(error))
            }
        }
    }

    const handleOnRestrictedDateChange = (value, record) => {
        if (record) {
            let temp = dateRestrictionData.map(x => Object.assign({}, x));
            let index = dateRestrictionData.indexOf(record)
            if (temp[index]) {
                if (value) {
                    value = value.format('YYYY-MM-DD');
                }
                temp[index].IncludedDate = value;
                setDateRestrictionData(temp)
            }
        }
    }

    const dateRestrictionColumns = [
        {
            title: "",
            dataIndex: 'Name',
            key: 'Name'
        },
        {
            title: "",
            dataIndex: 'IncludedDate',
            key: 'IncludedDate',
            align: 'center',
            render: (value, record) => {
                if (record.Id > 3) {
                    let disabled = true
                    if (selectedRowKeys && selectedRowKeys.indexOf(record.Id) > -1) {
                        disabled = false
                    }

                    return <DatePicker format={Constant.DateFormat} value={record.IncludedDate ? dayjs(new Date(record.IncludedDate).toString()) : null} onChange={e => handleOnRestrictedDateChange(e, record)} allowClear disabled={disabled} />
                }

                return null
            },
        }
    ]

    const components = {
        body: {
            cell: EditableCell,
        },
    };

    const dateRestrictionColumnMapping = dateRestrictionColumns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange
            }),
        }
    })

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys, newSelectedRows) => {
            if (newSelectedRows && dateRestrictionData) {
                let tempList = dateRestrictionData.map(x => {
                    x.IsSelected = false
                    return x
                })
                for (let i = 0; i < newSelectedRows.length; i++) {
                    let date = tempList.find(p => p.Id == newSelectedRows[i].Id)
                    if (date) {
                        date.IsSelected = true;
                    }
                }

                for (let i = 0; i < tempList.length; i++) {
                    if (newSelectedRowKeys.indexOf(tempList[i].Id) < 0) {
                        tempList[i].IncludedDate = null
                    }
                }

                setDateRestrictionData(tempList)
            }
            setSelectedRowKeys(newSelectedRowKeys)
        },
        columnWidth: 50
    }

    const getZoneType = () => {
        if (currentPosition && (currentPosition.PositionTypeId == Constant.PositionType.ClubMember || currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee)) {
            return t("fishing_location.club_zone")
        }

        if (currentPosition && currentPosition.PositionTypeId == Constant.PositionType.StateExcutive) {
            return t("fishing_location.state_zone")
        }

        return ""
    }

    const getApprovalStatus = () => {
        if (currentPosition && (currentPosition.PositionTypeId == Constant.PositionType.ClubMember || currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee)) {
            return t("fishing_activity.club_approval_status")
        }

        if (currentPosition && currentPosition.PositionTypeId == Constant.PositionType.StateExcutive) {
            return t("fishing_activity.state_approval_status")
        }

        return ""
    }

    const getSumCountTrophiesColumns = () => {
        let columns = [
            {
                title: "",
                dataIndex: 'Field1',
                key: 'Field1'
            },
            {
                title: "",
                dataIndex: 'Field2',
                key: 'Field2'
            },
            {
                title: "",
                dataIndex: 'Field3',
                key: 'Field3'
            },
            {
                title: "",
                dataIndex: 'Field4',
                key: 'Field4'
            }
        ]
        return columns
    }

    const getIndividualEntryTrophiesColumns = () => {
        let columns = [
            {
                title: "",
                dataIndex: 'Field1',
                key: 'Field1'
            },
            {
                title: "",
                dataIndex: 'Field2',
                key: 'Field2'
            },
            {
                title: "",
                dataIndex: 'Field3',
                key: 'Field3'
            },
            {
                title: "",
                dataIndex: 'Field4',
                key: 'Field4'
            },
            {
                title: "",
                dataIndex: 'Field5',
                key: 'Field5'
            },
            {
                title: "",
                dataIndex: 'Field6',
                key: 'Field6'
            },
            {
                title: "",
                dataIndex: 'Field7',
                key: 'Field7'
            },
            {
                title: "",
                dataIndex: 'Field8',
                key: 'Field8'
            },
            {
                title: "",
                dataIndex: 'Field9',
                key: 'Field9'
            }
        ]
        return columns
    }

    const sumCountTrphiesRowSelection = {
        selectedRowKeys: selectedSumCountTrophiesRowKeys,
        onChange: (newSelectedRowKeys, newSelectedRows) => {
            let newKey = newSelectedRowKeys
            if (selectedSumCountTrophiesRowKeys) {
                newKey = newSelectedRowKeys.filter(k => selectedSumCountTrophiesRowKeys.indexOf(k) < 0)
            }
            setSelectedSumCountTrophiesRowKeys(newKey)
            if(newKey && newKey.length > 0) {
                setSelectedIndividualEntityTrophiesRowKeys([])
            }
        },
        columnWidth: 50,
        hideSelectAll: true
    }

    const indivitualEntityTrophiesRowSelection = {
        selectedRowKeys: selectedIndividualEntityTrophiesRowKeys,
        onChange: (newSelectedRowKeys, newSelectedRows) => {
            let newKey = newSelectedRowKeys
            if (selectedIndividualEntityTrophiesRowKeys) {
                newKey = newSelectedRowKeys.filter(k => selectedIndividualEntityTrophiesRowKeys.indexOf(k) < 0)
            }
            setSelectedIndividualEntityTrophiesRowKeys(newKey)
            if(newKey && newKey.length > 0) {
                setSelectedSumCountTrophiesRowKeys([])
            }
        },
        columnWidth: 50,
        hideSelectAll: true
    }

    const onFormValuesChange = (changedValues, allValues, ignoreDirty = false) => {
        if (!ignoreDirty) {
            setIsDirty(true)
        }

        let trophy = form.getFieldsValue()
        if (!trophy || !trophy.Name || !trophy.TrophyTypeId || !trophy.FirstSortOrderId ||
            !trophy.SecondSortOrderId || !trophy.ThirdSortOrderId) {
            setCanSubmit(false)
        } else {
            setCanSubmit(true)
        }
    }

    return (
        <div>
            <Modal
                title={isEdit ? t("trophy_management.edit_trophy") : t("trophy_management.insert_trophy")}
                open={true}
                onCancel={closeForm}
                confirmLoading={false}
                maskClosable={false}
                centered
                width={1200}
                className='add-update-trophy-modal'
                footer={[
                    <Button key={0} size="large" onClick={closeForm}>{t("common.cancel")}</Button>,
                    <Button key={1} size="large" type="primary" disabled={!canSubmit} onClick={onSubmit}>{isEdit ? t("common.submit") : t("common.insert")}</Button>
                ]}
            >
                <Form
                    layout="vertical"
                    {...layout}
                    form={form}
                    name="trophyForm"
                    key='trophyForm'
                    onFinish={onSubmit}
                    onValuesChange={onFormValuesChange}
                    initialValues={{ StatusId: Constant.StatusCode.Active }}
                >
                    <Row gutter={[Constant.SpaceConstant.HorizontalGutter,]}>
                        <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="Name"
                                label={t("trophy_management.trophy_name")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("trophy_management.trophy_name_required"),
                                    },
                                ]}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 6 }}>
                            <Form.Item
                                name="TrophyTypeId"
                                label={t("trophy_management.trophy_awarded_to")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("trophy_management.trophy_awarded_to_required"),
                                    },
                                ]}
                            >
                                <EnhancedSelect
                                    showSearch optionFilterProp="children"
                                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}>
                                    {
                                        props.trophySelectionData && props.trophySelectionData.TrophyTypes && props.trophySelectionData.TrophyTypes.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                    }
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 6 }}>
                            <Form.Item
                                name="StatusId"
                                label={t("common.status")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("common.status_is_required"),
                                    },
                                ]}
                            >
                                <EnhancedSelect disabled={!isEdit}>
                                    {
                                        statusCodes.map((n, index) => <Option key={index} value={n.Value}>{n.Description}</Option>)
                                    }
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }}>
                            <Form.Item
                                name="Description"
                                label={t("trophy_management.trophy_description")}
                            >
                                <TextArea allowClear rows={2} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }}>
                            <Tabs defaultActiveKey="1">
                                <TabPane tab={t("trophy_management.sort_order")} key="sort_order">
                                    <Row gutter={[Constant.SpaceConstant.HorizontalGutter,]} >
                                        <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                                            <Form.Item
                                                name="FirstSortOrderId"
                                                label={t("trophy_management.first_sort_order")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("trophy_management.first_sort_order_required"),
                                                    },
                                                ]}
                                            >
                                                <EnhancedSelect
                                                    showSearch optionFilterProp="children"
                                                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}>
                                                    {
                                                        props.trophySelectionData && props.trophySelectionData.FirstSortOrders && props.trophySelectionData.FirstSortOrders.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                                    }
                                                </EnhancedSelect>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                                            <Form.Item
                                                name="SecondSortOrderId"
                                                label={t("trophy_management.second_sort_order")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("trophy_management.second_sort_order_required"),
                                                    },
                                                ]}
                                            >
                                                <EnhancedSelect
                                                    showSearch optionFilterProp="children"
                                                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}>
                                                    {
                                                        props.trophySelectionData && props.trophySelectionData.SecondSortOrders && props.trophySelectionData.SecondSortOrders.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                                    }
                                                </EnhancedSelect>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }}>
                                            <Form.Item
                                                name="ThirdSortOrderId"
                                                label={t("trophy_management.third_sort_order")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("trophy_management.third_sort_order_required"),
                                                    },
                                                ]}
                                            >
                                                <EnhancedSelect
                                                    showSearch optionFilterProp="children"
                                                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}>
                                                    {
                                                        props.trophySelectionData && props.trophySelectionData.ThirdSortOrders && props.trophySelectionData.ThirdSortOrders.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                                    }
                                                </EnhancedSelect>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tab={t("trophy_management.filters_to_apply")} key="filters_to_apply">
                                    <Row gutter={[Constant.SpaceConstant.HorizontalGutter,]}>
                                        {
                                            currentPosition && (currentPosition.PositionTypeId == Constant.PositionType.ClubMember || currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee) &&
                                            <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                                                <Form.Item
                                                    name="FinancialTypeIds"
                                                    label={t("member.financial_membership_type")}
                                                >
                                                    <EnhancedSelect allowClear mode="multiple" maxTagCount='responsive'
                                                        showSearch optionFilterProp="children"
                                                        filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}>
                                                        {
                                                            props.trophySelectionData && props.trophySelectionData.FinancialTypes && props.trophySelectionData.FinancialTypes.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                                        }
                                                    </EnhancedSelect>
                                                </Form.Item>
                                            </Col>
                                        }

                                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                                            <Form.Item
                                                name="FishingTypeIds"
                                                label={t("member.fishing_membership_type")}
                                            >
                                                <EnhancedSelect allowClear mode="multiple" maxTagCount='responsive'
                                                    showSearch optionFilterProp="children"
                                                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}>
                                                    {
                                                        props.trophySelectionData && props.trophySelectionData.FishingTypes && props.trophySelectionData.FishingTypes.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                                    }
                                                </EnhancedSelect>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                                            <Form.Item
                                                name="FishingCategoryIds"
                                                label={t("trophy_management.fishing_membership_category")}
                                            >
                                                <EnhancedSelect allowClear mode="multiple" maxTagCount='responsive'
                                                    showSearch optionFilterProp="children"
                                                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}>
                                                    {
                                                        props.trophySelectionData && props.trophySelectionData.FishingCategories && props.trophySelectionData.FishingCategories.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                                    }
                                                </EnhancedSelect>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                                            <Form.Item
                                                name="BoatMethodIds"
                                                label={t("trophy_management.boat_method")}
                                            >
                                                <EnhancedSelect allowClear mode="multiple" maxTagCount='responsive'
                                                    showSearch optionFilterProp="children"
                                                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}>
                                                    {
                                                        props.trophySelectionData && props.trophySelectionData.BoatMethods && props.trophySelectionData.BoatMethods.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                                    }
                                                </EnhancedSelect>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                                            <Form.Item
                                                name="LineClassIds"
                                                label={t("fishing_activity.line_class")}
                                            >
                                                <EnhancedSelect allowClear mode="multiple" maxTagCount='responsive'
                                                    showSearch optionFilterProp="children"
                                                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}>
                                                    {
                                                        props.trophySelectionData && props.trophySelectionData.LineClasses && props.trophySelectionData.LineClasses.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                                    }
                                                </EnhancedSelect>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                                            <Form.Item
                                                label={t("trophy_management.fly_fishing")}
                                                name="IsFlyFishing"
                                                valuePropName="checked">
                                                <Checkbox />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                                            <Form.Item
                                                label={t("fishing_activity.species")}
                                                name="FishSpeciesIds">
                                                <EnhancedSelect allowClear mode="multiple" maxTagCount='responsive'
                                                    showSearch optionFilterProp="children"
                                                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}>
                                                    {
                                                        props.trophySelectionData && props.trophySelectionData.FishSpecies && props.trophySelectionData.FishSpecies.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                                    }
                                                </EnhancedSelect>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                                            <Form.Item
                                                label={t("trophy_management.fishing_activity_type")}
                                                name="FishingActivityTypeIds">
                                                <EnhancedSelect allowClear mode="multiple" maxTagCount='responsive'
                                                    showSearch optionFilterProp="children"
                                                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}>
                                                    {
                                                        props.trophySelectionData && props.trophySelectionData.FishingActivityTypes && props.trophySelectionData.FishingActivityTypes.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                                    }
                                                </EnhancedSelect>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                                            <Form.Item
                                                label={getZoneType()}
                                                name="ZoneIds">
                                                <EnhancedSelect allowClear mode="multiple" maxTagCount='responsive'
                                                    showSearch optionFilterProp="children"
                                                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}>
                                                    {
                                                        props.trophySelectionData && props.trophySelectionData.Zones && props.trophySelectionData.Zones.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                                    }
                                                </EnhancedSelect>
                                            </Form.Item>
                                        </Col>

                                        {
                                            currentPosition && (currentPosition.PositionTypeId == Constant.PositionType.ClubMember || currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee) &&
                                            <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                                                <Form.Item
                                                    label={t("trophy_management.club_boundary")}
                                                    name="ClubBoundaryIds">
                                                    <EnhancedSelect allowClear mode="multiple" maxTagCount='responsive'
                                                        showSearch optionFilterProp="children"
                                                        filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}>
                                                        {
                                                            props.trophySelectionData && props.trophySelectionData.ClubBoundaries && props.trophySelectionData.ClubBoundaries.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                                        }
                                                    </EnhancedSelect>
                                                </Form.Item>
                                            </Col>
                                        }

                                        {
                                            currentPosition && currentPosition.PositionTypeId == Constant.PositionType.StateExcutive &&
                                            <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                                                <Form.Item
                                                    label={t("fishing_activity.state_point_score_zone")}
                                                    name="StatePointScoreZoneIds">
                                                    <EnhancedSelect allowClear mode="multiple" maxTagCount='responsive'
                                                        showSearch optionFilterProp="children"
                                                        filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}>
                                                        {
                                                            props.trophySelectionData && props.trophySelectionData.StatePointScoreZones && props.trophySelectionData.StatePointScoreZones.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                                        }
                                                    </EnhancedSelect>
                                                </Form.Item>
                                            </Col>
                                        }

                                        {
                                            currentPosition && (currentPosition.PositionTypeId == Constant.PositionType.ClubMember || currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee) &&
                                            <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                                                <Form.Item
                                                    label={t("trophy_management.club_event_restrictions")}
                                                    name="EventRestrictionsIds">
                                                    <EnhancedSelect allowClear mode="multiple" maxTagCount='responsive'
                                                        showSearch optionFilterProp="children"
                                                        filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}>
                                                        {
                                                            props.trophySelectionData && props.trophySelectionData.ClubEventRestrictions && props.trophySelectionData.ClubEventRestrictions.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                                        }
                                                    </EnhancedSelect>
                                                </Form.Item>
                                            </Col>
                                        }

                                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                                            <Form.Item
                                                label={t("trophy_management.state_event_restrictions")}
                                                name="EventRestrictionsIds">
                                                <EnhancedSelect allowClear mode="multiple" maxTagCount='responsive'
                                                    showSearch optionFilterProp="children"
                                                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}>
                                                    {
                                                        props.trophySelectionData && props.trophySelectionData.StateEventRestrictions && props.trophySelectionData.StateEventRestrictions.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                                    }
                                                </EnhancedSelect>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                                            <Form.Item
                                                label={getApprovalStatus()}
                                                name="ApprovalStatusIds">
                                                <EnhancedSelect allowClear mode="multiple" maxTagCount='responsive'
                                                    showSearch optionFilterProp="children"
                                                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}>
                                                    {
                                                        props.trophySelectionData && props.trophySelectionData.ApprovalStatuses && props.trophySelectionData.ApprovalStatuses.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                                    }
                                                </EnhancedSelect>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                                            <Form.Item
                                                label={t("trophy_management.boat_categories")}
                                                name="BoatCategoryIds">
                                                <EnhancedSelect allowClear mode="multiple" maxTagCount='responsive'
                                                    showSearch optionFilterProp="children"
                                                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}>
                                                    {
                                                        props.trophySelectionData && props.trophySelectionData.BoatCategories && props.trophySelectionData.BoatCategories.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                                    }
                                                </EnhancedSelect>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} className='m-t-10'>
                                            <div className='color-black bold-text'>Additional Date Restrictions</div>
                                        </Col>

                                        <Col xs={{ span: 24 }} className='m-t-10'>
                                            <Table
                                                components={components}
                                                rowClassName={() => 'editable-row'}
                                                rowSelection={rowSelection}
                                                {...GetTableConfigs(dateRestrictionColumnMapping, dateRestrictionData, dateRestrictionData.length, "Id", null, 1, null, false)}
                                            />
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tab={t("trophy_management.trophy_table_layout_options")} key="trophy_table_layout_options">
                                    <Row>
                                        <Col xs={{ span: 24 }}>
                                            <div>{t("trophy_management.sum_count_trophies")}</div>
                                            <Table
                                                rowSelection={sumCountTrphiesRowSelection}
                                                {...GetTableConfigs(sumCountTrophiesColumns, sumCountTrophiesData, sumCountTrophiesData.length, "Id", null, 1, null, false)}
                                            />
                                        </Col>
                                        <Col xs={{ span: 24 }}>
                                            <div>{t("trophy_management.indivitual_entry_trophies")}</div>
                                            <Table
                                                rowSelection={indivitualEntityTrophiesRowSelection}
                                                {...GetTableConfigs(individualEntityTrophiesColumns, individualEntityTrophiesData, individualEntityTrophiesData.length, "Id", null, 1, null, false)}
                                            />
                                        </Col>
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </Form>

            </Modal>
        </div>
    )

}

export default AddUpdateTrophy
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
    Constant,
    CommonService,
    StorageService,
    RecordService
} from '../../services/services'
import { Table, Button, Row, Col, Form, Tabs, Modal, Drawer, Space, Checkbox, Badge } from 'antd';
import { GetTableConfigs, ActionButtonsCell, EditableCell, DateTimeCell } from '../../components/customTable/customTable'
import LoadingContext from '../../components/loading/loadingContext'
import { useGlobalState } from '../../utilities/globalState'

const { confirm } = Modal;
const _ = require("lodash");

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const components = {
    body: {
        cell: EditableCell,
    },
};

const ReActiveRecord = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const { showLoading, dismissLoading } = React.useContext(LoadingContext);
    const [originalRecords, setOriginalRecords] = useState([]);
    const [records, setRecords] = useState([]);

    var [currentPosition] = useGlobalState('currentPosition');
    const [enableSubmit, setEnableSubmit] = useState(false);
   
    const onSubmit = () => {
        var allErrorMessages = [];

        setRecords(current =>
            current.map(obj => {
                var errorMessages = [];

                if (!obj.StatusId) {
                    errorMessages.push({
                        key: 'StatusId',
                        value: t('common.status_is_required')
                    })
                }

                allErrorMessages.push(...errorMessages);
                return {
                    ...obj,
                    required: errorMessages.length > 0 ? true : false,
                    ErrorMessages: errorMessages,
                };
            }),
        );

        if (allErrorMessages.length > 0) {            
            CommonService.presentToast('error', allErrorMessages.map(x => x.value).join(', '));
            return;
        }

        records.forEach(record => {
            if (record.RecordDate) {
                if (typeof record.RecordDate === 'string' || record.RecordDate instanceof String) {
                    var length = record.RecordDate.split('/').length;
                    if (length >= 3) {
                        record.RecordDate = moment(record.RecordDate, Constant.DateFormat).format(Constant.DateFormatYYYMMDD);
                    }
                } else {
                    record.RecordDate = record.RecordDate.format(Constant.DateFormatYYYMMDD);
                }
            }
        });

        showLoading();
        RecordService.updateRecordStatus(records)
            .finally(() => {
                dismissLoading();
            })
            .then(result => {
                CommonService.presentToast('success', t('record_management.update_record_successful'));
                props.handleCancel(true);
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const canSubmit = (newRecords) => {
        if (newRecords && newRecords.length > 0) {
            return true;
        }
        return false;
    }

    const submitConfirmation = () => {
        function customizer(newRecords, oldRecords) {
            if (newRecords.length !== oldRecords.length)
                return true;

            for (var i = 0; i < newRecords.length; i ++) {
                if (((newRecords[i].StatusId !== null || oldRecords[i].StatusId !== null) && newRecords[i].StatusId !== oldRecords[i].StatusId)) {
                    return true;
                }
            }

            return false;
        }
        
        var newRecords = _.sortBy(records, [function(m) { return m.Id; }]);
        var oldRecords = _.sortBy(originalRecords, [function(m) { return m.Id; }]);
        var changed = _.isEqualWith(newRecords, oldRecords, customizer);
        if (changed) {
            confirm({
                title: t("common.submit_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    onSubmit();
                },
                onCancel() {
                    props.handleCancel(true);
                }
            });
        }
        else {
            props.handleCancel(false);
        }
    }


    const onClose = () => {
        submitConfirmation();
    }

    const handleOnChange = (value, record, dataIndex) => {
        if (record) {
            var index = records.findIndex(x => x.Id === record.Id);
            _.update(records[index], dataIndex, function (n) { 
                return value;
            });
        }

        setEnableSubmit(canSubmit(records));
    }

    const statusCodes = [
        {
            Value: Constant.StatusCode.Active,
            Description: t("common.active")
        },
        {
            Value: Constant.StatusCode.Inactive,
            Description: t("common.inactive")
        }
    ]


    const isClub = () => {
        if (typeof (currentPosition.PositionTypeId) === 'undefined') {
            currentPosition = StorageService.getCurrentPosition();
        }

        if (currentPosition && currentPosition.EntityTypeId === Constant.EntityType.Club) {
            return true;
        } else {
            return false;
        }
    }

    const columns = [
        {
            title: t('fishing_activity.species'),
            dataIndex: 'FishSpeciesName',
            key: 'FishSpeciesName',
            width: 130,
        },
        {
            title: t('member.club'),
            dataIndex: 'ClubEntityAcronym',
            key: 'ClubEntityAcronym',
            width: 80,
            hidden: isClub()
        },
        {
            title: t('record_management.record_category'),
            dataIndex: 'RecordCategoryName',
            key: 'RecordCategoryName',
            width: 80,
        },
        {
            title: t('fishing_activity.line_class'),
            dataIndex: 'LineClassName',
            key: 'LineClassName',
            width: 80,
        },
        {
            title: t('record_management.record_environment'),
            dataIndex: 'RecordEnvironmentName',
            key: 'RecordEnvironmentName',
            width: 100,
        },
        {
            title: t('fishing_activity.weight'),
            dataIndex: 'FishWeight',
            key: 'FishWeight',
            cellType: Constant.CellType.DecimalInput,
            width: 80,
        },
        {
            title: t('record_management.record_date'),
            dataIndex: 'RecordDate',
            key: 'RecordDate',
            cellType: Constant.CellType.Date,
            width: 100
        },
        {
            title: t('record_management.fishing_location'),
            dataIndex: 'LocationName',
            key: 'LocationName',
            width: 80,
        },
        {
            title: t('member.first_name'),
            dataIndex: 'FirstName',
            key: 'FirstName',
            width: 80,
        },
        {
            title: t('member.surname'),
            dataIndex: 'Surname',
            key: 'Surname',
            width: 80,
        },
        {
            title: <span className='required'>{ t('common.status') }</span>,
            dataIndex: 'StatusId',
            key: 'StatusId',
            editable: true,
            extraData: statusCodes,
            required: true,
            cellType: Constant.CellType.StatusSelection,
            selectionKey: "Value",
            selectionDisplay: "Description",
            width: 90,
            allowClear: false,
            handleOnChange: handleOnChange
        }
    ]

    const mainColumns = (columns).map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                disabled: col.disabled,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition,
                selectionKey: col.selectionKey,
                selectionDisplay: col.selectionDisplay,
                validateAtStart: col.validateAtStart,
                allowClear: col.allowClear,
                selectionSort: col.selectionSort,
                onRemoveFile: col.onRemoveFile,
                beforeUploadFile: col.beforeUploadFile,
                getFileList: col.getFileList,
            }),
        };
    })

    useEffect(() => {
        showLoading();

        var newRecords = [...props.selectedRecords];
        setRecords(newRecords);
        setRecords(current =>
            current.map(obj => {
                return {
                    ...obj,
                    RecordDate: obj.RecordDate ? moment(obj.RecordDate).format(Constant.DateFormat) : null,
                };
            }),
        );
        setOriginalRecords(_.cloneDeep(newRecords));
        setEnableSubmit(canSubmit(newRecords));

        Promise.all([
        ])
        .finally(() => {            
            dismissLoading();
        })
        .then((values) => {
        });

        return () => {
        }
    }, [])

    return (
        <div>
            <Modal
                title={t("record_management.update_record")}
                centered
                forceRender
                open={true}
                onOk={onSubmit}
                confirmLoading={false}
                onCancel={onClose}
                okText={props.isEdit ? t("common.submit") : t("common.insert")}
                cancelText={t("common.cancel")}
                maskClosable={false}
                width={1450}
                footer={[
                    <Button key="back" onClick={onClose}>
                      {t("common.cancel")}
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} disabled={!enableSubmit} onClick={onSubmit}>
                      {t("common.submit")}
                    </Button>
                  ]}
            >
                <Form
                    {...layout}
                    name='form'
                    key='form'
                    onFinish={onSubmit}
                    component={false}
                    form={form}>

                    <Row className="m-t-10" gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Table
                                components={components}
                                rowClassName={() => 'editable-row'}
                                tableLayout='fixed'
                                columns={mainColumns} 
                                dataSource={records} 
                                pagination={false}
                                rowKey="Id"
                            />
                        </Col>
                    </Row>
                </Form>
            </Modal>
            
        </div>
    )
}

export default ReActiveRecord
import React, { useEffect, useState } from 'react';
import './createDayLog.scss';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
    Constant,
    CommonService,
    FishingActivityService,
    StorageService
} from '../../../services/services'
import { Breadcrumb, Button, Row, Col, Form, Input, InputNumber, DatePicker } from 'antd';
import { ChevronUpIcon, ChevronDownIcon } from '../../../components/icons/icons'
import LoadingContext from '../../../components/loading/loadingContext'
import { useGlobalState } from '../../../utilities/globalState'
import { useLocation } from "react-router-dom";
import TagCardFormView from "../../../components/tagCardFormView/tagCardFormView"
import TagCardTableView from "../../../components/tagCardTableView/tagCardTableView"
import EnhancedSelect from '../../../components/enhancedSelect/enhancedSelect';
import { useHistory } from "react-router-dom";
import PubSub from 'pubsub-js'

const _ = require("lodash");

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const CreateDayLog = (props) => {
    const { t } = useTranslation();
    var [currentPosition] = useGlobalState('currentPosition');

    const [loading, setLoading] = useState(false);
    const { showLoading, dismissLoading } = React.useContext(LoadingContext);
    const [boats, setBoats] = useState([]);
    const [boatOptions, setBoatOptions] = useState([]);
    const [boatCaptains, setBoatCaptains] = useState([]);
    const [boatCaptainOptions, setBoatCaptainOptions] = useState([]);
    const [allClubEvents, setAllClubEvents] = useState([]);
    const [clubEvents, setClubEvents] = useState([]);
    const [stateEvents, setStateEvents] = useState([]);
    const [noOfActivityEntries, setNoOfActivityEntries] = useState([]);
    const [entities, setEntities] = useState([]);
    const [tagCaptures, setTagCaptures] = useState([]);
    const [fishingMethods, setFishingMethods] = useState([]);
    const [anglers, setAnglers] = useState([]);
    const [anglerOptions, setAnglerOptions] = useState([]);
    const [activeAnglers, setActiveAnglers] = useState([]);
    const [inActiveAnglers, setInActiveAnglers] = useState([]);
    const [otherAnglers, setOtherAnglers] = useState([]);
    const [allFishingLocations, setAllFishingLocations] = useState([]);
    const [fishingLocations, setFishingLocations] = useState([]);
    const [fishSpecies, setFishSpecies] = useState([]);
    const [lineClasses, setLineClasses] = useState([]);
    const [fishingCategories, setFishingCategories] = useState([]);
    const [tagApprovals, setTagApprovals] = useState([]);
    const [allClubZones, setAllClubZones] = useState([]);
    const [clubZones, setClubZones] = useState([]);
    const [statePointScoreZones, setStatePointScoreZones] = useState([]);
    const [yesNos, setYesNos] = useState([]);
    const [extraLineItemEntry, setExtraLineItemEntry] = useState(1);
    const [extraLineItemEntries, setExtraLineItemEntries] = useState([]);
    const [showActivityHeaderMore, setShowActivityHeaderMore] = useState(false);
    const [showActivityLine, setShowActivityLine] = useState(false);
    const [isTableView, setIsTableView] = useState(false);
    const [disabledStateView, setDisabledStateView] = useState(false);
    var [isAddNew, setIsAddNew] = useState(true);
    const [isViewOnly, setIsViewOnly] = useState(false);
    const [isHeaderViewOnly, setIsHeaderViewOnly] = useState(false);

    var [activityHeaderId, setActivityHeaderId] = useState(-1);
    var [activityHeader, setActivityHeader] = useState({});
    var [activityLines, setActivityLines] = useState([]);

    const [refresh, setRefresh] = useState(false);

    const [headerForm] = Form.useForm();
    const location = useLocation();
    const history = useHistory();
    const [isApproved, setIsApproved] = useState(false);

    const onSaveActivityHeader = () => {
        showLoading();

        // Validate
        let data = headerForm.getFieldsValue();
        if (!activityHeader) {
            activityHeader = data;
        } else {
            activityHeader.ActivitySheetNumber = data.ActivitySheetNumber;
            activityHeader.EntityId = data.EntityId;
            activityHeader.BoatId = data.BoatId;
            activityHeader.BoatCaptainId = data.BoatCaptainId;
            activityHeader.ActivityDate = data.ActivityDate;
            activityHeader.PersonOnBoard = data.PersonOnBoard;
            activityHeader.HoursOnWater = data.HoursOnWater;
            activityHeader.NoOfActivityEntries = data.NoOfActivityEntries;
            activityHeader.StateEventId = data.StateEventId;
            activityHeader.ClubEventId = data.ClubEventId;
            activityHeader.DayLogId = data.DayLogId;
        }

        if (activityHeader.BoatId == null || typeof activityHeader.BoatId === undefined) {
            setShowActivityHeaderMore(false);
            CommonService.presentToast('error', t('fishing_activity.boat_is_required'));
            dismissLoading();
            return;
        }

        if (activityHeader.BoatCaptainId == null || typeof activityHeader.BoatCaptainId === undefined) {
            setShowActivityHeaderMore(false);
            CommonService.presentToast('error', t('fishing_activity.captain_is_required'));
            dismissLoading();
            return;
        }

        if (activityHeader.ActivityDate) {
            if (typeof activityHeader.ActivityDate === 'string' || activityHeader.ActivityDate instanceof String) {
                var length = activityHeader.ActivityDate.split('/').length;
                if (length >= 3) {
                    activityHeader.ActivityDate = dayjs(activityHeader.ActivityDate, Constant.DateFormatYYYMMDD);
                }
            } else {
                activityHeader.ActivityDate = activityHeader.ActivityDate.format(Constant.DateFormatYYYMMDD);
            }
        }

        if (activityHeader.ClubEventId == -1) {
            activityHeader.ClubEventId = null;
        }

        if (activityHeader.StateEventId == -1) {
            activityHeader.StateEventId = null;
        }

        // Add/Update
        FishingActivityService.addUpdateActivityHeader(activityHeader)
            .finally(() => {
                dismissLoading();
            })
            .then(result => {
                // CommonService.presentToast('success', activityHeader.Id > 0 ? t('fishing_activity.activity_header_update_successful') : t('fishing_activity.activity_header_create_successful'));
                setShowActivityLine(true);
                setShowActivityHeaderMore(true);
                setIsAddNew(false);
                if (result.data && result.data.Id) {
                    result.data.ClubEventId = result.data.ClubEventId > 0 ? result.data.ClubEventId : -1;
                    result.data.StateEventId = result.data.StateEventId > 0 ? result.data.StateEventId : -1;

                    setActivityHeaderId(result.data.Id);
                    setActivityHeader(result.data);
                    activityHeader.Id = result.data.Id;
                }

                if (result.data && result.data.ActivityLines && result.data.ActivityLines.length > 0) {
                    var newActivityLines = [];
                    for (var i = 0; i < result.data.ActivityLines.length; i++) {
                        result.data.ActivityLines[i].RecordId = i + 1;
                        result.data.ActivityLines[i].DateEntered = result.data.ActivityLines[i].DateEntered ? dayjs(result.data.ActivityLines[i].DateEntered, Constant.DateFormatYYYMMDD) : null;
                        if (result.data.ActivityLines[i].IsFlyfishing) {
                            result.data.ActivityLines[i].IsFlyfishing = 1;
                        } else if (!result.data.ActivityLines[i].IsFlyfishing) {
                            result.data.ActivityLines[i].IsFlyfishing = 2;
                        }

                        if (result.data.ActivityLines[i].IsSaltwater) {
                            result.data.ActivityLines[i].IsSaltwater = 1;
                        } else if (!result.data.ActivityLines[i].IsSaltwater) {
                            result.data.ActivityLines[i].IsSaltwater = 2;
                        }

                        if (result.data.ActivityLines[i].IsInsideClubWaters) {
                            result.data.ActivityLines[i].IsInsideClubWaters = 1;
                        } else if (!result.data.ActivityLines[i].IsInsideClubWaters) {
                            result.data.ActivityLines[i].IsInsideClubWaters = 2;
                        }

                        result.data.ActivityLines[i].ClubEventId = result.data.ActivityLines[i].ClubEventId > 0 ? result.data.ActivityLines[i].ClubEventId : -1;
                        result.data.ActivityLines[i].StateEventId = result.data.ActivityLines[i].StateEventId > 0 ? result.data.ActivityLines[i].StateEventId : -1;

                        newActivityLines.push(result.data.ActivityLines[i]);
                    }
                    setActivityLines(newActivityLines);
                } else {
                    var newActivityLines = [];
                    for (var i = 0; i < activityHeader.NoOfActivityEntries; i++) {
                        newActivityLines.push(newActivityLine(i + 1, activityHeader));
                    }
                    setActivityLines(newActivityLines);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const addActivityLines = () => {
        showLoading();
        setShowActivityLine(true);
        var newActivityLines = [];
        for (var i = 0; i < extraLineItemEntry; i++) {
            newActivityLines.push(newActivityLine(i + 1, activityHeader));
        }
        setActivityLines(newActivityLines);
        dismissLoading();
    }

    const handleExtraLineItemEntryOnChange = (e) => {
        setExtraLineItemEntry(e);
    }

    const handleActivityLines = (id) => {
        FishingActivityService.getActivityLinesByActivityHeaderId(id)
            .finally(() => {
            })
            .then(result => {
                if (result.data) {
                    var newActivityLines = [];
                    for (var i = 0; i < result.data.length; i++) {
                        result.data[i].RecordId = i + 1;
                        result.data[i].DateEntered = result.data[i].DateEntered ? dayjs(result.data[i].DateEntered, Constant.DateFormatYYYMMDD) : null;
                        if (result.data[i].IsFlyfishing) {
                            result.data[i].IsFlyfishing = 1;
                        } else if (!result.data[i].IsFlyfishing) {
                            result.data[i].IsFlyfishing = 2;
                        }

                        if (result.data[i].IsSaltwater) {
                            result.data[i].IsSaltwater = 1;
                        } else if (!result.data[i].IsSaltwater) {
                            result.data[i].IsSaltwater = 2;
                        }

                        if (result.data[i].IsInsideClubWaters) {
                            result.data[i].IsInsideClubWaters = 1;
                        } else if (!result.data[i].IsInsideClubWaters) {
                            result.data[i].IsInsideClubWaters = 2;
                        }

                        result.data[i].ClubEventId = result.data[i].ClubEventId > 0 ? result.data[i].ClubEventId : -1;
                        result.data[i].StateEventId = result.data[i].StateEventId > 0 ? result.data[i].StateEventId : -1;

                        newActivityLines.push(result.data[i]);
                    }
                    setActivityLines(newActivityLines);
                }
            });
    }

    const handleCancel = () => {
        var newActivityHeaderId = location.state && location.state.DayLogId > 0 ? location.state.DayLogId : -1;
        var previousUrl = location.state && location.state.PreviousUrl ? location.state.PreviousUrl : '';
        if (newActivityHeaderId > 0 && previousUrl.length > 0) {
            history.push(previousUrl, location.state);
            setTimeout(() => {
                PubSub.publish(Constant.PubSupType.PageChanged)
            }, 100);
        } else {
            setShowActivityLine(false);
            setShowActivityHeaderMore(false);
        }
    }

    const handleOnChange = (value, dataIndex) => {
        if (activityHeader) {
            _.update(activityHeader, dataIndex, function (n) {
                return value;
            });

            if (dataIndex === 'EntityId') {
                // Boats
                var newBoats = boats.filter(b => b.EntityId === value)
                setBoatOptions([
                    {
                        label: '',
                        options: [
                            {
                                label: t("fishing_activity.non_club_boat"),
                                value: 0,
                            },
                        ],
                    },
                    {
                        label: t("fishing_activity.club_boats"),
                        options: newBoats && newBoats.length > 0 ? newBoats.map((n, index) => {
                            return {
                                label: n.Name,
                                value: n.Id,
                            }
                        }) : [],
                    },
                ]);

                // Captains
                var newBoatCaptains = boatCaptains.filter(b => b.EntityId === value)
                setBoatCaptainOptions([
                    {
                        label: '',
                        options: [
                            {
                                label: t("fishing_activity.non_club_captain"),
                                value: -1,
                            },
                        ],
                    },
                    {
                        label: t("fishing_activity.our_club_captains"),
                        options: newBoatCaptains && newBoatCaptains.length > 0 ? newBoatCaptains.map((n, index) => {
                            return {
                                label: n.Name,
                                value: n.Id,
                            }
                        }) : [],
                    },
                ]);

                var newActiveAnglers = activeAnglers && activeAnglers.length > 0 ? activeAnglers.filter(b => b.EntityId === value) : [];
                var newInActiveAnglers = inActiveAnglers && inActiveAnglers.length > 0 ? inActiveAnglers.filter(b => b.EntityId === value) : [];
                var newOtherAnglers = otherAnglers && otherAnglers.length > 0 ? otherAnglers.filter(b => b.EntityId !== value) : [];
                setAnglerOptions([
                    {
                        label: '',
                        options: [
                            {
                                label: t("fishing_activity.non_member"),
                                value: -1,
                            },
                        ],
                    },
                    {
                        label: t("fishing_activity.club_active_members"),
                        options: newActiveAnglers && newActiveAnglers.length > 0 ? newActiveAnglers.map((n, index) => {
                            return {
                                label: n.Name,
                                value: n.Id,
                            }
                        }) : [],
                    },
                    // {
                    //     label: t("fishing_activity.club_inactive_members"),
                    //     options: newInActiveAnglers && newInActiveAnglers.length > 0 ? newInActiveAnglers.map((n, index) => {
                    //         return {
                    //             label: n.Name,
                    //             value: n.Id,
                    //         }
                    //     }) : [],
                    // },
                    {
                        label: t("fishing_activity.other_clubs_members"),
                        options: newOtherAnglers && newOtherAnglers.length > 0 ? newOtherAnglers.map((n, index) => {
                            return {
                                label: n.Name,
                                value: n.Id,
                            }
                        }) : [],
                    },
                ]);

                // Locations
                var newFishingLocations = allFishingLocations && allFishingLocations.length > 0 ? allFishingLocations.filter(b => b.EntityId === value) : [];
                setFishingLocations(newFishingLocations);

                // Club Events
                var newClubEvents = allClubEvents && allClubEvents.length > 0 ? allClubEvents.filter(b => b.EntityId === value) : [];
                setClubEvents([...[{
                    Id: -1,
                    Name: t("common.blank"),
                    EntityId: value,
                    SortOrder: 0
                }], ...newClubEvents]);

                // Club Zones
                var newClubZones = allClubZones && allClubZones.length > 0 ? allClubZones.filter(b => b.EntityId === value) : [];
                setClubZones(newClubZones);
            }
            else if (dataIndex === 'ActivityDate' && activityLines && activityLines.length > 0) {
                for (let i = 0; i < activityLines.length; i++) {
                    var activityLine = activityLines[i];
                    handlePointScores(activityHeader.ActivityDate, activityLine);
                }
            }
        }
    }

    const handlePointScores = (activityDate, activityLine) => {
        var activityDate = "";
        if (activityDate) {
            if (typeof activityDate === 'string' || activityDate instanceof String) {
                var length = activityDate.split('/').length;
                if (length >= 3) {
                    activityDate = dayjs(activityDate, Constant.DateFormatYYYMMDD);
                }
            } else {
                activityDate = activityDate.format(Constant.DateFormatYYYMMDD);
            }
        }

        var dateEntered = "";
        if (activityLine.DateEntered) {
            if (typeof activityLine.DateEntered === 'string' || activityLine.DateEntered instanceof String) {
                var length = activityLine.DateEntered.split('/').length;
                if (length >= 3) {
                    dateEntered = dayjs(activityLine.DateEntered, Constant.DateFormatYYYMMDD);
                }
            } else {
                dateEntered = activityLine.DateEntered.format(Constant.DateFormatYYYMMDD);
            }
        }

        if (activityLine.StateApprovalId === Constant.TagApprovalStatus.Approved || activityLine.StateApprovalId === Constant.TagApprovalStatus.Disqualified) {
            return;
        }

        FishingActivityService.getPointScores(activityDate, activityLine.AnglerId, activityLine.LineClassId, activityLine.ClubZoneId, activityLine.StatePointScoreZoneId, activityLine.FishSpeciesId, activityLine.TagCaptureId, activityLine.FishWeight, dateEntered)
            .then(result => {
                if (result.data) {
                    setActivityLines(current =>
                        current.map(obj => {
                            if (obj.Id === activityLine.Id) {
                                return {
                                    ...obj,
                                    ClubCapturePoints: result.data.ClubCapturePoints,
                                    ClubTagReleasePoints: result.data.ClubTagReleasePoints,
                                    ClubMeasureReleasePoints: result.data.ClubMeasureReleasePoints,
                                    ClubLeadReleasePoints: result.data.ClubLeadReleasePoints,
                                    StatePoints: result.data.StatePoints,
                                    StateCapturePoints: result.data.StateCapturePoints,
                                    StateTagReleasePoints: result.data.StateTagReleasePoints,
                                    StateMeasureReleasePoints: result.data.StateMeasureReleasePoints,
                                    StateLeadReleasePoints: result.data.StateLeadReleasePoints,
                                };
                            }
                            return obj;
                        }),
                    );
                }
            });
    }

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight())
        }, 100);

        showLoading();
        setRefresh(false);

        var newActivityHeaderId = location.state && location.state.DayLogId > 0 ? location.state.DayLogId : -1;
        setActivityHeaderId(newActivityHeaderId);
        setShowActivityLine(newActivityHeaderId > 0 ? true : false);
        setShowActivityHeaderMore(newActivityHeaderId > 0 ? true : false);
        setIsTableView(newActivityHeaderId > 0 ? true : false);

        if (location.state && location.state.ViewOnly) {
            setIsViewOnly(location.state.ViewOnly)
        }

        setYesNos([{
            Id: 1,
            Name: t("common.yes")
        },
        {
            Id: 2,
            Name: t("common.no")
        }]);

        var newExtraLineItemEntries = [];
        for (var i = 0; i < 20; i++) {
            newExtraLineItemEntries.push({ Id: i + 1, Name: (i + 1).toString() });
        }
        setExtraLineItemEntries(newExtraLineItemEntries);

        if (typeof (currentPosition.PositionTypeId) === 'undefined') {
            currentPosition = StorageService.getCurrentPosition();
        }

        if (typeof (currentPosition.PositionTypeId) === 'undefined' || currentPosition.PositionTypeId === Constant.PositionType.ClubMember || currentPosition.PositionTypeId === Constant.PositionType.ClubCommittee) {
            setDisabledStateView(true);
        } else {
            setDisabledStateView(false);
        }

        Promise.all([
            FishingActivityService.getActivityHeaderById(newActivityHeaderId),
            FishingActivityService.getActivityHeaderFilterDatas(),
        ])
            .finally(() => {
                dismissLoading();
            })
            .then((response) => {
                var entityId = currentPosition && currentPosition.EntityId > 0 ? currentPosition.EntityId : 0;

                if (response && response.length > 0) {
                    if (response[0].data) {
                        if (response[0].data.ActivityDate) {
                            response[0].data.ActivityDate = dayjs(response[0].data.ActivityDate, Constant.DateFormatYYYMMDD);
                        } else {
                            response[0].data.ActivityDate = null;
                        }

                        response[0].data.ClubEventId = response[0].data.ClubEventId > 0 ? response[0].data.ClubEventId : -1;
                        response[0].data.StateEventId = response[0].data.StateEventId > 0 ? response[0].data.StateEventId : -1;

                        headerForm.setFieldsValue(response[0].data);
                        setActivityHeader(response[0].data);
                        setIsAddNew(newActivityHeaderId > 0 ? false : true);
                        entityId = response[0].data.EntityId;

                        if (response[0].data && response[0].data.ActivityLines) {
                            if (response[0].data.ActivityLines && response[0].data.ActivityLines.length > 0) {
                                var newActivityLines = [];
                                let hasApprovedRecord = false
                                for (var i = 0; i < response[0].data.ActivityLines.length; i++) {
                                    response[0].data.ActivityLines[i].RecordId = i + 1;
                                    response[0].data.ActivityLines[i].DateEntered = response[0].data.ActivityLines[i].DateEntered ? dayjs(response[0].data.ActivityLines[i].DateEntered, Constant.DateFormatYYYMMDD) : null;
                                    if (response[0].data.ActivityLines[i].IsFlyfishing) {
                                        response[0].data.ActivityLines[i].IsFlyfishing = 1;
                                    } else if (!response[0].data.ActivityLines[i].IsFlyfishing) {
                                        response[0].data.ActivityLines[i].IsFlyfishing = 2;
                                    }

                                    if (response[0].data.ActivityLines[i].IsSaltwater) {
                                        response[0].data.ActivityLines[i].IsSaltwater = 1;
                                    } else if (!response[0].data.ActivityLines[i].IsSaltwater) {
                                        response[0].data.ActivityLines[i].IsSaltwater = 2;
                                    }

                                    if (response[0].data.ActivityLines[i].IsInsideClubWaters) {
                                        response[0].data.ActivityLines[i].IsInsideClubWaters = 1;
                                    } else if (!response[0].data.ActivityLines[i].IsInsideClubWaters) {
                                        response[0].data.ActivityLines[i].IsInsideClubWaters = 2;
                                    }

                                    response[0].data.ActivityLines[i].ClubEventId = response[0].data.ActivityLines[i].ClubEventId > 0 ? response[0].data.ActivityLines[i].ClubEventId : -1;
                                    response[0].data.ActivityLines[i].StateEventId = response[0].data.ActivityLines[i].StateEventId > 0 ? response[0].data.ActivityLines[i].StateEventId : -1;
                                    if (response[0].data.ActivityLines[i].ClubApprovalId && !hasApprovedRecord) {
                                        hasApprovedRecord = true
                                    }
                                    newActivityLines.push(response[0].data.ActivityLines[i]);
                                }
                                setIsApproved(hasApprovedRecord)
                                setActivityLines(newActivityLines);
                            } else {
                                var newActivityLines = [];
                                for (var i = 0; i < response[0].data.NoOfActivityEntries; i++) {
                                    newActivityLines.push(newActivityLine(i + 1, response[0].data));
                                }
                                setActivityLines(newActivityLines);
                            }
                        }
                    }

                    if (response[1].data) {
                        // Boats
                        setBoats(response[1].data.Boats);
                        var newBoats = response[1].data.Boats && response[1].data.Boats.length > 0 ? response[1].data.Boats.filter(b => b.EntityId === entityId) : [];
                        setBoatOptions([
                            {
                                label: '',
                                options: [
                                    {
                                        label: t("fishing_activity.non_club_boat"),
                                        value: 0,
                                    },
                                ],
                            },
                            {
                                label: t("fishing_activity.club_boats"),
                                options: newBoats.map((n, index) => {
                                    return {
                                        label: n.Name,
                                        value: n.Id,
                                    }
                                }),
                            },
                        ]);

                        // Captains
                        setBoatCaptains(response[1].data.BoatCaptains);
                        var newBoatCaptains = response[1].data.BoatCaptains && response[1].data.BoatCaptains.length > 0 ? response[1].data.BoatCaptains.filter(b => b.EntityId === entityId) : [];
                        setBoatCaptainOptions([
                            {
                                label: '',
                                options: [
                                    {
                                        label: t("fishing_activity.non_club_captain"),
                                        value: -1,
                                    },
                                ],
                            },
                            {
                                label: t("fishing_activity.our_club_captains"),
                                options: newBoatCaptains.map((n, index) => {
                                    return {
                                        label: n.Name,
                                        value: n.Id,
                                    }
                                }),
                            },
                        ]);

                        // Club Events
                        setAllClubEvents(response[1].data.ClubEvents);
                        var newClubEvents = response[1].data.ClubEvents && response[1].data.ClubEvents.length > 0 ? response[1].data.ClubEvents.filter(b => b.EntityId === entityId) : [];
                        setClubEvents([...[{
                            Id: -1,
                            Name: t("common.blank"),
                            EntityId: entityId,
                            SortOrder: 0
                        }], ...newClubEvents]);

                        setStateEvents([...[{
                            Id: -1,
                            Name: t("common.blank"),
                            SortOrder: 0
                        }], ...response[1].data.StateEvents]);
                        setNoOfActivityEntries(response[1].data.NoOfActivityEntries);
                        setEntities(response[1].data.Entities);
                        setTagCaptures(response[1].data.TagCaptures);
                        setFishingMethods(response[1].data.FishingMethods);

                        // Anglers
                        setActiveAnglers(response[1].data.ActiveAnglers ? response[1].data.ActiveAnglers : []);
                        setInActiveAnglers(response[1].data.InActiveAnglers ? response[1].data.InActiveAnglers : []);
                        setOtherAnglers(response[1].data.OtherAnglers ? response[1].data.OtherAnglers : []);
                        setAnglers([...response[1].data.ActiveAnglers ? response[1].data.ActiveAnglers : [],
                        ...response[1].data.InActiveAnglers ? response[1].data.InActiveAnglers : [],
                        ...response[1].data.OtherAnglers ? response[1].data.OtherAnglers : []]);

                        var newActiveAnglers = response[1].data.ActiveAnglers && response[1].data.ActiveAnglers.length > 0 ? response[1].data.ActiveAnglers.filter(b => b.EntityId === entityId) : [];
                        var newInActiveAnglers = response[1].data.InActiveAnglers && response[1].data.InActiveAnglers.length > 0 ? response[1].data.InActiveAnglers.filter(b => b.EntityId === entityId) : [];
                        var newOtherAnglers = response[1].data.OtherAnglers && response[1].data.OtherAnglers.length > 0 ? response[1].data.OtherAnglers.filter(b => b.EntityId !== entityId) : [];
                        setAnglerOptions([
                            {
                                label: '',
                                options: [
                                    {
                                        label: t("fishing_activity.non_member"),
                                        value: -1,
                                    },
                                ],
                            },
                            {
                                label: t("fishing_activity.club_active_members"),
                                options: newActiveAnglers && newActiveAnglers.length > 0 ? newActiveAnglers.map((n, index) => {
                                    return {
                                        label: n.Name,
                                        value: n.Id,
                                    }
                                }) : [],
                            },
                            // {
                            //     label: t("fishing_activity.club_inactive_members"),
                            //     options: newInActiveAnglers && newInActiveAnglers.length > 0 ? newInActiveAnglers.map((n, index) => {
                            //         return {
                            //             label: n.Name,
                            //             value: n.Id,
                            //         }
                            //     }) : [],
                            // },
                            {
                                label: t("fishing_activity.other_clubs_members"),
                                options: newOtherAnglers && newOtherAnglers.length > 0 ? newOtherAnglers.map((n, index) => {
                                    return {
                                        label: n.Name,
                                        value: n.Id,
                                    }
                                }) : [],
                            },
                        ]);

                        // Locations
                        setAllFishingLocations(response[1].data.FishingLocations);
                        var newFishingLocations = response[1].data.FishingLocations && response[1].data.FishingLocations.length > 0 ? response[1].data.FishingLocations.filter(b => b.EntityId === entityId) : [];
                        setFishingLocations(newFishingLocations);

                        setFishSpecies(response[1].data.FishSpecies);
                        setLineClasses(response[1].data.LineClasses);
                        setFishingCategories(response[1].data.FishingCategories);
                        setTagApprovals(response[1].data.TagApprovals);

                        // Club Zones
                        setAllClubZones(response[1].data.ClubZones);
                        var newClubZones = response[1].data.ClubZones && response[1].data.ClubZones.length > 0 ? response[1].data.ClubZones.filter(b => b.EntityId === entityId) : [];
                        setClubZones(newClubZones);

                        setStatePointScoreZones(response[1].data.StatePointScoreZones);
                    }
                }
            });

        setTimeout(() => {
            let pageName = location.state && location.state.DayLogId > 0 ? t("fishing_activity.edit_day_log") : null
            PubSub.publish(Constant.PubSupType.PageChanged, pageName)
        }, 100);
        return () => {
            FishingActivityService.cancelRequest();
            clearTimeout(timeoutFn);
        }
    }, [])

    const newActivityLine = (id, activityHeader) => {
        return {
            IsAddNew: true,
            RecordId: id,
            Id: id,
            ActivityHeaderId: activityHeader.Id,
            TagCaptureId: null,
            AnglerId: null,
            FishingMethodId: null,
            CaughtDate: null,
            CaughtTime: null,
            FishSpeciesId: null,
            TagNumber: null,
            FishingLocationId: null,
            GpsLatitude: null,
            GpsLongitude: null,
            IsFlyfishing: 2,
            IsSaltwater: 1,
            DateEntered: dayjs().format(Constant.DateFormat),
            ClubEventId: activityHeader.ClubEventId > 0 ? activityHeader.ClubEventId : -1,
            StateEventId: activityHeader.StateEventId > 0 ? activityHeader.StateEventId : -1,
            IsNonOffset: true
        };
    }

    const disableEditDayLog = () => {
        if (isViewOnly)
            return true
        if (currentPosition && currentPosition.PositionTypeId == Constant.PositionType.ClubMember && isApproved)
            return true
        return false
    }


    return (
        <div className="create-day-log">
            <Breadcrumb>
                <Breadcrumb.Item>{t("menu.boat_angler_day_log_record")}</Breadcrumb.Item>
            </Breadcrumb>
            <Form
                layout="vertical"
                {...layout}
                name='headerForm'
                key='headerForm'
                form={headerForm}
                onFinish={onSaveActivityHeader}>
                <Row gutter={24} className="m-t-10">
                    <Col xs={{ span: 24 }} className="text-right extra-btn-m-t">
                        <div>
                            {!showActivityHeaderMore &&
                                <Button type="text" icon={<ChevronDownIcon />} onClick={() => { setShowActivityHeaderMore(true) }} disabled={isAddNew} />
                            }
                            {showActivityHeaderMore &&
                                <Button type="text" icon={<ChevronUpIcon />} onClick={() => { setShowActivityHeaderMore(false) }} />
                            }
                        </div>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            label={t("fishing_activity.date")}
                            rules={[{ required: true, message: t("fishing_activity.date_is_required") }]}
                            name="ActivityDate" colon={false} labelAlign="left">
                            <DatePicker disabled={disableEditDayLog()} format={Constant.DateFormat} allowClear style={{ width: "100%" }} onChange={(e) => handleOnChange(e, 'ActivityDate')} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={t("fishing_activity.boat")}
                            rules={[{ required: true, message: t("fishing_activity.boat_is_required") }]}
                            name="BoatId" colon={false} labelAlign="left">
                            <EnhancedSelect showSearch optionFilterProp="label" allowClear={true}
                                maxTagCount='responsive'
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={boatOptions}
                                onChange={(e) => handleOnChange(e, 'BoatId')}
                                disabled={disableEditDayLog()}
                            >
                            </EnhancedSelect>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={t("fishing_activity.club")}
                            rules={[{ required: true, message: t("fishing_activity.club_is_required") }]}
                            name="EntityId" colon={false} labelAlign="left">
                            <EnhancedSelect showSearch optionFilterProp="label" allowClear={true}
                                maxTagCount='responsive'
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={entities ? entities.map(b => {
                                    return {
                                        label: b.Name,
                                        value: b.Id
                                    }
                                }) : []}
                                disabled={disabledStateView || disableEditDayLog()}
                                onChange={(e) => handleOnChange(e, 'EntityId')}
                            >
                            </EnhancedSelect>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={t("fishing_activity.activity_sheet_number")}
                            extra={t("fishing_activity.activity_sheet_number_placeholder")}
                            rules={[{ required: true, message: t("fishing_activity.activity_sheet_number_is_required") }]}
                            name="ActivitySheetNumber" colon={false} labelAlign="left">
                            <Input disabled={disableEditDayLog()} allowClear onChange={(e) => handleOnChange(e.target.value, 'ActivitySheetNumber')} />
                        </Form.Item>
                    </Col>
                </Row>
                {!showActivityHeaderMore &&
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                label={t("fishing_activity.captain")}
                                rules={[{ required: true, message: t("fishing_activity.captain_is_required") }]}
                                name="BoatCaptainId" colon={false} labelAlign="left">
                                <EnhancedSelect showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={boatCaptainOptions}
                                    onChange={(e) => handleOnChange(e, 'BoatCaptainId')}
                                    disabled={disableEditDayLog()}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={t("fishing_activity.person_on_board")}
                                extra={t("fishing_activity.person_on_board_placeholder")}
                                rules={[{ required: true, message: t("fishing_activity.person_on_board_is_required") }]}
                                name="PersonOnBoard" colon={false} labelAlign="left">
                                <InputNumber disabled={disableEditDayLog()} min={0} style={{ width: "100%" }} stringMode={true} onChange={(e) => handleOnChange(e, 'PersonOnBoard')} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={t("fishing_activity.hours_on_water")}
                                extra={t("fishing_activity.hours_on_water_placeholder")}
                                rules={[{ required: true, message: t("fishing_activity.hours_on_water_is_required") }]}
                                name="HoursOnWater" colon={false} labelAlign="left">
                                <InputNumber disabled={disableEditDayLog()} min={0} style={{ width: "100%" }} precision={1} step="0.1" stringMode={true} onChange={(e) => handleOnChange(e, 'HoursOnWater')} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={t("fishing_activity.day_log_id")}
                                extra={t("fishing_activity.auto_generated")}
                                rules={[{ required: true, message: t("fishing_activity.day_log_id_is_required") }]}
                                name="DayLogId" colon={false} labelAlign="left">
                                <Input allowClear disabled={true} />
                            </Form.Item>
                        </Col>
                    </Row>
                }
                {!showActivityHeaderMore &&
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                label={t("fishing_activity.no_of_activity_entries")}
                                rules={[{ required: true, message: t("fishing_activity.no_of_activity_entries_is_required") }]}
                                name="NoOfActivityEntries" colon={false} labelAlign="left">
                                <EnhancedSelect showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.value == input)}
                                    options={noOfActivityEntries ? noOfActivityEntries.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                    onChange={(e) => handleOnChange(e, 'NoOfActivityEntries')}
                                    disabled={disableEditDayLog()}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={t("fishing_activity.state_special_event")}
                                rules={[{ required: false, message: t("fishing_activity.state_special_event_is_required") }]}
                                name="StateEventId" colon={false} labelAlign="left">
                                <EnhancedSelect showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                    filterSort={(optionA, optionB) => (optionA?.sort == optionB?.sort)}
                                    options={stateEvents ? stateEvents.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id,
                                            sort: b.SortOrder
                                        }
                                    }) : []}
                                    onChange={(e) => handleOnChange(e, 'StateEventId')}
                                    disabled={disableEditDayLog()}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label={t("fishing_activity.club_special_event")}
                                rules={[{ required: false, message: t("fishing_activity.club_special_event_is_required") }]}
                                name="ClubEventId" colon={false} labelAlign="left">
                                <EnhancedSelect showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                    filterSort={(optionA, optionB) => (optionA?.sort == optionB?.sort)}
                                    options={clubEvents ? clubEvents.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id,
                                            sort: b.SortOrder
                                        }
                                    }) : []}
                                    onChange={(e) => handleOnChange(e, 'ClubEventId')}
                                    disabled={disableEditDayLog()}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                }
                {!showActivityLine && !disableEditDayLog() &&
                    <Row align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter]}>
                        <Col xs={{ span: 24 }} className="text-right m-t-25">

                            <Button className="m-l-10 secondary-btn btn-solid-dark-blue" type="primary" htmlType="submit" onClick={() => { setIsTableView(true) }}>
                                {t("fishing_activity.save_data_and_begin_entering_activities_table_view")}
                            </Button>

                            <Button className="m-l-10 secondary-btn btn-solid-dark-blue" type="primary" htmlType="submit" onClick={() => { setIsTableView(false) }}>
                                {t("fishing_activity.save_data_and_begin_entering_activities_form_view")}
                            </Button>
                        </Col>
                    </Row>
                }
            </Form>

            {showActivityLine && !disableEditDayLog() &&
                <Row gutter={24} className="m-t-10">
                    <Col xs={{ span: 24 }} className="text-right extra-btn-m-t">
                        <div>
                            <EnhancedSelect defaultValue={extraLineItemEntry} showSearch optionFilterProp="label" allowClear={true}
                                maxTagCount='responsive'
                                filterOption={(input, option) => (option?.value == input)}
                                options={extraLineItemEntries ? extraLineItemEntries.map(b => {
                                    return {
                                        label: b.Name,
                                        value: b.Id
                                    }
                                }) : []}
                                onChange={(e) => handleExtraLineItemEntryOnChange(e)}
                                style={{ width: 65 }}
                                align="center"
                            >
                            </EnhancedSelect>
                            <Button className="m-l-10 secondary-btn btn-solid-dark-blue" type="primary" onClick={addActivityLines}>
                                {t("fishing_activity.add_extra_line_items_to_this_day_log")}
                            </Button>

                            {!isTableView &&
                                <Button className="m-l-10 secondary-btn btn-solid-dark-blue" type="primary" onClick={() => { setIsTableView(true) }}>
                                    {t("fishing_activity.switch_to_table_view")}
                                </Button>
                            }

                            {isTableView &&
                                <Button className="m-l-10 secondary-btn btn-solid-dark-blue" type="primary" onClick={() => { setIsTableView(false) }}>
                                    {t("fishing_activity.switch_to_form_view")}
                                </Button>
                            }
                        </div>
                    </Col>
                </Row>
            }

            {showActivityLine && isTableView &&
                <TagCardTableView
                    handleCancel={handleCancel}
                    handleActivityLines={handleActivityLines}
                    showActivityLine={showActivityLine}
                    isTableView={isTableView}
                    tagCaptures={tagCaptures}
                    fishingMethods={fishingMethods}
                    anglers={anglers}
                    anglerOptions={anglerOptions}
                    fishingLocations={fishingLocations}
                    fishSpecies={fishSpecies}
                    lineClasses={lineClasses}
                    fishingCategories={fishingCategories}
                    tagApprovals={tagApprovals}
                    clubZones={clubZones}
                    statePointScoreZones={statePointScoreZones}
                    clubEvents={clubEvents}
                    stateEvents={stateEvents}
                    boatCaptains={boatCaptains}
                    activityHeader={activityHeader}
                    activityLines={activityLines}
                    yesNos={yesNos}
                    activityHeaderId={activityHeaderId}
                    disabledStateView={disabledStateView}
                    isViewOnly={isViewOnly} />
            }


            {showActivityLine && !isTableView &&
                <TagCardFormView
                    handleCancel={handleCancel}
                    handleActivityLines={handleActivityLines}
                    tagCaptures={tagCaptures}
                    fishingMethods={fishingMethods}
                    anglers={anglers}
                    anglerOptions={anglerOptions}
                    fishingLocations={fishingLocations}
                    fishSpecies={fishSpecies}
                    lineClasses={lineClasses}
                    fishingCategories={fishingCategories}
                    tagApprovals={tagApprovals}
                    clubZones={clubZones}
                    statePointScoreZones={statePointScoreZones}
                    clubEvents={clubEvents}
                    stateEvents={stateEvents}
                    boatCaptains={boatCaptains}
                    yesNos={yesNos}
                    activityHeader={activityHeader}
                    activityLines={activityLines}
                    activityHeaderId={activityHeaderId}
                    disabledStateView={disabledStateView}
                    isViewOnly={isViewOnly} />
            }
        </div>
    )
}

export default CreateDayLog
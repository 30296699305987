import React, { useEffect, useState } from 'react';
import './membershipManagement.scss';
import { useTranslation } from 'react-i18next';
import {
    Constant,
    CommonService,
    MembershipService
} from '../../../services/services'
import { Table, Button, Row, Col, Select, Form, Input, Tabs, Modal, Breadcrumb, Drawer, Space, DatePicker, Empty } from 'antd';
import { GetTableConfigs, DateTimeCell, ActionButtonsCell, StatusCell, FinancialEndCell } from '../../../components/customTable/customTable'
import { FilterAltIcon, CloseIcon, CopyIcon } from '../../../components/icons/icons'
import LoadingContext from '../../../components/loading/loadingContext'
import EnhancedSelect from '../../../components/enhancedSelect/enhancedSelect';
import CreateEditMembership from './createEditMembership';
import PubSub from 'pubsub-js';
import { useGlobalState } from '../../../utilities/globalState'
const { Option } = Select;
const { confirm } = Modal;
const { TabPane } = Tabs;
const _ = require("lodash");

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const MembershipManagement = (props) => {
    const { t } = useTranslation();
    const [formFilter] = Form.useForm();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext);
    const [totalItems, setTotalItems] = useState(0);
    const [members, setMembers] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [titles, setTitles] = useState([]);
    const [genders, setGenders] = useState([]);
    const [familyGroups, setFamilyGroups] = useState([]);
    const [fishingTypes, setFishingTypes] = useState([]);
    const [fishingCategories, setFishingCategories] = useState([]);
    const [financialTypes, setFinancialTypes] = useState([]);
    const [financialStatuses, setFinancialStatuses] = useState([]);
    const [positionTitles, setPositionTitles] = useState([]);
    const [memberStatuses, setMemberStatuses] = useState([]);
    const [states, setStates] = useState([]);
    const [financialYears, setFinancialYears] = useState([]);
    const [entities, setEntities] = useState([]);
    const [visibleModal, setVisibleModal] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isCopy, setIsCopy] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [searchMembership, setSearchMembership] = useState({ MemberStatusIds: [] });

    const [gridConfigOptions, setGridConfigOptionsValue] = useState({
        pageNumber: 1,
        pageSize: CommonService.getPageSize(),
        sort: "LastName-ascend",
    })
    const [currentPosition] = useGlobalState('currentPosition');

    const search = (pageNumber, pageSize, sort, query, isShowLoading = false) => {
        if (isShowLoading) {
            showLoading();
        }

        let params = { ...query };
        if (!params) params = { MemberStatusIds: [] };

        if (params.FinancialEnd) {
            params.FinancialEnd = params.FinancialEnd.format(Constant.DateFormatYYYMMDD);
        }

        MembershipService.searchMemberships(pageNumber, pageSize, sort, params)
            .finally(() => {
                if (isShowLoading) {
                    dismissLoading();
                }

                setSelectedRowKeys([]);
                setSelectedMembers([]);
            })
            .then(result => {
                if (result.data) {
                    setMembers(result.data.ObjectList);
                    setTotalItems(result.data.TotalItems);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const copyMember = (record) => {
        setIsEdit(false);
        setIsCopy(true);
        var newMember = _.clone(record);
        newMember.Id = -(selectedMembers.length + 1);
        newMember.IsAddNew = true;
        newMember.FirstName = null;
        newMember.LastName = null;

        if (newMember.MemberPositions && newMember.MemberPositions.length > 0) {
            newMember.MemberPositions.forEach(memberPosition => {
                memberPosition.MemberId = newMember.Id;
            });
        }
        setSelectedMembers([newMember]);
        setVisibleModal(true);
    }

    const addMembers = () => {
        setIsEdit(false);
        setIsCopy(false);
        setVisibleModal(true);
    }

    const editMembers = () => {
        setIsEdit(true);
        setIsCopy(false);
        setVisibleModal(true);
    }

    const deleteMembers = async () => {
        confirm({
            title: t("member.delete_members_confirm"),
            content: t("member.delete_warm_message"),
            okText: t("common.yes"),
            cancelText: t("common.no"),
            onOk() {
                if (selectedRowKeys.length === 0) {
                    return;
                }
                var ids = selectedRowKeys;
                showLoading();

                MembershipService.deleteMemberships(ids)
                    .finally(() => {
                        dismissLoading();
                    })
                    .then(result => {
                        refreshData();
                    })
                    .catch(error => {
                        CommonService.handleErrorResponse(error);
                    });
            }
        });
    }

    const handleCancel = (refresh) => {
        setVisibleModal(false);
        if (refresh) {
            refreshData();
        }

    }

    const getStateClubNumberTitle = () => {
        if (currentPosition != null && (currentPosition.EntityTypeId === Constant.EntityType.Gamebase || currentPosition.EntityTypeId === Constant.EntityType.National)) {
            return t('member.national_association_number');
        } else if (currentPosition != null && currentPosition.EntityTypeId === Constant.EntityType.State) {
            return t('member.state_association_number');
        } else {
            return t('member.club_number');
        }
    }

    const getStateClubNumberTitleWith = () => {
        if (currentPosition != null && (currentPosition.EntityTypeId === Constant.EntityType.Gamebase || currentPosition.EntityTypeId === Constant.EntityType.National)) {
            return 45;
        } else if (currentPosition != null && currentPosition.EntityTypeId === Constant.EntityType.State) {
            return 30;
        } else {
            return 25;
        }
    }

    const getStateClubTitle = () => {
        // if (currentPosition != null && (currentPosition.EntityTypeId === Constant.EntityType.Gamebase || currentPosition.EntityTypeId === Constant.EntityType.National)) {
        //     return t('member.national_association');
        // } else if (currentPosition != null && currentPosition.EntityTypeId === Constant.EntityType.State) {
        //     return t('member.state_association');
        // } else {
        //     return t('member.club');
        // }
        return t('member.club');
    }

    const getMemberTitle = () => {
        return currentPosition != null && currentPosition.StateAssociationAcronym != null && currentPosition.StateAssociationAcronym.length > 0 ? currentPosition.StateAssociationAcronym + " #" : t('member.member_number');
    }

    const isClub = () => {
        return currentPosition != null && currentPosition.EntityTypeId === Constant.EntityType.Club;
    }

    const stateColumns = [
        {
            title: t('common.action'),
            key: 'Id',
            render: (value, record) => {
                return <ActionButtonsCell data={record} btnList={[
                    {
                        Description: t("common.copy"),
                        Icon: <CopyIcon />,
                        Hide: false,
                        ColorClass: Constant.ColorClass.LightYellow,
                        NoMargin: true,
                        handleAction: copyMember
                    }
                ]} />
            },
            align: 'center',
            width: 40
        },
        {
            title: <span>{getStateClubTitle()}</span>,
            dataIndex: 'EntityName',
            key: 'EntityName',
            sorter: false,
            width: 70
        },
        {
            title: <span>{getMemberTitle()}</span>,
            dataIndex: 'MemberId',
            key: 'MemberId',
            sorter: true,
            width: 55,
        },
        {
            title: t('member.last_name'),
            dataIndex: 'LastName',
            key: 'LastName',
            sorter: true,
            defaultSortOrder: 'ascend',
            width: 60,
        },
        {
            title: t('member.first_name'),
            dataIndex: 'FirstName',
            key: 'FirstName',
            sorter: true,
            width: 60,
        },
        {
            title: t('member.family_group'),
            dataIndex: 'FamilyGroupName',
            key: 'FamilyGroupName',
            sorter: true,
            width: 75,
        },
        {
            title: t('member.fishing_category'),
            dataIndex: 'FishingCategoryName',
            key: 'FishingCategoryName',
            sorter: true,
            width: 90,
        },
        {
            title: t('member.financial_status'),
            dataIndex: 'FinancialStatusName',
            key: 'FinancialStatusName',
            sorter: false,
            ellipsis: true,
            width: 45,
        },
        {
            title: t('common.status'),
            dataIndex: 'MemberStatusId',
            key: 'MemberStatusId',
            sorter: false,
            align: 'center',
            width: 40,
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.MemberStatusId)}
                    description={CommonService.getStatusDescription(record.MemberStatusId, false)} />
            )
        }
    ]

    const clubColumns = [
        {
            title: t('common.action'),
            key: 'Id',
            render: (value, record) => {
                return <ActionButtonsCell data={record} btnList={[
                    {
                        Description: t("common.copy"),
                        Icon: <CopyIcon />,
                        Hide: false,
                        ColorClass: Constant.ColorClass.LightYellow,
                        NoMargin: true,
                        handleAction: copyMember
                    }
                ]} />
            },
            align: 'center',
            width: 33
        },
        {
            title: <span>{getMemberTitle()}</span>,
            dataIndex: 'MemberId',
            key: 'MemberId',
            sorter: true,
            width: 45,
        },
        {
            title: t('member.last_name'),
            dataIndex: 'LastName',
            key: 'LastName',
            sorter: true,
            defaultSortOrder: 'ascend',
            width: 55,
        },
        {
            title: t('member.first_name'),
            dataIndex: 'FirstName',
            key: 'FirstName',
            sorter: true,
            width: 58,
        },
        {
            title: t('member.family_group'),
            dataIndex: 'FamilyGroupName',
            key: 'FamilyGroupName',
            sorter: true,
            width: 66,
        },
        {
            title: t('member.fishing_category'),
            dataIndex: 'FishingCategoryName',
            key: 'FishingCategoryName',
            sorter: true,
            width: 74,
        },
        {
            title: t('member.financial_status'),
            dataIndex: 'FinancialStatusName',
            key: 'FinancialStatusName',
            sorter: false,
            ellipsis: true,
            width: 61,
        },
        {
            title: t('common.status'),
            dataIndex: 'MemberStatusId',
            key: 'MemberStatusId',
            sorter: false,
            align: 'center',
            width: 32,
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.MemberStatusId)}
                    description={CommonService.getStatusDescription(record.MemberStatusId, false)} />
            )
        }
    ]

    const expandedRowRender = (record, index, indent, expanded) => {
        return <Tabs defaultActiveKey="1">
            <TabPane tab={t("member.membership_details")} key="club_details">
                <Row>
                    <Col xs={{ span: 24 }}>
                        {_getTableClubDetails(record)}
                    </Col>
                </Row>
            </TabPane>
            <TabPane tab={t("member.member_details")} key="member_details">
                <Row>
                    <Col xs={{ span: 24 }}>
                        {_getTableMemberDetails(record)}
                    </Col>
                </Row>
            </TabPane>
            <TabPane tab={t("member.address")} key="contact">
                <Row>
                    <Col xs={{ span: 24 }}>
                        {_getTableMemberContact(record)}
                    </Col>
                </Row>
            </TabPane>
            <TabPane tab={t("member.position_access")} key="position_access">
                <Row>
                    <Col xs={{ span: 24 }}>
                        {_getTablePositionAccess(record)}
                    </Col>
                </Row>
            </TabPane>
        </Tabs>
    }

    const _getTableClubDetails = (member) => {
        var columns = [
            {
                title: t('member.financial_type'),
                dataIndex: 'FinancialTypeName',
                key: 'FinancialTypeName',
                sorter: false,
                ellipsis: true,
                width: 100,
            },
            {
                title: t('member.fishing_type'),
                dataIndex: 'FishingTypeName',
                key: 'FishingTypeName',
                sorter: false,
                ellipsis: true,
                width: 80,
            },
            {
                title: t('member.dob'),
                dataIndex: 'DateOfBirth',
                key: 'DateOfBirth',
                sorter: false,
                ellipsis: true,
                width: 80,
                render: (value, record) => (
                    <DateTimeCell value={record.DateOfBirth} />
                )
            },
            {
                title: t('member.gender'),
                dataIndex: 'GenderName',
                key: 'GenderName',
                sorter: false,
                ellipsis: true,
                width: 50,
            },
            {
                title: t('member.financial_end'),
                dataIndex: 'FinancialEnd',
                key: 'FinancialEnd',
                sorter: false,
                ellipsis: true,
                width: 100,
                render: (value, record) => (
                    <FinancialEndCell value={record.FinancialEnd} />
                )
            },
            {
                title: t('member.flag_member'),
                dataIndex: 'Flagged',
                key: 'Flagged',
                sorter: false,
                ellipsis: true,
                align: "center",
                width: 90,
                // render: (value, record) => (
                //     <YesNoCell value={record.Flagged} />
                // ),
                render: (value, record) => (
                    <StatusCell data={record}
                        colorClass={CommonService.getRevertStatusColorClass(record.Flagged)}
                        description={CommonService.getStatusDescription(record.Flagged, true)} />
                )
            },
            {
                title: t('member.comments'),
                dataIndex: 'Comments',
                key: 'Comments',
                sorter: false,
                ellipsis: true,
                width: 100,
            }
        ]
        return <Table className='custom-member-table' columns={columns} dataSource={[member]} pagination={false} rowKey="Id" />
    }

    const _getTableMemberDetails = (member) => {
        var columns = [
            {
                title: t('member.member_start_date'),
                dataIndex: 'MemberSince',
                key: 'MemberSince',
                sorter: false,
                ellipsis: true,
                width: 80,
                render: (value, record) => (
                    <DateTimeCell value={record.MemberSince} />
                )
            },
            {
                title: t('member.title'),
                dataIndex: 'TitleName',
                key: 'TitleName',
                sorter: false,
                ellipsis: true,
                width: 60,
            },
            {
                title: t('member.mobile'),
                dataIndex: 'MobilePhone',
                key: 'MobilePhone',
                sorter: false,
                ellipsis: true,
                width: 80
            },
            {
                title: t('member.home_phone'),
                dataIndex: 'HomePhone',
                key: 'HomePhone',
                sorter: false,
                ellipsis: true,
                width: 80
            },
            {
                title: t('member.email'),
                dataIndex: 'Email',
                key: 'Email',
                sorter: false,
                ellipsis: true,
                width: 120
            }
        ]
        return <Table className='custom-member-table' columns={columns} dataSource={[member]} pagination={false} rowKey="Id" />
    }

    const _getTableMemberContact = (member) => {
        var columns = [
            {
                title: t('member.street_number'),
                dataIndex: 'StreetNo',
                key: 'StreetNo',
                sorter: false,
                width: 90,
            },
            {
                title: t('member.street_name'),
                dataIndex: 'StreetLine1',
                key: 'StreetLine1',
                sorter: false,
                width: 100,
            },
            {
                title: t('member.street_type'),
                dataIndex: 'StreetLine2',
                key: 'StreetLine2',
                sorter: false,
                ellipsis: true,
                width: 110,
            },
            {
                title: t('member.suburb'),
                dataIndex: 'Suburb',
                key: 'Suburb',
                sorter: false,
                ellipsis: true,
                width: 110,
            },
            {
                title: t('member.state'),
                dataIndex: 'State',
                key: 'State',
                sorter: false,
                ellipsis: true,
                width: 70,
            },
            {
                title: t('member.post_code'),
                dataIndex: 'PostCode',
                key: 'PostCode',
                sorter: false,
                width: 70,
            },
            {
                title: t('member.country'),
                dataIndex: 'CountryCode',
                key: 'CountryCode',
                sorter: false,
                width: 100,
            }
        ]
        return <Table className='custom-member-table' columns={columns} dataSource={[member]} pagination={false} rowKey="Id" />
    }

    const _getTablePositionAccess = (member) => {
        var columns = [
            {
                title: t('member.position_title'),
                dataIndex: 'PositionTitleName',
                key: 'PositionTitleName',
                sorter: false,
                width: 80,
            },
            {
                title: t('member.entity_name'),
                dataIndex: 'EntityName',
                key: 'EntityName',
                sorter: false,
                width: 80,
            }
        ]
        return <Table className='custom-member-table position-access-table' locale={{ emptyText: "" }} columns={columns} dataSource={member.MemberPositions} pagination={false} rowKey="Id" />
    }

    const onTableChange = (pagination, filters, sorter, extra) => {
        let data = formFilter.getFieldsValue();
        if (!data || (data && !data.MemberStatusIds)) {
            data.MemberStatusIds = [];
        }

        setSearchMembership(data);

        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = gridConfigOptions.sort;
        }
        search(pagination.current, pagination.pageSize, sort, data);

        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        });
    }

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (newSelectedRowKeys, newSelectedRows) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setSelectedMembers(newSelectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: record.UserName === Constant.SuperUserName && currentPosition.EntityTypeId !== Constant.EntityType.Gamebase,
        }),
    }

    const showFilterLayout = () => {
        setOpenFilter(true);
    }

    const closeFilterLayout = () => {
        formFilter.setFieldsValue(searchMembership);
        setOpenFilter(false);
    }

    const refreshData = (isShowLoading = false) => {
        setSelectedRowKeys([]);
        setSelectedMembers([]);

        let data = formFilter.getFieldsValue();
        if (!data || (data && !data.MemberStatusIds)) {
            data.MemberStatusIds = [];
        }
        setSearchMembership(data);

        search(1, gridConfigOptions.pageSize, gridConfigOptions.sort, data, isShowLoading);

        setGridConfigOptionsValue(
            {
                ...gridConfigOptions,
                pageNumber: 1
            }
        );

    }

    const resetFilter = () => {
        formFilter.resetFields();
        setSearchMembership({ MemberStatusIds: [] });
    }

    const applyFilter = () => {

        refreshData(true);

        setOpenFilter(false);
    }

    const isClubMember = () => {
        return currentPosition != null && currentPosition.PositionTypeId === Constant.PositionType.ClubMember;
    }

    const isClubEntityType = () => {
        return currentPosition != null && currentPosition.EntityTypeId === Constant.EntityType.Club;
    }

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight())
        }, 100)

        showLoading();
        Promise.all([
            MembershipService.searchMemberships(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, { MemberStatusIds: [] }),
            MembershipService.getFilterDatas()
        ])
            .finally(() => {
                dismissLoading();

                setSelectedRowKeys([]);
                setSelectedMembers([]);
                setSearchMembership({ MemberStatusIds: [] });
            })
            .then((response) => {
                if (response[0].data) {
                    setMembers(response[0].data.ObjectList);
                    setTotalItems(response[0].data.TotalItems);
                }

                if (response[1].data) {
                    setTitles(response[1].data.Titles);
                    setGenders(response[1].data.Genders);
                    setFamilyGroups(response[1].data.FamilyGroups);
                    setFishingTypes(response[1].data.FishingTypes);
                    setFishingCategories(response[1].data.FishingCategories);
                    setFinancialTypes(response[1].data.FinancialTypes);
                    setFinancialStatuses(response[1].data.FinancialStatuses);
                    setPositionTitles(response[1].data.PositionTitles);
                    setMemberStatuses(response[1].data.MemberStatuses);
                    setStates(response[1].data.States);
                    setFinancialYears(response[1].data.FinancialYears);
                    setEntities(response[1].data.Clubs);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);

        return () => {
            MembershipService.cancelRequest();
            clearTimeout(timeoutFn);
        }
    }, [])

    return (
        <>
        {!isClubMember() &&
            <div className="member-management">
                <Breadcrumb>
                    <Breadcrumb.Item>{t("menu.membership")}</Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    <span>{t("member.member_header_description")}</span>
                </Row>
                <Row id={Constant.FixItemsContainerId} className="fix-item-container">
                    <Col xs={{ span: 24 }} className="text-right extra-btn-m-t">
                        <div>
                            <Button onClick={showFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon />}>{t("common.filter")}</Button>

                            <Button id='btnDelete' disabled={selectedMembers.length === 0} danger className="m-l-10 secondary-btn" onClick={deleteMembers}>
                                {t("common.delete")}
                            </Button>

                            <Button id='btnEdit' disabled={selectedMembers.length === 0} className={selectedMembers.length > 0 ? "m-l-10 secondary-btn btn-outline-dark-blue" : "m-l-10 secondary-btn"} onClick={editMembers}>
                                {t("common.edit")}
                            </Button>

                            <Button className="m-l-10 secondary-btn btn-solid-dark-blue" type="primary" onClick={addMembers}>
                                {t("common.add_new")}
                            </Button>
                        </div>
                    </Col>
                </Row>

                <Row className={`${isClub() ? 'm-t-10 club-section-table' : 'm-t-10 state-section-table'}`} gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                    <Col xs={{ span: 24 }}>
                        <Table
                            {...GetTableConfigs(isClub() ? clubColumns : stateColumns, members, totalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                            expandable={{ expandedRowRender }}
                            rowSelection={rowSelection}
                        />
                    </Col>
                </Row>
                <Row className="fix-item-container">
                    <Col xs={{ span: 24 }}>
                        <span>{t("member.member_footer_description")}</span>
                    </Col>
                </Row>

                {/* Filter Layout  */}
                <Drawer
                    title={t("common.filter")}
                    width={378}
                    onClose={closeFilterLayout}
                    open={openFilter}
                    closeIcon={false}
                    styles={{
                        body: {
                            paddingBottom: 80,
                        }
                    }}
                    extra={
                        <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                            <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                            <Button onClick={closeFilterLayout} icon={<CloseIcon />} type="link" />
                        </Space>
                    }
                    footer={
                        <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                            <Button onClick={closeFilterLayout}>{t("common.cancel")}</Button>
                            <Button onClick={applyFilter} type="primary">
                                {t("common.apply")}
                            </Button>
                        </Space>
                    }>
                    <Form
                        layout="vertical"
                        {...layout}
                        form={formFilter}
                        name="formFilter"
                        key='formFilter'
                        initialValues={{ MemberStatusIds: [] }}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="LastName"
                                    label={t("member.last_name")}
                                >
                                    <Input allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="FirstName"
                                    label={t("member.first_name")}
                                >
                                    <Input allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="FamilyGroupIds"
                                    label={t('member.family_group')}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={familyGroups ? familyGroups.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="GenderIds"
                                    label={t("member.gender")}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) => (optionA?.value == optionB?.value)}
                                            options={genders ? genders.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="FishingTypeIds"
                                    label={t("member.fishing_type")}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) => (optionA?.value == optionB?.value)}
                                            options={fishingTypes ? fishingTypes.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="FishingCategoryIds"
                                    label={t("member.fishing_category")}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) => (optionA?.value == optionB?.value)}
                                            options={fishingCategories ? fishingCategories.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="FinancialTypeIds"
                                    label={t('member.financial_type')}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) => (optionA?.value == optionB?.value)}
                                            options={financialTypes ? financialTypes.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="FinancialStatusIds"
                                    label={t("member.financial_status")}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={financialStatuses ? financialStatuses.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="FinancialEnd"
                                    label={t('member.financial_end')}
                                >
                                    <DatePicker format={Constant.DateFormat} placeholder={Constant.DateFormat} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="MemberStatusIds"
                                    label={t("common.status")}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={memberStatuses ? memberStatuses.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    hidden={isClubEntityType()}
                                    name="ClubIds"
                                    label={t("member.club")}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={entities ? entities.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Drawer>

                {/* Create/Edit Member Layout  */}
                {
                    visibleModal &&
                    <CreateEditMembership
                        handleCancel={handleCancel}
                        titles={titles}
                        isEdit={isEdit}
                        isCopy={isCopy}
                        genders={genders}
                        familyGroups={familyGroups}
                        fishingTypes={fishingTypes}
                        fishingCategories={fishingCategories}
                        financialTypes={financialTypes}
                        financialStatuses={financialStatuses}
                        positionTitles={positionTitles}
                        memberStatuses={memberStatuses}
                        states={states}
                        financialYears={financialYears}
                        selectedRowKeys={selectedRowKeys}
                        selectedMembers={selectedMembers} >

                    </CreateEditMembership>
                }
            </div>
        }

        {isClubMember() &&
            <>
                <Table
                    style={{ visibility: 'hidden', height: '0px' }}
                    {...GetTableConfigs(isClub() ? clubColumns : stateColumns, [], 0, "Id", onTableChange, gridConfigOptions.pageNumber)}
                />
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_access")} />
            </>
        }
    </>
    )

}

export default MembershipManagement;
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import './membershipSearch.scss';
import { useTranslation } from 'react-i18next';
import {
    Constant,
    CommonService,
    SettingService,
    MembershipService
} from '../../../services/services'
import { Table, Button, Row, Col, Select, Form, Input, Typography, Drawer, Space } from 'antd';
import { GetTableConfigs, YesNoCell, StatusCell } from '../../../components/customTable/customTable'
import { FilterAltIcon, CloseIcon } from '../../../components/icons/icons'
import LoadingContext from '../../../components/loading/loadingContext'
import PubSub from 'pubsub-js';
import { useGlobalState } from '../../../utilities/globalState'
import EnhancedSelect from '../../../components/enhancedSelect/enhancedSelect'

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const { Title } = Typography;

const MembershipSearch = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [formFilter] = Form.useForm();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext);
    const [totalItems, setTotalItems] = useState(0);
    const [members, setMembers] = useState([]);
    const [fishingCategories, setFishingCategories] = useState([]);
    const [financialTypes, setFinancialTypes] = useState([]);
    const [yesNos, setYesNos] = useState([]);
    const [entityDetail, setEntityDetail] = useState({})
    const [openFilter, setOpenFilter] = useState(false);
    const [searchMembership, setSearchMembership] = useState({ MemberStatusIds: [Constant.MemberStatusCode.Active] });
    const [columns, setColumns] = useState([]);
    const [memberStatuses, setMemberStatuses] = useState([]);

    const [gridConfigOptions, setGridConfigOptionsValue] = useState({
        pageNumber: 1,
        pageSize: CommonService.getPageSize(),
        sort: "FirstName-ascend",
    })
    const [currentPosition] = useGlobalState('currentPosition');
    const [clubData, setClubData] = useState([]);

    const search = (pageNumber, pageSize, sort, query, isShowLoading = false) => {
        if (isShowLoading) {
            showLoading();
        }

        let params = { ...query };
        if (!params) params = { MemberStatusIds: [Constant.MemberStatusCode.Active] };
        params.SeachAllClubs = true

        MembershipService.membersSearch(pageNumber, pageSize, sort, params)
            .finally(() => {
                if (isShowLoading) {
                    dismissLoading();
                }

            })
            .then(result => {
                if (result.data) {
                    setMembers(result.data.ObjectList);
                    setTotalItems(result.data.TotalItems);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const navigateToCreateNewMember = () => {
        history.push(Constant.Pages.MembershipManagement);
        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);
    }

    const getStateClubTitle = () => {
        if (currentPosition != null && (currentPosition.EntityTypeId === Constant.EntityType.Gamebase || currentPosition.EntityTypeId === Constant.EntityType.National || currentPosition.EntityTypeId === Constant.EntityType.State)) {
            return t('member.state_association');
        } else {
            return t('member.club');
        }
    }

    const getMemberTitle = () => {
        return currentPosition != null && currentPosition.StateAssociationAcronym != null && currentPosition.StateAssociationAcronym.length > 0 ? currentPosition.StateAssociationAcronym + " #" : t('member.member_number');
    }

    const isClubMember = () => {
        return currentPosition != null && currentPosition.PositionTypeId === Constant.PositionType.ClubMember;
    }

    const isClubCommittee = () => {
        return currentPosition != null && currentPosition.PositionTypeId === Constant.PositionType.ClubCommittee;
    }

    const isClubMemberAndClubCommittee = () => {
        return currentPosition != null && (currentPosition.PositionTypeId === Constant.PositionType.ClubMember || currentPosition.PositionTypeId === Constant.PositionType.ClubCommittee);
    }

    var memberColumns = [
        {
            title: t('member.first_name'),
            dataIndex: 'FirstName',
            key: 'FirstName',
            sorter: true,
            defaultSortOrder: 'ascend',
            width: 65,
        },
        {
            title: t('member.last_name'),
            dataIndex: 'LastName',
            key: 'LastName',
            sorter: true,
            width: 65,
        },
        {
            title: t('member.member_number'),
            dataIndex: 'MemberId',
            key: 'MemberId',
            sorter: true,
            width: 90,
        },
        {
            title: t('member.club'),
            dataIndex: 'EntityAcronym',
            key: 'EntityAcronym',
            sorter: true,
            width: 90
        },
        {
            title: t('member.financial_type'),
            dataIndex: 'FinancialTypeName',
            key: 'FinancialTypeName',
            sorter: true,
            width: 70,
        },
        {
            title: t('member.fishing_category'),
            dataIndex: 'FishingCategoryName',
            key: 'FishingCategoryName',
            sorter: true,
            width: 85,
        },
        {
            title: t('member.financial'),
            dataIndex: 'FinancialStatusId',
            key: 'FinancialStatusId',
            sorter: false,
            width: 35,
            render: (value, record) => (
                <YesNoCell value={record.FinancialStatusId === Constant.FinancialStatus.Financial ? true : false} />
            )
        },
        {
            title: t('common.status'),
            dataIndex: 'MemberStatusId',
            key: 'MemberStatusId',
            sorter: false,
            align: 'center',
            width: 40,
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.MemberStatusId)}
                    description={CommonService.getStatusDescription(record.MemberStatusId, false)} />
            )
        }
    ]

    var clubColumns = [
        {
            title: t('member.first_name'),
            dataIndex: 'FirstName',
            key: 'FirstName',
            sorter: true,
            defaultSortOrder: 'ascend',
            width: 65,
        },
        {
            title: t('member.last_name'),
            dataIndex: 'LastName',
            key: 'LastName',
            sorter: true,
            width: 65,
        },
        {
            title: t('member.member_number'),
            dataIndex: 'MemberId',
            key: 'MemberId',
            sorter: true,
            width: 90,
        },
        {
            title: t('member.club'),
            dataIndex: 'EntityAcronym',
            key: 'EntityAcronym',
            sorter: true,
            width: 90
        },
        {
            title: t('member.financial_type'),
            dataIndex: 'FinancialTypeName',
            key: 'FinancialTypeName',
            sorter: true,
            width: 70,
        },
        {
            title: t('member.fishing_category'),
            dataIndex: 'FishingCategoryName',
            key: 'FishingCategoryName',
            sorter: true,
            width: 96,
        },
        {
            title: t('member.financial'),
            dataIndex: 'FinancialStatusId',
            key: 'FinancialStatusId',
            sorter: false,
            align: 'center',
            width: 35,
            render: (value, record) => (
                <YesNoCell value={record.FinancialStatusId === Constant.FinancialStatus.Financial ? true : false} />
            )
        },
        {
            title: t('member.flagged'),
            dataIndex: 'Flagged',
            key: 'Flagged',
            sorter: false,
            align: 'center',
            width: 35,
            // render: (value, record) => (
            //     <YesNoCell value={record.Flagged} />
            // )
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getRevertStatusColorClass(record.Flagged)}
                    description={CommonService.getStatusDescription(record.Flagged, true)} />
            )
        },
        {
            title: t('common.status'),
            dataIndex: 'MemberStatusId',
            key: 'MemberStatusId',
            sorter: false,
            align: 'center',
            width: 40,
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.MemberStatusId)}
                    description={CommonService.getStatusDescription(record.MemberStatusId, false)} />
            )
        }
    ]

    var stateColumns = [
        {
            title: t('member.first_name'),
            dataIndex: 'FirstName',
            key: 'FirstName',
            sorter: true,
            defaultSortOrder: 'ascend',
            width: 65,
        },
        {
            title: t('member.last_name'),
            dataIndex: 'LastName',
            key: 'LastName',
            sorter: true,
            width: 65,
        },
        {
            title: t('member.member_number'),
            dataIndex: 'MemberId',
            key: 'MemberId',
            sorter: true,
            width: 90,
        },
        {
            title: t('member.club'),
            dataIndex: 'EntityAcronym',
            key: 'EntityAcronym',
            sorter: true,
            width: 90
        },
        {
            title: t('member.financial_type'),
            dataIndex: 'FinancialTypeName',
            key: 'FinancialTypeName',
            sorter: true,
            width: 70,
        },
        {
            title: t('member.fishing_category'),
            dataIndex: 'FishingCategoryName',
            key: 'FishingCategoryName',
            sorter: true,
            width: 96,
        },
        {
            title: t('member.financial'),
            dataIndex: 'FinancialStatusId',
            key: 'FinancialStatusId',
            sorter: false,
            align: 'center',
            width: 35,
            render: (value, record) => (
                <YesNoCell value={record.FinancialStatusId === Constant.FinancialStatus.Financial ? true : false} />
            )
        },
        {
            title: t('member.flagged'),
            dataIndex: 'Flagged',
            key: 'Flagged',
            sorter: false,
            align: 'center',
            width: 35,
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getRevertStatusColorClass(record.Flagged)}
                    description={CommonService.getStatusDescription(record.Flagged, true)} />
            )
        },
        {
            title: t('common.status'),
            dataIndex: 'MemberStatusId',
            key: 'MemberStatusId',
            sorter: false,
            align: 'center',
            width: 40,
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.MemberStatusId)}
                    description={CommonService.getStatusDescription(record.MemberStatusId, false)} />
            )
        }
    ]

    const onTableChange = (pagination, filters, sorter, extra) => {
        let data = formFilter.getFieldsValue();
        if (!data || (data && !data.MemberStatusIds)) {
            data.MemberStatusIds = [Constant.MemberStatusCode.Active];
        }
        setSearchMembership(data);

        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = gridConfigOptions.sort;
        }
        search(pagination.current, pagination.pageSize, sort, data);

        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        });
    }

    const showFilterLayout = () => {
        setOpenFilter(true);
    }

    const closeFilterLayout = () => {
        formFilter.setFieldsValue(searchMembership);
        setOpenFilter(false);
    }

    const refreshData = (isShowLoading = false) => {
        let data = formFilter.getFieldsValue();
        if (!data || (data && !data.MemberStatusIds)) {
            data.MemberStatusIds = [Constant.MemberStatusCode.Active];
        }
        setSearchMembership(data);

        search(1, gridConfigOptions.pageSize, gridConfigOptions.sort, data, isShowLoading);

        setGridConfigOptionsValue(
            {
                ...gridConfigOptions,
                pageNumber: 1
            }
        );

    }

    const resetFilter = () => {
        formFilter.resetFields();
        setSearchMembership({ MemberStatusIds: [Constant.MemberStatusCode.Active] });
    }

    const applyFilter = () => {

        refreshData(true);

        setOpenFilter(false);
    }


    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight())
        }, 100)

        showLoading();
        setYesNos([{
            Id: 1,
            Name: t("common.yes")
        },
        {
            Id: 2,
            Name: t("common.no")
        }]);

        if (isClubMember()) {
            setColumns(memberColumns);
        } else if (isClubCommittee()) {
            setColumns(clubColumns);
        } else {
            setColumns(stateColumns);
        }
        Promise.all([
            MembershipService.membersSearch(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, { FetchAll: true, MemberStatusIds: [Constant.MemberStatusCode.Active], SeachAllClubs: true }),
            MembershipService.getFilterDatas(),
            SettingService.getStateEntityDetail()
        ])
            .finally(() => {
                dismissLoading();

                setSearchMembership({ MemberStatusIds: [Constant.MemberStatusCode.Active] });
            })
            .then((response) => {
                if (response[0].data) {
                    setMembers(response[0].data.ObjectList);
                    setTotalItems(response[0].data.TotalItems);
                }

                if (response[1].data) {
                    setFishingCategories(response[1].data.FishingCategories);
                    setFinancialTypes(response[1].data.FinancialTypes);
                    setClubData(response[1].data.Clubs);
                    setMemberStatuses(response[1].data.MemberStatuses);
                }

                if (response[2].data) {
                    setEntityDetail(response[2].data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);

        return () => {
            MembershipService.cancelRequest();
            clearTimeout(timeoutFn);
        }
    }, [])

    return (
        <div className="member-search">
            {/* <Breadcrumb>
                <Breadcrumb.Item>{t("member.member_search", { state_association_acronym: entityDetail.EntityAcronym, state_club: getStateClubTitle() })}</Breadcrumb.Item>
            </Breadcrumb> */}
            <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-b-10'>
                <Col xs={{ span: 24 }}>
                    <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                        <Title className='color-dark-blue' level={5}>{t("member.member_search", { state_association_acronym: entityDetail.EntityAcronym, state_club: getStateClubTitle() })}</Title>
                    </Space>
                </Col>
            </Row>
            <Row>
                <span>{t("member.member_search_message1", { state_association_acronym: entityDetail.EntityAcronym })}</span>
            </Row>
            <Row style={{ display: isClubMember() ? 'none' : 'block' }}>
                <p style={{ marginBlockStart: 8, marginBlockEnd: 4 }}>{t("member.member_search_message2")}</p>
            </Row>
            <Row style={{ display: isClubMember() ? 'none' : 'block' }}>
                <span>{t("member.member_search_message3")}</span>
            </Row>
            <Row>
                <p className='header-title' style={{ marginBlockStart: 8, marginBlockEnd: 4 }}>{t("member.member_search_message4")}</p>
            </Row>
            <Row>
                <span className='header-title' dangerouslySetInnerHTML={{ __html: t("member.member_search_message5", { state_association_acronym: entityDetail.EntityAcronym, state_association_website: entityDetail.Website }) }}></span>
            </Row>
            <Row id={Constant.FixItemsContainerId} className="fix-item-container m-t-10">
                <Col xs={{ span: 24 }} className="text-right extra-btn-m-t">
                    <div>
                        <Button onClick={showFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon />}>{t("common.filter")}</Button>

                        <Button style={{ display: isClubCommittee() ? 'inline-block' : 'none' }} className="m-l-10 secondary-btn" type="primary" onClick={navigateToCreateNewMember}>
                            {t("member.return_to_create_new_club_member")}
                        </Button>
                    </div>
                </Col>
            </Row>

            <Row className="m-t-10 section-table" gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                <Col xs={{ span: 24 }}>
                    <Table
                        {...GetTableConfigs(columns, members, totalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                    />
                </Col>
            </Row>

            {/* Filter Layout  */}
            <Drawer
                title={t("common.filter")}
                width={378}
                onClose={closeFilterLayout}
                open={openFilter}
                closeIcon={false}
                styles={{
                    body: {
                        paddingBottom: 80,
                    }
                }}
                extra={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                        <Button onClick={closeFilterLayout} icon={<CloseIcon />} type="link" />
                    </Space>
                }
                footer={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button onClick={closeFilterLayout}>{t("common.cancel")}</Button>
                        <Button onClick={applyFilter} type="primary">
                            {t("common.apply")}
                        </Button>
                    </Space>
                }>
                <Form
                    layout="vertical"
                    {...layout}
                    form={formFilter}
                    name="formFilter"
                    key='formFilter'
                    initialValues={{ MemberStatusIds: [Constant.MemberStatusCode.Active] }}>
                    <Row gutter={24} >
                        <Col span={24}>
                            <Form.Item
                                name="ClubIds"
                                label={t("member.club")}
                            >
                                <EnhancedSelect allowClear={true}
                                    mode="multiple"
                                    showSearch optionFilterProp="children"
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                >
                                    {
                                        clubData && clubData.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                    }
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="MemberId"
                                label={t("member.member_number")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="LastName"
                                label={t("member.last_name")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="FirstName"
                                label={t("member.first_name")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="FinancialTypeIds"
                                label={t('member.financial_type')}
                            >
                                <EnhancedSelect mode="multiple" allowClear={true}
                                    showSearch optionFilterProp="children"
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                >
                                    {
                                        financialTypes && financialTypes.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                    }
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="FishingCategoryIds"
                                label={t("member.fishing_category")}
                            >
                                <EnhancedSelect mode="multiple" allowClear={true}
                                    showSearch optionFilterProp="children"
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                >
                                    {
                                        fishingCategories && fishingCategories.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                    }
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="FinancialIds"
                                label={t("member.financial")}
                            >
                                <EnhancedSelect mode="multiple" allowClear={true}
                                    showSearch optionFilterProp="children"
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                >
                                    {
                                        yesNos && yesNos.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                    }
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24} style={{ display: isClubMember() ? 'none' : 'block' }}>
                        <Col span={24}>
                            <Form.Item
                                name="FlaggedIds"
                                label={t("member.flagged")}
                            >
                                <EnhancedSelect mode="multiple" allowClear={true}
                                    showSearch optionFilterProp="children"
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                >
                                    {
                                        yesNos && yesNos.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                    }
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="MemberStatusIds"
                                label={t("common.status")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={memberStatuses ? memberStatuses.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </div>
    )

}

export default MembershipSearch;
import axios from 'axios';
import { axiosPortal } from './authService'
const source = [];
let baseURL = "api/Document"

export const DocumentService = {
    getDocuments: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetDocuments?PageNumber=${pageNumber ? pageNumber : 1}`

        if (sort) {
            url += `&Sort=${sort}`
        }

        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (params) {
            if (params.DocumentIds) {
                url += `&DocumentIds=${params.DocumentIds}`;
            }
            if (params.EntityIds) {
                url += `&EntityIds=${params.EntityIds}`;
            }
            if (params.Title) {
                url += `&Title=${params.Title}`;
            }
            if (params.FileName) {
                url += `&FileName=${params.FileName}`;
            }
            if (params.DocumentDate) {
                url += `&DocumentDate=${params.DocumentDate}`;
            }
            if (params.DocumentCategoryIds) {
                url += `&DocumentCategoryIds=${params.DocumentCategoryIds}`;
            }
            if(params.StatusIds){
                url += `&StatusIds=${params.StatusIds}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getFilterDatas: function (includedAll) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/FilterDatas?includedAll=${includedAll}`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    addUpdateDocuments: function (data) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    deleteDocuments: function (ids) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/DeleteDocuments`, ids, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getDocumentsCategories: function (documentSource) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetDocumentsCategories?documentSource=${documentSource}`
        
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getTournaments: function (documentSource) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetTournaments?documentSource=${documentSource}`
        
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    cancelRequest: function () {
        if (source && source.length > 0) {
            for (var i = 0; i < source.length; i++) {
                source[i].cancel('Operation canceled.')
            }
            source.splice(0, source.length)
        }
    }
}
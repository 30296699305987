import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import './customTimePicker.scss';

const CustomTimePicker = ({ value, onChange, mask, defaultValue, disabled }) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleChange = (e) => {
        var newValue = e.target.value;
        if (newValue && newValue.length >= 2) {
            var firstValue = newValue.slice(0, 1);
            var secondValue = newValue.slice(1, 2);
            if (firstValue > 2) {
                newValue = '0' + newValue.slice(1, 5);
            } else if (firstValue == 2 && secondValue > 4) {
                newValue = firstValue + '4' + newValue.slice(2, 5);
            }
        }
        
        if (newValue && newValue.length >= 5) {
            var thirdValue = newValue.slice(3, 4);
            if (thirdValue > 5) {
                newValue = newValue.slice(0, 3) + '5' + newValue.slice(4, 5);
            }
        }

        onChange({ target: { value: newValue } });
    };


    return (
        <InputMask
            value={value || defaultValue}
            mask={"99:99"} 
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={'custom-mask-input'}
            style={{
                borderColor: isFocused ? '#2986ff' : '#d9d9d9',
            }}
            disabled={disabled}
        />
    );
};

export default CustomTimePicker;
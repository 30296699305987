
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './anonymousLayout.scss';
import { Layout, } from 'antd';
const { Content } = Layout;


function AnonymousLayout(props) {
    return (
        <Layout className='anonymous-layout'>
            <Content>
                {props.children}
            </Content>
        </Layout>
    )
}

export default AnonymousLayout;

import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { GetTableConfigs } from '../../../components/customTable/customTable'

function SkipperOfBoats(props) {
    const { t } = useTranslation();
    var [skipperOfBoats, setSkipperOfBoats] = useState([]);

    const columns = [
        {
            title: t('home.id'),
            dataIndex: 'Id',
            key: 'Id',
            ellipsis: true,
            width: 100
        },
        {
            title: t('home.name'),
            dataIndex: 'Name',
            key: 'Name',
            ellipsis: true,
            width: 200
        },
        {
            title: t('home.rego'),
            dataIndex: 'Rego',
            key: 'Rego',
            width: 200
        }
    ]

    const closeForm = () => {
        props.handleCancel()
    }

    useEffect(() => {
        if (props.skipperOfBoats && props.skipperOfBoats.length > 0) {
            setSkipperOfBoats(props.skipperOfBoats);
        }

        return () => {
        }
    }, [])

    return (
        <div>
            <Modal
                title={t("home.skipper_of_boats")}
                open={true}
                confirmLoading={false}
                onCancel={closeForm}
                maskClosable={false}
                footer={null}
                width={756}
            >
                <Row className='m-t-15'>
                    <Col xs={{ span: 24 }}>
                        <Table
                            {...GetTableConfigs( columns, skipperOfBoats, skipperOfBoats.length, "Id", null, null, "", false)}
                        />
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}

export default SkipperOfBoats;
import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Space, Empty } from 'antd';
import { Constant, HomeService, CommonService } from '../../services/services';
import { useTranslation } from 'react-i18next';
import HomeDetails from './homeDetails/homeDetails'
import PubSub from 'pubsub-js'
import LoadingContext from '../../components/loading/loadingContext'
import { useGlobalState } from '../../utilities/globalState'

const { Text, Title } = Typography;

function Home(props) {
    const { t } = useTranslation();
    const [visibleMemberInfo, setVisibleMemberInfo] = useState(false)
    const [selectedMemberType, setSelectedMemberType] = useState()
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [memberDetail, setMemberDetail] = useState({})
    const [currentPosition] = useGlobalState('currentPosition')

    useEffect(() => {
        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);
        showLoading()
        HomeService.getMemberDetail()
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    setMemberDetail(result.data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })

        return () => {
            HomeService.cancelRequest()
        }
    }, [])

    useEffect(() => {
        showLoading()
        HomeService.getMemberDetail()
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    setMemberDetail(result.data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }, [currentPosition])

    const handleMemberInfoCancel = () => {
        setVisibleMemberInfo(false)
    }

    const viewMemberInfo = (memberType) => {
        setSelectedMemberType(memberType)
        setVisibleMemberInfo(true)
    }

    return (
        <>
            {currentPosition && currentPosition.PositionTypeId == Constant.PositionType.ClubMember &&
                < div >
                    <Row align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                <Title className='color-dark-blue' level={5}>{t("home.system_information")}</Title>
                            </Space>
                        </Col>
                        <Col xs={{ span: 12 }}>
                            <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                <Text className='color-black'>{t("home.name")}</Text>
                                <Text className='semi-bold-text'>{memberDetail.Name ?? "_"}</Text>
                            </Space>
                        </Col>
                        <Col xs={{ span: 12 }}>
                            <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                <Text className='color-black'>{t("home.club")}</Text>
                                <Text className='semi-bold-text'>{memberDetail.EntityName ?? "_"}</Text>
                            </Space>

                        </Col>
                        <Col xs={{ span: 12 }}>
                            <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                <Text className='color-black'>{t("home.financial_end")}</Text>
                                <Text className='semi-bold-text'>{CommonService.getDateString(memberDetail.FinancialEnd) ?? "_"}</Text>
                            </Space>
                        </Col>

                        <Col xs={{ span: 12 }}>
                            <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                <Text className='color-black'>{t("home.financial_type")}</Text>
                                <Text className='semi-bold-text'>{memberDetail.FinancialMembershipType ?? "_"}</Text>
                            </Space>

                        </Col>
                        <Col xs={{ span: 12 }}>
                            <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                <Text className='color-black'>{t("home.fishing_type")}</Text>
                                <Text className='semi-bold-text'>{memberDetail.FishingMembershipType ?? "_"}</Text>
                            </Space>
                        </Col>

                        {
                            memberDetail.IsJunior &&
                            <Col xs={{ span: 12 }}>
                                <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                    <Text className='color-black'>{t("home.date_of_birth")}</Text>
                                    <Text className='semi-bold-text'>{CommonService.getDateString(memberDetail.DateOfBirth) ?? "_"}</Text>
                                </Space>

                            </Col>
                        }

                        <Col xs={{ span: 12 }}>
                            <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                <Text className='color-black'>{t("home.fishing_category")}</Text>
                                <Text className='semi-bold-text'>{memberDetail.FishingCategory ?? "_"}</Text>
                            </Space>
                        </Col>

                        <Col xs={{ span: 12 }}>
                            <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                <Text className='color-black'>{t("home.activity_count")}</Text>
                                <Text className='semi-bold-text'>{memberDetail.FishingActivityCount ?? "_"}</Text>
                            </Space>
                        </Col>
                        <Col xs={{ span: 12 }}>
                            <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                <Text className='color-black'>{t("home.skipper_of_boats")}</Text>
                                <Text className='pointer semi-bold-text' underline onClick={() => viewMemberInfo(Constant.HomeDetailsType.SkipperOfBoats)}>{memberDetail.SkipperOfBoats ? memberDetail.SkipperOfBoats.length : 0}</Text>
                            </Space>
                        </Col>
                    </Row>

                </div >
            }
            {currentPosition &&
                (currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee ||
                    currentPosition.PositionTypeId == Constant.PositionType.StateExcutive ||
                    currentPosition.PositionTypeId == Constant.PositionType.NationalExecutive) &&
                < div >
                    <Row align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                <Title className='color-dark-blue' level={5}>{t("home.system_information")}</Title>
                                <Text className='color-black semi-bold-text'>{memberDetail.EntityName}</Text>
                            </Space>
                        </Col>
                        <Col xs={{ span: 8 }}>
                            <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                <Text className='color-black'>{t("home.financial_members")}</Text>
                                <Text className='pointer semi-bold-text' underline onClick={() => viewMemberInfo(Constant.HomeDetailsType.FinancialMembers)}>{memberDetail.FinancialMembers ? memberDetail.FinancialMembers : 0}</Text>
                            </Space>

                        </Col>
                        <Col xs={{ span: 16 }}>
                            <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                <Text className='color-black'>{t("home.un_financial_members")}</Text>
                                <Text className='pointer semi-bold-text' underline onClick={() => viewMemberInfo(Constant.HomeDetailsType.UnFinancialMembers)}>{memberDetail.UnFinancialMembers ? memberDetail.UnFinancialMembers : 0}</Text>
                            </Space>
                        </Col>

                        <Col xs={{ span: 8 }}>
                            <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                <Text className='color-black'>{t("home.fishing_members")}</Text>
                                <Text className='pointer semi-bold-text' underline onClick={() => viewMemberInfo(Constant.HomeDetailsType.FishingMembers)}>{memberDetail.FishingMembers ? memberDetail.FishingMembers : 0}</Text>
                            </Space>

                        </Col>
                        <Col xs={{ span: 16 }}>
                            <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                <Text className='color-black'>{t("home.juniors_with_errors")}</Text>
                                <Text className='pointer semi-bold-text' underline onClick={() => viewMemberInfo(Constant.HomeDetailsType.JuniorsWithErrors)}>{memberDetail.JuniorsWithErrors ? memberDetail.JuniorsWithErrors : 0}</Text>
                            </Space>
                        </Col>

                        <Col xs={{ span: 8 }}>
                            <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                <Text className='color-black'>{t("home.active_members")}</Text>
                                <Text className='pointer semi-bold-text' underline onClick={() => viewMemberInfo(Constant.HomeDetailsType.ActiveMembers)}>{memberDetail.ActiveMembers ? memberDetail.ActiveMembers : 0}</Text>
                            </Space>
                        </Col>
                        <Col xs={{ span: 16 }}>
                            <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                <Text className='color-black'>{t("home.current_boats")}</Text>
                                <Text className='pointer semi-bold-text' underline onClick={() => viewMemberInfo(Constant.HomeDetailsType.CurrentBoat)}>{memberDetail.CurrentBoats ? memberDetail.CurrentBoats : 0}</Text>
                            </Space>
                        </Col>

                        <Col xs={{ span: 8 }}>
                            <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                <Text className='color-black'>{t("home.members_with_admin_access")}</Text>
                                <Text className='pointer semi-bold-text' underline onClick={() => viewMemberInfo(Constant.HomeDetailsType.MembersWithAdminAccess)}>{memberDetail.AdminMembers ? memberDetail.AdminMembers : 0}</Text>
                            </Space>
                        </Col>
                    </Row>

                </div >
            }
            {
                visibleMemberInfo && selectedMemberType &&
                <HomeDetails visible={visibleMemberInfo} handleCancel={handleMemberInfoCancel} selectedMemberType={selectedMemberType} objectData={memberDetail}></HomeDetails>
            }
        </>
    )
}

export default Home;
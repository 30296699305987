import React, { useState, useEffect } from 'react';
import './dateApplication.scss';
import { Constant, CommonService, TournamentService } from '../../../services/services';
import { useTranslation } from 'react-i18next';
import LoadingContext from '../../../components/loading/loadingContext'
import { Row, Col, Button, Form, Modal, Input, Typography, DatePicker } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import PubSub from 'pubsub-js'
import dayjs from 'dayjs';

const { confirm } = Modal;
const { Title } = Typography;
const { TextArea } = Input;
const _ = require("lodash");

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const DateApplication = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [submitForm] = Form.useForm();
    const [isDirty, setIsDirty] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);

    useEffect(() => {
        let startDate = dayjs()
        let finishDate = startDate.add(1, 'day')
        submitForm.setFieldValue("StartDate", startDate)
        submitForm.setFieldValue("FinishDate", finishDate)
    }, [])

    const onSubmit = () => {
        if (canSubmit) {
            // Add/Update
            showLoading();
            let tournament = submitForm.getFieldsValue();

            if (tournament.StartDate && tournament.FinishDate && tournament.StartDate > tournament.FinishDate) {
                CommonService.presentToast('error', t('tournament.start_date_must_be_less_end_date_message'));
                dismissLoading();
                return;
            }

            if (tournament.AlternateStartDate && tournament.AlternateStartDate && tournament.AlternateStartDate > tournament.AlternateStartDate) {
                CommonService.presentToast('error', t('tournament.alternate_start_date_must_be_less_alternate_end_date_message'));
                dismissLoading();
                return;
            }

            TournamentService.addUpdateTournament(tournament)
                .finally(() => {
                    dismissLoading();
                })
                .then(result => {
                    CommonService.presentToast('success', tournament.Id > 0 ? t('tournament.tournament_update_successful') : t('tournament.tournament_create_successful'));
                    props.handleCancel(true)
                })
                .catch(error => {
                    CommonService.handleErrorResponse(error);
                });
        }
    }

    const disabledStartDate = (current) => {
        var endDate = submitForm.getFieldValue('FinishDate');
        if (current && endDate && current.endOf('day') > endDate.endOf('day')) {
            return true;
        }
        return false;
    }

    const disabledEndDate = (current) => {
        var startDate = submitForm.getFieldValue('StartDate');
        if (current && startDate && current.endOf('day') < startDate.endOf('day')) {
            return true;
        }

        return false;
    }

    const disabledAlternateStartDate = (current) => {
        var alternateEndDate = submitForm.getFieldValue('AlternateFinishDate');
        if (current && alternateEndDate && current.endOf('day') > alternateEndDate.endOf('day')) {
            return true;
        }
        return false;
    }

    const disabledAlternateEndDate = (current) => {
        var alternateStartDate = submitForm.getFieldValue('AlternateStartDate');
        if (current && alternateStartDate && current.endOf('day') < alternateStartDate.endOf('day')) {
            return true;
        }

        return false;
    }

    const getErrorMessage = () => {
        let tournament = submitForm.getFieldsValue();

        if (!tournament || tournament.Title) {
            return t("tournament.tournament_title_is_required")
        }

        if (!tournament || tournament.StartDate) {
            return t("tournament.tournament_start_date_is_required")
        }

        if (!tournament || tournament.FinishDate) {
            return t("tournament.tournament_end_date_is_required")
        }

        if (!tournament || tournament.AlternateStartDate) {
            return t("tournament.alternate_start_date_is_required")
        }

        if (!tournament || tournament.AlternateFinishDate) {
            return t("tournament.alternate_end_date_is_required")
        }

        return null
    }

    const closeForm = () => {
        if (isDirty) {
            confirm({
                title: t("common.confirm"),
                icon: <ExclamationCircleOutlined />,
                content: t("common.submit_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    if (canSubmit) {
                        onSubmit()
                    } else {
                        let message = getErrorMessage()
                        if (message) {
                            CommonService.presentToast('error', message)
                        }
                    }

                },
                onCancel() {
                    props.handleCancel()
                }

            });
        } else {
            props.handleCancel()
        }
    }

    const onFormValuesChange = (changedValues, allValues) => {
        setIsDirty(true)

        let tournament = submitForm.getFieldsValue();
        if (tournament && tournament.Title && tournament.StartDate && tournament.StartDate &&
            tournament.AlternateStartDate && tournament.AlternateFinishDate) {
            setCanSubmit(true)
        } else {
            setCanSubmit(false)
        }
    }

    const onStartDateChange = (value) => {
        let date = dayjs(value)
        let finishDate = date.add(1, 'day')
        submitForm.setFieldValue("FinishDate", finishDate)
    }

    const onAlternativeStartDateChange = (value) => {
        let date = dayjs(value)
        let finishDate = date.add(1, 'day')
        submitForm.setFieldValue("AlternateFinishDate", finishDate)
    }

    return (
        <div>
            <Modal
                title={t("menu.apply_for_tournament_dates")}
                open={true}
                onCancel={closeForm}
                confirmLoading={false}
                maskClosable={false}
                centered
                width={850}
                footer={[
                    <Button key={0} size="large" onClick={closeForm}>{t("common.cancel")}</Button>,
                    <Button key={1} size="large" type="primary" onClick={onSubmit} disabled={!canSubmit}>{t("common.apply")}</Button>
                ]}
                className='tournament-date-application'
            >
                <Row>
                    <Col xs={{ span: 24 }}>
                        <Form
                            layout="vertical"
                            {...layout}
                            form={submitForm}
                            name="submitForm"
                            key='submitForm'
                            onValuesChange={onFormValuesChange}
                        >
                            <Row gutter={[Constant.SpaceConstant.HorizontalGutter, Constant.SpaceConstant.VerticalGutter]}>
                                <Col xs={{ span: 24 }}>
                                    <Form.Item
                                        label={t("tournament.tournament_title")}
                                        rules={[{ required: true, message: t("tournament.tournament_title_is_required") }]}
                                        name="Title" colon={false} labelAlign="left">
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 12 }}>
                                    <Form.Item
                                        label={t("tournament.tournament_start_date")}
                                        rules={[{ required: true, message: t("tournament.tournament_start_date_is_required") }]}
                                        name="StartDate" colon={false} labelAlign="left">
                                        <DatePicker disabledDate={disabledStartDate} format={Constant.DateFormat} placeholder={Constant.DateFormat} allowClear style={{ width: "100%" }}
                                        onChange={onStartDateChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 12 }}>
                                    <Form.Item
                                        label={t("tournament.tournament_end_date")}
                                        rules={[{ required: true, message: t("tournament.tournament_end_date_is_required") }]}
                                        name="FinishDate" colon={false} labelAlign="left">
                                        <DatePicker disabledDate={disabledEndDate} format={Constant.DateFormat} placeholder={Constant.DateFormat} allowClear style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 12 }}>
                                    <Form.Item
                                        label={t("tournament.alternate_start_date")}
                                        rules={[{ required: true, message: t("tournament.alternate_start_date_is_required") }]}
                                        name="AlternateStartDate" colon={false} labelAlign="left">
                                        <DatePicker disabledDate={disabledAlternateStartDate} format={Constant.DateFormat} placeholder={Constant.DateFormat} allowClear style={{ width: "100%" }} 
                                        onChange={onAlternativeStartDateChange}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 12 }}>
                                    <Form.Item
                                        label={t("tournament.alternate_end_date")}
                                        rules={[{ required: true, message: t("tournament.alternate_end_date_is_required") }]}
                                        name="AlternateFinishDate" colon={false} labelAlign="left">
                                        <DatePicker disabledDate={disabledAlternateEndDate} format={Constant.DateFormat} placeholder={Constant.DateFormat} allowClear style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }}>
                                    <Form.Item
                                        label={t("tournament.tournament_description")}
                                        rules={[{ required: false, message: t("tournament.tournament_description_is_required") }]}
                                        name="Description" colon={false} labelAlign="left">
                                        <TextArea rows={4} allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}

export default DateApplication;
import axios from 'axios';
import { axiosPortal } from './authService'

const source = [];
let baseURL = "api/Home"

export const HomeService = {
    getMemberDetail: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/MemberDetail`

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getFinanceMembers: function (pageNumber, pageSize) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/FinanceMembers?pageNumber=${pageNumber ? pageNumber : 1}`

        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getUnFinanceMembers: function (pageNumber, pageSize) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/UnFinanceMembers?pageNumber=${pageNumber ? pageNumber : 1}`
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getFishingMembers: function (pageNumber, pageSize) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/FishingMembers?pageNumber=${pageNumber ? pageNumber : 1}`

        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getJuniorWithErrorMembers: function (pageNumber, pageSize) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/JuniorWithErrorMembers?pageNumber=${pageNumber ? pageNumber : 1}`
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getActiveMembers: function (pageNumber, pageSize) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/ActiveMembers?pageNumber=${pageNumber ? pageNumber : 1}`
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getCurrentBoats: function (pageNumber, pageSize) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/CurrentBoats?pageNumber=${pageNumber ? pageNumber : 1}`
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getAdminMembers: function (pageNumber, pageSize) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/AdminMembers?pageNumber=${pageNumber ? pageNumber : 1}`
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    cancelRequest: function () {
        if (source && source.length > 0) {
            for (var i = 0; i < source.length; i++) {
                source[i].cancel('Operation canceled.')
            }
            source.splice(0, source.length)
        }
    }
}
import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Badge, Form, Button, Space, Upload, Modal, Typography } from 'antd';
import { Constant, SettingService, CommonService, CommunicationService } from '../../services/services';
import { useTranslation } from 'react-i18next';
import PubSub from 'pubsub-js'
import LoadingContext from '../../components/loading/loadingContext'
import { useGlobalState } from '../../utilities/globalState'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import AddImage from './../../assets/icons/add-image.svg';
import './emailMembers.scss';
import ConfirmLeavePage from "../../components/confirmLeavePage/confirmLeavePage"
import EnhancedSelect from '../../components/enhancedSelect/enhancedSelect';
import EmailMemberSearch from './emailMemberSearch';
import { PlusIcon } from '../../components/icons/icons'
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useHistory } from "react-router-dom";

const { TextArea } = Input;
const { confirm } = Modal;
const { Text } = Typography;

function EmailMembers(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const [form] = Form.useForm();
    const [currentPosition] = useGlobalState('currentPosition')
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [confirmLeave, setConfirmLeave] = useState(false)
    const [visibleEmailMemberSearch, setVisibleEmailMemberSearch] = useState(false)
    const [selectedMembers, setSelectedMembers] = useState([])
    const [editorState, setEditorState] = useState()
    const [attachments, setAttachments] = useState([{
        FileName: null,
        FileBase64: null
    }])

    useEffect(() => {
        if (currentPosition && currentPosition.PositionTypeId === Constant.PositionType.ClubMember) {
            history.push('/unauthorised');
            return;
        }
        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);

        return () => {
            SettingService.cancelRequest()
        }
    }, [])

    const onSubmit = () => {
        let data = form.getFieldsValue()
        if (data) {
            let emailList = []

            if(data.To){
                emailList.push(data.To)
            }          

            if (selectedMembers) {
                for (let i = 0; i < selectedMembers.length; i++) {
                    emailList.push(selectedMembers[i].Email)
                }
            }
            data.EmailTos = emailList

            if (editorState) {
                data.Message = draftToHtml(convertToRaw(editorState.getCurrentContent()))
            }

            if (attachments) {
                let files = attachments.filter(a => a.FileBase64)
                if (files.length > 0) {
                    data.Attachments = files
                }
            }

            showLoading()
            CommunicationService.sendEmailMembers(data)
                .finally(() => dismissLoading())
                .then(result => {
                    CommonService.presentToast('success', t("email_members.email_success"));
                    resetPage()
                })
                .catch(error => {
                    CommonService.handleErrorResponse(error);
                })
        }
    }

    const onCancel = () => {
        confirm({
            title: t("common.confirm"),
            icon: <ExclamationCircleOutlined />,
            content: t("entity_detail.reset_warning"),
            okText: t("common.yes"),
            cancelText: t("common.no"),
            onOk() {
                resetPage()
            },
            onCancel() {
            }

        });
    }

    const onPropertyChanged = () => {
        setConfirmLeave(true)
    }

    const beforeUploadFile = (file) => {
        CommonService.getFileBase64(file, fileUrl => {

            setConfirmLeave(true)

            let attachmentList = attachments.filter(a => a.FileBase64)
            let temp = attachmentList.map(x => Object.assign({}, x));
            temp.push({
                FileName: file.name,
                FileBase64: fileUrl
            })

            if (temp.length < 5) {
                temp.push({
                    FileName: null,
                    FileBase64: null
                })
            }

            setAttachments(temp)
        });
    }

    const onEditorStateChange = (state) => {
        setEditorState(state)
    }

    const handleEmailMemberSearchCancel = (data) => {
        setSelectedMembers(data)
        setVisibleEmailMemberSearch(false)
    }

    const deleteAttachment = (file) => {
        let newFiles = attachments.filter(a => a != file)
        setAttachments(newFiles)
    }

    // const canSubmit = () => {
    //     let data = form.getFieldsValue()
    //     if(!data || !data.To || !data.Subject){
    //         return false
    //     }
    //     return true
    // }

    const resetPage = () => {
        form.resetFields()
        setSelectedMembers([])
        setConfirmLeave(false)
        setEditorState(null)
        setAttachments([{
            FileName: null,
            FileBase64: null
        }])
    }

    return (
        <div className="email-members-page">
            <ConfirmLeavePage
                shouldPrompt={confirmLeave}
            >
                {({ leavePage, stayOnPage, isDialogActive }) => (
                    <Modal
                        title={t("common.warning")}
                        open={isDialogActive}
                        onOk={leavePage}
                        onCancel={stayOnPage}
                    >
                        <p>
                            {t("entity_detail.leave_warning")}
                        </p>
                    </Modal>
                )}
            </ConfirmLeavePage>

            <div>
                <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                    <h5 className='color-dark-blue'>{t("email_members.title")}</h5>
                </Row>
                <Form
                    form={form}
                    name="formEmail"
                    layout="vertical"
                    onFinish={onSubmit}
                >
                    <Row gutter={[Constant.SpaceConstant.HorizontalGutter]}>
                        <Col xs={{ span: 17 }}>
                            <Form.Item
                                label={t("email_members.to")}
                                name="To" colon={false} labelAlign="left"
                                className={!selectedMembers || selectedMembers.length == 0 ? 'to-required' : ''}
                                rules={[() => ({
                                    validator(rule, value) {
                                        if ((selectedMembers && selectedMembers.length > 0) || value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(t("email_members.to_required"));
                                    },
                                }),
                                { type: "email", message: t("email_members.email_invalid") }]}
                            >
                                <Input maxLength={100} onChange={onPropertyChanged} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 7 }}>
                            <Form.Item label=" ">
                                <Badge count={selectedMembers ? selectedMembers.length : 0}>
                                    <Button style={{ float: 'right' }} type='primary' onClick={() => { setVisibleEmailMemberSearch(true) }} className='btn-icon' icon={<PlusIcon />}>{t("email_members.select_member_list")}</Button>
                                </Badge>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 17 }}>
                            <Form.Item
                                label={t("email_members.reply_to")}
                                name="ReplyTo" colon={false} labelAlign="left"
                            >
                                <Input maxLength={100} onChange={onPropertyChanged} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 17 }}>
                            <Form.Item
                                label={t("email_members.subject")}
                                name="Subject" colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("email_members.subject_required") }]}
                            >
                                <Input maxLength={100} onChange={onPropertyChanged} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 17 }}>
                            <Row>
                                <Col xs={{ span: 4 }}>
                                    <Text className='color-black'>{t("email_members.attachments")}</Text>
                                </Col>
                                <Col xs={{ span: 20 }}>
                                    {
                                        attachments.map(a =>
                                            <Row gutter={[48]} className='m-b-10'>
                                                <Col xs={{ span: 4 }}>
                                                    <Space>
                                                        <Upload
                                                            listType="text"
                                                            showUploadList={false}
                                                            beforeUpload={file => beforeUploadFile(file)}
                                                            maxCount={1}
                                                        >
                                                            <Button size="small" className='btn-solid-dark-blue'>{t("sanctioning_applications.choose_file")}</Button>
                                                        </Upload>
                                                    </Space>
                                                </Col>
                                                <Col xs={{ span: 20 }}>
                                                    <Space direction="horizontal" size={Constant.SpaceConstant.TextFieldGutter}>
                                                        <Text className='semi-bold-text'>{a.FileName ? a.FileName : "_"}</Text>
                                                        {a.FileBase64 &&
                                                            <Button className='color-dark-red delete-button' type="text" onClick={() => deleteAttachment(a)} icon={<DeleteOutlined />} size="middle" />
                                                        }
                                                    </Space>
                                                </Col>
                                            </Row>
                                        )
                                    }

                                </Col>
                            </Row>
                        </Col>
                        <Col xs={{ span: 17 }}>
                            <Form.Item
                                label={t("email_members.message")}
                                name="Message" colon={false} labelAlign="left"
                            >
                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={onEditorStateChange}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <span>{t("email_members.instruction_1")}</span>
                    </Row>
                    <Row>
                        <span>{t("email_members.instruction_2")}</span>
                    </Row>
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                        <Col flex={100} className="text-right">
                            <div>
                                <Button className='btn-outline-medium-grey' onClick={onCancel}>{t("common.cancel")}</Button>
                                <Button size="large" type="primary" className='m-l-10' htmlType="submit">{t("email_members.send_email")}</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div >
            {
                visibleEmailMemberSearch &&
                <EmailMemberSearch visible={visibleEmailMemberSearch} handleCancel={handleEmailMemberSearchCancel} objectData={selectedMembers}></EmailMemberSearch>
            }
        </div>
    )
}

export default EmailMembers
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
    Constant,
    CommonService,
    StorageService,
    DocumentService
} from '../../../services/services'
import { Table, Button, Row, Col, Form, Tabs, Modal, Drawer, Space, Checkbox, Badge } from 'antd';
import { GetTableConfigs, ActionButtonsCell, EditableCell, DateTimeCell } from '../../../components/customTable/customTable'
import { TrashIcon, CloseIcon } from '../../../components/icons/icons'
import LoadingContext from '../../../components/loading/loadingContext'
import { useGlobalState } from '../../../utilities/globalState'

const { confirm } = Modal;
const _ = require("lodash");

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const components = {
    body: {
        cell: EditableCell,
    },
};

const CreateEditDocument = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const { showLoading, dismissLoading } = React.useContext(LoadingContext);
    const [originalDocuments, setOriginalDocuments] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [fileList, setFileList] = useState([]);

    var [currentPosition] = useGlobalState('currentPosition');
    const [enableSubmit, setEnableSubmit] = useState(false);
   
    const onSubmit = () => {
        // Validate
        var allErrorMessages = [];

        setDocuments(current =>
            current.map(obj => {
                var errorMessages = [];
                if (!obj.Title) {
                    errorMessages.push({
                        key: 'Title',
                        value: t('document.title_is_required')
                    })
                }

                if (!obj.FileName) {
                    errorMessages.push({
                        key: 'FileName',
                        value: t('document.file_name_is_required')
                    })
                }

                if (!obj.DocumentCategoryId) {
                    errorMessages.push({
                        key: 'DocumentCategoryId',
                        value: t('document.category_is_required')
                    })
                }

                if (!obj.StatusId) {
                    errorMessages.push({
                        key: 'StatusId',
                        value: t('common.status_is_required')
                    })
                }

                allErrorMessages.push(...errorMessages);
                return {
                    ...obj,
                    required: errorMessages.length > 0 ? true : false,
                    ErrorMessages: errorMessages,
                };
            }),
        );

        if (allErrorMessages.length > 0) {            
            CommonService.presentToast('error', allErrorMessages.map(x => x.value).join(', '));
            return;
        }

        documents.forEach(document => {
            if (document.DocumentDate) {
                if (typeof document.DocumentDate === 'string' || document.DocumentDate instanceof String) {
                    var length = document.DocumentDate.split('/').length;
                    if (length >= 3) {
                        document.DocumentDate = moment(document.DocumentDate, Constant.DateFormat).format(Constant.DateFormatYYYMMDD);
                    }
                } else {
                    document.DocumentDate = document.DocumentDate.format(Constant.DateFormatYYYMMDD);
                }
            }
        });

        // Add/Update
        showLoading();
        DocumentService.addUpdateDocuments(documents)
            .finally(() => {
                dismissLoading();
            })
            .then(result => {
                if (result.data) {
                    CommonService.presentToast('success', props.isEdit ? t('document.update_documents_successful') : t('document.create_documents_successful'));
                    props.handleCancel(true);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const canSubmit = (newDocuments) => {
        if (newDocuments && newDocuments.length > 0) {
            let inValid = newDocuments.filter(x => !x.Title || !x.FileName || !x.DocumentCategoryId);
            if (inValid && inValid.length > 0) {
                return false
            }
            return true;
        }
        return false;
    }

    const submitConfirmation = () => {
        function customizer(newDocuments, oldDocuments) {
            if (newDocuments.length !== oldDocuments.length)
                return true;

            for (var i = 0; i < newDocuments.length; i ++) {
                
                var newDocumentDate = '';
                if (newDocuments[i].DocumentDate) {
                    if (typeof newDocuments[i].DocumentDate === 'string' || newDocuments[i].DocumentDate instanceof String) {
                        var length = newDocuments[i].DocumentDate.split('/').length;
                        if (length >= 3) {
                            newDocumentDate = moment(newDocuments[i].DocumentDate, Constant.DateFormat).format(Constant.DateFormatYYYMMDD);
                        } else {
                            newDocumentDate = moment(newDocuments[i].DocumentDate, Constant.DateFormatYYYMMDD).format(Constant.DateFormatYYYMMDD);
                        }
                    } else {
                        newDocumentDate = newDocuments[i].DocumentDate.format(Constant.DateFormatYYYMMDD);
                    }
                }

                var oldDocumentDate = '';
                if (oldDocuments[i].DocumentDate) {
                    if (typeof oldDocuments[i].DocumentDate === 'string' || oldDocuments[i].DocumentDate instanceof String) {
                        var length = oldDocuments[i].DocumentDate.split('/').length;
                        if (length >= 3) {
                            oldDocumentDate = moment(oldDocuments[i].DocumentDate, Constant.DateFormat).format(Constant.DateFormatYYYMMDD);
                        } else {
                            oldDocumentDate = moment(oldDocuments[i].DocumentDate, Constant.DateFormatYYYMMDD).format(Constant.DateFormatYYYMMDD);
                        }
                    } else {
                        oldDocumentDate = oldDocuments[i].DocumentDate.format(Constant.DateFormatYYYMMDD);
                    }
                }

                if (newDocuments[i].IsAddNew || oldDocuments[i].IsAddNew) {
                    if (((newDocuments[i].Title !== null || oldDocuments[i].Title !== null) && newDocuments[i].Title !== oldDocuments[i].Title) ||
                        ((newDocuments[i].FileName !== null || oldDocuments[i].FileName !== null) && newDocuments[i].FileName !== oldDocuments[i].FileName) ||
                        ((newDocuments[i].DocumentCategoryId !== null || oldDocuments[i].DocumentCategoryId !== null) && newDocuments[i].DocumentCategoryId !== oldDocuments[i].DocumentCategoryId) ||
                        (newDocumentDate !== oldDocumentDate) ||
                        ((newDocuments[i].StatusId !== null || oldDocuments[i].StatusId !== null) && newDocuments[i].StatusId !== oldDocuments[i].StatusId) ||
                        ((newDocuments[i].AccessPublic !== null || oldDocuments[i].AccessPublic !== null) && newDocuments[i].AccessPublic !== oldDocuments[i].AccessPublic) ||
                        ((newDocuments[i].AccessClubMembers !== null || oldDocuments[i].AccessClubMembers !== null) && newDocuments[i].AccessClubMembers !== oldDocuments[i].AccessClubMembers) ||
                        ((newDocuments[i].AccessClubCommittee !== null || oldDocuments[i].AccessClubCommittee !== null) && newDocuments[i].AccessClubCommittee !== oldDocuments[i].AccessClubCommittee) ||
                        ((newDocuments[i].AccessStateMembers !== null || oldDocuments[i].AccessStateMembers !== null) && newDocuments[i].AccessStateMembers !== oldDocuments[i].AccessStateMembers) ||
                        ((newDocuments[i].AccessStateCommittee !== null || oldDocuments[i].AccessStateCommittee !== null) && newDocuments[i].AccessStateCommittee !== oldDocuments[i].AccessStateCommittee)) {
                        return true;
                    }
                }
                else {
                    if (((newDocuments[i].Title !== null || oldDocuments[i].Title !== null) && newDocuments[i].Title !== oldDocuments[i].Title) ||
                        ((newDocuments[i].FileName !== null || oldDocuments[i].FileName !== null) && newDocuments[i].FileName !== oldDocuments[i].FileName) ||
                        ((newDocuments[i].DocumentCategoryId !== null || oldDocuments[i].DocumentCategoryId !== null) && newDocuments[i].DocumentCategoryId !== oldDocuments[i].DocumentCategoryId) ||
                        (newDocumentDate !== oldDocumentDate) ||
                        ((newDocuments[i].StatusId !== null || oldDocuments[i].StatusId !== null) && newDocuments[i].StatusId !== oldDocuments[i].StatusId) ||
                        ((newDocuments[i].AccessPublic !== null || oldDocuments[i].AccessPublic !== null) && newDocuments[i].AccessPublic !== oldDocuments[i].AccessPublic) ||
                        ((newDocuments[i].AccessClubMembers !== null || oldDocuments[i].AccessClubMembers !== null) && newDocuments[i].AccessClubMembers !== oldDocuments[i].AccessClubMembers) ||
                        ((newDocuments[i].AccessClubCommittee !== null || oldDocuments[i].AccessClubCommittee !== null) && newDocuments[i].AccessClubCommittee !== oldDocuments[i].AccessClubCommittee) ||
                        ((newDocuments[i].AccessStateMembers !== null || oldDocuments[i].AccessStateMembers !== null) && newDocuments[i].AccessStateMembers !== oldDocuments[i].AccessStateMembers) ||
                        ((newDocuments[i].AccessStateCommittee !== null || oldDocuments[i].AccessStateCommittee !== null) && newDocuments[i].AccessStateCommittee !== oldDocuments[i].AccessStateCommittee)) {
                        return true;
                    }
                }
            }

            return false;
        }
        
        var newDocuments = _.sortBy(documents, [function(m) { return m.Id; }]);
        var oldDocuments = _.sortBy(originalDocuments, [function(m) { return m.Id; }]);
        var changed = _.isEqualWith(newDocuments, oldDocuments, customizer);
        if (changed) {
            confirm({
                title: t("common.submit_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    onSubmit();
                },
                onCancel() {
                    props.handleCancel(true);
                }
            });
        }
        else {
            props.handleCancel(false);
        }
    }


    const onClose = () => {
        submitConfirmation();
    }

    const addDocument = () => {
        var id = -(documents.length + 1);
        setDocuments([...documents, newDocument(id)]);
        setDocuments(current =>
            current.map(obj => {
                return {
                    ...obj,
                    DocumentDate: obj.DocumentDate ? moment(obj.DocumentDate).format(Constant.DateFormat) : null,
                };
            }),
        );
    }

    const deleteDocument = (record) => {
        if (!record.IsAddNew && record.Id > 0) {
            confirm({
                title: t("document.delete_document_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    showLoading();
    
                    DocumentService.deleteDocuments([record.Id])
                    .finally(() => {
                        dismissLoading();
                    })
                    .then(result => {
                        const newDocuments = documents.filter((m) => m.Id !== record.Id);
                        setDocuments(newDocuments);
                    })
                    .catch(error => {
                        CommonService.handleErrorResponse(error);
                    });
                }
            });
        } else {
            const newDocuments = documents.filter((m) => m.Id !== record.Id);
            setDocuments(newDocuments);
        }
    }
    
    const handleOnChange = (value, record, dataIndex) => {
        if (record) {
            var index = documents.findIndex(x => x.Id === record.Id);
            _.update(documents[index], dataIndex, function (n) { 
                return value;
            });
        }

        setEnableSubmit(canSubmit(documents));
    }

    const onRemoveFile = (file, record, dataIndex) => {
        if (record) {
            var index = documents.findIndex(x => x.Id === record.Id);
            _.update(documents[index], 'FileName', function (n) { 
                return null;
            });

            _.update(documents[index], 'FileBase64', function (n) { 
                return null;
            });

            _.update(documents[index], 'Url', function (n) { 
                return null;
            });
            onSetFileList(documents);
        }
        setEnableSubmit(canSubmit(documents));
        return false;
    }

    const beforeUploadFile = (file, record, dataIndex) => {
        if (record) {
            CommonService.getFileBase64(file, fileBase64 => {
                var index = documents.findIndex(x => x.Id === record.Id);
                _.update(documents[index], 'FileName', function (n) { 
                    return file.name;
                });

                _.update(documents[index], 'FileBase64', function (n) { 
                    return fileBase64;
                });
                onSetFileList(documents);
            });
        }
        setEnableSubmit(canSubmit(documents));
        return false;
    }

    const onSetFileList = (newDocuments) => {
        var newFileList = [];
        for (var i = 0; i < newDocuments.length; i ++) {
            if (newDocuments[i].FileName) {
                newFileList.push({
                    uid: newDocuments[i].Id,
                    name: newDocuments[i].FileName,
                    status: 'done',
                    url: newDocuments[i].Url,
                });
            }
        }
        setFileList(newFileList);
    }
    
    const getFileList = (record) => {
        if (record) {
            var index = fileList.findIndex(x => x.uid === record.Id);
            if (index >= 0) {
                return [fileList[index]];
            }
        }
        return [];
    }

    const statusCodes = [
        {
            Value: Constant.StatusCode.Active,
            Description: t("common.active")
        },
        {
            Value: Constant.StatusCode.Inactive,
            Description: t("common.inactive")
        }
    ]

    const clubColumns = [
        {
            title: t('common.action'),
            key: 'Id',
            render: (value, record) => {
                return <ActionButtonsCell data={record} btnList={[
                    {
                        Description: t("common.delete"),
                        Icon: <TrashIcon />,
                        ColorClass: Constant.ColorClass.LightRed,
                        Hide: false,
                        NoMargin: true,
                        handleAction: deleteDocument,
                    }
                ]} />
            },
            align: 'center',
            width: 45,
        },
        {
            title: <span className='required'>{ t('document.title') }</span>,
            dataIndex: 'Title',
            key: 'Title',
            editable: true,
            required: true,
            cellType: Constant.CellType.Input,
            width: 130,
            handleOnChange: handleOnChange,
        },
        {
            title: <span className='required'>{ t('document.file_name') }</span>,
            dataIndex: 'FileName',
            key: 'FileName',
            editable: true,
            required: true,
            cellType: Constant.CellType.Upload,
            width: 130,
            onRemoveFile: onRemoveFile,
            beforeUploadFile: beforeUploadFile,
            getFileList: getFileList,
        },
        {
            title: <span className='required'>{ t('document.category') }</span>,
            dataIndex: 'DocumentCategoryId',
            key: 'DocumentCategoryId',
            editable: true,
            extraData: props.documentCategories,
            required: true,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "Name",
            selectionSort: "Id",
            width: 120,
            handleOnChange: handleOnChange,
        },
        {
            title: t('document.date'),
            dataIndex: 'DocumentDate',
            key: 'DocumentDate',
            editable: true,
            cellType: Constant.CellType.Date,
            width: 95,
            handleOnChange: handleOnChange,
        },
        {
            title: t('document.club_members'),
            dataIndex: 'AccessClubMembers',
            key: 'AccessClubMembers',
            editable: true,
            cellType: Constant.CellType.Checkbox,
            align: "center",
            width: 58,
            handleOnChange: handleOnChange,
        },
        {
            title: t('document.club_committee'),
            dataIndex: 'AccessClubCommittee',
            key: 'AccessClubCommittee',
            editable: true,
            cellType: Constant.CellType.Checkbox,
            align: "center",
            width: 65,
            handleOnChange: handleOnChange,
        },
        {
            title: t('document.state_members'),
            dataIndex: 'AccessStateMembers',
            key: 'AccessStateMembers',
            editable: true,
            cellType: Constant.CellType.Checkbox,
            align: "center",
            width: 58,
            handleOnChange: handleOnChange,
        },
        {
            title: t('document.state_committee'),
            dataIndex: 'AccessStateCommittee',
            key: 'AccessStateCommittee',
            editable: true,
            cellType: Constant.CellType.Checkbox,
            align: "center",
            width: 65,
            handleOnChange: handleOnChange,
        },
        {
            title: t('document.public'),
            dataIndex: 'AccessPublic',
            key: 'AccessPublic',
            editable: true,
            cellType: Constant.CellType.Checkbox,
            align: "center",
            width: 45,
            handleOnChange: handleOnChange,
        },
        {
            title: <span className='required'>{ t('common.status') }</span>,
            dataIndex: 'StatusId',
            key: 'StatusId',
            editable: true,
            extraData: statusCodes,
            required: true,
            cellType: Constant.CellType.StatusSelection,
            selectionKey: "Value",
            selectionDisplay: "Description",
            width: 70,
            allowClear: false,
            handleOnChange: handleOnChange
        }
    ]

    const stateColumns = [
        {
            title: t('common.action'),
            key: 'Id',
            render: (value, record) => {
                return <ActionButtonsCell data={record} btnList={[
                    {
                        Description: t("common.delete"),
                        Icon: <TrashIcon />,
                        ColorClass: Constant.ColorClass.LightRed,
                        Hide: false,
                        NoMargin: true,
                        handleAction: deleteDocument,
                    }
                ]} />
            },
            align: 'center',
            width: 45,
        },
        {
            title: <span className='required'>{ t('document.title') }</span>,
            dataIndex: 'Title',
            key: 'Title',
            editable: true,
            required: true,
            cellType: Constant.CellType.Input,
            width: 150,
            handleOnChange: handleOnChange,
        },
        {
            title: <span className='required'>{ t('document.file_name') }</span>,
            dataIndex: 'FileName',
            key: 'FileName',
            editable: true,
            required: true,
            cellType: Constant.CellType.Upload,
            width: 140,
            onRemoveFile: onRemoveFile,
            beforeUploadFile: beforeUploadFile,
            getFileList: getFileList,
        },
        {
            title: <span className='required'>{ t('document.category') }</span>,
            dataIndex: 'DocumentCategoryId',
            key: 'DocumentCategoryId',
            editable: true,
            extraData: props.documentCategories,
            required: true,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "Name",
            selectionSort: "Id",
            width: 120,
            handleOnChange: handleOnChange,
        },
        {
            title: t('document.date'),
            dataIndex: 'DocumentDate',
            key: 'DocumentDate',
            editable: true,
            cellType: Constant.CellType.Date,
            width: 98,
            handleOnChange: handleOnChange,
        },
        {
            title: t('document.club_committee'),
            dataIndex: 'AccessClubCommittee',
            key: 'AccessClubCommittee',
            editable: true,
            cellType: Constant.CellType.Checkbox,
            align: "center",
            width: 65,
            handleOnChange: handleOnChange,
        },
        {
            title: t('document.state_members'),
            dataIndex: 'AccessStateMembers',
            key: 'AccessStateMembers',
            editable: true,
            cellType: Constant.CellType.Checkbox,
            align: "center",
            width: 58,
            handleOnChange: handleOnChange,
        },
        {
            title: t('document.state_committee'),
            dataIndex: 'AccessStateCommittee',
            key: 'AccessStateCommittee',
            editable: true,
            cellType: Constant.CellType.Checkbox,
            align: "center",
            width: 65,
            handleOnChange: handleOnChange,
        },
        {
            title: t('document.public'),
            dataIndex: 'AccessPublic',
            key: 'AccessPublic',
            editable: true,
            cellType: Constant.CellType.Checkbox,
            align: "center",
            width: 45,
            handleOnChange: handleOnChange,
        },
        {
            title: <span className='required'>{ t('common.status') }</span>,
            dataIndex: 'StatusId',
            key: 'StatusId',
            editable: true,
            extraData: statusCodes,
            required: true,
            cellType: Constant.CellType.StatusSelection,
            selectionKey: "Value",
            selectionDisplay: "Description",
            width: 70,
            allowClear: false,
            handleOnChange: handleOnChange
        }
    ]

    const isClub = () => {
        if (typeof (currentPosition.PositionTypeId) === 'undefined') {
            currentPosition = StorageService.getCurrentPosition();
        }

        if (currentPosition && currentPosition.EntityTypeId === Constant.EntityType.Club) {
            return true;
        } else {
            return false;
        }
    }

    const mainColumns = (!isClub() ? stateColumns : clubColumns).map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                disabled: col.disabled,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition,
                selectionKey: col.selectionKey,
                selectionDisplay: col.selectionDisplay,
                validateAtStart: col.validateAtStart,
                allowClear: col.allowClear,
                selectionSort: col.selectionSort,
                onRemoveFile: col.onRemoveFile,
                beforeUploadFile: col.beforeUploadFile,
                getFileList: col.getFileList,
            }),
        };
    })

    const newDocument = (id) => {
        return {
            IsAddNew: true,
            Id: id,
            Title: null,
            FileName: null,
            DocumentDate: moment(),
            StatusId: Constant.StatusCode.Active,
        };
    }

    useEffect(() => {
        showLoading();

        var newDocuments = [];
        if (props.isCopy) {
            newDocuments = [...newDocuments, ...props.selectedDocuments];
        }
        else if (!props.isEdit) {
            var id = -(newDocuments.length + 1);
            newDocuments.push(newDocument(id));
        } else {
            newDocuments = [...newDocuments, ...props.selectedDocuments];
        }
        setDocuments(newDocuments);
        setDocuments(current =>
            current.map(obj => {
                return {
                    ...obj,
                    DocumentDate: obj.DocumentDate ? moment(obj.DocumentDate).format(Constant.DateFormat) : null,
                };
            }),
        );
        onSetFileList(newDocuments);
        setOriginalDocuments(_.cloneDeep(newDocuments));
        setEnableSubmit(canSubmit(newDocuments));

        Promise.all([
        ])
        .finally(() => {            
            dismissLoading();
        })
        .then((values) => {
        });

        return () => {
        }
    }, [])

    return (
        <div>
            <Modal
                title={props.isEdit ? t("document.edit_documents") : t("document.insert_documents")}
                centered
                forceRender
                open={true}
                onOk={onSubmit}
                confirmLoading={false}
                onCancel={onClose}
                okText={props.isEdit ? t("common.submit") : t("common.insert")}
                cancelText={t("common.cancel")}
                maskClosable={false}
                width={1450}
                footer={[
                    <Button key="back" onClick={onClose}>
                      {t("common.cancel")}
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} disabled={!enableSubmit} onClick={onSubmit}>
                      {props.isEdit ? t("common.submit") : t("common.insert")}
                    </Button>
                  ]}
            >
                <Form
                    {...layout}
                    name='form'
                    key='form'
                    onFinish={onSubmit}
                    component={false}
                    form={form}>
                    <Row id={Constant.FixItemsContainerId} className="fix-item-container">
                        <Col xs={{ span: 24 }} className="text-right extra-btn-m-t">
                            <div className="m-t-10">
                                <Button className="m-l-10 secondary-btn" type="primary" onClick={addDocument}>
                                    {t("common.add")}
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    <Row className="m-t-10" gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Table
                                components={components}
                                rowClassName={() => 'editable-row'}
                                tableLayout='fixed'
                                columns={mainColumns} 
                                dataSource={documents} 
                                pagination={false}
                                rowKey="Id"
                            />
                        </Col>
                    </Row>
                </Form>
            </Modal>
            
        </div>
    )
}

export default CreateEditDocument
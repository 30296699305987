import React, { useState, useEffect } from 'react';
import { Modal, InputNumber, Row, Col, Button, Upload, Input, Form, Select, Tabs, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import LoadingContext from '../../components/loading/loadingContext'
import { useGlobalState } from './../../utilities/globalState'
import { Constant, CommonService, RecordService } from '../../services/services';
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import './createEditRecord.scss';
import EnhancedSelect from '../../components/enhancedSelect/enhancedSelect';
import AddImage from './../../assets/icons/add-image.svg';

const { confirm } = Modal;
const { TextArea } = Input;
const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};
const { TabPane } = Tabs;

const AddUpdateRecord = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [isEdit, setIsEdit] = useState(false);
    const [form] = Form.useForm();
    const [currentPosition] = useGlobalState('currentPosition');
    const [canSubmit, setCanSubmit] = useState(false);
    const [fishSpecies, setFishSpecies] = useState([]);
    const [recordCategories, setRecordCategories] = useState([]);
    const [recordEnvironments, setRecordEnvironments] = useState([]);
    const [lineClasses, setLineClasses] = useState([]);
    const [fishingLocations, setFishingLocations] = useState([]);
    const [entities, setEntities] = useState([]);
    const [imageBase64, setImageBase64] = useState();
    const [imageName, setImageName] = useState();
    const [imageUrl, setImageUrl] = useState();
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        setFishSpecies(props.fishSpecies);
        setRecordCategories(props.recordCategories);
        setRecordEnvironments(props.recordEnvironments);
        setLineClasses(props.lineClasses);
        setFishingLocations(props.fishingLocations);
        setEntities(props.entities);
        if (props.visible) {
            if (!props.objectData || !props.objectData.Id) {
                setIsEdit(false)
            } else {
                if (props.objectData.RecordDate) {
                    props.objectData.RecordDate = dayjs(props.objectData.RecordDate, Constant.DateFormatYYYMMDD);
                } else {
                    props.objectData.RecordDate = null;
                }

                if (props.objectData.ImageBase64) {
                    setImageBase64(props.objectData.ImageBase64)
                }
                setImageUrl(props.objectData.ImageUrl)
                form.setFieldsValue(props.objectData)
                setIsEdit(true)

                setTimeout(() => {
                    onFormValuesChange(null, null, true)
                }, 100);
            }
        }
    }, [props.visible])


    const getErrorMessage = () => {
        let record = form.getFieldsValue()
        if (!record.FishSpeciesId) {
            return t("record_management.fish_species_required")
        }
        if (!record.RecordEnvironmentId) {
            return t("record_management.record_environment_required")
        }
        if (!record.RecordCategoryId) {
            return t("record_management.record_category_required")
        }
        if (!record.LineClassId) {
            return t("record_management.line_class_required")
        }
        if (!record.FishWeight) {
            return t("record_management.fish_weight_required")
        }
        if (!record.FishingLocationId) {
            return t("record_management.fishing_location_required")
        }
        if (!record.RecordDate) {
            return t("record_management.record_date_required")
        }
        if (!record.FirstName) {
            return t("record_management.first_name_required")
        }
        if (!record.Surname) {
            return t("record_management.surname_required")
        }
        if (!record.ClubEntityId) {
            return t("record_management.club_required")
        }
        return null
    }

    const closeForm = () => {
        if (isDirty) {
            confirm({
                title: t("common.confirm"),
                icon: <ExclamationCircleOutlined />,
                content: t("common.submit_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    if (canSubmit) {
                        onSubmit()
                    } else {
                        let message = getErrorMessage()
                        if (message) {
                            CommonService.presentToast('error', message)
                        }
                    }

                },
                onCancel() {
                    props.handleCancel()
                }

            });
        } else {
            props.handleCancel()
        }
    }

    const onSubmit = () => {
        if (canSubmit) {
            if (isEdit) {
                showLoading()
                let record = form.getFieldsValue()

                Object.assign(props.objectData, record)
                props.objectData.ImageBase64 = imageBase64
                props.objectData.ImageName = imageName
                props.objectData.ImageUrl = imageUrl
                RecordService.updateRecord(props.objectData)
                    .finally(() => dismissLoading())
                    .then(result => {
                        CommonService.presentToast('success', t("record_management.update_record_successful"))
                        props.handleCancel(true)
                    })
                    .catch(error => CommonService.handleErrorResponse(error))
            } else {
                showLoading()
                let record = form.getFieldsValue()
                record.ImageBase64 = imageBase64
                record.ImageName = imageName

                RecordService.addRecord(record)
                    .finally(() => dismissLoading())
                    .then(result => {
                        CommonService.presentToast('success', t("record_management.create_record_successful"))
                        props.handleCancel(true)
                    })
                    .catch(error => CommonService.handleErrorResponse(error))
            }
        }
    }

    const onFormValuesChange = (changedValues, allValues, ignoreDirty = false) => {

        if (!ignoreDirty) {
            setIsDirty(true)
        }

        let record = form.getFieldsValue()
        if (!record || !record.FishSpeciesId || !record.RecordEnvironmentId || !record.RecordCategoryId ||
            !record.LineClassId || !record.FishWeight || !record.LocationName || !record.RecordDate || !record.FirstName || !record.Surname) {
            setCanSubmit(false)
        } else {
            setCanSubmit(true)
        }
    }

    const beforeUploadPhoto = img => {
        setIsDirty(true);
        CommonService.getImgBase64(img, imageUrl => {
            setImageBase64(imageUrl);
            setImageName(img.name);
        });
    }

    const getImageLogo = () => {
        if (props.objectData) {
            if (imageUrl) {
                return imageUrl
            } else if (imageBase64) {
                return imageBase64
            }

            return null
        }
    }

    const removeLogo = () => {
        setImageUrl("");
        setImageBase64("");
        setImageName("");
        setIsDirty(true);
    }
    const isClub = () => {
        return currentPosition != null && currentPosition.EntityTypeId === Constant.EntityType.Club;
    }
    return (
        <div>
            <Modal
                title={isEdit ? t("record_management.update_record") : t("record_management.insert_record")}
                open={true}
                onCancel={closeForm}
                confirmLoading={false}
                maskClosable={false}
                centered
                width={1000}
                className='add-update-record-modal'
                footer={[
                    <Button key={0} size="large" onClick={closeForm}>{t("common.cancel")}</Button>,
                    <Button key={1} size="large" type="primary" disabled={!canSubmit} onClick={onSubmit}>{isEdit ? t("common.submit") : t("common.insert")}</Button>
                ]}
            >
                <Form
                    layout="vertical"
                    {...layout}
                    form={form}
                    name="recordForm"
                    key='recordForm'
                    onFinish={onSubmit}
                    onValuesChange={onFormValuesChange}
                    initialValues={{ StatusId: Constant.StatusCode.Active }}
                >
                    <Row gutter={[Constant.SpaceConstant.HorizontalGutter,]}>
                        <Col xs={{ span: 24 }}>
                            <Form.Item
                                name="FishSpeciesId"
                                label={t("fishing_activity.species")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("record_management.fish_species_required"),
                                    },
                                ]}
                            >
                                <EnhancedSelect
                                    showSearch optionFilterProp="children"
                                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}>
                                    {
                                        fishSpecies.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                    }
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="RecordEnvironmentId"
                                label={t("record_management.record_environment")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("record_management.record_environment_required"),
                                    },
                                ]}
                            >
                                <EnhancedSelect
                                    showSearch optionFilterProp="children"
                                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}>
                                    {
                                        recordEnvironments.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                    }
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="LocationName"
                                label={t("record_management.fishing_location")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("record_management.fishing_location_required"),
                                    },
                                ]}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="ClubEntityId"
                                label={t("fishing_activity.club")}
                                hidden={isClub()}
                            >
                                <EnhancedSelect allowClear>
                                    {
                                        entities.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                    }
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="RecordCategoryId"
                                label={t("record_management.record_category")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("record_management.record_category_required"),
                                    },
                                ]}
                            >
                                <EnhancedSelect
                                    showSearch optionFilterProp="children"
                                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}>
                                    {
                                        recordCategories.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                    }
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="LineClassId"
                                label={t("fishing_activity.line_class")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("record_management.line_class_required"),
                                    },
                                ]}
                            >
                                <EnhancedSelect
                                    showSearch optionFilterProp="children"
                                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}>
                                    {
                                        lineClasses.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                    }
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="FishWeight"
                                label={t("fishing_activity.weight")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("record_management.fish_weight_required"),
                                    },
                                ]}
                            >
                                <InputNumber precision={2} min={0} addonAfter="kg" allowClear/>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                label={t("record_management.record_date")}
                                rules={[{ required: true, message: t("record_management.record_date_required") }]}
                                name="RecordDate" colon={false} labelAlign="left">
                                <DatePicker format={Constant.DateFormat} allowClear style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="FirstName"
                                label={t("member.first_name")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("record_management.first_name_required"),
                                    },
                                ]}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="Surname"
                                label={t("member.surname")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("record_management.surname_required"),
                                    },
                                ]}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="FlLjfl"
                                label={t("record_management.fl_ljfl")}
                            >
                                <InputNumber precision={0} min={0} addonAfter="mm" allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="TotalLength"
                                label={t("record_management.total_length")}
                            >
                                <InputNumber precision={0} min={0} addonAfter="mm" allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="Girth"
                                label={t("record_management.girth")}
                            >
                                <InputNumber precision={0} min={0} addonAfter="mm" allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="RodMakeModel"
                                label={t("record_management.rod_make_model")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="ReelMakeModel"
                                label={t("record_management.reel_make_model")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="LineMake"
                                label={t("record_management.line_make")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="BoatName"
                                label={t("record_management.boat_name")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="NameBaitLureFly"
                                label={t("record_management.name_bait_lure_fly")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                label={t('record_management.image')}
                                colon={false} labelAlign="left"
                                className='upload-item'
                            >
                                <Upload
                                    className="avatar-uploader"
                                    listType="picture-card"
                                    showUploadList={false}
                                    beforeUpload={beforeUploadPhoto}
                                    accept="image/*"
                                >
                                    {
                                        getImageLogo() ?
                                            <img alt={t('record_management.record_image')} className="record-image" src={getImageLogo()} />
                                            : <img alt={t('record_management.record_image')} className="record-image" src={AddImage} />
                                    }
                                </Upload>
                                {getImageLogo() &&
                                    <span className="remove-image">
                                        <Button type="primary" shape="circle" icon={<DeleteOutlined />} onClick={removeLogo} size="middle" />
                                    </span>
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            </Modal>
        </div>
    )

}

export default AddUpdateRecord
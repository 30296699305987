import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import { getInitialProps, useTranslation } from 'react-i18next';
import {
    Tooltip, Button, Badge, Form, DatePicker, Input,
    Empty, InputNumber, Checkbox, Select, Modal,
    Col, Row, Popover, TimePicker, Upload
} from 'antd';
import './customTable.scss';
import { Constant, CommonService, StorageService } from './../../services/services';
import dayjs from 'dayjs';
import CustomInputMask from '../CustomerInputMask/CustomInputMask';
import TagNumber from '../tagNumber/tagNumber';
import CustomTimePicker from '../customTimePicker/customTimePicker';
import EnhancedSelect from '../enhancedSelect/enhancedSelect';

const { Option } = Select;

export const ActionButtonsCell = (props) => {
    return (
        <div className={`flex-items-center ${props?.justifyContentStart ? "align-item-start" : ""}`} >
            {
                props?.btnList.map((item, i) => {
                    return (
                        <Tooltip key={i} placement="bottom" title={item.Description}>
                            <span className="btn-container">
                                {
                                    item.Type && item.Type == Constant.ButtonType.Link &&
                                    <Button icon={item.Icon} className={`m-l-5 m-r-5 action-btn action-btn${item.ColorClass ? item.ColorClass : ""} ${item.Hide ? "hidden" : ""} ${item.NoMargin ? "m-0" : ""}`}
                                        disabled={props.disabled} onClick={() => props.handleAction ? props.handleAction(props.data) : (item.handleAction ? item.handleAction(props.data) : (() => { }))}
                                        type='link'
                                        target="_blank"
                                        href={`${item.Link ? item.Link : ""}`}
                                    />
                                }
                                {
                                    (!item.Type || item.Type != Constant.ButtonType.Link) &&
                                    <Button icon={item.Icon} className={`m-l-5 m-r-5 action-btn action-btn${item.ColorClass ? item.ColorClass : ""} ${item.Hide ? "hidden" : ""} ${item.NoMargin ? "m-0" : ""}`}
                                        disabled={props.disabled} onClick={() => props.handleAction ? props.handleAction(props.data) : (item.handleAction ? item.handleAction(props.data) : (() => { }))}
                                    />
                                }

                            </span>
                        </Tooltip>
                    )
                })
            }
        </div>
    );
};

export const StatusCell = (props) => {
    return (
        <Tooltip placement="bottom" title={props.enableTruncate ? props.description : ''}>
            <span className={`${props.enableTruncate ? 'status-truncate truncate' : ''} status-tag status-tag${props.colorClass} ${props.hide ? "hidden" : ""}`}>
                {props.description}
            </span>
        </Tooltip>

    );
};

export const EntityCell = (props) => {
    return (
        <span>
            {props.data.EntityId + ' - ' + props.data.EntityName}
        </span>
    );
};


export const TextAndButtonCell = (props) => {
    const onClick = () => {
        if (props.onClick)
            props.onClick(props.data)
    }

    const onClear = () => {
        if (props.onClear)
            props.onClear(props.data)
    }

    return (
        <div className="text-button-cell flex-items align-space-between">
            <span title={props.value} className={`truncate-flex-item ${props.includeWhiteSpaces ? "included-spaces" : ""}`}>{props.value}</span>

            {
                props.allowClear && props.value &&
                <span onClick={onClear} class="text-button-cell-clear m-r-5" unselectable="on" aria-hidden="true" style={{ userSelect: 'none' }}><span role="img" aria-label="close-circle" class="anticon anticon-close-circle"><svg fill-rule="evenodd" viewBox="64 64 896 896" focusable="false" data-icon="close-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64c247.4 0 448 200.6 448 448S759.4 960 512 960 64 759.4 64 512 264.6 64 512 64zm127.98 274.82h-.04l-.08.06L512 466.75 384.14 338.88c-.04-.05-.06-.06-.08-.06a.12.12 0 00-.07 0c-.03 0-.05.01-.09.05l-45.02 45.02a.2.2 0 00-.05.09.12.12 0 000 .07v.02a.27.27 0 00.06.06L466.75 512 338.88 639.86c-.05.04-.06.06-.06.08a.12.12 0 000 .07c0 .03.01.05.05.09l45.02 45.02a.2.2 0 00.09.05.12.12 0 00.07 0c.02 0 .04-.01.08-.05L512 557.25l127.86 127.87c.04.04.06.05.08.05a.12.12 0 00.07 0c.03 0 .05-.01.09-.05l45.02-45.02a.2.2 0 00.05-.09.12.12 0 000-.07v-.02a.27.27 0 00-.05-.06L557.25 512l127.87-127.86c.04-.04.05-.06.05-.08a.12.12 0 000-.07c0-.03-.01-.05-.05-.09l-45.02-45.02a.2.2 0 00-.09-.05.12.12 0 00-.07 0z"></path></svg></span></span>
            }

            <Tooltip placement="bottom" title={props.tooltipText}>

                <span className="btn-container">
                    <Button icon={props.icon} className={`action-btn action-btn${props.colorClass}`}
                        onClick={onClick} disabled={props.disabled}
                    />
                </span>
            </Tooltip>
        </div>
    );
};

export const GetTableConfigs = (columns, dataSource, totalItems, rowKey, onChangeFn, currentPageNumber, customClass = "", showPagination = true) => {
    const { t } = useTranslation();
    let pageSize = null;
    var authData = StorageService.getAuthData()
    if (authData) {
        pageSize = Number(authData.pageSize)
    }
    return {
        className: `custom-table ${customClass}`,
        showSorterTooltip: false,
        scroll: { y: 300, x: 'max-content' },
        pagination: !showPagination || !pageSize ? false : {
            total: totalItems,
            size: "small",
            showTotal: (total) => t('common.total_items', { total: total }),
            current: currentPageNumber ? currentPageNumber : 1,
            defaultPageSize: pageSize ? pageSize : Constant.PageSize,
            showTitle: false,
            simple: false,
            showLessItems: true,
            showQuickJumper: false,
            showSizeChanger: false
        },
        locale: {
            emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_data")} />,
            filterConfirm: t('common.filter')
        },
        columns: columns,
        dataSource: dataSource,
        onChange: onChangeFn,
        rowKey: rowKey

    }
}

export const GetTableConfigs_SimplePaginator = (columns, dataSource, totalItems, rowKey, onChangeFn, currentPageNumber, customClass = "", showPagination = true) => {
    const { t } = useTranslation();
    let pageSize = null;
    var authData = StorageService.getAuthData()
    if (authData) {
        pageSize = Number(authData.pageSize)
    }
    return {
        className: `custom-table ${customClass}`,
        showSorterTooltip: false,
        scroll: { y: 300 },
        pagination: !showPagination || !pageSize ? false : {
            total: totalItems,
            size: "small",
            current: currentPageNumber ? currentPageNumber : 1,
            defaultPageSize: pageSize ? pageSize : Constant.PageSize,
            showTitle: false,
            simple: false,
            showLessItems: true,
            showQuickJumper: false,
            showSizeChanger: false
        },
        locale: {
            emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_data")} />,
            filterConfirm: t('common.filter')
        },
        columns: columns,
        dataSource: dataSource,
        onChange: onChangeFn,
        rowKey: rowKey
    }
}

export const DateTimeCell = (props) => {
    return (
        <>
            {
                props.value &&
                <span className={`${props.className}`}>
                    {CommonService.getDateString(props.value)} {props.showTime ? CommonService.getTimeString(props.value) : ""}
                </span>
            }
            {
                !props.value && <span>{props.emptyText ? props.emptyText : ""}</span>
            }

        </>

    );
};

export const YesNoCell = (props) => {
    const { t } = useTranslation();
    return (
        <span className={`${props.className} status-tag ${props.value ? 'status-tag--light-green-color' : 'status-tag--light-red-color'} `}>
            {props.value ? t('common.yes') : t('common.no')}
        </span>
    );
};

export const FinancialEndCell = (props) => {
    const { t } = useTranslation();
    if (props.value) {
        if (new Date(props.value).getFullYear() === 9999) {
            return (
                <span className={`${props.className}`}>
                    {t('common.never_life_member')}
                </span>
            );
        }
    }

    return (
        <span className={`${props.className}`}>
            {CommonService.getDateString(props.value)} {props.showTime ? CommonService.getTimeString(props.value) : ""}
        </span>
    );
};


export const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    cellType,
    required,
    duplicated,
    extraData,
    handleOnChange,
    disableToggleInput,
    enableTabKeyEvent,
    errorTextPosition,
    selectionKey,
    selectionDisplay,
    validateAtStart,
    entityId,
    positionTypeId,
    disabled,
    allowClear = true,
    getFileList,
    beforeUploadFile,
    onRemoveFile,
    minValue,
    selectionSort,
    isOptions,
    precision,
    handleDisabledCell,
    searchLike,
    tooltipText,
    ...restProps
}) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [editing, setEditing] = useState(false);
    const numberInputRef = useRef(null);

    useEffect(() => {
        if (cellType === Constant.CellType.Selection ||
            cellType === Constant.CellType.Input ||
            cellType === Constant.CellType.NumberInput ||
            cellType === Constant.CellType.DecimalInput ||
            cellType === Constant.CellType.FamilyGroupSelection ||
            cellType === Constant.CellType.StatusSelection ||
            cellType === Constant.CellType.MemberDateOfBirth ||
            cellType === Constant.CellType.Checkbox ||
            cellType === Constant.CellType.InputPassword ||
            cellType === Constant.CellType.ClubSelection ||
            cellType === Constant.CellType.GPSLatitudeInput ||
            cellType === Constant.CellType.GPSLongitudeInput ||
            cellType === Constant.CellType.Time ||
            cellType === Constant.CellType.TagNumber ||
            cellType === Constant.CellType.Upload ||
            cellType === Constant.CellType.CustomTimePicker ||
            cellType === Constant.CellType.EnhancedSelect ||
            cellType === Constant.CellType.FishingMethodEnhancedSelect) {
            form.setFieldsValue(record)
        }

        if (validateAtStart) {
            form.validateFields()
        }

    }, children)

    useEffect(() => {
        if (cellType === Constant.CellType.Selection ||
            cellType === Constant.CellType.Input ||
            cellType === Constant.CellType.NumberInput ||
            cellType === Constant.CellType.DecimalInput ||
            cellType === Constant.CellType.FamilyGroupSelection ||
            cellType === Constant.CellType.StatusSelection ||
            cellType === Constant.CellType.MemberDateOfBirth ||
            cellType === Constant.CellType.Date ||
            cellType === Constant.CellType.InputPassword ||
            cellType === Constant.CellType.ClubSelection ||
            cellType === Constant.CellType.GPSLatitudeInput ||
            cellType === Constant.CellType.GPSLongitudeInput ||
            cellType === Constant.CellType.Time ||
            cellType === Constant.CellType.TagNumber ||
            cellType === Constant.CellType.Upload ||
            cellType === Constant.CellType.CustomTimePicker ||
            cellType === Constant.CellType.EnhancedSelect ||
            cellType === Constant.CellType.FishingMethodEnhancedSelect) {
            form.validateFields();
        }
    }, [record?.ErrorMessages])

    const getInitialDateValue = () => {
        if ((cellType === Constant.CellType.Date || cellType === Constant.CellType.MemberDateOfBirth) && record) {
            if (record[dataIndex]) {
                record[dataIndex] = dayjs(record[dataIndex], Constant.DateFormat)
            }
        }
        return record
    }

    const getInitialTimeValue = () => {
        if ((cellType === Constant.CellType.Time) && record) {
            if (record[dataIndex]) {
                record[dataIndex] = dayjs(record[dataIndex], Constant.TimeFormat)
            }
        }
        return record
    }

    const toggleEdit = (value) => {
        setEditing(value);
        setTimeout(() => {
            if (value && numberInputRef && numberInputRef.current) {
                numberInputRef.current.focus()
            }
        }, 200);
    };

    const toggleSave = (record, dataIndex) => {
        toggleEdit(false)
        if (handleOnChange) {
            let value = form.getFieldValue(dataIndex)
            handleOnChange(value, record, dataIndex)
        }
    };

    const getCellFormat = () => {
        if (cellType === Constant.CellType.Checkbox) {
            return (
                <Form form={form}>
                    <Form.Item
                        style={{
                            margin: 0,
                        }}
                        name={dataIndex}
                        valuePropName="checked"
                    >
                        <Checkbox onChange={e => handleOnChange ? handleOnChange(e.target.checked, record, dataIndex) : null}
                            disabled={disabled || (handleDisabledCell ? handleDisabledCell(record) : false)} />

                    </Form.Item>
                </Form>
            )
        }
        else if (cellType === Constant.CellType.Selection) {
            if (extraData) {
                return (
                    <Form form={form} initialValues={record}>
                        <Form.Item
                            style={{
                                margin: 0,
                            }}
                            name={dataIndex}
                            rules={[() => ({
                                validator(rule, value) {
                                    if (!required || value) {
                                        return Promise.resolve();
                                    }

                                    if (record.ErrorMessages != null && record.ErrorMessages.length > 0) {
                                        var errorMessage = record.ErrorMessages.find((e) => e.key === dataIndex);
                                        if (errorMessage != null && errorMessage.value) {
                                            return Promise.reject(errorMessage.value);
                                        }
                                    }
                                    return Promise.resolve();
                                },
                            })]}
                        >
                            <Select allowClear={allowClear} onChange={e => handleOnChange ? handleOnChange(e, record, dataIndex) : null} disabled={disabled}
                                showSearch optionFilterProp="children"
                                filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}
                            >
                                {
                                    extraData.map(n => <Option key={n[selectionKey] ? n[selectionKey] : n.Code} disabled={n.Disabled} value={n[selectionKey] ? n[selectionKey] : n.Code} className={`${n.Hidden ? "hidden" : ""}`}>{n[selectionDisplay] ? n[selectionDisplay] : n.Description}</Option>)
                                }
                            </Select>

                        </Form.Item>
                    </Form>
                )
            } else {
                return children
            }
        }
        else if (cellType === Constant.CellType.FamilyGroupSelection) {
            if (extraData) {
                var newExtra = extraData;
                if (record.EntityId > 0) {
                    newExtra = extraData.filter(x => x.EntityId === record.EntityId);
                }
                return (
                    <Form form={form} initialValues={record}>
                        <Form.Item
                            style={{
                                margin: 0,
                            }}
                            name={dataIndex}
                            rules={[() => ({
                                validator(rule, value) {
                                    if (!required || value) {
                                        return Promise.resolve();
                                    }

                                    if (record.ErrorMessages != null && record.ErrorMessages.length > 0) {
                                        var errorMessage = record.ErrorMessages.find((e) => e.key === dataIndex);
                                        if (errorMessage != null && errorMessage.value) {
                                            return Promise.reject(errorMessage.value);
                                        }
                                    }
                                    return Promise.resolve();
                                },
                            })]}
                        >
                            <EnhancedSelect showSearch optionFilterProp="label" allowClear={true}
                                maxTagCount='responsive'
                                filterOption={(input, option) => {
                                    if (searchLike) {
                                        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
                                    }
                                    return (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase());
                                }}
                                filterSort={(optionA, optionB) => {
                                    if (selectionSort === 'Id' || selectionSort === 'Code') {
                                        return optionA?.value == optionB?.value;
                                    } else if (selectionSort === 'SortOrder') {
                                        return optionA?.sort == optionB?.sort;
                                    }
                                    return (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase());
                                }}
                                options={isOptions ? newExtra : (newExtra ? newExtra.map(n => {
                                    return {
                                        label: n[selectionDisplay] ? n[selectionDisplay] : n.Description,
                                        value: n[selectionKey] ? n[selectionKey] : n.Code
                                    }
                                }) : [])}
                                onChange={e => handleOnChange ? handleOnChange(e, record, dataIndex) : null} disabled={disabled}
                            >
                            </EnhancedSelect>
                        </Form.Item>
                    </Form>
                )
            } else {
                return children
            }
        }
        else if (cellType === Constant.CellType.StatusSelection) {
            if (extraData) {
                return (
                    <Form form={form} initialValues={record}>
                        <Form.Item
                            style={{
                                margin: 0,
                            }}
                            name={dataIndex}
                            rules={[() => ({
                                validator(rule, value) {
                                    if (!required || value) {
                                        return Promise.resolve();
                                    }

                                    if (record.ErrorMessages != null && record.ErrorMessages.length > 0) {
                                        var errorMessage = record.ErrorMessages.find((e) => e.key === dataIndex);
                                        if (errorMessage != null && errorMessage.value) {
                                            return Promise.reject(errorMessage.value);
                                        }
                                    }
                                    return Promise.resolve();
                                },
                            })]}
                        >
                            <EnhancedSelect showSearch optionFilterProp="label" allowClear={true}
                                maxTagCount='responsive'
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                filterSort={(optionA, optionB) => {
                                    if (selectionSort === 'Id' || selectionSort === 'Code') {
                                        return optionA?.value == optionB?.value;
                                    } else if (selectionSort === 'SortOrder') {
                                        return optionA?.sort == optionB?.sort;
                                    }
                                    return (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase());
                                }}
                                options={isOptions ? extraData : (extraData ? extraData.map(n => {
                                    return {
                                        label: n[selectionDisplay] ? n[selectionDisplay] : n.Description,
                                        value: n[selectionKey] ? n[selectionKey] : n.Code
                                    }
                                }) : [])}
                                onChange={e => handleOnChange ? handleOnChange(e, record, dataIndex) : null} disabled={record.IsAddNew}
                            >
                            </EnhancedSelect>
                        </Form.Item>
                    </Form>
                )
            } else {
                return children
            }
        }
        else if (cellType === Constant.CellType.Input) {
            return (
                <Form form={form} initialValues={record} >
                    <Form.Item
                        style={{
                            margin: 0,
                        }}
                        name={dataIndex}
                        rules={[() => ({
                            validator(rule, value) {
                                if (!required || (!duplicated && value)) {
                                    return Promise.resolve();
                                }

                                if (record.ErrorMessages != null && record.ErrorMessages.length > 0) {
                                    var errorMessage = record.ErrorMessages.find((e) => e.key === dataIndex);
                                    if (errorMessage != null && errorMessage.value) {
                                        return Promise.reject(errorMessage.value);
                                    }
                                }
                                return Promise.resolve();
                            },
                        })]}
                    >
                        <Input onChange={e => handleOnChange ? handleOnChange(e.target.value, record, dataIndex) : null} allowClear
                            disabled={disabled || (handleDisabledCell ? handleDisabledCell(record) : false)} />
                    </Form.Item>
                </Form>
            )
        }
        else if (cellType === Constant.CellType.NumberInput) {
            return (
                <Form form={form} initialValues={record}>
                    <Form.Item style={{ margin: 0 }}
                        name={dataIndex}
                        rules={[() => ({
                            validator(rule, value) {
                                if (!required || value) {
                                    return Promise.resolve();
                                }

                                if (record.ErrorMessages != null && record.ErrorMessages.length > 0) {
                                    var errorMessage = record.ErrorMessages.find((e) => e.key === dataIndex);
                                    if (errorMessage != null && errorMessage.value) {
                                        return Promise.reject(errorMessage.value);
                                    }
                                }
                                return Promise.resolve();
                            },
                        })]}
                    >
                        <InputNumber min={minValue ?? minValue} onChange={e => handleOnChange ? handleOnChange(e, record, dataIndex) : null}
                            disabled={disabled || (handleDisabledCell ? handleDisabledCell(record) : false)} />
                    </Form.Item>
                </Form>
            )
        }
        else if (cellType === Constant.CellType.DecimalInput) {
            return (
                <Form form={form} initialValues={record}>
                    <Form.Item style={{ margin: 0 }}
                        name={dataIndex}
                        rules={[() => ({
                            validator(rule, value) {
                                if (!required || value) {
                                    return Promise.resolve();
                                }

                                if (record.ErrorMessages != null && record.ErrorMessages.length > 0) {
                                    var errorMessage = record.ErrorMessages.find((e) => e.key === dataIndex);
                                    if (errorMessage != null && errorMessage.value) {
                                        return Promise.reject(errorMessage.value);
                                    }
                                }
                                return Promise.resolve();
                            },
                        })]}
                    >
                        <InputNumber precision={precision >= 0 ? precision : 2} min={0} onChange={e => handleOnChange ? handleOnChange(e, record, dataIndex) : null}
                            disabled={disabled || (handleDisabledCell ? handleDisabledCell(record) : false)} />
                    </Form.Item>
                </Form>
            )
        }
        else if (cellType === Constant.CellType.EmailInput) {
            return (
                <Form form={form} initialValues={record} >
                    <Form.Item
                        style={{
                            margin: 0,
                        }}
                        name={dataIndex}
                        rules={[() => ({
                            validator(rule, value) {
                                if (!required || (!duplicated && value)) {
                                    return Promise.resolve();
                                }

                                if (record.ErrorMessages != null && record.ErrorMessages.length > 0) {
                                    var errorMessage = record.ErrorMessages.find((e) => e.key === dataIndex);
                                    if (errorMessage != null && errorMessage.value) {
                                        return Promise.reject(errorMessage.value);
                                    }
                                }
                                return Promise.resolve();
                            },
                        }),
                        { type: "email", message: t("email_members.email_invalid") }]}
                    >
                        <Input onChange={e => handleOnChange ? handleOnChange(e.target.value, record, dataIndex) : null} allowClear
                            disabled={disabled || (handleDisabledCell ? handleDisabledCell(record) : false)} />
                    </Form.Item>
                </Form>
            )
        }
        else if (cellType === Constant.CellType.Date) {
            return (
                <Form form={form} initialValues={getInitialDateValue()}>
                    <Form.Item style={{ margin: 0 }} name={dataIndex}
                        rules={[() => ({
                            validator(rule, value) {
                                if (!required || value) {
                                    return Promise.resolve();
                                }

                                if (record.ErrorMessages != null && record.ErrorMessages.length > 0) {
                                    var errorMessage = record.ErrorMessages.find((e) => e.key === dataIndex);
                                    if (errorMessage != null && errorMessage.value) {
                                        return Promise.reject(errorMessage.value);
                                    }
                                }
                                return Promise.resolve();
                            },
                        })]}
                    >
                        <DatePicker format={Constant.DateFormat} onChange={e => handleOnChange ? handleOnChange(e, record, dataIndex) : null} allowClear disabled={disabled} />
                    </Form.Item>
                </Form>
            )
        }
        else if (cellType === Constant.CellType.MemberDateOfBirth) {
            var invalid = false;
            if (record.ErrorMessages != null && record.ErrorMessages.length > 0) {
                invalid = true;
            }
            return (
                <Form form={form} initialValues={getInitialDateValue()}>
                    <Form.Item style={{ margin: 0 }} name={dataIndex}
                        rules={[() => ({
                            validator(rule, value) {
                                if (!required || (!invalid && value)) {
                                    return Promise.resolve();
                                }

                                if (record.ErrorMessages != null && record.ErrorMessages.length > 0) {
                                    var errorMessage = record.ErrorMessages.find((e) => e.key === dataIndex);
                                    if (errorMessage != null && errorMessage.value) {
                                        return Promise.reject(errorMessage.value);
                                    }
                                }
                                return Promise.resolve();
                            },
                        })]}
                    >
                        <DatePicker format={Constant.DateFormat} onChange={e => handleOnChange ? handleOnChange(e, record, dataIndex) : null} allowClear disabled={disabled} />
                    </Form.Item>
                </Form>
            )
        }
        else if (cellType === Constant.CellType.InputPassword) {
            return (
                <Form form={form} initialValues={record} >
                    <Form.Item
                        style={{
                            margin: 0,
                        }}
                        name={dataIndex}
                        rules={[() => ({
                            validator(rule, value) {
                                if (!required || (!duplicated && value)) {
                                    return Promise.resolve();
                                }

                                if (record.ErrorMessages != null && record.ErrorMessages.length > 0) {
                                    var errorMessage = record.ErrorMessages.find((e) => e.key === dataIndex);
                                    if (errorMessage != null && errorMessage.value) {
                                        return Promise.reject(errorMessage.value);
                                    }
                                }
                                return Promise.resolve();
                            },
                        })]}
                    >
                        <Input.Password onChange={e => handleOnChange ? handleOnChange(e.target.value, record, dataIndex) : null} allowClear disabled={disabled} />
                    </Form.Item>
                </Form>
            )
        }
        else if (cellType === Constant.CellType.ClubSelection) {
            if (extraData) {
                var newExtra = extraData;
                if (record.IsAddNew) {
                    newExtra = extraData.filter(x => x.EntityTypeId === Constant.EntityType.Club);
                }

                return (
                    <Form form={form} initialValues={record}>
                        <Form.Item
                            style={{
                                margin: 0,
                            }}
                            name={dataIndex}
                            rules={[() => ({
                                validator(rule, value) {
                                    if (!required || value) {
                                        return Promise.resolve();
                                    }

                                    if (record.ErrorMessages != null && record.ErrorMessages.length > 0) {
                                        var errorMessage = record.ErrorMessages.find((e) => e.key === dataIndex);
                                        if (errorMessage != null && errorMessage.value) {
                                            return Promise.reject(errorMessage.value);
                                        }
                                    }
                                    return Promise.resolve();
                                },
                            })]}
                        >
                            <EnhancedSelect showSearch optionFilterProp="label" allowClear={true}
                                maxTagCount='responsive'
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                filterSort={(optionA, optionB) => {
                                    if (selectionSort === 'Id' || selectionSort === 'Code') {
                                        return optionA?.value == optionB?.value;
                                    } else if (selectionSort === 'SortOrder') {
                                        return optionA?.sort == optionB?.sort;
                                    }
                                    return (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase());
                                }}
                                options={isOptions ? extraData : (extraData ? extraData.map(n => {
                                    return {
                                        label: n[selectionDisplay] ? n[selectionDisplay] : n.Description,
                                        value: n[selectionKey] ? n[selectionKey] : n.Code
                                    }
                                }) : [])}
                                onChange={e => handleOnChange ? handleOnChange(e, record, dataIndex) : null} defaultValue={record.EntityId} disabled={!record.IsAddNew}
                            >
                            </EnhancedSelect>
                        </Form.Item>
                    </Form>
                )
            } else {
                return children
            }
        }
        else if (cellType === Constant.CellType.GPSLatitudeInput) {
            return (
                <Form form={form} initialValues={record} >
                    <Form.Item
                        style={{
                            margin: 0,
                        }}
                        name={dataIndex}
                        rules={[() => ({
                            validator(rule, value) {
                                if (!required || (!duplicated && value && value.replace(/[^0-9]/g, '').length == 7)) {
                                    return Promise.resolve();
                                }

                                if (record.ErrorMessages != null && record.ErrorMessages.length > 0) {
                                    var errorMessage = record.ErrorMessages.find((e) => e.key === dataIndex);
                                    if (errorMessage != null && errorMessage.value) {
                                        return Promise.reject(errorMessage.value);
                                    }
                                }
                                return Promise.resolve();
                            },
                        })]}
                    >
                        <CustomInputMask defaultValue="__°__.000'" mask={"99°99.999'"} placeholder="Enter Latitude" onChange={e => handleOnChange ? handleOnChange(e.target.value, record, dataIndex) : null} allowClear
                            disabled={disabled || (handleDisabledCell ? handleDisabledCell(record) : false)} />
                    </Form.Item>
                </Form>
            )
        }
        else if (cellType === Constant.CellType.GPSLongitudeInput) {
            return (
                <Form form={form} initialValues={record} >
                    <Form.Item
                        style={{
                            margin: 0,
                        }}
                        name={dataIndex}
                        rules={[() => ({
                            validator(rule, value) {
                                if (!required || (!duplicated && value && value.replace(/[^0-9]/g, '').length == 8)) {
                                    return Promise.resolve();
                                }

                                if (record.ErrorMessages != null && record.ErrorMessages.length > 0) {
                                    var errorMessage = record.ErrorMessages.find((e) => e.key === dataIndex);
                                    if (errorMessage != null && errorMessage.value) {
                                        return Promise.reject(errorMessage.value);
                                    }
                                }
                                return Promise.resolve();
                            },
                        })]}
                    >
                        <CustomInputMask defaultValue="___°__.000'" mask={"999°99.999'"} onChange={e => handleOnChange ? handleOnChange(e.target.value, record, dataIndex) : null} allowClear
                            disabled={disabled || (handleDisabledCell ? handleDisabledCell(record) : false)} />
                    </Form.Item>
                </Form>
            )
        }
        else if (cellType === Constant.CellType.Time) {
            return (
                <Form form={form} initialValues={getInitialTimeValue()}>
                    <Form.Item style={{ margin: 0 }} name={dataIndex}
                        rules={[() => ({
                            validator(rule, value) {
                                if (!required || value) {
                                    return Promise.resolve();
                                }

                                if (record.ErrorMessages != null && record.ErrorMessages.length > 0) {
                                    var errorMessage = record.ErrorMessages.find((e) => e.key === dataIndex);
                                    if (errorMessage != null && errorMessage.value) {
                                        return Promise.reject(errorMessage.value);
                                    }
                                }
                                return Promise.resolve();
                            },
                        })]}
                    >
                        <TimePicker format={Constant.TimeFormat} onChange={e => handleOnChange ? handleOnChange(e, record, dataIndex) : null} allowClear placeholder="" disabled={disabled} />
                    </Form.Item>
                </Form>
            )
        }
        else if (cellType === Constant.CellType.TagNumber) {
            return (
                <Form form={form} initialValues={record} >
                    <Form.Item
                        style={{
                            margin: 0,
                        }}
                        name={dataIndex}
                        rules={[() => ({
                            validator(rule, value) {
                                if (!required || value) {
                                    return Promise.resolve();
                                }

                                if (record.ErrorMessages != null && record.ErrorMessages.length > 0) {
                                    var errorMessage = record.ErrorMessages.find((e) => e.key === dataIndex);
                                    if (errorMessage != null && errorMessage.value) {
                                        return Promise.reject(errorMessage.value);
                                    }
                                }
                                return Promise.resolve();
                            },
                        })]}
                    >
                        <TagNumber mask={"a999999"} onChange={e => handleOnChange ? handleOnChange(e.target.value, record, dataIndex) : null} allowClear
                            disabled={disabled || (handleDisabledCell ? handleDisabledCell(record) : false)} />
                    </Form.Item>
                </Form>
            )
        }
        else if (cellType === Constant.CellType.Upload) {
            return (
                <Form form={form} initialValues={record} >
                    <Form.Item
                        style={{
                            margin: 0,
                        }}
                        name={dataIndex}
                        rules={[() => ({
                            validator(rule, value) {
                                if (!required || value) {
                                    return Promise.resolve();
                                }

                                if (record.ErrorMessages != null && record.ErrorMessages.length > 0) {
                                    var errorMessage = record.ErrorMessages.find((e) => e.key === dataIndex);
                                    if (errorMessage != null && errorMessage.value) {
                                        return Promise.reject(errorMessage.value);
                                    }
                                }
                                return Promise.resolve();
                            },
                        })]}
                    >
                        <Upload
                            listType="text"
                            showUploadList={true}
                            onRemove={file => onRemoveFile(file, record, dataIndex)}
                            beforeUpload={file => beforeUploadFile(file, record, dataIndex)}
                            fileList={getFileList(record)}
                            maxCount={1}
                        >
                            <Button size="small" className='btn-solid-dark-blue'>{t("common.choose_file")}</Button>
                        </Upload>
                    </Form.Item>
                </Form>
            )
        } else if (cellType === Constant.CellType.CustomTimePicker) {
            return (
                <Form form={form} initialValues={record} >
                    <Form.Item
                        style={{
                            margin: 0,
                        }}
                        name={dataIndex}
                        rules={[() => ({
                            validator(rule, value) {
                                if (!required || value) {
                                    return Promise.resolve();
                                }

                                if (record.ErrorMessages != null && record.ErrorMessages.length > 0) {
                                    var errorMessage = record.ErrorMessages.find((e) => e.key === dataIndex);
                                    if (errorMessage != null && errorMessage.value) {
                                        return Promise.reject(errorMessage.value);
                                    }
                                }
                                return Promise.resolve();
                            },
                        })]}
                    >

                        <CustomTimePicker onChange={e => handleOnChange ? handleOnChange(e.target.value, record, dataIndex) : null} allowClear
                            disabled={disabled || (handleDisabledCell ? handleDisabledCell(record) : false)} />
                    </Form.Item>
                </Form>
            )
        } else if (cellType === Constant.CellType.EnhancedSelect) {
            if (extraData) {
                return (
                    <Form form={form} initialValues={record}>
                        <Form.Item
                            style={{
                                margin: 0,
                            }}
                            name={dataIndex}
                            rules={[() => ({
                                validator(rule, value) {
                                    if (!required || value) {
                                        return Promise.resolve();
                                    }

                                    if (record.ErrorMessages != null && record.ErrorMessages.length > 0) {
                                        var errorMessage = record.ErrorMessages.find((e) => e.key === dataIndex);
                                        if (errorMessage != null && errorMessage.value) {
                                            return Promise.reject(errorMessage.value);
                                        }
                                    }
                                    return Promise.resolve();
                                },
                            })]}
                        >
                            <EnhancedSelect showSearch optionFilterProp="label" allowClear={allowClear}
                                maxTagCount='responsive'
                                filterOption={(input, option) => {
                                    if (searchLike) {
                                        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
                                    }
                                    return (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase());
                                }}
                                filterSort={(optionA, optionB) => {
                                    if (selectionSort === 'Id' || selectionSort === 'Code') {
                                        return optionA?.value == optionB?.value;
                                    } else if (selectionSort === 'SortOrder') {
                                        return optionA?.sort == optionB?.sort;
                                    }
                                    return (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase());
                                }}
                                options={isOptions ? extraData : (extraData ? extraData.map(n => {
                                    return {
                                        label: n[selectionDisplay] ? n[selectionDisplay] : n.Description,
                                        value: n[selectionKey] ? n[selectionKey] : n.Code,
                                        sort: n[selectionSort] ? n[selectionSort] : n.SortOrder,
                                    }
                                }) : [])}
                                onChange={e => handleOnChange ? handleOnChange(e, record, dataIndex) : null} disabled={disabled || (handleDisabledCell ? handleDisabledCell(record) : false)}
                            >
                            </EnhancedSelect>
                        </Form.Item>
                    </Form>
                )
            } else {
                return children
            }
        } else if (cellType === Constant.CellType.FishingMethodEnhancedSelect) {
            if (extraData) {
                return (
                    <Form form={form} initialValues={record}>
                        <Form.Item
                            style={{
                                margin: 0,
                            }}
                            name={dataIndex}
                            rules={[() => ({
                                validator(rule, value) {
                                    if (!required || value) {
                                        return Promise.resolve();
                                    }

                                    if (record.ErrorMessages != null && record.ErrorMessages.length > 0) {
                                        var errorMessage = record.ErrorMessages.find((e) => e.key === dataIndex);
                                        if (errorMessage != null && errorMessage.value) {
                                            return Promise.reject(errorMessage.value);
                                        }
                                    }
                                    return Promise.resolve();
                                },
                            })]}
                        >
                            <EnhancedSelect showSearch optionLabelProp="label" allowClear={true}
                                maxTagCount='responsive'
                                filterOption={(input, option) => {
                                    if (searchLike) {
                                        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
                                    }
                                    return (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase());
                                }}
                                filterSort={(optionA, optionB) => {
                                    if (selectionSort === 'Id' || selectionSort === 'Code') {
                                        return optionA?.value == optionB?.value;
                                    } else if (selectionSort === 'SortOrder') {
                                        return optionA?.sort == optionB?.sort;
                                    }
                                    return (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase());
                                }}
                                onChange={e => handleOnChange ? handleOnChange(e, record, dataIndex) : null}
                                disabled={disabled || (handleDisabledCell ? handleDisabledCell(record) : false)}
                            >
                                {extraData ? extraData.map(n => {
                                    return (<>
                                        <Option key={n[selectionKey] ? n[selectionKey] : n.Id} value={n[selectionKey] ? n[selectionKey] : n.Id} label={n[selectionDisplay] ? n[selectionDisplay] : n.Code}>
                                            {n.DisplayName}
                                        </Option>
                                    </>);
                                }) : []}
                            </EnhancedSelect>
                        </Form.Item>
                    </Form>
                )
            } else {
                return children
            }
        } else if (cellType === Constant.CellType.ToggleNumberInput) {
            if (!editing) {
                return (
                    <Tooltip title={tooltipText}>
                        <div style={{ cursor: 'pointer' }} onClick={() => toggleEdit(true)}>
                            {record[dataIndex]}
                        </div>
                    </Tooltip>
                )
            } else {
                return (
                    <Form form={form} initialValues={record}>
                        <Form.Item style={{ margin: 0 }}
                            name={dataIndex}
                            rules={[() => ({
                                validator(rule, value) {
                                    if (!required || value) {
                                        return Promise.resolve();
                                    }

                                    if (record.ErrorMessages != null && record.ErrorMessages.length > 0) {
                                        var errorMessage = record.ErrorMessages.find((e) => e.key === dataIndex);
                                        if (errorMessage != null && errorMessage.value) {
                                            return Promise.reject(errorMessage.value);
                                        }
                                    }
                                    return Promise.resolve();
                                },
                            })]}
                        >
                            <InputNumber ref={numberInputRef} min={minValue ?? minValue}
                                onBlur={e => toggleSave(record, dataIndex)}
                                disabled={disabled || (handleDisabledCell ? handleDisabledCell(record) : false)} />
                        </Form.Item>
                    </Form>
                )
            }
        }
    }

    let childNode = children;

    if (editable) {
        childNode = getCellFormat()

    }

    return <td {...restProps}>
        {/* <Popover overlayStyle={{ zIndex: 2 }} disabled={!isShowPopover} placement={errorTextPosition}
            content={<p style={{ color: "#ff4d4f" }}>{popoverMessage}</p>}
        >
            {childNode}
        </Popover> */}
        {childNode}

    </td>;
};

import { useRef, useState } from 'react';
import { Select } from 'antd';
const _ = require("lodash");

const getActiveOpitonContentElement = (rootElement) => {
    if (rootElement) {
        let activeOptionElement = rootElement.getElementsByClassName(
            'ant-select-item-option-active',
        )?.[0];
        if (activeOptionElement) {
            let optionContentElement = activeOptionElement.getElementsByClassName(
                'ant-select-item-option-content',
            )?.[0];
            return optionContentElement;
        }
    }
    return void 0;
};

const getSelectedOpitonContentElement = (rootElement) => {
    if (rootElement) {
        let selectedOptionElements = rootElement.getElementsByClassName(
            'ant-select-item-option-selected',
        );
        if (selectedOptionElements && selectedOptionElements.length > 0) {
            let selectedContentElements = []
            for (let i = 0; i < selectedOptionElements.length; i++) {
                let selectedContent = selectedOptionElements[i].getElementsByClassName(
                    'ant-select-item-option-content',
                )?.[0];

                if (selectedContent) {
                    selectedContentElements.push(selectedContent)
                }
            }
            return selectedContentElements;
        }
    }
    return void 0;
};

const EnhancedSelect = (props) => {
    const {
        options,
        optionFilterProp,
        dropdownRender,
        onBlur,
        onChange,
        ...restProps
    } = props;

    const dropdownWrapperElementRef = useRef();
    const selectRef = useRef();
    const [selectedOptions, setSelectedOptions] = useState([])

    const clearActive = () => {
        setTimeout(() => {
            if (!dropdownWrapperElementRef || !dropdownWrapperElementRef.current) return;

            let activeElements = dropdownWrapperElementRef.current.getElementsByClassName(
                'ant-select-item-option-active',
            )

            if (activeElements && activeElements.length > 0) {
                for (let i = 0; i < activeElements.length; i++) {
                    if (activeElements[i].classList) {
                        activeElements[i].classList.remove("ant-select-item-option-active")
                    }
                }
            }
        }, 100);
    }

    const enhancedOnClear = () => {
        clearActive()
        if (props.onClear) {
            props.onClear()
        }
    }

    const enhancedOnChanged = (value) => {
        if (Array.isArray(value)) {
            setSelectedOptions(value)
        } else if (value) {
            setSelectedOptions([value])
        } else {
            setSelectedOptions([])
        }

        if (onChange) {
            onChange(value)
        }
    }

    const onInputKeyDown = (e) => {
        if (e.code == "Tab") {
            let allOptions = options
            if (!allOptions || allOptions.length == 0) {
                if (Array.isArray(props.children)) {
                    allOptions = props.children.filter(o => o.props).map(o => o.props)
                }
            }

            let activeOptionContentElement = getActiveOpitonContentElement(
                dropdownWrapperElementRef.current,
            );
            if (
                activeOptionContentElement &&
                Array.isArray(allOptions) &&
                allOptions.length > 0
            ) {
                let subActiveOption = null;
                let activeOption = allOptions.find((el) => {
                    if (el.options && el.options.length > 0) {
                        return el.options.some((subEl) => {
                            if (subEl[optionFilterProp ?? 'children']?.trim() === activeOptionContentElement.innerText?.trim()) {
                                subActiveOption = subEl;
                            }
                        });
                    } 
                    else if (typeof (el[optionFilterProp ?? 'children']) === 'object' && el[optionFilterProp ?? 'children'].props) {
                        if (el[optionFilterProp ?? 'children'].props[optionFilterProp ?? 'children']?.toString().trim() === activeOptionContentElement.innerText?.trim()) {
                            subActiveOption = el[optionFilterProp ?? 'children'].props;
                            return el;
                        }
                    }

                    return (
                        el[optionFilterProp ?? 'children']?.toString().trim() ===
                        activeOptionContentElement.innerText?.trim()
                    );
                });

                if (subActiveOption) {
                    activeOption = subActiveOption;
                }
                if (activeOption) {
                    //let selectedElements = getSelectedOpitonContentElement(dropdownWrapperElementRef.current)
                    if (props.mode == "multiple") {
                        // let selectedElementValues = selectedElements.filter(e => e.innerText).map(e => e.innerText.trim())
                        // let selectedOptions = allOptions.filter((el) => selectedElementValues.indexOf(el[optionFilterProp ?? 'children']?.trim()) >= 0)
                        let selectedValues = selectedOptions.map(e => e)
                        if (selectedOptions.indexOf(activeOption.value) < 0) {
                            selectedValues.push(activeOption.value)
                            enhancedOnChanged(selectedValues);
                        }
                    } else {
                        enhancedOnChanged(activeOption.value);
                    }
                }
            }
        }
    }

    return (
        <Select
            ref={selectRef}
            {...restProps}
            onChange={enhancedOnChanged}
            optionFilterProp={optionFilterProp}
            options={options}
            onClear={enhancedOnClear}
            dropdownRender={(originNode) => {
                if (_.isFunction(dropdownRender)) {
                    return (
                        <div ref={dropdownWrapperElementRef}>
                            {dropdownRender(originNode)}
                        </div>
                    )
                }
                return (
                    <div ref={dropdownWrapperElementRef}>
                        {originNode}
                    </div>
                );
            }}
            onInputKeyDown={onInputKeyDown}
        />
    )
}

export default EnhancedSelect
import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import './tagNumber.scss';

const TagNumber = ({ value, onChange, mask, defaultValue, disabled }) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleChange = (e) => {
        const newValue = e.target.value;
        const lastThreeCharacters = newValue.slice(-6);
        const replacedValue = newValue.slice(0, -6) + lastThreeCharacters.replace(/_/g, '_');
        onChange({ target: { value: replacedValue.toUpperCase() } });
    };

    return (
        <InputMask
            value={value || defaultValue}
            mask={mask}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={'custom-mask-input'}
            disabled={disabled}
            style={{
                borderColor: isFocused ? '#2986ff' : '#d9d9d9',
            }}
        />
    );
};

export default TagNumber;
import React, { useEffect, useState } from 'react';
import './activityEntriesManagement.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
    Constant,
    CommonService,
    FishingActivityService
} from '../../../services/services'
import { Button, Row, Col, Form, Tabs, Modal, InputNumber , Input, Select } from 'antd';
import TagNumber from '../../../components/tagNumber/tagNumber';
import dayjs from 'dayjs';
import LoadingContext from '../../../components/loading/loadingContext'
import EnhancedSelect from '../../../components/enhancedSelect/enhancedSelect';

const { Option } = Select;
const { confirm } = Modal;
const { TabPane } = Tabs;
const _ = require("lodash");
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};


const EditActivityEntry = (props) => {
    const { t } = useTranslation();
    const [activityEntryForm] = Form.useForm();

    const { showLoading, dismissLoading } = React.useContext(LoadingContext)

    const [originalActivityLine, setOriginalActivityLine] = useState({});
    const [activityLine, setActivityLine] = useState({});
    const [fishSpecies, setFishSpecies] = useState([]);
    const [approvalStatuses, setApprovalStatuses] = useState([]);
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [refreshData, setRefreshData] = useState(false);

    const onSubmit = () => {
        // Validate
        if (!activityLine.FishSpeciesId) {
            return;
        }

        // Add/Update
        showLoading();
        FishingActivityService.addUpdateActivityLines([activityLine])
            .finally(() => {
                dismissLoading();
            })
            .then(result => {
                if (result.data) {
                    CommonService.presentToast('success', t('fishing_activity.activity_lines_update_successful') );
                    props.handleCancel(true);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const onClose = () => {
        submitConfirmation();
    }

    const submitConfirmation = () => {
        function customizer(newActivityLine, oldActivityLine) {

            if (((newActivityLine.TagNumber !== null || oldActivityLine.TagNumber !== null) && newActivityLine.Name !== oldActivityLine.Name) ||
                ((newActivityLine.FishWeight !== null || oldActivityLine.FishWeight !== null) && newActivityLine.FishWeight !== oldActivityLine.FishWeight) ||
                ((newActivityLine.LineClassId !== null || oldActivityLine.LineClassId !== null) && newActivityLine.LineClassId !== oldActivityLine.LineClassId) ||
                ((newActivityLine.ClubPoints !== null || oldActivityLine.ClubPoints !== null) && newActivityLine.ClubPoints !== oldActivityLine.ClubPoints) ||
                ((newActivityLine.ClubApprovalId !== null || oldActivityLine.ClubApprovalId !== null) && newActivityLine.ClubApprovalId !== oldActivityLine.ClubApprovalId) ||
                ((newActivityLine.StatePoints !== null || oldActivityLine.StatePoints !== null) && newActivityLine.StatePoints !== oldActivityLine.StatePoints) ||
                ((newActivityLine.StateApprovalId !== null || oldActivityLine.StateApprovalId !== null) && newActivityLine.StateApprovalId !== oldActivityLine.StateApprovalId) ||
                ((newActivityLine.FishSpeciesId !== null || oldActivityLine.FishSpeciesId !== null) && newActivityLine.FishSpeciesId !== oldActivityLine.FishSpeciesId)
                ) {
                return true;
                }
            return false;
        }
        
        var newActivityLine = activityLine;
        var oldActivityLine = originalActivityLine;
        var changed = _.isEqualWith(newActivityLine, oldActivityLine, customizer);
        if (changed) {
            confirm({
                title: t("common.submit_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    onSubmit();
                },
                onCancel() {
                    props.handleCancel(true);
                }
            });
        }
        else {
            props.handleCancel(false);
        }
    }

    useEffect(() => {

        if (props.isEdit) {
            setActivityLine(props.selectedActivityLine);
            setFishSpecies(props.fishSpecies);
            setApprovalStatuses(props.approvalStatuses);
            setOriginalActivityLine(_.cloneDeep(props.selectedActivityLine));
            activityEntryForm.setFieldsValue(props.selectedActivityLine);
            activityEntryForm.setFieldValue('TagNumber', props.selectedActivityLine.TagNumber);
            activityEntryForm.setFieldValue('FishWeight', props.selectedActivityLine.FishWeight);
            activityEntryForm.setFieldValue('FishSpeciesId', props.selectedActivityLine.FishSpeciesId);
            activityEntryForm.setFieldValue('LineClassId', props.selectedActivityLine.LineClassId);
            activityEntryForm.setFieldValue('ClubPoints', props.selectedActivityLine.ClubPoints);
            activityEntryForm.setFieldValue('ClubApprovalId', props.selectedActivityLine.ClubApprovalId);
            activityEntryForm.setFieldValue('StatePoints', props.selectedActivityLine.StatePoints);
            activityEntryForm.setFieldValue('StateApprovalId', props.selectedActivityLine.StateApprovalId);
            setEnableSubmit(true);
        }

    }, [])

    const handlePointScores = (record) => {
        var dateEntered = "";
        if (record.DateEntered) {
            if (typeof record.DateEntered === 'string' || record.DateEntered instanceof String) {
                var length = record.DateEntered.split('/').length;
                if (length >= 3) {
                    dateEntered = dayjs(record.DateEntered, Constant.DateFormatYYYMMDD);
                }
            } else {
                dateEntered = record.DateEntered.format(Constant.DateFormatYYYMMDD);
            }
        }

        if (!record.ClubApprovalId || !record.StateApprovalId) {
            FishingActivityService.getPointScores(record.AnglerId, record.LineClassId, record.ClubZoneId, record.StatePointScoreZoneId, record.FishSpeciesId, record.TagCaptureId, record.FishWeight, dateEntered)
            .then(result => {
                if (result.data) {

                    if (!record.ClubApprovalId) {
                        _.update(activityLine, 'ClubPoints', function (n) {
                            return result.data.ClubPoints;
                        });
    
                        _.update(activityLine, 'ClubCapturePoints', function (n) {
                            return result.data.ClubCapturePoints;
                        });
    
                        _.update(activityLine, 'ClubTagReleasePoints', function (n) {
                            return result.data.ClubTagReleasePoints;
                        });
    
                        _.update(activityLine, 'ClubMeasureReleasePoints', function (n) {
                            return result.data.ClubMeasureReleasePoints;
                        });
    
                        _.update(activityLine, 'ClubLeadReleasePoints', function (n) {
                            return result.data.ClubLeadReleasePoints;
                        });
                        activityEntryForm.setFieldValue('ClubPoints', result.data.ClubPoints);
                    }
                    
                    if (!record.StateApprovalId) {
                        _.update(activityLine, 'StatePoints', function (n) {
                            return result.data.StatePoints;
                        });

                        _.update(activityLine, 'StateCapturePoints', function (n) {
                            return result.data.StateCapturePoints;
                        });

                        _.update(activityLine, 'StateTagReleasePoints', function (n) {
                            return result.data.StateTagReleasePoints;
                        });

                        _.update(activityLine, 'StateMeasureReleasePoints', function (n) {
                            return result.data.StateMeasureReleasePoints;
                        });

                        _.update(activityLine, 'StateLeadReleasePoints', function (n) {
                            return result.data.StateLeadReleasePoints;
                        });
                        activityEntryForm.setFieldValue('StatePoints', result.data.StatePoints);
                    }
                }
            });
        }
    }

    const handleFishSpeciesOnChange = (value, record, dataIndex) => {
        if (record) {
            _.update(activityLine, dataIndex, function (n) {
                return value;
            });

            var isSaltwater = 0;
            var fishSpecy = props.fishSpecies.find(x => x.Id === value);
            if (fishSpecy) {
                isSaltwater = fishSpecy.IsSaltwater;
            }

            _.update(activityLine, "IsSaltwater", function (n) {
                return isSaltwater;
            });

            handlePointScores(record);
        }
    }

    const handleFishWeightOnChange = (value, record, dataIndex) => {
        if (record) {
            _.update(activityLine, dataIndex, function (n) {
                return value;
            });
            handlePointScores(record);
        }
    }

    const handleLineClassOnChange = (value, record, dataIndex) => {
        if (record) {
            _.update(activityLine, dataIndex, function (n) {
                return value;
            });
            handlePointScores(record);
        }
    }

    const handleOnChange = (value, record, dataIndex) => {
        if (record) {
            _.update(activityLine, dataIndex, function (n) {
                return value;
            });
        }
    }

    return (
        <div>
            <Modal
                title={t("fishing_activity.edit_activity_entry")}
                open={true}
                onCancel={onClose}
                confirmLoading={false}
                maskClosable={false}
                centered
                width={1200}
                footer={[
                    <Button key={0} size="large" onClick={onClose}>{t("common.cancel")}</Button>,
                    <Button key={1} size="large" type="primary" disabled={!enableSubmit} onClick={onSubmit}>{t("common.submit")}</Button>
                ]}
            >
                <Form
                    {...layout}
                    name='activityEntryForm'
                    key='activityEntryForm'
                    onFinish={onSubmit}
                    component={false}
                    form={activityEntryForm}
                    >
                    <Row gutter={32}>
                        <Col span={4}>
                            <Form.Item
                                        name="Id"
                                        label={t("fishing_activity.act_id")}
                            >
                                <div>{activityLine.Id}</div>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                        name="DayLogId"
                                        label={t("fishing_activity.day_log_id")}
                            >
                                <div>{activityLine.DayLogId}</div>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                        name="ActivitySheetNumber"
                                        label={t("fishing_activity.asn")}
                            >
                                <div>{activityLine.ActivitySheetNumber}</div>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                        name="ActivityDate"
                                        label={t("fishing_activity.date")}
                            >
                                <div>{moment(activityLine.ActivityDate).format(Constant.DateFormat)}</div>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                        name="BoatName"
                                        label={t("fishing_activity.boat_name")}
                            >
                                <div>{activityLine.BoatName}</div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={25}>
                        <Col span={4}>
                            <Form.Item
                                        name="TagCaptureName"
                                        label={t("fishing_activity.tag_capt")}
                            >
                                <div>{activityLine.TagCaptureName}</div>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                        name="FishingMethodDescription"
                                        label={t("fishing_activity.method")}
                            >
                                <div>{activityLine.FishingMethodDescription}</div>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                        name="CaughtTime"
                                        label={t("fishing_activity.time")}
                            >
                                <div>{activityLine.CaughtTime}</div>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                        name="AnglerName"
                                        label={t("fishing_activity.angler")}
                            >
                                <div>{activityLine.AnglerName}</div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab={t("fishing_activity.fish_info")} key="fish_info">
                            <Row gutter={24}>
                                <Col xs={{ span: 6 }}>
                                    <Form.Item
                                        name="TagNumber"
                                        label={t("fishing_activity.tag_number")}
                                    >
                                        <TagNumber onChange={(e) => handleOnChange(e.target.value, activityLine, 'TagNumber')} mask={"a999999"} defaultValue="_______" />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 6 }}>
                                    <Form.Item
                                        name="FishWeight"
                                        label={t("fishing_activity.weight")}
                                    >
                                        <InputNumber onChange={(e) => handleFishWeightOnChange(e, activityLine, 'FishWeight')} precision={2} min={0} max={9999} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 6 }} >
                                    <Form.Item
                                        name="FishSpeciesId"
                                        label= { t('fishing_activity.species') }
                                        rules={[{ required: true, message: t("fishing_activity.species_is_required") }]}
                                        colon={false} labelAlign="left"
                                    >
                                        <EnhancedSelect showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={fishSpecies ? fishSpecies.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}
                                            onChange={(e) => handleFishSpeciesOnChange(e, activityLine, 'FishSpeciesId')}
                                        >
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 6 }}>
                                    <Form.Item
                                        name="LineClassId"
                                        label={t("fishing_activity.line_class")}
                                    >
                                        <EnhancedSelect onChange={(e) => handleLineClassOnChange(e, activityLine, 'LineClassId')} allowClear={true}
                                            showSearch optionFilterProp="label"
                                            filterOption={(input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
                                        >
                                            {
                                                props.lineClasses && props.lineClasses.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                            }
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab={t("fishing_activity.club_approval")} key="club_approval">
                            <Row gutter={24}>
                                <Col xs={{ span: 6 }}>
                                    <Form.Item
                                        name="ClubPoints"
                                        label={t("fishing_activity.club_points")}
                                    >
                                        <InputNumber allowClear
                                            onChange={(e) => handleOnChange(e, activityLine, 'ClubPoints')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 6 }}>
                                    <Form.Item
                                        name="ClubApprovalId"
                                        label={t("fishing_activity.club_approval")}
                                    >
                                        <EnhancedSelect onChange={(e) => handleOnChange(e, activityLine, 'ClubApprovalId')}
                                            showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={approvalStatuses ? approvalStatuses.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}
                                        >
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab={t("fishing_activity.state_approval")} key="state_approval">
                        <Row gutter={24}>
                                <Col xs={{ span: 6 }}>
                                    <Form.Item
                                        name="StatePoints"
                                        label={t("fishing_activity.state_points")}
                                    >
                                        <InputNumber allowClear
                                            onChange={(e) => handleOnChange(e, activityLine, 'StatePoints')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 6 }}>
                                    <Form.Item
                                        name="StateApprovalId"
                                        label={t("fishing_activity.state_approval")}
                                    >
                                        <EnhancedSelect onChange={(e) => handleOnChange(e, activityLine, 'StateApprovalId')}
                                            showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={approvalStatuses ? approvalStatuses.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}
                                        >
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                </Form>

            </Modal>
        </div>
    )
}

export default EditActivityEntry
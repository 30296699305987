import axios from 'axios';
import { axiosPortal } from './authService'

const source = [];
let baseURL = "api/Setting"

export const SettingService = {
    getPositionTitles: function (pageNumber, pageSize) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/PositionTitles?PageNumber=${pageNumber ? pageNumber : 1}`
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    deletePositionTitles: function (positionTitleIds) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/DeletePositionTitles`

        return axiosPortal.post(url, positionTitleIds, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    addPositionTitles: function (positionTitles) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/PositionTitles`

        return axiosPortal.post(url, positionTitles, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    updatePositionTitles: function (positionTitles) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/PositionTitles`

        return axiosPortal.put(url, positionTitles, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getDefaultPositionTitle: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/DefaultPositionTitle`

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getPositionTypes: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/PositionTypes`

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getMembersByClub: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/MembersByClub`

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    // Entity
    getEntityDetail: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        return axiosPortal.get(`${baseURL}/EntityDetail`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    updateEntityDetail: function (entity) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        return axiosPortal.post(`${baseURL}/EntityDetail`, entity, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getEntityLogo: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        return axiosPortal.get(`${baseURL}/EntityLogo`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getEntityLogoByParentId: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        return axiosPortal.get(`${baseURL}/GetEntityLogoByParentId`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getStateEntityDetail: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        return axiosPortal.get(`${baseURL}/GetStateEntityDetail`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    // Boat Categories
    getBoatCategories: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetBoatCategories?PageNumber=${pageNumber ? pageNumber : 1}`
        if (sort) {
            url += `&Sort=${sort}`
        }
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (params) {
            if (params.Name) {
                url += `&Name=${params.Name}`;
            }
            if (params.Description) {
                url += `&Description=${params.Description}`;
            }
            if (params.StatusIds) {
                url += `&StatusIds=${params.StatusIds}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    addUpdateBoatCategories: function (data) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/AddUpdateBoatCategories`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    deleteBoatCategories: function (ids) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/DeleteBoatCategories`, ids, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getAllBoatCategories: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        return axiosPortal.get(`${baseURL}/GetAllBoatCategories`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    // Main Contact Details
    getMainContactDetails: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetMainContactDetails`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    addUpdateMainContactDetails: function (data) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/AddUpdateMainContactDetails`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    // Point Score Zones
    getPointScoreZones: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetPointScoreZones?PageNumber=${pageNumber ? pageNumber : 1}`
        if (sort) {
            url += `&Sort=${sort}`
        }
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (params) {
            if (params.Name) {
                url += `&Name=${params.Name}`;
            }
            if (params.Description) {
                url += `&Description=${params.Description}`;
            }
            if (params.StatusIds) {
                url += `&StatusIds=${params.StatusIds}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getZones: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        return axiosPortal.get('api/master-data/zones', {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    addUpdatePointScoreZones: function (data) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/AddUpdatePointScoreZones`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getStates: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        return axiosPortal.get('api/master-data/states', {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getStateEntities: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        return axiosPortal.get(`${baseURL}/StateEntities`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getNationalEntities: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        return axiosPortal.get(`${baseURL}/NationalEntities`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    deletePointScoreZones: function (ids) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/DeletePointScoreZones`, ids, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getAllPointScoreZones: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        return axiosPortal.get(`${baseURL}/GetAllPointScoreZones`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getPointScoreZonesByParentId: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetPointScoreZonesByParentId?PageNumber=${pageNumber ? pageNumber : 1}`
        if (sort) {
            url += `&Sort=${sort}`
        }
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (params) {
            if (params.Name) {
                url += `&Name=${params.Name}`;
            }
            if (params.Description) {
                url += `&Description=${params.Description}`;
            }
            if (params.StatusIds) {
                url += `&StatusIds=${params.StatusIds}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    // Special Events
    getSpecialEvents: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetSpecialEvents?PageNumber=${pageNumber ? pageNumber : 1}`
        if (sort) {
            url += `&Sort=${sort}`
        }
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }
        if (params) {
            if (params.Name) {
                url += `&Name=${params.Name}`;
            }
            if (params.Description) {
                url += `&Description=${params.Description}`;
            }
            if (params.StatusIds) {
                url += `&StatusIds=${params.StatusIds}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    addUpdateSpecialEvents: function (data) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/AddUpdateSpecialEvents`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    deleteSpecialEvents: function (ids) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/DeleteSpecialEvents`, ids, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getAllSpecialEvents: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        return axiosPortal.get(`${baseURL}/GetAllSpecialEvents`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getStatusCodes: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        return axiosPortal.get(`${baseURL}/GetStatusCodes`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getSpecialEventsByParentId: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetSpecialEventsByParentId?PageNumber=${pageNumber ? pageNumber : 1}`
        if (sort) {
            url += `&Sort=${sort}`
        }
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }
        if (params) {
            if (params.Name) {
                url += `&Name=${params.Name}`;
            }
            if (params.Description) {
                url += `&Description=${params.Description}`;
            }
            if (params.StatusIds) {
                url += `&StatusIds=${params.StatusIds}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    cancelRequest: function () {
        if (source && source.length > 0) {
            for (var i = 0; i < source.length; i++) {
                source[i].cancel('Operation canceled.')
            }
            source.splice(0, source.length)
        }
    },

    // Fishing Locations
    getFishingLocations: function (pageNumber, pageSize, sort, params, isClubDefault = false) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetFishingLocations?PageNumber=${pageNumber ? pageNumber : 1}&isClubDefault=${isClubDefault}`
        if (sort) {
            url += `&Sort=${sort}`
        }
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (params) {
            if (params.Name) {
                url += `&Name=${params.Name}`;
            }
            if (params.GpsLatitude) {
                url += `&GpsLatitude=${params.GpsLatitude}`;
            }
            if (params.GpsLongitude) {
                url += `&GpsLongitude=${params.GpsLongitude}`;
            }
            if (params.NominalWaterDepth) {
                url += `&NominalWaterDepth=${params.NominalWaterDepth}`;
            }
            if (params.IsInsideClubWaters) {
                url += `&IsInsideClubWaters=${params.IsInsideClubWaters}`;
            }
            if (params.ClubZoneIds) {
                url += `&ClubZoneIds=${params.ClubZoneIds}`;
            }
            if (params.StatePointScoreZoneIds) {
                url += `&StatePointScoreZoneIds=${params.StatePointScoreZoneIds}`;
            }
            if (params.StatusIds) {
                url += `&StatusIds=${params.StatusIds}`;
            }
            if (params.InsideClubWaterIds) {
                url += `&InsideClubWaterIds=${params.InsideClubWaterIds}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getExportFishingLocations: function (sort, params, fileType) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/ExportFishingLocations?fileType=${fileType}`

        if (sort) {
            url += `&Sort=${sort}`
        }

        if (params) {
            if (params.Name) {
                url += `&Name=${params.Name}`;
            }
            if (params.GpsLatitude) {
                url += `&GpsLatitude=${params.GpsLatitude}`;
            }
            if (params.GpsLongitude) {
                url += `&GpsLongitude=${params.GpsLongitude}`;
            }
            if (params.NominalWaterDepth) {
                url += `&NominalWaterDepth=${params.NominalWaterDepth}`;
            }
            if (params.IsInsideClubWaters) {
                url += `&IsInsideClubWaters=${params.IsInsideClubWaters}`;
            }
            if (params.ClubZoneIds) {
                url += `&ClubZoneIds=${params.ClubZoneIds}`;
            }
            if (params.StatePointScoreZoneIds) {
                url += `&StatePointScoreZoneIds=${params.StatePointScoreZoneIds}`;
            }
            if (params.StatusIds) {
                url += `&StatusIds=${params.StatusIds}`;
            }
            if (params.InsideClubWaterIds) {
                url += `&InsideClubWaterIds=${params.InsideClubWaterIds}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token,
            responseType: 'blob'
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    addUpdateFishingLocations: function (data) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/AddUpdateFishingLocations`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    deleteFishingLocations: function (ids) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/DeleteFishingLocations`, ids, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getAllFishingLocations: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        return axiosPortal.get(`${baseURL}/GetAllFishingLocations`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getClubZones: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        return axiosPortal.get(`${baseURL}/GetClubZones`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getStatePointScoreZones: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        return axiosPortal.get(`${baseURL}/GetStatePointScoreZones`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getClubPointScores: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/ClubPointScores?PageNumber=${pageNumber ? pageNumber : 1}`
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (sort) {
            url += `&Sort=${sort}`
        }
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (params) {
            if (params.ZoneIds) {
                url += `&ZoneIds=${params.ZoneIds}`;
            }
            if (params.TagCaptureId) {
                url += `&TagCaptureId=${params.TagCaptureId}`;
            }
            if (params.GetAllZones) {
                url += `&GetAllZones=${params.GetAllZones}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getStatePointScores: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/StatePointScores?PageNumber=${pageNumber ? pageNumber : 1}`
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (sort) {
            url += `&Sort=${sort}`
        }
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (params) {
            if (params.ZoneIds) {
                url += `&ZoneIds=${params.ZoneIds}`;
            }
            if (params.TagCaptureId) {
                url += `&TagCaptureId=${params.TagCaptureId}`;
            }
            if (params.GetAllZones) {
                url += `&GetAllZones=${params.GetAllZones}`;
            }
            if (params.FishSpeciesIds) {
                url += `&FishSpeciesIds=${params.FishSpeciesIds}`;
            }
            if (params.LineClassIds) {
                url += `&LineClassIds=${params.LineClassIds}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getCapturePointScores: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/CapturePointScores?PageNumber=${pageNumber ? pageNumber : 1}`

        if (sort) {
            url += `&Sort=${sort}`
        }
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (params) {
            if (params.FishSpeciesIds) {
                url += `&FishSpeciesIds=${params.FishSpeciesIds}`;
            }
        }
        
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    updateStatePointScore: function (data) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/StatePointScore`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    UpdateFishSpeciesTagCode: function (data) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/UpdateFishSpeciesTagCode`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    UpdateFishSpeciesCaptureBase: function (data) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/UpdateFishSpeciesCaptureBase`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getTagCodes: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = "api/master-data/tag-code"
        
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    }
}
import axios from 'axios';
import PubSub from 'pubsub-js'
import { StorageService, Constant } from './services'
import { number } from 'react-i18next/icu.macro';

const source = [];
export const axiosPortal = axios.create();

export const AuthService = {
    registerIntercepter: function () {
        let authService = this
        axiosPortal.defaults.baseURL = process.env.REACT_APP_BASE_URL
        axiosPortal.interceptors.request.use(function (config) {
            let authData = StorageService.getAuthData()
            if (authData) {
                if (config.url
                    && config.url.indexOf("token") === -1
                    && config.url.indexOf("ForgotPassword") === -1
                    && config.url.indexOf("ResetPassword") === -1
                    && config.url.indexOf("ClubContactDetails") === -1) {
                    config.headers["Authorization"] = `bearer ${authData.token}`

                    let currentPosition = StorageService.getCurrentPosition()
                    if (currentPosition) {
                        config.headers["Position"] = JSON.stringify(currentPosition)
                    }
                }

                if (authData.redirectApiUrl) {
                    config.baseURL = authData.redirectApiUrl
                } else {
                    axiosPortal.defaults.baseURL = process.env.REACT_APP_BASE_URL;
                    config.baseURL = process.env.REACT_APP_BASE_URL;
                }
            }

            return config;
        }, function (error) {
            return Promise.reject(error);
        });

        // Add a response interceptor
        axiosPortal.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error && error.response && error.response.status === 401) {
                setTimeout(() => {
                    PubSub.publish(Constant.PubSupType.UserLogout, Constant.PubSupType.UserLogout);
                }, 100);
            }

            return Promise.reject(error);
        });
    },
    login: function (username, password) {
        return new Promise((resolve, reject) => {
            const params = new URLSearchParams();
            params.append('grant_type', 'password');
            params.append('username', username);
            params.append('password', password);

            return axiosPortal.post("/token", params).then(res => {
                var expiredTime = new Date();
                expiredTime.setSeconds(expiredTime.getSeconds() + res.data["expires_in"])
                let data = {
                    name: res.data["username"],
                    token: res.data["access_token"],
                    expire: expiredTime,
                    refreshToken: res.data["refresh_token"],
                    positions: JSON.parse(res.data["positions"]),
                    changePasswordRequired: res.data["changePasswordRequired"],
                    pageSize: res.data["pageSize"],
                    fishingTypeId: res.data["fishingTypeId"]
                };
                StorageService.setAuthData(data)
                resolve(data);
            }, (error) => {
                reject(error)
            })
        })
    },
    forgotPassword: function (username, isMemberNumber) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `api/Account/ForgotPassword`
        let data = {
            Username: username,
            IsMemberNumber: isMemberNumber
        }
        return axiosPortal.post(url, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    hasAuthorized: function () {
        let result = false
        let authData = StorageService.getAuthData()
        if (authData) {
            let expiredDate = new Date(authData.expire)
            let currentDate = new Date()
            if (expiredDate >= currentDate) {
                result = true
            }
        }
        return result
    }
}
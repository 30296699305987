import React, { useEffect, useState } from 'react';
import './editMemberDetails.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
    Constant,
    CommonService,
    SettingService,
    MembershipService,
    UserService
} from '../../../services/services'
import { Button, Row, Col, Form, Input, Tabs, Breadcrumb, Typography, Descriptions } from 'antd';
import LoadingContext from '../../../components/loading/loadingContext'
import SkipperOfBoats from './skipperOfBoats';
import PubSub from 'pubsub-js';
import EnhancedSelect from '../../../components/enhancedSelect/enhancedSelect';

const { Text } = Typography;
const { TabPane } = Tabs;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const EditMemberDetails = (props) => {
    const { t } = useTranslation();
    const [contactForm] = Form.useForm();
    const [changePasswordForm] = Form.useForm();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext);
    const [states, setStates] = useState([]);
    var [contactDetail, setContactDetail] = useState({});
    var [clubDetail, setClubDetail] = useState([]);
    var [memberDetail, setMemberDetail] = useState([]);
    var [skipperOfBoats, setSkipperOfBoats] = useState([]);
    const [visibleModal, setVisibleModal] = useState(false);

    const onSubmit = () => {
        // Add/Update
        showLoading();
        let data = contactForm.getFieldsValue();
        if (!contactDetail) {
            contactDetail = data;
        }
        else {
            contactDetail.MobilePhone = data.MobilePhone;
            contactDetail.HomePhone = data.HomePhone;
            contactDetail.Email = data.Email;
            contactDetail.StreetNo = data.StreetNo;
            contactDetail.StreetLine1 = data.StreetLine1;
            contactDetail.StreetLine2 = data.StreetLine2;
            contactDetail.Suburb = data.Suburb;
            contactDetail.State = data.State;
            contactDetail.PostCode = data.PostCode;
        }
        MembershipService.addUpdateMembershipContactDetail(contactDetail)
            .finally(() => {
                dismissLoading();
            })
            .then(result => {
                if (result.data) {
                    CommonService.presentToast('success', contactDetail.AddressId > 0 ? t('member.member_contact_details_update_successful') : t('member.member_contact_details_create_successful'));
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const onCancel = () => {
        MembershipService.getMembershipContactDetail()
            .finally(() => {
            })
            .then(response => {
                if (response.data) {
                    contactForm.setFieldsValue(response.data);
                    setContactDetail(response.data);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const onChangePassword = (data) => {
        showLoading();
        if (!data.Password || !data.ConfirmPassword) {
            return;
        }
        UserService.changePassword(data.Password, data.ConfirmPassword)
            .finally(() => {
                dismissLoading();
            })
            .then(() => {
                CommonService.presentToast('success', t('login.change_password_successful'));
                changePasswordForm.resetFields();
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const viewSkipperOfBoats = () => {
        setVisibleModal(true);
    }

    const handleCancel = (refresh) => {
        setVisibleModal(false);
    }

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight())
        }, 100)

        showLoading();
        setClubDetail([
            {
                key: '1',
                label: t('member.club'),
                children: null,
            },
            {
                key: '2',
                label: t('member.name_of_contact_person'),
                children: null,
            },
            {
                key: '3',
                label: t('member.main_contact_phone_number'),
                children: null,
            },
            {
                key: '4',
                label: t('member.main_contact_email'),
                children: null,
            },
        ]);

        setMemberDetail([
            {
                key: '1',
                label: t('member.member_name'),
                children: null,
            },
            {
                key: '2',
                label: t('member.user_name'),
                children: null,
            },
            {
                key: '3',
                label: t('member.club_member_number'),
                children: null,
            },
            {
                key: '4',
                label: t('member.member_id'),
                span: 2,
                children: null,
            },
            {
                key: '5',
                label: t('member.dob'),
                children: null,
            },
            {
                key: '6',
                label: t('member.gender'),
                children: null,
            },
            {
                key: '7',
                label: t('member.financial_membership_type'),
                children: null,
            },
            {
                key: '8',
                label: t('member.fishing_membership_type'),
                span: 2,
                children: null,
            },
            {
                key: '9',
                label: t('member.fishing_category'),
                children: null,
            },
            {
                key: '10',
                label: t('member.financial_end'),
                children: null,
            },
            {
                key: '11',
                label: t('member.member_since'),
                children: null,
            },
            {
                key: '12',
                label: t('member.family_group'),
                span: 2,
                children: null,
            },
            {
                key: '13',
                label: t('member.skipper_of_boats'),
                children: null,
            },
            // {
            //     key: '14',
            //     label: t('member.comments'),
            //     span: 2,
            //     children: null,
            // },
        ]);

        Promise.all([
            MembershipService.getMembershipDetail(),
            SettingService.getStates()
        ])
            .finally(() => {
                dismissLoading();
            })
            .then((response) => {
                if (response[0] && response[0].data) {
                    setContactDetail(response[0].data.ContactDetail);
                    setSkipperOfBoats(response[0].data.SkipperOfBoats);

                    setClubDetail([
                        {
                            key: '1',
                            label: t('member.club'),
                            children: response[0].data.EntityName,
                        },
                        {
                            key: '2',
                            label: t('member.name_of_contact_person'),
                            children: response[0].data.EntityContactName,
                        },
                        {
                            key: '3',
                            label: t('member.main_contact_phone_number'),
                            children: response[0].data.EntityContactPhone,
                        },
                        {
                            key: '4',
                            label: t('member.main_contact_email'),
                            children: response[0].data.EntityContactEmail,
                        },
                    ]);

                    var skipperOfBoatsCount = response[0].data.SkipperOfBoats != null ? response[0].data.SkipperOfBoats.length : 0;
                    setMemberDetail([
                        {
                            key: '1',
                            label: t('member.member_name'),
                            children: response[0].data.MemberName,
                        },
                        {
                            key: '2',
                            label: t('member.user_name'),
                            children: response[0].data.UserName,
                        },
                        {
                            key: '3',
                            label: t('member.club_member_number'),
                            children: response[0].data.EntityId,
                        },
                        {
                            key: '4',
                            label: t('member.member_id'),
                            span: 2,
                            children: response[0].data.MemberId,
                        },
                        {
                            key: '5',
                            label: response[0].data.IsJunior ? t('member.dob_for_junior') : t('member.dob'),
                            children: response[0].data.DateOfBirth ? moment(response[0].data.DateOfBirth).format(Constant.DateFileFormat) : "",
                        },
                        {
                            key: '6',
                            label: t('member.gender'),
                            children: response[0].data.GenderName,
                        },
                        {
                            key: '7',
                            label: t('member.financial_membership_type'),
                            children: response[0].data.FinancialTypeName,
                        },
                        {
                            key: '8',
                            label: t('member.fishing_membership_type'),
                            span: 2,
                            children: response[0].data.FishingTypeName,
                        },
                        {
                            key: '9',
                            label: t('member.fishing_category'),
                            children: response[0].data.FishingCategoryName,
                        },
                        {
                            key: '10',
                            label: t('member.financial_end'),
                            children: response[0].data.FinancialEnd ? moment(response[0].data.FinancialEnd).format(Constant.DateFileFormat) : "",
                        },
                        {
                            key: '11',
                            label: t('member.member_since'),
                            children: response[0].data.MemberSince ? moment(response[0].data.MemberSince).year() : "",
                        },
                        {
                            key: '12',
                            label: t('member.family_group'),
                            span: 2,
                            children: response[0].data.FamilyGroupName,
                        },
                        {
                            key: '13',
                            label: t('member.skipper_of_boats'),
                            children: <Text className='pointer' underline onClick={() => viewSkipperOfBoats()}>{skipperOfBoatsCount}</Text>
                        },
                        // {
                        //     key: '14',
                        //     label: t('member.comments'),
                        //     span: 2,
                        //     children: response[0].data.Comments,
                        // },
                    ]);
                }

                if (response[1] && response[1].data) {
                    setStates(response[1].data)
                }
            });

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);

        return () => {
            clearTimeout(timeoutFn);
        }
    }, [])

    const SubmitButton = ({ form }) => {
        const [submittable, setSubmittable] = React.useState(false);

        // Watch all values
        const values = Form.useWatch([], form);
        React.useEffect(() => {
            form
                .validateFields({
                    validateOnly: true,
                })
                .then(
                    () => {
                        setSubmittable(true);
                    },
                    () => {
                        setSubmittable(false);
                    },
                );
        }, [values]);
        return (
            <Button size="large" type="primary" htmlType="submit" disabled={!submittable}>{t("common.submit")}</Button>
        );
    }

    return (
        <div className="club-member-detail">
            <Breadcrumb>
                <Breadcrumb.Item>{t("menu.club_member_details")}</Breadcrumb.Item>
            </Breadcrumb>
            <Row className="m-t-10" gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                <Col xs={{ span: 24 }}>
                    <Descriptions layout="vertical" column={4} items={clubDetail} size='middle' colon={false} labelStyle={{ color: "#111111" }} contentStyle={{ fontWeight: 600 }} />
                </Col>
                <Col xs={{ span: 24 }}>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab={t("member.member_details")} key="member_details">
                            <Row>
                                <Col xs={{ span: 24 }}>
                                    <Descriptions layout="vertical" column={2} items={memberDetail} size='middle' colon={false} labelStyle={{ color: "#111111" }} contentStyle={{ fontWeight: 600 }} />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab={t("member.contact")} key="contact">
                            <Row>
                                <Col xs={{ span: 24 }}>
                                    <Form
                                        layout="vertical"
                                        {...layout}
                                        form={contactForm}
                                        name="contactForm"
                                        key='contactForm'
                                        onFinish={onSubmit}
                                        initialValues={contactDetail}>
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={t("member.street_number_short")}
                                                    extra={t("member.street_number_placeholder")}
                                                    rules={[{ required: true, message: t("member.street_number_is_required") }]}
                                                    name="StreetNo" colon={false} labelAlign="left">
                                                    <Input allowClear />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={t("member.street_name_short")}
                                                    extra={t("member.street_name_placeholder")}
                                                    rules={[{ required: true, message: t("member.street_name_is_required") }]}
                                                    name="StreetLine1" colon={false} labelAlign="left">
                                                    <Input allowClear />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={t("member.street_type_short")}
                                                    extra={t("member.street_type_placeholder")}
                                                    rules={[{ required: true, message: t("member.street_type_is_required") }]}
                                                    name="StreetLine2" colon={false} labelAlign="left">
                                                    <Input allowClear />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={t("member.suburb")}
                                                    rules={[{ required: true, message: t("member.suburb_is_required") }]}
                                                    name="Suburb" colon={false} labelAlign="left">
                                                    <Input allowClear />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={t("member.state")}
                                                    rules={[{ required: true, message: t("member.state_is_required") }]}
                                                    name="State" colon={false} labelAlign="left">
                                                    <EnhancedSelect showSearch optionFilterProp="label" allowClear={true}
                                                        maxTagCount='responsive'
                                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                                        filterSort={(optionA, optionB) => (optionA?.value == optionB?.value)}
                                                        options={states ? states.map(b => {
                                                            return {
                                                                label: b.Acronym,
                                                                value: b.Acronym
                                                            }
                                                        }) : []}
                                                    >
                                                    </EnhancedSelect>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={t("member.post_code")}
                                                    rules={[{ required: true, message: t("member.post_code_is_required") }]}
                                                    name="PostCode" colon={false} labelAlign="left">
                                                    <Input allowClear />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={t("member.email")}
                                                    rules={[{ required: true, message: t("member.email_is_required") }, { type: "email", message: t("member.email_invalid") }]}
                                                    name="Email" colon={false} labelAlign="left">
                                                    <Input allowClear />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={t("member.mobile_phone")}
                                                    name="MobilePhone" colon={false} labelAlign="left">
                                                    <Input allowClear />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={t("member.home_phone")}
                                                    name="HomePhone" colon={false} labelAlign="left">
                                                    <Input allowClear />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter]}>
                                            <Col xs={{ span: 24 }} className="text-right m-t-25">
                                                <Button size="large" className="m-r-5" onClick={onCancel}>{t("common.cancel")}</Button>
                                                <SubmitButton form={contactForm} />
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab={t("login.change_password")} key="change_password">
                            <Row>
                                <Col xs={{ span: 24 }}>
                                    <Form
                                        layout="vertical"
                                        form={changePasswordForm}
                                        name="changePasswordForm"
                                        key='changePasswordForm'
                                        onFinish={onChangePassword}
                                    >
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={t("login.password")}
                                                    name="Password" colon={false} labelAlign="left"
                                                    rules={[{ required: true, message: t("login.password_required") }]}
                                                >
                                                    <Input.Password placeholder={t("login.password")} allowClear />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={t("login.confirm_password")}
                                                    name="ConfirmPassword" colon={false} labelAlign="left"
                                                    rules={[({ getFieldValue }) => ({
                                                        validator(rule, value) {
                                                            let password = getFieldValue('Password')
                                                            if (value !== password) {
                                                                return Promise.reject(t("login.confirm_password_mismatch"));
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    })]}
                                                >
                                                    <Input.Password placeholder={t("login.confirm_password")} allowClear />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                            </Col>
                                        </Row>
                                        <Row align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter]}>
                                            <Col xs={{ span: 12 }} className="text-right m-t-25">
                                                <Button htmlType="submit" type="primary">
                                                    {t("common.change_password")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>

            {/* Skipper Of Boats Layout  */}
            {
                visibleModal &&
                <SkipperOfBoats
                    handleCancel={handleCancel}
                    skipperOfBoats={skipperOfBoats}>

                </SkipperOfBoats>
            }
        </div>
    )

}

export default EditMemberDetails;
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GetTableConfigs, EditableCell } from '../../../components/customTable/customTable'
import { Drawer, Modal, Table } from 'antd';
import { Constant, CommonService } from '../../../services/services';
import './boatDetail.scss'

const BoatDetail = (props) => {
    const { t } = useTranslation();
    const [fishDetails, setFishDetails] = useState([]);

    useEffect(() => {
        if (props.visible && props.objectData && props.objectData.FishDetails) {
            setFishDetails(props.objectData.FishDetails)
        }

        // let timeoutFn = setTimeout(() => {
        //     document.documentElement.style.setProperty('--trophy-detail-container-height', CommonService.calculateTableBodyMaxHeight("none-0", 24))
        // }, 100)

    }, [props.visible])

    const closeForm = () => {
        props.handleCancel()
    }

    const onTableChange = (pagination) => {
    }

    const getColumns = () => {
        let columns = []
        if (props.objectData) {
            for (let i = 0; i < props.objectData.FishFieldCount; i++) {
                if(props.objectData["HeaderField" + (i + 1)]) {
                    let column = {
                        title: props.objectData["HeaderField" + (i + 1)],
                        dataIndex: 'Field' + (i + 1),
                        key: 'Field' + (i + 1),
                        onHeaderCell: column => ({
                            minWidth: 100
                          })
                    }
                    columns.push(column)
                }              
            }
        }

        return columns
    }

    const components = {
        body: {
            cell: EditableCell,
        }
    };

    return (
        <div>
            <Drawer
                title={props.objectData.Name}
                open={true}
                confirmLoading={false}
                onClose={closeForm}
                maskClosable={false}
                className="vertical-modal boat-detail"
                width={900}
            >
                <Table
                    components={components}
                    rowClassName={() => 'editable-row'}
                    {...GetTableConfigs(getColumns(), fishDetails, fishDetails.length, "Field1", onTableChange, 1, "", false)}
                />
            </Drawer>
        </div>
    )
}

export default BoatDetail
import React, { useState, useEffect } from 'react';
import { Constant, CommonService, MembershipService } from '../../../services/services';
import { useTranslation } from 'react-i18next';
import { GetTableConfigs, StatusCell, ActionButtonsCell, EditableCell } from '../../../components/customTable/customTable'
import LoadingContext from '../../../components/loading/loadingContext'
import { FilterAltIcon, CloseIcon } from '../../../components/icons/icons';
import EnhancedSelect from '../../../components/enhancedSelect/enhancedSelect';
import { Row, Col, Table, Button, Checkbox, Modal, Form, Drawer, Space, Input, Select, InputNumber, Empty, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useGlobalState } from './../../../utilities/globalState'
import PubSub from 'pubsub-js'
import AddUpdateFamilyGroup from './../addUpdateFamilyGroup/addUpdateFamilyGroup'

const { confirm } = Modal;
const { Option } = Select;

const defaultGridOption = {
    pageNumber: 1,
    pageSize: CommonService.getPageSize(),
    sort: "FamilyGroupName-ascend"
}

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const { Text, Title } = Typography;

const FamilyGroupManagement = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const emptyTableData = {
        ObjectList: [],
        TotalItems: 0
    }

    const [familyGroupData, setFamilyGroupDataData] = useState(emptyTableData)
    const [gridConfigOptions, setGridConfigOptionsValue] = useState(defaultGridOption)
    const [visibleFilter, setVisibleFilter] = useState(false);
    const [formFilter] = Form.useForm();
    const [updateFamilyGroups, setUpdateFamilyGroups] = useState([])
    const [visibleAddUpdateFamilyGroup, setVisibleAddUpdateFamilyGroup] = useState(false);
    const [familyGroupFilter, setFamilyGroupFilter] = useState({ StatusIds: Constant.StatusCode.Active });
    const [currentPosition] = useGlobalState('currentPosition');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
        }, 100)

        showLoading()
        Promise.all([
            getFamilyGroups(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, familyGroupFilter, gridConfigOptions.sort)
        ])
            .finally(() => dismissLoading())
            .then(response => {
                if (response[0] && response[0].data) {
                    setFamilyGroupDataData(response[0].data)
                }

            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);
        return () => {
            MembershipService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    const getFamilyGroups = (pageNumber, pageSize, filter, sort) => {
        showLoading()
        if (!filter) {
            filter = { ...familyGroupFilter }
        }

        MembershipService.getFamilyGroups(pageNumber, pageSize, sort, filter)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    setFamilyGroupDataData(result.data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    const statusCodes = [
        {
            Value: Constant.StatusCode.Active,
            Description: t("common.active")
        },
        {
            Value: Constant.StatusCode.Inactive,
            Description: t("common.inactive")
        }
    ]

    const columns = [
        {
            title: t('family_group.family_group_name'),
            dataIndex: 'Name',
            key: 'Name',
            sorter: true,
            width: 130,
            defaultSortOrder: 'ascend'
        },
        {
            title: t('family_group.primary_contact'),
            key: 'PrimaryContact',
            dataIndex: 'PrimaryContact',
            width: 130,
            sorter: true
        },
        {
            title: t('family_group.member_2'),
            key: 'Member2',
            dataIndex: 'Member2',
            width: 130,
            sorter: true
        },
        {
            title: t('family_group.member_3'),
            key: 'Member3',
            dataIndex: 'Member3',
            width: 130,
            sorter: true
        },
        {
            title: t('family_group.member_4'),
            key: 'Member4',
            dataIndex: 'Member4',
            width: 130,
            sorter: true
        },
        {
            title: t('family_group.member_5'),
            key: 'Member5',
            dataIndex: 'Member5',
            width: 130,
            sorter: true
        },
        {
            title: t('family_group.member_6'),
            key: 'Member6',
            dataIndex: 'Member6',
            width: 130,
            sorter: true
        },
        {
            title: t('common.status'),
            dataIndex: 'StatusId',
            key: 'StatusId',
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.StatusId)}
                    description={CommonService.getStatusDescription(record.StatusId)} />
            ),
            sorter: true,
            align: 'center',
            width: 100
        }

    ]

    const onTableChange = (pagination, filters, sorter, extra) => {
        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = defaultGridOption.sort;
        }

        getFamilyGroups(pagination.current, pagination.pageSize, familyGroupFilter, sort)
        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        })
        setSelectedRowKeys([])
    }

    const hasFamilyGroupSelected = () => {
        if (familyGroupData && familyGroupData.ObjectList && familyGroupData.ObjectList.length > 0) {
            let selected = familyGroupData.ObjectList.filter(p => p.IsSelected)
            if (selected && selected.length > 0) {
                return true
            }
        }
        return false
    }

    const showFilterLayout = () => {
        setVisibleFilter(true);
    }

    const closeFilterLayout = () => {
        formFilter.setFieldsValue(familyGroupFilter);
        setVisibleFilter(false);
    }

    const resetFilter = () => {
        formFilter.resetFields();
        setFamilyGroupFilter({ StatusIds: Constant.StatusCode.Active });
        setSelectedRowKeys([])
    }

    const applyFilter = () => {
        setVisibleFilter(false);
        let filter = formFilter.getFieldsValue();
        setFamilyGroupFilter(filter)
        setGridConfigOptionsValue({
            ...gridConfigOptions,
            pageNumber: 1
        })
        getFamilyGroups(1, gridConfigOptions.pageSize, filter, gridConfigOptions.sort)
    }

    const handleAddUpdateFamilyGroups = (isReload) => {
        setVisibleAddUpdateFamilyGroup(false)
        setSelectedRowKeys([])

        if (isReload) {
            getFamilyGroups(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, familyGroupFilter, gridConfigOptions.sort)
        }
        else {
            let tempList = familyGroupData.ObjectList.map(x => {
                x.IsSelected = false
                return x
            })
            familyGroupData.ObjectList = tempList

            setFamilyGroupDataData({ ...familyGroupData })
        }
    }

    const addFamilyGroups = () => {
        setUpdateFamilyGroups([])
        setVisibleAddUpdateFamilyGroup(true)
    }

    const editFamilyGroups = () => {
        if (hasFamilyGroupSelected()) {
            let selected = familyGroupData.ObjectList.filter(p => p.IsSelected)
            setUpdateFamilyGroups(selected)
            setVisibleAddUpdateFamilyGroup(true)

        }
    }

    const deleteFamilyGroups = () => {
        if (hasFamilyGroupSelected()) {
            confirm({
                title: t("common.confirm"),
                icon: <ExclamationCircleOutlined />,
                content: t("family_group.delete_family_group_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    let selected = familyGroupData.ObjectList.filter(p => p.IsSelected)
                    let ids = selected.map((n, index) => n.Id)
                    showLoading()
                    MembershipService.deleteFamilyGroups(ids)
                        .finally(() => dismissLoading())
                        .then(result => {
                            getFamilyGroups(1, gridConfigOptions.pageSize, familyGroupFilter, gridConfigOptions.sort)
                            setGridConfigOptionsValue({
                                ...gridConfigOptions,
                                pageNumber: 1
                            })

                        })
                        .catch(error => CommonService.handleErrorResponse(error))
                },
                onCancel() {
                }

            });
        }
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys, newSelectedRows) => {
            if (newSelectedRows && familyGroupData && familyGroupData.ObjectList) {
                let tempList = familyGroupData.ObjectList.map(x => {
                    x.IsSelected = false
                    return x
                })
                for (let i = 0; i < newSelectedRows.length; i++) {
                    let familyGroup = tempList.find(p => p.Id == newSelectedRows[i].Id)
                    if (familyGroup) {
                        familyGroup.IsSelected = true;
                    }
                }

                familyGroupData.ObjectList = tempList

                setFamilyGroupDataData({ ...familyGroupData })
            }
            setSelectedRowKeys(newSelectedRowKeys)
        },
        columnWidth: 100
    }

    return (
        <>
            {currentPosition && currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee &&
                <div>
                    <div id={Constant.FixItemsContainerId}>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                            <Col xs={{ span: 24 }}>
                                <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                    <Title className='color-dark-blue' level={5}>{t("family_group.family_group_management")}</Title>
                                </Space>
                            </Col>
                            <Col xs={{ span: 24 }}>
                                <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                    <span>{t("family_group.header_message")}</span>
                                </Space>
                            </Col>
                        </Row>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                            <Col flex={100} className="text-right">
                                <div>
                                    <Button onClick={showFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon />}>{t("common.filter")}</Button>
                                    <Button className='m-l-10' danger disabled={!hasFamilyGroupSelected()} onClick={deleteFamilyGroups} >{t("common.delete")}</Button>
                                    <Button className='btn-outline-dark-blue m-l-10' disabled={!hasFamilyGroupSelected()} onClick={editFamilyGroups}>{t("common.edit")}</Button>
                                    <Button className='btn-solid-dark-blue m-l-10' onClick={addFamilyGroups}>{t("common.add_new")}</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Table
                                rowSelection={rowSelection}
                                {...GetTableConfigs(columns, familyGroupData?.ObjectList, familyGroupData?.TotalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                            />
                        </Col>
                    </Row>
                    <Drawer
                        title={t("common.filter")}
                        width={378}
                        onClose={closeFilterLayout}
                        open={visibleFilter}
                        closeIcon={false}
                        styles={{
                            body: {
                                paddingBottom: 80,
                            }
                        }}
                        extra={
                            <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                                <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                                <Button onClick={closeFilterLayout} icon={<CloseIcon />} type="link" />
                            </Space>
                        }
                        footer={
                            <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                                <Button onClick={closeFilterLayout}>{t("common.cancel")}</Button>
                                <Button onClick={applyFilter} type="primary">
                                    {t("common.apply")}
                                </Button>
                            </Space>
                        }>
                        <Form
                            layout="vertical"
                            {...layout}
                            form={formFilter}
                            name="formFilter"
                            key='formFilter'
                            initialValues={{ StatusIds: [Constant.StatusCode.Active] }}
                        >
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="Name"
                                        label={t("family_group.family_group_name")}
                                    >
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="PrimaryContact"
                                        label={t("family_group.primary_contact")}
                                    >
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="Member2"
                                        label={t("family_group.member_2")}
                                    >
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="Member3"
                                        label={t("family_group.member_3")}
                                    >
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="Member4"
                                        label={t("family_group.member_4")}
                                    >
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="Member5"
                                        label={t("family_group.member_5")}
                                    >
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="Member6"
                                        label={t("family_group.member_6")}
                                    >
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="StatusIds"
                                        label={t("common.status")}
                                    >
                                        <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            defaultValue={1}
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={statusCodes ? statusCodes.map(b => {
                                                return {
                                                    label: b.Description,
                                                    value: b.Value
                                                }
                                            }) : []}>
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Drawer>
                    {
                        visibleAddUpdateFamilyGroup &&
                        <AddUpdateFamilyGroup visible={visibleAddUpdateFamilyGroup} handleCancel={handleAddUpdateFamilyGroups} objectData={updateFamilyGroups}></AddUpdateFamilyGroup>
                    }
                </div>
            }

            {currentPosition && currentPosition.PositionTypeId != Constant.PositionType.ClubCommittee &&
                <>
                    <Table
                        style={{ visibility: 'hidden', height: '0px' }}
                        rowSelection={rowSelection}
                        {...GetTableConfigs(columns, [], 0, "Id", onTableChange, gridConfigOptions.pageNumber)}
                    />
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_access")} />
                </>
            }
        </>
    )
}

export default FamilyGroupManagement;
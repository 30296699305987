import React, { useState, useEffect } from 'react';
import { Constant, CommonService, SettingService, TrophyService, FishingActivityService } from '../../services/services';
import { useTranslation } from 'react-i18next';
import LoadingContext from '../../components/loading/loadingContext'
import { useGlobalState } from '../../utilities/globalState'
import { Row, Col, Table, Tabs, Dropdown, Form, Space, Empty, Typography, Drawer, Button } from 'antd';
import { GetTableConfigs } from '../../components/customTable/customTable'
import { DownloadOutlined } from '@ant-design/icons';
import { FilterAltIcon, CloseIcon } from '../../components/icons/icons'
import EnhancedSelect from '../../components/enhancedSelect/enhancedSelect';

import PubSub from 'pubsub-js'

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const { TabPane } = Tabs;
const { Text, Title } = Typography;

const ClubApprovalsStatus = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [currentPosition] = useGlobalState('currentPosition');
    const [stateApprovalsStatusData, setStateApprovalsStatusData] = useState([])
    const [entityDetail, setEntityDetail] = useState({});
    const [tagApprovalData, setTagApprovalData] = useState([])
    const [tagCaptureData, setTagCaptureData] = useState([])
    const [financialYearsData, setFinancialYearsData] = useState([])
    const [visibleFilter, setVisibleFilter] = useState(false);
    const [form] = Form.useForm();
    const [formFilter] = Form.useForm();
    const [approvalStatusFilter, setApprovalStatusFilter] = useState({});
    const [currentFinancialYear, setCurrentFinancialYear] = useState();

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
        }, 100)

        showLoading()
        Promise.all([
            SettingService.getStateEntityDetail(),
            FishingActivityService.getTagApproval(),
            TrophyService.getTagCapture(),
            TrophyService.getAllFinancialYears(),
        ])
            .finally(() => dismissLoading())
            .then(response => {
                if (response[0] && response[0].data) {
                    setEntityDetail(response[0].data)
                }
                if (response[1] && response[1].data) {
                    setTagApprovalData(response[1].data)
                }
                if (response[2] && response[2].data) {
                    setTagCaptureData(response[2].data)
                }
                if (response[3] && response[3].data) {
                    setFinancialYearsData(response[3].data)
                    let currentYears = response[3].data.filter(x => x.IsCurrentFinancialYear)
                    if (currentYears && currentYears.length > 0) {
                        setCurrentFinancialYear(currentYears[0])
                        setApprovalStatusFilter({
                            FinancialYearId: currentYears[0].Id
                        })
                        getClubApprovalsStatus(currentYears[0].Id)
                    }

                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);
        return () => {
            SettingService.cancelRequest()
            FishingActivityService.cancelRequest()
            TrophyService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    const approvalsStatusColumns = [
        {
            title: "",
            dataIndex: 'BoatName',
            key: 'BoatName',
            width: 200
        },
        {
            title: t("state_approvals_status.approved"),
            dataIndex: 'TotalApproved',
            key: 'TotalApproved',
            width: 60,
            align: 'center'
        },
        {
            title: t("state_approvals_status.dis_qual"),
            dataIndex: 'TotalDisqualified',
            key: 'TotalDisqualified',
            width: 60,
            align: 'center'
        },
        {
            title: t("state_approvals_status.blank"),
            dataIndex: 'TotalBlank',
            key: 'TotalBlank',
            width: 60,
            align: 'center'
        },
        {
            title: t("state_approvals_status.grand_total"),
            dataIndex: 'TotalApprovalsStatus',
            key: 'TotalApprovalsStatus',
            width: 60,
            align: 'center'
        }
    ]



    const getClubApprovalsStatus = (financialYearId, tagCaptureId, tagApprovalId) => {
        showLoading()
        TrophyService.getApprovalsStatus(financialYearId, tagCaptureId, tagApprovalId, false)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    setStateApprovalsStatusData(result.data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    const showFilterLayout = () => {
        setVisibleFilter(true);
    }

    const closeFilterLayout = () => {
        formFilter.setFieldsValue(approvalStatusFilter);
        setVisibleFilter(false);
    }

    const resetFilter = () => {
        formFilter.resetFields();
        formFilter.setFieldValue("FinancialYearId", currentFinancialYear.Id)
        setApprovalStatusFilter({
            FinancialYearId: currentFinancialYear.Id
        })
    }

    const applyFilter = () => {
        setVisibleFilter(false);
        let values = formFilter.getFieldsValue();
        setApprovalStatusFilter(values)
        getClubApprovalsStatus(values.FinancialYearId, values.TagCaptureId, values.TagApprovalId)
    }

    const exportItems = [
        {
            label: t("common.excel"),
            key: Constant.FileType.Excel
        },
        {
            label: t("common.csv"),
            key: Constant.FileType.Csv
        }
    ];

    const handleExport = (e) => {
        exportStateApprovalsStatus(e.key)
    };

    const menuExport = {
        items: exportItems,
        onClick: handleExport
    };

    const exportStateApprovalsStatus = (fileType) => {

        showLoading()
        TrophyService.getExportClubApprovalsStatus(fileType, approvalStatusFilter?.FinancialYearId, approvalStatusFilter?.TagCaptureId, approvalStatusFilter?.TagApprovalId)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    let fileName = 'Club_Approval'
                    if (fileType == Constant.FileType.Excel) {
                        const type = result.headers['content-type']
                        const blob = new Blob([result.data], { type: type })
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = `${fileName}.xlsx`
                        link.click()
                    } else if (fileType == Constant.FileType.Csv) {
                        const type = result.headers['content-type']
                        const blob = new Blob([result.data], { type: type })
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = `${fileName}.csv`
                        link.click()
                    }
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    const isClubCommittee = () => {
        if (currentPosition && (currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee ||
            currentPosition.PositionTypeId == Constant.PositionType.ClubMember
        ))
            return true
        return false
    }


    return (
        <>
            {
                currentPosition && (currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee ||
                    currentPosition.PositionTypeId == Constant.PositionType.ClubMember) &&
                <>
                    <div>
                        <div id={Constant.FixItemsContainerId}>
                            <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                                <Col xs={{ span: 24 }}>
                                    <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                        <Title className='color-dark-blue' level={5}>{t("state_approvals_status.title", { state_association_acronym: isClubCommittee() ? t("member.club") : entityDetail.EntityAcronym })}</Title>
                                    </Space>
                                </Col>
                                <Col xs={{ span: 24 }} className="text-right">
                                    <Button className='btn-outline-blue btn-icon' icon={<FilterAltIcon />} onClick={showFilterLayout}>{t("common.filter")}</Button>
                                    <Dropdown className='m-l-10' menu={menuExport} trigger={['click']}>
                                        <Button className='btn-outline-blue btn-icon' icon={<DownloadOutlined />}>
                                            {t("common.export")}
                                        </Button>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </div>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab={t("state_approvals_status.state_point_score_approvals_status", { state_association_acronym: isClubCommittee() ? t("member.club") : entityDetail.EntityAcronym })} key="approvals_status">
                                <Row>
                                    <Table
                                        bordered
                                        {...GetTableConfigs(approvalsStatusColumns, stateApprovalsStatusData?.ClubApprovalsStatusList, stateApprovalsStatusData?.ClubApprovalsStatusList?.length, "ClubId", null, 1, null, false)}
                                        summary={() => {
                                            return (
                                                <>
                                                    <Table.Summary.Row>
                                                        <Table.Summary.Cell>{t("common.total")}</Table.Summary.Cell>
                                                        <Table.Summary.Cell className='text-center'>
                                                            <Text>{stateApprovalsStatusData?.TotalApproved}</Text>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell className='text-center'>
                                                            <Text>{stateApprovalsStatusData?.TotalDisqualified}</Text>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell className='text-center'>
                                                            <Text>{stateApprovalsStatusData?.TotalBlank}</Text>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell className='text-center'>
                                                            <Text>{stateApprovalsStatusData?.TotalApprovalsStatus}</Text>
                                                        </Table.Summary.Cell>
                                                    </Table.Summary.Row>
                                                </>
                                            );
                                        }

                                        }
                                    />
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                    <Drawer
                        title={t("common.filter")}
                        width={378}
                        onClose={closeFilterLayout}
                        open={visibleFilter}
                        closeIcon={false}
                        styles={{
                            body: {
                                paddingBottom: 80,
                            }
                        }}
                        extra={
                            <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                                <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                                <Button onClick={closeFilterLayout} icon={<CloseIcon />} type="link" />
                            </Space>
                        }
                        footer={
                            <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                                <Button onClick={closeFilterLayout}>{t("common.cancel")}</Button>
                                <Button onClick={applyFilter} type="primary">
                                    {t("common.apply")}
                                </Button>
                            </Space>
                        }>
                        <Form
                            layout="vertical"
                            {...layout}
                            form={formFilter}
                            name="formFilter"
                            key='formFilter'
                            initialValues={approvalStatusFilter}>


                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="FinancialYearId"
                                        label={t("fishing_activity.financial_year")}
                                    >
                                        <EnhancedSelect showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toString().toLowerCase().startsWith(input.toLowerCase())}
                                            filterSort={(optionA, optionB) => (optionA?.value == optionB?.value)}
                                            options={financialYearsData ? financialYearsData.map(b => {
                                                return {
                                                    label: b.Year,
                                                    value: b.Id
                                                }
                                            }) : []}
                                        >
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="TagCaptureId"
                                        label={t("state_approvals_status.tag_capture")}
                                    >
                                        <EnhancedSelect showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                            filterSort={(optionA, optionB) => (optionA?.value == optionB?.value)}
                                            options={tagCaptureData ? tagCaptureData.map(b => {
                                                return {
                                                    label: b.Description,
                                                    value: b.Id
                                                }
                                            }) : []}
                                        >
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="TagApprovalId"
                                        label={t("state_approvals_status.tag_approval")}
                                    >
                                        <EnhancedSelect showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={tagApprovalData ? tagApprovalData.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}
                                        >
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Drawer>
                </>
            }
            {currentPosition && currentPosition.PositionTypeId != Constant.PositionType.ClubCommittee &&
                currentPosition.PositionTypeId != Constant.PositionType.ClubMember &&
                <>
                    <Table
                        style={{ visibility: 'hidden', height: '0px' }}
                        {...GetTableConfigs(approvalsStatusColumns, [], 0, "Id", null, 1)}
                    />
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_access")} />
                </>
            }

        </>
    )
}
export default ClubApprovalsStatus;
import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import './CustomInputMask.scss';

const CustomInputMask = ({ value, onChange, mask, defaultValue, disabled, isFilter = false }) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleChange = (e) => {
        let newValue = e.target.value;
        const degreeIndex = newValue.indexOf('°');
        
        if (degreeIndex !== -1 && degreeIndex + 1 < newValue.length) {
            const minutesChar = newValue[degreeIndex + 1];
            if (minutesChar > '5' && minutesChar != '_') {
                newValue = newValue.slice(0, degreeIndex + 1) + '5' + newValue.slice(degreeIndex + 2);
            }
        }

        if (!isFilter){
            const lastThreeCharacters = newValue.slice(-4);
            const replacedValue = newValue.slice(0, -4) + lastThreeCharacters.replace(/_/g, '0');
        }
        onChange({ target: { value: newValue } });
    };

    return (
        <InputMask
            value={value || defaultValue}
            mask={mask}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={'custom-mask-input'}
            disabled={disabled}
            style={{
                borderColor: isFocused ? '#2986ff' : '#d9d9d9',
            }}
        />
    );
};

export default CustomInputMask;

export const StorageService = {
    authKey: "authorizationData",
    currentPositionKey: "currentPosition",
    currentMenuKey: "currentMenu",
    setAuthData: function (authData) {
        localStorage.setItem(this.authKey, JSON.stringify(authData));
    },
    getAuthData: function () {
        let data = localStorage.getItem(this.authKey)
        if (data) {
            return JSON.parse(data);
        }
        return null
    },
    removeAuthData: function () {
        localStorage.removeItem(this.authKey);
    },
    setCurrentPosition: function (data) {
        localStorage.setItem(this.currentPositionKey, JSON.stringify(data));
    },
    getCurrentPosition: function () {
        let data = localStorage.getItem(this.currentPositionKey)
        if (data) {
            return JSON.parse(data);
        }
        return null
    },
    removeCurrentPosition: function () {
        localStorage.removeItem(this.currentPositionKey);
    },
    setCurrentMenu: function (data) {
        localStorage.setItem(this.currentMenuKey, JSON.stringify(data));
    },
    getCurrentMenu: function () {
        let data = localStorage.getItem(this.currentMenuKey)
        if (data) {
            return JSON.parse(data);
        }
        return null
    },
    removeCurrentMenu: function () {
        localStorage.removeItem(this.currentMenuKey);
    }
}
import React, { useState, useEffect } from 'react';
import './boatSearch.scss';
import { Constant, CommonService, MembershipService, SettingService, FishingActivityService } from '../../services/services';
import { useTranslation } from 'react-i18next';
import { GetTableConfigs, StatusCell, ActionButtonsCell, EditableCell } from '../../components/customTable/customTable'
import LoadingContext from '../../components/loading/loadingContext'
import { FilterAltIcon, CloseIcon } from '../../components/icons/icons';
import { Row, Col, Table, Button, Typography, Modal, Form, Drawer, Space, Input, Select, InputNumber, Empty, Dropdown } from 'antd';
import { ExclamationCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { useGlobalState } from './../../utilities/globalState'
import EnhancedSelect from '../../components/enhancedSelect/enhancedSelect'
import PubSub from 'pubsub-js'

const { Text, Title } = Typography;

const { confirm } = Modal;
const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const defaultGridOption = {
    pageNumber: 1,
    pageSize: CommonService.getPageSize(),
    sort: "BoatName-ascend"
}

const BoatSearch = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const emptyTableData = {
        ObjectList: [],
        TotalItems: 0
    }
    const [boatData, setBoatData] = useState(emptyTableData)
    const [gridConfigOptions, setGridConfigOptionsValue] = useState(defaultGridOption)
    const [currentPosition] = useGlobalState('currentPosition');
    const [visibleFilter, setVisibleFilter] = useState(false);
    const [formFilter] = Form.useForm();
    const [boatFilter, setBoatFilter] = useState({ StatusIds: Constant.StatusCode.Active, SeachAllClubs: true });
    const [entityDetail, setEntityDetail] = useState({})
    const [clubData, setClubData] = useState([]);

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
        }, 100)

        showLoading()
        Promise.all([
            MembershipService.getBoats(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, boatFilter),
            SettingService.getStateEntityDetail(),
            MembershipService.getClubsInState()
        ])
            .finally(() => dismissLoading())
            .then(response => {
                if (response[0] && response[0].data) {
                    setBoatData(response[0].data)
                }

                if (response[1] && response[1].data) {
                    setEntityDetail(response[1].data)
                }

                if (response[2] && response[2].data) {
                    setClubData(response[2].data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);
        return () => {
            MembershipService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    const getBoats = (pageNumber, pageSize, filter, sort) => {
        showLoading()
        if (!filter) {
            filter = { ...boatFilter }
        }
        filter.SeachAllClubs = true
        MembershipService.getBoats(pageNumber, pageSize, sort, filter)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    setBoatData(result.data)
                }
            })
    }

    const skipperFinanceChecks = [
        {
            Value: 1,
            Description: t("common.yes")
        },
        {
            Value: 2,
            Description: t("common.no")
        }
    ]

    const statusCodes = [
        {
            Value: Constant.StatusCode.Active,
            Description: t("common.active")
        },
        {
            Value: Constant.StatusCode.Inactive,
            Description: t("common.inactive")
        }
    ]

    const getColumns = () => {
        let columns = [
            {
                title: t('boat.boat_name'),
                dataIndex: 'Name',
                key: 'Name',
                sorter: true,
                width: 150,
                defaultSortOrder: 'ascend'
            },
            {
                title: t('boat.rego'),
                dataIndex: 'RegoNo',
                key: 'RegoNo',
                sorter: true,
                width: 130
            },
            {
                title: t('boat.boat_no'),
                dataIndex: 'BoatNo',
                key: 'BoatNo',
                sorter: true,
                width: 130
            }
        ]
        columns.push({
            title: t('member.club'),
            dataIndex: 'ClubAcronym',
            key: 'ClubAcronym',
            sorter: true,
            width: 130
        })
        columns.push({
            title: t('boat.make'),
            dataIndex: 'Make',
            key: 'Make',
            sorter: true,
            width: 100
        },
            {
                title: t('boat.length'),
                dataIndex: 'Length',
                key: 'Length',
                sorter: true,
                width: 120
            },
            {
                title: t('boat.skipper_1'),
                key: 'Skipper1',
                dataIndex: 'Skipper1',
                render: (value, record) => {
                    if (record.Skipper1) {
                        return record.Skipper1.FirstName + " " + record.Skipper1.LastName
                    }
                    return null
                },
                sorter: true,
                width: 145
            },
            {
                title: t('boat.skipper_2'),
                key: 'Skipper2',
                dataIndex: 'Skipper2',
                render: (value, record) => {
                    if (record.Skipper2) {
                        return record.Skipper2.FirstName + " " + record.Skipper2.LastName
                    }
                    return null
                },
                sorter: true,
                width: 145
            },
            {
                title: t('boat.financial'),
                key: 'IsSkipperFinancialCheck',
                dataIndex: 'IsSkipperFinancialCheck',
                render: (value, record) => (
                    <StatusCell data={record}
                        colorClass={CommonService.getBooleanColorClass(record.IsSkipperFinancialCheck)}
                        description={CommonService.getBooleanDescription(record.IsSkipperFinancialCheck)} />
                ),
                sorter: true,
                align: 'center',
                width: 110
            },
            {
                title: t('common.status'),
                dataIndex: 'StatusId',
                key: 'StatusId',
                render: (value, record) => (
                    <StatusCell data={record}
                        colorClass={CommonService.getStatusColorClass(record.StatusId)}
                        description={CommonService.getStatusDescription(record.StatusId)} />
                ),
                sorter: true,
                align: 'center',
                width: 100
            })
        return columns
    }

    const showClub = () => {
        if (currentPosition != null && (currentPosition.PositionTypeId == Constant.PositionType.NationalExecutive ||
            currentPosition.PositionTypeId == Constant.PositionType.StateExcutive))
            return true
        return false
    }



    const onTableChange = (pagination, filters, sorter, extra) => {
        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = defaultGridOption.sort;
        }

        getBoats(pagination.current, pagination.pageSize, boatFilter, sort)
        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        })
    }

    const showFilterLayout = () => {
        setVisibleFilter(true);
    }

    const closeFilterLayout = () => {
        formFilter.setFieldsValue(boatFilter);
        setVisibleFilter(false);
    }

    const resetFilter = () => {
        formFilter.resetFields();
        setBoatFilter({StatusIds: Constant.StatusCode.Active, SeachAllClubs: true});
    }

    const applyFilter = () => {
        setVisibleFilter(false);
        let filter = formFilter.getFieldsValue();
        setBoatFilter(filter)
        setGridConfigOptionsValue({
            ...gridConfigOptions,
            pageNumber: 1
        })
        getBoats(1, gridConfigOptions.pageSize, filter, gridConfigOptions.sort)
    }

    const exportItems = [
        {
            label: t("common.excel"),
            key: Constant.FileType.Excel
        },
        {
            label: t("common.csv"),
            key: Constant.FileType.Csv
        }
    ];

    const handleExport = (e) => {
        exportBoats(e.key)
    };

    const menuExport = {
        items: exportItems,
        onClick: handleExport
    };

    const exportBoats = (fileType) => {
        showLoading()
        MembershipService.getBoatSearchExport(gridConfigOptions.sort, boatFilter, fileType)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    let fileName = 'Boat List'
                    if (fileType == Constant.FileType.Excel) {
                        const type = result.headers['content-type']
                        const blob = new Blob([result.data], { type: type })
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = `${fileName}.xlsx`
                        link.click()
                    } else if (fileType == Constant.FileType.Csv) {
                        const type = result.headers['content-type']
                        const blob = new Blob([result.data], { type: type })
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = `${fileName}.csv`
                        link.click()
                    }
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    return (
        <>
            <div className='boat-search'>
                <div id={Constant.FixItemsContainerId}>
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-b-10'>
                        <Col xs={{ span: 24 }}>
                            <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                <Title className='color-dark-blue' level={5}>{t("boat.boat_search")}</Title>
                            </Space>
                        </Col>
                        <Col xs={{ span: 24 }}>
                            <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                <span>{t("boat.boat_search_mes_1", { state_association_acronym: entityDetail.EntityAcronym })}</span>
                                <span className='header-title'>{t("boat.boat_search_mes_2")}</span>
                                <span className='header-title' dangerouslySetInnerHTML={{ __html: t("boat.boat_search_mes_3", { state_association_acronym: entityDetail.EntityAcronym, state_association_website: entityDetail.Website }) }}></span>
                            </Space>
                        </Col>
                    </Row>

                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                        <Col xs={{ span: 24 }} className="text-right">
                            <Button onClick={showFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon />}>{t("common.filter")}</Button>
                            {currentPosition != null && currentPosition.PositionTypeId != Constant.PositionType.ClubMember && 
                                <Dropdown className='m-l-10' menu={menuExport} trigger={['click']}>
                                    <Button className='btn-outline-blue btn-icon' icon={<DownloadOutlined />}>
                                        {t("common.export")}
                                    </Button>
                                </Dropdown>
                            }
                            
                        </Col>
                    </Row>
                </div>

                <Row className='section-table' gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                    <Col xs={{ span: 24 }}>
                        <Table
                            {...GetTableConfigs(getColumns(), boatData?.ObjectList, boatData?.TotalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                        />
                    </Col>
                </Row>

                <Drawer
                    title={t("common.filter")}
                    width={378}
                    onClose={closeFilterLayout}
                    open={visibleFilter}
                    closeIcon={false}
                    styles={{
                        body: {
                            paddingBottom: 80,
                        }
                    }}
                    extra={
                        <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                            <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                            <Button onClick={closeFilterLayout} icon={<CloseIcon />} type="link" />
                        </Space>
                    }
                    footer={
                        <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                            <Button onClick={closeFilterLayout}>{t("common.cancel")}</Button>
                            <Button onClick={applyFilter} type="primary">
                                {t("common.apply")}
                            </Button>
                        </Space>
                    }>
                    <Form
                        layout="vertical"
                        {...layout}
                        form={formFilter}
                        name="formFilter"
                        key='formFilter'
                        initialValues={{}}>

                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="Name"
                                    label={t("boat.boat_name")}
                                >
                                    <Input allowClear />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="RegoNo"
                                    label={t("boat.rego")}
                                >
                                    <Input allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="ClubIds"
                                    label={t("fishing_activity.club")}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={clubData ? clubData.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="BoatNo"
                                    label={t("boat.boat_no")}
                                >
                                    <Input allowClear />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="Make"
                                    label={t("boat.make")}
                                >
                                    <Input allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="Length"
                                    label={t("boat.length")}
                                >
                                    <InputNumber style={{ width: '100%' }} allowClear precision={2} min={0} max={9999} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="Skipper1"
                                    label={t("boat.skipper_1")}
                                >
                                    <Input allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="Skipper2"
                                    label={t("boat.skipper_2")}
                                >
                                    <Input allowClear />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="SkipperFinancialChecks"
                                    label={t("boat.skipper_financial_check")}
                                >
                                    <EnhancedSelect mode="multiple" allowClear={true}
                                        showSearch optionFilterProp="children"
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                    >
                                        {
                                            skipperFinanceChecks.map((n, index) => <Option key={index} value={n.Value}>{n.Description}</Option>)
                                        }
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="StatusIds"
                                    label={t("common.status")}
                                >
                                    <EnhancedSelect mode="multiple" allowClear={true} defaultValue={1}
                                        showSearch optionFilterProp="children"
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                    >
                                        {
                                            statusCodes && statusCodes.map((n, index) => <Option key={index} value={n.Value}>{n.Description}</Option>)
                                        }
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Drawer>
            </div>
        </>
    )
}

export default BoatSearch;
import React, { useState, useEffect } from 'react';
import { Modal, Table, Row, Col, Button, Input, Drawer, Space, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import LoadingContext from '../../../components/loading/loadingContext'
import { useGlobalState } from '../../../utilities/globalState'
import { GetTableConfigs_SimplePaginator, ActionButtonsCell, TextAndButtonCell, EditableCell } from '../../../components/customTable/customTable'
import { TrophyService, CommonService } from '../../../services/services'
import { CloseIcon } from '../../../components/icons/icons'
import { contains } from 'underscore';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const ViewBoats = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [boats, setBoats] = useState([]);
    const [viewBoatForm] = Form.useForm();
    const [displayBoats, setDisplayBoats] = useState([]);
    useEffect(() => {
        if (props.selectedObject) {
            showLoading()
            TrophyService.getBoatGroupById(props.selectedObject.Id)
                .finally(() => dismissLoading())
                .then(result => {
                    if (result.data) {
                        setBoats(result.data.Boats)
                        setDisplayBoats(result.data.Boats)
                    }
                })
                .catch(error => {
                    CommonService.handleErrorResponse(error);
                })
        }
        return () => {
            TrophyService.cancelRequest()
        }
    }, [])

    const closeForm = () => {
        props.handleCancel()
    }

    const columns = [
        {
            title: t('boat.boat_no'),
            dataIndex: 'BoatNo',
            key: 'BoatNo',
            sorter: false,
            width: 100
        },
        {
            title: t('boat.boat_name'),
            dataIndex: 'Name',
            key: 'Name',
            sorter: false,
            width: 150
        },
        {
            title: t('angler_group.club'),
            dataIndex: 'ClubAcronym',
            key: 'ClubAcronym',
            sorter: false,
            width: 120
        },
        {
            title: t('boat.rego'),
            dataIndex: 'RegoNo',
            key: 'RegoNo',
            sorter: false,
            width: 130
        },
        {
            title: t('boat.skipper_1'),
            dataIndex: 'Skipper1',
            key: 'Skipper1',
            render: (value, record) => {
                if (record.Skipper1) {
                    return record.Skipper1.FirstName + " " + record.Skipper1.LastName
                }
                return null
            },
            sorter: false,
            width: 145
        },
        {
            title: t('boat.skipper_2'),
            dataIndex: 'Skipper2',
            key: 'Skipper2',
            render: (value, record) => {
                if (record.Skipper2) {
                    return record.Skipper2.FirstName + " " + record.Skipper2.LastName
                }
                return null
            },
            sorter: false,
            width: 145
        },
    ]

    const boatNameOnChange = (value) => {
        if (value && value.length > 0) {
            const selectedBoats = boats.filter((m) => m.Name.toString().toLowerCase().includes(value.toLowerCase()));
            setDisplayBoats(selectedBoats);
        }
        else {
            setDisplayBoats(boats);
        }
    }

    return (
        <Drawer
            title={`${t("boat_grouping.boats")}-${props.selectedObject?.Name}`}
            width={900}
            onClose={closeForm}
            open={true}
            closeIcon={false}
            styles={{
                body: {
                    paddingBottom: 80,
                }
            }}
            extra={
                <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                    <Button onClick={closeForm} icon={<CloseIcon />} type="link" />
                </Space>
            }>
            <Form
                layout="vertical"
                {...layout}
                form={viewBoatForm}
                name="viewBoatForm"
                key='viewBoatForm'
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="Name"
                            label={t("boat.boat_name")}
                        >
                            <Input onChange={(e) => boatNameOnChange(e.target.value)} allowClear />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col xs={{ span: 24 }}>
                        <Table
                            {...GetTableConfigs_SimplePaginator(columns, displayBoats, displayBoats.length, "Id", null, null)}
                            pagination={false}
                        />
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )
}

export default ViewBoats
import React, { useState, useEffect } from 'react';
import { Constant, CommonService, MembershipService } from '../../../../services/services';
import { useTranslation } from 'react-i18next';
import { GetTableConfigs_SimplePaginator, ActionButtonsCell, EditableCell, StatusCell } from '../../../../components/customTable/customTable'
import LoadingContext from '../../../../components/loading/loadingContext'
import EnhancedSelect from '../../../../components/enhancedSelect/enhancedSelect';
import { FilterAltIcon, CloseIcon, EditIcon } from '../../../../components/icons/icons';
import { Row, Col, Table, Button, DatePicker, Modal, Form, Drawer, Space, Input, Select, Dropdown, theme } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useGlobalState } from '../../../../utilities/globalState'
import CreateEditMembership from '../../membershipManagement/createEditMembership';
import { useHistory } from "react-router-dom";

import moment from 'moment';
import PubSub from 'pubsub-js'

const { confirm } = Modal;
const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const MemberReport = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [formFilter] = Form.useForm();
    const [financialYears, setFinancialYears] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [members, setMembers] = useState([]);
    const [searchMemberReport, setSearchMemberReport] = useState({ MemberStatusIds: [Constant.MemberStatusCode.Active] });
    const [genders, setGenders] = useState([]);
    const [familyGroups, setFamilyGroups] = useState([]);
    const [fishingTypes, setFishingTypes] = useState([]);
    const [fishingCategories, setFishingCategories] = useState([]);
    const [financialTypes, setFinancialTypes] = useState([]);
    const [financialStatuses, setFinancialStatuses] = useState([]);
    const [openFilter, setOpenFilter] = useState(false);
    const [memberStatuses, setMemberStatuses] = useState([]);
    const [reportFormat, setReportFormat] = useState();
    const [error, setError] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [titles, setTitles] = useState([]);
    const [positionTitles, setPositionTitles] = useState([]);
    const [states, setStates] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);

    const viewMember = (record) => {
        setIsEdit(true);
        setVisibleModal(true);
        setSelectedRowKeys([record.Id]);
        setSelectedMembers([record]);
    }

    const handleCancel = (refresh) => {
        setVisibleModal(false);
        if (refresh) {
            refreshData();
        }

    }
    const [gridConfigOptions, setGridConfigOptionsValue] = useState({
        pageNumber: 1,
        pageSize: CommonService.getPageSize(),
        sort: "LastName-ascend",
    })
    const [currentPosition] = useGlobalState('currentPosition');

    const { token } = theme.useToken();
    const style = {
        border: `1px solid ${token.colorPrimary}`,
        borderRadius: '50%',
    };

    const cellRender = React.useCallback((current, info) => {
        if (info.type !== 'date') {
            return info.originNode;
        }
        if (typeof current === 'number') {
            return <div className="ant-picker-cell-inner">{current}</div>;
        }
        return (
            <div className="ant-picker-cell-inner" style={current.date() === 1 ? style : {}}>
                {current.date()}
            </div>
        );
    }, []);

    const search = (pageNumber, pageSize, sort, query, isShowLoading = false) => {
        if (isShowLoading) {
            showLoading();
        }

        let params = { ...query };
        if (!params) params = { MemberStatusIds: [Constant.MemberStatusCode.Active] };
        if (params.FinancialEnd) {
            var financialEnd = null;
            var length = params.FinancialEnd.split('/').length;
            if (length >= 3) {
                financialEnd = moment(params.FinancialEnd, Constant.DateFormat);
            } else {
                financialEnd = moment(params.FinancialEnd)
            }
            params.FinancialEnd = financialEnd;
        }

        MembershipService.searchMemberships(pageNumber, pageSize, sort, params)
            .finally(() => {
                if (isShowLoading) {
                    dismissLoading();
                }
                setSelectedRowKeys([]);
                setSelectedMembers([]);
            })
            .then(result => {
                if (result.data) {
                    setMembers(result.data.ObjectList);
                    setTotalItems(result.data.TotalItems);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    useEffect(() => {
        if (currentPosition && currentPosition.PositionTypeId === Constant.PositionType.ClubMember){
            history.push('/unauthorised');
            return;
        }
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight())
        }, 100)

        showLoading();
        Promise.all([
            MembershipService.searchMemberships(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, { MemberStatusIds: [Constant.MemberStatusCode.Active] }),
            MembershipService.getFilterDatas()
        ])
            .finally(() => {
                setSelectedRowKeys([]);
                setSelectedMembers([]);
                dismissLoading();

                setSearchMemberReport({ MemberStatusIds: [Constant.MemberStatusCode.Active] });
            })
            .then((response) => {
                if (response[0].data) {
                    setMembers(response[0].data.ObjectList);
                    setTotalItems(response[0].data.TotalItems);
                }

                if (response[1].data) {
                    setTitles(response[1].data.Titles);
                    setGenders(response[1].data.Genders);
                    setFamilyGroups(response[1].data.FamilyGroups);
                    setFishingTypes(response[1].data.FishingTypes);
                    setFishingCategories(response[1].data.FishingCategories);
                    setFinancialTypes(response[1].data.FinancialTypes);
                    setFinancialStatuses(response[1].data.FinancialStatuses);
                    setFinancialYears(response[1].data.FinancialYearsOnly);
                    setMemberStatuses(response[1].data.MemberStatuses);
                    setPositionTitles(response[1].data.PositionTitles);
                    setStates(response[1].data.States);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);

        return () => {
            MembershipService.cancelRequest();
            clearTimeout(timeoutFn);
        }
    }, [])

    const handleExport = (e) => {
        if (!reportFormat) {
            setError(true);
        } else {
            setError(false);
            exportMemberReport(e.key)
        }
    };

    const exportItems = [
        {
            label: t("common.excel"),
            key: Constant.FileType.Excel
        },
        {
            label: t("common.csv"),
            key: Constant.FileType.Csv
        }
    ];
    const menuExport = {
        items: exportItems,
        onClick: handleExport
    };

    const getActionButtonsInfo = (item) => {
        return [
            {
                Description: t("common.edit"),
                Icon: <EditIcon />,
                ColorClass: Constant.ColorClass.LightBlue,
                handleAction: viewMember
            }
        ]
    }

    const getMemberTitle = () => {
        return currentPosition != null && currentPosition.StateAssociationAcronym != null && currentPosition.StateAssociationAcronym.length > 0 ? currentPosition.StateAssociationAcronym + " #" : t('member.member_number');
    }

    const getStateClubTitle = () => {
        return t('member.club');
    }

    const isClub = () => {
        return currentPosition != null && currentPosition.EntityTypeId === Constant.EntityType.Club;
    }

    const isClubMemberAndClubCommittee = () => {
        return currentPosition != null && (currentPosition.PositionTypeId === Constant.PositionType.ClubMember || currentPosition.PositionTypeId === Constant.PositionType.ClubCommittee);
    }
    const stateColumns = [
        {
            title: t('common.action'),
            key: 'Id',
            render: (value, record) => {
                return <ActionButtonsCell btnList={getActionButtonsInfo(record)} data={record} />
            },
            align: "center",
            width: 38
        },
        {
            title: <span>{getStateClubTitle()}</span>,
            dataIndex: 'EntityAcronym',
            key: 'EntityAcronym',
            sorter: true,
            width: 70,
        },
        {
            title: t('member.first_name'),
            dataIndex: 'FirstName',
            key: 'FirstName',
            sorter: true,
            width: 80,
        },
        {
            title: t('member.last_name'),
            dataIndex: 'LastName',
            key: 'LastName',
            sorter: true,
            defaultSortOrder: 'ascend',
            width: 80,
        },
        {
            title: t('member.financial_type'),
            dataIndex: 'FinancialTypeName',
            key: 'FinancialTypeName',
            sorter: true,
            width: 80,
        },
        {
            title: t('member.fishing_type'),
            dataIndex: 'FishingTypeName',
            key: 'FishingTypeName',
            sorter: true,
            ellipsis: true,
            width: 80,
        },
        {
            title: t('member.fishing_category'),
            dataIndex: 'FishingCategoryName',
            key: 'FishingCategoryName',
            sorter: true,
            width: 80,
        },
        {
            title: <span>{getMemberTitle()}</span>,
            dataIndex: 'MemberId',
            key: 'MemberId',
            sorter: true,
            width: 55,
        },
        {
            title: t('common.status'),
            dataIndex: 'MemberStatusId',
            key: 'MemberStatusId',
            sorter: false,
            align: 'center',
            width: 50,
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.MemberStatusId)}
                    description={CommonService.getStatusDescription(record.MemberStatusId, false)} />
            )
        }
    ]

    const clubColumns = [
        {
            title: t('common.action'),
            key: 'Id',
            render: (value, record) => {
                return <ActionButtonsCell btnList={getActionButtonsInfo(record)} data={record} />
            },
            align: "center",
            width: 38
        },
        {
            title: t('member.first_name'),
            dataIndex: 'FirstName',
            key: 'FirstName',
            sorter: true,
            width: 100,
        },
        {
            title: t('member.last_name'),
            dataIndex: 'LastName',
            key: 'LastName',
            sorter: true,
            defaultSortOrder: 'ascend',
            width: 100,
        },
        {
            title: t('member.financial_type'),
            dataIndex: 'FinancialTypeName',
            key: 'FinancialTypeName',
            sorter: true,
            width: 100,
        },
        {
            title: t('member.fishing_type'),
            dataIndex: 'FishingTypeName',
            key: 'FishingTypeName',
            sorter: true,
            ellipsis: true,
            width: 80,
        },
        {
            title: t('member.fishing_category'),
            dataIndex: 'FishingCategoryName',
            key: 'FishingCategoryName',
            sorter: true,
            width: 80,
        },
        {
            title: <span>{getMemberTitle()}</span>,
            dataIndex: 'MemberId',
            key: 'MemberId',
            sorter: true,
            width: 80,
        },
        {
            title: t('common.status'),
            dataIndex: 'MemberStatusId',
            key: 'MemberStatusId',
            sorter: false,
            align: 'center',
            width: 50,
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.MemberStatusId)}
                    description={CommonService.getStatusDescription(record.MemberStatusId, false)} />
            )
        }
    ]

    const onTableChange = (pagination, filters, sorter, extra) => {
        let data = formFilter.getFieldsValue();
        if (!data || (data && !data.MemberStatusIds)) {
            data.MemberStatusIds = [Constant.MemberStatusCode.Active];
        }
        setSearchMemberReport(data);

        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = gridConfigOptions.sort;
        }
        search(pagination.current, pagination.pageSize, sort, data);

        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        });
    }

    const showFilterLayout = () => {
        setOpenFilter(true);
    }

    const closeFilterLayout = () => {
        formFilter.setFieldsValue(searchMemberReport);
        setOpenFilter(false);
    }

    const refreshData = (isShowLoading = false) => {

        setSelectedRowKeys([]);
        setSelectedMembers([]);
        let data = formFilter.getFieldsValue();

        if (!data || (data && !data.MemberStatusIds)) {
            data.MemberStatusIds = [Constant.MemberStatusCode.Active];
        }
        setSearchMemberReport(data);

        search(1, gridConfigOptions.pageSize, gridConfigOptions.sort, data, isShowLoading);

        setGridConfigOptionsValue(
            {
                ...gridConfigOptions,
                pageNumber: 1
            }
        );

    }

    const resetFilter = () => {
        formFilter.resetFields();
        setSearchMemberReport({ MemberStatusIds: [Constant.MemberStatusCode.Active] });
    }

    const applyFilter = () => {

        refreshData(true);

        setOpenFilter(false);
    }

    const components = {
        body: {
            cell: EditableCell,
        }
    };
    const exportMemberReport = (fileType) => {
        showLoading();
        MembershipService.getExportMemberReport(gridConfigOptions.sort, formFilter.getFieldsValue(), fileType, reportFormat)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    if (fileType == Constant.FileType.Excel) {
                        const type = result.headers['content-type']
                        const blob = new Blob([result.data], { type: type })
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = `Member_Report_List.xlsx`
                        link.click()
                    } else if (fileType == Constant.FileType.Csv) {
                        const type = result.headers['content-type']
                        const blob = new Blob([result.data], { type: type })
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = `Member_Report_List.csv`
                        link.click()
                    }
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    const deleteMember = (record) => {
        if (!record.IsAddNew && record.Id > 0) {
            confirm({
                title: t("member.delete_member_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    showLoading();

                    MembershipService.deleteMemberships([record.Id])
                        .finally(() => {
                            dismissLoading();
                        })
                        .then(result => {
                            record.MemberStatusId = Constant.MemberStatusCode.Inactive;
                            setMembers(members);
                        })
                        .catch(error => {
                            CommonService.handleErrorResponse(error);
                        });
                }
            });
        } else {
            const newMembers = members.filter((m) => m.Id !== record.Id);
            setMembers(newMembers);
        }
    }

    const handleReporFormatChange = (value) => {
        if (value) {
            setError(false);
        }
        setReportFormat(value);
    };

    return (
        <>
            <div>
                <div id={Constant.FixItemsContainerId}>
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                        <h5 className='color-dark-blue'>{t("member.member_report")}</h5>
                    </Row>
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                        <Col flex={100} className="text-right">
                            <div>
                                <Button onClick={showFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon />}>{t("common.filter")}</Button>
                                <Dropdown className='m-l-10' menu={menuExport} trigger={['click']}>
                                    <Button className='btn-outline-blue btn-icon' icon={<DownloadOutlined />}>
                                        {t("common.export")}
                                    </Button>
                                </Dropdown>
                                <EnhancedSelect
                                    className="text-left"
                                    allowClear
                                    style={{
                                        width: 170,
                                        paddingLeft: '7px'
                                    }}
                                    placeholder="Report Format"
                                    onChange={handleReporFormatChange}
                                    status={error ? "error" : ""}
                                    options={[
                                        {
                                            value: 'Full',
                                            label: t("member.report_format_full"),
                                        },
                                        {
                                            value: 'GFAADetails',
                                            label: t("member.report_format_gfaa_details"),
                                        },
                                        {
                                            value: 'SMSAndEmail',
                                            label: t("member.report_format_sms_and_email"),
                                        },
                                    ]}
                                />
                                {error && <p style={{ color: 'red' }}>{t("member.report_report_error")}</p>}
                            </div>
                        </Col>
                    </Row>
                </div>

                <Row justify="center">
                    <span>{totalItems} {t("member.members_match_the_above_criteria")}</span>
                </Row>
                <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                    <Col xs={{ span: 24 }}>
                        <Table
                            components={components}
                            rowClassName={() => 'editable-row'}
                            {...GetTableConfigs_SimplePaginator(isClub() ? clubColumns : stateColumns, members, totalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                        />
                    </Col>
                </Row>

                <Drawer
                    title={t("common.filter")}
                    width={378}
                    onClose={closeFilterLayout}
                    open={openFilter}
                    closeIcon={false}
                    styles={{
                        body: {
                            paddingBottom: 80,
                        }
                    }}
                    extra={
                        <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                            <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                            <Button onClick={closeFilterLayout} icon={<CloseIcon />} type="link" />
                        </Space>
                    }
                    footer={
                        <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                            <Button onClick={closeFilterLayout}>{t("common.cancel")}</Button>
                            <Button onClick={applyFilter} type="primary">
                                {t("common.apply")}
                            </Button>
                        </Space>
                    }>
                    <Form
                        layout="vertical"
                        {...layout}
                        form={formFilter}
                        name="formFilter"
                        key='formFilter'
                        initialValues={{ MemberStatusIds: [Constant.MemberStatusCode.Active] }}>
                        <Row gutter={24} >
                            <Col span={24}>
                                <Form.Item
                                    name="EntityName"
                                    label={t("member.club")}
                                    hidden={isClubMemberAndClubCommittee()}
                                >
                                    <Input allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="FirstName"
                                    label={t("member.first_name")}
                                >
                                    <Input allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="LastName"
                                    label={t("member.last_name")}
                                >
                                    <Input allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="GenderIds"
                                    label={t("member.gender")}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={genders ? genders.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="FamilyGroupIds"
                                    label={t('member.family_group')}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={familyGroups ? familyGroups.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="FinancialTypeIds"
                                    label={t('member.financial_type')}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={financialTypes ? financialTypes.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="FinancialStatusIds"
                                    label={t("member.financial_status")}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={financialStatuses ? financialStatuses.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="FishingTypeIds"
                                    label={t("member.fishing_type")}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={fishingTypes ? fishingTypes.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="FishingCategoryIds"
                                    label={t("member.fishing_category")}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={fishingCategories ? fishingCategories.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="JoinedOrRenewedDateRange"
                                    label={t('member.joined_or_renewed_date_range')}
                                >
                                    <DatePicker.RangePicker cellRender={cellRender} format={Constant.DateFormat}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        {/* <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="FinancialEnd"
                                    label={t('member.financial_end')}
                                >
                                    <EnhancedSelect mode="single" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={financialYears ? financialYears.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Code
                                            }
                                        }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row> */}
                        
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="FinancialYearId"
                                    label={t("fishing_activity.financial_year")}
                                >
                                    <EnhancedSelect mode="single" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={financialYears ? financialYears.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="MemberStatusIds"
                                    label={t("common.status")}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={memberStatuses ? memberStatuses.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Drawer>

                {/* Create/Edit Member Layout  */}
                {
                    visibleModal &&
                    <CreateEditMembership
                        handleCancel={handleCancel}
                        titles={titles}
                        isEdit={isEdit}
                        genders={genders}
                        familyGroups={familyGroups}
                        fishingTypes={fishingTypes}
                        fishingCategories={fishingCategories}
                        financialTypes={financialTypes}
                        financialStatuses={financialStatuses}
                        positionTitles={positionTitles}
                        memberStatuses={memberStatuses}
                        states={states}
                        financialYears={financialYears}
                        selectedRowKeys={selectedRowKeys}
                        selectedMembers={selectedMembers} >

                    </CreateEditMembership>
                }
            </div>
        </>
    )
}

export default MemberReport;
import React, { useEffect, useState } from 'react';
import './memberFinancialDateManagement.scss';
import { useTranslation } from 'react-i18next';
import {
    Constant,
    CommonService,
    MembershipService
} from '../../../services/services'
import moment from 'moment';
import { Table, Button, Row, Col, Select, Form, Input, Breadcrumb, Drawer, Space, Empty } from 'antd';
import { GetTableConfigs_SimplePaginator, StatusCell, FinancialEndCell, GetTableConfigs } from '../../../components/customTable/customTable'
import { FilterAltIcon, CloseIcon } from '../../../components/icons/icons'
import LoadingContext from '../../../components/loading/loadingContext'
import EnhancedSelect from '../../../components/enhancedSelect/enhancedSelect';
import EditMemberFinancialDate from './editMemberFinancialDate';
import PubSub from 'pubsub-js';
import { useHistory } from "react-router-dom";
import { useGlobalState } from '../../../utilities/globalState'
const { Option } = Select;
const _ = require("lodash");

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const MemberFinancialDateManagement = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [formFilter] = Form.useForm();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext);
    const [totalItems, setTotalItems] = useState(0);
    const [members, setMembers] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [genders, setGenders] = useState([]);
    const [familyGroups, setFamilyGroups] = useState([]);
    const [fishingTypes, setFishingTypes] = useState([]);
    const [fishingCategories, setFishingCategories] = useState([]);
    const [financialTypes, setFinancialTypes] = useState([]);
    const [financialStatuses, setFinancialStatuses] = useState([]);
    const [financialYears, setFinancialYears] = useState([]);
    const [visibleModal, setVisibleModal] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [searchMemberFinancialDate, setSearchMemberFinancialDate] = useState({ MemberStatusIds: [Constant.MemberStatusCode.Active] });
    const [memberStatuses, setMemberStatuses] = useState([]);

    const [gridConfigOptions, setGridConfigOptionsValue] = useState({
        pageNumber: 1,
        pageSize: CommonService.getPageSize(),
        sort: "LastName-ascend",
    })
    const [currentPosition] = useGlobalState('currentPosition');

    const search = (pageNumber, pageSize, sort, query, isShowLoading = false) => {
        if (isShowLoading) {
            showLoading();
        }

        let params = { ...query };
        if (!params) params = { MemberStatusIds: [Constant.MemberStatusCode.Active] };

        if (params.FinancialEnd) {
            var financialEnd = null;
            var length = params.FinancialEnd.split('/').length;
            if (length >= 3) {
                financialEnd = moment(params.FinancialEnd, Constant.DateFormat);
            } else {
                financialEnd = moment(params.FinancialEnd)
            }
            params.FinancialEnd = financialEnd;
        }

        MembershipService.searchMemberships(pageNumber, pageSize, sort, params)
            .finally(() => {
                if (isShowLoading) {
                    dismissLoading();
                }

                setSelectedRowKeys([]);
                setSelectedMembers([]);
            })
            .then(result => {
                if (result.data) {
                    setMembers(result.data.ObjectList);
                    setTotalItems(result.data.TotalItems);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const editMembers = () => {
        setIsEdit(true);
        setVisibleModal(true);
    }

    const handleCancel = (refresh) => {
        setVisibleModal(false);
        if (refresh) {
            refreshData();
        }

    }

    const getMemberTitle = () => {
        return currentPosition != null && currentPosition.StateAssociationAcronym != null && currentPosition.StateAssociationAcronym.length > 0 ? currentPosition.StateAssociationAcronym + " #" : t('member.member_number');
    }

    const getStateClubTitle = () => {
        return t('member.club');
    }

    const isClub = () => {
        return currentPosition != null && currentPosition.EntityTypeId === Constant.EntityType.Club;
    }

    const isClubMemberAndClubCommittee = () => {
        return currentPosition != null && (currentPosition.PositionTypeId === Constant.PositionType.ClubMember || currentPosition.PositionTypeId === Constant.PositionType.ClubCommittee);
    }
    const stateColumns = [
        {
            title: <span>{getMemberTitle()}</span>,
            dataIndex: 'MemberId',
            key: 'MemberId',
            sorter: true,
            width: 55,
        },
        {
            title: <span>{getStateClubTitle()}</span>,
            dataIndex: 'EntityName',
            key: 'EntityName',
            sorter: false,
            width: 70,
        },
        {
            title: t('member.last_name'),
            dataIndex: 'LastName',
            key: 'LastName',
            sorter: true,
            defaultSortOrder: 'ascend',
            width: 60,
        },
        {
            title: t('member.first_name'),
            dataIndex: 'FirstName',
            key: 'FirstName',
            sorter: true,
            width: 60,
        },
        {
            title: t('member.family_group'),
            dataIndex: 'FamilyGroupName',
            key: 'FamilyGroupName',
            sorter: true,
            width: 60,
        },
        {
            title: t('member.financial_end'),
            dataIndex: 'FinancialEnd',
            key: 'FinancialEnd',
            sorter: false,
            ellipsis: true,
            width: 80,
            render: (value, record) => (
                <FinancialEndCell value={record.FinancialEnd} />
            )
        },
        {
            title: t('member.financial_type'),
            dataIndex: 'FinancialTypeName',
            key: 'FinancialTypeName',
            sorter: true,
            width: 70,
        },
        {
            title: t('member.financial_status'),
            dataIndex: 'FinancialStatusName',
            key: 'FinancialStatusName',
            sorter: true,
            width: 70,
        },
        {
            title: t('member.fishing_category'),
            dataIndex: 'FishingCategoryName',
            key: 'FishingCategoryName',
            sorter: true,
            ellipsis: true,
            width: 66,
        },
        {
            title: t('common.status'),
            dataIndex: 'MemberStatusId',
            key: 'MemberStatusId',
            sorter: false,
            align: 'center',
            width: 50,
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.MemberStatusId)}
                    description={CommonService.getStatusDescription(record.MemberStatusId, false)} />
            )
        }
    ]

    const clubColumns = [
        {
            title: <span>{getMemberTitle()}</span>,
            dataIndex: 'MemberId',
            key: 'MemberId',
            sorter: true,
            width: 45,
        },
        {
            title: t('member.last_name'),
            dataIndex: 'LastName',
            key: 'LastName',
            sorter: true,
            defaultSortOrder: 'ascend',
            width: 55,
        },
        {
            title: t('member.first_name'),
            dataIndex: 'FirstName',
            key: 'FirstName',
            sorter: true,
            width: 58,
        },
        {
            title: t('member.family_group'),
            dataIndex: 'FamilyGroupName',
            key: 'FamilyGroupName',
            sorter: true,
            width: 62,
        },
        {
            title: t('member.financial_end'),
            dataIndex: 'FinancialEnd',
            key: 'FinancialEnd',
            sorter: false,
            ellipsis: true,
            width: 80,
            render: (value, record) => (
                <FinancialEndCell value={record.FinancialEnd} />
            )
        },
        {
            title: t('member.financial_type'),
            dataIndex: 'FinancialTypeName',
            key: 'FinancialTypeName',
            sorter: true,
            width: 70,
        },
        {
            title: t('member.financial_status'),
            dataIndex: 'FinancialStatusName',
            key: 'FinancialStatusName',
            sorter: true,
            width: 70,
        },
        {
            title: t('member.fishing_category'),
            dataIndex: 'FishingCategoryName',
            key: 'FishingCategoryName',
            sorter: true,
            ellipsis: true,
            width: 66,
        },
        {
            title: t('common.status'),
            dataIndex: 'MemberStatusId',
            key: 'MemberStatusId',
            sorter: false,
            align: 'center',
            width: 50,
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.MemberStatusId)}
                    description={CommonService.getStatusDescription(record.MemberStatusId, false)} />
            )
        }
    ]

    const onTableChange = (pagination, filters, sorter, extra) => {
        let data = formFilter.getFieldsValue();
        setSearchMemberFinancialDate(data);

        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = gridConfigOptions.sort;
        }
        search(pagination.current, pagination.pageSize, sort, data);

        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        });
    }

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (newSelectedRowKeys, newSelectedRows) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setSelectedMembers(newSelectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: record.UserName === Constant.SuperUserName && currentPosition.EntityTypeId !== Constant.EntityType.Gamebase,
        }),
    }

    const showFilterLayout = () => {
        setOpenFilter(true);
    }

    const closeFilterLayout = () => {
        formFilter.setFieldsValue(searchMemberFinancialDate);
        setOpenFilter(false);
    }

    const refreshData = (isShowLoading = false) => {
        setSelectedRowKeys([]);
        setSelectedMembers([]);

        let data = formFilter.getFieldsValue();

        if (!data || (data && !data.MemberStatusIds)) {
            data.MemberStatusIds = [Constant.MemberStatusCode.Active];
        }

        setSearchMemberFinancialDate(data);

        search(1, gridConfigOptions.pageSize, gridConfigOptions.sort, data, isShowLoading);

        setGridConfigOptionsValue(
            {
                ...gridConfigOptions,
                pageNumber: 1
            }
        );

    }

    const resetFilter = () => {
        formFilter.resetFields();
        setSearchMemberFinancialDate({ MemberStatusIds: [Constant.MemberStatusCode.Active] });
    }

    const applyFilter = () => {

        refreshData(true);

        setOpenFilter(false);
    }


    useEffect(() => {

        if (currentPosition && currentPosition.PositionTypeId === Constant.PositionType.ClubMember){
            history.push('/unauthorised');
            return;
        }
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight())
        }, 100)

        showLoading();
        Promise.all([
            MembershipService.searchMemberships(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, { MemberStatusIds: [Constant.MemberStatusCode.Active] }),
            MembershipService.getFilterDatas()
        ])
            .finally(() => {
                dismissLoading();

                setSelectedRowKeys([]);
                setSelectedMembers([]);
                setSearchMemberFinancialDate({ MemberStatusIds: [Constant.MemberStatusCode.Active] });
            })
            .then((response) => {
                if (response[0].data) {
                    setMembers(response[0].data.ObjectList);
                    setTotalItems(response[0].data.TotalItems);
                }

                if (response[1].data) {
                    setGenders(response[1].data.Genders);
                    setFamilyGroups(response[1].data.FamilyGroups);
                    setFishingTypes(response[1].data.FishingTypes);
                    setFishingCategories(response[1].data.FishingCategories);
                    setFinancialTypes(response[1].data.FinancialTypes);
                    setFinancialStatuses(response[1].data.FinancialStatuses);
                    setFinancialYears(response[1].data.FinancialYears);
                    setMemberStatuses(response[1].data.MemberStatuses);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);

        return () => {
            MembershipService.cancelRequest();
            clearTimeout(timeoutFn);
        }
    }, [])

    return (

        <>
            {currentPosition && (currentPosition.PositionTypeId != Constant.PositionType.ClubMember) &&
                <div className="member-financial-date-page">
                    <Breadcrumb>
                        <Breadcrumb.Item>{t("menu.member_financial__end_date")}</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row id={Constant.FixItemsContainerId} className="fix-item-container">
                        <Col xs={{ span: 24 }} className="text-right extra-btn-m-t">
                            <div>
                                <Button onClick={showFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon />}>{t("common.filter")}</Button>

                                <Button id='btnEdit' disabled={selectedMembers.length === 0} className={selectedMembers.length > 0 ? "m-l-10 secondary-btn btn-outline-dark-blue" : "m-l-10 secondary-btn"} onClick={editMembers}>
                                    {t("common.edit")}
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    <Row className={`${isClub() ? 'm-t-10 club-section-table' : 'm-t-10 state-section-table'}`} gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Table
                                {...GetTableConfigs_SimplePaginator(isClub() ? clubColumns : stateColumns, members, totalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                                rowSelection={rowSelection}
                            />
                        </Col>
                    </Row>

                    {/* Filter Layout  */}
                    <Drawer
                        title={t("common.filter")}
                        width={378}
                        onClose={closeFilterLayout}
                        open={openFilter}
                        closeIcon={false}
                        styles={{
                            body: {
                                paddingBottom: 80,
                            }
                        }}
                        extra={
                            <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                                <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                                <Button onClick={closeFilterLayout} icon={<CloseIcon />} type="link" />
                            </Space>
                        }
                        footer={
                            <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                                <Button onClick={closeFilterLayout}>{t("common.cancel")}</Button>
                                <Button onClick={applyFilter} type="primary">
                                    {t("common.apply")}
                                </Button>
                            </Space>
                        }>
                        <Form
                            layout="vertical"
                            {...layout}
                            form={formFilter}
                            name="formFilter"
                            key='formFilter'
                            initialValues={{ MemberStatusIds: [Constant.MemberStatusCode.Active] }}>
                            <Row gutter={24} >
                                <Col span={24}>
                                    <Form.Item
                                        name="EntityName"
                                        label={t("member.club")}
                                        hidden={isClubMemberAndClubCommittee()}
                                    >
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="LastName"
                                        label={t("member.last_name")}
                                    >
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="FirstName"
                                        label={t("member.first_name")}
                                    >
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="FamilyGroupIds"
                                        label={t('member.family_group')}
                                    >
                                        <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={familyGroups ? familyGroups.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}>
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="FinancialEnd"
                                        label={t('member.financial_end')}
                                    >
                                        <EnhancedSelect mode="single" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={financialYears ? financialYears.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Code
                                                }
                                            }) : []}>
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="FinancialTypeIds"
                                        label={t('member.financial_type')}
                                    >
                                        <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={financialTypes ? financialTypes.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}>
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="FinancialStatusIds"
                                        label={t("member.financial_status")}
                                    >
                                        <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={financialStatuses ? financialStatuses.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}>
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="FishingCategoryIds"
                                        label={t("member.fishing_category")}
                                    >
                                        <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={fishingCategories ? fishingCategories.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}>
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="MemberStatusIds"
                                        label={t("common.status")}
                                    >
                                        <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={memberStatuses ? memberStatuses.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}>
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Drawer>
                </div>
            }

            {
                visibleModal &&
                <EditMemberFinancialDate
                    handleCancel={handleCancel}
                    isEdit={isEdit}
                    genders={genders}
                    familyGroups={familyGroups}
                    fishingTypes={fishingTypes}
                    fishingCategories={fishingCategories}
                    financialTypes={financialTypes}
                    financialStatuses={financialStatuses}
                    financialYears={financialYears}
                    selectedRowKeys={selectedRowKeys}
                    selectedMembers={selectedMembers} >

                </EditMemberFinancialDate>
            }
        </>
    )
}

export default MemberFinancialDateManagement;
import axios from 'axios';
import { axiosPortal } from './authService'
const source = [];
let baseURL = "api/Tournament"

export const TournamentService = {
    getTournaments: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/Tournaments?PageNumber=${pageNumber ? pageNumber : 1}`

        if (sort) {
            url += `&Sort=${sort}`
        }
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (params) {
            if (params.TournamentIds) {
                url += `&TournamentIds=${params.TournamentIds}`;
            }
            if (params.EntityIds) {
                url += `&EntityIds=${params.EntityIds}`;
            }
            if (params.StateZoneIds) {
                url += `&StateZoneIds=${params.StateZoneIds}`;
            }
            if (params.Title) {
                url += `&Title=${params.Title}`;
            }
            if (params.StartDate) {
                let date = `${params.StartDate.year()}-${params.StartDate.month() + 1}-${params.StartDate.date()}`
                url += `&StartDate=${date}`;
            }
            if (params.FinishDate) {
                let date = `${params.FinishDate.year()}-${params.FinishDate.month() + 1}-${params.FinishDate.date()}`
                url += `&FinishDate=${date}`;
            }
            if (params.AlternateStartDate) {
                let date = `${params.AlternateStartDate.year()}-${params.AlternateStartDate.month() + 1}-${params.AlternateStartDate.date()}`
                url += `&AlternateStartDate=${date}`;
            }
            if (params.AlternateFinishDate) {
                let date = `${params.AlternateFinishDate.year()}-${params.AlternateFinishDate.month() + 1}-${params.AlternateFinishDate.date()}`
                url += `&AlternateFinishDate=${date}`;
            }
            if (params.IsApplyForTournamentSanctioning) {
                url += `&IsApplyForTournamentSanctioning=${params.IsApplyForTournamentSanctioning}`;
            }
            if(params.StatusIds){
                url += `&StatusIds=${params.StatusIds}`;
            }
            if (params.IsApplyForDateApprovals) {
                url += `&IsApplyForDateApprovals=${params.IsApplyForDateApprovals}`;
            }
            if (params.IsTournamentSanctioningApprovals) {
                url += `&IsTournamentSanctioningApprovals=${params.IsTournamentSanctioningApprovals}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getFilterDatas: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/FilterDatas`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    addUpdateTournament: function (tournament) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/AddUpdateTournament`
        return axiosPortal.post(url, tournament, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    updateTournamentStatuses: function (tournaments) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/UpdateTournamentStatuses`

        return axiosPortal.post(url, tournaments, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    deleteTournaments: function (tournamentIds) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/DeleteTournaments`

        return axiosPortal.post(url, tournamentIds, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    updateTournamentDatesApprovedStatuses: function (tournaments) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/UpdateTournamentDatesApprovedStatuses`
        return axiosPortal.post(url, tournaments, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    updateTournamentApprovalNotes: function (tournamentId, approvalNotes) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/UpdateTournamentApprovalNotes`
        return axiosPortal.post(url, {Id: tournamentId, ApprovalNotes: approvalNotes}, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    updateTournamentSanctioningApplication: function (tournament) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/TournamentSanctioningApplication`
        return axiosPortal.post(url, tournament, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    updateTournament: function (tournament) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/Tournament`
        return axiosPortal.put(url, tournament, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    deleteTournament: function (tournamentId) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/DeleteTournament?tournamentId=${tournamentId}`
        return axiosPortal.post(url, tournamentId, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getTournamentList: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/Tournaments?PageNumber=${pageNumber ? pageNumber : 1}`

        if (sort) {
            url += `&Sort=${sort}`
        }
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (params) {
            if (params.TournamentIds) {
                url += `&TournamentIds=${params.TournamentIds}`;
            }
            if (params.EntityIds) {
                url += `&EntityIds=${params.EntityIds}`;
            }
            if (params.StateZoneIds) {
                url += `&StateZoneIds=${params.StateZoneIds}`;
            }
            if (params.Title) {
                url += `&Title=${params.Title}`;
            }
            if (params.StartDate) {
                let date = `${params.StartDate.year()}-${params.StartDate.month() + 1}-${params.StartDate.date()}`
                url += `&StartDate=${date}`;
            }
            if (params.FinishDate) {
                let date = `${params.FinishDate.year()}-${params.FinishDate.month() + 1}-${params.FinishDate.date()}`
                url += `&FinishDate=${date}`;
            }
            if (params.AlternateStartDate) {
                url += `&AlternateStartDate=${params.AlternateStartDate}`;
            }
            if (params.AlternateFinishDate) {
                url += `&AlternateFinishDate=${params.AlternateFinishDate}`;
            }
            if (params.IsApplyForTournamentSanctioning) {
                url += `&IsApplyForTournamentSanctioning=${params.IsApplyForTournamentSanctioning}`;
            }
            if(params.StatusIds){
                url += `&StatusIds=${params.StatusIds}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    updateTournamentSanctioningApprovals: function (tournaments) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/UpdateTournamentSanctioningApprovals`
        return axiosPortal.put(url, tournaments, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    cancelRequest: function () {
        if (source && source.length > 0) {
            for (var i = 0; i < source.length; i++) {
                source[i].cancel('Operation canceled.')
            }
            source.splice(0, source.length)
        }
    }
}
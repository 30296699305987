import React from 'react';
import BasePointScore from '../BasePointScore/basePointScore';
import { Constant } from '../../../services/services';
import { useTranslation } from 'react-i18next';

const TagRPointScore = (props) => {
    const { t } = useTranslation();

    return (
        <BasePointScore {...props} tagCaptureCode={Constant.TagCaptureCode.T_R}
            clubTitle={t("point_score.club_tag_r_point_score")}
            stateTitle={t("point_score.state_tag_r_point_score")}>
        </BasePointScore>
    )
}

export default TagRPointScore;
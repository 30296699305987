import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingContext from '../../components/loading/loadingContext'
import { CommonService, MembershipService, StorageService } from '../../services/services'
import { Button, Form, InputNumber, Modal, Col, Row } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { confirm } = Modal;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const AccountSetting = (props) => {
    const { t } = useTranslation();
    const [accountSettingForm] = Form.useForm();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        var authData = StorageService.getAuthData()
        accountSettingForm.setFieldsValue({
            PageSize: authData.pageSize
        })
    }, [])

    const canSubmit = () => {
        let data = accountSettingForm.getFieldsValue()
        if (isDirty && data && (data.PageSize == null || data.PageSize > 0)) {
            return true
        }

        return false
    }

    const onSubmit = () => {
        if (canSubmit()) {
            showLoading()
            let data = accountSettingForm.getFieldsValue()
            MembershipService.updateAccountSetting(data)
                .finally(() => dismissLoading())
                .then(result => {
                    CommonService.presentToast('success', t("login.account_setting_update_successful"))
                    var authData = StorageService.getAuthData()
                    authData.pageSize = data.PageSize
                    StorageService.setAuthData(authData)
                    props.handleCancel()
                    window.location.reload();
                })
                .catch(error => CommonService.handleErrorResponse(error))
        }
    }

    const closeForm = () => {
        if (isDirty) {
            confirm({
                title: t("common.confirm"),
                icon: <ExclamationCircleOutlined />,
                content: t("common.submit_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    if (canSubmit()) {
                        onSubmit()
                    }
                },
                onCancel() {
                    props.handleCancel()
                }
            });
        } else {
            props.handleCancel()
        }
    }

    const onFormValuesChange = (changedValues, allValues) => {
        setIsDirty(true)
    }

    return (
        <div>
            <Modal
                title={t("login.account_setting")}
                open={true}
                onCancel={closeForm}
                confirmLoading={false}
                maskClosable={false}
                centered
                width={400}
                footer={[
                    <Button key={0} size="large" onClick={closeForm}>{t("common.cancel")}</Button>,
                    <Button key={1} size="large" type="primary" disabled={!canSubmit()} onClick={onSubmit}>{t("common.submit")}</Button>
                ]}
            >
                <Form
                    layout="vertical"
                    {...layout}
                    form={accountSettingForm}
                    name="accountSettingForm"
                    key='accountSettingForm'
                    onFinish={onSubmit}
                    onValuesChange={onFormValuesChange}>
                    <Row className='m-b-30'>
                        <Col xs={{ span: 24 }}>
                            <Form.Item
                                label={t("login.page_size")}
                                name="PageSize" colon={false} labelAlign="left"
                            >
                                <InputNumber min={1} max={9999} allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default AccountSetting
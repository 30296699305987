import axios from 'axios';
import { axiosPortal } from './authService'

const source = [];

export const UserService = {
    getClubContactDetails: function (username, isMemberNumber) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `api/ClubContactDetails?username=${username}&isMemberNumber=${isMemberNumber}`

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getMenuTypes: function () {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `api/MenuTypes`

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    changePassword: function (password, confirmPassword) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `api/Account/ChangePassword`
        let data = {
            Password: password,
            ConfirmPassword: confirmPassword
        }

        return axiosPortal.post(url, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getAppMenus: function () {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `api/AllMenus`

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    cancelRequest: function () {
        if (source && source.length > 0) {
            for (var i = 0; i < source.length; i++) {
                source[i].cancel('Operation canceled.')
            }
            source.splice(0, source.length)
        }
    }
}
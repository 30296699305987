import React, { useState, useEffect } from 'react';
import { Constant } from '../../../services/services';
import { useTranslation } from 'react-i18next';
import { DateTimeCell } from '../../../components/customTable/customTable'
import LoadingContext from '../../../components/loading/loadingContext'
import { Row, Col, Table, Button, Form, Typography, Avatar, Card } from 'antd';

const { Title } = Typography;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const ViewDocumentItem = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext);

    const documentColumns = [
        {
            title: '',
            key: 'Id',
            render: (value, record) => {
                return <>
                    {
                        record.SponsorLogoUrl &&
                        <Avatar className='custom-logo' src={<img src={record.SponsorLogoUrl} alt="avatar" />} />
                    }
                </>
            },
            align: 'center',
            width: 50,
        },
        {
            title: t('document.title'),
            dataIndex: 'Title',
            key: 'Title',
            width: 120,
        },
        {
            title: t('document.file_name'),
            dataIndex: 'FileName',
            key: 'FileName',
            render: (value, record) => {
                return <>
                    {
                        <Button className={`m-l-5 m-r-5 action-btn `}
                        type='link'
                        target="_blank"
                        href={`${record.Url ? record.Url : ""}`} >
                        {record.FileName}
                        </Button>
                    }
                </>
            },
            width: 200,
        },
        {
            title: t('document.date'),
            dataIndex: 'DocumentDate',
            key: 'DocumentDate',
            align: 'center',
            width: 70,
            render: (value, record) => (
                <DateTimeCell value={record.DocumentDate} />
            )
        }
    ]

    const tournanmentColumns = [
        {
            title: '',
            key: 'Id',
            render: (value, record) => {
                return <>
                    {
                        record.SponsorLogoUrl &&
                        <Avatar className='custom-logo' src={<img src={record.SponsorLogoUrl} alt="avatar" />} />
                    }
                </>
            },
            align: 'center',
            width: 50,
        },
        {
            title: t('document.title'),
            dataIndex: 'Title',
            key: 'Title',
            width: 120,
        },
        {
            title: t('sanctioning_applications.flyer'),
            key: 'FlyerUrl',
            dataIndex: 'FlyerUrl',
            render: (value, record) => {
                return <>
                    {
                        record.FlyerUrl &&
                        <Button className={`m-l-5 m-r-5 action-btn `}
                        type='link'
                        target="_blank"
                        href={record.FlyerUrl} >
                            {t('sanctioning_applications.flyer')}
                        </Button>
                    }
                </>
            },
            align: 'center',
            width: 60,
        },
        {
            title: t('sanctioning_applications.rules'),
            key: 'RulesUrl',
            dataIndex: 'RulesUrl',
            render: (value, record) => {
                return <>
                    {
                        record.RulesUrl &&
                        <Button className={`m-l-5 m-r-5 action-btn `}
                        type='link'
                        target="_blank"
                        href={record.RulesUrl} >
                            {t('sanctioning_applications.rules')}
                        </Button>
                    }
                </>
            },
            align: 'center',
            width: 60,
        },
        {
            title: t('sanctioning_applications.entry_form'),
            key: 'EntryFormUrl',
            dataIndex: 'EntryFormUrl',
            render: (value, record) => {
                return <>
                    {
                        record.EntryFormUrl &&
                        <Button className={`m-l-5 m-r-5 action-btn `}
                        type='link'
                        target="_blank"
                        href={record.EntryFormUrl} >
                            {t('sanctioning_applications.entry_form')}
                        </Button>
                    }
                </>
            },
            align: 'center',
            width: 60,
        },
        {
            title: t('document.date'),
            dataIndex: 'StartDate',
            key: 'StartDate',
            align: 'center',
            width: 70,
            render: (value, record) => (
                <DateTimeCell value={record.StartDate} />
            )
        }
    ]

    useEffect(() => {
       
    }, [])

    return (
        <>
             <Form
                {...layout}
                name={'documentForm' + props.id}
                key={'documentForm' + props.id}>
                <Card key={props.id} style={{ width: "100%" }} className='document-item-card-view'>
                    {/* Documents */}
                    {props.isDocument && 
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                            <Col xs={{ span: 24 }}>
                                <Title className='text-center color-dark-blue' level={5}>{props.documentCategory.Name}</Title>
                            </Col>
                            <Col xs={{ span: 24 }}>
                                {!props.documentCategory.Documents || props.documentCategory.Documents.length == 0 &&
                                    <div className='text-center'>{t('document.no_documents_found')}</div>
                                }
                                {props.documentCategory.Documents && props.documentCategory.Documents.length > 0 &&
                                <Table
                                    tableLayout='fixed'
                                    showHeader={false}
                                    bordered={false}
                                    columns={documentColumns}
                                    dataSource={props.documentCategory.Documents}
                                    pagination={false}
                                />
                                }
                            </Col>
                        </Row>
                    }

                    {/* Tournaments */}
                    {!props.isDocument && 
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                            <Col xs={{ span: 24 }}>
                                <Title className='text-center color-dark-blue' level={5}>{t('document.tournament_flyer_forms')}</Title>
                            </Col>
                            <Col xs={{ span: 24 }}>
                                {!props.tournaments || props.tournaments.length == 0 &&
                                    <div className='text-center'>{t('document.no_documents_found')}</div>
                                }
                                {props.tournaments && props.tournaments.length > 0 &&
                                <Table
                                    tableLayout='fixed'
                                    showHeader={false}
                                    bordered={false}
                                    columns={tournanmentColumns}
                                    dataSource={props.tournaments}
                                    pagination={false}
                                />
                                }
                            </Col>
                        </Row>
                    }
                    
                </Card>
            </Form>
        </>

    )

}

export default ViewDocumentItem;
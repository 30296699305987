import React, { useState, useEffect } from 'react';
import { Constant, CommonService, MembershipService, SettingService, FishingActivityService } from '../../services/services';
import { useTranslation } from 'react-i18next';
import LoadingContext from '../../components/loading/loadingContext'
import { Row, Col, DatePicker, Button, Dropdown, Checkbox, Form, Drawer, Space, Input, Select, Typography, Empty } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useGlobalState } from '../../utilities/globalState'
import EnhancedSelect from '../../components/enhancedSelect/enhancedSelect';

import PubSub from 'pubsub-js'

const { Option } = Select;
const { Title } = Typography;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const ExportActivity = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [formFilter] = Form.useForm();
    const [clubEntities, setClubEntities] = useState([])
    const [currentPosition] = useGlobalState('currentPosition')
    const [filterData, setFilterData] = useState({})
    const [isAllData, setIsAllData] = useState(false)

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
        }, 100)

        showLoading()
        FishingActivityService.getActivityHeaderExportFilterData()
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    setFilterData(result.data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);
        return () => {
            MembershipService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    const exportActivity = (e) => {
        var filter = formFilter.getFieldsValue()
        showLoading()

        FishingActivityService.getExportActivityReport(filter, e.key, isAllData)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    if (e.key == Constant.FileType.Excel) {
                        const type = result.headers['content-type']
                        const blob = new Blob([result.data], { type: type })
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = `Activity_Report.xlsx`
                        link.click()
                    } else if (e.key == Constant.FileType.Csv) {
                        const type = result.headers['content-type']
                        const blob = new Blob([result.data], { type: type })
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = `Activity_Report.csv`
                        link.click()
                    }
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    const exportItems = [
        {
            label: t("common.excel"),
            key: Constant.FileType.Excel
        },
        {
            label: t("common.csv"),
            key: Constant.FileType.Csv
        }
    ];
    const menuExport = {
        items: exportItems,
        onClick: exportActivity
    };

    const showAllDataChanged = (check) => {
        setIsAllData(check)
    };

    const reset = () => {
        formFilter.resetFields()
        setIsAllData(false)
    }

    return (
        <div>
            <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                <Col xs={{ span: 24 }}>
                    <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                        <Title className='color-dark-blue' level={5}>{t("fishing_activity.export_activity_title")}</Title>
                    </Space>
                </Col>
            </Row>
            <Form
                layout="vertical"
                {...layout}
                name='formFilter'
                key='formFilter'
                form={formFilter}
                onFinish={exportActivity}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            label={t("fishing_activity.boat_name")}
                            name="BoatIds" colon={false} labelAlign="left">
                            <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                maxTagCount='responsive'
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={filterData && filterData.Boats ? filterData.Boats.map(b => {
                                    return {
                                        label: b.Name,
                                        value: b.Id
                                    }
                                }) : []}
                            >
                            </EnhancedSelect>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={t("fishing_activity.captain")}
                            name="BoatCaptainIds" colon={false} labelAlign="left">
                            <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                maxTagCount='responsive'
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={filterData && filterData.BoatCaptains ? filterData.BoatCaptains.map(b => {
                                    return {
                                        label: b.Name,
                                        value: b.Id
                                    }
                                }) : []}>
                            </EnhancedSelect>
                        </Form.Item>
                    </Col>
                    {
                        currentPosition && (currentPosition.PositionTypeId == Constant.PositionType.ClubMember || currentPosition.PositionTypeId == Constant.PositionType.StateExcutive || currentPosition.PositionTypeId == Constant.PositionType.NationalExecutive) &&
                        (
                            <Col span={12}>
                                <Form.Item
                                    label={t("fishing_activity.club")}
                                    name="EntityIds" colon={false} labelAlign="left">
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={filterData && filterData.Entities ? filterData.Entities.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        )
                    }
                    <Col span={6}>
                        <Form.Item
                            label={t("fishing_activity.date_from")}
                            name="ActivityDateFrom" colon={false} labelAlign="left">
                            <DatePicker format={Constant.DateFormat} allowClear style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={t("fishing_activity.date_to")}
                            name="ActivityDateTo" colon={false} labelAlign="left">
                            <DatePicker format={Constant.DateFormat} allowClear style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={t("fishing_activity.club_special_event")}
                            name="ClubEventIds" colon={false} labelAlign="left">
                            <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                maxTagCount='responsive'
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={filterData && filterData.ClubEvents ? filterData.ClubEvents.map(b => {
                                    return {
                                        label: b.Name,
                                        value: b.Id
                                    }
                                }) : []}>
                            </EnhancedSelect>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={t("fishing_activity.state_special_event")}
                            name="StateEventIds" colon={false} labelAlign="left">
                            <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                maxTagCount='responsive'
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={filterData && filterData.StateEvents ? filterData.StateEvents.map(b => {
                                    return {
                                        label: b.Name,
                                        value: b.Id
                                    }
                                }) : []}>
                            </EnhancedSelect>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="DayLogId"
                            label={t("fishing_activity.day_log_id")}
                        >
                            <Input allowClear />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="ActivitySheetNumber"
                            label={t("fishing_activity.activity_sheet_number")}
                        >
                            <Input allowClear />
                        </Form.Item>
                    </Col>
                    {
                        currentPosition && (currentPosition.PositionTypeId == Constant.PositionType.ClubMember || currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee) &&
                        (
                            <Col span={6}>
                                <Form.Item
                                    label={t("fishing_activity.financial_year")}
                                    name="FinancialYearIds" colon={false} labelAlign="left">
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={filterData && filterData.FinancialYears ? filterData.FinancialYears.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        )
                    }
                    {
                        currentPosition && (currentPosition.PositionTypeId == Constant.PositionType.StateExcutive || currentPosition.PositionTypeId == Constant.PositionType.NationalExecutive) &&
                        (
                            <Col span={12}>
                                <Form.Item
                                    label={t("fishing_activity.financial_year")}
                                    name="FinancialYearIds" colon={false} labelAlign="left">
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={filterData && filterData.FinancialYears ? filterData.FinancialYears.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        )
                    }
                    <Col span={6}>
                        <Form.Item
                            label={t("family_group.fishing_membership_type")}
                            name="FishingTypeIds" colon={false} labelAlign="left">
                            <EnhancedSelect mode="multiple" allowClear={true}
                                maxTagCount='responsive'
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={filterData && filterData.FishingTypes ? filterData.FishingTypes.map(b => {
                                    return {
                                        label: b.Name,
                                        value: b.Id
                                    }
                                }) : []}>
                            </EnhancedSelect>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={t("member.fishing_category")}
                            name="FishingCategoryIds" colon={false} labelAlign="left">
                            <EnhancedSelect mode="multiple" allowClear={true}
                                maxTagCount='responsive'
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={filterData && filterData.FishingCategories ? filterData.FishingCategories.map(b => {
                                    return {
                                        label: b.Name,
                                        value: b.Id
                                    }
                                }) : []}>
                            </EnhancedSelect>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={t("member.gender")}
                            name="GenderIds" colon={false} labelAlign="left">
                            <EnhancedSelect mode="multiple" allowClear={true}
                                maxTagCount='responsive'
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={filterData && filterData.Genders ? filterData.Genders.map(b => {
                                    return {
                                        label: b.Name,
                                        value: b.Id
                                    }
                                }) : []}>
                            </EnhancedSelect>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={t("fishing_activity.created_by")}
                            name="CreatedBys" colon={false} labelAlign="left">
                            <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                maxTagCount='responsive'
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={filterData && filterData.LineCreatedBys ? filterData.LineCreatedBys.map(b => {
                                    return {
                                        label: b.Name,
                                        value: b.Name
                                    }
                                }) : []}>
                            </EnhancedSelect>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={t("fishing_activity.club_point_score_zone")}
                            name="ClubPointScoreZoneIds" colon={false} labelAlign="left">
                            <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                maxTagCount='responsive'
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={filterData && filterData.ClubPointScoreZones ? filterData.ClubPointScoreZones.map(b => {
                                    return {
                                        label: b.Name,
                                        value: b.Id
                                    }
                                }) : []}>
                            </EnhancedSelect>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={t("fishing_activity.state_point_score_zone")}
                            name="StatePointScoreZoneIds" colon={false} labelAlign="left">
                            <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                maxTagCount='responsive'
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={filterData && filterData.StatePointScoreZones ? filterData.StatePointScoreZones.map(b => {
                                    return {
                                        label: b.Name,
                                        value: b.Id
                                    }
                                }) : []}>
                            </EnhancedSelect>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={currentPosition && (currentPosition.PositionTypeId == Constant.PositionType.ClubMember || currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee) ? t("fishing_activity.club_approval_status") : t("fishing_activity.state_approval_status")}
                            name="ClubApprovalStatusIds" colon={false} labelAlign="left">
                            <EnhancedSelect mode="multiple" allowClear={true}
                                maxTagCount='responsive'
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={filterData && filterData.ApprovalStatuses ? filterData.ApprovalStatuses.map(b => {
                                    return {
                                        label: b.Name,
                                        value: b.Id
                                    }
                                }) : []}>
                            </EnhancedSelect>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={t("fishing_activity.angler")}
                            name="AnglerIds" colon={false} labelAlign="left">
                            <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                maxTagCount='responsive'
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={filterData && filterData.Anglers ? filterData.Anglers.map(b => {
                                    return {
                                        label: b.Name,
                                        value: b.Id
                                    }
                                }) : []}>
                            </EnhancedSelect>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                    <Col flex={100} className="text-right">
                        <div>
                            <Checkbox onChange={e => showAllDataChanged(e.target.checked)} checked={isAllData}>{t("fishing_activity.show_all_data")}</Checkbox>
                            <Button size="large" onClick={reset}>{t("common.reset")}</Button>
                            <Dropdown className='m-l-10' menu={menuExport} trigger={['click']}>
                                <Button size="large" type="primary" className='btn-icon' icon={<DownloadOutlined />}>
                                    {t("common.export")}
                                </Button>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ExportActivity;
import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Button, Input, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import LoadingContext from '../../../components/loading/loadingContext'
import { TournamentService, CommonService, Constant } from '../../../services/services'

const { confirm } = Modal;
const _ = require("lodash");
const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};


const DateApprovalsNote = (props) => {
    const { t } = useTranslation();
    const [notesForm] = Form.useForm();

    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [approvalNotes, setApprovalNotes] = useState(null);
    // const [tournament, setTournament] = useState({});

    const [enableSubmit, setEnableSubmit] = useState(false);

    const onSubmit = () => {
        // Update Approval Notes
        var changed = false;
        if (approvalNotes != props.selectedTournament.ApprovalNotes) {
            changed = true;
        }

        _.update(props.selectedTournament, "ApprovalNotes", function (n) {
            return approvalNotes;
        });
        props.handleCancel(props.selectedTournament, changed);

        // showLoading();
        // TournamentService.updateTournamentApprovalNotes(tournament.Id, approvalNotes)
        //     .finally(() => {
        //         dismissLoading();
        //     })
        //     .then(result => {
        //         if (result.data) {
        //             CommonService.presentToast('success', t('tournament.update_approval_notes_successful'));

        //             _.update(tournament, "ApprovalNotes", function (n) {
        //                 return approvalNotes;
        //             });
        //             props.handleCancel(tournament, true);
        //         }
        //     })
        //     .catch(error => {
        //         CommonService.handleErrorResponse(error);
        //     });
    }

    const submitConfirmation = () => {
        var changed = false;
        if (approvalNotes != props.selectedTournament.ApprovalNotes) {
            changed = true;
        }

        if (changed) {
            confirm({
                title: t("common.submit_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    onSubmit();
                },
                onCancel() {
                    _.update(props.selectedTournament, "ApprovalNotes", function (n) {
                        return props.selectedTournament.ApprovalNotes;
                    });
                    props.handleCancel(props.selectedTournament, false);
                }
            });
        }
        else {
            _.update(props.selectedTournament, "ApprovalNotes", function (n) {
                return props.selectedTournament.ApprovalNotes;
            });
            props.handleCancel(props.selectedTournament, false);
        }
    }

    const onClose = () => {
        submitConfirmation();
    }

    const canSubmit = (newApprovalNotes) => {
        if (newApprovalNotes && newApprovalNotes.length > 0) {
            return true;
        }
        return false;
    }

    const handleApprovalNotesOnChange = (e) => {
        setApprovalNotes(e.target.value);
        setEnableSubmit(canSubmit(e.target.value));
    }

    useEffect(() => {
        setApprovalNotes(props.selectedTournament.ApprovalNotes);
        notesForm.setFieldsValue(props.selectedTournament);
        setEnableSubmit(canSubmit(props.selectedTournament.ApprovalNotes));
    }, [])

    return (
        <div>
            <Modal
                title={t("tournament.tournament_approval_notes")}
                open={true}
                onCancel={onClose}
                confirmLoading={false}
                maskClosable={false}
                centered
                width={540}
                footer={[
                    <Button key={0} size="large" onClick={onClose}>{t("common.cancel")}</Button>,
                    <Button key={1} size="large" type="primary" disabled={!enableSubmit} onClick={onSubmit}>{t("common.ok")}</Button>
                ]}
            >
                <Form
                    {...layout}
                    name='notesForm'
                    key='notesForm'
                    onFinish={onSubmit}
                    component={false}
                    form={notesForm}>

                    <Row id={Constant.FixItemsContainerId} className="fix-item-container">
                        <Col span={24}>
                            <Form.Item
                                name="ApprovalNotes" colon={false} labelAlign="left">
                                <TextArea rows={6} onChange={handleApprovalNotesOnChange}  />
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>

            </Modal>
        </div>
    )
}

export default DateApprovalsNote
import React, { useEffect, useState } from 'react';
import './boatCategoryManagement.scss';
import { useTranslation } from 'react-i18next';
import {
    Constant,
    CommonService,
    SettingService
} from '../../../services/services'
import { Table, Button, Row, Col, Form, Modal } from 'antd';
import { GetTableConfigs, EditableCell, ActionButtonsCell } from '../../../components/customTable/customTable'
import { TrashIcon } from '../../../components/icons/icons'
import LoadingContext from '../../../components/loading/loadingContext'

const { confirm } = Modal;
const _ = require("lodash");

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const components = {
    body: {
        cell: EditableCell,
    },
};

const CreateEditBoatCategory = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { showLoading, dismissLoading } = React.useContext(LoadingContext);
    const [originalBoatCategories, setOriginalBoatCategories] = useState([]);
    const [boatCategories, setBoatCategories] = useState([]);
    const [allBoatCategories, setAllBoatCategories] = useState([]);
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [form] = Form.useForm();
   
    const onSubmit = () => {
        // Validate
        var allErrorMessages = [];
        setBoatCategories(current =>
            current.map(obj => {
                var errorMessages = [];
                if (!obj.Name) {
                    errorMessages.push({
                        key: 'Name',
                        value: t('boat.boat_category_is_required')
                    })
                }

                if (!obj.StatusId) {
                    errorMessages.push({
                        key: 'StatusId',
                        value: t('common.status_is_required')
                    })
                }

                // Exists
                var existBoatCategories = allBoatCategories.filter(x => x.Id !== obj.Id && x.Name === obj.Name);
                if (existBoatCategories !== null && existBoatCategories.length > 0) {
                    errorMessages.push({
                        key: 'Name',
                        value: t('boat.boat_category_exist')
                    })
                }

                allErrorMessages.push(...errorMessages);
                return {
                    ...obj,
                    ErrorMessages: errorMessages,
                };
            }),
        );

        if (allErrorMessages.length > 0) {
            return;
        }

        // Add/Update
        showLoading();
        SettingService.addUpdateBoatCategories(boatCategories)
            .finally(() => {
                dismissLoading();
            })
            .then(result => {
                if (result.data) {
                    CommonService.presentToast('success', props.isEdit ? t('boat.update_successful') : t('boat.create_successful'));
                    props.handleCancel(true);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const canSubmit = (newBoatCategories) => {
        if (newBoatCategories && newBoatCategories.length > 0) {
            let inValid = newBoatCategories.filter(x => !x.Name);
            if (inValid && inValid.length > 0) {
                return false
            }
            return true;
        }
        return false;
    }

    const submitConfirmation = () => {
        function customizer(newBoatCategories, oldBoatCategories) {
            if (newBoatCategories.length !== oldBoatCategories.length)
                return true;

            for (var i = 0; i < newBoatCategories.length; i ++) {
                if (((newBoatCategories[i].Name !== null || oldBoatCategories[i].Name !== null) && newBoatCategories[i].Name !== oldBoatCategories[i].Name) ||
                    ((newBoatCategories[i].Description !== null || oldBoatCategories[i].Description !== null) && newBoatCategories[i].Description !== oldBoatCategories[i].Description)) {
                    return true;
                }
            }

            return false;
        }
        
        var newBoatCategories = _.sortBy(boatCategories, [function(m) { return m.Id; }]);
        var oldBoatCategories = _.sortBy(originalBoatCategories, [function(m) { return m.Id; }]);
        var changed = _.isEqualWith(newBoatCategories, oldBoatCategories, customizer);
        if (changed) {
            confirm({
                title: t("common.submit_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    onSubmit();
                },
                onCancel() {
                    props.handleCancel(true);
                }
            });
        }
        else {
            props.handleCancel(false);
        }
    }

    const onClose = () => {
        submitConfirmation();
    }

    const addBoatCategory = () => {
        var recordId = (boatCategories.length + 1);
        var id = -recordId;
        setBoatCategories([...boatCategories, newBoatCategory(null, id)]);
    }
    
    const deleteBoatCategory = (record) => {
        if (!record.IsAddNew && record.Id > 0) {
            confirm({
                title: t("boat.delete_club_boat_category_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    showLoading();
    
                    SettingService.deleteBoatCategories([record.Id])
                    .finally(() => {
                        dismissLoading();
                    })
                    .then(result => {
                        const newBoatCategories = boatCategories.filter((m) => m.Id !== record.Id);
                        setBoatCategories(newBoatCategories);
                        setRefresh(true);
                    })
                    .catch(error => {
                        CommonService.handleErrorResponse(error);
                    });
                }
            });
        } else {
            const newBoatCategories = boatCategories.filter((m) => m.Id !== record.Id);
            setBoatCategories(newBoatCategories);
        }
    }

    const handleOnChange = (value, record, dataIndex) => {
        if (record) {
            var index = boatCategories.findIndex(x => x.Id === record.Id);
            _.update(boatCategories[index], dataIndex, function (n) { 
                return value;
            });

            if (dataIndex === 'Name') {
                setBoatCategories(current =>
                    current.map(obj => {
                        var errorMessages = [];
                        if (obj.Id === record.Id) {
                            var existBoatCategories = boatCategories.filter(x => x.Id !== obj.Id && x.Name === obj.Name);
                            if (existBoatCategories !== null && existBoatCategories.length > 0) {
                                errorMessages.push({
                                    key: 'Name',
                                    value: t('boat.boat_category_exist')
                                })
                            }
                        }
                        return {
                            ...obj,
                            ErrorMessages: errorMessages,
                        };
                    }),
                );
            }
        }
        setEnableSubmit(canSubmit(boatCategories));
    }

    var columns = [
        {
            title: t('common.record'),
            dataIndex: 'RecordId',
            key: 'RecordId',
            editable: false,
            required: false,
            width: 25,
        },
        {
            title: <span className='required'>{ t('boat.boat_category') }</span>,
            dataIndex: 'Name',
            key: 'Name',
            editable: true,
            required: true,
            duplicated: true,
            cellType: Constant.CellType.Input,
            width: 70,
            handleOnChange: handleOnChange,
        },
        {
            title: t('common.description'),
            dataIndex: 'Description',
            key: 'Description',
            editable: true,
            required: false,
            cellType: Constant.CellType.Input,
            width: 120,
            handleOnChange: handleOnChange,
        },
        {
            title: <span className='required'>{ t('common.status') }</span>,
            dataIndex: 'StatusId',
            key: 'StatusId',
            editable: true,
            extraData: props.statuses,
            required: true,
            cellType: Constant.CellType.StatusSelection,
            selectionKey: "Id",
            selectionDisplay: "Name",
            width: 45,
            handleOnChange: handleOnChange
        },
        {
            title: t('common.action'),
            key: 'Id',
            render: (value, record) => {
                return <ActionButtonsCell data={record} btnList={[
                    {
                        Description: t("common.delete"),
                        Icon: <TrashIcon />,
                        ColorClass: Constant.ColorClass.LightRed,
                        Hide: false,
                        NoMargin: true,
                        handleAction: deleteBoatCategory
                    }
                ]} />
            },
            align: 'center',
            width: 25
        },
    ]

    const boatCategoryColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                duplicated: col.duplicated,
                disabled: col.disabled,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition,
                selectionKey: col.selectionKey,
                selectionDisplay: col.selectionDisplay,
                validateAtStart: col.validateAtStart
            }),
        };
    })

    useEffect(() => {
        showLoading();

        var newBoatCategories = [];
        if (!props.isEdit) {
            var recordId = (boatCategories.length + 1);
            var id = -recordId;
            newBoatCategories.push(newBoatCategory(null, id));
        } else {
            for (var i = 0; i < props.selectedBoatCategories.length; i ++) {
                // props.selectedBoatCategories[i].RecordId = i + 1;
                props.selectedBoatCategories[i].RecordId = props.selectedBoatCategories[i].Id;
            }
            newBoatCategories = [...newBoatCategories, ...props.selectedBoatCategories];
        }
        setBoatCategories(newBoatCategories);
        setOriginalBoatCategories(_.cloneDeep(newBoatCategories));
        setEnableSubmit(canSubmit(newBoatCategories));
        setRefresh(false);

        Promise.all([
            SettingService.getAllBoatCategories()
        ])
        .finally(() => {
            dismissLoading();
        })
        .then((values) => {
            if (values && values.length > 0) {
                setAllBoatCategories(values[0].data);
            }
        });

        return () => {
        }
    }, [])

    const newBoatCategory = (recordId, id) => {
        return {
            IsAddNew: true,
            RecordId: recordId,
            Id: id,
            Name: null,
            Description: null,
            StatusId: Constant.StatusCode.Active
        };
    }

    return (
        <div>
            <Modal
                title={props.isEdit ? t("boat.edit_club_boat_categories") : t("boat.insert_club_boat_categories")}
                centered
                forceRender
                open={true}
                onOk={onSubmit}
                confirmLoading={false}
                onCancel={onClose}
                okText={props.isEdit ? t("common.submit") : t("common.insert")}
                cancelText={t("common.cancel")}
                maskClosable={false}
                width={860}
                footer={[
                    <Button key="back" onClick={onClose}>
                      {t("common.cancel")}
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} disabled={!enableSubmit} onClick={onSubmit}>
                      {props.isEdit ? t("common.submit") : t("common.insert")}
                    </Button>
                  ]}
            >
                <Form
                    {...layout}
                    name='insertEditBoatCategory'
                    key='insertEditBoatCategory'
                    onFinish={onSubmit}
                    component={false}
                    form={form}>
                    <Row id={Constant.FixItemsContainerId} className="fix-item-container">
                        <Col xs={{ span: 24 }} className="text-right extra-btn-m-t">
                            <div className="m-t-10">
                                <Button className="m-l-10 secondary-btn" type="primary" onClick={addBoatCategory}>
                                    {t("common.add")}
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    <Row className="m-t-10" gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Table
                                components={components}
                                rowClassName={() => 'editable-row'}
                                tableLayout='fixed'
                                columns={boatCategoryColumns} 
                                dataSource={boatCategories} 
                                pagination={false}
                                rowKey="Id"
                            />
                        </Col>
                    </Row>
                </Form>

                
            </Modal>
        </div>
    )
}

export default CreateEditBoatCategory
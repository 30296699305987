import axios from 'axios';
import { axiosPortal } from './authService'

const source = [];
let baseURL = "api/FishingActivity"

export const FishingActivityService = {
    getActivityHeaderById: function (id) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetActivityHeaderById/${id}`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },


    getActivityHeaderFilterDatas: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/ActivityHeaderFilterDatas`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    addUpdateActivityHeader: function (data) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/AddUpdateActivityHeader`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getActivityLinesByActivityHeaderId: function (id) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetActivityLines/${id}`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    addUpdateActivityLines: function (data) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/AddUpdateActivityLines`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    deleteActivityLines: function (ids) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/DeleteActivityLines`, ids, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getPointScores: function (activityDate, anglerId, lineClassId, clubZoneId, statePointScoreZoneId, fishSpeciesId, tagCaptureId, fishWeight, dateEntered) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);

        if (!anglerId) {
            anglerId = -1;
        }

        if (!lineClassId) {
            lineClassId = 0;
        }

        if (!clubZoneId) {
            clubZoneId = 0;
        }

        if (!statePointScoreZoneId) {
            statePointScoreZoneId = 0;
        }

        if (!fishSpeciesId) {
            fishSpeciesId = 0;
        }

        if (!tagCaptureId) {
            tagCaptureId = 0;
        }

        if (!fishWeight) {
            fishWeight = 0;
        }

        let url = `${baseURL}/GetPointScores?activityDate=${activityDate}&anglerId=${anglerId}&lineClassId=${lineClassId}&clubZoneId=${clubZoneId}&statePointScoreZoneId=${statePointScoreZoneId}&fishSpeciesId=${fishSpeciesId}&tagCaptureId=${tagCaptureId}&fishWeight=${fishWeight}&dateEntered=${dateEntered}`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getDayLogList: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/ActivityHeaders?PageNumber=${pageNumber ? pageNumber : 1}`

        if (sort) {
            url += `&Sort=${sort}`
        }

        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (params) {
            if (params.DayLogId) {
                url += `&DayLogId=${params.DayLogId}`;
            }
            if (params.ActivitySheetNumber) {
                url += `&ActivitySheetNumber=${params.ActivitySheetNumber}`;
            }
            if (params.CreatedBys) {
                url += `&CreatedBys=${params.CreatedBys}`;
            }
            if (params.BoatIds) {
                url += `&BoatIds=${params.BoatIds}`;
            }
            if (params.BoatCaptainIds) {
                url += `&BoatCaptainIds=${params.BoatCaptainIds}`;
            }
            if (params.PersonOnBoard) {
                url += `&PersonOnBoard=${params.PersonOnBoard}`;
            }
            if (params.HoursOnWater) {
                url += `&HoursOnWater=${params.HoursOnWater}`;
            }
            if (params.NoOfActivityEntries) {
                url += `&NoOfActivityEntries=${params.NoOfActivityEntries}`;
            }
            if (params.ClubEventIds) {
                url += `&ClubEventIds=${params.ClubEventIds}`;
            }
            if (params.StateEventIds) {
                url += `&StateEventIds=${params.StateEventIds}`;
            }
            if (params.ActivityDateFrom) {
                url += `&ActivityDateFrom=${params.ActivityDateFrom}`;
            }
            if (params.ActivityDateTo) {
                url += `&ActivityDateTo=${params.ActivityDateTo}`;
            }
            if (params.FinancialYearIds) {
                url += `&FinancialYearIds=${params.FinancialYearIds}`;
            }
            if (params.StatusIds) {
                url += `&StatusIds=${params.StatusIds}`;
            }
            if (params.ClubIds) {
                url += `&ClubIds=${params.ClubIds}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getFinancialYears: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = 'api/master-data/financial-years'
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getClubSpecialEvents: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/CurrentClubSpecialEvents`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getStateSpecialEvents: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/CurrentStateSpecialEvents`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getExportActivityHeaders: function (sort, params, fileType) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/ExportActivityHeaders?fileType=${fileType}`

        if (sort) {
            url += `&Sort=${sort}`
        }

        if (params) {
            if (params.DayLogId) {
                url += `&DayLogId=${params.DayLogId}`;
            }
            if (params.ActivitySheetNumber) {
                url += `&ActivitySheetNumber=${params.ActivitySheetNumber}`;
            }
            if (params.CreatedBys) {
                url += `&CreatedBys=${params.CreatedBys}`;
            }
            if (params.BoatIds) {
                url += `&BoatIds=${params.BoatIds}`;
            }
            if (params.BoatCaptainIds) {
                url += `&BoatCaptainIds=${params.BoatCaptainIds}`;
            }
            if (params.PersonOnBoard) {
                url += `&PersonOnBoard=${params.PersonOnBoard}`;
            }
            if (params.HoursOnWater) {
                url += `&HoursOnWater=${params.HoursOnWater}`;
            }
            if (params.NoOfActivityEntries) {
                url += `&NoOfActivityEntries=${params.NoOfActivityEntries}`;
            }
            if (params.ClubEventIds) {
                url += `&ClubEventIds=${params.ClubEventIds}`;
            }
            if (params.StateEventIds) {
                url += `&StateEventIds=${params.StateEventIds}`;
            }
            if (params.ActivityDateFrom) {
                url += `&ActivityDateFrom=${params.ActivityDateFrom}`;
            }
            if (params.ActivityDateTo) {
                url += `&ActivityDateTo=${params.ActivityDateTo}`;
            }
            if (params.FinancialYearIds) {
                url += `&FinancialYearIds=${params.FinancialYearIds}`;
            }
            if (params.StatusIds) {
                url += `&StatusIds=${params.StatusIds}`;
            }
            if (params.ClubIds) {
                url += `&ClubIds=${params.ClubIds}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token,
            responseType: 'blob'
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    deleteDayLog: function (id) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/DeleteActivityHeader`

        return axiosPortal.post(url, id, {
            cancelToken: cancelToken.token,
            headers: {
                "Content-Type": "application/json"
            }
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getActivityLinesByTagNumber: function (tagNumber) {
        if(!tagNumber)
            return
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetActivityLinesByTagNumber?tagNumber=${tagNumber}`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getActivityHeaderExportFilterData: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/ActivityHeaderExportFilterData`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getActivityLineExportFilterData: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/ActivityLineExportFilterData`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getExportActivityReport: function (params, fileType, isAllData) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/ExportActivityReport?fileType=${fileType}`

        if (params) {
            if (params.BoatIds) {
                url += `&BoatIds=${params.BoatIds}`;
            }
            if (params.BoatCaptainIds) {
                url += `&BoatCaptainIds=${params.BoatCaptainIds}`;
            }
            if (params.AnglerIds) {
                url += `&AnglerIds=${params.AnglerIds}`;
            }
            if (params.EntityIds) {
                url += `&EntityIds=${params.EntityIds}`;
            }
            if (params.ActivityDateFrom) {
                url += `&ActivityDateFrom=${params.ActivityDateFrom}`;
            }
            if (params.ActivityDateTo) {
                url += `&ActivityDateTo=${params.ActivityDateTo}`;
            }
            if (params.ClubEventIds) {
                url += `&ClubEventIds=${params.ClubEventIds}`;
            }
            if (params.StateEventIds) {
                url += `&StateEventIds=${params.StateEventIds}`;
            }
            if (params.DayLogId) {
                url += `&DayLogId=${params.DayLogId}`;
            }
            if (params.ActivitySheetNumber) {
                url += `&ActivitySheetNumber=${params.ActivitySheetNumber}`;
            }
            if (params.FinancialYearIds) {
                url += `&FinancialYearIds=${params.FinancialYearIds}`;
            }
            if (params.FishingTypeIds) {
                url += `&FishingTypeIds=${params.FishingTypeIds}`;
            }
            if (params.FishingCategoryIds) {
                url += `&FishingCategoryIds=${params.FishingCategoryIds}`;
            }
            if (params.GenderIds) {
                url += `&GenderIds=${params.GenderIds}`;
            }
            if (params.CreatedBys) {
                url += `&CreatedBys=${params.CreatedBys}`;
            }
            if (params.ClubPointScoreZoneIds) {
                url += `&ClubPointScoreZoneIds=${params.ClubPointScoreZoneIds}`;
            }
            if (params.StatePointScoreZoneIds) {
                url += `&StatePointScoreZoneIds=${params.StatePointScoreZoneIds}`;
            }
            if (params.ClubApprovalStatusIds) {
                url += `&ClubApprovalStatusIds=${params.ClubApprovalStatusIds}`;
            }
            url += `&IsAllData=${isAllData}`;
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token,
            responseType: 'blob'
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getActivityHeadersForActivityApproval: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetActivityHeadersForActivityApproval?PageNumber=${pageNumber ? pageNumber : 1}&IsCheckActivityLine=true`

        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (sort) {
            url += `&Sort=${sort}`
        }

        if (params) {
            if (params.DayLogId) {
                url += `&DayLogId=${params.DayLogId}`;
            }
            if (params.ActivitySheetNumber) {
                url += `&ActivitySheetNumber=${params.ActivitySheetNumber}`;
            }
            if (params.EntityId) {
                url += `&ClubIds=${params.EntityId}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getActivityLinesForActivityApproval: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetActivityLinesForActivityApproval?PageNumber=${pageNumber ? pageNumber : 1}`
        if (sort) {
            url += `&Sort=${sort}`
        }

        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (params) {
            if (params.DayLogId) {
                url += `&DayLogId=${params.DayLogId}`;
            }
            if (params.ActivitySheetNumber) {
                url += `&ActivitySheetNumber=${params.ActivitySheetNumber}`;
            }
            if (params.EntityId) {
                url += `&ClubIds=${params.EntityId}`;
            }
        }
        
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getClubsByState: function (parentEntityId) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetClubs`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getTagApproval: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = "api/master-data/tag-approval"
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    updateApprovalStatus: function (lines) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/UpdateApprovalStatus`

        return axiosPortal.put(url, lines, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    
    getActivityLinesById: function (activityLineId) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetActivityLinesById?activityLineId=${activityLineId}`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    cancelRequest: function () {
        if (source && source.length > 0) {
            for (var i = 0; i < source.length; i++) {
                source[i].cancel('Operation canceled.')
            }
            source.splice(0, source.length)
        }
    },
    getAllActivityLines: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetAllActivityLines?PageNumber=${pageNumber ? pageNumber : 1}`

        if (sort) {
            url += `&Sort=${sort}`
        }

        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (params) {
            if (params.DayLogId) {
                url += `&DayLogId=${params.DayLogId}`;
            }
            if (params.ActivitySheetNumber) {
                url += `&ActivitySheetNumber=${params.ActivitySheetNumber}`;
            }
            if (params.CreatedBys) {
                url += `&CreatedBys=${params.CreatedBys}`;
            }
            if (params.BoatIds) {
                url += `&BoatIds=${params.BoatIds}`;
            }
            if (params.AnglerName) {
                url += `&AnglerName=${params.AnglerName}`;
            }
            if (params.BoatCaptainIds) {
                url += `&BoatCaptainIds=${params.BoatCaptainIds}`;
            }
            if (params.ActivityDateFrom) {
                url += `&ActivityDateFrom=${params.ActivityDateFrom}`;
            }
            if (params.ActivityDateTo) {
                url += `&ActivityDateTo=${params.ActivityDateTo}`;
            }
            if (params.FinancialYearIds) {
                url += `&FinancialYearIds=${params.FinancialYearIds}`;
            }
            if (params.TagCaptureIds) {
                url += `&TagCaptureIds=${params.TagCaptureIds}`;
            }
            if (params.FishingMethodIds) {
                url += `&FishingMethodIds=${params.FishingMethodIds}`;
            }
            if (params.FishSpeciesIds) {
                url += `&FishSpeciesIds=${params.FishSpeciesIds}`;
            }
            if (params.TagNumber) {
                url += `&TagNumber=${params.TagNumber}`;
            }
            if (params.FishWeight) {
                url += `&FishWeight=${params.FishWeight}`;
            }
            if (params.LineClassId) {
                url += `&LineClassId=${params.LineClassId}`;
            }
            if (params.AnglerIds) {
                url += `&AnglerIds=${params.AnglerIds}`;
            }
            if (params.ClubApprovalIds) {
                url += `&ClubApprovalIds=${params.ClubApprovalIds}`;
            }
            if (params.StateApprovalIds) {
                url += `&StateApprovalIds=${params.StateApprovalIds}`;
            }
            if (params.ClubIds) {
                url += `&ClubIds=${params.ClubIds}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getExportActivityLines: function (sort, params, fileType) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/ExportActivityLines?fileType=${fileType}`

        if (sort) {
            url += `&Sort=${sort}`
        }

        if (params) {
            if (params.DayLogId) {
                url += `&DayLogId=${params.DayLogId}`;
            }
            if (params.ActivitySheetNumber) {
                url += `&ActivitySheetNumber=${params.ActivitySheetNumber}`;
            }
            if (params.CreatedBys) {
                url += `&CreatedBys=${params.CreatedBys}`;
            }
            if (params.BoatIds) {
                url += `&BoatIds=${params.BoatIds}`;
            }
            if (params.AnglerName) {
                url += `&AnglerName=${params.AnglerName}`;
            }
            if (params.BoatCaptainIds) {
                url += `&BoatCaptainIds=${params.BoatCaptainIds}`;
            }
            if (params.ActivityDateFrom) {
                url += `&ActivityDateFrom=${params.ActivityDateFrom}`;
            }
            if (params.ActivityDateTo) {
                url += `&ActivityDateTo=${params.ActivityDateTo}`;
            }
            if (params.FinancialYearIds) {
                url += `&FinancialYearIds=${params.FinancialYearIds}`;
            }
            if (params.TagCaptureIds) {
                url += `&TagCaptureIds=${params.TagCaptureIds}`;
            }
            if (params.FishingMethodIds) {
                url += `&FishingMethodIds=${params.FishingMethodIds}`;
            }
            if (params.FishSpeciesIds) {
                url += `&FishSpeciesIds=${params.FishSpeciesIds}`;
            }
            if (params.TagNumber) {
                url += `&TagNumber=${params.TagNumber}`;
            }
            if (params.FishWeight) {
                url += `&FishWeight=${params.FishWeight}`;
            }
            if (params.LineClassId) {
                url += `&LineClassId=${params.LineClassId}`;
            }
            if (params.AnglerIds) {
                url += `&AnglerIds=${params.AnglerIds}`;
            }
            if (params.ClubApprovalIds) {
                url += `&ClubApprovalIds=${params.ClubApprovalIds}`;
            }
            if (params.StateApprovalIds) {
                url += `&StateApprovalIds=${params.StateApprovalIds}`;
            }
            if (params.ClubIds) {
                url += `&ClubIds=${params.ClubIds}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token,
            responseType: 'blob'
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    verifyDuplicateActivitySheetNumber: function (activityHeaderId, activitySheetNumber) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `${baseURL}/VerifyDuplicateActivitySheetNumber?activityHeaderId=${activityHeaderId}&activitySheetNumber=${activitySheetNumber}`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
}
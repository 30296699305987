import React, { useState, useEffect } from 'react';
import { Modal, Tree, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from './../../../utilities/globalState'
import { Constant } from '../../../services/services';

const { Search } = Input;

const MemberList = (props) => {
    const { t } = useTranslation();
    const [memberData, setMemberData] = useState([]);
    const [selectedMemberIds, setSelectedMemberIds] = useState([]);
    const [treeHeight, setTreeHeight] = useState(300);
    const [currentPosition] = useGlobalState('currentPosition');

    useEffect(() => {
        let bodyHeight = treeHeight
        let bodyElement = document.getElementsByClassName("ant-modal-body");
        if (bodyElement && bodyElement.length > 0) {
            bodyHeight = bodyElement[bodyElement.length - 1].offsetHeight
            setTreeHeight(bodyHeight)
        }

        if (props.viewOnly) {
            if (props.objectData && props.objectData.Members && props.objectData.Members.length > 0) {
                let members = []
                props.objectData.Members.forEach(item => {
                    let memberItem = ""
                    if (currentPosition &&
                        (currentPosition.PositionTypeId == Constant.PositionType.StateExcutive || currentPosition.PositionTypeId == Constant.PositionType.NationalExecutive)) {
                        memberItem = getTreeItem(item.ClubAcronym + " - " + item.FirstName + " " + item.LastName, item.Id, true)
                    } else {
                        memberItem = getTreeItem(item.FirstName + " " + item.LastName, item.Id, true)
                    }
                    members.push(memberItem)
                });
                setMemberData(members)
            }
        } else if (props.allMembers) {
            let members = []
            let sortedMembers = []
            if (props.objectData && props.objectData.Members && props.objectData.Members.length > 0) {
                let ids = props.objectData.Members.map((n, index) => n.Id)
                let selected = props.allMembers.filter(m => ids.indexOf(m.Id) > -1)
                let unselected = props.allMembers.filter(m => ids.indexOf(m.Id) < 0)
                sortedMembers = [...selected, ...unselected]
            }

            if (sortedMembers.length == 0) {
                sortedMembers = [...props.allMembers]
            }

            sortedMembers.forEach(item => {
                let name = ""
                if (currentPosition &&
                    (currentPosition.PositionTypeId == Constant.PositionType.StateExcutive || currentPosition.PositionTypeId == Constant.PositionType.NationalExecutive)) {
                    name = item.ClubAcronym + " - " + item.FirstName + " " + item.LastName
                } else {
                    name = item.FirstName + " " + item.LastName
                }

                let memberItem = getTreeItem(name, item.Id, false)
                members.push(memberItem)
            });
            setMemberData(members)

            if (props.memberListType == Constant.MemberListType.PositionHolder) {
                if (props.objectData && props.objectData.Members && props.objectData.Members.length > 0) {
                    let ids = props.objectData.Members.map((n, index) => n.Id)
                    setSelectedMemberIds(ids)
                }
            } else if (props.memberListType == Constant.MemberListType.Skipper1) {
                if(props.objectData && props.objectData.Skipper1){
                    setSelectedMemberIds([props.objectData.Skipper1.Id])
                }      
            }
            else if (props.memberListType == Constant.MemberListType.Skipper2) {
                if(props.objectData && props.objectData.Skipper2){
                    setSelectedMemberIds([props.objectData.Skipper2.Id])
                }             
            }
            else if (props.memberListType == Constant.MemberListType.FamilyGroup) {
                if(props.objectData && props.objectData.member){
                    setSelectedMemberIds([props.objectData.member.Id])
                }               
            }

        }
    }, [props.objectData])

    const getTreeItem = (title, key, disabled, children) => {
        return {
            "title": title,
            "key": key,
            "disabled": disabled,
            "children": children
        };
    }

    const onSearchChange = (e) => {
        if (e && e.target && e.target.value) {
            let search = props.allMembers.filter(m => (m.FirstName && m.LastName && (m.FirstName + " " + m.LastName).toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
                || (m.ClubAcronym && (m.ClubAcronym + " - " + m.FirstName + " " + m.LastName).toLowerCase().indexOf(e.target.value.toLowerCase()) > -1))
            let members = []
            search.forEach(item => {
                let name = ""
                if (currentPosition &&
                    (currentPosition.PositionTypeId == Constant.PositionType.StateExcutive || currentPosition.PositionTypeId == Constant.PositionType.NationalExecutive)) {
                    name = item.ClubAcronym + " - " + item.FirstName + " " + item.LastName
                } else {
                    name = item.FirstName + " " + item.LastName
                }
                let memberItem = getTreeItem(name, item.Id, false)
                members.push(memberItem)
            });
            setMemberData(members)
        } else {
            let members = []
            props.allMembers.forEach(item => {
                let name = ""
                if (currentPosition &&
                    (currentPosition.PositionTypeId == Constant.PositionType.StateExcutive || currentPosition.PositionTypeId == Constant.PositionType.NationalExecutive)) {
                    name = item.ClubAcronym + " - " + item.FirstName + " " + item.LastName
                } else {
                    name = item.FirstName + " " + item.LastName
                }
                let memberItem = getTreeItem(name, item.Id, false)
                members.push(memberItem)
            });
            setMemberData(members)
        }
    }

    const onCheck = (checkedKeys, info) => {
        if (props.memberListType == Constant.MemberListType.PositionHolder) {
            setSelectedMemberIds(checkedKeys)
        } 
        else {
            if (info && info.node) {
                setSelectedMemberIds([info.node.key])
            }
        }
    };

    const closeForm = () => {
        props.handleCancel()
    }

    const onSubmit = () => {
        let selectedMembers = []
        if (selectedMemberIds && selectedMemberIds.length > 0) {
            selectedMembers = props.allMembers.filter(m => selectedMemberIds.indexOf(m.Id) > -1)
        }

        if (props.memberListType == Constant.MemberListType.PositionHolder) {
            props.objectData.Members = selectedMembers
        }

        if (props.memberListType == Constant.MemberListType.Skipper1) {
            props.objectData.Skipper1 = selectedMembers[0]
        }

        if (props.memberListType == Constant.MemberListType.Skipper2) {
            props.objectData.Skipper2 = selectedMembers[0]
        }

        if (props.memberListType == Constant.MemberListType.FamilyGroup) {
            props.objectData.member = selectedMembers[0]
        }

        props.handleCancel(props.objectData)
    }

    const getTitle = () => {
        if (props.memberListType == Constant.MemberListType.PositionHolder) {
            return t("position_title.position_holders")
        }

        if (props.memberListType == Constant.MemberListType.Skipper1) {
            return t('boat.skipper_1')
        }

        if (props.memberListType == Constant.MemberListType.Skipper2) {
            return t('boat.skipper_2')
        }

        if (props.memberListType == Constant.MemberListType.FamilyGroup) {
            return t('family_group.members')
        }

        return ""
    }

    return (
        <div>
            <Modal
                title={getTitle()}
                onOk={onSubmit}
                open={true}
                confirmLoading={false}
                onCancel={closeForm}
                maskClosable={false}
                className="vertical-modal"
                {...(props.viewOnly ? { footer: null } : {})}
            >
                {!props.viewOnly &&
                    <Search
                        style={{
                            marginBottom: 18,
                        }}
                        placeholder="Search"
                        onChange={onSearchChange}
                        allowClear
                    />
                }
                <Tree
                    {...(!props.viewOnly ? { checkable: true } : {})}
                    checkedKeys={selectedMemberIds}
                    onCheck={onCheck}
                    treeData={memberData}
                    height={treeHeight}
                    key="Id"
                />
            </Modal>
        </div>
    )
}

export default MemberList

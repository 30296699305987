import React, { useEffect, useState } from 'react';
import './dayLogSummary.scss';
import { useTranslation } from 'react-i18next';
import {
    Constant,
    CommonService,
    SettingService
} from '../../../services/services'
import { Table, Button, Row, Col, Form, Breadcrumb, Drawer, Space, DatePicker, theme } from 'antd';
import { GetTableConfigs, EditableCell } from '../../../components/customTable/customTable'
import { FilterAltIcon, CloseIcon } from '../../../components/icons/icons'
import LoadingContext from '../../../components/loading/loadingContext'
import EnhancedSelect from '../../../components/enhancedSelect/enhancedSelect';
import PubSub from 'pubsub-js';
import { TrophyService } from '../../../services/trophyService';
import moment from 'moment';
import { useGlobalState } from '../../../utilities/globalState';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const DayLogSummary = (props) => {
    const { t } = useTranslation();
    const [formFilter] = Form.useForm();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext);
    const [totalItems, setTotalItems] = useState(0);
    const [dayLogSummaries, setDayLogSummaries] = useState([]);
    const [openFilter, setOpenFilter] = useState(false);
    const [searchDayLogSummaries, setSearchDayLogSummaries] = useState();
    const [filterData, setFilterData] = useState({});
    const [currentPosition] = useGlobalState('currentPosition');
    const [currentFinancialYear, setCurrentFinancialYear] = useState();

    const [gridConfigOptions, setGridConfigOptionsValue] = useState({
        pageNumber: 1,
        pageSize: CommonService.getPageSize(),
    })

    const { token } = theme.useToken();

    const style = {
        border: `1px solid ${token.colorPrimary}`,
        borderRadius: '50%',
    };

    const cellRender = React.useCallback((current, info) => {
        if (info.type !== 'date') {
            return info.originNode;
        }
        if (typeof current === 'number') {
            return <div className="ant-picker-cell-inner">{current}</div>;
        }
        return (
            <div className="ant-picker-cell-inner" style={current.date() === 1 ? style : {}}>
                {current.date()}
            </div>
        );
    }, []);

    const search = (pageNumber, pageSize, sort, query, isShowLoading = false) => {
        if (isShowLoading) {
            showLoading();
        }

        let params = { ...query };

        TrophyService.getClubDayLogSummaries(pageNumber, pageSize, sort, params)
            .finally(() => {
                if (isShowLoading) {
                    dismissLoading();
                }
            })
            .then(result => {
                if (result.data) {
                    setDayLogSummaries(result.data.ObjectList);
                    setTotalItems(result.data.TotalItems);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const columns = [
        {
            title: t('trophy_management.boat_id'),
            dataIndex: 'BoatId',
            key: 'BoatId',
            sorter: true,
            width: 70,
        },
        {
            title: t('boat.boat_name'),
            dataIndex: 'BoatName',
            key: 'BoatName',
            sorter: true,
            width: 170,
        },
        {
            title: t('trophy_management.day_logs_entered'),
            dataIndex: 'DayLogsEntered',
            key: 'DayLogsEntered',
            sorter: true,
            width: 130,
            align: 'center'
        },
        {
            title: t('trophy_management.fishing_activies_entered'),
            dataIndex: 'FishingActivitiesEntered',
            key: 'FishingActivitiesEntered',
            sorter: true,
            width: 135,
            align: 'center'
        },
        {
            title: t('trophy_management.boat_hours'),
            dataIndex: 'BoatHours',
            key: 'BoatHours',
            sorter: true,
            width: 140,
            align: 'center'
        },
        {
            title: t('trophy_management.man_hours'),
            dataIndex: 'ManHours',
            key: 'ManHours',
            sorter: true,
            width: 100,
            align: 'center'
        }
    ]

    const columnsMapping = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition
            }),
        };
    })

    const stateColumns = [
        {
            title: t('trophy_management.club'),
            dataIndex: 'ClubName',
            key: 'ClubName',
            sorter: true,
            width: 210,
        },
        {
            title: t('trophy_management.day_logs_entered'),
            dataIndex: 'DayLogsEntered',
            key: 'DayLogsEntered',
            sorter: true,
            width: 100,
            align: 'center'
        },
        {
            title: t('trophy_management.fishing_activies_entered'),
            dataIndex: 'FishingActivitiesEntered',
            key: 'FishingActivitiesEntered',
            sorter: true,
            width: 120,
            align: 'center'
        },
        {
            title: t('trophy_management.boat_hours'),
            dataIndex: 'BoatHours',
            key: 'BoatHours',
            sorter: true,
            width: 90,
            align: 'center'
        },
        {
            title: t('trophy_management.man_hours'),
            dataIndex: 'ManHours',
            key: 'ManHours',
            sorter: true,
            width: 90,
            align: 'center'
        },
        {
          title: t('trophy_management.day_log_summary_group_tilte'),
          children: [
            {
              title: t('trophy_management.men'),
              dataIndex: 'MenCount',
              key: 'MenCount',
              width: 50,
              align: 'center'
            },
            {
              title: t('trophy_management.women'),
              dataIndex: 'WomenCount',
              key: 'WomenCount',
              width: 60,
              align: 'center'
            },
            {
              title: t('trophy_management.jm'),
              dataIndex: 'JMCount',
              key: 'JMCount',
              width: 50,
              align: 'center'
            },
            {
              title: t('trophy_management.jf'),
              dataIndex: 'JFCount',
              key: 'JFCount',
              width: 50,
              align: 'center'
            },
            {
              title: t('trophy_management.sfm'),
              dataIndex: 'SFMCount',
              key: 'SFMCount',
              width: 50,
              align: 'center'
            },
            {
              title: t('trophy_management.sff'),
              dataIndex: 'SFFCount',
              key: 'SFFCount',
              width: 50,
              align: 'center'
            },
            {
              title: t('trophy_management.nonfish'),
              dataIndex: 'NONFISHCount',
              key: 'NONFISHCount',
              width: 70,
              align: 'center'
            },
            {
              title: t('trophy_management.total_active_anglers'),
              dataIndex: 'TotalActiveAnglers',
              key: 'TotalActiveAnglers',
              width: 80,
              align: 'center'
            }
          ],
        },
    ]

    const stateColumnsMapping = stateColumns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition
            }),
        };
    })

    const getColumnMapping = () => {
        if (currentPosition && (currentPosition.PositionTypeId == Constant.PositionType.ClubMember ||
            currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee)) {
            return columnsMapping
        } else if (currentPosition && (currentPosition.PositionTypeId == Constant.PositionType.StateExcutive ||
            currentPosition.PositionTypeId == Constant.PositionType.NationalExecutive)) {
            return stateColumnsMapping
        }

        return null
    }

    const onTableChange = (pagination, filters, sorter, extra) => {
        let data = formFilter.getFieldsValue();

        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = gridConfigOptions.sort;
        }

        if (data.FinancialEnd) {
            var financialEnd = null;
            var length = data.FinancialEnd.split('/').length;
            if (length >= 3) {
                financialEnd = moment(data.FinancialEnd, Constant.DateFormat);
            } else {
                financialEnd = moment(data.FinancialEnd)
            }
            data.FinancialEnd = financialEnd;
        }
        search(pagination.current, pagination.pageSize, sort, data);

        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        });
    }

    const showFilterLayout = () => {
        setOpenFilter(true);
    }

    const closeFilterLayout = () => {
        formFilter.setFieldsValue(searchDayLogSummaries);
        setOpenFilter(false);
    }

    const refreshData = (isShowLoading = false) => {
        let data = formFilter.getFieldsValue();
        setSearchDayLogSummaries(data);

        search(1, gridConfigOptions.pageSize, gridConfigOptions.sort, data, isShowLoading);

        setGridConfigOptionsValue(
            {
                ...gridConfigOptions,
                pageNumber: 1
            }
        );

    }

    const resetFilter = () => {
        formFilter.resetFields();
        formFilter.setFieldValue("FinancialYearId", currentFinancialYear.Id)
        setSearchDayLogSummaries({
            FinancialYearId: currentFinancialYear.Id
        });
    }

    const applyFilter = () => {

        refreshData(true);

        setOpenFilter(false);
    }


    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
        }, 100)
    
        showLoading();
    
        TrophyService.getAllFinancialYears()
            .then(yearResponse => {
                if (yearResponse?.data) {
                    const currentYears = yearResponse.data.filter(x => x.IsCurrentFinancialYear);
                    if (currentYears?.length > 0) {
                        setCurrentFinancialYear(currentYears[0]);
                        const financialYearId = currentYears[0].Id;
    
                        return Promise.all([
                            TrophyService.getClubDayLogSummaries(
                                gridConfigOptions.pageNumber, 
                                gridConfigOptions.pageSize, 
                                gridConfigOptions.sort,
                                { FinancialYearId: financialYearId }
                            ),
                            TrophyService.getTrophyFilterData(),
                            Promise.resolve({ data: yearResponse.data })
                        ]);
                    }
                }
                return Promise.reject('No current financial year found');
            })
            .then(([summariesResponse, filterResponse, yearResponse]) => {
                if (summariesResponse?.data) {
                    setDayLogSummaries(summariesResponse.data.ObjectList);
                    setTotalItems(summariesResponse.data.TotalItems);
                }
                if (filterResponse?.data) {
                    setFilterData(filterResponse.data);
                }
                if (yearResponse?.data) {
                    const currentYears = yearResponse.data.filter(x => x.IsCurrentFinancialYear);
                    if (currentYears?.length > 0) {
                        setSearchDayLogSummaries({
                            FinancialYearId: currentYears[0].Id
                        });
                    }
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
            .finally(() => {
                dismissLoading();
                setSearchDayLogSummaries();
            });
    
        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);
    
        return () => {
            SettingService.cancelRequest();
            clearTimeout(timeoutFn);
        }
    }, [])

    const components = {
        body: {
            cell: EditableCell,
        }
    };

    return (
        <div className="day-log-summary">
            <Breadcrumb>
                <Breadcrumb.Item>{t("menu.day_log_and_fishing_activity_summary")}</Breadcrumb.Item>
            </Breadcrumb>
            <Row id={Constant.FixItemsContainerId} className="fix-item-container">
                <Col xs={{ span: 24 }} className="text-right extra-btn-m-t">
                    <div className="m-t-10">
                        <Button onClick={showFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon className="anticon anticon-search" />}>{t("common.filter")}</Button>
                    </div>
                </Col>
            </Row>

            <Row className="m-t-10 section-table" gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                <Col xs={{ span: 24 }}>
                    <Table
                        components={components}
                        rowClassName={() => 'editable-row'}
                        {...GetTableConfigs(getColumnMapping(), dayLogSummaries, totalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                        scroll={{ x: "max-content", y: "max-content" }}
                    />
                </Col>
            </Row>

            {/* Filter Layout  */}
            <Drawer
                title={t("common.filter")}
                width={378}
                onClose={closeFilterLayout}
                open={openFilter}
                closeIcon={false}
                styles={{
                    body: {
                        paddingBottom: 80,
                    }
                }}
                extra={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                        <Button onClick={closeFilterLayout} icon={<CloseIcon />} type="link" />
                    </Space>
                }
                footer={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button onClick={closeFilterLayout}>{t("common.cancel")}</Button>
                        <Button onClick={applyFilter} type="primary">
                            {t("common.apply")}
                        </Button>
                    </Space>
                }>
                <Form
                    layout="vertical"
                    {...layout}
                    form={formFilter}
                    name="formFilter"
                    key='formFilter'>
                    <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="DateRange"
                                    label={t('trophy_management.date_range')}
                                >
                                    <DatePicker.RangePicker cellRender={cellRender} format={Constant.DateFormat}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="FinancialYearId"
                                    label={t("fishing_activity.financial_year")}
                                    initialValue={currentFinancialYear?.Id}
                                >
                                    <EnhancedSelect mode="single" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={filterData && filterData.FinancialYears ? filterData.FinancialYears.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                </Form>
            </Drawer>
        </div>
    )

}

export default DayLogSummary;
import React, { useState, useEffect } from 'react';
import { Modal, Table, Row, Col, Button, Checkbox, Form, Select, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import LoadingContext from '../../../components/loading/loadingContext'
import { useGlobalState } from '../../../utilities/globalState'
import { GetTableConfigs, ActionButtonsCell, TextAndButtonCell, EditableCell } from '../../../components/customTable/customTable'
import { TrashIcon, CloseIcon, EditIcon } from '../../../components/icons/icons'
import { MembershipService, SettingService, CommonService, Constant, TrophyService } from '../../../services/services'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import EnhancedSelect from '../../../components/enhancedSelect/enhancedSelect'

const { confirm } = Modal;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const { Option } = Select;

const AddUpdateBoatGroup = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [isEdit, setIsEdit] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [currentPosition] = useGlobalState('currentPosition');
    const [boatGroupingForm] = Form.useForm();
    const [selectedBoats, setSelectedBoats] = useState([]);
    const [boats, setBoats] = useState([]);
    const [disableAddBoat, setDisableAddBoat] = useState(false);
    const [enableSubmit, setEnableSubmit] = useState(false);

    const statusCodes = [
        {
            Value: Constant.StatusCode.Active,
            Description: t("common.active")
        },
        {
            Value: Constant.StatusCode.Inactive,
            Description: t("common.inactive")
        }
    ]

    const getErrorMessage = () => {
        let values = boatGroupingForm.getFieldsValue()
        if (!values.Name) {
            return t("boat_grouping.boat_group_name_required")
        }
        let invalidBoats = selectedBoats.filter(x => !x.Id)
        if (invalidBoats.length > 0) {
            return t("boat_grouping.boats_required")
        }

        return null
    }

    const closeForm = () => {
        if (isDirty) {
            confirm({
                title: t("common.confirm"),
                icon: <ExclamationCircleOutlined />,
                content: t("common.submit_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    if (canSubmit()) {
                        onSubmit()
                    } else {
                        let message = getErrorMessage()
                        if (message) {
                            CommonService.presentToast('error', message)
                        }
                    }
                },
                onCancel() {
                    props.handleCancel()
                }
            });
        } else {
            props.handleCancel()
        }
    }

    const canSubmit = () => {
        let data = boatGroupingForm.getFieldsValue()
        let inValidBoats = selectedBoats.filter(x => !x.Id)
        if (isDirty && data && data.Name && inValidBoats.length == 0) {
            return true
        }
        return false
    }

    const onSubmit = () => {
        if (canSubmit()) {
            showLoading()
            let data = boatGroupingForm.getFieldsValue()
            let selectedObject = {
                Id: props.selectedObject ? props.selectedObject.Id : null,
                Name: data.Name,
                Boats: selectedBoats,
                StatusId: data.StatusId
            }
            TrophyService.addUpdateBoatGroup(selectedObject)
                .finally(() => dismissLoading())
                .then(result => {
                    if (props.selectedObject && props.selectedObject.Id) {
                        CommonService.presentToast('success', t("boat_grouping.update_successful"))
                    }
                    else {
                        CommonService.presentToast('success', t("boat_grouping.create_successful"))
                    }
                    props.handleCancel(true)
                })
                .catch(error => CommonService.handleErrorResponse(error))
        }
        else {
            let message = getErrorMessage()
            if (message) {
                CommonService.presentToast('error', message)
            }
        }
    }

    const onFormValuesChange = (changedValues, allValues) => {
        setIsDirty(true)
        setEnableSubmit(true)
    }

    const addBlankLine = () => {
        let lines = [
            ...selectedBoats,
            getBlankLine()
        ]
        setSelectedBoats(lines)
    }

    const getBlankLine = () => {
        return {
            Id: null,
            IsAddNew: true,
            Index: selectedBoats.length
        };
    };

    const deleteBoat = (record) => {
        if (!record.IsAddNew && record.Id > 0) {
            confirm({
                title: t("boat_grouping.delete_boat_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    const newBoats = selectedBoats.filter((m) => m.Id !== record.Id);
                    setSelectedBoats(newBoats);
                    setIsDirty(true)
                    if (newBoats.length == 0) {
                        setEnableSubmit(false)
                    }
                    else{
                        setEnableSubmit(true)
                    }
                }
            });
        }
        else {
            const newBoats = selectedBoats.filter((m) => m.Index !== record.Index);
            setSelectedBoats(newBoats);
            if (newBoats.length == 0) {
                setEnableSubmit(false)
            }
            else{
                setEnableSubmit(true)
            }
            setIsDirty(true)
        }
    }

    const handleOnChange = (value, record, dataIndex) => {
        if (record) {
            setIsDirty(true)
            let temp = selectedBoats.map(x => Object.assign({}, x));
            var index = selectedBoats.indexOf(record);
            if (index >= 0) {
                let selectedData = boats.find(x => x.Id == value)
                if (selectedData) {

                    temp[index] = {
                        ...record,
                        ...selectedData
                    }
                }

                setSelectedBoats(temp)
            }
            setEnableSubmit(true)
        }
    }

    const columns = [
        {
            title: t('common.action'),
            render: (value, record) => {
                return <ActionButtonsCell data={record} btnList={[
                    {
                        Description: t("common.delete"),
                        Icon: <TrashIcon />,
                        ColorClass: Constant.ColorClass.LightRed,
                        Hide: false,
                        NoMargin: true,
                        handleAction: deleteBoat
                    }
                ]} />
            },
            align: 'center',
            width: 60,
        },
        {
            title: t('boat_grouping.boat_name_number'),
            dataIndex: 'Id',
            key: 'Id',
            editable: true,
            extraData: boats,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "NameBoatNo",
            validateAtStart: true,
            handleOnChange: handleOnChange,
            width: 220,
            allowClear: false
        },
        {
            title: t('angler_group.club'),
            dataIndex: 'ClubAcronym',
            key: 'ClubAcronym',
            width: 150
        },
        {
            title: t('boat.rego'),
            dataIndex: 'RegoNo',
            key: 'RegoNo',
            sorter: false,
            width: 130
        },
        {
            title: t('boat.skipper_1'),
            dataIndex: 'Skipper1',
            key: 'Skipper1',
            render: (value, record) => {
                if (record.Skipper1) {
                    return record.Skipper1.FirstName + " " + record.Skipper1.LastName
                }
                return null
            },
            sorter: false,
            width: 145
        },
        {
            title: t('boat.skipper_2'),
            dataIndex: 'Skipper2',
            key: 'Skipper2',
            render: (value, record) => {
                if (record.Skipper2) {
                    return record.Skipper2.FirstName + " " + record.Skipper2.LastName
                }
                return null
            },
            sorter: false,
            width: 145
        }

    ]

    const columnsMapping = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                validateAtStart: col.validateAtStart,
                disabled: col.disabled,
                extraData: col.extraData,
                selectionKey: col.selectionKey,
                selectionDisplay: col.selectionDisplay,
                allowClear: col.allowClear
            })
        };
    })

    useEffect(() => {
        showLoading()
        Promise.all([
            MembershipService.getBoats(1, null, null, { StatusIds: Constant.StatusCode.Active })
        ])
            .finally(() => dismissLoading())
            .then(response => {
                if (response[0].data) {
                    setBoats(response[0].data.ObjectList);
                    if (props.selectedObject && props.selectedObject.Id) {
                        if (props.selectedObject.Boats && props.selectedObject.Boats.length > 0) {
                            let temp = props.selectedObject.Boats
                            for (var i = 0; i < temp.length; i++) {
                                temp.Index = i
                            }
                            setSelectedBoats(temp)
                        }
                    } else {
                        setSelectedBoats([getBlankLine()])
                    }
                }
            })
            .catch(error => CommonService.handleErrorResponse(error))
    }, [])

    const components = {
        body: {
            cell: EditableCell,
        },
    };

    return (
        <Modal
            title={props.selectedObject && props.selectedObject.Id ? t("boat_grouping.edit_boat_group") : t("boat_grouping.insert_boat_group")}
            open={true}
            onCancel={closeForm}
            confirmLoading={false}
            maskClosable={false}
            centered
            width={1200}
            footer={[
                <Button key={0} size="large" onClick={closeForm}>{t("common.cancel")}</Button>,
                <Button key={1} size="large" type="primary" disabled={!enableSubmit} onClick={onSubmit}>{props.selectedObject && props.selectedObject.Id ? t("common.submit") : t("common.insert")}</Button>
            ]}
        >
            <Row>
                <Col xs={{ span: 24 }}>
                    <Form
                        layout="vertical"
                        {...layout}
                        form={boatGroupingForm}
                        name="boatGroupingForm"
                        key='boatGroupingForm'
                        initialValues={props.selectedObject}
                        onFinish={onSubmit}
                        onValuesChange={onFormValuesChange}>
                        <Row gutter={[Constant.SpaceConstant.HorizontalGutter, Constant.SpaceConstant.VerticalGutter]}>
                            <Col xs={{ span: 18 }}>
                                <Form.Item
                                    label={t("boat_grouping.boat_group_name")}
                                    extra={t("boat_grouping.suggested_text")}
                                    name="Name" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("boat_grouping.boat_group_name_required") }]}
                                >
                                    <Input allowClear />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }}>
                                <Form.Item
                                    name="StatusId"
                                    label={t("common.status")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("common.status_is_required"),
                                        },
                                    ]}
                                >
                                    <EnhancedSelect showSearch optionFilterProp="label" allowClear={false}
                                        defaultValue={1}
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={statusCodes ? statusCodes.map(b => {
                                            return {
                                                label: b.Description,
                                                value: b.Value
                                            }
                                        }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-b-10'>
                            <Col flex={100} className="text-right">
                                <div>
                                    <Button className='btn-solid-dark-blue m-l-10' onClick={addBlankLine}>{t("common.add")}</Button>
                                </div>
                            </Col>
                        </Row>

                    </Form>
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Table
                                components={components}
                                columns={columnsMapping}
                                dataSource={selectedBoats}
                                pagination={false}
                                rowKey="Index"
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Modal>
    )

}

export default AddUpdateBoatGroup
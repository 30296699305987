import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './mainLayout.scss';
import { PlusIcon, ChevronLeftIcon, CommunicationIcon, FishingActivityIcon, HomeIcon, MembershipIcon, RecordIcon, SettingIcon, TournamentIcon, TrophyIcon } from '../../components/icons/icons'
import { Constant, CommonService, StorageService, SettingService } from '../../services/services';
import { Layout, Menu, Button, Modal, Breadcrumb, Space, Avatar, Flex, Select, Dropdown, Typography } from 'antd';
import { useGlobalState, resetGlobalState } from '../../utilities/globalState'
import { useLocation, useHistory } from "react-router-dom";
import logo from './../../assets/images/logo.png';
import { QuestionOutlined, LogoutOutlined, ExclamationCircleOutlined, SettingOutlined } from '@ant-design/icons';
import PubSub from 'pubsub-js'
import EnhancedSelect from '../../components/enhancedSelect/enhancedSelect';
import AccountSetting from '../../pages/accountSetting/accountSetting'
const { Header, Sider, Content } = Layout;
const { Option } = Select;
const { Text } = Typography;
const { confirm } = Modal;

function MainLayout(props) {
    const [collapsed, setCollapsed] = useState(false);
    const [currentPosition, setCurrentPosition] = useGlobalState('currentPosition');
    const [userPositions, setUserPositions] = useGlobalState('userPositions');
    const [displayMenu, setDisplayMenu] = useState([]);
    const [breadcrumbItems, setBreadcrumbItems] = useGlobalState('breadcrumbItems');
    const [entityLogo, setEntityLogo] = useState("");
    const [visibleAccountSetting, setVisibleAccountSetting] = useState(false);

    const { t } = useTranslation();
    const slideWidth = 300
    const history = useHistory();
    const location = useLocation()

    const dropdownItems = [
        {
            label: <a href="https://www.antgroup.com">{t("login.log_out")}</a>,
            key: '0',
        }
    ];

    function getMenuItem(label, key, icon, children, type, className) {
        return {
            key,
            icon,
            children,
            label,
            type,
            className
        };
    }

    useEffect(() => {
        var breadcrumbToken = PubSub.subscribe(Constant.PubSupType.PageChanged, UpdateBreadcrumb);
        var logoutToken = PubSub.subscribe(Constant.PubSupType.UserLogout, userLogout);

        return () => {
            PubSub.unsubscribe(breadcrumbToken);
            PubSub.unsubscribe(logoutToken);
        }
    }, [])

    useEffect(() => {
        if (currentPosition && currentPosition.Menus && currentPosition.Menus.length > 0) {
            let menus = []
            currentPosition.Menus.forEach(item => {
                let menu = createDisplayMenu(item)
                menus.push(menu)
            });
            setDisplayMenu(menus)
        }

        SettingService.getEntityLogo()
            .then(result => {
                setEntityLogo(result.data)
            })
            .catch(error => {
                setEntityLogo("")
            })
    }, [currentPosition])

    const createDisplayMenu = (menu) => {
        if (menu.SubMenus && menu.SubMenus.length > 0) {
            let displaySubMenus = []
            menu.SubMenus.forEach(item => {
                let displaySubMenu = createDisplayMenu(item)
                displaySubMenus.push(displaySubMenu)
            });
            return getMenuItem(menu.Name, menu.Id, getMenuIcon(menu), displaySubMenus, null, `level${menu.Level}`)
        } else {
            let key = menu.Slug == "/" && menu.Name != "Home" ? menu.Id : menu.Slug
            return getMenuItem(menu.Name, key, getMenuIcon(menu), null, null, `level${menu.Level}`)
        }
    }

    const getMenuIcon = (menu) => {
        if (menu && menu.Level == 0) {
            if (menu.Id == 0) {
                return (
                    <HomeIcon />
                )
            } else if (menu.Id == 1) {
                return (
                    <SettingIcon />
                )
            } else if (menu.Id == 2) {
                return (
                    <MembershipIcon />
                )
            } else if (menu.Id == 3) {
                return (
                    <FishingActivityIcon />
                )
            } else if (menu.Id == 4) {
                return (
                    <TrophyIcon />
                )
            } else if (menu.Id == 5) {
                return (
                    <TournamentIcon />
                )
            } else if (menu.Id == 6) {
                return (
                    <RecordIcon />
                )
            } else if (menu.Id == 7) {
                return (
                    <CommunicationIcon />
                )
            }
        }
        return null
    }

    const onMenuClick = (e) => {
        if (currentPosition && currentPosition.Menus) {
            if(e.key == Constant.Pages.TournamentCalendar){
                console.log(e.key)
                window.open(Constant.TournamentCalendarDefaultLink, '_blank').focus();
                return
            }
            let allMenus = []
            CommonService.getAllSubMenus(currentPosition.Menus, allMenus)

            let menu = allMenus.find(m => m.Slug == e.key)
            if (menu && (!menu.SubMenus || menu.SubMenus.length == 0)) {
                history.push(menu.Slug)
            }
        }
    }

    const handleMenuClick = (e) => {
        if (e.key == "1") {
            setVisibleAccountSetting(true)
        }
        else if (e.key == "2") {
            setTimeout(() => {
                PubSub.publish(Constant.PubSupType.UserLogout, Constant.PubSupType.UserLogout);
            }, 100);
        }
    };
    const items = [
        {
            label: t("login.account_setting"),
            key: "1",
            icon: <SettingOutlined />
        },
        {
            label: t("login.log_out"),
            key: "2",
            icon: <LogoutOutlined />
        }

    ];
    const menuProps = {
        items,
        onClick: handleMenuClick,
    };
    const onPositionChange = (value) => {
        if (value) {
            confirm({
                title: t("common.confirm"),
                icon: <ExclamationCircleOutlined />,
                content: t("common.change_position_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    let position = userPositions.find(p => p.Id === value)
                    if (position && (!position.Menus || position.Menus.length == 0)) {
                        CommonService.presentToast("warning", t("login.no_menu"))
                        return
                    }
                    setCurrentPosition(position)
                    StorageService.setCurrentPosition(position)
                    history.push(Constant.Pages.Home)


                },
                onCancel() {
                }

            });
        }
    }

    const UpdateBreadcrumb = (event, data) => {
        let position = StorageService.getCurrentPosition()
        if (position && position.Menus && position.Menus.length > 0) {
            if (props.history && props.history.location && props.history.location.pathname) {
                let allMenus = []
                CommonService.getAllSubMenus(position.Menus, allMenus)
                let menu = allMenus.find(m => m.Slug == props.history.location.pathname)
                if (menu) {
                    let menus = []
                    CommonService.getAllParentMenus(allMenus, menu, menus)

                    let breadcrumbs = []
                    for (let i = menus.length - 1; i >= 0; i--) {
                        breadcrumbs.push({
                            title: menus[i].Name
                        })
                    }

                    if(data) {
                        breadcrumbs[breadcrumbs.length - 1] = {title: data}
                    }

                    setBreadcrumbItems(breadcrumbs)

                }
            }
        }
    }

    const userLogout = (key) => {
        StorageService.removeAuthData();
        StorageService.removeCurrentPosition();
        StorageService.removeCurrentMenu();
        history.push(Constant.Pages.Login);
        resetGlobalState()
    }

    const handleAccountSettingClose = () => {
        setVisibleAccountSetting(false)
    }

    const onUserManualClick = () => {
        if (currentPosition && currentPosition.PositionTypeId == Constant.PositionType.ClubMember) {
            window.open("https://d9dlna1c5izyi.cloudfront.net/Live/UserManual/QGFA+-+GAMEBASE+User+Manual+-+MEMBER+Access.pdf", '_blank').focus();
        }
        if (currentPosition && currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee) {
            window.open("https://d9dlna1c5izyi.cloudfront.net/Live/UserManual/QGFA+-+GAMEBASE+User+Manual+-+CLUB+COMMITTEE+Access.pdf", '_blank').focus();
        }
        if (currentPosition && currentPosition.PositionTypeId == Constant.PositionType.StateExcutive) {
            window.open("https://d9dlna1c5izyi.cloudfront.net/Live/UserManual/QGFA+-+GAMEBASE+User+Manual+-+STATE+EXECUTIVE+Access.pdf", '_blank').focus();
        }
    }

    return (
        <>
            <Layout className='main-layout'>
                <Header>
                    <Flex>
                        <div className='left-container p-l-20' style={{ width: slideWidth }}>
                            <Flex justify='space-between' align='center' className='full-height'>
                                <Avatar src={<img src={logo} alt="avatar" />} />
                                <Button type="primary" className={`${collapsed ? 'collapsed' : ''}`}
                                    icon={<ChevronLeftIcon />}
                                    onClick={() => setCollapsed(!collapsed)}
                                />
                            </Flex>

                        </div>
                        <div className='right-container p-l-20'>
                            <Flex justify='space-between' align='center' className='full-height full-width'>
                                <Space size='middle' className='right-container--left-component'>
                                    {
                                        entityLogo &&
                                        <Avatar src={<img src={entityLogo} alt="avatar" />} />
                                    }

                                    <EnhancedSelect className='user-positions' value={currentPosition ? currentPosition.Id : null} onChange={onPositionChange} style={{ width: 400 }}
                                        showSearch optionFilterProp="children"
                                        filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                    >
                                        {
                                            userPositions.map((n, index) => <Option key={index} value={n.Id}>{n.PositionTitleName + " - " + n.EntityName}</Option>)
                                        }
                                    </EnhancedSelect>
                                </Space>

                                <Space size='middle' className='right-container--right-component'>
                                    <Button shape="circle" icon={<QuestionOutlined />} onClick={onUserManualClick} />
                                    <Dropdown menu={menuProps} trigger={['click']}>
                                        <Space>
                                            <Button type='primary' shape='round' className='blue-linear-gradient-bg'>
                                                {CommonService.getFirstCharUsername()}
                                            </Button>
                                            <Text style={{ cursor: "pointer" }}>{CommonService.getUsernameLogin()}</Text>
                                        </Space>
                                    </Dropdown>
                                </Space>
                            </Flex>
                        </div>
                    </Flex>
                </Header>
                <Layout>
                    <Sider width={slideWidth} trigger={null} collapsible collapsed={collapsed}>
                        <div className="demo-logo-vertical" />
                        <Menu
                            mode="inline"
                            defaultSelectedKeys={['/']}
                            selectedKeys={[props.location.pathname]}
                            defaultOpenKeys={['sub1']}
                            style={{
                                height: '100%',
                            }}
                            onClick={onMenuClick}
                            items={displayMenu}
                        />
                    </Sider>
                    <Layout style={{ padding: '0 20px 15px', height: 'calc(100vh - 76px)' }}>
                        {/* //TODO */}
                        <Breadcrumb
                            separator=">"
                            items={breadcrumbItems}
                        />

                        <Content>
                            <div className="site-layout--content">
                                {props.children}
                            </div>
                        </Content>
                    </Layout>
                </Layout>
                {
                    visibleAccountSetting &&
                    <AccountSetting handleCancel={handleAccountSettingClose}></AccountSetting>
                }
            </Layout>

        </>
    )
}

export default MainLayout;
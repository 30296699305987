import React, { useState, useEffect } from 'react';
import './positionTitleManagement.scss';
import { Constant, CommonService, SettingService, UserService } from '../../../services/services';
import { useTranslation } from 'react-i18next';
import { GetTableConfigs, StatusCell, ActionButtonsCell, EditableCell } from '../../../components/customTable/customTable'
import LoadingContext from '../../../components/loading/loadingContext'
import { EyeIcon } from '../../../components/icons/icons';
import { Row, Col, Table, Button, Checkbox, Modal, Empty } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import MenuAccess from './../menuAccess/menuAccess';
import MemberList from './../memberList/memberList';
import AddUpdatePositionTitle from './../addUpdatePositionTitle/addUpdatePositionTitle';
import { useGlobalState } from './../../../utilities/globalState'
import PubSub from 'pubsub-js'
import { Prompt } from "react-router-dom";

const { confirm } = Modal;

const PositionTitleManagement = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const emptyTableData = {
        ObjectList: [],
        TotalItems: 0
    }
    const [positionTitleData, setPositionTitleData] = useState(emptyTableData)
    const [visibleMenuAccess, setVisibleMenuAccess] = useState(false)
    const [visibleMemberList, setVisibleMemberList] = useState(false)
    const [visibleAddUpdatePositionTitle, setVisibleAddUpdatePositionTitle] = useState(false);
    const [selectedObject, setSelectedObject] = useState();
    const [gridConfigOptions, setGridConfigOptionsValue] = useState({
        pageNumber: 1,
        pageSize: CommonService.getPageSize()
    })
    const [positionTypes, setPositionTypes] = useState([])
    const [updatePositions, setUpdatePositions] = useState([])
    const [, setAppMenus] = useGlobalState('appMenus');
    const [confirmLeave, setConfirmLeave] = useState(false)
    const [warningMessage, setWarningMessage] = useState("")
    const [currentPosition] = useGlobalState('currentPosition');

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
        }, 100)

        if (currentPosition && currentPosition.PositionTypeId != Constant.PositionType.ClubMember) {
            showLoading()
            Promise.all([
                UserService.getAppMenus(),
                SettingService.getPositionTypes(),
                SettingService.getPositionTitles(gridConfigOptions.pageNumber, gridConfigOptions.pageSize)
            ])
                .finally(() => dismissLoading())
                .then(response => {
                    if (response[0] && response[0].data) {
                        setAppMenus(response[0].data)
                    }

                    if (response[1] && response[1].data) {
                        setPositionTypes(response[1].data)
                    }

                    if (response[2] && response[2].data) {
                        setPositionTitleData(response[2].data)
                    }
                })
                .catch(error => {
                    CommonService.handleErrorResponse(error);
                })
        }

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);
        return () => {
            SettingService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    const getPositionTitles = (pageNumber, pageSize) => {
        showLoading()
        SettingService.getPositionTitles(pageNumber, pageSize)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    setPositionTitleData(result.data)
                }
            })
    }

    const viewMenuAccess = (item) => {
        setSelectedObject(item)
        setVisibleMenuAccess(true)
    }

    const viewMembers = (item) => {
        setSelectedObject(item)
        setVisibleMemberList(true)
    }

    const getActionButtonsInfo = (record) => {
        return [
            {
                Description: t("common.view"),
                ColorClass: Constant.ColorClass.LightBlue,
                Icon: <EyeIcon />,
                Hide: false,
                handleAction: viewMenuAccess
            }
        ]
    }

    const SelectedPositionChange = (value, record) => {
        setConfirmLeave(true)
        if (record && positionTitleData && positionTitleData.ObjectList) {
            let tempList = [...positionTitleData.ObjectList]
            let position = tempList.find(p => p.PositionTitleId == record.PositionTitleId)
            if (position) {
                position.IsSelected = value;
            }

            positionTitleData.ObjectList = tempList

            setPositionTitleData({ ...positionTitleData })
        }
    }

    const columns = [
        {
            key: 'Id',
            //cellType: Constant.CellType.Checkbox,
            width: 50,
            //handleOnChange: SelectedPositionChange,
            render: (value, record) => {
                return (
                    <Checkbox disabled={record.IsAdmin} checked={record.IsSelected} onChange={e => SelectedPositionChange(e.target.checked, record)} />
                )
            }
        },
        {
            title: t('position_title.position_title'),
            dataIndex: 'PositionTitleName',
            key: 'PositionTitleName',
            width: 150
        },
        {
            title: t('position_title.position_type'),
            dataIndex: 'PositionTypeName',
            key: 'PositionTypeName',
            width: 150
        },
        {
            title: t('position_title.position_email'),
            dataIndex: 'PositionTitleEmail',
            key: 'PositionTitleEmail',
            width: 150
        },
        {
            title: t('position_title.position_holders'),
            key: 'Id',
            render: (value, record) => {
                if (record.Members && record.Members.length == 1) {
                    return record.Members[0].FirstName + " " + record.Members[0].LastName
                } else if (record.Members && record.Members.length > 1) {
                    return (
                        <Button size="small" type="link" onClick={() => viewMembers(record)}>
                            <span>
                                {record.Members.length}
                            </span>
                        </Button>
                    )
                }
            },
            width: 145
        },
        {
            title: t('position_title.is_admin'),
            key: 'Id',
            width: 55,
            render: (value, record) => {
                return (
                    <Checkbox disabled={true} checked={record.IsAdmin} />
                )
            }
        },
        {
            title: t('position_title.is_default'),
            key: 'Id',
            width: 55,
            render: (value, record) => {
                return (
                    <Checkbox disabled={true} checked={record.IsDefault} />
                )
            }
        },
        {
            title: t('position_title.menu_access'),
            key: 'Id',
            render: (value, record) => (
                <ActionButtonsCell btnList={getActionButtonsInfo(record)} data={record} />
            ),
            align: 'center',
            width: 60
        },

    ]

    const columnsMapping = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition
            }),
        };
    })

    const components = {
        body: {
            cell: EditableCell,
        }
    };

    const onTableChange = (pagination) => {
        getPositionTitles(pagination.current, pagination.pageSize)
        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize
        })
    }

    const handleMenuAccessCancel = (objectData) => {
        setVisibleMenuAccess(false)
        if (objectData) {
            //TODO
        }
    }

    const handleMemberListCancel = (objectData) => {
        setVisibleMemberList(false)
        if (objectData) {
            //TODO
        }
    }

    const handleAddUpdatePositions = (objectData) => {
        setVisibleAddUpdatePositionTitle(false)
        if (objectData) {
            if (objectData.ObjectList && objectData.ObjectList.length > 0) {
                for (let i = 0; i < objectData.ObjectList.length; i++) {
                    objectData.ObjectList[i].IsSelected = false
                }
            }
            setPositionTitleData(objectData)
        }
    };

    const hasPositionSelected = () => {
        if (positionTitleData && positionTitleData.ObjectList && positionTitleData.ObjectList.length > 0) {
            let selected = positionTitleData.ObjectList.filter(p => p.IsSelected)
            if (selected && selected.length > 0) {
                return true
            }
        }
        return false
    }

    const deletePositionTitles = () => {
        if (hasPositionSelected()) {
            confirm({
                title: t("common.confirm"),
                icon: <ExclamationCircleOutlined />,
                content: t("position_title.delete_position_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    let selected = positionTitleData.ObjectList.filter(p => p.IsSelected)
                    let ids = selected.map((n, index) => n.PositionTitleId)
                    showLoading()
                    SettingService.deletePositionTitles(ids)
                        .finally(() => dismissLoading())
                        .then(result => {
                            if (result.data) {
                                setPositionTitleData(result.data)
                            }

                        })
                        .catch(error => CommonService.handleErrorResponse(error))
                },
                onCancel() {
                }

            });
        }
    }

    const addPositionTitles = () => {
        setUpdatePositions([])
        setVisibleAddUpdatePositionTitle(true)
    }

    const editPositionTitles = () => {
        if (hasPositionSelected()) {
            let selected = positionTitleData.ObjectList.filter(p => p.IsSelected)
            setUpdatePositions(selected)
            setVisibleAddUpdatePositionTitle(true)
        }
    }

    useEffect(() => {
        if (currentPosition && currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee) {
            if (positionTitleData && positionTitleData.ObjectList && positionTitleData.ObjectList.length > 0) {
                let defaultPositions = positionTitleData.ObjectList.filter(p => p.IsDefault)
                if (!defaultPositions || defaultPositions.length == 0) {
                    setWarningMessage(t("position_title.no_defaut_warning"))
                    setConfirmLeave(true)
                    return
                } else if (defaultPositions && defaultPositions.length == 1) {
                    setConfirmLeave(false)
                } else if (defaultPositions && defaultPositions.length > 1) {
                    setWarningMessage(t("position_title.multiple_defaut_warning"))
                    setConfirmLeave(true)
                    return
                }
                let adminPositions = positionTitleData.ObjectList.filter(p => p.PositionTypeId == Constant.PositionType.ClubCommittee && p.IsAdmin)
                if (!adminPositions || adminPositions.length == 0) {
                    setWarningMessage(t("position_title.no_admin_warning"))
                    setConfirmLeave(true)
                    return
                } else if (adminPositions && adminPositions.length == 1) {
                    setConfirmLeave(false)
                    return
                } else if (adminPositions && adminPositions.length > 1) {
                    setWarningMessage(t("position_title.multiple_admin_warning"))
                    setConfirmLeave(true)
                    return
                }
            }
        } else if (currentPosition && currentPosition.PositionTypeId == Constant.PositionType.StateExcutive) {
            if (positionTitleData && positionTitleData.ObjectList && positionTitleData.ObjectList.length > 0) {
                let adminPositions = positionTitleData.ObjectList.filter(p => p.PositionTypeId == Constant.PositionType.StateExcutive && p.IsAdmin)
                if (!adminPositions || adminPositions.length == 0) {
                    setWarningMessage(t("position_title.no_admin_warning"))
                    setConfirmLeave(true)
                    return
                } else if (adminPositions && adminPositions.length == 1) {
                    setConfirmLeave(false)
                    return
                } else if (adminPositions && adminPositions.length > 1) {
                    setWarningMessage(t("position_title.multiple_admin_warning"))
                    setConfirmLeave(true)
                    return
                }
            }
        } else if (currentPosition && currentPosition.PositionTypeId == Constant.PositionType.NationalExecutive) {
            if (positionTitleData && positionTitleData.ObjectList && positionTitleData.ObjectList.length > 0) {
                let adminPositions = positionTitleData.ObjectList.filter(p => p.PositionTypeId == Constant.PositionType.NationalExecutive && p.IsAdmin)
                if (!adminPositions || adminPositions.length == 0) {
                    setWarningMessage(t("position_title.no_admin_warning"))
                    setConfirmLeave(true)
                    return
                } else if (adminPositions && adminPositions.length == 1) {
                    setConfirmLeave(false)
                    return
                } else if (adminPositions && adminPositions.length > 1) {
                    setWarningMessage(t("position_title.multiple_admin_warning"))
                    setConfirmLeave(true)
                    return
                }
            }
        }
    }, [positionTitleData])

    return (
        <>
            {
                currentPosition && currentPosition.PositionTypeId != Constant.PositionType.ClubMember &&
                <>
                    <Prompt
                        when={confirmLeave}
                        message={warningMessage}
                    />
                    <div id={Constant.FixItemsContainerId}>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                            <h5 className='color-dark-blue'>{t("position_title.manage_positions")}</h5>
                        </Row>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                            <Col flex={100} className="text-right">
                                <div>
                                    <Button danger disabled={!hasPositionSelected()} onClick={deletePositionTitles}>{t("common.delete")}</Button>
                                    <Button className='btn-outline-dark-blue m-l-10' disabled={!hasPositionSelected()} onClick={editPositionTitles}>{t("common.edit")}</Button>
                                    <Button className='btn-solid-dark-blue m-l-10' onClick={addPositionTitles}>{t("common.add_new")}</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Table
                                components={components}
                                rowClassName={() => 'editable-row'}
                                {...GetTableConfigs(columnsMapping, positionTitleData?.ObjectList, positionTitleData?.TotalItems, "PositionTitleId", onTableChange, gridConfigOptions.pageNumber)}
                            />
                        </Col>
                    </Row>
                    {
                        visibleMenuAccess &&
                        <MenuAccess handleCancel={handleMenuAccessCancel} viewOnly={true} objectData={selectedObject}></MenuAccess>
                    }
                    {
                        visibleMemberList &&
                        <MemberList handleCancel={handleMemberListCancel} viewOnly={true} objectData={selectedObject} memberListType={Constant.MemberListType.PositionHolder}></MemberList>
                    }
                    {
                        visibleAddUpdatePositionTitle &&
                        <AddUpdatePositionTitle visible={visibleAddUpdatePositionTitle} handleCancel={handleAddUpdatePositions} positionTypes={positionTypes} objectData={updatePositions}></AddUpdatePositionTitle>
                    }
                </>
            }

            {currentPosition && currentPosition.PositionTypeId == Constant.PositionType.ClubMember &&
                <>
                    <Table
                        style={{ visibility: 'hidden', height: '0px' }}
                        components={components}
                        rowClassName={() => 'editable-row'}
                        {...GetTableConfigs(columnsMapping, positionTitleData?.ObjectList, positionTitleData?.TotalItems, "PositionTitleId", onTableChange, gridConfigOptions.pageNumber)}
                    />
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_access")} />
                </>
            }
        </>

    )
}

export default PositionTitleManagement;

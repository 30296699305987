import React, { useState, useEffect } from 'react';
import './dateApprovals.scss';
import { Constant, CommonService, TournamentService } from '../../../services/services';
import { useTranslation } from 'react-i18next';
import { GetTableConfigs_SimplePaginator, EditableCell, ActionButtonsCell, DateTimeCell } from '../../../components/customTable/customTable'
import LoadingContext from '../../../components/loading/loadingContext'
import EnhancedSelect from '../../../components/enhancedSelect/enhancedSelect';
import { FilterAltIcon, CloseIcon, EditIcon } from '../../../components/icons/icons';
import { Row, Col, Table, Button, Form, Drawer, Space, Input, 
    Typography, Select, DatePicker, Modal, Empty } from 'antd';
import PubSub from 'pubsub-js'
import DateApprovalsNote from './dateApprovalsNote'
import { useGlobalState } from './../../../utilities/globalState'

const { confirm } = Modal;
const { Title } = Typography;
const { Option } = Select;
const _ = require("lodash");

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const components = {
    body: {
        cell: EditableCell,
    },
};

const DateApprovals = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [filterForm] = Form.useForm();
    const [tournamentDatesApproveds, setTournamentDatesApproveds] = useState([]);
    const [entities, setEntities] = useState([]);
    const [stateZones, setStateZones] = useState([]);

    const [totalItems, setTotalItems] = useState(0);
    const [tournaments, setTournaments] = useState([]);
    const [selectedTournaments, setSelectedTournaments] = useState([]);
    const [searchTournament, setSearchTournament] = useState({ IsApplyForDateApprovals: true, StatusIds: Constant.StatusCode.Active });
    const [selectedTournament, setSelectedTournament] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const [enableSubmit, setEnableSubmit] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);

    const [gridConfigOptions, setGridConfigOptionsValue] = useState({
        pageNumber: 1,
        pageSize: CommonService.getPageSize(),
        sort: "StartDate-descend"
    })
    const [currentPosition] = useGlobalState('currentPosition');

    const search = (pageNumber, pageSize, sort, query, isResetSelected, isShowLoading = false) => {
        if (isShowLoading) {
            showLoading();
        }

        let params = { ...query };
        if (!params) params = { IsApplyForDateApprovals: true, StatusIds: [Constant.StatusCode.Active] };

        params.IsApplyForDateApprovals = true;
        TournamentService.getTournaments(pageNumber, pageSize, sort, params)
            .finally(() => {
                if (isShowLoading) {
                    dismissLoading();
                }

                if (isResetSelected) {
                    setSelectedTournaments([]);
                }
                setEnableSubmit(selectedTournaments && selectedTournaments.length > 0);
            })
            .then(result => {
                if (result.data) {
                    var newTournaments = result.data.ObjectList;
                    if (newTournaments && newTournaments.length > 0) {
                        for (var i = 0; i < newTournaments.length; i++) {
                            var index = selectedTournaments.findIndex(x => x.Id === newTournaments[i].Id);
                            if (index > -1) {
                                newTournaments[i].ApprovalNotes = selectedTournaments[index].ApprovalNotes;
                            }
                        }
                    }
                    setTournaments(newTournaments);
                    setTotalItems(result.data.TotalItems);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    const canSubmit = (newSelectedTournaments) => {
        if (newSelectedTournaments && newSelectedTournaments.length > 0) {
            return true;
        }
        return false;
    }

    const handleDatesApprovedStatusOnChange = (value, record, dataIndex) => {
        if (record) {
            var index = tournaments.findIndex(x => x.Id === record.Id);
            _.update(tournaments[index], dataIndex, function (n) {
                return value;
            });
            var newSelectedTournaments = selectedTournaments.filter(x => x.Id !== record.Id);
            newSelectedTournaments.push(tournaments[index]);
            setSelectedTournaments(newSelectedTournaments);
            setEnableSubmit(canSubmit(newSelectedTournaments));
        }
        else {
            setEnableSubmit(canSubmit(selectedTournaments));
        }
    }

    const getActionButtonsInfo = (item) => {
        return [
            {
                Description: t("common.edit"),
                Icon: <EditIcon />,
                ColorClass: Constant.ColorClass.LightBlue,
                handleAction: editDateApprovalsNote
            },
        ]
    }

    const columns = [
        {
            title: t('tournament.club'),
            dataIndex: 'EntityAcronym',
            key: 'EntityAcronym',
            sorter: true,
            width: 60
        },
        {
            title: t('tournament.zone'),
            dataIndex: 'StateZoneName',
            key: 'StateZoneName',
            sorter: true,
            width: 70
        },
        {
            title: t('tournament.title'),
            dataIndex: 'Title',
            key: 'Title',
            sorter: true,  
            width: 140
        },
        {
            title: t('tournament.start_date'),
            dataIndex: 'StartDate',
            key: 'StartDate',
            sorter: true,
            defaultSortOrder: 'descend',
            width: 80,
            render: (value, record) => (
                <DateTimeCell value={record.StartDate} />
            )
        },
        {
            title: t('tournament.end_date'),
            dataIndex: 'FinishDate',
            key: 'FinishDate',
            sorter: true,
            width: 70,
            render: (value, record) => (
                <DateTimeCell value={record.FinishDate} />
            )
        },
        {
            title: t('tournament.alternate_start_date_short'),
            dataIndex: 'AlternateStartDate',
            key: 'AlternateStartDate',
            sorter: true,
            width: 70,
            render: (value, record) => (
                <DateTimeCell value={record.AlternateStartDate} />
            )
        },
        {
            title: t('tournament.alternate_end_date_short'),
            dataIndex: 'AlternateFinishDate',
            key: 'AlternateFinishDate',
            sorter: true,
            width: 70,
            render: (value, record) => (
                <DateTimeCell value={record.AlternateFinishDate} />
            )
        },
        {
            title: t('common.status'),
            dataIndex: 'DatesApprovedId',
            key: 'DatesApprovedId',
            editable: true,
            required: true,
            extraData: tournamentDatesApproveds,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "Name",
            selectionSort: "Id",
            width: 100,
            handleOnChange: handleDatesApprovedStatusOnChange
        },
        {
            title: t('tournament.notes'),
            dataIndex: 'Name',
            key: 'Name',
            align: "center",
            width: 45,
            render: (value, record) => {
                return <ActionButtonsCell btnList={getActionButtonsInfo(record)} data={record} />
            },
        },
    ]

    const mainColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                disabled: col.disabled,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition,
                selectionKey: col.selectionKey,
                selectionDisplay: col.selectionDisplay,
                validateAtStart: col.validateAtStart,
                allowClear: col.allowClear,
                selectionSort: col.selectionSort
            }),
        };
    })

    const onTableChange = (pagination, filters, sorter, extra) => {
        if (currentPage !== pagination.current) {
            if (selectedTournaments.length > 0) {
                confirm({
                    title: t("tournament.leave_warning_message"),
                    okText: t("common.yes"),
                    cancelText: t("common.no"),
                    onOk() {
                        searchNexPage(pagination, filters, sorter, extra);
                    },
                    onCancel() {
                        console.log('Cancel');
                    }
                });
                return;
            }
        }
        searchNexPage(pagination, filters, sorter, extra);
    }

    const searchNexPage = (pagination, filters, sorter, extra) => {
        setCurrentPage(pagination.current);

        let data = filterForm.getFieldsValue();
        if (!data || (data && !data.StatusIds) || (data && !data.IsApplyForDateApprovals)) {
            data.StatusIds = [Constant.StatusCode.Active];
            data.IsApplyForDateApprovals = true;
        }
        setSearchTournament(data);

        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = gridConfigOptions.sort;
        }
        search(pagination.current, pagination.pageSize, sort, data, true);

        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        });
    }

    // Filters
    const showFilterLayout = () => {
        setOpenFilter(true);
    }

    const closeFilterLayout = () => {
        filterForm.setFieldsValue(searchTournament);
        setOpenFilter(false);
    }

    const resetFilter = () => {
        filterForm.resetFields();
        setSearchTournament({ IsApplyForDateApprovals: true, StatusIds: Constant.StatusCode.Active });
    }

    const applyFilter = () => {

        refreshData(false, true);

        setOpenFilter(false);
    }

    const refreshData = (isResetSelected, isShowLoading = false) => {

        let data = filterForm.getFieldsValue();
        if (!data || (data && !data.StatusIds) || (data && !data.IsApplyForDateApprovals)) {
            data.StatusIds = [Constant.StatusCode.Active];
            data.IsApplyForDateApprovals = true;
        }
        setSearchTournament(data);

        search(1, gridConfigOptions.pageSize, gridConfigOptions.sort, data, isResetSelected, isShowLoading);

        setGridConfigOptionsValue(
            {
                ...gridConfigOptions,
                pageNumber: 1
            }
        );

    }

    // Update Date Approvals Note
    const editDateApprovalsNote = (record) => {
        setSelectedTournament(record);
        setVisibleModal(true);
    }

    // Update Dates Approved Statuses
    const handleCancel = (newTournament, changed) => {
        setVisibleModal(false);
        if (changed) {
            // refreshData();
            var newSelectedTournaments = selectedTournaments.filter(x => x.Id !== newTournament.Id);
            newSelectedTournaments.push(newTournament);
            setSelectedTournaments(newSelectedTournaments);
            setEnableSubmit(canSubmit(newSelectedTournaments));
        }
    }

    const onSubmit = () => {
        if (selectedTournaments.length === 0) {
            return;
        }

        showLoading();
        TournamentService.updateTournamentDatesApprovedStatuses(selectedTournaments)
            .finally(() => {
                dismissLoading();
                setEnableSubmit(false);
            })
            .then(result => {
                if (result.data) {
                    CommonService.presentToast('success', t('tournament.tournaments_update_status_successful'));
                    refreshData(true);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const onCancel = () => {
        refreshData(false);
    }

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
        }, 100)

        showLoading()
        Promise.all([
            TournamentService.getTournaments(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, { IsApplyForDateApprovals: true, StatusIds: Constant.StatusCode.Active }),
            TournamentService.getFilterDatas()
        ])
            .finally(() => {
                dismissLoading();

                setSelectedTournaments([]);
                setSearchTournament({ IsApplyForDateApprovals: true, StatusIds: [Constant.StatusCode.Active] });
            })
            .then(response => {
                if (response[0].data) {
                    setTournaments(response[0].data.ObjectList);
                    setTotalItems(response[0].data.TotalItems);
                }

                if (response[1].data) {
                    setTournamentDatesApproveds(response[1].data.TournamentDatesApproveds);
                    setEntities(response[1].data.Entities);
                    setStateZones(response[1].data.StateZones);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);
        return () => {
            TournamentService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    return (
        <>
         {currentPosition && (currentPosition.PositionTypeId == Constant.PositionType.StateExcutive ||
            currentPosition.PositionTypeId == Constant.PositionType.NationalExecutive) &&
            <div className='date-approvals-section'>
                <div id={Constant.FixItemsContainerId}>
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                <Title className='color-dark-blue' level={5}>{t("menu.tournaments_date_approvals")}</Title>
                            </Space>
                        </Col>
                    </Row>
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-b-10'>
                        <Col flex={100} className="text-right">
                            <div>
                                <Button onClick={showFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon />}>{t("common.filter")}</Button>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row className='date-approvals-section-table' gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                    <Col xs={{ span: 24 }}>
                        <Table
                            components={components}
                            rowClassName={() => 'editable-row'}
                            {...GetTableConfigs_SimplePaginator(mainColumns, tournaments, totalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                            rowKey="Id"
                        />
                    </Col>
                </Row>

                <Row className="fix-item-container">
                    <Col xs={{ span: 24 }} className="text-right extra-btn-m-t">
                        <div className="m-t-10">
                            <Button size="large" className="m-r-5" onClick={onCancel}>{t("common.cancel")}</Button>
                            <Button size="large" type="primary" htmlType="submit" disabled={!enableSubmit} onClick={onSubmit}>{t("common.save")}</Button>
                        </div>
                    </Col>
                </Row>

                {/* Filter */}
                <Drawer
                    title={t("common.filter")}
                    width={378}
                    onClose={closeFilterLayout}
                    open={openFilter}
                    closeIcon={false}
                    styles={{
                        body: {
                            paddingBottom: 80,
                        }
                    }}
                    extra={
                        <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                            <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                            <Button onClick={closeFilterLayout} icon={<CloseIcon />} type="link" />
                        </Space>
                    }
                    footer={
                        <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                            <Button onClick={closeFilterLayout}>{t("common.cancel")}</Button>
                            <Button onClick={applyFilter} type="primary">
                                {t("common.apply")}
                            </Button>
                        </Space>
                    }>
                    <Form
                        layout="vertical"
                        {...layout}
                        form={filterForm}
                        name="filterForm"
                        key='filterForm'
                    >
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="EntityIds"
                                    label={t("tournament.club")}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={entities ? entities.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="StateZoneIds"
                                    label={t("tournament.zone")}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={stateZones ? stateZones.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="Title"
                                    label={t("tournament.title")}
                                >
                                    <Input allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="StartDate"
                                    label={t('tournament.start_date')}
                                >
                                    <DatePicker format={Constant.DateFormat} placeholder={Constant.DateFormat} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="FinishDate"
                                    label={t('tournament.end_date')}
                                >
                                    <DatePicker format={Constant.DateFormat} placeholder={Constant.DateFormat} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="AlternateStartDate"
                                    label={t('tournament.alternate_start_date_short')}
                                >
                                    <DatePicker format={Constant.DateFormat} placeholder={Constant.DateFormat} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="AlternateFinishDate"
                                    label={t('tournament.alternate_end_date_short')}
                                >
                                    <DatePicker format={Constant.DateFormat} placeholder={Constant.DateFormat} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Drawer>

                {/* Update Note */}
                {
                    visibleModal &&
                    <DateApprovalsNote
                        handleCancel={handleCancel}
                        selectedTournament={selectedTournament} />
                }
             </div>
}
{currentPosition && currentPosition.PositionTypeId != Constant.PositionType.StateExcutive && 
currentPosition.PositionTypeId != Constant.PositionType.NationalExecutive &&
                <>
                    <Table
                        style={{ visibility: 'hidden', height: '0px' }}
                        {...GetTableConfigs_SimplePaginator(mainColumns, [], 0, "Id", onTableChange, gridConfigOptions.pageNumber)}
                            
                    />
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_access")} />
                </>
            }
        </>
    )
}

export default DateApprovals;
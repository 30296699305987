import React, { useEffect, useState } from 'react';
import './App.scss';
import AppRoutes from "./utilities/appRoutes";
import { Constant, UserService, StorageService, CommonService } from './services/services'
import PubSub from 'pubsub-js'
import { useGlobalState, resetGlobalState } from './utilities/globalState'
import { useNavigate } from "react-router-dom";
import { theme, ConfigProvider } from 'antd';
import LoadingProvider from './components/loading/loadingProvider'
import Spinner from './components/loading/spinner/spinner'

const config = {
  token: {
    colorPrimary: Constant.CustomizeTheme.ColorBlue,
    colorError: Constant.CustomizeTheme.ColorDarkRed,
    fontFamily: Constant.CustomizeTheme.FontFamily,
    colorLink: Constant.CustomizeTheme.ColorDarkBlue,
    controlHeight: Constant.CustomizeTheme.ControlHeight,
    colorText: Constant.CustomizeTheme.ColorDarkBlue,
    colorTextLabel: Constant.CustomizeTheme.ColorDarkBlue,
    colorTextHeading: Constant.CustomizeTheme.ColorBlack,
    fontSize: Constant.CustomizeTheme.FontSize,
    colorBorder: Constant.CustomizeTheme.ColorInputBorder,
    colorBgContainerDisabled: Constant.CustomizeTheme.ColorTransparentGrey,
    colorTextDisabled: Constant.CustomizeTheme.ColorMediumGrey
  },
  components: {
    Input: {
      colorTextHeading: Constant.CustomizeTheme.ColorBlack,
      colorText: Constant.CustomizeTheme.ColorDarkBlue
    },
    Table: {
      headerColor: Constant.CustomizeTheme.ColorDarkBlue,
      colorText: Constant.CustomizeTheme.ColorBlack
    },
    Breadcrumb: {
      lastItemColor: Constant.CustomizeTheme.ColorDarkBlue1,
      itemColor: Constant.CustomizeTheme.ColorDarkBlue1,
      separatorColor: Constant.CustomizeTheme.ColorDarkBlue1
    },
    Layout: {
      bodyBg: '#FAFBFF',
      siderBg: 'white',
      headerBg: 'white',
      headerPadding: 0
    },
    Typography: {
      titleMarginBottom: 0
    }
  },
};

// By static function

function App() {
  const [userPositions, setUserPositions] = useGlobalState('userPositions');
  const [currentPosition, setCurrentPosition] = useGlobalState('currentPosition');

  useEffect(() => {
    init()
  }, [])

  const init = () => {
    var authData = StorageService.getAuthData()
    if (authData) {
      if (authData.positions && (!userPositions || userPositions.length == 0)) {
        setUserPositions(authData.positions)
      }
      if (!currentPosition || !currentPosition.PositionTitleId) {
        let data = StorageService.getCurrentPosition()
        setCurrentPosition(data)
      }
    }
  }

  return (

    <ConfigProvider
      theme={config}
    >
      <div className="App">
        <LoadingProvider>
          <Spinner color="#fafafa" />
          <AppRoutes />
        </LoadingProvider>
      </div>
    </ConfigProvider>
  );

}

export default App;

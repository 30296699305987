import React, { useState, useEffect } from 'react';
import { Constant, CommonService, TournamentService, SettingService } from '../../services/services';
import { useTranslation } from 'react-i18next';
import LoadingContext from './../../components/loading/loadingContext'
import { Row, Col, Button, Form, Space, Input, Typography, DatePicker, Select, Modal, Table, Drawer, Empty } from 'antd';
import PubSub from 'pubsub-js'
import { GetTableConfigs, EditableCell, ActionButtonsCell, DateTimeCell } from '../../components/customTable/customTable'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useGlobalState } from '../../utilities/globalState';
import { FilterAltIcon, CloseIcon, EditIcon, EyeIcon } from './../../components/icons/icons';
import ConfirmLeavePage from "../../components/confirmLeavePage/confirmLeavePage";
import './stateTournamentSanctioningApprovals.scss';
import EnhancedSelect from '../../components/enhancedSelect/enhancedSelect'
const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const { confirm } = Modal;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const defaultGridOption = {
    pageNumber: 1,
    pageSize: CommonService.getPageSize(),
    sort: "StartDate-descend"
}

const StateTournamentSanctioningApprovals = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [submitForm] = Form.useForm();
    const [currentPosition] = useGlobalState('currentPosition');
    const [entityDetail, setEntityDetail] = useState({})
    const [tournamentSanctioned, setTournamentSanctioned] = useState([]);
    const [formFilter] = Form.useForm();
    const [tournamentData, setTournamentData] = useState([]);
    const [confirmLeave, setConfirmLeave] = useState(false)
    const [isDirty, setIsDirty] = useState(false);
    const [entities, setEntities] = useState([]);
    const [stateZones, setStateZones] = useState([]);
    const [selectedObj, setSelectedObj] = useState({});
    const [enableNoteModal, setEnableNoteModal] = useState(false);
    const [enableFilterModal, setEnableFilterModal] = React.useState(false);
    const [tournamentFilter, setTournamentFilter] = React.useState({});
    const [approvalNotesForm] = Form.useForm();
    const [gridConfigOptions, setGridConfigOptionsValue] = useState(defaultGridOption)
    const [selectedTournamentData, setSelectedTournamentData] = useState([]);
    const [sortedInfo, setSortedInfo] = useState({});

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
        }, 100)
        showLoading()
        Promise.all([
            SettingService.getStateEntityDetail(),
            TournamentService.getFilterDatas()
        ])
            .finally(() => dismissLoading())
            .then(response => {
                if (response[0] && response[0].data) {
                    setEntityDetail(response[0].data)
                }

                if (response[1] && response[1].data) {
                    setTournamentSanctioned(response[1].data.TournamentSanctioneds);
                    setEntities(response[1].data.Entities);
                    setStateZones(response[1].data.StateZones);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })

        getTournaments(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort)
        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);
        return () => {
            SettingService.cancelRequest()
            TournamentService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    const getTournaments = (pageNumber, pageSize, sort, filter) => {
        showLoading();
        if (!filter) {
            filter = { ...tournamentFilter }
        }
        filter.IsTournamentSanctioningApprovals = true
        TournamentService.getTournaments(pageNumber, pageSize, sort, filter)
            .finally(() => {
                dismissLoading();
            })
            .then(result => {
                if (result.data) {
                    setIsDirty(false)
                    setConfirmLeave(false)
                    setSelectedTournamentData([])
                    setTournamentData(result.data);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    const canSubmit = () => {
        if (isDirty && selectedTournamentData && selectedTournamentData.length > 0) {
            return true;
        }
        return false;
    }

    const updateChangedValue = (value, record, dataIndex) => {
        setIsDirty(true)
        let tournament = tournamentData
        let selectedRecords = tournament.ObjectList.filter(x => x.Id == record.Id)
        if (selectedRecords && selectedRecords.length > 0) {
            let index = tournament.ObjectList.indexOf(selectedRecords[0])
            if (tournament.ObjectList[index]) {
                tournament.ObjectList[index][dataIndex] = value;
                setTournamentData(tournament)
                setConfirmLeave(true)
            }
        }

        let selectedTournaments = selectedTournamentData.filter(x => x.Id == record.Id)
        if (!selectedTournaments || selectedTournaments.length == 0)
            selectedTournamentData.push(record)
        else {
            selectedTournaments[0][dataIndex] = value
        }
        setSelectedTournamentData(selectedTournamentData)
    }

    const handleOnChange = (value, record, dataIndex) => {
        if (record) {
            updateChangedValue(value, record, dataIndex)
        }
    }

    const enableSanctioningNote = (record) => {
        setSelectedObj(record);
        setEnableNoteModal(true);
        approvalNotesForm.setFieldValue('SanctioningApprovalNotes', record.SanctioningApprovalNotes)
    }

    const sanctionedNoteHandleClose = () => {
        setEnableNoteModal(false);
        approvalNotesForm.resetFields()
    }

    const sanctionedNoteUpdate = () => {
        let values = approvalNotesForm.getFieldsValue()
        setEnableNoteModal(false);
        if (selectedObj) {
            updateChangedValue(values.SanctioningApprovalNotes, selectedObj, "SanctioningApprovalNotes")
        }
    }

    const getActionButtonsInfo = (item) => {
        return [
            {
                Description: t("common.edit"),
                Icon: <EditIcon />,
                ColorClass: Constant.ColorClass.LightBlue,
                handleAction: enableSanctioningNote
            },
        ]
    }

    const columns = [
        {
            title: t('tournament.club'),
            dataIndex: 'EntityAcronym',
            key: 'EntityAcronym',
            sorter: true,
            sortOrder: sortedInfo && sortedInfo.columnKey === 'EntityAcronym' ? sortedInfo.order : null,
            width: 150
        },
        {
            title: t('tournament.zone'),
            dataIndex: 'StateZoneName',
            key: 'StateZoneName',
            sorter: true,
            sortOrder: sortedInfo && sortedInfo.columnKey === 'StateZoneName' ? sortedInfo.order : null,
            width: 130
        },
        {
            title: t('tournament.title'),
            dataIndex: 'Title',
            key: 'Title',
            sorter: true,
            sortOrder: sortedInfo && sortedInfo.columnKey === 'Title' ? sortedInfo.order : null,
            width: 200
        },
        {
            title: t('tournament.start_date'),
            dataIndex: 'StartDate',
            key: 'StartDate',
            sorter: true,
            sortOrder: sortedInfo && sortedInfo.columnKey === 'StartDate' ? sortedInfo.order : null,
            width: 130,
            render: (value, record) => (
                <DateTimeCell value={record.StartDate} />
            )
        },
        {
            title: t('tournament.end_date'),
            dataIndex: 'FinishDate',
            key: 'FinishDate',
            sorter: true,
            sortOrder: sortedInfo && sortedInfo.columnKey === 'FinishDate' ? sortedInfo.order : null,
            width: 130,
            render: (value, record) => (
                <DateTimeCell value={record.FinishDate} />
            )
        },
        {
            title: t('common.status'),
            dataIndex: 'SanctionedId',
            key: 'SanctionedId',
            editable: true,
            extraData: tournamentSanctioned,
            cellType: Constant.CellType.Selection,
            selectionKey: "Id",
            selectionDisplay: "Name",
            width: 260,
            handleOnChange: handleOnChange
        },
        {
            title: t('tournament.notes'),
            align: "center",
            width: 50,
            render: (value, record) => {
                return <ActionButtonsCell btnList={getActionButtonsInfo(record)} data={record} />
            },
        },
        {
            title: t('sanctioning_applications.flyer'),
            key: 'Id',
            render: (value, record) => {
                return <>
                    {

                        record.FlyerUrl &&
                        <ActionButtonsCell data={record} btnList={[
                            {
                                Description: t("common.view"),
                                Icon: <EyeIcon />,
                                ColorClass: Constant.ColorClass.LightBlue,
                                Hide: false,
                                NoMargin: true,
                                Type: Constant.ButtonType.Link,
                                Link: record.FlyerUrl
                            }
                        ]} />
                    }
                </>
            },
            align: 'center',
            width: 80,
        },
        {
            title: t('sanctioning_applications.rules'),
            key: 'Id',
            render: (value, record) => {
                return <>
                    {
                        record.RulesUrl &&
                        <ActionButtonsCell data={record} btnList={[
                            {
                                Description: t("common.view"),
                                Icon: <EyeIcon />,
                                ColorClass: Constant.ColorClass.LightBlue,
                                Hide: false,
                                NoMargin: true,
                                Type: Constant.ButtonType.Link,
                                Link: record.RulesUrl
                            }
                        ]} />
                    }
                </>

            },
            align: 'center',
            width: 80,
        },
        {
            title: t('sanctioning_applications.entry_form'),
            key: 'Id',
            render: (value, record) => {
                return <>
                    {
                        record.EntryFormUrl &&
                        <ActionButtonsCell data={record} btnList={[
                            {
                                Description: t("common.view"),
                                Icon: <EyeIcon />,
                                ColorClass: Constant.ColorClass.LightBlue,
                                Hide: false,
                                NoMargin: true,
                                Type: Constant.ButtonType.Link,
                                Link: record.EntryFormUrl
                            }
                        ]} />
                    }
                </>

            },
            align: 'center',
            width: 80,
        }
    ]

    const columnsMapping = columns.filter(c => !c.hidden).map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition,
                selectionKey: col.selectionKey,
                selectionDisplay: col.selectionDisplay,
            })
        };
    })

    const closeFilterModal = () => {
        setEnableFilterModal(false)
    }

    const showFilterModal = () => {
        setEnableFilterModal(true)
    }

    const applyFilter = () => {
        setEnableFilterModal(false)
        let filter = formFilter.getFieldsValue();
        onCancelSanctioningAppovals(t("tournament_sanctioning_approvals.updated_tournament_confirm"), gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, filter, sortedInfo)
    }

    const resetFilter = () => {
        formFilter.resetFields();
        setTournamentFilter({});
        setSelectedTournamentData([])
    }

    const onTableChange = (pagination, filters, sorter, extra) => {
        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = defaultGridOption.sort;
        }
        onCancelSanctioningAppovals(t("tournament_sanctioning_approvals.updated_tournament_confirm"), pagination.current, pagination.pageSize, sort, tournamentFilter, sorter)
    }

    const components = {
        body: {
            cell: EditableCell,
        }
    };

    const onSaveSanctioningAppovals = () => {
        setConfirmLeave(false)
        updateSanctioningAppovals(gridConfigOptions.pageNumber, 
                            gridConfigOptions.pageSize, 
                            gridConfigOptions.sort, 
                            tournamentFilter)
    }

    const onCancelSanctioningAppovals = (message, pageNumber, pageSize, sort, filter, sortedInfo) => {
        if (isDirty) {
            confirm({
                title: t("common.confirm"),
                icon: <ExclamationCircleOutlined />,
                content: message,
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    setConfirmLeave(false)
                    updateSanctioningAppovals(pageNumber, pageSize, sort, filter)
                    setGridConfigOptionsValue({
                        pageNumber: pageNumber,
                        pageSize: pageSize,
                        sort: sort
                    })
                    setSortedInfo(sortedInfo)
                    setTournamentFilter(filter)
                },
                onCancel() {
                    setTournamentData([])
                    setConfirmLeave(false)
                    getTournaments(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, tournamentFilter)
                    formFilter.setFieldsValue({
                        EntityIds: tournamentFilter.EntityIds,
                        StateZoneIds: tournamentFilter.StateZoneIds,
                        Title: tournamentFilter.Title,
                        StartDate: tournamentFilter.StartDate,
                        FinishDate: tournamentFilter.FinishDate
                    })
                }

            });
        } else {
            setTournamentData([])
            setConfirmLeave(false)
            setSortedInfo(sortedInfo)
            setTournamentFilter(filter)
            setGridConfigOptionsValue({
                pageNumber: pageNumber,
                pageSize: pageSize,
                sort: sort
            })
            getTournaments(pageNumber, pageSize, sort, filter)
        }
    }

    const updateSanctioningAppovals = (pageNumber, pageSize, sort, filter) => {
        if (selectedTournamentData.length === 0) {
            return;
        }

        showLoading();
        TournamentService.updateTournamentSanctioningApprovals(selectedTournamentData)
            .finally(() => {
                dismissLoading();

            })
            .then(result => {
                CommonService.presentToast('success', t('tournament.update_status_successful'));
                setSelectedTournamentData([]);
                getTournaments(pageNumber, pageSize, sort, filter)
                setConfirmLeave(false)
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const closeNotesModal = () => {
        approvalNotesForm.resetFields()
        setEnableNoteModal(false)
    }

    return (
        <>
            {
                currentPosition && (currentPosition.PositionTypeId == Constant.PositionType.StateExcutive ||
                    currentPosition.PositionTypeId == Constant.PositionType.NationalExecutive) &&
                <div className='sanctioning-approval'>
                    <ConfirmLeavePage
                        shouldPrompt={confirmLeave}
                    >
                        {({ leavePage, stayOnPage, isDialogActive }) => (
                            <Modal
                                title={t("common.warning")}
                                open={isDialogActive}
                                onOk={leavePage}
                                onCancel={stayOnPage}
                            >
                                <p>
                                    {t("entity_detail.leave_warning")}
                                </p>
                            </Modal>
                        )}
                    </ConfirmLeavePage>
                    <div id={Constant.FixItemsContainerId}>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-b-10'>
                            <Col xs={{ span: 24 }}>
                                <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                    <Title className='color-dark-blue' level={5}>{t("tournament_sanctioning_approvals.title")}</Title>
                                </Space>
                            </Col>
                        </Row>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                            <Col flex={100} className="text-right">
                                <div>
                                    <Button onClick={showFilterModal} className='btn-outline-blue btn-icon' icon={<FilterAltIcon />}>{t("common.filter")}</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Table
                                components={components}
                                pagination={false}
                                {...GetTableConfigs(columnsMapping, tournamentData?.ObjectList, tournamentData?.TotalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                            />
                        </Col>
                    </Row>
                    <Row align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter]}>
                        <Col xs={{ span: 24 }} className="text-right m-t-25">
                            <Button size="large" className="m-r-5" onClick={() => onCancelSanctioningAppovals(t("common.submit_confirm"), gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, tournamentFilter)}>{t("common.cancel")}</Button>
                            <Button size="large" type="primary" onClick={onSaveSanctioningAppovals} disabled={!canSubmit()}>{t("common.save")}</Button>
                        </Col>
                    </Row>
                </div>
            }

            <Drawer
                title={t("common.filter")}
                width={378}
                onClose={closeFilterModal}
                open={enableFilterModal}
                closeIcon={false}
                styles={{
                    body: {
                        paddingBottom: 80,
                    }
                }}
                extra={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                        <Button onClick={closeFilterModal} icon={<CloseIcon />} type="link" />
                    </Space>
                }
                footer={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button onClick={closeFilterModal}>{t("common.cancel")}</Button>
                        <Button onClick={applyFilter} type="primary">
                            {t("common.apply")}
                        </Button>
                    </Space>
                }>
                <Form
                    layout="vertical"
                    {...layout}
                    form={formFilter}
                    name="filterForm"
                    key='filterForm'
                >
                    <Col span={24}>
                        <Form.Item
                            name="EntityIds"
                            label={t("tournament.club")}
                        >
                            <EnhancedSelect mode="multiple" allowClear={true}
                                maxTagCount='responsive'
                                showSearch optionFilterProp="children"
                                filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}
                            >
                                {
                                    entities && entities.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                }
                            </EnhancedSelect>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="StateZoneIds"
                            label={t("tournament.zone")}
                        >
                            <EnhancedSelect mode="multiple" allowClear={true}
                                showSearch optionFilterProp="children"
                                maxTagCount='responsive'
                                filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}
                            >
                                {
                                    stateZones && stateZones.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                }
                            </EnhancedSelect>
                        </Form.Item>
                    </Col>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="Title"
                                label={t("tournament.title")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="StartDate"
                                label={t('tournament.start_date')}
                            >
                                <DatePicker format={Constant.DateFormat} placeholder={Constant.DateFormat} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="FinishDate"
                                label={t('tournament.end_date')}
                            >
                                <DatePicker format={Constant.DateFormat} placeholder={Constant.DateFormat} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>

            {
                enableNoteModal &&
                <Modal
                    title={t("tournament.tournament_approval_notes")}
                    open={true}
                    onCancel={closeNotesModal}
                    confirmLoading={false}
                    maskClosable={false}
                    centered
                    width={540}
                    footer={[
                        <Button key={0} size="large" onClick={sanctionedNoteHandleClose}>{t("common.cancel")}</Button>,
                        <Button key={1} size="large" type="primary" onClick={sanctionedNoteUpdate}>{t("common.ok")}</Button>
                    ]}
                >
                    <Form
                        {...layout}
                        layout="vertical"
                        form={approvalNotesForm}
                        name="approvalNotesForm"
                        key='approvalNotesForm'
                        initialValues={selectedObj}>

                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="SanctioningApprovalNotes" colon={false} labelAlign="left">
                                    <TextArea rows={6} />
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>

                </Modal>
            }
             {currentPosition && currentPosition.PositionTypeId != Constant.PositionType.StateExcutive && currentPosition.PositionTypeId != Constant.PositionType.NationalExecutive &&
                <div className='m-t-10'>
                    <Table
                        style={{ visibility: 'hidden', height: '0px' }}
                        {...GetTableConfigs(columnsMapping, [], tournamentData?.length, "Id", null, 1, null, false)}
                    />
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_access")} />
                </div >
            }
        </>)

}

export default StateTournamentSanctioningApprovals;


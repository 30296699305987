import React, { useEffect, useState } from 'react';
import './tagCardTableView.scss';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
    Constant,
    CommonService,
    StorageService,
    FishingActivityService
} from '../../services/services'
import { Table, Button, Row, Col, Form, Skeleton, Modal, Descriptions } from 'antd';
import { GetTableConfigs, ActionButtonsCell, EditableCell } from '../customTable/customTable'
import { EyeIcon, TrashIcon } from '../icons/icons'
import LoadingContext from '../loading/loadingContext'
import { useGlobalState } from '../../utilities/globalState'


const { confirm } = Modal;
const _ = require("lodash");

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const components = {
    body: {
        cell: EditableCell,
    },
};

const TagCardTableView = (props) => {
    const { t } = useTranslation();
    const [tableViewForm] = Form.useForm();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext);
    const [loading, setLoading] = useState(false);

    const [activityLines, setActivityLines] = useState([]);
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [disabledStateView, setDisabledStateView] = useState(false);
    const [isClubMember, setIsClubMember] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [currentPosition] = useGlobalState('currentPosition');


    const disableEditDayLog = (item) => {
        if (props.isViewOnly)
            return true
        if (currentPosition && item) {
            if (currentPosition.PositionTypeId == Constant.PositionType.ClubMember &&
                (item.ClubApprovalId || item.StateApprovalId))
                return true
        }
        return false
    }

    const onSaveActivityLines = () => {
        if (activityLines === null) {
            return;
        }

        // Validate
        var allErrorMessages = [];
        setActivityLines(current =>
            current.map(obj => {
                var errorMessages = [];
                if (!obj.TagCaptureId) {
                    errorMessages.push({
                        key: 'TagCaptureId',
                        value: t('fishing_activity.tag_capt_is_required')
                    })
                }

                if (obj.TagCaptureId === Constant.TagCaptureCode.T_R && !obj.TagNumber) {
                    errorMessages.push({
                        key: 'TagNumber',
                        value: t('fishing_activity.tag_number_is_required')
                    })
                }

                if (obj.TagNumber && obj.TagNumber.length > 0 && !CommonService.verifyTagNumber(obj.TagNumber)) {
                    errorMessages.push({
                        key: 'TagNumber',
                        value: t('fishing_activity.tag_number_is_invalid')
                    })
                }

                if (obj.OldTagNumber && obj.OldTagNumber.length > 0 && !CommonService.verifyTagNumber(obj.OldTagNumber)) {
                    errorMessages.push({
                        key: 'OldTagNumber',
                        value: t('fishing_activity.old_tag_number_is_invalid')
                    })
                }

                if (!obj.FishingMethodId) {
                    errorMessages.push({
                        key: 'FishingMethodId',
                        value: t('fishing_activity.method_is_required')
                    })
                }

                if (!obj.CaughtTime) {
                    errorMessages.push({
                        key: 'CaughtTime',
                        value: t('fishing_activity.time_is_required')
                    })
                }

                if (!obj.FishSpeciesId) {
                    errorMessages.push({
                        key: 'FishSpeciesId',
                        value: t('fishing_activity.species_is_required')
                    })
                }

                if (!obj.LineClassId) {
                    errorMessages.push({
                        key: 'LineClassId',
                        value: t('fishing_activity.line_class_is_required')
                    })
                }

                if (!obj.AnglerId) {
                    errorMessages.push({
                        key: 'AnglerId',
                        value: t('fishing_activity.angler_is_required')
                    })
                }

                if (!obj.FishingLocationId) {
                    errorMessages.push({
                        key: 'FishingLocationId',
                        value: t('fishing_activity.location_is_required')
                    })
                }

                if (!obj.GpsLatitude) {
                    errorMessages.push({
                        key: 'GpsLatitude',
                        value: t('fishing_activity.gps_latitude_is_required')
                    })
                }

                if (!obj.GpsLongitude) {
                    errorMessages.push({
                        key: 'GpsLongitude',
                        value: t('fishing_activity.gps_longitude_is_required')
                    })
                }

                if (!obj.StatePointScoreZoneId) {
                    errorMessages.push({
                        key: 'StatePointScoreZoneId',
                        value: t('fishing_activity.state_zone_is_required')
                    })
                }

                if (!obj.IsFlyfishing) {
                    errorMessages.push({
                        key: 'IsFlyfishing',
                        value: t('fishing_activity.flyfishing_is_required')
                    })
                }

                if (!obj.IsSaltwater) {
                    errorMessages.push({
                        key: 'IsSaltwater',
                        value: t('fishing_activity.saltwater_is_required')
                    })
                }
                allErrorMessages.push(...errorMessages);
                return {
                    ...obj,
                    required: errorMessages.length > 0 ? true : false,
                    ErrorMessages: errorMessages,
                };
            }),
        );

        if (allErrorMessages.length > 0) {
            CommonService.presentToast('error', allErrorMessages.map(x => x.value).join(', '));
            return;
        }

        activityLines.forEach(activityLine => {
            if (activityLine.IsFlyfishing === 1) {
                activityLine.IsFlyfishing = true;
            } else if (activityLine.IsFlyfishing === 2) {
                activityLine.IsFlyfishing = false;
            }

            if (activityLine.IsSaltwater === 1) {
                activityLine.IsSaltwater = true;
            } else if (activityLine.IsSaltwater === 2) {
                activityLine.IsSaltwater = false;
            }

            if (activityLine.IsInsideClubWaters === 1) {
                activityLine.IsInsideClubWaters = true;
            } else if (activityLine.IsInsideClubWaters === 2) {
                activityLine.IsInsideClubWaters = false;
            }

            if (activityLine.ClubEventId == -1) {
                activityLine.ClubEventId = null;
            }

            if (activityLine.StateEventId == -1) {
                activityLine.StateEventId = null;
            }

            if (activityLine.DateEntered) {
                var newDateEntered = activityLine.DateEntered;
                if (typeof newDateEntered === 'string' || newDateEntered instanceof String) {
                    var length = newDateEntered.split('/').length;
                    if (length >= 3) {
                        activityLine.DateEntered = dayjs(newDateEntered, Constant.DateFormatYYYMMDD);
                        activityLine.DateEnteredExtra = activityLine.DateEntered;
                    }
                } else {
                    activityLine.DateEntered = newDateEntered.format(Constant.DateFormatYYYMMDD);
                    activityLine.DateEnteredExtra = activityLine.DateEntered;
                }
            }
        });

        // Add/Update
        showLoading();
        var newActivityHeader = props.activityHeader;
        newActivityHeader.ActivityLines = activityLines;
        if (newActivityHeader.ClubEventId == -1) {
            newActivityHeader.ClubEventId = null;
        }

        if (newActivityHeader.StateEventId == -1) {
            newActivityHeader.StateEventId = null;
        }

        if (newActivityHeader.ActivityDate) {
            if (typeof newActivityHeader.ActivityDate === 'string' || newActivityHeader.ActivityDate instanceof String) {
                var length = newActivityHeader.ActivityDate.split('/').length;
                if (length >= 3) {
                    newActivityHeader.ActivityDate = dayjs(newActivityHeader.ActivityDate, Constant.DateFormatYYYMMDD);
                }
            } else {
                newActivityHeader.ActivityDate = newActivityHeader.ActivityDate.format(Constant.DateFormatYYYMMDD);
            }
        }

        FishingActivityService.addUpdateActivityHeader(newActivityHeader)
            .finally(() => {
                dismissLoading();
            })
            .then(result => {
                if (result.data) {
                    CommonService.presentToast('success', isEdit ? t('fishing_activity.activity_lines_update_successful') : t('fishing_activity.activity_lines_create_successful'));

                    if (isEdit) {
                        props.handleCancel()
                    } else {
                        props.handleActivityLines(props.activityHeaderId);
                    }
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const onCancel = () => {
        props.handleCancel();
    }

    const deleteActivityLine = (record) => {
        if (!record.IsAddNew && record.Id > 0) {
            confirm({
                title: t("fishing_activity.delete_activity_lines_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    showLoading();

                    FishingActivityService.deleteActivityLines([record.Id])
                        .finally(() => {
                            dismissLoading();
                        })
                        .then(result => {
                            const newActivityLines = activityLines.filter((m) => m.Id !== record.Id);
                            setActivityLines(newActivityLines);
                        })
                        .catch(error => {
                            CommonService.handleErrorResponse(error);
                        });
                }
            });
        } else {
            const newActivityLines = activityLines.filter((m) => m.Id !== record.Id);
            setActivityLines(newActivityLines);
        }
    }

    const showPointScoreModal = (record) => {
        const items = [
            {
                key: '1',
                label: t('fishing_activity.capture_points'),
                children: record.StateCapturePoints ?? 0,
            },
            {
                key: '2',
                label: t('fishing_activity.tag_release_points'),
                children: record.StateTagReleasePoints ?? 0,
            },
            {
                key: '3',
                label: t('fishing_activity.measure_release_points'),
                children: record.StateMeasureReleasePoints ?? 0,
            },
            {
                key: '4',
                label: t('fishing_activity.lead_release_points'),
                children: record.StateLeadReleasePoints ?? 0,
            },
            {
                key: '5',
                label: t('fishing_activity.total_points'),
                children: (record.StateCapturePoints ?? 0) + (record.StateTagReleasePoints ?? 0) + (record.StateMeasureReleasePoints ?? 0) + (record.StateLeadReleasePoints ?? 0),
            }
        ];

        Modal.info({
            title: t('common.point_score'),
            width: 520,
            icon: null,
            content: (
                <div>
                    <Descriptions title="" layout="horizontal" column={1} bordered items={items} />
                </div>
            ),
            onOk() { },
        });
    }

    const canSubmit = (activityLines) => {
        if (activityLines && activityLines.length > 0) {
            let inValid = activityLines.filter(x => !x.TagCaptureId || !x.FishingMethodId || !x.CaughtTime || !x.FishSpeciesId || !x.StatePointScoreZoneId || !x.IsFlyfishing || !x.IsSaltwater);
            if (inValid && inValid.length > 0) {
                return false
            }
            return true;
        }
        return false;
    }

    const handleOnChange = (value, record, dataIndex) => {
        if (record) {
            var index = activityLines.findIndex(x => x.Id === record.Id);
            _.update(activityLines[index], dataIndex, function (n) {
                return value;
            });

            if (dataIndex === 'DateEntered') {
                handlePointScores(record);
            }
        }
    }

    const handleTagCaptureOnChange = (value, record, dataIndex) => {
        if (record) {
            var index = activityLines.findIndex(x => x.Id === record.Id);
            _.update(activityLines[index], dataIndex, function (n) {
                return value;
            });

            handlePointScores(record);

            setActivityLines(current =>
                current.map(obj => {
                    if (obj.Id === activityLines[index].Id) {
                        var errorMessages = [];

                        if (obj.TagCaptureId === Constant.TagCaptureCode.T_R && !obj.TagNumber) {
                            errorMessages.push({
                                key: 'TagNumber',
                                value: t('fishing_activity.tag_number_is_required')
                            })
                            console.log(errorMessages)
                        }

                        return {
                            ...obj,
                            required: errorMessages.length > 0 ? true : false,
                            ErrorMessages: errorMessages,
                        };
                    }

                    return obj;
                }),
            );
        }

        // setEnableSubmit(canSubmit(activityLines));
    }

    const handleLocationOnChange = (value, record, dataIndex) => {
        if (record) {
            var index = activityLines.findIndex(x => x.Id === record.Id);
            _.update(activityLines[index], dataIndex, function (n) {
                return value;
            });

            var location = props.fishingLocations.find(x => x.Id === value);
            if (location) {
                setActivityLines(current =>
                    current.map(obj => {
                        if (obj.Id === activityLines[index].Id) {
                            return {
                                ...obj,
                                GpsLatitude: location.GpsLatitude,
                                GpsLongitude: location.GpsLongitude,
                                IsInsideClubWaters: location.IsInsideClubWaters ? 1 : 2,
                                ClubZoneId: location.ClubZoneId,
                                StatePointScoreZoneId: location.StatePointScoreZoneId,
                            };
                        }
                        return obj;
                    }),
                );

                handlePointScores(record);
            }
        }
    }

    const handleOnCheckboxChange = (value, record, dataIndex) => {
        var index = activityLines.findIndex(x => x.Id === record.Id);
        _.update(activityLines[index], dataIndex, function (n) {
            return value;
        });
    }

    const handleFishSpeciesOnChange = (value, record, dataIndex) => {
        if (record) {
            var index = activityLines.findIndex(x => x.Id === record.Id);
            _.update(activityLines[index], dataIndex, function (n) {
                return value;
            });

            var isSaltwater = 1;
            var fishSpecy = props.fishSpecies.find(x => x.Id === value);
            if (fishSpecy) {
                isSaltwater = fishSpecy.IsSaltwater ? 1 : 2;
            }

            if (isSaltwater != 1 || isSaltwater != 2) {
                isSaltwater = 1;
            }

            setActivityLines(current =>
                current.map(obj => {
                    if (obj.Id === activityLines[index].Id) {
                        return {
                            ...obj,
                            IsSaltwater: isSaltwater,
                        };
                    }
                    return obj;
                }),
            );

            handlePointScores(record);
        }

        // setEnableSubmit(canSubmit(activityLines));
    }

    const handleFishWeightOnChange = (value, record, dataIndex) => {
        if (record) {
            var index = activityLines.findIndex(x => x.Id === record.Id);
            _.update(activityLines[index], dataIndex, function (n) {
                return value;
            });

            handlePointScores(record);
        }
    }

    const handleLineClassOnChange = (value, record, dataIndex) => {
        if (record) {
            var index = activityLines.findIndex(x => x.Id === record.Id);
            _.update(activityLines[index], dataIndex, function (n) {
                return value;
            });

            handlePointScores(record);
        }
    }

    const handleAnglerOnChange = (value, record, dataIndex) => {
        if (record) {
            var index = activityLines.findIndex(x => x.Id === record.Id);
            _.update(activityLines[index], dataIndex, function (n) {
                return value;
            });

            var angler = props.anglers.find(x => x.Id === value);
            if (angler) {
                var fishingCategory = props.fishingCategories.find(x => x.Id === angler.FishingCategoryId);
                if (fishingCategory) {
                    setActivityLines(current =>
                        current.map(obj => {
                            if (obj.Id === record.Id) {
                                return {
                                    ...obj,
                                    FishingCategoryId: fishingCategory.Id,
                                };
                            }
                            return obj;
                        }),
                    );
                }
            } else {
                setActivityLines(current =>
                    current.map(obj => {
                        if (obj.Id === record.Id) {
                            return {
                                ...obj,
                                FishingCategoryId: null,
                            };
                        }
                        return obj;
                    }),
                );
            }

            handlePointScores(record);
        }
    }

    const handleZoneOnChange = (value, record, dataIndex) => {
        if (record) {
            var index = activityLines.findIndex(x => x.Id === record.Id);
            _.update(activityLines[index], dataIndex, function (n) {
                return value;
            });

            handlePointScores(record);
        }

        // setEnableSubmit(canSubmit(activityLines));
    }

    const handlePointScores = (record) => {
        var activityDate = "";
        if (props.activityHeader && props.activityHeader.ActivityDate) {
            if (typeof props.activityHeader.ActivityDate === 'string' || props.activityHeader.ActivityDate instanceof String) {
                var length = props.activityHeader.ActivityDate.split('/').length;
                if (length >= 3) {
                    activityDate = dayjs(props.activityHeader.ActivityDate, Constant.DateFormatYYYMMDD);
                }
            } else {
                activityDate = props.activityHeader.ActivityDate.format(Constant.DateFormatYYYMMDD);
            }
        }

        var dateEntered = "";
        if (record.DateEntered) {
            if (typeof record.DateEntered === 'string' || record.DateEntered instanceof String) {
                var length = record.DateEntered.split('/').length;
                if (length >= 3) {
                    dateEntered = dayjs(record.DateEntered, Constant.DateFormatYYYMMDD);
                }
            } else {
                dateEntered = record.DateEntered.format(Constant.DateFormatYYYMMDD);
            }
        }

        if (record.StateApprovalId === Constant.TagApprovalStatus.Approved || record.StateApprovalId === Constant.TagApprovalStatus.Disqualified) {
            return;
        }
        
        FishingActivityService.getPointScores(activityDate, record.AnglerId, record.LineClassId, record.ClubZoneId, record.StatePointScoreZoneId, record.FishSpeciesId, record.TagCaptureId, record.FishWeight, dateEntered)
            .then(result => {
                if (result.data) {
                    setActivityLines(current =>
                        current.map(obj => {
                            if (obj.Id === record.Id) {
                                return {
                                    ...obj,
                                    ClubCapturePoints: result.data.ClubCapturePoints,
                                    ClubTagReleasePoints: result.data.ClubTagReleasePoints,
                                    ClubMeasureReleasePoints: result.data.ClubMeasureReleasePoints,
                                    ClubLeadReleasePoints: result.data.ClubLeadReleasePoints,
                                    StatePoints: result.data.StatePoints,
                                    StateCapturePoints: result.data.StateCapturePoints,
                                    StateTagReleasePoints: result.data.StateTagReleasePoints,
                                    StateMeasureReleasePoints: result.data.StateMeasureReleasePoints,
                                    StateLeadReleasePoints: result.data.StateLeadReleasePoints,
                                };
                            }
                            return obj;
                        }),
                    );
                }
            });
    }

    var mainColumns = [
        {
            title: '#',
            dataIndex: 'RecordId',
            key: 'RecordId',
            align: 'center',
            width: 50,
            fixed: 'left',
        },
        {
            title: t('common.action'),
            key: 'Id',
            render: (value, record) => {
                return <ActionButtonsCell data={record} btnList={[
                    {
                        Description: t("common.delete"),
                        Icon: <TrashIcon />,
                        ColorClass: Constant.ColorClass.LightRed,
                        Hide: disableEditDayLog(record),
                        NoMargin: true,
                        handleAction: deleteActivityLine
                    }
                ]} />
            },
            align: 'center',
            width: 70,
            fixed: 'left',
        },
        {
            title: <span className='required'>{t('fishing_activity.tag_capt')}</span>,
            dataIndex: 'TagCaptureId',
            key: 'TagCaptureId',
            editable: true,
            required: true,
            extraData: props.tagCaptures,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "Name",
            selectionSort: 'Id',
            width: 120,
            handleOnChange: handleTagCaptureOnChange,
            fixed: 'left',
            handleDisabledCell: disableEditDayLog
        },
        {
            title: <span className='required'>{t('fishing_activity.method')}</span>,
            dataIndex: 'FishingMethodId',
            key: 'FishingMethodId',
            editable: true,
            required: true,
            extraData: props.fishingMethods,
            cellType: Constant.CellType.FishingMethodEnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "DisplayName",
            width: 200,
            handleOnChange: handleOnChange,
            fixed: 'left',
            handleDisabledCell: disableEditDayLog
        },
        {
            title: <div><span className='required'>{t('fishing_activity.time')}</span> <br /> <span className='ant-form-item-extra'>{t('fishing_activity.time_placeholder')}</span></div>,
            dataIndex: 'CaughtTime',
            key: 'CaughtTime',
            editable: true,
            required: true,
            cellType: Constant.CellType.CustomTimePicker,
            width: 110,
            handleOnChange: handleOnChange,
            fixed: 'left',
            handleDisabledCell: disableEditDayLog
        },
        {
            title: <span className='required'>{t('fishing_activity.species')} </span>,
            dataIndex: 'FishSpeciesId',
            key: 'FishSpeciesId',
            editable: true,
            required: true,
            extraData: props.fishSpecies,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "Name",
            searchLike: true,
            width: 250,
            handleOnChange: handleFishSpeciesOnChange,
            fixed: 'left',
            handleDisabledCell: disableEditDayLog
        },
        {
            title: <span className=''>{t('fishing_activity.tag_number')}</span>,
            dataIndex: 'TagNumber',
            key: 'TagNumber',
            editable: !props.isViewOnly,
            required: true,
            cellType: Constant.CellType.TagNumber,
            width: 120,
            handleOnChange: handleOnChange,
            fixed: 'left',
            handleDisabledCell: disableEditDayLog
        },
        {
            title: t('fishing_activity.weight'),
            dataIndex: 'FishWeight',
            key: 'FishWeight',
            editable: !props.isViewOnly,
            required: false,
            cellType: Constant.CellType.DecimalInput,
            precision: 2,
            width: 100,
            handleOnChange: handleFishWeightOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: t('fishing_activity.weight_measure'),
            dataIndex: 'IsWeightMeasure',
            key: 'IsWeightMeasure',
            editable: true,
            required: false,
            cellType: Constant.CellType.Checkbox,
            width: 100,
            align: 'center',
            handleOnChange: handleOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: <span className='required'>{t('fishing_activity.line_class')} </span>,
            dataIndex: 'LineClassId',
            key: 'LineClassId',
            editable: true,
            required: true,
            extraData: props.lineClasses,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "Name",
            selectionSort: 'Id',
            width: 120,
            handleOnChange: handleLineClassOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: <span className='required'>{t('fishing_activity.angler')}</span>,
            dataIndex: 'AnglerId',
            key: 'AnglerId',
            editable: true,
            required: true,
            extraData: props.anglerOptions,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "Name",
            isOptions: true,
            searchLike: true,
            width: 250,
            handleOnChange: handleAnglerOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: <span className='required'>{t('fishing_activity.location')}</span>,
            dataIndex: 'FishingLocationId',
            key: 'FishingLocationId',
            editable: true,
            required: true,
            extraData: props.fishingLocations,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "Name",
            searchLike: true,
            width: 200,
            handleOnChange: handleLocationOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: (<div>
                <span className='required'>{t('fishing_activity.gps_latitude')}</span> <br />
                <span className='ant-form-item-extra'>dd</span>
                <span style={{ color: '#C71720' }}>*</span>
                <span>o</span>
                <span className='ant-form-item-extra'> mm</span>
                <span style={{ color: '#C71720' }}>*</span>
                <span className='ant-form-item-extra'>.mmm</span>
                <span>'</span>
            </div>),
            dataIndex: 'GpsLatitude',
            key: 'GpsLatitude',
            editable: !props.isViewOnly,
            required: true,
            validateAtStart: true,
            cellType: Constant.CellType.GPSLatitudeInput,
            width: 150,
            handleOnChange: handleOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: (<div>
                <span className='required'>{t('fishing_activity.gps_longitude')}</span> <br />
                <span className='ant-form-item-extra'>ddd</span>
                <span style={{ color: '#C71720' }}>*</span>
                <span>o</span>
                <span className='ant-form-item-extra'> mm</span>
                <span style={{ color: '#C71720' }}>*</span>
                <span className='ant-form-item-extra'>.mmm</span>
                <span>'</span>
            </div>),
            dataIndex: 'GpsLongitude',
            key: 'GpsLongitude',
            editable: !props.isViewOnly,
            required: true,
            validateAtStart: true,
            cellType: Constant.CellType.GPSLongitudeInput,
            width: 160,
            handleOnChange: handleOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: t('fishing_activity.length'),
            dataIndex: 'FishLength',
            key: 'FishLength',
            editable: !props.isViewOnly,
            required: false,
            cellType: Constant.CellType.NumberInput,
            minValue: 0,
            width: 120,
            handleOnChange: handleOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: t('fishing_activity.ljfl_fl'),
            dataIndex: 'IsLjflFl',
            key: 'IsLjflFl',
            editable: true,
            required: false,
            cellType: Constant.CellType.Checkbox,
            width: 60,
            align: 'center',
            handleOnChange: handleOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: t('fishing_activity.meas'),
            dataIndex: 'IsMeasure',
            key: 'IsMeasure',
            editable: true,
            required: false,
            cellType: Constant.CellType.Checkbox,
            width: 70,
            align: 'center',
            handleOnChange: handleOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: <div><span className=''>{t('fishing_activity.fight_time')}</span> <br /> <span className='ant-form-item-extra'>{t('fishing_activity.fight_time_placeholder')}</span></div>,
            dataIndex: 'FightTime',
            key: 'FightTime',
            editable: true,
            required: false,
            cellType: Constant.CellType.CustomTimePicker,
            width: 110,
            handleOnChange: handleOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: t('fishing_activity.circle_hook'),
            dataIndex: 'IsCircleHook',
            key: 'IsCircleHook',
            editable: true,
            required: false,
            cellType: Constant.CellType.Checkbox,
            width: 100,
            align: 'center',
            handleOnChange: handleOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: t('fishing_activity.non_offset'),
            dataIndex: 'IsNonOffset',
            key: 'IsNonOffset',
            editable: true,
            required: false,
            cellType: Constant.CellType.Checkbox,
            width: 110,
            align: 'center',
            handleOnChange: handleOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: t('fishing_activity.club_approvals'),
            className: 'club-approvals',
            editable: true,
            children: [
                {
                    title: t('fishing_activity.club_points'),
                    dataIndex: 'ClubPoints',
                    key: 'ClubPoints',
                    editable: !props.isViewOnly,
                    required: false,
                    cellType: Constant.CellType.DecimalInput,
                    precision: 1,
                    width: 140,
                    className: 'club-approvals-children-left',
                    handleOnChange: handleOnChange,
                    handleDisabledCell: disableEditDayLog
                },
                {
                    title: t('fishing_activity.club_approval'),
                    dataIndex: 'ClubApprovalId',
                    key: 'ClubApprovalId',
                    editable: true,
                    required: false,
                    disabled: isClubMember,
                    extraData: props.tagApprovals,
                    cellType: Constant.CellType.EnhancedSelect,
                    selectionKey: "Id",
                    selectionDisplay: "Name",
                    width: 140,
                    className: 'club-approvals-children-right',
                    handleOnChange: handleOnChange,
                    handleDisabledCell: disableEditDayLog
                },
            ]
        },
        {
            title: t('fishing_activity.club_comments'),
            dataIndex: 'Notes',
            key: 'Notes',
            editable: !props.isViewOnly,
            required: false,
            cellType: Constant.CellType.Input,
            width: 260,
            handleOnChange: handleOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: t('fishing_activity.first_billfish_tag_r'),
            dataIndex: 'IsFirstBillfishTr',
            key: 'IsFirstBillfishTr',
            editable: true,
            required: false,
            cellType: Constant.CellType.Checkbox,
            align: 'center',
            width: 100,
            handleOnChange: handleOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: t('fishing_activity.recapt'),
            dataIndex: 'IsRecapture',
            key: 'IsRecapture',
            editable: true,
            required: false,
            cellType: Constant.CellType.Checkbox,
            align: 'center',
            width: 100,
            handleOnChange: handleOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: t('fishing_activity.old_tag_number'),
            dataIndex: 'OldTagNumber',
            key: 'OldTagNumber',
            editable: !props.isViewOnly,
            required: false,
            cellType: Constant.CellType.TagNumber,
            width: 120,
            handleOnChange: handleOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: <div><span className='required'>{t('fishing_activity.flyfishing')}</span><br /><span>{t('fishing_activity.y_n')}</span></div>,
            dataIndex: 'IsFlyfishing',
            key: 'IsFlyfishing',
            editable: true,
            required: true,
            extraData: props.yesNos,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "Name",
            selectionSort: 'Id',
            width: 110,
            allowClear: false,
            align: 'center',
            handleOnChange: handleOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: <div><span className='required'>{t('fishing_activity.saltwater')}</span><br /><span>{t('fishing_activity.y_n')}</span></div>,
            dataIndex: 'IsSaltwater',
            key: 'IsSaltwater',
            editable: true,
            required: true,
            extraData: props.yesNos,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "Name",
            selectionSort: 'Id',
            align: 'center',
            width: 120,
            allowClear: false,
            handleOnChange: handleOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: <div><span>{t('fishing_activity.inside_club_water')}</span><br /><span>{t('fishing_activity.y_n')}</span></div>,
            dataIndex: 'IsInsideClubWaters',
            key: 'IsInsideClubWaters',
            editable: true,
            required: false,
            extraData: props.yesNos,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "Name",
            selectionSort: 'Id',
            align: 'center',
            width: 110,
            allowClear: false,
            handleOnChange: handleOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: t('fishing_activity.club_zone'),
            dataIndex: 'ClubZoneId',
            key: 'ClubZoneId',
            editable: true,
            required: false,
            extraData: props.clubZones,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "Name",
            width: 180,
            handleOnChange: handleZoneOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: <span className='required'>{t('fishing_activity.state_zone')}</span>,
            dataIndex: 'StatePointScoreZoneId',
            key: 'StatePointScoreZoneId',
            editable: true,
            required: true,
            extraData: props.statePointScoreZones,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "Name",
            width: 180,
            handleOnChange: handleZoneOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: t('fishing_activity.club_event'),
            dataIndex: 'ClubEventId',
            key: 'ClubEventId',
            editable: true,
            required: false,
            extraData: props.clubEvents,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "Name",
            selectionSort: 'Id',
            width: 220,
            handleOnChange: handleOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: t('fishing_activity.state_event'),
            dataIndex: 'StateEventId',
            key: 'StateEventId',
            editable: true,
            required: false,
            extraData: props.stateEvents,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "Name",
            selectionSort: 'Id',
            width: 220,
            handleOnChange: handleOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: t('fishing_activity.state_approvals'),
            editable: true,
            children: [
                {
                    title: t('fishing_activity.state_points'),
                    dataIndex: 'StatePoints',
                    key: 'StatePoints',
                    editable: !props.isViewOnly,
                    required: false,
                    disabled: props.disabledStateView,
                    cellType: Constant.CellType.DecimalInput,
                    precision: 1,
                    width: 140,
                    handleOnChange: handleOnChange,
                },
                {
                    title: t("common.point_score"),
                    render: (value, record) => {
                        return <ActionButtonsCell data={record} btnList={[
                            {
                                Description: t("common.point_score"),
                                Icon: <EyeIcon />,
                                ColorClass: Constant.ColorClass.LightBlue,
                                Hide: props.isViewOnly,
                                NoMargin: true,
                                handleAction: showPointScoreModal
                            }
                        ]} />
                    },
                    width: 65,
                },
                {
                    title: t('fishing_activity.state_approval'),
                    dataIndex: 'StateApprovalId',
                    key: 'StateApprovalId',
                    editable: true,
                    required: false,
                    disabled: props.disabledStateView,
                    handleDisabledCell: disableEditDayLog,
                    extraData: props.tagApprovals,
                    cellType: Constant.CellType.EnhancedSelect,
                    selectionKey: "Id",
                    selectionDisplay: "Name",
                    width: 140,
                    handleOnChange: handleOnChange,
                },
            ]
        },
        {
            title: t('fishing_activity.state_disqualifying_reasons'),
            editable: true,
            children: [
                {
                    title: t('fishing_activity.non_mem'),
                    dataIndex: 'NonMember',
                    key: 'NonMember',
                    editable: true,
                    required: false,
                    disabled: props.disabledStateView,
                    handleDisabledCell: disableEditDayLog,
                    cellType: Constant.CellType.Checkbox,
                    align: 'center',
                    width: 80,
                    handleOnChange: handleOnCheckboxChange,
                },
                {
                    title: t('fishing_activity.drop_fish'),
                    dataIndex: 'IsDropFish',
                    key: 'IsDropFish',
                    editable: true,
                    required: false,
                    disabled: props.disabledStateView,
                    handleDisabledCell: disableEditDayLog,
                    cellType: Constant.CellType.Checkbox,
                    align: 'center',
                    width: 80,
                    handleOnChange: handleOnCheckboxChange,
                },
                {
                    title: t('fishing_activity.too_late'),
                    dataIndex: 'IsTooLate',
                    key: 'IsTooLate',
                    editable: true,
                    required: false,
                    disabled: props.disabledStateView,
                    handleDisabledCell: disableEditDayLog,
                    cellType: Constant.CellType.Checkbox,
                    align: 'center',
                    width: 80,
                    handleOnChange: handleOnCheckboxChange,
                },
                {
                    title: t('fishing_activity.no_tag_card'),
                    dataIndex: 'IsNoTagCard',
                    key: 'IsNoTagCard',
                    editable: true,
                    required: false,
                    disabled: props.disabledStateView,
                    handleDisabledCell: disableEditDayLog,
                    cellType: Constant.CellType.Checkbox,
                    align: 'center',
                    width: 80,
                    handleOnChange: handleOnCheckboxChange,
                },
                {
                    title: t('fishing_activity.tackl'),
                    dataIndex: 'IsTackle',
                    key: 'IsTackle',
                    editable: true,
                    required: false,
                    disabled: props.disabledStateView,
                    handleDisabledCell: disableEditDayLog,
                    cellType: Constant.CellType.Checkbox,
                    align: 'center',
                    width: 80,
                    handleOnChange: handleOnCheckboxChange,
                },
                {
                    title: t('fishing_activity.fish_too_small'),
                    dataIndex: 'IsFishTooSmall',
                    key: 'IsFishTooSmall',
                    editable: true,
                    required: false,
                    disabled: props.disabledStateView,
                    handleDisabledCell: disableEditDayLog,
                    cellType: Constant.CellType.Checkbox,
                    align: 'center',
                    width: 80,
                    handleOnChange: handleOnCheckboxChange,
                },
                {
                    title: t('fishing_activity.oob'),
                    dataIndex: 'IsOob',
                    key: 'IsOob',
                    editable: true,
                    required: false,
                    disabled: props.disabledStateView,
                    handleDisabledCell: disableEditDayLog,
                    cellType: Constant.CellType.Checkbox,
                    align: 'center',
                    width: 80,
                    handleOnChange: handleOnCheckboxChange,
                },
                {
                    title: t('fishing_activity.other'),
                    dataIndex: 'IsOther',
                    key: 'IsOther',
                    editable: true,
                    required: false,
                    disabled: props.disabledStateView,
                    handleDisabledCell: disableEditDayLog,
                    cellType: Constant.CellType.Checkbox,
                    align: 'center',
                    width: 80,
                    handleOnChange: handleOnCheckboxChange,
                }
            ],
        },
        {
            title: <span className=''>{t('fishing_activity.date_entered')}</span>,
            dataIndex: 'DateEntered',
            key: 'DateEntered',
            editable: true,
            required: false,
            disabled: disabledStateView,
            cellType: Constant.CellType.Date,
            width: 150,
            handleOnChange: handleOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: t('fishing_activity.state_comments'),
            dataIndex: 'StateNotes',
            key: 'StateNotes',
            editable: !props.isViewOnly,
            required: false,
            disabled: disabledStateView,
            cellType: Constant.CellType.Input,
            width: 260,
            handleOnChange: handleOnChange,
        },
    ]

    const mapColumns = col => {
        if (!col.editable) {
            return col;
        }

        var newCol = {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                disabled: col.disabled,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition,
                selectionKey: col.selectionKey,
                selectionDisplay: col.selectionDisplay,
                minValue: col.minValue,
                selectionSort: col.selectionSort,
                isOptions: col.isOptions,
                searchLike: col.searchLike,
                validateAtStart: col.validateAtStart,
                allowClear: col.allowClear,
                precision: col.precision,
                handleDisabledCell: col.handleDisabledCell
            }),
        };
        if (col.children) {
            newCol.children = col.children.map(mapColumns);
        }
        return newCol;
    }

    const tableViewMainColumns = mainColumns.map(mapColumns);

    useEffect(() => {
        setLoading(true);
        setActivityLines(props.activityLines);
        // setEnableSubmit(canSubmit(props.activityLines)); 
        var existActivityLine = _.find(props.activityLines, function (x) { return x.IsAddNew; });
        setIsEdit(existActivityLine != null ? false : true);

        setTimeout(() => {
            setLoading(false);
        }, 100);

        return () => {
        }
    }, [props.activityLines])

    useEffect(() => {
        setDisabledStateView(props.disabledStateView);
        return () => {
        }
    }, [props.disabledStateView])

    useEffect(() => {
        if (typeof (currentPosition.PositionTypeId) === 'undefined') {
            currentPosition = StorageService.getCurrentPosition();
        }

        if (typeof (currentPosition.PositionTypeId) === 'undefined' || currentPosition.PositionTypeId === Constant.PositionType.ClubMember) {
            setIsClubMember(true);
        } else {
            setIsClubMember(false);
        }

        return () => {
        }
    }, [])

    const SubmitButton = ({ form }) => {
        const [submittable, setSubmittable] = React.useState(false);

        // Watch all values
        const values = Form.useWatch([], form);
        React.useEffect(() => {
            form
                .validateFields({
                    validateOnly: true,
                })
                .then(
                    () => {
                        setSubmittable(true);
                    },
                    () => {
                        setSubmittable(false);
                    },
                );
        }, [values]);
        return (
            <Button size="large" type="primary" htmlType="submit" disabled={!submittable}>{t("fishing_activity.save_day_log")}</Button>
        );
    }

    return (
        <Skeleton loading={loading} active paragraph={{ rows: 1, width: "100%" }}>
            <Form
                {...layout}
                name='tableViewForm'
                key='tableViewForm'
                form={tableViewForm}
                className='tag-card-table-view'
                onFinish={onSaveActivityLines}>
                <Row className="fix-item-container">
                    <Col xs={{ span: 24 }} className="text-left extra-btn-m-t">
                        <div className="m-t-10">
                            <span>{t("fishing_activity.note_save_message")}</span>
                        </div>
                    </Col>
                </Row>

                <Row className="custom-section-table m-t-10" gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                    <Col xs={{ span: 24 }}>
                        <Table
                            components={components}
                            rowClassName={() => 'editable-row'}
                            {...GetTableConfigs(tableViewMainColumns, activityLines, activityLines.length, "Id", null)}
                            pagination={false}
                            rowKey="Id"
                        />
                    </Col>
                </Row>
                <Row align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter]}>
                    <Col xs={{ span: 24 }} className="text-right m-t-25">
                        <Button size="large" className="m-r-5" onClick={onCancel}>{t("common.cancel")}</Button>
                        {
                            !props.isViewOnly && <SubmitButton form={tableViewForm} />

                        }

                    </Col>
                </Row>

            </Form>
        </Skeleton>
    )
}

export default TagCardTableView
import React, { useEffect, useState } from 'react';
import './fishingLocationManagement.scss';
import { useTranslation } from 'react-i18next';
import {
    Constant,
    CommonService,
    SettingService
} from '../../services/services'
import { Table, Button, Row, Col, Form, Input, Modal, Breadcrumb, Drawer, Space, Select, Dropdown, Empty } from 'antd';
import { GetTableConfigs, StatusCell, YesNoCell } from '../../components/customTable/customTable'
import { FilterAltIcon, CloseIcon } from '../../components/icons/icons'
import LoadingContext from '../../components/loading/loadingContext'
import EnhancedSelect from '../../components/enhancedSelect/enhancedSelect';
import CreateEditFishingLocation from './createEditFishingLocation';
import { useGlobalState } from '../../utilities/globalState'
import { DownloadOutlined } from '@ant-design/icons';
import PubSub from 'pubsub-js';
import { useHistory } from "react-router-dom";
import CustomInputMask from '../../components/CustomerInputMask/CustomInputMask';
const { confirm } = Modal;
const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};
const FishingLocationManagement = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [formFilter] = Form.useForm();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext);
    const [yesNos, setYesNos] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [fishingLocations, setFishingLocations] = useState([]);
    const [selectedFishingLocations, setSelectedFishingLocations] = useState([]);
    const [visibleModal, setVisibleModal] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [clubZones, setClubZones] = useState([]);
    const [statePointScoreZones, setStatePointScoreZones] = useState([]);
    const [searchFishingLocation, setSearchFishingLocation] = useState({ StatusIds: [Constant.StatusCode.Active] });

    const [currentPosition] = useGlobalState('currentPosition');
    const [gridConfigOptions, setGridConfigOptionsValue] = useState({
        pageNumber: 1,
        pageSize: CommonService.getPageSize(),
        sort: "LocationName-ascend",
    })


    const search = (pageNumber, pageSize, sort, query, isShowLoading = false) => {
        if (isShowLoading) {
            showLoading();
        }

        let params = { ...query };
        if (!params) params = { StatusIds: [Constant.StatusCode.Active] };

        SettingService.getFishingLocations(pageNumber, pageSize, sort, params)
            .finally(() => {
                if (isShowLoading) {
                    dismissLoading();
                }

                setSelectedRowKeys([]);
                setSelectedFishingLocations([]);
            })
            .then(result => {
                if (result.data) {
                    setFishingLocations(result.data.ObjectList);
                    setTotalItems(result.data.TotalItems);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const addFishingLocations = () => {
        setIsEdit(false);
        setVisibleModal(true);
    }

    const editFishingLocations = () => {
        setIsEdit(true);
        setVisibleModal(true);
    }

    const deleteFishingLocations = async () => {
        confirm({
            title: t("fishing_location.delete_club_fishing_locations_confirm"),
            okText: t("common.yes"),
            cancelText: t("common.no"),
            onOk() {
                if (selectedRowKeys.length === 0) {
                    return;
                }
                var ids = selectedRowKeys;
                showLoading();

                SettingService.deleteFishingLocations(ids)
                    .finally(() => {
                        dismissLoading();
                    })
                    .then(result => {
                        refreshData();
                    })
                    .catch(error => {
                        CommonService.handleErrorResponse(error);
                    });
            }
        });
    }

    const handleCancel = (refresh) => {
        setVisibleModal(false);
        if (refresh) {
            refreshData();
        }
    }

    const columns = [
        {
            title: t('fishing_location.location_name'),
            dataIndex: 'LocationName',
            key: 'LocationName',
            sorter: true,
            defaultSortOrder: 'ascend',
            width: 170,
        },
        {
            title: t('fishing_location.gps_latitude'),
            dataIndex: 'GpsLatitude',
            key: 'GpsLatitude',
            sorter: true,
            width: 130,
        },
        {
            title: t('fishing_location.gps_longitude'),
            dataIndex: 'GpsLongitude',
            key: 'GpsLongitude',
            sorter: true,
            width: 135,
        },
        {
            title: t('fishing_location.nominal_water_depth'),
            dataIndex: 'NominalWaterDepth',
            key: 'NominalWaterDepth',
            sorter: true,
            width: 140,
        },
        {
            title: t('fishing_location.is_inside_club_waters'),
            dataIndex: 'IsInsideClubWaters',
            key: 'IsInsideClubWaters',
            sorter: true,
            align: "center",
            width: 110,
            render: (value, record) => (
                <YesNoCell value={record.IsInsideClubWaters} />
            )
        },
        {
            title: t('fishing_location.club_zone'),
            dataIndex: 'ClubZoneName',
            key: 'ClubZoneName',
            sorter: true,
            width: 100,
        },
        {
            title: t('fishing_location.state_point_score_zone'),
            dataIndex: 'StatePointScoreZoneName',
            key: 'StatePointScoreZoneName',
            sorter: true,
            width: 130,
        },
        {
            title: t('common.status'),
            dataIndex: 'StatusId',
            key: 'StatusId',
            sorter: true,
            width: 65,
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.StatusId)}
                    description={CommonService.getStatusDescription(record.StatusId, false)} />
            )
        }
    ]

    const onTableChange = (pagination, filters, sorter, extra) => {
        let data = formFilter.getFieldsValue();
        if (!data || (data && !data.StatusIds)) {
            data.StatusIds = [Constant.StatusCode.Active];
        }

        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = gridConfigOptions.sort;
        }
        search(pagination.current, pagination.pageSize, sort, data);

        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        });
    }

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (newSelectedRowKeys, newSelectedRows) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setSelectedFishingLocations(newSelectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: record.StatusId === Constant.StatusCode.Inactive,
        }),
    }

    const showFilterLayout = () => {
        setOpenFilter(true);
    }

    const closeFilterLayout = () => {
        formFilter.setFieldsValue(searchFishingLocation);
        setOpenFilter(false);
    }

    const refreshData = (isShowLoading = false) => {
        setSelectedRowKeys([]);
        setSelectedFishingLocations([]);

        let data = formFilter.getFieldsValue();
        if (!data || (data && !data.StatusIds)) {
            data.StatusIds = [Constant.StatusCode.Active];
        }
        setSearchFishingLocation(data);

        search(1, gridConfigOptions.pageSize, gridConfigOptions.sort, data, isShowLoading);

        setGridConfigOptionsValue(
            {
                ...gridConfigOptions,
                pageNumber: 1
            }
        );

    }

    const resetFilter = () => {
        formFilter.resetFields();
        setSearchFishingLocation({ StatusIds: [Constant.StatusCode.Active] });
    }

    const applyFilter = () => {

        refreshData(true);

        setOpenFilter(false);
    }


    useEffect(() => {

        if (currentPosition && (currentPosition.PositionTypeId === Constant.PositionType.StateExcutive || currentPosition.PositionTypeId === Constant.PositionType.ClubMember)) {
            history.push('/unauthorised');
            return;
        }
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
        }, 100)

        showLoading();
        setYesNos([{
            Id: 1,
            Name: t("common.yes")
        },
        {
            Id: 2,
            Name: t("common.no")
        }]);

        Promise.all([
            SettingService.getFishingLocations(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, { StatusIds: [Constant.StatusCode.Active] }),
            SettingService.getStatusCodes(),
            SettingService.getClubZones(),
            SettingService.getStatePointScoreZones()
        ])
            .finally(() => {
                dismissLoading();
                setSelectedRowKeys([]);
                setSelectedFishingLocations([]);
                setSearchFishingLocation({ StatusIds: [Constant.StatusCode.Active] });
            })
            .then((response) => {
                if (response[0].data) {
                    setFishingLocations(response[0].data.ObjectList);
                    setTotalItems(response[0].data.TotalItems);
                }

                if (response[1].data) {
                    setStatuses(response[1].data);
                }

                if (response[2].data) {
                    setClubZones(response[2].data);
                }

                if (response[3].data) {
                    setStatePointScoreZones(response[3].data);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);

        return () => {
            SettingService.cancelRequest();
            clearTimeout(timeoutFn);
        }
    }, [history])

    const exportFishingLocationReport = (fileType) => {
        showLoading();
        SettingService.getExportFishingLocations(gridConfigOptions.sort, formFilter.getFieldsValue(), fileType)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    if (fileType == Constant.FileType.Excel) {
                        const type = result.headers['content-type']
                        const blob = new Blob([result.data], { type: type })
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = `Fishing_Location_List.xlsx`
                        link.click()
                    } else if (fileType == Constant.FileType.Csv) {
                        const type = result.headers['content-type']
                        const blob = new Blob([result.data], { type: type })
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = `Fishing_Location_List.csv`
                        link.click()
                    }
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    const handleExport = (e) => {
        exportFishingLocationReport(e.key)
    };

    const exportItems = [
        {
            label: t("common.excel"),
            key: Constant.FileType.Excel
        },
        {
            label: t("common.csv"),
            key: Constant.FileType.Csv
        }
    ];
    const menuExport = {
        items: exportItems,
        onClick: handleExport
    };

    return (
        <>
            {currentPosition && (currentPosition.PositionTypeId != Constant.PositionType.StateExcutive) &&
                <div className="fishing-location-management">
                    <Breadcrumb>
                        <Breadcrumb.Item>{t("menu.club_fishing_Locations")}</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row id={Constant.FixItemsContainerId} className="fix-item-container">
                        <Col xs={{ span: 24 }} className="text-right extra-btn-m-t">
                            <Space>
                                {currentPosition && currentPosition.PositionTypeId == Constant.PositionType.ClubMember &&
                                    <Dropdown className='m-l-10' menu={menuExport} trigger={['click']}>
                                        <Button className='btn-outline-blue btn-icon' icon={<DownloadOutlined />}>
                                            {t("common.export")}
                                        </Button>
                                    </Dropdown>
                                }
                                <Button onClick={showFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon className="anticon anticon-search" />}>{t("common.filter")}</Button>

                                <Button id='btnDelete' disabled={selectedFishingLocations.length === 0} danger className="m-l-10 secondary-btn" onClick={deleteFishingLocations}>
                                    {t("common.delete")}
                                </Button>

                                <Button id='btnEdit' disabled={selectedFishingLocations.length === 0} className={selectedFishingLocations.length > 0 ? "m-l-10 secondary-btn btn-outline-dark-blue" : "m-l-10 secondary-btn"} onClick={editFishingLocations}>
                                    {t("common.edit")}
                                </Button>

                                <Button className="m-l-10 secondary-btn btn-solid-dark-blue" type="primary" onClick={addFishingLocations}>
                                    {t("common.add_new")}
                                </Button>
                            </Space>
                        </Col>
                    </Row>

                    <Row className="m-t-10 section-table" gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 30 }}>
                            <Table
                                {...GetTableConfigs(columns, fishingLocations, totalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                                rowSelection={rowSelection}
                            />
                        </Col>
                    </Row>

                    {/* Filter Layout  */}
                    <Drawer
                        title={t("common.filter")}
                        width={378}
                        onClose={closeFilterLayout}
                        open={openFilter}
                        closeIcon={false}
                        styles={{
                            body: {
                                paddingBottom: 80,
                            }
                        }}
                        extra={
                            <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                                <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                                <Button onClick={closeFilterLayout} icon={<CloseIcon />} type="link" />
                            </Space>
                        }
                        footer={
                            <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                                <Button onClick={closeFilterLayout}>{t("common.cancel")}</Button>
                                <Button onClick={applyFilter} type="primary">
                                    {t("common.apply")}
                                </Button>
                            </Space>
                        }>
                        <Form
                            layout="vertical"
                            {...layout}
                            form={formFilter}
                            name="formFilter"
                            key='formFilter'
                            initialValues={{ StatusIds: [Constant.StatusCode.Active] }}>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="Name"
                                        label={t("fishing_location.location_name")}
                                    >
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="GpsLatitude"
                                        label={t("fishing_location.gps_latitude")}
                                    >
                                        <CustomInputMask defaultValue="__°__.___'" mask={"99°99.999'"} isFilter ={true} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="GpsLongitude"
                                        label={t("fishing_location.gps_longitude")}
                                    >
                                        <CustomInputMask defaultValue="__°__.___'" mask={"99°99.999'"} isFilter ={true} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="NominalWaterDepth"
                                        label={t("fishing_location.nominal_water_depth")}
                                    >
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="InsideClubWaterIds"
                                        label={t("fishing_location.is_inside_club_waters")}
                                    >
                                        <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) => (optionA?.value == optionB?.value)}
                                            options={yesNos ? yesNos.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}>
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="ClubZoneIds"
                                        label={t("fishing_location.club_zone")}
                                    >
                                        <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={clubZones ? clubZones.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}>
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="StatePointScoreZoneIds"
                                        label={t("fishing_location.state_point_score_zone")}
                                    >
                                        <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={statePointScoreZones ? statePointScoreZones.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}>
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="StatusIds"
                                        label={t("common.status")}
                                    >
                                        <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={statuses ? statuses.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}>
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Drawer>

                    {/* Create/Edit Fishing Location Layout  */}
                    {
                        visibleModal &&
                        <CreateEditFishingLocation
                            handleCancel={handleCancel}
                            isEdit={isEdit}
                            selectedFishingLocations={selectedFishingLocations}
                            statuses={statuses}
                            clubZones={clubZones}
                            statePointScoreZones={statePointScoreZones} >

                        </CreateEditFishingLocation>
                    }
                </div>

            }
        </>
    )

}

export default FishingLocationManagement;
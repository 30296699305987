import axios from 'axios';
import { axiosPortal } from './authService'
import moment from 'moment';

const source = [];
let baseURL = "api/Trophy"

export const TrophyService = {
    getTrophies: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/Trophies?PageNumber=${pageNumber ? pageNumber : 1}`

        if (sort) {
            url += `&Sort=${sort}`
        }
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (params) {
            if (params.TrophyName) {
                url += `&TrophyName=${params.TrophyName}`;
            }
            if (params.TrophyTypeIds) {
                url += `&TrophyTypeIds=${params.TrophyTypeIds}`;
            }
            if (params.DateFrom) {
                url += `&DateFrom=${params.DateFrom}`;
            }
            if (params.DateTo) {
                url += `&DateTo=${params.DateTo}`;
            }
            if (params.Ranking) {
                url += `&Ranking=${params.Ranking}`;
            }
            if (params.StatusIds) {
                url += `&StatusIds=${params.StatusIds}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getTrophyFilterData: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/TrophyFilterData`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getAnglers: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        return axiosPortal.get(`${baseURL}/GetAnglers`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getTrophyDetail: function (trophyId, rankings, dateFrom, dateTo, financialYearIds) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/TrophyDetail?trophyId=${trophyId}`
        if (rankings) {
            url += `&rankings=${rankings}`;
        }
        if (dateFrom) {
            url += `&dateFrom=${dateFrom}`;
        }
        if (dateTo) {
            url += `&dateTo=${dateTo}`;
        }
        if (financialYearIds) {
            url += `&financialYearIds=${financialYearIds}`;
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getTrophySelectionData: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/TrophySelectionData`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    addTrophy: function (trophy) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/Trophy`

        return axiosPortal.post(url, trophy, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    updateTrophy: function (trophy) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/Trophy`

        return axiosPortal.put(url, trophy, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    deleteTrophies: function (trophyIds) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/DeleteTrophies`

        return axiosPortal.post(url, trophyIds, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getTrophyTableLayoutData: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetTrophyTableLayoutData`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getAnglerGroups: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetAnglerGroups?PageNumber=${pageNumber ? pageNumber : 1}`

        if (sort) {
            url += `&Sort=${sort}`
        }
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (params) {
            if (params.Name) {
                url += `&Name=${params.Name}`;
            }
            if (params.StatusIds) {
                url += `&StatusIds=${params.StatusIds}`;
            }
        }
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getExportTrophies: function (trophyIds, rankings, dateFrom, dateTo, financialYearIds, fileType) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/ExportTrophies?trophyIds=${trophyIds}&fileType=${fileType}`
        if (rankings) {
            url += `&rankings=${rankings}`;
        }
        if (dateFrom) {
            url += `&dateFrom=${dateFrom}`;
        }
        if (dateTo) {
            url += `&dateTo=${dateTo}`;
        }
        if (financialYearIds) {
            url += `&financialYearIds=${financialYearIds}`;
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token,
            responseType: 'blob'
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    addUpdateAnglerGroup: function (anglerGroup) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/AddUpdateAnglerGroup`

        return axiosPortal.post(url, anglerGroup, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    deleteAnglerGroup: function (id) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        return axiosPortal.post(`${baseURL}/DeleteAnglerGroup?id=${id}`, null, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    deleteAnglerMember: function (anglerGroupId, memberId) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        return axiosPortal.post(`${baseURL}/DeleteAnglerMember`, { AnglerGroupId: anglerGroupId, MemberId: memberId }, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getApprovalsStatus: function (financialYearId, tagCaptureId, tagApprovalId, isStateApprovalsStatus) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/ApprovalsStatus?financialYearId=${financialYearId}&isStateApprovalsStatus=${isStateApprovalsStatus}`

        if (tagCaptureId) {
            url += `&tagCaptureId=${tagCaptureId}`
        }

        if (tagApprovalId) {
            url += `&tagApprovalId=${tagApprovalId}`;
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getTagCapture: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = 'api/master-data/tag-capture'
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getAllFinancialYears: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `api/GetAllFinancialYears`

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getExportStateApprovalsStatus: function (fileType, financialYearId, tagCaptureId, tagApprovalId) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/ExportStateApprovalsStatus?fileType=${fileType}&financialYearId=${financialYearId}`

        if (tagCaptureId) {
            url += `&tagCaptureId=${tagCaptureId}`
        }

        if (tagApprovalId) {
            url += `&tagApprovalId=${tagApprovalId}`
        }
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token,
            responseType: 'blob'
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getExportClubApprovalsStatus: function (fileType, financialYearId, tagCaptureId, tagApprovalId) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/ExportClubApprovalsStatus?fileType=${fileType}&financialYearId=${financialYearId}`

        if (tagCaptureId) {
            url += `&tagCaptureId=${tagCaptureId}`
        }

        if (tagApprovalId) {
            url += `&tagApprovalId=${tagApprovalId}`
        }
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token,
            responseType: 'blob'
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getBoatGroups: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetBoatGroups?PageNumber=${pageNumber ? pageNumber : 1}`

        if (sort) {
            url += `&Sort=${sort}`
        }
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (params) {
            if (params.Name) {
                url += `&Name=${params.Name}`;
            }
            if (params.BoatNo) {
                url += `&BoatNo=${params.BoatNo}`;
            }
            if (params.StatusIds) {
                url += `&StatusIds=${params.StatusIds}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getBoatGroupById: function (id) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetBoatGroupById?id=${id}`

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    deleteBoatGroup: function (id) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/DeleteBoatGroup?id=${id}`

        return axiosPortal.post(url, null, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    addUpdateBoatGroup: function (boatGroup) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/AddUpdateBoatGroup`

        return axiosPortal.post(url, boatGroup, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    cancelRequest: function () {
        if (source && source.length > 0) {
            for (var i = 0; i < source.length; i++) {
                source[i].cancel('Operation canceled.')
            }
            source.splice(0, source.length)
        }
    },
    getClubDayLogSummaries: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/ClubDayLogSummaries?PageNumber=${pageNumber ? pageNumber : 1}`
        if (sort) {
            url += `&Sort=${sort}`
        }
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (params) {
            if (params.FinancialYearId) {
                url += `&FinancialYearId=${params.FinancialYearId}`;
            }
            if (params.DateRange) {
                let dateRange;
                if (params.DateRange[0].$d) {
                    dateRange = moment(params.DateRange[0].$d).format("YYYY-MM-DD");
                }
                if (params.DateRange[1].$d) {
                    dateRange = dateRange + ',' + moment(params.DateRange[1].$d).format("YYYY-MM-DD");
                }
                url += `&DateRange=${dateRange}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
}
import React, { useState, useEffect } from 'react';
import { Modal, InputNumber, Row, Col, Button, Upload, Input, Form, Select, Tabs, Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { Constant} from '../../services/services';
import dayjs from 'dayjs';
import './createEditRecord.scss';
import AddImage from './../../assets/icons/add-image.svg';
import moment from 'moment';

const { confirm } = Modal;
const { TextArea } = Input;
const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};
const { TabPane } = Tabs;

const RecordDetail = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [record, setRecord] = useState({});
    const [imageUrl, setImageUrl] = useState();
    const [isState, setIsState] = useState(false);

    useEffect(() => {
        if (props.visible) {
            
            if (props.objectData.ImageUrl) {
                setImageUrl(props.objectData.ImageUrl);
            }
            form.setFieldsValue(props.objectData)
            setRecord(props.objectData);
            setIsState(props.isState);
        }
    }, [props.visible])


    const closeForm = () => {
        props.handleCancel()
    }

    return (
        <div>
            <Modal
                title={t("record_management.view_record")}
                open={true}
                onCancel={closeForm}
                confirmLoading={false}
                maskClosable={false}
                centered
                width={1000}
                className='add-update-record-modal'
                footer={null} 
            >
                <Form
                    layout="vertical"
                    {...layout}
                    form={form}
                    name="recordForm"
                    key='recordForm'
                >
                    <Row gutter={[Constant.SpaceConstant.HorizontalGutter,]}>
                        <Col xs={{ span: 24 }}>
                            <Form.Item
                                    name="FishSpeciesName"
                                    label={t("fishing_activity.species")}
                                >
                                <div>{record.FishSpeciesName}</div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="RecordEnvironmentName"
                                label={t("record_management.record_environment")}
                            >
                                <div>{record.RecordEnvironmentName}</div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="LocationName"
                                label={t("record_management.fishing_location")}
                            >
                                <div>{record.LocationName}</div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="ClubEntityName"
                                label={t("fishing_activity.club")}
                            >
                               {isState ? <div>{record.EntityName}</div> : <div>{record.ClubEntityName}</div>}
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="RecordCategoryName"
                                label={t("record_management.record_category")}
                            >
                                <div>{record.RecordCategoryName}</div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="LineClassName"
                                label={t("fishing_activity.line_class")}
                            >
                                <div>{record.LineClassName}</div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="FishWeight"
                                label={t("fishing_activity.weight")}
                            >
                                <div>{record.FishWeight}</div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                            label={t("record_management.record_date")}
                            name="RecordDate">
                            <div>{moment(record.RecordDate).format(Constant.DateFormat)}</div>
                        </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="FirstName"
                                label={t("member.first_name")}
                            >
                                <div>{record.FirstName}</div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="Surname"
                                label={t("member.surname")}
                            >
                                <div>{record.Surname}</div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="FlLjfl"
                                label={t("record_management.fl_ljfl")}
                            >
                                <div>{record.FlLjfl + " mm"}</div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="TotalLength"
                                label={t("record_management.total_length")}
                            >
                                <div>{record.TotalLength + " mm"}</div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="Girth"
                                label={t("record_management.girth")}
                            >
                                <div>{record.Girth + " mm"}</div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="RodMakeModel"
                                label={t("record_management.rod_make_model")}
                            >
                                <div>{record.RodMakeModel}</div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="ReelMakeModel"
                                label={t("record_management.reel_make_model")}
                            >
                                <div>{record.ReelMakeModel}</div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="LineMake"
                                label={t("record_management.line_make")}
                            >
                                <div>{record.LineMake}</div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="BoatName"
                                label={t("record_management.boat_name")}
                            >
                                <div>{record.BoatName}</div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="NameBaitLureFly"
                                label={t("record_management.name_bait_lure_fly")}
                            >
                                <div>{record.NameBaitLureFly}</div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
                            <Form.Item
                                label={t('record_management.image')}
                                colon={false} labelAlign="left"
                                className='upload-item'
                            >
                                <Image src={imageUrl} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            </Modal>
        </div>
    )

}

export default RecordDetail
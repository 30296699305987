import React from 'react';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';

const Unauthorised = () => {
    const { t } = useTranslation();

    return (
        <div className='m-t-10'>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_access")} />
        </div >
    )
}

export default Unauthorised
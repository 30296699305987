import React, { useEffect, useState } from 'react';
import './memberFinancialDateManagement.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
    Constant,
    CommonService,
    MembershipService
} from '../../../services/services'
import { Table, Button, Row, Col, Form, Modal} from 'antd';
import { GetTableConfigs, DateTimeCell, EditableCell } from '../../../components/customTable/customTable'
import LoadingContext from '../../../components/loading/loadingContext'
import { useGlobalState } from '../../../utilities/globalState'

const { confirm } = Modal;
const _ = require("lodash");

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const components = {
    body: {
        cell: EditableCell,
    },
};

const EditMemberFinancialDate = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [originalMembers, setOriginalMembers] = useState([]);
    const [entities, setEntities] = useState([]);
    const { showLoading, dismissLoading } = React.useContext(LoadingContext);
    const [members, setMembers] = useState([]);
    const [currentPosition] = useGlobalState('currentPosition');
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [fieldsChange, setFieldsChange] = useState([]);
    const [form] = Form.useForm();
   
    useEffect(() => {
        showLoading();
        setRefresh(false);

        Promise.all([
            MembershipService.getEntitiesByPositionTypeId(-1)
        ])
        .finally(() => {            
            dismissLoading();
        })
        .then((values) => {
            if (values && values.length > 0) {
                var newEntities = values[0].data;
                setEntities(newEntities);
                var newMembers = [];
                newMembers = [...newMembers, ...props.selectedMembers];
                
                setMembers(newMembers);
                setMembers(current =>
                    current.map(obj => {
                        return {
                            ...obj,
                            FinancialEnd: obj.FinancialEnd ? moment(obj.FinancialEnd).format(Constant.DateFormat) : null,
                        };
                    }),
                );
                setOriginalMembers(_.cloneDeep(newMembers));
                setEnableSubmit(canSubmit(newMembers));
            }
        });

        return () => {
        }
    }, [])

    const onSubmit = () => {
        // Validate
        var allErrorMessages = [];

        setMembers(current =>
            current.map(obj => {
                var errorMessages = [];
                
                if (!obj.FinancialTypeId) {
                    errorMessages.push({
                        key: 'FinancialTypeId',
                        value: t('member.fishing_category_is_required')
                    })
                }

                if (!obj.FinancialEnd) {
                    errorMessages.push({
                        key: 'FinancialEnd',
                        value: t('member.financial_end_is_required')
                    })
                }

                allErrorMessages.push(...errorMessages);
                return {
                    ...obj,
                    required: errorMessages.length > 0 ? true : false,
                    ErrorMessages: errorMessages,
                };
            }),
        );

        if (allErrorMessages.length > 0) {            
            return;
        }

        members.forEach(member => {
            if (member.FinancialEnd) {
                var length = member.FinancialEnd.split('/').length;
                if (length >= 3) {
                    member.FinancialEnd = moment(member.FinancialEnd, Constant.DateFormat).format(Constant.DateFormatYYYMMDD);
                }
            }
            member.MemberStatusId = Constant.MemberStatusCode.Active;
        });

        showLoading();
        MembershipService.addUpdateMemberships(members)
            .finally(() => {
                dismissLoading();
            })
            .then(result => {
                if (result.data) {
                    CommonService.presentToast('success', t('member.update_successful'));
                    props.handleCancel(true);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const canSubmit = (newMembers) => {
        if (newMembers && newMembers.length > 0) {
            let inValid = newMembers.filter(x => !x.FinancialTypeId || !x.FinancialEnd);
            if (inValid && inValid.length > 0) {
                return false
            }
            return true;
        }
        return false;
    }

    const submitConfirmation = () => {
        function customizer(newMembers, oldMembers) {
            if (newMembers.length !== oldMembers.length)
                return true;

            for (var i = 0; i < newMembers.length; i ++) {
                
                var newFinancialEnd = '';
                if (newMembers[i].FinancialEnd) {
                    newFinancialEnd = newMembers[i].FinancialEnd;
                }

                var oldFinancialEnd = '';
                if (oldMembers[i].FinancialEnd && oldMembers[i].FinancialEnd.length > 0) {
                    oldFinancialEnd = moment(oldMembers[i].FinancialEnd).format(Constant.DateFormat);
                }

                if (((newMembers[i].FinancialTypeId !== null || oldMembers[i].FinancialTypeId !== null) && newMembers[i].FinancialTypeId !== oldMembers[i].FinancialTypeId) ||
                    (newFinancialEnd !== oldFinancialEnd)) {
                    return true;
                }
            }

            return false;
        }
        
        var newMembers = _.sortBy(members, [function(m) { return m.Id; }]);
        var oldMembers = _.sortBy(originalMembers, [function(m) { return m.Id; }]);
        var changed = _.isEqualWith(newMembers, oldMembers, customizer);
        if (changed) {
            confirm({
                title: t("common.submit_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    onSubmit();
                },
                onCancel() {
                    props.handleCancel(true);
                }
            });
        }
        else {
            props.handleCancel(false);
        }
    }

    const onClose = () => {
        submitConfirmation();
    }
    
    const handleOnChange = (value, record, dataIndex) => {
        if (record) {
            var index = members.findIndex(x => x.Id === record.Id);
            _.update(members[index], dataIndex, function (n) { 
                return value;
            });

            updateFieldsChange(members[index]);
        }

        setEnableSubmit(canSubmit(members));
    }

    const handleFinancialEndOnChange = (value, record, dataIndex) => {
        if (record) {
            var index = members.findIndex(x => x.Id === record.Id);
            _.update(members[index], dataIndex, function (n) { 
                return value;
            });

            var financialStatusId = members[index].FinancialStatusId;
            if (members[index].FinancialEnd) {
                var financialEnd = null;
                var length = members[index].FinancialEnd.split('/').length;
                if (length >= 3) {
                    financialEnd = moment(members[index].FinancialEnd, Constant.DateFormat);
                } else {
                    financialEnd = moment(members[index].FinancialEnd)
                }

                if (!financialEnd) {
                    financialStatusId = Constant.FinancialStatus.UnFinancial;
                } else {
                    var currentDate = moment();
                    var days = currentDate.diff(financialEnd, 'days');
                    if (days <= 0) {
                        financialStatusId = Constant.FinancialStatus.Financial;
                    } else {
                        financialStatusId = Constant.FinancialStatus.UnFinancial;
                    }
                }
            } else {
                financialStatusId = Constant.FinancialStatus.UnFinancial;
            }

            if (!financialStatusId) {
                financialStatusId = Constant.FinancialStatus.UnFinancial;
            }

            members[index].FinancialStatusId = financialStatusId;
            setMembers(current =>
                current.map(obj => {
                    if (obj.Id === members[index].Id) {
                        return {
                            ...obj,
                            FinancialStatusId: financialStatusId
                        };
                    }
                    return obj;
                }),
            );

            updateFieldsChange(members[index]);
        }

        setEnableSubmit(canSubmit(members));
    }

    const getMemberTitle = () => {
        return currentPosition != null && currentPosition.StateAssociationAcronym != null && currentPosition.StateAssociationAcronym.length > 0 ? currentPosition.StateAssociationAcronym + " #" : t('member.member_number');
    }

    const isClub = () => {
        return currentPosition != null && currentPosition.EntityTypeId === Constant.EntityType.Club;
    }

    var clubColumns = [
        {
            title: <span>{ getMemberTitle() }</span>,
            dataIndex: 'MemberId',
            key: 'MemberId',
            editable: false,
            sorter: false,
            width: 45,
        },
        {
            title: <span>{ t('member.financial_type') }</span>,
            dataIndex: 'FinancialTypeName',
            key: 'FinancialTypeName',
            editable: false,
            extraData: props.financialTypes,
            cellType: Constant.CellType.Selection,
            selectionKey: "Id",
            selectionDisplay: "Name",
            width: 95,
            handleOnChange: handleOnChange
        },
        {
            title: <span>{ t('home.financial_end') }</span>,
            dataIndex: 'FinancialEnd',
            key: 'FinancialEnd',
            editable: true,
            extraData: props.financialYears,
            cellType: Constant.CellType.Selection,
            selectionKey: "Code",
            selectionDisplay: "Name",
            width: 95,
            handleOnChange: handleFinancialEndOnChange
        },
        {
            title: <span>{ t('member.last_name') }</span>,
            dataIndex: 'LastName',
            key: 'LastName',
            editable: false,
            required: false,
            cellType: Constant.CellType.Input,
            width: 50,
        },
        {
            title: <span>{ t('member.first_name') }</span>,
            dataIndex: 'FirstName',
            key: 'FirstName',
            editable: false,
            required: false,
            cellType: Constant.CellType.Input,
            width: 50,
        },
        {
            title: t('member.family_group'),
            dataIndex: 'FamilyGroupName',
            key: 'FamilyGroupName',
            editable: false,
            required: false,
            cellType: Constant.CellType.Input,
            width: 55,
        },
        {
            title: <span>{ t('member.fishing_category') }</span>,
            dataIndex: 'FishingCategoryName',
            key: 'FishingCategoryName',
            editable: false,
            required: false,
            cellType: Constant.CellType.Input,
            width: 70,
        },
        {
            title: t('member.junior_dob'),
            dataIndex: 'DateOfBirth',
            key: 'DateOfBirth',
            sorter: false,
            width: 60,
            render: (value, record) => (
                <DateTimeCell value={record.DateOfBirth} />
            )
        }
    ]

    var stateColumns = [
        {
            title: <span>{ getMemberTitle() }</span>,
            dataIndex: 'MemberId',
            key: 'MemberId',
            sorter: false,
            width: 55,
        },
        {
            title: <span>{ t('member.club') }</span>,
            dataIndex: 'EntityName',
            key: 'EntityName',
            editable: false,
            required: false,
            cellType: Constant.CellType.Input,
            width: 75,
        },
        {
            title: <span>{ t('member.financial_type') }</span>,
            dataIndex: 'FinancialTypeName',
            key: 'FinancialTypeName',
            editable: false,
            extraData: props.financialTypes,
            cellType: Constant.CellType.Selection,
            selectionKey: "Id",
            selectionDisplay: "Name",
            width: 95,
            handleOnChange: handleOnChange
        },
        {
            title: <span>{ t('home.financial_end') }</span>,
            dataIndex: 'FinancialEnd',
            key: 'FinancialEnd',
            editable: true,
            extraData: props.financialYears,
            cellType: Constant.CellType.Selection,
            selectionKey: "Code",
            selectionDisplay: "Name",
            width: 95,
            handleOnChange: handleFinancialEndOnChange
        },
        {
            title: <span>{ t('member.last_name') }</span>,
            dataIndex: 'LastName',
            key: 'LastName',
            editable: false,
            required: false,
            cellType: Constant.CellType.Input,
            width: 50,
        },
        {
            title: <span>{ t('member.first_name') }</span>,
            dataIndex: 'FirstName',
            key: 'FirstName',
            editable: false,
            required: false,
            cellType: Constant.CellType.Input,
            width: 50,
        },
        {
            title: t('member.family_group'),
            dataIndex: 'FamilyGroupName',
            key: 'FamilyGroupName',
            editable: false,
            required: false,
            cellType: Constant.CellType.Input,
            width: 55,
        },
        {
            title: <span>{ t('member.fishing_category') }</span>,
            dataIndex: 'FishingCategoryName',
            key: 'FishingCategoryName',
            editable: false,
            required: false,
            cellType: Constant.CellType.Input,
            width: 70,
        },
        {
            title: t('member.junior_dob'),
            dataIndex: 'DateOfBirth',
            key: 'DateOfBirth',
            sorter: false,
            width: 60,
            render: (value, record) => (
                <DateTimeCell value={record.DateOfBirth} />
            )
        }
    ]

    var columns = isClub() ? clubColumns : stateColumns;
    const memberColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                disabled: col.disabled,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition,
                selectionKey: col.selectionKey,
                selectionDisplay: col.selectionDisplay,
                validateAtStart: col.validateAtStart
            }),
        };
    })

    const updateFieldsChange = (memberChange) => {
        if (fieldsChange.length > 0 && members.length > 1) {
            fieldsChange.forEach(fieldChange => {
                setMembers(current =>
                    current.map(obj => {
                        obj[fieldChange] = memberChange[fieldChange];
                        return obj;
                    }),
                );
            });
        }
    }

    return (
        <div>
            <Modal
                title={t("member.edit_members_financial_end_date")}
                centered
                forceRender
                open={true}
                onOk={onSubmit}
                confirmLoading={false}
                onCancel={onClose}
                okText={t("common.submit")}
                cancelText={t("common.cancel")}
                maskClosable={false}
                width={1200}
                footer={[
                    <Button key="back" onClick={onClose}>
                      {t("common.cancel")}
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} disabled={!enableSubmit} onClick={onSubmit}>
                      {t("common.submit")}
                    </Button>
                  ]}
            >
                <Form
                    {...layout}
                    name='editMemberFinancialDate'
                    key='editMemberFinancialDate'
                    onFinish={onSubmit}
                    component={false}
                    form={form}>
                    <Row className="m-t-10" gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Table
                                components={components}
                                rowClassName={() => 'editable-row'}
                                {...GetTableConfigs(memberColumns, members, members.length, "Id", null)}
                                pagination={false}
                                rowKey="Id"
                            />
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default EditMemberFinancialDate
import React from 'react';
import BasePointScore from '../BasePointScore/basePointScore';
import { Constant } from '../../../services/services';
import { useTranslation } from 'react-i18next';

const MRPointScore = (props) => {
    const { t } = useTranslation();

    return (
        <BasePointScore {...props} tagCaptureCode={Constant.TagCaptureCode.M_R}
            clubTitle={t("point_score.club_m_r_point_score")}
            stateTitle={t("point_score.state_m_r_point_score")}>
        </BasePointScore>
    )
}

export default MRPointScore;
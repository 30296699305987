import React, { useState, useEffect } from 'react';
import { Modal, Table, Row, Col, Button, Checkbox, Input, Form, Select, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { GetTableConfigs, ActionButtonsCell, EditableCell } from '../../../components/customTable/customTable'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Constant, CommonService, TrophyService } from '../../../services/services';
import { EyeIcon } from '../../../components/icons/icons';
import LoadingContext from '../../../components/loading/loadingContext'
import BoatDetail from './../boatDetail/boatDetail'
import './trophyDetail.scss'

const TrophyDetail = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [boatDetails, setboatDetails] = useState([]);
    const [visibleBoatDetail, setVisibleBoatDetail] = useState(false);
    const [selectedObject, setSelectedObject] = useState({})
    const [trophyDetail, setTrophyDetail] = useState({});

    useEffect(() => {
        if (props.visible && props.objectData) {
            let timeoutFn = setTimeout(() => {
                document.documentElement.style.setProperty('--trophy-detail-container-height', CommonService.calculateTableBodyMaxHeight("none-0", 10))
            }, 100)

            showLoading()
            let rankings = props.rankings
            if (!rankings) {
                rankings = 10
            }
            TrophyService.getTrophyDetail(props.objectData.Id, rankings, props.dateFrom, props.dateTo, props.financialYearIds)
                .finally(() => dismissLoading())
                .then(result => {
                    if (result.data && result.data.BoatDetails) {
                        setboatDetails(result.data.BoatDetails)
                        setTrophyDetail(result.data)
                    }
                })
                .catch(error => CommonService.handleErrorResponse(error))
        }
    }, [props.visible])

    const closeForm = () => {
        props.handleCancel()
    }

    const onTableChange = (pagination) => {
    }

    const viewFishDetail = (record) => {
        setVisibleBoatDetail(true)
        setSelectedObject(record)
    }

    const getViewButtonsInfo = (item) => {
        return [
            {
                Description: t("common.view"),
                Icon: <EyeIcon />,
                ColorClass: Constant.ColorClass.LightBlue,
                handleAction: viewFishDetail
            }
        ]
    }

    const getColumns = () => {
        let columns = []
        if (trophyDetail) {
            for (let i = 0; i < trophyDetail.BoatFieldCount; i++) {
                if(trophyDetail["HeaderField" + (i + 1)]) {
                    let column = {
                        title: trophyDetail["HeaderField" + (i + 1)],
                        dataIndex: 'Field' + (i + 1),
                        key: 'Field' + (i + 1),
                    }
                    columns.push(column)
                }              
            }

            // columns.push({
            //     title: t('trophy_management.view_trophy'),
            //     render: (value, record) => {
            //         return <ActionButtonsCell btnList={getViewButtonsInfo(record)} data={record} />
            //     },
            //     align: "center",
            //     width: 90
            // })
        }

        return columns
    }

    const components = {
        body: {
            cell: EditableCell,
        }
    };

    const handleCloseBoatDetail = () => {
        setVisibleBoatDetail(false)
    }

    return (
        <div>
            <Modal
                title={props.objectData.Name}
                open={true}
                onCancel={closeForm}
                confirmLoading={false}
                maskClosable={false}
                centered
                width={850}
                className='trophy-detail'
            >
                <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                    <Col xs={{ span: 24 }}>
                        <Table
                            components={components}
                            rowClassName={() => 'editable-row'}
                            {...GetTableConfigs(getColumns(), boatDetails, boatDetails.length, "Field1", onTableChange, 1, "", false)}
                        />
                    </Col>
                </Row>
            </Modal>

            {
                visibleBoatDetail &&
                <BoatDetail visible={visibleBoatDetail} handleCancel={handleCloseBoatDetail} objectData={selectedObject}></BoatDetail>
            }
        </div>
    )
}

export default TrophyDetail
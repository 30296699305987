import React, { useState, useEffect } from 'react';
import { Modal, Tree } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from './../../../utilities/globalState'
import { CommonService } from '../../../services/services'

const MenuAccess = (props) => {
    const { t } = useTranslation();
    const [appMenus] = useGlobalState('appMenus');
    const [menuData, setMenuData] = useState([]);
    const [selectedMenuIds, setSelectedMenuIds] = useState([]);
    const [treeHeight, setTreeHeight] = useState(300);
    const [autoExpandIds, setAutoExpandIds] = useState([]);

    useEffect(() => {
        let bodyHeight = treeHeight
        let bodyElement = document.getElementsByClassName("ant-modal-body");
        if (bodyElement && bodyElement.length > 0) {
            bodyHeight = bodyElement[bodyElement.length - 1].offsetHeight
            setTreeHeight(bodyHeight)
        }

        if (appMenus && appMenus.length > 0) {
            let menus = []
            let allIds = []
            appMenus.forEach(item => {
                allIds.push(item.Id)
                let menu = createDisplayMenu(item, allIds)
                menus.push(menu)
            });
            setMenuData(menus)
            setAutoExpandIds(allIds)

            if (props.objectData && props.objectData.Menus && props.objectData.Menus.length > 0) {
                let ids = props.objectData.Menus.map((n, index) => n.Id)
                setSelectedMenuIds(ids)
            }
        }
    }, [props.objectData])

    const createDisplayMenu = (menu, allIds) => {
        if (menu.SubMenus && menu.SubMenus.length > 0) {
            let displaySubMenus = []
            menu.SubMenus.forEach(item => {
                allIds.push(item.Id)
                let displaySubMenu = createDisplayMenu(item, allIds)
                displaySubMenus.push(displaySubMenu)
            });
            return getMenuItem(menu.Name, menu.Id, props.viewOnly ? true : false, displaySubMenus)
        } else {
            return getMenuItem(menu.Name, menu.Id, props.viewOnly ? true : false)
        }
    }

    const getMenuItem = (title, key, disabled, children) => {
        return {
            "title": title,
            "key": key,
            "disabled": disabled,
            "children": children
        };
    }

    const onCheck = (e, info) => {
        setSelectedMenuIds(e.checked)

        let allMenus = []
        CommonService.getAllSubMenus(appMenus, allMenus)
        if (info && info.checked) {
            if (info.node) {
                let checkedItem = allMenus.find(m => m.Id == info.node.key)
                if (checkedItem) {
                    let allParents = []
                    CommonService.getAllParentMenus(allMenus, checkedItem, allParents)
                    if (allParents && allParents.length > 0) {
                        allParents.forEach(parent => {
                            if (e.checked.indexOf(parent.Id) < 0) {
                                e.checked.push(parent.Id)
                            }
                        })                      
                    }
                    if (checkedItem.SubMenus && checkedItem.SubMenus.length > 0) {
                        let allSubMenus = []
                        CommonService.getAllSubMenus(checkedItem.SubMenus, allSubMenus)
                        allSubMenus.forEach(menu => {
                            if (e.checked.indexOf(menu.Id) < 0) {
                                e.checked.push(menu.Id)
                            }
                        })
                    }
                }
            }
        } else if (info && !info.checked) {
            if (info.node) {
                let unCheckedItem = allMenus.find(m => m.Id == info.node.key)
                if (unCheckedItem) {
                    let allParents = []
                    CommonService.getAllParentMenus(allMenus, unCheckedItem, allParents)
                    if(allParents && allParents.length > 0){
                        allParents.forEach(parent => {
                            if(parent && parent.SubMenus && parent.SubMenus.length > 0){
                                let ids = parent.SubMenus.map(m => m.Id)
                                let checked = e.checked.filter(c => ids.indexOf(c) > -1)
                                if(!checked || checked.length == 0){
                                    let index = e.checked.indexOf(parent.Id)
                                    if(index > -1){
                                        e.checked.splice(index, 1)
                                    }
                                }
                            }
                        })
                    }                   

                    if (unCheckedItem.SubMenus && unCheckedItem.SubMenus.length > 0) {
                        let allSubMenus = []
                        CommonService.getAllSubMenus(unCheckedItem.SubMenus, allSubMenus)
                        allSubMenus.forEach(menu => {
                            let index = e.checked.indexOf(menu.Id)
                            if (index > -1) {
                                e.checked.splice(index, 1)
                            }
                        })
                    }
                }
            }
        }
    };

    const closeForm = () => {
        props.handleCancel()
    }

    const onSubmit = () => {
        let selectedMenus = []
        if (selectedMenuIds && selectedMenuIds.length > 0) {
            GetSelectedMenus(appMenus, selectedMenuIds, selectedMenus)
        }
        props.objectData.Menus = selectedMenus
        props.handleCancel(props.objectData)
    }

    const GetSelectedMenus = (menus, selectedIds, selectedMenus) => {
        if (menus && menus.length > 0) {
            if (selectedIds && selectedIds.length > 0) {
                let query = menus.filter(m => selectedIds.indexOf(m.Id) > -1)
                if (query.length > 0) {
                    query.forEach(item => {
                        selectedMenus.push(item)
                        if (item.SubMenus && item.SubMenus.length > 0) {
                            GetSelectedMenus(item.SubMenus, selectedIds, selectedMenus)
                        }
                    })
                }
            }
        }
    }

    return (
        <div>
            <Modal
                title={t("position_title.menu_access")}
                onOk={onSubmit}
                open={true}
                confirmLoading={false}
                onCancel={closeForm}
                maskClosable={false}
                className="vertical-modal modal-open"
                {...(props.viewOnly ? { footer: null } : {})}
            >
                <Tree
                    checkable
                    checkedKeys={selectedMenuIds}
                    onCheck={onCheck}
                    treeData={menuData}
                    height={treeHeight}
                    expandedKeys={autoExpandIds}
                    checkStrictly
                />
            </Modal>
        </div>
    )
}

export default MenuAccess

import React, { useEffect, useState } from 'react';
import './boatCategoryManagement.scss';
import { useTranslation } from 'react-i18next';
import {
    Constant,
    CommonService,
    SettingService
} from '../../../services/services'
import { Table, Button, Row, Col, Form, Input, Modal, Breadcrumb, Drawer, Space, Empty } from 'antd';
import { GetTableConfigs, StatusCell } from '../../../components/customTable/customTable'
import { FilterAltIcon, CloseIcon } from '../../../components/icons/icons'
import LoadingContext from '../../../components/loading/loadingContext'
import EnhancedSelect from '../../../components/enhancedSelect/enhancedSelect';
import CreateEditBoatCategory from './createEditBoatCategory';
import { useGlobalState } from './../../../utilities/globalState'
import PubSub from 'pubsub-js';
const { confirm } = Modal;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const BoatCategoryManagement = (props) => {
    const { t } = useTranslation();
    const [formFilter] = Form.useForm();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext);
    const [totalItems, setTotalItems] = useState(0);
    const [boatCategories, setBoatCategories] = useState([]);
    const [selectedBoatCategories, setSelectedBoatCategories] = useState([]);
    const [visibleModal, setVisibleModal] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [searchBoatCategory, setSearchBoatCategory] = useState({ StatusIds: [Constant.StatusCode.Active] });

    const [gridConfigOptions, setGridConfigOptionsValue] = useState({
        pageNumber: 1,
        pageSize: CommonService.getPageSize(),
        sort: "Name-ascend",
    })

    const [currentPosition] = useGlobalState('currentPosition');

    const search = (pageNumber, pageSize, sort, query, isShowLoading = false) => {
        if (isShowLoading) {
            showLoading();
        }

        let params = { ...query };
        if (!params) params = { StatusIds: [Constant.StatusCode.Active] };

        SettingService.getBoatCategories(pageNumber, pageSize, sort, params)
            .finally(() => {
                if (isShowLoading) {
                    dismissLoading();
                }

                setSelectedRowKeys([]);
                setSelectedBoatCategories([]);
            })
            .then(result => {
                if (result.data) {
                    setBoatCategories(result.data.ObjectList);
                    setTotalItems(result.data.TotalItems);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const addBoatCategories = () => {
        setIsEdit(false);
        setVisibleModal(true);
    }

    const editBoatCategories = () => {
        setIsEdit(true);
        setVisibleModal(true);
    }

    const deleteBoatCategories = async () => {
        confirm({
            title: t("boat.delete_club_boat_categories_confirm"),
            okText: t("common.yes"),
            cancelText: t("common.no"),
            onOk() {
                if (selectedRowKeys.length === 0) {
                    return;
                }
                var ids = selectedRowKeys;
                showLoading();

                SettingService.deleteBoatCategories(ids)
                    .finally(() => {
                        dismissLoading();
                    })
                    .then(result => {
                        refreshData();
                    })
                    .catch(error => {
                        CommonService.handleErrorResponse(error);
                    });
            }
        });
    }

    const handleCancel = (refresh) => {
        setVisibleModal(false);
        if (refresh) {
            refreshData();
        }

    }

    const columns = [
        {
            title: t('boat.boat_category'),
            dataIndex: 'Name',
            key: 'Name',
            sorter: true,
            defaultSortOrder: 'ascend',
            width: 100,
        },
        {
            title: t('common.description'),
            dataIndex: 'Description',
            key: 'Description',
            sorter: true,
            width: 160,
        },
        {
            title: t('common.status'),
            dataIndex: 'StatusId',
            key: 'StatusId',
            sorter: false,
            width: 35,
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.StatusId)}
                    description={CommonService.getStatusDescription(record.StatusId, false)} />
            )
        }
    ]

    const onTableChange = (pagination, filters, sorter, extra) => {
        let data = formFilter.getFieldsValue();
        if (!data || (data && !data.StatusIds)) {
            data.StatusIds = [Constant.StatusCode.Active];
        }

        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = gridConfigOptions.sort;
        }
        search(pagination.current, pagination.pageSize, sort, data);

        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        });
    }

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (newSelectedRowKeys, newSelectedRows) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setSelectedBoatCategories(newSelectedRows);
        }
    }

    const showFilterLayout = () => {
        setOpenFilter(true);
    }

    const closeFilterLayout = () => {
        formFilter.setFieldsValue(searchBoatCategory);
        setOpenFilter(false);
    }

    const refreshData = (isShowLoading = false) => {
        setSelectedRowKeys([]);
        setSelectedBoatCategories([]);

        let data = formFilter.getFieldsValue();
        if (!data || (data && !data.StatusIds)) {
            data.StatusIds = [Constant.StatusCode.Active];
        }
        setSearchBoatCategory(data);

        search(1, gridConfigOptions.pageSize, gridConfigOptions.sort, data, isShowLoading);

        setGridConfigOptionsValue(
            {
                ...gridConfigOptions,
                pageNumber: 1
            }
        );

    }

    const resetFilter = () => {
        formFilter.resetFields();
        setSearchBoatCategory({ StatusIds: [Constant.StatusCode.Active] });
    }

    const applyFilter = () => {

        refreshData(true);

        setOpenFilter(false);
    }


    const isClubCommittee = () => {
        return currentPosition != null && currentPosition.PositionTypeId === Constant.PositionType.ClubCommittee;
    }

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
        }, 100)

        showLoading();
        Promise.all([
            SettingService.getBoatCategories(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, { StatusIds: [Constant.StatusCode.Active] }),
            SettingService.getStatusCodes()
        ])
            .finally(() => {
                dismissLoading();
                setSelectedRowKeys([]);
                setSelectedBoatCategories([]);
                setSearchBoatCategory({ StatusIds: [Constant.StatusCode.Active] });
            })
            .then((response) => {
                if (response[0].data) {
                    setBoatCategories(response[0].data.ObjectList);
                    setTotalItems(response[0].data.TotalItems);
                }

                if (response[1].data) {
                    setStatuses(response[1].data);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);

        return () => {
            SettingService.cancelRequest();
            clearTimeout(timeoutFn);
        }
    }, [])

    return (
        <>
        {isClubCommittee() &&
            <div className="boat-category-management">
                <Breadcrumb>
                    <Breadcrumb.Item>{t("menu.club_boat_categories")}</Breadcrumb.Item>
                </Breadcrumb>
                <Row id={Constant.FixItemsContainerId} className="fix-item-container">
                    <Col xs={{ span: 24 }} className="text-right extra-btn-m-t">
                        <div className="m-t-10">

                            <Button onClick={showFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon className="anticon anticon-search" />}>{t("common.filter")}</Button>

                            <Button id='btnDelete' disabled={selectedBoatCategories.length === 0} danger className="m-l-10 secondary-btn" onClick={deleteBoatCategories}>
                                {t("common.delete")}
                            </Button>

                            <Button id='btnEdit' disabled={selectedBoatCategories.length === 0} className={selectedBoatCategories.length > 0 ? "m-l-10 secondary-btn btn-outline-dark-blue" : "m-l-10 secondary-btn"} onClick={editBoatCategories}>
                                {t("common.edit")}
                            </Button>

                            <Button className="m-l-10 secondary-btn btn-solid-dark-blue" type="primary" onClick={addBoatCategories}>
                                {t("common.add_new")}
                            </Button>
                        </div>
                    </Col>
                </Row>

                <Row className="m-t-10 section-table" gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                    <Col xs={{ span: 24 }}>
                        <Table
                            {...GetTableConfigs(columns, boatCategories, totalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                            rowSelection={rowSelection}
                        />
                    </Col>
                </Row>

                {/* Filter Layout  */}
                <Drawer
                    title={t("common.filter")}
                    width={378}
                    onClose={closeFilterLayout}
                    open={openFilter}
                    closeIcon={false}
                    styles={{
                        body: {
                            paddingBottom: 80,
                        }
                    }}
                    extra={
                        <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                            <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                            <Button onClick={closeFilterLayout} icon={<CloseIcon />} type="link" />
                        </Space>
                    }
                    footer={
                        <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                            <Button onClick={closeFilterLayout}>{t("common.cancel")}</Button>
                            <Button onClick={applyFilter} type="primary">
                                {t("common.apply")}
                            </Button>
                        </Space>
                    }>
                    <Form
                        layout="vertical"
                        {...layout}
                        form={formFilter}
                        name="formFilter"
                        key='formFilter'
                        initialValues={{ StatusIds: [Constant.StatusCode.Active] }}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="Name"
                                    label={t("boat.boat_category")}
                                >
                                    <Input allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="Description"
                                    label={t("common.description")}
                                >
                                    <Input allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="StatusIds"
                                    label={t("common.status")}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={statuses ? statuses.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Drawer>

                {/* Create/Edit Boat Category Layout  */}
                {
                    visibleModal &&
                    <CreateEditBoatCategory
                        handleCancel={handleCancel}
                        isEdit={isEdit}
                        selectedBoatCategories={selectedBoatCategories}
                        statuses={statuses} >

                    </CreateEditBoatCategory>
                }
            </div>
        }

        {!isClubCommittee() &&
            <>
                <Table
                    style={{ visibility: 'hidden', height: '0px' }}
                    {...GetTableConfigs(columns, [], 0, "Id", onTableChange, gridConfigOptions.pageNumber)}
                />
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_access")} />
            </>
        }
        </>
    )

}

export default BoatCategoryManagement;
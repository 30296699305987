import React, { useState, useEffect } from 'react';
import { Modal, Table, Row, Col, Button, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import LoadingContext from '../../../components/loading/loadingContext'
import { useGlobalState } from './../../../utilities/globalState'
import { Constant, CommonService, SettingService } from '../../../services/services';
import { EditIcon } from '../../../components/icons/icons';
import { GetTableConfigs, ActionButtonsCell, EditableCell, TextAndButtonCell } from '../../../components/customTable/customTable'
import MenuAccess from './../menuAccess/menuAccess';
import MemberList from './../memberList/memberList';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

const AddUpdatePositionTitle = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [positionTitles, setPositionTitles] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [visibleMemberList, setVisibleMemberList] = useState(false)
    const [visibleMenuAccess, setVisibleMenuAccess] = useState(false)
    const [selectedObject, setSelectedObject] = useState();
    const [allMembers, setAllMembers] = useState([]);
    const [isDirty, setIsDirty] = useState(false);
    const [currentPosition] = useGlobalState('currentPosition');
    const [defaultPosition, setDefaultPosition] = useState({});
    const [appMenus] = useGlobalState('appMenus');

    const DefaultClubMemberMenuAccess = [
        Constant.Pages.MemberViewClubContacts,
        Constant.Pages.MemberViewStateContacts,
        Constant.Pages.ClubDefaults,
        Constant.Pages.StateDefaults,
        Constant.Pages.EditMemberDetails,
        Constant.Pages.MembershipSearch,
        Constant.Pages.BoatSearch,
        Constant.Pages.CreateDayLog,
        Constant.Pages.DayLogList,
        Constant.Pages.ActivityEntriesList,
        Constant.Pages.ExportActivity,
        Constant.Pages.TagCardSearch,
        Constant.Pages.ViewTrophy,
        Constant.Pages.DayLogSummary,
        Constant.Pages.ClubApprovalsStatus,
        Constant.Pages.StateApprovalsStatus,
        Constant.Pages.TournamentCalendar,
        Constant.Pages.StateRecord,
        Constant.Pages.ClubRecord,
        Constant.Pages.ViewDocuments
    ]

    useEffect(() => {
        if (props.visible) {
            if (!props.objectData || props.objectData.length == 0) {
                setPositionTitles([getBlankLine()])
                setIsEdit(false)
            } else {
                setPositionTitles(props.objectData.map(x => Object.assign({}, x)))
                setIsEdit(true)
            }

            showLoading()
            Promise.all([
                SettingService.getMembersByClub(),
                SettingService.getDefaultPositionTitle()
            ])
                .finally(() => dismissLoading())
                .then(response => {
                    if (response[0] && response[0].data) {
                        setAllMembers(response[0].data)
                    }
                    if (response[1] && response[1].data) {
                        setDefaultPosition(response[1].data)
                    }
                })
                .catch(error => CommonService.handleErrorResponse(error))
        }
    }, [props.visible])

    const updateMenuAccess = (record) => {
        setSelectedObject(record)
        setVisibleMenuAccess(true)
    }

    const getMenuAccessButtonsInfo = (record) => {
        return [
            {
                Description: t("position_title.menu_access"),
                ColorClass: Constant.ColorClass.LightBlue,
                Icon: <EditIcon />,
                Hide: false,
                handleAction: updateMenuAccess
            }
        ]
    }

    const handleOnRecordChange = (value, record, dataIndex) => {
        if (record) {
            if (dataIndex == "IsDefault") {
                let needConfirm = false
                let currentDefaultName = ""

                if (value) {
                    if (defaultPosition && defaultPosition.PositionTitleId > 0) {
                        if (!isEdit) {
                            currentDefaultName = defaultPosition.PositionTitleName
                            needConfirm = true
                        } else {
                            if (record.PositionTitleId != defaultPosition.PositionTitleId) {
                                currentDefaultName = defaultPosition.PositionTitleName
                                needConfirm = true
                            }
                        }
                    }

                    if (!needConfirm) {
                        let currentDefault = positionTitles.find(p => p.PositionTitleId != record.PositionTitleId && p.IsDefault)
                        if (currentDefault) {
                            currentDefaultName = currentDefault.PositionTitleName
                            needConfirm = true
                        }
                    }

                    if (!needConfirm) {
                        let temp = positionTitles.map(x => Object.assign({}, x));
                        let index = positionTitles.indexOf(record)
                        if (temp[index]) {
                            temp[index][dataIndex] = value;
                            for (let i = 0; i < temp.length; i++) {
                                if (temp[i].PositionTitleId != record.PositionTitleId) {
                                    temp[i].IsDefault = false
                                }
                            }
                            setDefaultPosition(record)
                            setPositionTitles(temp)
                            checkDirty(temp)
                        }
                    } else {
                        confirm({
                            title: t("common.confirm"),
                            icon: <ExclamationCircleOutlined />,
                            content: t("position_title.default_change_confirm", { name: currentDefaultName }),
                            okText: t("common.yes"),
                            cancelText: t("common.no"),
                            onOk() {
                                let temp = positionTitles.map(x => Object.assign({}, x));
                                let index = positionTitles.indexOf(record)
                                if (temp[index]) {
                                    temp[index][dataIndex] = value;
                                    for (let i = 0; i < temp.length; i++) {
                                        if (temp[i].PositionTitleId != record.PositionTitleId) {
                                            temp[i].IsDefault = false
                                        }
                                    }
                                    setDefaultPosition(record)
                                    setPositionTitles(temp)
                                    checkDirty(temp)
                                }
                            },
                            onCancel() {
                            }

                        });
                    }
                }

            } else {
                let temp = positionTitles.map(x => Object.assign({}, x));
                let index = positionTitles.indexOf(record)
                if (temp[index]) {
                    temp[index][dataIndex] = value;

                    if (dataIndex == "PositionTypeId") {
                        if (value != Constant.PositionType.ClubMember) {
                            temp[index]["IsDefault"] = false
                        } else {
                            if(!record.Menus || record.Menus.length == 0) {
                                let allMenus = []
                                CommonService.getAllSubMenus(appMenus, allMenus)
                                let defaultMenus = allMenus.filter(m => DefaultClubMemberMenuAccess.indexOf(m.Slug) >= 0 || m.Name == "Home")
                                let parentMenus = []
                                for(let i = 0; i < defaultMenus.length; i++) {
                                    let temp = []
                                    CommonService.getAllParentMenus(allMenus, defaultMenus[i], temp)
                                    if(temp && temp.length > 0) {
                                        for(let j = 0; j < temp.length; j++) {
                                            if(parentMenus.indexOf(temp[j] < 0)) {
                                                parentMenus.push(temp[j])
                                            }
                                        }
                                    }
                                }
                                
                                temp[index].Menus = [...defaultMenus, ...parentMenus]
                            }
                        }
                    }
                    setPositionTitles(temp)
                    checkDirty(temp)
                }
            }
        }
    }

    const editMembers = (record) => {
        setSelectedObject(record)
        setVisibleMemberList(true)
    }

    const disableEmail = (record) => {
        if (record && record.PositionTypeId == Constant.PositionType.ClubMember) {
            return true
        }
        return false
    }

    const columns = [
        {
            title: t('position_title.position_title'),
            dataIndex: 'PositionTitleName',
            key: 'PositionTitleName',
            editable: true,
            required: true,
            cellType: Constant.CellType.Input,
            width: 150,
            validateAtStart: true,
            handleOnChange: handleOnRecordChange
        },
        {
            title: t('position_title.position_type'),
            dataIndex: 'PositionTypeId',
            key: 'PositionTypeId',
            editable: true,
            extraData: props.positionTypes,
            required: true,
            cellType: Constant.CellType.Selection,
            width: 150,
            selectionKey: "Id",
            selectionDisplay: "Name",
            validateAtStart: true,
            handleOnChange: handleOnRecordChange
        },
        {
            title: t('position_title.position_email'),
            dataIndex: 'PositionTitleEmail',
            key: 'PositionTitleEmail',
            editable: true,
            required: false,
            cellType: Constant.CellType.EmailInput,
            width: 150,
            validateAtStart: true,
            handleOnChange: handleOnRecordChange,
            handleDisabledCell: disableEmail
        },
        {
            title: t('position_title.position_holders'),
            key: 'Members',
            render: (value, record) => {
                let text = ""
                if (record.PositionTypeId > 0 && record.PositionTypeId != Constant.PositionType.ClubMember) {
                    if (record.Members && record.Members.length == 1) {
                        text = record.Members[0].FirstName + " " + record.Members[0].LastName
                    } else if (record.Members && record.Members.length > 1) {
                        text = record.Members.length
                    }
                }

                return <TextAndButtonCell value={text} disabled={record.PositionTypeId > 0 && record.PositionTypeId != Constant.PositionType.ClubMember ? false : true}
                    data={record} icon={<EditIcon />} includeWhiteSpaces={true} onClick={editMembers} colorClass={Constant.ColorClass.LightBlue} />
            },
            width: 120
        },
        {
            title: t('position_title.is_default'),
            key: 'IsDefault',
            render: (value, record) => {
                let enable = false
                if (record.PositionTypeId > 0 && record.PositionTypeId == Constant.PositionType.ClubMember && 
                    currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee &&
                    !record.IsDefault) {
                    enable = true
                }

                return <Checkbox checked={record.IsDefault} onChange={(e) => handleOnRecordChange(e.target.checked, record, "IsDefault")} disabled={!enable}></Checkbox>
            },
            align: 'center',
            width: 50
        },
        {
            title: t('position_title.menu_access'),
            key: 'Menus',
            render: (value, record) => (
                <ActionButtonsCell btnList={getMenuAccessButtonsInfo(record)} data={record} />
            ),
            align: 'center',
            width: 50
        },

    ]

    const columnsMapping = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition,
                selectionKey: col.selectionKey,
                selectionDisplay: col.selectionDisplay,
                validateAtStart: col.validateAtStart,
                handleDisabledCell: col.handleDisabledCell,
                
            }),
        };
    });

    const components = {
        body: {
            cell: EditableCell,
        }
    };

    const onTableChange = (pagination) => {
    }

    const getBlankLine = () => {
        let index = 0;
        if (positionTitles && positionTitles.length > 0) {
            index = positionTitles.length
        }
        return {
            PositionTitleId: index,
            PositionTitleName: "",
            PositionTypeId: null,
            Menus: [],
            Members: []
        };
    };

    const addBlankLine = () => {
        let lines = [
            ...positionTitles,
            getBlankLine()
        ]

        setPositionTitles(lines)
    }

    const handleMemberListCancel = (objectData) => {
        setVisibleMemberList(false)
        if (objectData) {
            let temp = positionTitles.map(x => Object.assign({}, x));
            let index = positionTitles.indexOf(objectData)
            if (temp[index]) {
                setIsDirty(true)
                temp[index].Members = objectData.Members;
                setPositionTitles(temp);
            }
        }
    }

    const handleMenuAccessCancel = (objectData) => {
        setVisibleMenuAccess(false)
        if (objectData) {
            let temp = positionTitles.map(x => Object.assign({}, x));
            let index = positionTitles.indexOf(objectData)
            if (temp[index]) {
                setIsDirty(true)
                temp[index].Menus = objectData.Menus;
                setPositionTitles(temp);
            }
        }
    }

    const canSubmit = () => {
        if (positionTitles && positionTitles.length > 0) {
            let inValid = positionTitles.filter(p => !p.PositionTitleName || !p.PositionTypeId || p.PositionTypeId <= 0 || (p.PositionTitleEmail && !CommonService.verifyEmail(p.PositionTitleEmail)))
            if (inValid && inValid.length > 0) {
                return false
            }
            return true
        }
        return false
    }

    const checkDirty = (data) => {
        if(data && data.length > 0){
            if(!isEdit){
                let newItems = data.filter(d => d.Id > 0)
                if(newItems && newItems.length > 0) {
                    setIsDirty(true)
                } else {
                    setIsDirty(false)
                }
            } else{
                let dirty = false
                for(let i = 0; i < data.length; i++)
                {
                    let original = props.objectData.find(o => o.Id == data[i].Id)
                    if(!original){
                        dirty = true
                        break
                    } else {
                        if (!CommonService.shallowCompare(data[i], original)) {
                            dirty = true
                            break
                        }
                    }
                    
                }

                if(dirty){
                    setIsDirty(true)
                } else{
                    setIsDirty(false)
                }
            }
        }    
    }

    const closeForm = () => {
        if (isDirty) {
            confirm({
                title: t("common.confirm"),
                icon: <ExclamationCircleOutlined />,
                content: t("position_title.cancel_change_warning"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    props.handleCancel()
                },
                onCancel() {
                }

            });
        } else {
            props.handleCancel()
        }
    }

    const onSubmit = () => {
        if (canSubmit()) {
            if (isEdit) {
                showLoading()
                SettingService.updatePositionTitles(positionTitles)
                    .finally(() => dismissLoading())
                    .then(result => {
                        if (result.data) {
                            CommonService.presentToast('success', t("position_title.update_successful"))
                            props.handleCancel(result.data)
                        }
                    })
                    .catch(error => CommonService.handleErrorResponse(error))
            } else {
                showLoading()
                SettingService.addPositionTitles(positionTitles)
                    .finally(() => dismissLoading())
                    .then(result => {
                        if (result.data) {
                            CommonService.presentToast('success', t("position_title.create_successful"))
                            props.handleCancel(result.data)
                        }
                    })
                    .catch(error => CommonService.handleErrorResponse(error))
            }
        }
    }
    return (
        <div>
            <Modal
                title={isEdit ? t("position_title.edit_position") : t("position_title.create_position")}
                open={true}
                onCancel={closeForm}
                confirmLoading={false}
                maskClosable={false}
                centered
                width={850}
                footer={[
                    <Button key={0} size="large" onClick={closeForm}>{t("common.cancel")}</Button>,
                    <Button key={1} size="large" type="primary" disabled={!canSubmit()} onClick={onSubmit}>{isEdit ? t("common.submit") : t("common.insert")}</Button>
                ]}
            >
                {!isEdit &&
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                        <Col flex={100} className="text-right">
                            <div>
                                <Button className='btn-solid-dark-blue m-l-10' onClick={addBlankLine}>{t("common.add")}</Button>
                            </div>
                        </Col>
                    </Row>
                }
                <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                    <Col xs={{ span: 24 }}>
                        <Table
                            components={components}
                            rowClassName={() => 'editable-row'}
                            {...GetTableConfigs(columnsMapping, positionTitles, positionTitles.length, "PositionTitleId", onTableChange, 1, "", false)}
                        />
                    </Col>
                </Row>
            </Modal>

            {
                visibleMemberList &&
                <MemberList handleCancel={handleMemberListCancel} viewOnly={false} allMembers={allMembers} objectData={selectedObject} memberListType={Constant.MemberListType.PositionHolder}></MemberList>
            }
            {
                visibleMenuAccess &&
                <MenuAccess handleCancel={handleMenuAccessCancel} viewOnly={false} objectData={selectedObject}></MenuAccess>
            }
        </div>
    )
}

export default AddUpdatePositionTitle

import React, { useState, useEffect } from 'react';
import { Constant, CommonService, TournamentService } from '../../../services/services';
import { useTranslation } from 'react-i18next';
import { GetTableConfigs, StatusCell, ActionButtonsCell, DateTimeCell } from '../../../components/customTable/customTable'
import LoadingContext from '../../../components/loading/loadingContext'
import EnhancedSelect from '../../../components/enhancedSelect/enhancedSelect';
import { FilterAltIcon, EyeIcon, TrashIcon, CloseIcon, EditIcon } from '../../../components/icons/icons';
import { Row, Col, Table, Button, DatePicker, Modal, Form, Drawer, Space, Input, Select, Typography, Empty, Upload } from 'antd';
import { ExclamationCircleOutlined, CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { useGlobalState } from '../../../utilities/globalState'
import TournamentSanctioningApplication from '../sanctioningApplications/tournamentSanctioningApplication'
import PubSub from 'pubsub-js'

const { confirm } = Modal;
const { Option } = Select;
const { Title, Text } = Typography;
const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const defaultGridOption = {
    pageNumber: 1,
    pageSize: CommonService.getPageSize(),
    sort: "StartDate-descend"
}

const SanctioningApplications = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const emptyTableData = {
        ObjectList: [],
        TotalItems: 0
    }
    const [gridConfigOptions, setGridConfigOptionsValue] = useState(defaultGridOption)
    const [currentPosition] = useGlobalState('currentPosition');
    const [formFilter] = Form.useForm();
    const [visibleFilter, setVisibleFilter] = useState(false);
    const [sanctioningData, setSanctioningData] = useState(emptyTableData)
    const [sanctioningFilter, setSanctioningFilter] = useState({ StatusIds: Constant.StatusCode.Active, IsApplyForTournamentSanctioning: true });
    const [selectedObject, setSelectedObject] = useState();
    const [visibleNote, setVisibleNote] = useState(false);
    const [visibleSanctioningApplication, setVisibleSanctioningApplication] = useState(false);
    const [visibleAddEditTournament, setVisibleAddEditTournament] = useState(false);
    const [imageBase64, setImageBase64] = useState();

    const viewDataType = {
        Notes: 1,
        Flyer: 2,
        Rules: 3,
        EntryForm: 4
    }

    const statusCodes = [
        {
            Value: Constant.StatusCode.Active,
            Description: t("common.active")
        },
        {
            Value: Constant.StatusCode.Inactive,
            Description: t("common.inactive")
        }
    ]

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
        }, 100)

        showLoading()
        Promise.all([
            TournamentService.getTournaments(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, sanctioningFilter)
        ])
            .finally(() => dismissLoading())
            .then(response => {
                if (response[0] && response[0].data) {
                    setSanctioningData(response[0].data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);
        return () => {
            TournamentService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    const viewDataTypeSelection = (dataType, record) => {
        if (dataType == viewDataType.Notes) {
            setVisibleNote(true)
            setSelectedObject(record)
        }
        else if (dataType == viewDataType.Flyer) {

        }
        else if (dataType == viewDataType.Rules) {

        }
        else if (dataType == viewDataType.EntryForm) {

        }
    }

    const applyForTournamentSanctioning = (record) => {
        setVisibleSanctioningApplication(true)
        setSelectedObject(record)
    }

    const deleleTournamentSanctioning = (record) => {
        confirm({
            title: t("common.confirm"),
            icon: <ExclamationCircleOutlined />,
            content: t("sanctioning_applications.delete_confirm"),
            okText: t("common.yes"),
            cancelText: t("common.no"),
            onOk() {

                showLoading()
                TournamentService.deleteTournament(record.Id)
                    .finally(() => dismissLoading())
                    .then(result => {
                        CommonService.presentToast('success', t("sanctioning_applications.delete_tournament_successfully"))
                        getTournamentSanctioningData(1, gridConfigOptions.pageSize, sanctioningFilter, gridConfigOptions.sort)
                        setGridConfigOptionsValue({
                            ...gridConfigOptions,
                            pageNumber: 1
                        })
                    })
                    .catch(error => CommonService.handleErrorResponse(error))
            },
            onCancel() {
            }

        });
    }

    const columns = [
        {
            title: t('common.action'),
            key: 'Id',
            render: (value, record) => {
                return <ActionButtonsCell data={record} btnList={[
                    {
                        Description: t("common.apply"),
                        Icon: <CheckOutlined />,
                        ColorClass: Constant.ColorClass.LightGreen,
                        Hide: record.StatusId == Constant.StatusCode.Active ? false : true,
                        handleAction: applyForTournamentSanctioning
                    },
                    {
                        Description: t("common.delete"),
                        Icon: <TrashIcon />,
                        ColorClass: Constant.ColorClass.LightRed,
                        Hide: record.StatusId == Constant.StatusCode.Active ? false : true,
                        handleAction: deleleTournamentSanctioning
                    }
                ]} />
            },
            align: 'center',
            width: 80,
        },
        {
            title: t('sanctioning_applications.title'),
            dataIndex: 'Title',
            key: 'Title',
            sorter: true,
            width: 200
        },
        {
            title: t('sanctioning_applications.start_date'),
            dataIndex: 'StartDate',
            key: 'StartDate',
            sorter: true,
            width: 100,
            defaultSortOrder: 'descend',
            render: (value, record) => (
                <DateTimeCell value={record.StartDate} />
            )
        },
        {
            title: t('sanctioning_applications.finish_date'),
            dataIndex: 'FinishDate',
            key: 'FinishDate',
            sorter: true,
            width: 100,
            render: (value, record) => (
                <DateTimeCell value={record.FinishDate} />
            )
        },
        {
            title: t('sanctioning_applications.notes'),
            key: 'Id',
            render: (value, record) => {
                return <ActionButtonsCell data={record} btnList={[
                    {
                        Description: t("common.view"),
                        Icon: <EyeIcon />,
                        ColorClass: Constant.ColorClass.LightBlue,
                        Hide: false,
                        NoMargin: true,
                        handleAction: () => viewDataTypeSelection(viewDataType.Notes, record)
                    }
                ]} />
            },
            align: 'center',
            width: 80,
        },
        {
            title: t('sanctioning_applications.flyer'),
            key: 'Id',
            render: (value, record) => {
                return <>
                    {

                        record.FlyerUrl &&
                        <ActionButtonsCell data={record} btnList={[
                            {
                                Description: t("common.view"),
                                Icon: <EyeIcon />,
                                ColorClass: Constant.ColorClass.LightBlue,
                                Hide: false,
                                NoMargin: true,
                                Type: Constant.ButtonType.Link,
                                Link: record.FlyerUrl
                            }
                        ]} />
                    }
                </>
            },
            align: 'center',
            width: 80,
        },
        {
            title: t('sanctioning_applications.rules'),
            key: 'Id',
            render: (value, record) => {
                return <>
                    {
                        record.RulesUrl &&
                        <ActionButtonsCell data={record} btnList={[
                            {
                                Description: t("common.view"),
                                Icon: <EyeIcon />,
                                ColorClass: Constant.ColorClass.LightBlue,
                                Hide: false,
                                NoMargin: true,
                                Type: Constant.ButtonType.Link,
                                Link: record.RulesUrl
                            }
                        ]} />
                    }
                </>

            },
            align: 'center',
            width: 80,
        },
        {
            title: t('sanctioning_applications.entry_form'),
            key: 'Id',
            render: (value, record) => {
                return <>
                    {
                        record.EntryFormUrl &&
                        <ActionButtonsCell data={record} btnList={[
                            {
                                Description: t("common.view"),
                                Icon: <EyeIcon />,
                                ColorClass: Constant.ColorClass.LightBlue,
                                Hide: false,
                                NoMargin: true,
                                Type: Constant.ButtonType.Link,
                                Link: record.EntryFormUrl
                            }
                        ]} />
                    }
                </>

            },
            align: 'center',
            width: 80,
        },
        {
            title: t('sanctioning_applications.approval_status'),
            dataIndex: 'StatusId',
            key: 'StatusId',
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={Constant.ColorClass.LightGreen}
                    description={t("sanctioning_applications.date_approved")} />
            ),
            sorter: false,
            align: 'center',
            width: 150
        },
        {
            title: t('common.status'),
            dataIndex: 'StatusId',
            key: 'StatusId',
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.StatusId)}
                    description={CommonService.getStatusDescription(record.StatusId)} />
            ),
            sorter: true,
            align: 'center',
            width: 90
        }
    ]

    const showFilterLayout = () => {
        setVisibleFilter(true)
    }

    const closeFilterLayout = () => {
        formFilter.setFieldsValue(sanctioningFilter);
        setVisibleFilter(false);
    }

    const resetFilter = () => {
        formFilter.resetFields();
        setSanctioningFilter({ StatusIds: Constant.StatusCode.Active });
    }

    const applyFilter = () => {
        setVisibleFilter(false);
        let filter = formFilter.getFieldsValue();
        setSanctioningFilter(filter)
        setGridConfigOptionsValue({
            ...gridConfigOptions,
            pageNumber: 1
        })
        getTournamentSanctioningData(1, gridConfigOptions.pageSize, filter, gridConfigOptions.sort)
    }

    const onTableChange = (pagination, filters, sorter, extra) => {
        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = defaultGridOption.sort;
        }

        getTournamentSanctioningData(pagination.current, pagination.pageSize, sanctioningFilter, sort)
        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        })
    }

    const getTournamentSanctioningData = (pageNumber, pageSize, filter, sort) => {
        showLoading()
        if (!filter) {
            filter = { ...sanctioningFilter }
        }

        filter.IsApplyForTournamentSanctioning = true

        TournamentService.getTournaments(pageNumber, pageSize, sort, filter)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    setSanctioningData(result.data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    const closeNoteModal = () => {
        setVisibleNote(false)
        setSelectedObject({})
    }

    const closeSanctioningApplicationModal = (isReload) => {
        setVisibleSanctioningApplication(false)
        if (isReload) {
            getTournamentSanctioningData(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, sanctioningFilter, gridConfigOptions.sort)
        }
    }

    return (
        <>
            {currentPosition && currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee &&
                <div>
                    <div id={Constant.FixItemsContainerId}>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                            <Title className='color-dark-blue' level={5}>{t("sanctioning_applications.apply_for_tournament_sanctioning")}</Title>
                        </Row>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                            <Col flex={100} className="text-right">
                                <div>
                                    <Button onClick={showFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon />}>{t("common.filter")}</Button>

                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Table
                                {...GetTableConfigs(columns, sanctioningData?.ObjectList, sanctioningData?.TotalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                            />
                        </Col>
                    </Row>
                </div>
            }

            <Drawer
                title={t("common.filter")}
                width={378}
                onClose={closeFilterLayout}
                open={visibleFilter}
                closeIcon={false}
                styles={{
                    body: {
                        paddingBottom: 80,
                    }
                }}
                extra={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                        <Button onClick={closeFilterLayout} icon={<CloseIcon />} type="link" />
                    </Space>
                }
                footer={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button onClick={closeFilterLayout}>{t("common.cancel")}</Button>
                        <Button onClick={applyFilter} type="primary">
                            {t("common.apply")}
                        </Button>
                    </Space>
                }>
                <Form
                    layout="vertical"
                    {...layout}
                    form={formFilter}
                    name="formFilter"
                    key='formFilter'
                    initialValues={sanctioningFilter}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="Title"
                                label={t("sanctioning_applications.title")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="StartDate"
                                label={t("sanctioning_applications.start_date")}
                            >
                                <DatePicker format={Constant.DateFormat} allowClear style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="FinishDate"
                                label={t("sanctioning_applications.finish_date")}
                            >
                                <DatePicker format={Constant.DateFormat} allowClear style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="StatusIds"
                                label={t("common.status")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={statusCodes ? statusCodes.map(b => {
                                            return {
                                                label: b.Description,
                                                value: b.Value
                                            }
                                        }) : []}>
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>

            {
                visibleNote &&
                <Modal
                    title={t("sanctioning_applications.tournament_approval_notes")}
                    open={true}
                    onCancel={closeNoteModal}
                    confirmLoading={false}
                    maskClosable={false}
                    centered
                    width={850}
                    footer={null}
                >
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }} className={`${!selectedObject || !selectedObject.ApprovalNotes ? "text-center" : ""}`}>
                            <div>{selectedObject && selectedObject.ApprovalNotes ? selectedObject.ApprovalNotes : t("sanctioning_applications.no_notes_entered")}</div>
                        </Col>
                    </Row>
                </Modal>
            }

            {
                visibleSanctioningApplication &&
                <TournamentSanctioningApplication handleCancel={closeSanctioningApplicationModal} visible={visibleSanctioningApplication} selectedObject={selectedObject}></TournamentSanctioningApplication>
            }
            {currentPosition && currentPosition.PositionTypeId != Constant.PositionType.ClubCommittee &&
                <>
                    <Table
                        style={{ visibility: 'hidden', height: '0px' }}
                        {...GetTableConfigs(columns, [], 0, "Id", null, gridConfigOptions.pageNumber)}
                    />
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_access")} />
                </>
            }
        </>

    )

}

export default SanctioningApplications;
import { createGlobalState } from 'react-hooks-global-state';

export const { useGlobalState, setGlobalState } = createGlobalState({
    sample: [],
    userPositions: [],
    currentPosition: {},
    userMenus: [],
    currentMenu: {},
    appMenus: [],
    breadcrumbItems: []
});

export const resetGlobalState = () => {
    setGlobalState("sample", [])
    setGlobalState("userPositions", [])
    setGlobalState("currentPosition", {})
    setGlobalState("breadcrumbItems", [])
};
import React, { useEffect, useState } from 'react';
import './tagCardFormView.scss';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
    Constant,
    StorageService,
    FishingActivityService
} from '../../services/services'
import { Table, Button, Row, Col, Form, Descriptions, Checkbox, Input, InputNumber, Modal, Card, Select } from 'antd';
import { GetTableConfigs, EditableCell, ActionButtonsCell } from '../customTable/customTable'
import LoadingContext from '../loading/loadingContext'
import CustomInputMask from '../CustomerInputMask/CustomInputMask';
import TagNumber from '../tagNumber/tagNumber';
import CustomTimePicker from '../../components/customTimePicker/customTimePicker';
import EnhancedSelect from '../enhancedSelect/enhancedSelect';
import { EyeIcon } from '../icons/icons'
import { useGlobalState } from '../../utilities/globalState'

const _ = require("lodash");

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const components = {
    body: {
        cell: EditableCell,
    },
};

const TagCardFormViewItem = (props) => {
    const { t } = useTranslation();
    const [cardViewForm] = Form.useForm();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext);

    const [disabledStateView, setDisabledStateView] = useState(false);
    const [isClubMember, setIsClubMember] = useState(false);
    const [activityLines, setActivityLines] = useState(false);
    const [currentPosition] = useGlobalState('currentPosition');

    const deleteActivityLine = (record) => {
        props.deleteActivityLine(record);
    }

    const showPointScoreModal = (record) => {
        const items = [
            {
                key: '1',
                label: t('fishing_activity.capture_points'),
                children: props.activityLine.StateCapturePoints ?? 0,
            },
            {
                key: '2',
                label: t('fishing_activity.tag_release_points'),
                children: props.activityLine.StateTagReleasePoints ?? 0,
            },
            {
                key: '3',
                label: t('fishing_activity.measure_release_points'),
                children: props.activityLine.StateMeasureReleasePoints ?? 0,
            },
            {
                key: '4',
                label: t('fishing_activity.lead_release_points'),
                children: props.activityLine.StateLeadReleasePoints ?? 0,
            },
            {
                key: '5',
                label: t('fishing_activity.total_points'),
                children: (props.activityLine.StateCapturePoints ?? 0) + (props.activityLine.StateTagReleasePoints ?? 0) + (props.activityLine.StateMeasureReleasePoints ?? 0) + (props.activityLine.StateLeadReleasePoints ?? 0),
            }
        ];

        Modal.info({
            title: t('common.point_score'),
            width: 520,
            icon: null,
            content: (
                <div>
                    <Descriptions title="" layout="horizontal" column={1} bordered items={items} />
                </div>
            ),
            onOk() { },
        });
    }

    const handleOnChange = (value, record, dataIndex) => {
        if (record) {
            _.update(props.activityLine, dataIndex, function (n) {
                return value;
            });
        }
    }

    const handleTagCaptureOnChange = (value, record, dataIndex) => {
        if (record) {
            _.update(props.activityLine, dataIndex, function (n) {
                return value;
            });

            handlePointScores(record);
        }
    }

    const handleLocationOnChange = (value, record, dataIndex) => {
        if (record) {
            _.update(props.activityLine, dataIndex, function (n) {
                return value;
            });

            var location = props.fishingLocations.find(x => x.Id === value);
            if (location) {
                _.update(props.activityLine, 'GpsLatitude', function (n) {
                    return location.GpsLatitude;
                });

                _.update(props.activityLine, 'GpsLongitude', function (n) {
                    return location.GpsLongitude;
                });

                _.update(props.activityLine, 'IsInsideClubWaters', function (n) {
                    return location.IsInsideClubWaters ? 1 : 2;
                });

                _.update(props.activityLine, 'ClubZoneId', function (n) {
                    return location.ClubZoneId;
                });

                _.update(props.activityLine, 'StatePointScoreZoneId', function (n) {
                    return location.StatePointScoreZoneId;
                });

                cardViewForm.setFieldValue('GpsLatitude', location.GpsLatitude);
                cardViewForm.setFieldValue('GpsLongitude', location.GpsLongitude);
                cardViewForm.setFieldValue('IsInsideClubWaters', location.IsInsideClubWaters ? 1 : 2);
                cardViewForm.setFieldValue('ClubZoneId', location.ClubZoneId);
                cardViewForm.setFieldValue('StatePointScoreZoneId', location.StatePointScoreZoneId);

                handlePointScores(record);
            }
        }
    }

    const handleFishSpeciesOnChange = (value, record, dataIndex) => {
        if (record) {
            _.update(props.activityLine, dataIndex, function (n) {
                return value;
            });

            var isSaltwater = 1;
            var fishSpecy = props.fishSpecies.find(x => x.Id === value);
            if (fishSpecy) {
                isSaltwater = fishSpecy.IsSaltwater ? 1 : 2;
            }

            if (isSaltwater != 1 || isSaltwater != 2) {
                isSaltwater = 1;
            }

            _.update(props.activityLine, 'IsSaltwater', function (n) {
                return isSaltwater;
            });
            cardViewForm.setFieldValue('IsSaltwater', isSaltwater);

            handlePointScores(record);
        }
    }

    const handleFishWeightOnChange = (value, record, dataIndex) => {
        if (record) {
            _.update(props.activityLine, dataIndex, function (n) {
                return value;
            });

            handlePointScores(record);
        }
    }

    const handleLineClassOnChange = (value, record, dataIndex) => {
        if (record) {
            _.update(props.activityLine, dataIndex, function (n) {
                return value;
            });

            handlePointScores(record);
        }
    }

    const handleAnglerOnChange = (value, record, dataIndex) => {
        if (record) {
            _.update(props.activityLine, dataIndex, function (n) {
                return value;
            });

            var angler = props.anglers.find(x => x.Id === value);
            if (angler) {
                var fishingCategory = props.fishingCategories.find(x => x.Id === angler.FishingCategoryId);
                if (fishingCategory) {
                    _.update(props.activityLine, 'FishingCategoryId', function (n) {
                        return fishingCategory.Id;
                    });

                    cardViewForm.setFieldValue('FishingCategoryId', fishingCategory.Id);
                }
            } else {
                _.update(props.activityLine, 'FishingCategoryId', function (n) {
                    return null;
                });

                cardViewForm.setFieldValue('FishingCategoryId', null);
            }

            handlePointScores(record);
        }
    }

    const handleZoneOnChange = (value, record, dataIndex) => {
        if (record) {
            _.update(props.activityLine, dataIndex, function (n) {
                return value;
            });

            handlePointScores(record);
        }
    }

    const handleTableOnChange = (value, record, dataIndex) => {
        if (record) {
            var index = activityLines.findIndex(x => x.Id === record.Id);
            _.update(activityLines[index], dataIndex, function (n) {
                return value;
            });

            _.update(props.activityLine, dataIndex, function (n) {
                return value;
            });

            if (dataIndex === 'DateEntered') {
                handlePointScores(record);
            }
        }
    }

    const handlePointScores = (record) => {

        var activityDate = "";
        if (props.activityHeader && props.activityHeader.ActivityDate) {
            if (typeof props.activityHeader.ActivityDate === 'string' || props.activityHeader.ActivityDate instanceof String) {
                var length = props.activityHeader.ActivityDate.split('/').length;
                if (length >= 3) {
                    activityDate = dayjs(props.activityHeader.ActivityDate, Constant.DateFormatYYYMMDD);
                }
            } else {
                activityDate = props.activityHeader.ActivityDate.format(Constant.DateFormatYYYMMDD);
            }
        }

        var dateEntered = "";
        if (record.DateEntered) {
            if (typeof record.DateEntered === 'string' || record.DateEntered instanceof String) {
                var length = record.DateEntered.split('/').length;
                if (length >= 3) {
                    dateEntered = dayjs(record.DateEntered, Constant.DateFormatYYYMMDD);
                }
            } else {
                dateEntered = record.DateEntered.format(Constant.DateFormatYYYMMDD);
            }
        }

        if (record.StateApprovalId === Constant.TagApprovalStatus.Approved || record.StateApprovalId === Constant.TagApprovalStatus.Disqualified) {
            return;
        }

        FishingActivityService.getPointScores(activityDate, record.AnglerId, record.LineClassId, record.ClubZoneId, record.StatePointScoreZoneId, record.FishSpeciesId, record.TagCaptureId, record.FishWeight, dateEntered)
            .then(result => {
                if (result.data) {
                    setActivityLines(current =>
                        current.map(obj => {
                            if (obj.Id === record.Id) {
                                return {
                                    ...obj,
                                    StatePoints: result.data.StatePoints,
                                };
                            }
                            return obj;
                        }),
                    );

                    _.update(props.activityLine, 'ClubCapturePoints', function (n) {
                        return result.data.ClubCapturePoints;
                    });

                    _.update(props.activityLine, 'ClubTagReleasePoints', function (n) {
                        return result.data.ClubTagReleasePoints;
                    });

                    _.update(props.activityLine, 'ClubMeasureReleasePoints', function (n) {
                        return result.data.ClubMeasureReleasePoints;
                    });

                    _.update(props.activityLine, 'ClubLeadReleasePoints', function (n) {
                        return result.data.ClubLeadReleasePoints;
                    });

                    _.update(props.activityLine, 'StatePoints', function (n) {
                        return result.data.StatePoints;
                    });

                    _.update(props.activityLine, 'StateCapturePoints', function (n) {
                        return result.data.StateCapturePoints;
                    });

                    _.update(props.activityLine, 'StateTagReleasePoints', function (n) {
                        return result.data.StateTagReleasePoints;
                    });

                    _.update(props.activityLine, 'StateMeasureReleasePoints', function (n) {
                        return result.data.StateMeasureReleasePoints;
                    });

                    _.update(props.activityLine, 'StateLeadReleasePoints', function (n) {
                        return result.data.StateLeadReleasePoints;
                    });
                }
            });
    }

    const disableEditDayLog = () => {
        if (props.isViewOnly)
            return true
        if (currentPosition && props.activityLine) {
            if (currentPosition.PositionTypeId == Constant.PositionType.ClubMember &&
                (props.activityLine.ClubApprovalId || props.activityLine.StateApprovalId))
                return true
        }
        return false
    }

    var mainColumns = [
        {
            title: t('fishing_activity.state_points'),
            dataIndex: 'StatePoints',
            key: 'StatePoints',
            editable: true,
            required: false,
            disabled: disabledStateView,
            cellType: Constant.CellType.DecimalInput,
            precision: 1,
            align: 'center',
            width: 90,
            handleOnChange: handleTableOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: t("common.point_score"),
            render: (value, record) => {
                return <ActionButtonsCell data={record} btnList={[
                    {
                        Description: t("common.point_score"),
                        Icon: <EyeIcon />,
                        ColorClass: Constant.ColorClass.LightBlue,
                        Hide: false,
                        NoMargin: true,
                        handleAction: showPointScoreModal
                    }
                ]} />
            },
            align: 'center',
            width: 62,
        },
        {
            title: t('fishing_activity.state_approval'),
            dataIndex: 'StateApprovalId',
            key: 'StateApprovalId',
            editable: true,
            required: false,
            disabled: disabledStateView,
            extraData: props.tagApprovals,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "Name",
            width: 95,
            handleOnChange: handleTableOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: t('fishing_activity.non_mem'),
            dataIndex: 'NonMember',
            key: 'NonMember',
            editable: true,
            required: false,
            disabled: disabledStateView,
            cellType: Constant.CellType.Checkbox,
            align: 'center',
            width: 58,
            handleOnChange: handleTableOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: t('fishing_activity.drop_fish'),
            dataIndex: 'IsDropFish',
            key: 'IsDropFish',
            editable: true,
            required: false,
            disabled: disabledStateView,
            cellType: Constant.CellType.Checkbox,
            align: 'center',
            width: 57,
            handleOnChange: handleTableOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: t('fishing_activity.too_late'),
            dataIndex: 'IsTooLate',
            key: 'IsTooLate',
            editable: true,
            required: false,
            disabled: disabledStateView,
            cellType: Constant.CellType.Checkbox,
            align: 'center',
            width: 52,
            handleOnChange: handleTableOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: t('fishing_activity.no_tag_card'),
            dataIndex: 'IsNoTagCard',
            key: 'IsNoTagCard',
            editable: true,
            required: false,
            disabled: disabledStateView,
            cellType: Constant.CellType.Checkbox,
            align: 'center',
            width: 55,
            handleOnChange: handleTableOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: t('fishing_activity.tackl'),
            dataIndex: 'IsTackle',
            key: 'IsTackle',
            editable: true,
            required: false,
            disabled: disabledStateView,
            cellType: Constant.CellType.Checkbox,
            align: 'center',
            width: 63,
            handleOnChange: handleTableOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: t('fishing_activity.fish_too_small'),
            dataIndex: 'IsFishTooSmall',
            key: 'IsFishTooSmall',
            editable: true,
            required: false,
            disabled: disabledStateView,
            cellType: Constant.CellType.Checkbox,
            align: 'center',
            width: 63,
            handleOnChange: handleTableOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: t('fishing_activity.oob'),
            dataIndex: 'IsOob',
            key: 'IsOob',
            editable: true,
            required: false,
            disabled: disabledStateView,
            cellType: Constant.CellType.Checkbox,
            align: 'center',
            width: 57,
            handleOnChange: handleTableOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: t('fishing_activity.other'),
            dataIndex: 'IsOther',
            key: 'IsOther',
            editable: true,
            required: false,
            disabled: disabledStateView,
            cellType: Constant.CellType.Checkbox,
            align: 'center',
            width: 63,
            handleOnChange: handleTableOnChange,
            handleDisabledCell: disableEditDayLog
        },
        {
            title: <span className=''>{t('fishing_activity.date_entered')}</span>,
            dataIndex: 'DateEntered',
            key: 'DateEntered',
            editable: true,
            required: false,
            disabled: disabledStateView,
            cellType: Constant.CellType.Date,
            width: 150,
            handleOnChange: handleTableOnChange,
            handleDisabledCell: disableEditDayLog
        }
    ]

    const tableViewMainColumns = mainColumns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                disabled: col.disabled,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition,
                selectionKey: col.selectionKey,
                selectionDisplay: col.selectionDisplay,
                validateAtStart: col.validateAtStart,
                precision: col.precision,
                handleDisabledCell: col.handleDisabledCell
            }),
        };
    })

    const tagCards = [
        {
            key: '1',
            label: '#',
            children: props.activityLine.RecordId ? props.activityLine.RecordId : '',
        },
        {
            key: '2',
            label: t("fishing_activity.tag_card"),
            children: props.activityLine.TagNumber ? props.activityLine.TagNumber : '_______',
        }
    ];

    const getFishingMethodWidth = () => {
        if (document.documentElement.clientWidth >= 1700) {
            return "250px";
        } else if (document.documentElement.clientWidth >= 1600 && document.documentElement.clientWidth < 1700) {
            return "200px";
        } else if (document.documentElement.clientWidth >= 1500 && document.documentElement.clientWidth < 1600) {
            return "180px";
        } else if (document.documentElement.clientWidth >= 1400 && document.documentElement.clientWidth < 1500) {
            return "170px";
        } else {
            return "120px";
        }
    }

    const getFishSpeciesWidth = () => {
        if (document.documentElement.clientWidth >= 1700) {
            return "350px";
        } else if (document.documentElement.clientWidth >= 1600 && document.documentElement.clientWidth < 1700) {
            return "210px";
        } else if (document.documentElement.clientWidth >= 1500 && document.documentElement.clientWidth < 1600) {
            return "200px";
        } else if (document.documentElement.clientWidth >= 1400 && document.documentElement.clientWidth < 1500) {
            return "170px";
        } else {
            return "140px";
        }
    }

    const getFishingLocationWidth = () => {
        if (document.documentElement.clientWidth >= 1700) {
            return "370px";
        } else if (document.documentElement.clientWidth >= 1600 && document.documentElement.clientWidth < 1700) {
            return "320px";
        } else {
            return "210px";
        }
    }

    const getClubZoneWidth = () => {
        if (document.documentElement.clientWidth >= 1700) {
            return "220px";
        } else if (document.documentElement.clientWidth >= 1600 && document.documentElement.clientWidth < 1700) {
            return "200px";
        } else {
            return "150px";
        }
    }

    const getStatePointScoreZoneWidth = () => {
        if (document.documentElement.clientWidth >= 1700) {
            return "220px";
        } else if (document.documentElement.clientWidth >= 1600 && document.documentElement.clientWidth < 1700) {
            return "180px";
        } else {
            return "150px";
        }
    }

    const getIsMeasureWidth = () => {
        if (document.documentElement.clientWidth >= 1700) {
            return "120px";
        } else if (document.documentElement.clientWidth >= 1600 && document.documentElement.clientWidth < 1700) {
            return "120px";
        } else {
            return "90px";
        }
    }

    const getIsCircleHookWidth = () => {
        if (document.documentElement.clientWidth >= 1700) {
            return "150px";
        } else if (document.documentElement.clientWidth >= 1600 && document.documentElement.clientWidth < 1700) {
            return "150px";
        } else {
            return "130px";
        }
    }

    const getIsFirstBillfishTrWidth = () => {
        if (document.documentElement.clientWidth >= 1700) {
            return "180px";
        } else if (document.documentElement.clientWidth >= 1600 && document.documentElement.clientWidth < 1700) {
            return "180px";
        } else {
            return "150px";
        }
    }

    const getIsRecaptureWidth = () => {
        if (document.documentElement.clientWidth >= 1700) {
            return "150px";
        } else if (document.documentElement.clientWidth >= 1600 && document.documentElement.clientWidth < 1700) {
            return "150px";
        } else {
            return "80px";
        }
    }

    useEffect(() => {
        cardViewForm.setFieldsValue(props.activityLine);
        cardViewForm.setFieldValue('FishWeight', props.activityLine.FishWeight);
        cardViewForm.setFieldValue('IsWeightMeasure', props.activityLine.IsWeightMeasure);
        cardViewForm.setFieldValue('LineClassId', props.activityLine.LineClassId);
        cardViewForm.setFieldValue('IsLjflFl', props.activityLine.IsLjflFl);
        cardViewForm.setFieldValue('IsMeasure', props.activityLine.IsMeasure);
        cardViewForm.setFieldValue('IsCircleHook', props.activityLine.IsCircleHook);
        cardViewForm.setFieldValue('IsNonOffset', props.activityLine.IsNonOffset);
        cardViewForm.setFieldValue('IsFirstBillfishTr', props.activityLine.IsFirstBillfishTr);
        cardViewForm.setFieldValue('IsRecapture', props.activityLine.IsRecapture);
        cardViewForm.setFieldValue('ClubPoints', props.activityLine.ClubPoints);
        cardViewForm.setFieldValue('ClubApprovalId', props.activityLine.ClubApprovalId);
        cardViewForm.setFieldValue('IsFlyfishing', props.activityLine.IsFlyfishing);
        cardViewForm.setFieldValue('IsSaltwater', props.activityLine.IsSaltwater);
        cardViewForm.setFieldValue('IsInsideClubWaters', props.activityLine.IsInsideClubWaters);
        cardViewForm.setFieldValue('ClubZoneId', props.activityLine.ClubZoneId);
        cardViewForm.setFieldValue('StatePointScoreZoneId', props.activityLine.StatePointScoreZoneId);
        cardViewForm.setFieldValue('ClubEventId', props.activityLine.ClubEventId);
        cardViewForm.setFieldValue('StateEventId', props.activityLine.StateEventId);
        setActivityLines([props.activityLine]);
        return () => {
        }
    }, [props.activityLine])

    useEffect(() => {
        setDisabledStateView(props.disabledStateView);
        return () => {
        }
    }, [props.disabledStateView])

    useEffect(() => {
        if (typeof (currentPosition.PositionTypeId) === 'undefined') {
            currentPosition = StorageService.getCurrentPosition();
        }
        
        if (typeof (currentPosition.PositionTypeId) === 'undefined' || currentPosition.PositionTypeId === Constant.PositionType.ClubMember) {
            setIsClubMember(true);
        } else {
            setIsClubMember(false);
        }
        
        return () => {
        }
    }, [])

    return (

        <Form
            {...layout}
            name={'cardViewForm' + props.activityLine.Id}
            key={'cardViewForm' + props.activityLine.Id}
            form={cardViewForm}
            initialValues={props.activityLine}>
            <Card style={{ width: "100%" }} className='tag-card-form-view'>
                <Card.Grid style={{ width: "50%" }} hoverable={false} className='tag-card-form-header-view'>
                    <Row gutter={24} className="text-left extra-btn-m-t">
                        <Col span={14}>
                            <Descriptions title="" items={tagCards} />
                        </Col>
                        {
                            !disableEditDayLog() &&
                            <Col span={10} className="text-right extra-btn-m-t">
                                <Button size="small" type="text" className='ant-delete-tag' onClick={() => deleteActivityLine(props.activityLine)}>{t("common.delete_record")}</Button>
                            </Col>
                        }

                    </Row>
                </Card.Grid>
                <Card.Grid style={{ width: "100%" }} hoverable={false} className='tag-card-form-body-view'>
                    <Row gutter={24} wrap={false}>
                        <Col flex="120px" style={{ paddingRight: '0px' }}>
                            <Form.Item
                                label={t("fishing_activity.tag_capt")}
                                rules={[{ required: true, message: t("fishing_activity.tag_capt_is_required") }]}
                                name="TagCaptureId" colon={false} labelAlign="left">
                                <EnhancedSelect style={{ width: '100%' }} showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                    filterSort={(optionA, optionB) => (optionA?.value == optionB?.value)}
                                    options={props.tagCaptures ? props.tagCaptures.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                    onChange={(e) => handleTagCaptureOnChange(e, props.activityLine, 'TagCaptureId')}
                                    disabled={disableEditDayLog()}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                        <Col flex={getFishingMethodWidth()} style={{ paddingRight: '0px' }}>
                            <Form.Item
                                label={t("fishing_activity.method")}
                                rules={[{ required: true, message: t("fishing_activity.method_is_required") }]}
                                name="FishingMethodId" colon={false} labelAlign="left">
                                <EnhancedSelect style={{ width: '100%' }} showSearch optionLabelProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    onChange={(e) => handleOnChange(e, props.activityLine, 'FishingMethodId')}
                                    disabled={disableEditDayLog()}
                                >
                                    {props.fishingMethods ? props.fishingMethods.map(b => {
                                        return (<>
                                            <Option value={b.Id} label={b.DisplayName}>
                                                {b.DisplayName}
                                            </Option>
                                        </>);
                                    }) : []}
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                        <Col flex="100px" style={{ paddingRight: '0px' }}>
                            <Form.Item
                                label={<div><span>{t("fishing_activity.time")}</span> <br /> <span className='ant-form-item-extra'>{t("fishing_activity.time_placeholder")}</span></div>}
                                rules={[{ required: true, message: t("fishing_activity.time_is_required") }]}
                                name="CaughtTime" colon={false} labelAlign="left">
                                <CustomTimePicker onChange={(e) => handleOnChange(e.target.value, props.activityLine, 'CaughtTime')} format={Constant.TimeFormat} placeholder=''
                                    disabled={disableEditDayLog()} />
                            </Form.Item>
                        </Col>
                        <Col flex={getFishSpeciesWidth()} style={{ paddingRight: '0px' }}>
                            <Form.Item
                                label={t("fishing_activity.species")}
                                rules={[{ required: true, message: t("fishing_activity.species_is_required") }]}
                                name="FishSpeciesId" colon={false} labelAlign="left">
                                <EnhancedSelect style={{ width: '100%' }} showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={props.fishSpecies ? props.fishSpecies.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                    onChange={(e) => handleFishSpeciesOnChange(e, props.activityLine, 'FishSpeciesId')}
                                    disabled={disableEditDayLog()}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                        <Col flex="115px" style={{ paddingRight: '0px' }}>
                            <Form.Item
                                label={t("fishing_activity.tag_number")}
                                rules={[{ required: props.activityLine && props.activityLine.TagCaptureId === Constant.TagCaptureCode.T_R, message: t("fishing_activity.tag_number_is_required") }]}
                                name="TagNumber" colon={false} labelAlign="left">
                                <TagNumber onChange={(e) => handleOnChange(e.target.value, props.activityLine, 'TagNumber')} mask={"a999999"} defaultValue="_______"
                                    disabled={disableEditDayLog()} />
                            </Form.Item>
                        </Col>
                        <Col flex="125px" style={{ paddingRight: '0px' }}>
                            <Form.Item
                                label={t("fishing_activity.weight")}
                                rules={[{ required: false, message: t("fishing_activity.weight_is_required") }]}
                                name="FishWeight" colon={false} labelAlign="left">
                                <InputNumber onChange={(e) => handleFishWeightOnChange(e, props.activityLine, 'FishWeight')} precision={2} min={0}
                                    disabled={disableEditDayLog()} />
                            </Form.Item>
                        </Col>
                        <Col flex="60px" align="middle" style={{ paddingRight: '0px' }}>
                            <Form.Item
                                label={t("fishing_activity.weight_measure")}
                                name="IsWeightMeasure"
                                valuePropName="checked"
                                className='custom-checkbox'>
                                <Checkbox onChange={(e) => handleOnChange(e.target.checked, props.activityLine, 'IsWeightMeasure')}
                                    disabled={disableEditDayLog()} />
                            </Form.Item>
                        </Col>
                        <Col flex="120px" style={{ paddingRight: '0px' }}>
                            <Form.Item
                                label={t("fishing_activity.line_class")}
                                rules={[{ required: true, message: t("fishing_activity.line_class_is_required") }]}
                                name="LineClassId" colon={false} labelAlign="left">
                                <EnhancedSelect style={{ width: '100%' }} showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                    filterSort={(optionA, optionB) => (optionA?.value == optionB?.value)}
                                    options={props.lineClasses ? props.lineClasses.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                    onChange={(e) => handleLineClassOnChange(e, props.activityLine, 'LineClassId')}
                                    disabled={disableEditDayLog()}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                        <Col flex="auto" style={{ minWidth: '170px' }}>
                            <Form.Item
                                label={t("fishing_activity.angler")}
                                rules={[{ required: true, message: t("fishing_activity.angler_is_required") }]}
                                name="AnglerId" colon={false} labelAlign="left">
                                <EnhancedSelect style={{ width: '100%' }} showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={props.anglerOptions}
                                    onChange={(e) => handleAnglerOnChange(e, props.activityLine, 'AnglerId')}
                                    disabled={disableEditDayLog()}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24} wrap={false}>
                        <Col flex={getFishingLocationWidth()} style={{ paddingRight: '0px' }}>
                            <Form.Item
                                label={t("fishing_activity.location")}
                                rules={[{ required: true, message: t("fishing_activity.location_is_required") }]}
                                name="FishingLocationId" colon={false} labelAlign="left">
                                <EnhancedSelect style={{ width: '100%' }} showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={props.fishingLocations ? props.fishingLocations.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                    onChange={(e) => handleLocationOnChange(e, props.activityLine, 'FishingLocationId')}
                                    disabled={disableEditDayLog()}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                        <Col flex="180px" style={{ paddingRight: '0px' }}>
                            <Form.Item
                                label={<div>
                                    <span>{t('fishing_activity.gps_latitude')} </span>
                                    <br />
                                    <span className='ant-form-item-extra'>dd</span>
                                    <span style={{ color: '#C71720' }}>*</span>
                                    <span>o</span>
                                    <span className='ant-form-item-extra'> mm</span>
                                    <span style={{ color: '#C71720' }}>*</span>
                                    <span className='ant-form-item-extra'>.mmm</span>
                                    <span>'</span>
                                </div>}
                                rules={[{ required: true, message: t("fishing_activity.gps_latitude_is_required") }]}
                                name="GpsLatitude" colon={false} labelAlign="left">
                                <CustomInputMask onChange={(e) => handleOnChange(e.target.value, props.activityLine, 'GpsLatitude')} defaultValue="__°__.000'" mask={"99°99.999'"}
                                    disabled={disableEditDayLog()} />
                            </Form.Item>
                        </Col>
                        <Col flex="180px" style={{ paddingRight: '0px' }}>
                            <Form.Item
                                label={<div>
                                    <span>{t('fishing_activity.gps_longitude')} </span>
                                    <br />
                                    <span className='ant-form-item-extra'>ddd</span>
                                    <span style={{ color: '#C71720' }}>*</span>
                                    <span>o</span>
                                    <span className='ant-form-item-extra'> mm</span>
                                    <span style={{ color: '#C71720' }}>*</span>
                                    <span className='ant-form-item-extra'>.mmm</span>
                                    <span>'</span>
                                </div>}
                                rules={[{ required: true, message: t("fishing_activity.gps_longitude_is_required") }]}
                                name="GpsLongitude" colon={false} labelAlign="left">
                                <CustomInputMask onChange={(e) => handleOnChange(e.target.value, props.activityLine, 'GpsLongitude')} defaultValue="___°__.000'" mask={"999°99.999'"}
                                    disabled={disableEditDayLog()} />
                            </Form.Item>
                        </Col>
                        <Col flex="auto" style={{ minWidth: '155px', paddingRight: '0px' }}>
                            <Form.Item
                                label={t("fishing_activity.club_comments")}
                                rules={[{ required: false, message: t("fishing_activity.club_comments_is_required") }]}
                                name="Notes" colon={false} labelAlign="left">
                                <Input onChange={(e) => handleOnChange(e.target.value, props.activityLine, 'Notes')} allowClear
                                    disabled={disableEditDayLog()} />
                            </Form.Item>
                        </Col>
                        <Col flex="160px">
                            <Form.Item
                                label={t("fishing_activity.fishing_category")}
                                rules={[{ required: false, message: t("fishing_activity.fishing_category_is_required") }]}
                                name="FishingCategoryId" colon={false} labelAlign="left">
                                <EnhancedSelect style={{ width: '100%' }} showSearch optionFilterProp="label" allowClear={true} disabled={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={props.fishingCategories ? props.fishingCategories.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                    onChange={(e) => handleOnChange(e, props.activityLine, 'FishingCategoryId')}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24} wrap={false}>
                        <Col flex="120px" style={{ paddingRight: '0px' }}>
                            <Form.Item
                                label={t("fishing_activity.length")}
                                rules={[{ required: false, message: t("fishing_activity.length_is_required") }]}
                                name="FishLength" colon={false} labelAlign="left">
                                <InputNumber onChange={(e) => handleOnChange(e, props.activityLine, 'FishLength')} precision={0} min={0}
                                    disabled={disableEditDayLog()} />
                            </Form.Item>
                        </Col>
                        <Col flex="80px" align="middle" style={{ paddingRight: '0px' }}>
                            <Form.Item
                                label={t("fishing_activity.ljfl_fl")}
                                name="IsLjflFl"
                                valuePropName="checked"
                                className='custom-checkbox'>
                                <Checkbox onChange={(e) => handleOnChange(e.target.checked, props.activityLine, 'IsLjflFl')}
                                    disabled={disableEditDayLog()} />
                            </Form.Item>
                        </Col>
                        <Col flex={getIsMeasureWidth()} align="middle" style={{ paddingRight: '0px' }}>
                            <Form.Item
                                label={t("fishing_activity.meas")}
                                name="IsMeasure"
                                valuePropName="checked"
                                className='custom-checkbox'>
                                <Checkbox onChange={(e) => handleOnChange(e.target.checked, props.activityLine, 'IsMeasure')}
                                    disabled={disableEditDayLog()} />
                            </Form.Item>
                        </Col>
                        <Col flex="120px" style={{ paddingRight: '0px' }}>
                            <Form.Item
                                label={<div><span>{t("fishing_activity.fight_time")}</span> <span className='ant-form-item-extra'>{t("fishing_activity.fight_time_placeholder")}</span></div>}
                                rules={[{ required: false, message: t("fishing_activity.fight_time_is_required") }]}
                                name="FightTime" colon={false} labelAlign="left">
                                <CustomTimePicker onChange={(e) => handleOnChange(e.target.value, props.activityLine, 'FightTime')} format={Constant.TimeFormat} placeholder=''
                                    disabled={disableEditDayLog()} />
                            </Form.Item>
                        </Col>
                        <Col flex={getIsCircleHookWidth()} align="middle" style={{ paddingRight: '0px' }}>
                            <Form.Item
                                label={t("fishing_activity.circle_hook")}
                                name="IsCircleHook"
                                valuePropName="checked"
                                className='custom-checkbox'>
                                <Checkbox onChange={(e) => handleOnChange(e.target.checked, props.activityLine, 'IsCircleHook')}
                                    disabled={disableEditDayLog()} />
                            </Form.Item>
                        </Col>
                        <Col flex="130px" align="middle" style={{ paddingRight: '0px' }}>
                            <Form.Item
                                label={t("fishing_activity.non_offset")}
                                name="IsNonOffset"
                                valuePropName="checked"
                                className='custom-checkbox'>
                                <Checkbox onChange={(e) => handleOnChange(e.target.checked, props.activityLine, 'IsNonOffset')}
                                    disabled={disableEditDayLog()} />
                            </Form.Item>
                        </Col>
                        <Col flex={getIsFirstBillfishTrWidth()} align="middle" style={{ paddingRight: '0px' }}>
                            <Form.Item
                                label={t("fishing_activity.first_billfish_tag_r")}
                                name="IsFirstBillfishTr"
                                valuePropName="checked"
                                className='custom-checkbox'>
                                <Checkbox onChange={(e) => handleOnChange(e.target.checked, props.activityLine, 'IsFirstBillfishTr')}
                                    disabled={disableEditDayLog()} />
                            </Form.Item>
                        </Col>
                        <Col flex={getIsRecaptureWidth()} align="middle" style={{ paddingRight: '0px' }}>
                            <Form.Item
                                label={t("fishing_activity.recapt")}
                                name="IsRecapture"
                                valuePropName="checked"
                                className='custom-checkbox'>
                                <Checkbox onChange={(e) => handleOnChange(e.target.checked, props.activityLine, 'IsRecapture')}
                                    disabled={disableEditDayLog()} />
                            </Form.Item>
                        </Col>
                        <Col flex="auto" style={{ minWidth: '150px' }}>
                            <Form.Item
                                label={t("fishing_activity.old_tag_number")}
                                rules={[{ required: false, message: t("fishing_activity.old_tag_number_is_required") }]}
                                name="OldTagNumber" colon={false} labelAlign="left">
                                <TagNumber onChange={(e) => handleOnChange(e.target.value, props.activityLine, 'OldTagNumber')} mask={"a999999"} defaultValue="_______"
                                    disabled={disableEditDayLog()} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24} wrap={false}>
                        <Col flex="110px" style={{ paddingRight: '0px' }}>
                            <Form.Item
                                label={t("fishing_activity.club_points")}
                                rules={[{ required: false, message: t("fishing_activity.club_points_is_required") }]}
                                name="ClubPoints" colon={false} labelAlign="left">
                                <InputNumber onChange={(e) => handleOnChange(e, props.activityLine, 'ClubPoints')} precision={1} min={0}
                                    disabled={disableEditDayLog()} />
                            </Form.Item>
                        </Col>
                        <Col flex="135px" style={{ paddingRight: '0px' }}>
                            <Form.Item
                                label={t("fishing_activity.club_approval")}
                                rules={[{ required: false, message: t("fishing_activity.club_approval_is_required") }]}
                                name="ClubApprovalId" colon={false} labelAlign="left">
                                <EnhancedSelect style={{ width: '100%' }} showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={props.tagApprovals ? props.tagApprovals.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                    onChange={(e) => handleOnChange(e, props.activityLine, 'ClubApprovalId')}
                                    disabled={isClubMember || disableEditDayLog()}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                        <Col flex="165px" style={{ paddingRight: '0px' }}>
                            <Form.Item
                                label={t("fishing_activity.inside_club_water")}
                                rules={[{ required: false, message: t("fishing_activity.inside_club_water_is_required") }]}
                                name="IsInsideClubWaters" colon={false} labelAlign="left">
                                <EnhancedSelect style={{ width: '100%' }} showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                    filterSort={(optionA, optionB) => (optionA?.value == optionB?.value)}
                                    options={props.yesNos ? props.yesNos.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                    onChange={(e) => handleOnChange(e, props.activityLine, 'IsInsideClubWaters')}
                                    disabled={disableEditDayLog()}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                        <Col flex={getClubZoneWidth()} style={{ paddingRight: '0px' }}>
                            <Form.Item
                                label={t("fishing_activity.club_zone")}
                                rules={[{ required: false, message: t("fishing_activity.club_zone_is_required") }]}
                                name="ClubZoneId" colon={false} labelAlign="left">
                                <EnhancedSelect style={{ width: '100%' }} showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={props.clubZones ? props.clubZones.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                    onChange={(e) => handleZoneOnChange(e, props.activityLine, 'ClubZoneId')}
                                    disabled={disableEditDayLog()}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                        <Col flex={getStatePointScoreZoneWidth()} style={{ paddingRight: '0px' }}>
                            <Form.Item
                                label={t("fishing_activity.state_zone")}
                                rules={[{ required: true, message: t("fishing_activity.state_zone_is_required") }]}
                                name="StatePointScoreZoneId" colon={false} labelAlign="left">
                                <EnhancedSelect style={{ width: '100%' }} showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={props.statePointScoreZones ? props.statePointScoreZones.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                    onChange={(e) => handleZoneOnChange(e, props.activityLine, 'StatePointScoreZoneId')}
                                    disabled={disableEditDayLog()}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                        <Col flex="auto" style={{ minWidth: '130px', paddingRight: '0px' }}>
                            <Form.Item
                                label={t("fishing_activity.state_event")}
                                rules={[{ required: false, message: t("fishing_activity.state_event_is_required") }]}
                                name="StateEventId" colon={false} labelAlign="left">
                                <EnhancedSelect style={{ width: '100%' }} showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                    filterSort={(optionA, optionB) => (optionA?.sort == optionB?.sort)}
                                    options={props.stateEvents ? props.stateEvents.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id,
                                            sort: b.SortOrder
                                        }
                                    }) : []}
                                    onChange={(e) => handleOnChange(e, props.activityLine, 'StateEventId')}
                                    disabled={disableEditDayLog()}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                        <Col flex="auto" style={{ minWidth: '130px' }}>
                            <Form.Item
                                label={t("fishing_activity.club_event")}
                                rules={[{ required: false, message: t("fishing_activity.club_event_is_required") }]}
                                name="ClubEventId" colon={false} labelAlign="left">
                                <EnhancedSelect style={{ width: '100%' }} showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                    filterSort={(optionA, optionB) => (optionA?.sort == optionB?.sort)}
                                    options={props.clubEvents ? props.clubEvents.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id,
                                            sort: b.SortOrder
                                        }
                                    }) : []}
                                    onChange={(e) => handleOnChange(e, props.activityLine, 'ClubEventId')}
                                    disabled={disableEditDayLog()}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24} wrap={false}>
                        <Col flex="230px">
                            <Row gutter={24} style={{ paddingRight: '0px' }}>
                                <Col span={12}>
                                    <Form.Item
                                        label={t("fishing_activity.flyfishing")}
                                        rules={[{ required: true, message: t("fishing_activity.flyfishing_is_required") }]}
                                        name="IsFlyfishing" colon={false} labelAlign="left">
                                        <EnhancedSelect style={{ width: '100%' }} showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                            filterSort={(optionA, optionB) => (optionA?.value == optionB?.value)}
                                            options={props.yesNos ? props.yesNos.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}
                                            onChange={(e) => handleOnChange(e, props.activityLine, 'IsFlyfishing')}
                                            disabled={disableEditDayLog()}
                                        >
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={t("fishing_activity.saltwater")}
                                        rules={[{ required: true, message: t("fishing_activity.saltwater_is_required") }]}
                                        name="IsSaltwater" colon={false} labelAlign="left">
                                        <EnhancedSelect style={{ width: '100%' }} showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                            filterSort={(optionA, optionB) => (optionA?.value == optionB?.value)}
                                            options={props.yesNos ? props.yesNos.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}
                                            onChange={(e) => handleOnChange(e, props.activityLine, 'IsSaltwater')}
                                            disabled={disableEditDayLog()}
                                        >
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        label={t("fishing_activity.state_comments")}
                                        rules={[{ required: false, message: t("fishing_activity.state_comments_is_required") }]}
                                        name="StateNotes" colon={false} labelAlign="left">
                                        <Input onChange={(e) => handleOnChange(e.target.value, props.activityLine, 'StateNotes')} allowClear
                                            disabled={disabledStateView || disableEditDayLog()} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="auto" style={{ minWidth: '500px' }}>
                            <Row gutter={24} justify="center">
                                <Table
                                    key={props.activityLine.Id}
                                    components={components}
                                    rowClassName={() => 'editable-row'}
                                    {...GetTableConfigs(tableViewMainColumns, activityLines, 1, "Id", null, null, 'tag-card-form-view-table', false)}
                                    rowKey="Id"
                                />
                            </Row>
                        </Col>
                    </Row>
                </Card.Grid>
            </Card>
        </Form>
    )
}

export default TagCardFormViewItem
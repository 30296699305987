import { ReactComponent as Plus } from './../../assets/icons/plus.svg';
import { ReactComponent as Lock } from './../../assets/icons/lock-fill.svg';
import { ReactComponent as User } from './../../assets/icons/user-fill.svg';
import { ReactComponent as Trash } from './../../assets/icons/trash.svg';
import { ReactComponent as Copy } from './../../assets/icons/copy.svg';
import { ReactComponent as ChevronRight } from './../../assets/icons/chevron-right.svg';
import { ReactComponent as ChevronLeft } from './../../assets/icons/chevron-left.svg';
import { ReactComponent as Eye } from './../../assets/icons/eye.svg';
import { ReactComponent as Edit } from './../../assets/icons/edit.svg';
import { ReactComponent as Close } from './../../assets/icons/close.svg';
import { ReactComponent as FilterAlt } from './../../assets/icons/filter-alt.svg';
import { ReactComponent as Communication } from './../../assets/icons/communication.svg';
import { ReactComponent as FishingActivity } from './../../assets/icons/fishing_activity.svg';
import { ReactComponent as Home } from './../../assets/icons/home.svg';
import { ReactComponent as Membership } from './../../assets/icons/membership.svg';
import { ReactComponent as Record } from './../../assets/icons/record.svg';
import { ReactComponent as Setting } from './../../assets/icons/setting.svg';
import { ReactComponent as Tournament } from './../../assets/icons/tournament.svg';
import { ReactComponent as Trophy } from './../../assets/icons/trophy.svg';
import { ReactComponent as ChevronUp } from './../../assets/icons/chevron-up.svg';
import { ReactComponent as ChevronDown } from './../../assets/icons/chevron-down.svg';

export const PlusIcon = () => {
    return (
        <Plus />
    );
}

export const LockIcon = () => {
    return (
        <Lock />
    );
}

export const UserIcon = () => {
    return (
        <User />
    )
}

export const TrashIcon = () => {
    return (
        <Trash />
    );
}

export const CopyIcon = () => {
    return (
        <Copy />
    );
}

export const ChevronRightIcon = () => {
    return (
        <ChevronRight />
    );
}

export const ChevronLeftIcon = () => {
    return (
        <ChevronLeft />
    );
}

export const EyeIcon = () => {
    return (
        <Eye />
    );
}

export const EditIcon = () => {
    return (
        <Edit />
    )

}
export const CloseIcon = () => {
    return (
        <Close />
    );
}

export const FilterAltIcon = () => {
    return (
        <FilterAlt />
    );
}

export const CommunicationIcon = () => {
    return (
        <Communication />
    );
}

export const FishingActivityIcon = () => {
    return (
        <FishingActivity />
    );
}

export const HomeIcon = () => {
    return (
        <Home />
    );
}

export const MembershipIcon = () => {
    return (
        <Membership />
    );
}

export const RecordIcon = () => {
    return (
        <Record />
    );
}

export const SettingIcon = () => {
    return (
        <Setting />
    );
}

export const TournamentIcon = () => {
    return (
        <Tournament />
    );
}

export const TrophyIcon = () => {
    return (
        <Trophy />
    );
}

export const ChevronUpIcon = () => {
    return (
        <ChevronUp />
    );
}

export const ChevronDownIcon = () => {
    return (
        <ChevronDown />
    );
}
import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Modal } from 'antd';
import { Constant, HomeService, CommonService } from '../../../services/services';
import { useTranslation } from 'react-i18next';
import { GetTableConfigs } from '../../../components/customTable/customTable'
import { useGlobalState } from '../../../utilities/globalState'
import LoadingContext from '../../../components/loading/loadingContext'

const defaultGridOption = {
    pageNumber: 1,
    pageSize: CommonService.getPageSize()
}

function HomeDetails(props) {
    const { t } = useTranslation();
    const [currentPosition] = useGlobalState('currentPosition')
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const emptyTableData = {
        ObjectList: [],
        TotalItems: 0
    }
    const [gridConfigOptions, setGridConfigOptionsValue] = useState(defaultGridOption)
    const [currentData, setCurrentData] = useState(emptyTableData)

    useEffect(() => {
        if (props.visible) {
            getData(gridConfigOptions.pageNumber, gridConfigOptions.pageSize)
        }
    }, [props.visible])

    const memberColumns = [
        {
            title: t('home.name'),
            dataIndex: 'Name',
            key: 'Name',
            ellipsis: true,
            width: 250
        },
        {
            title: t('member.club_acronym'),
            dataIndex: 'ClubAcronym',
            key: 'ClubAcronym',
            ellipsis: true,
            width: 200,
            hide: currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee || props.selectedMemberType == Constant.HomeDetailsType.MembersWithAdminAccess
        },
        {
            title: t('member.phone_number'),
            dataIndex: 'MobilePhone',
            key: 'MobilePhone',
            width: 180
        },
        {
            title: t('member.email'),
            dataIndex: 'Email',
            key: 'Email',
            ellipsis: true,
            width: 300
        }
    ]

    const errorMemberColumns = [
        {
            title: t('home.name'),
            dataIndex: 'Name',
            key: 'Name',
            ellipsis: true,
            width: 250
        },
        {
            title: t('member.club_acronym'),
            dataIndex: 'ClubAcronym',
            key: 'ClubAcronym',
            ellipsis: true,
            width: 100,
            hide: currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee
        },
        {
            title: t('member.phone_number'),
            dataIndex: 'MobilePhone',
            key: 'MobilePhone',
            width: 180
        },
        {
            title: t('member.email'),
            dataIndex: 'Email',
            key: 'Email',
            ellipsis: true,
            width: 250
        },
        {
            title: t('home.error_description'),
            dataIndex: 'DateOfBirth',
            key: 'DateOfBirth',
            render: (value, record) => (
                <div>{record.DateOfBirth ? t("home.too_old") : t("home.no_dob")}</div>
            ),
            ellipsis: true,
            width: 150
        }
    ]

    const boatColumns = [
        {
            title: t('home.id'),
            dataIndex: 'Id',
            key: 'Id',
            ellipsis: true,
            width: 100
        },
        {
            title: t('member.club_acronym'),
            dataIndex: 'ClubAcronym',
            key: 'ClubAcronym',
            ellipsis: true,
            width: 100,
            hide: currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee || currentPosition.PositionTypeId == Constant.PositionType.ClubMember
        },
        {
            title: t('home.name'),
            dataIndex: 'Name',
            key: 'Name',
            ellipsis: true,
            width: 200
        },
        {
            title: t('home.rego'),
            dataIndex: 'Rego',
            key: 'Rego',
            width: 200
        }
    ]

    const getDisplayColumns = () => {
        if (props.selectedMemberType) {
            if (props.selectedMemberType == Constant.HomeDetailsType.CurrentBoat ||
                props.selectedMemberType == Constant.HomeDetailsType.SkipperOfBoats) {
                return boatColumns.filter(c => !c.hide)
            } else if (props.selectedMemberType == Constant.HomeDetailsType.FinancialMembers ||
                props.selectedMemberType == Constant.HomeDetailsType.UnFinancialMembers ||
                props.selectedMemberType == Constant.HomeDetailsType.FishingMembers ||
                props.selectedMemberType == Constant.HomeDetailsType.ActiveMembers ||
                props.selectedMemberType == Constant.HomeDetailsType.MembersWithAdminAccess) {
                return memberColumns.filter(c => !c.hide)
            } else if (props.selectedMemberType == Constant.HomeDetailsType.JuniorsWithErrors) {
                return errorMemberColumns.filter(c => !c.hide)
            }
        }
        return memberColumns.filter(c => !c.hide)
    }

    const closeForm = () => {
        props.handleCancel()
    }

    const getModalTitle = () => {
        let title = ""
        if (props.selectedMemberType) {
            if (props.selectedMemberType == Constant.HomeDetailsType.FinancialMembers) {
                title = t("home.financial_members")
            }
            else if (props.selectedMemberType == Constant.HomeDetailsType.UnFinancialMembers) {
                title = t("home.un_financial_members")
            }
            else if (props.selectedMemberType == Constant.HomeDetailsType.FishingMembers) {
                title = t("home.fishing_members")
            }
            else if (props.selectedMemberType == Constant.HomeDetailsType.JuniorsWithErrors) {
                title = t("home.juniors_with_errors")
            }
            else if (props.selectedMemberType == Constant.HomeDetailsType.ActiveMembers) {
                title = t("home.active_members")
            }
            else if (props.selectedMemberType == Constant.HomeDetailsType.MembersWithAdminAccess) {
                title = t("home.members_with_admin_access")
            }
            else if (props.selectedMemberType == Constant.HomeDetailsType.CurrentBoat) {
                title = t("home.current_boats")
            }
            else if (props.selectedMemberType == Constant.HomeDetailsType.SkipperOfBoats) {
                title = t("home.skipper_of_boats")
            }
        }
        return title

    }
    const getData = (pageNumber, pageSize) => {
        if (props.objectData) {
            if (props.selectedMemberType == Constant.HomeDetailsType.SkipperOfBoats) {
                if (props.objectData.SkipperOfBoats && props.objectData.SkipperOfBoats.length > 0) {
                    let data = {
                        ObjectList: props.objectData.SkipperOfBoats
                    }
                    setCurrentData(data)
                }
            } else if (props.selectedMemberType == Constant.HomeDetailsType.FinancialMembers) {
                showLoading()
                HomeService.getFinanceMembers(pageNumber, pageSize)
                    .finally(() => dismissLoading())
                    .then(result => {
                        if (result.data) {
                            setCurrentData(result.data)
                        }
                    })
                    .catch(error => CommonService.handleErrorResponse(error))
            } else if (props.selectedMemberType == Constant.HomeDetailsType.UnFinancialMembers) {
                showLoading()
                HomeService.getUnFinanceMembers(pageNumber, pageSize)
                    .finally(() => dismissLoading())
                    .then(result => {
                        if (result.data) {
                            setCurrentData(result.data)
                        }
                    })
                    .catch(error => CommonService.handleErrorResponse(error))
            } else if (props.selectedMemberType == Constant.HomeDetailsType.FishingMembers) {
                showLoading()
                HomeService.getFishingMembers(pageNumber, pageSize)
                    .finally(() => dismissLoading())
                    .then(result => {
                        if (result.data) {
                            setCurrentData(result.data)
                        }
                    })
                    .catch(error => CommonService.handleErrorResponse(error))
            } else if (props.selectedMemberType == Constant.HomeDetailsType.JuniorsWithErrors) {
                showLoading()
                HomeService.getJuniorWithErrorMembers(pageNumber, pageSize)
                    .finally(() => dismissLoading())
                    .then(result => {
                        if (result.data) {
                            setCurrentData(result.data)
                        }
                    })
                    .catch(error => CommonService.handleErrorResponse(error))
            } else if (props.selectedMemberType == Constant.HomeDetailsType.ActiveMembers) {
                showLoading()
                HomeService.getActiveMembers(pageNumber, pageSize)
                    .finally(() => dismissLoading())
                    .then(result => {
                        if (result.data) {
                            setCurrentData(result.data)
                        }
                    })
                    .catch(error => CommonService.handleErrorResponse(error))
            } else if (props.selectedMemberType == Constant.HomeDetailsType.CurrentBoat) {
                showLoading()
                HomeService.getCurrentBoats(pageNumber, pageSize)
                    .finally(() => dismissLoading())
                    .then(result => {
                        if (result.data) {
                            setCurrentData(result.data)
                        }
                    })
                    .catch(error => CommonService.handleErrorResponse(error))
            } else if (props.selectedMemberType == Constant.HomeDetailsType.MembersWithAdminAccess) {
                showLoading()
                HomeService.getAdminMembers(pageNumber, pageSize)
                    .finally(() => dismissLoading())
                    .then(result => {
                        if (result.data) {
                            setCurrentData(result.data)
                        }
                    })
                    .catch(error => CommonService.handleErrorResponse(error))
            }
        }
    }
    const getDataLength = () => {
        if (props.objectData) {
            if (props.selectedMemberType == Constant.HomeDetailsType.SkipperOfBoats) {
                if (props.objectData.SkipperOfBoats) {
                    return props.objectData.SkipperOfBoats.length
                }
            } else if (props.selectedMemberType == Constant.HomeDetailsType.FinancialMembers) {
                if (props.objectData.FinancialMembers) {
                    return props.objectData.FinancialMembers.length
                }
            } else if (props.selectedMemberType == Constant.HomeDetailsType.UnFinancialMembers) {
                if (props.objectData.UnFinancialMembers) {
                    return props.objectData.UnFinancialMembers.length
                }
            } else if (props.selectedMemberType == Constant.HomeDetailsType.FishingMembers) {
                if (props.objectData.FishingMembers) {
                    return props.objectData.FishingMembers.length
                }
            } else if (props.selectedMemberType == Constant.HomeDetailsType.JuniorsWithErrors) {
                if (props.objectData.JuniorsWithErrors) {
                    return props.objectData.JuniorsWithErrors.length
                }
            } else if (props.selectedMemberType == Constant.HomeDetailsType.ActiveMembers) {
                if (props.objectData.ActiveMembers) {
                    return props.objectData.ActiveMembers.length
                }
            } else if (props.selectedMemberType == Constant.HomeDetailsType.CurrentBoat) {
                if (props.objectData.CurrentBoats) {
                    return props.objectData.CurrentBoats.length
                }
            } else if (props.selectedMemberType == Constant.HomeDetailsType.MembersWithAdminAccess) {
                if (props.objectData.AdminMembers) {
                    return props.objectData.AdminMembers.length
                }
            }
        }
        return 0
    }

    const onTableChange = (pagination, filters, sorter, extra) => {
        getData(pagination.current, pagination.pageSize)
        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize
        })
    }

    return (
        <div>
            <Modal
                title={getModalTitle()}
                open={true}
                confirmLoading={false}
                onCancel={closeForm}
                maskClosable={false}
                footer={null}
                width={1000}
            >
                <Row className='m-t-15'>
                    <Col xs={{ span: 24 }}>
                        <Table
                            {...GetTableConfigs(getDisplayColumns(), currentData?.ObjectList, currentData?.TotalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                        />
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}

export default HomeDetails;
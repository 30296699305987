import React, { useState, useEffect } from 'react';
import { Modal, Table, Row, Col, Button, Input, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import LoadingContext from '../../../components/loading/loadingContext'
import { useGlobalState } from '../../../utilities/globalState'
import { GetTableConfigs, ActionButtonsCell, TextAndButtonCell, EditableCell } from '../../../components/customTable/customTable'
import { TrashIcon, CloseIcon, EditIcon } from '../../../components/icons/icons'
import { TrophyService, SettingService, CommonService, Constant } from '../../../services/services'

const { confirm } = Modal;
const _ = require("lodash");

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const components = {
    body: {
        cell: EditableCell,
    },
};
const AddUpdateAnglerGroup = (props) => {
    const { t } = useTranslation();
    const [anglerGroupForm] = Form.useForm();

    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [anglers, setAnglers] = useState([]);

    const [anglerGroup, setAnglerGroup] = useState({Members: []});
    const [originalAnglerGroup, setOriginalAnglerGroup] = useState({Members: []});

    const [members, setMembers] = useState([]);
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [refreshData, setRefreshData] = useState(false);

    const onSubmit = () => {
        // Validate
        var allErrorMessages = [];
        setMembers(current =>
            current.map(obj => {
                var errorMessages = [];
                if (!obj.Id) {
                    errorMessages.push({
                        key: 'Id',
                        value: t('angler_group.angler_name_number_is_required')
                    })
                }

                allErrorMessages.push(...errorMessages);
                return {
                    ...obj,
                    required: errorMessages.length > 0 ? true : false,
                    ErrorMessages: errorMessages,
                };
            }),
        );

        if (allErrorMessages.length > 0) {
            return;
        }

        anglerGroup.Members = members;
        // Add/Update
        showLoading();
        TrophyService.addUpdateAnglerGroup(anglerGroup)
            .finally(() => {
                dismissLoading();
            })
            .then(result => {
                if (result.data) {
                    CommonService.presentToast('success', props.isEdit ? t('angler_group.update_angler_group_successful') : t('angler_group.create_angler_group_successful'));
                    props.handleCancel(true);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const submitConfirmation = () => {
        function customizer(newMembers, oldMembers) {
            if (newMembers.length !== oldMembers.length)
                return true;

            for (var i = 0; i < newMembers.length; i ++) {
                if (newMembers[i].Id != null && oldMembers[i].Id != null && newMembers[i].Id !== oldMembers[i].Id) {
                    return true;
                }
                return false;
            }

            return false;
        }
        
        var changed = false;
        if (anglerGroup.Name != originalAnglerGroup.Name) {
            changed = true;
        }

        if (!changed) {
            var newMembers = _.sortBy(members, [function(m) { return m.Id; }]);
            var oldMembers = _.sortBy(originalAnglerGroup.Members, [function(m) { return m.Id; }]);
            changed = _.isEqualWith(newMembers, oldMembers, customizer);
        }

        if (changed) {
            confirm({
                title: t("common.submit_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    onSubmit();
                },
                onCancel() {
                    props.handleCancel(true);
                }
            });
        }
        else {
            props.handleCancel(false);
        }
    }

    const onClose = () => {
        submitConfirmation();
    }

    const canSubmit = (newAnglerGroup, newMembers) => {
        if (newAnglerGroup && newAnglerGroup.Name) {
            if (!newAnglerGroup.Name) {
                return false;
            }

            if (newMembers && newMembers.length > 0) {
                let inValid = newMembers.filter(x => !x.Id);
                if (inValid && inValid.length > 0) {
                    return false
                }
            }
            return true;
        }
        return false;
    }

    const addMember = () => {
        const newMembers = members.filter((m) => m.Id == null);
        if (newMembers != null && newMembers.length > 0) {
            return;
        }

        setMembers([...members, newMember()]);
    }
    
    const deleteMember = (record) => {
        if (!record.IsAddNew && record.Id > 0) {
            confirm({
                title: t("angler_group.delete_member_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    showLoading();
                    TrophyService.deleteAnglerMember(anglerGroup.Id, record.Id)
                    .finally(() => {
                        dismissLoading();
                    })
                    .then(result => {
                        const newMembers = members.filter((m) => m.Id !== record.Id);
                        setMembers(newMembers);
                        setEnableSubmit(canSubmit(anglerGroup, newMembers));
                        setRefreshData(true);
                    })
                    .catch(error => {
                        CommonService.handleErrorResponse(error);
                    });
                }
            });
        } else {
            const newMembers = members.filter((m) => m.Id !== record.Id);
            setMembers(newMembers);
            setEnableSubmit(canSubmit(anglerGroup, newMembers));
        }
    }

    const handleAnglerGroupNameOnChange = (value) => {
        _.update(anglerGroup, "Name", function (n) {
            return value;
        });
        setEnableSubmit(canSubmit(anglerGroup, members));
    }

    const handleOnChange = (value, record, dataIndex) => {
        if (record) {
            var index = members.findIndex(x => x.Id === record.Id);
            _.update(members[index], dataIndex, function (n) {
                return value;
            });

            setMembers(current =>
                current.map(obj => {
                    if (obj.Id === record.Id) {
                        var angler = _.find(anglers, function(x) { return x.Id == record.Id; });
                        if (angler) {
                            return {
                                ...obj,
                                EntityName: angler.EntityName,
                                MobilePhone: angler.MobilePhone,
                                StreetName: angler.StreetName,
                                Suburb: angler.Suburb,
                                State: angler.State,
                                PostCode: angler.PostCode,
                            };
                        } else {
                            return {
                                ...obj,
                                EntityName: null,
                                MobilePhone: null,
                                StreetName: null,
                                Suburb: null,
                                State: null,
                                PostCode: null,
                            };
                        }
                    }
                    return obj;
                }),
            );
        }
        setEnableSubmit(canSubmit(anglerGroup, members));
    }

    const columns = [
        {
            title: t('common.action'),
            render: (value, record) => {
                return <ActionButtonsCell data={record} btnList={[
                    {
                        Description: t("common.delete"),
                        Icon: <TrashIcon />,
                        ColorClass: Constant.ColorClass.LightRed,
                        Hide: false,
                        NoMargin: true,
                        handleAction: deleteMember
                    }
                ]} />
            },
            align: 'center',
            width: 50,
        },
        {
            title: <span className='required'>{t('angler_group.angler_name_number')}</span>,
            dataIndex: 'Id',
            key: 'Id',
            editable: true,
            required: true,
            extraData: anglers,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "MemberNameNumber",
            validateAtStart: true,
            handleOnChange: handleOnChange,
            width: 160
        },
        {
            title: t('angler_group.club'),
            dataIndex: 'EntityName',
            key: 'EntityName',
            width: 180
        },
        {
            title: t('angler_group.phone_no'),
            dataIndex: 'MobilePhone',
            key: 'MobilePhone',
            width: 80
        },
        {
            title: t('angler_group.address'),
            dataIndex: 'StreetName',
            key: 'StreetName',
            width: 100
        },
        {
            title: t('angler_group.suburb'),
            dataIndex: 'Suburb',
            key: 'Suburb',
            width: 90
        },
        {
            title: t('angler_group.state'),
            dataIndex: 'State',
            key: 'State',
            width: 50
        },
        {
            title: t('angler_group.postcode'),
            dataIndex: 'PostCode',
            key: 'PostCode',
            width: 60
        }
    ]

    const memberColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                validateAtStart: col.validateAtStart,
                disabled: col.disabled,
                extraData: col.extraData,
                selectionKey: col.selectionKey,
                selectionDisplay: col.selectionDisplay,
                allowClear: col.allowClear
            }),
        };
    })

    const newAnglerGroup = (id) => {
        return {
            IsAddNew: true,
            Id: id,
            Name: null,
            StatusId: Constant.StatusCode.Active,
            Members: [ newMember() ]
        };
    }

    const newMember = () => {
        return {
            IsAddNew: true,
            Id: null
        };
    }

    useEffect(() => {

        if (!props.isEdit) {
            setAnglerGroup(newAnglerGroup(-1));
            setOriginalAnglerGroup(newAnglerGroup(-1));
            setMembers([newMember()]);
            anglerGroupForm.setFieldsValue(newAnglerGroup(-1));
        } else {
            setAnglerGroup(props.selectedAnglerGroup);
            setOriginalAnglerGroup(props.selectedAnglerGroup);
            setMembers(props.selectedAnglerGroup.Members);
            anglerGroupForm.setFieldsValue(props.selectedAnglerGroup);
            setEnableSubmit(canSubmit(props.selectedAnglerGroup, props.selectedAnglerGroup.Members));
        }

        showLoading()
        Promise.all([
            TrophyService.getAnglers()
        ])
        .finally(() => dismissLoading())
        .then(response => {
            if (response[0].data) {
                setAnglers(response[0].data);
            }
        })
        .catch(error => CommonService.handleErrorResponse(error))
    }, [])

    return (
        <div>
            <Modal
                title={props.isEdit ? t("angler_group.edit_angler_groups") : t("angler_group.insert_angler_groups")}
                open={true}
                onCancel={onClose}
                confirmLoading={false}
                maskClosable={false}
                centered
                width={1300}
                footer={[
                    <Button key={0} size="large" onClick={onClose}>{t("common.cancel")}</Button>,
                    <Button key={1} size="large" type="primary" disabled={!enableSubmit} onClick={onSubmit}>{props.isEdit ? t("common.submit") : t("common.insert")}</Button>
                ]}
            >
                <Form
                    {...layout}
                    name='anglerGroupForm'
                    key='anglerGroupForm'
                    onFinish={onSubmit}
                    component={false}
                    form={anglerGroupForm}>

                    <Row id={Constant.FixItemsContainerId} className="fix-item-container">
                        <Col span={24}>
                            <Form.Item
                                label={t("angler_group.angler_group_number")}
                                extra={t("angler_group.angler_group_placeholder")}
                                rules={[{ required: true, message: t("angler_group.angler_group_number_is_required") }]}
                                name="Name" colon={false} labelAlign="left">
                                <Input onChange={(e) => handleAnglerGroupNameOnChange(e.target.value)} allowClear  />
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    <Row id={Constant.FixItemsContainerId} className="fix-item-container">
                        <Col xs={{ span: 12 }} className="text-left extra-btn-m-t">
                        </Col>
                        <Col xs={{ span: 12 }} className="text-right extra-btn-m-t">
                            <div className="m-t-10">
                                <Button className="m-l-10 secondary-btn" type="primary" onClick={addMember}>
                                    {t("common.add")}
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    <Row className="m-t-10" gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Table
                                components={components}
                                rowClassName={() => 'editable-row'}
                                tableLayout='fixed'
                                columns={memberColumns} 
                                dataSource={members} 
                                pagination={false}
                                rowKey="Id"
                            />
                        </Col>
                    </Row>
                </Form>

            </Modal>
        </div>
    )
}

export default AddUpdateAnglerGroup
import React, { useState, useEffect } from 'react';
import { Constant, CommonService, SettingService, MembershipService } from '../../services/services';
import { useTranslation } from 'react-i18next';
import LoadingContext from '../../components/loading/loadingContext'
import { useGlobalState } from './../../utilities/globalState'
import { Row, Col, Table, Button, Dropdown, Form, Space, Select, Empty, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { GetTableConfigs } from '../../components/customTable/customTable'
import PubSub from 'pubsub-js'
import EnhancedSelect from '../../components/enhancedSelect/enhancedSelect';
import './eoyMembershipInvoicing.scss';
import dayjs from 'dayjs';

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const { Text, Title } = Typography;

const EoyMembershipInvoicing = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [membershipInvoicingData, setMembershipInvoicingData] = useState({
        EoyMembershipInvoicingLines: []
    })
    const [currentPosition] = useGlobalState('currentPosition');
    const [form] = Form.useForm();
    const [financialYears, setFinancialYears] = useState([]);
    const [entityDetail, setEntityDetail] = useState({})

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
        }, 100)
        showLoading()
        Promise.all([
            SettingService.getStateEntityDetail()
        ])
            .finally(() => dismissLoading())
            .then(response => {

                if (response[0] && response[0].data) {
                    setEntityDetail(response[0].data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
        runReport()
        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);
        return () => {
            MembershipService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    const exportItems = [
        {
            label: t("common.excel"),
            key: Constant.FileType.Excel
        },
        {
            label: t("common.csv"),
            key: Constant.FileType.Csv
        }
    ];

    const handleExport = (e) => {
        exportMembershipInvoicing(e.key)
    };

    const exportMembershipInvoicing = (fileType) => {
        showLoading()
        MembershipService.getExportEoyMembershipInvoicing(fileType)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    if (fileType == Constant.FileType.Excel) {
                        const type = result.headers['content-type']
                        const blob = new Blob([result.data], { type: type })
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = `EOY_Memberhsip_Invoicing.xlsx`
                        link.click()
                    } else if (fileType == Constant.FileType.Csv) {
                        const type = result.headers['content-type']
                        const blob = new Blob([result.data], { type: type })
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = `EOY_Memberhsip_Invoicing.csv`
                        link.click()
                    }
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    const menuExport = {
        items: exportItems,
        onClick: handleExport
    };

    const runReport = () => {
        showLoading()
        MembershipService.getEoyMembershipInvoicing()
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    setMembershipInvoicingData(result.data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    const columns = [
        {
            title: "DATED " + CommonService.getDateString(new Date()),
            dataIndex: 'ClubName',
            key: 'ClubName',
            width: 200
        },
        {
            title: t("membership_invoicing.invoiceable_members", { state_association_acronym: entityDetail.EntityAcronym }),
            className: "invoiceable-members",
            children: [
                {
                    title: t("membership_invoicing.senior"),
                    className: "invoiceable-members-child",
                    children: [
                        {
                            title: t("membership_invoicing.men"),
                            dataIndex: 'SeniorMen',
                            key: 'SeniorMen',
                            width: 40,
                            align: 'center',
                            className: "invoiceable-members-child"
                        },
                        {
                            title: t("membership_invoicing.women"),
                            dataIndex: 'SeniorWomen',
                            key: 'SeniorWomen',
                            width: 50,
                            align: 'center',
                            className: "invoiceable-members-child"
                        }
                    ]
                },
                {
                    title: t("membership_invoicing.junior"),
                    className: "invoiceable-members-child",
                    children: [
                        {
                            title: t("membership_invoicing.jm"),
                            dataIndex: 'JuniorMale',
                            key: 'JuniorMale',
                            width: 40,
                            align: 'center',
                            className: "invoiceable-members-child"
                        },
                        {
                            title: t("membership_invoicing.jf"),
                            dataIndex: 'JuniorFemale',
                            key: 'JuniorFemale',
                            width: 40,
                            align: 'center',
                            className: "invoiceable-members-child"
                        },
                        {
                            title: t("membership_invoicing.sfm"),
                            dataIndex: 'SmallFryMale',
                            key: 'SmallFryMale',
                            width: 40,
                            align: 'center',
                            className: "invoiceable-members-child"
                        },
                        {
                            title: t("membership_invoicing.sff"),
                            dataIndex: 'SmallFryFemale',
                            key: 'SmallFryFemale',
                            width: 40,
                            align: 'center',
                            className: "invoiceable-members-child"
                        }
                    ]
                },
                {
                    title: t("membership_invoicing.invoice_numbers"),
                    className: "invoiceable-members-child",
                    children: [
                        {
                            title: t("membership_invoicing.senior"),
                            dataIndex: 'SeniorInvoiceNumber',
                            key: 'SeniorInvoiceNumber',
                            width: 50,
                            align: 'center',
                            className: "invoiceable-members-child"
                        },
                        {
                            title: t("membership_invoicing.junior"),
                            dataIndex: 'JuniorInvoiceNumber',
                            key: 'JuniorInvoiceNumber',
                            width: 50,
                            align: 'center',
                            className: "invoiceable-members-child"
                        },
                        {
                            title: t("membership_invoicing.total"),
                            dataIndex: 'TotalInvoiceNumber',
                            key: 'TotalInvoiceNumber',
                            width: 40,
                            align: 'center',
                            className: "invoiceable-members-child"
                        }
                    ]
                },
            ]

        },
        {
            title: t("membership_invoicing.delegates_allowed"),
            align: 'center',
            className: "delegates-allowed",
            children: [
                {
                    title: t("membership_invoicing.maximum_members"),
                    dataIndex: 'DelegatesAllowed',
                    key: 'DelegatesAllowed',
                    width: 80,
                    align: 'center',
                    className: "invoiceable-members-child"
                }
            ],
        },

    ];

    return (
        <>
            {currentPosition && (currentPosition.PositionTypeId == Constant.PositionType.StateExcutive ||
                currentPosition.PositionTypeId == Constant.PositionType.NationalExecutive) &&
                <div className='eoy-membership-invoicing'>
                    <div id={Constant.FixItemsContainerId}>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                            <Col xs={{ span: 24 }}>
                                <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                    <Title className='color-dark-blue' level={5}>{t("membership_invoicing.title")}</Title>
                                </Space>
                            </Col>
                        </Row>
                        <Row gutter={24} align={'bottom'}>
                            <Col xs={{ span: 24 }} xxl={{ span: 16 }} className="text-right">
                                <Dropdown className='m-l-10' menu={menuExport} trigger={['click']}>
                                    <Button className='btn-outline-blue btn-icon' icon={<DownloadOutlined />}>
                                        {t("common.export")}
                                    </Button>
                                </Dropdown>
                            </Col>
                        </Row>
                    </div>
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='m-t-10'>
                        <Col xs={{ span: 24 }} xxl={{ span: 16 }}>
                            <Table
                                bordered
                                {...GetTableConfigs(columns, membershipInvoicingData.EoyMembershipInvoicingLines, membershipInvoicingData.EoyMembershipInvoicingLines.length, "ClubId", null, 1, null, false)}
                                summary={() => {
                                    return (
                                        <>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell>{t("common.total")}</Table.Summary.Cell>
                                                <Table.Summary.Cell className='text-center'>
                                                    <Text>{membershipInvoicingData?.TotalSeniorMen}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell className='text-center'>
                                                    <Text>{membershipInvoicingData?.TotalSeniorWomen}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell className='text-center'>
                                                    <Text>{membershipInvoicingData?.TotalJuniorMale}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell className='text-center'>
                                                    <Text>{membershipInvoicingData?.TotalJuniorFemale}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell className='text-center'>
                                                    <Text>{membershipInvoicingData?.TotalSmallFryMale}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell className='text-center'>
                                                    <Text>{membershipInvoicingData?.TotalSmallFryFemale}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell className='text-center'>
                                                    <Text>{membershipInvoicingData?.TotalSeniorInvoiceNumber}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell className='text-center'>
                                                    <Text>{membershipInvoicingData?.TotalJuniorInvoiceNumber}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell className='text-center'>
                                                    <Text>{membershipInvoicingData?.TotalTotalInvoiceNumber}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell className='text-center'>
                                                    <Text>{membershipInvoicingData?.TotalDelegatesAllowed}</Text>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        </>
                                    );
                                }}
                            />
                        </Col>
                    </Row>

                </div>
            }

            {currentPosition && currentPosition.PositionTypeId != Constant.PositionType.StateExcutive && currentPosition.PositionTypeId != Constant.PositionType.NationalExecutive &&
                <div className='m-t-10'>
                    <Table
                        style={{ visibility: 'hidden', height: '0px' }}
                        {...GetTableConfigs(columns, membershipInvoicingData.EoyMembershipInvoicingLines, membershipInvoicingData.EoyMembershipInvoicingLines.length, "ClubId", null, 1, null, false)}
                    />
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_access")} />
                </div >
            }
        </>
    )
}

export default EoyMembershipInvoicing;
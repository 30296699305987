import React, { useState, useEffect } from 'react';
import { Modal, Table, Row, Col, Button, Form, Space, Input, DatePicker, Typography, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import LoadingContext from '../../../components/loading/loadingContext'
import { Constant, CommonService, TournamentService, SettingService } from '../../../services/services';
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { DateTimeCell } from '../../../components/customTable/customTable'
import AddImage from '../../../assets/icons/add-image.svg'

const { confirm } = Modal;
const { Title, Text } = Typography;
const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const fileTypes = {
    Rules: 1,
    EntryForm: 2,
    Flyer: 3
}

const TournamentSanctioningApplication = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [isDirty, setIsDirty] = useState(false);
    const [editTournamentForm] = Form.useForm();
    const [selectedObject, setSelectedObject] = useState();

    useEffect(() => {
        if (props.visible && props.selectedObject)
            setSelectedObject(props.selectedObject)
    }, [props.visible])
    const canSubmit = () => {
        if (selectedObject && selectedObject.Rules && selectedObject.EntryForm) {
            return true
        }

        return false
    }

    const beforeUploadFile = (file, fileType) => {
        CommonService.getFileBase64(file, fileUrl => {
            let temp = Object.assign({}, selectedObject);
            if (fileType == fileTypes.Rules) {
                temp.RulesBase64 = fileUrl
                temp.Rules = file.name
            }
            else if (fileType == fileTypes.EntryForm) {
                temp.EntryFormBase64 = fileUrl
                temp.EntryForm = file.name
            }
            else if (fileType == fileTypes.Flyer) {
                temp.FlyerBase64 = fileUrl
                temp.Flyer = file.name
            }
            setIsDirty(true)
            setSelectedObject(temp)
        });
    }

    const getErrorMessage = () => {
        if (selectedObject) {
            if (!selectedObject.RulesUrl && !selectedObject.RulesBase64) {
                return t("sanctioning_applications.rules_required")
            }

            if (!selectedObject.EntryFormUrl && !selectedObject.EntryFormBase64) {
                return t("sanctioning_applications.entry_form_required")
            }
        }

        return null
    }

    const closeForm = () => {
        if (isDirty) {
            confirm({
                title: t("common.confirm"),
                icon: <ExclamationCircleOutlined />,
                content: t("common.submit_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    if (canSubmit()) {
                        onSubmit()
                    } else {
                        let message = getErrorMessage()
                        if (message) {
                            CommonService.presentToast('error', message)
                        }
                    }
                },
                onCancel() {
                    props.handleCancel()
                }
            });
        } else {
            props.handleCancel()
        }
    }

    const onSubmit = () => {
        if (canSubmit()) {
            showLoading()
            TournamentService.updateTournamentSanctioningApplication(selectedObject)
                .finally(() => dismissLoading())
                .then(result => {
                    CommonService.presentToast('success', t("sanctioning_applications.apply_for_tournament_date_successfully"))
                    props.handleCancel(true)
                })
                .catch(error => CommonService.handleErrorResponse(error))
        }
    }

    const removeFile = () => {
        let temp = Object.assign({}, selectedObject)
        temp.Flyer = null
        temp.FlyerBase64 = ""
        setSelectedObject(temp)
        setIsDirty(true)
    }

    return (
        <Modal
            title={t("tournament_list.apply_for_tournament_date")}
            open={true}
            onCancel={closeForm}
            confirmLoading={false}
            maskClosable={false}
            centered
            width={1200}
            footer={[
                <Button key={0} size="large" onClick={closeForm}>{t("common.cancel")}</Button>,
                <Button key={1} size="large" type="primary" disabled={!canSubmit()} onClick={onSubmit}>{t("common.apply")}</Button>
            ]}
        >
            <Row>
                <Col xs={{ span: 24 }}>
                    <Form
                        layout="vertical"
                        {...layout}
                        form={editTournamentForm}
                        name="editTournamentForm"
                        key='editTournamentForm'
                        onFinish={onSubmit}>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                            <Col xs={{ span: 24 }}>
                                <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                    <Text className='color-black'>{t("sanctioning_applications.tournament_start_date")}</Text>
                                    <Text className='semi-bold-text'>{selectedObject?.Title ?? "_"}</Text>
                                </Space>
                            </Col>
                            <Col xs={{ span: 24 }}>
                                <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                    <Text className='color-black'>{t("sanctioning_applications.tournament_description")}</Text>
                                    <Text className='semi-bold-text'>{selectedObject?.Description ?? "_"}</Text>
                                </Space>
                            </Col>
                            <Col xs={{ span: 12 }}>
                                <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                    <Text className='color-black'>{t("sanctioning_applications.tournament_start_date")}</Text>
                                    {selectedObject ? <DateTimeCell value={selectedObject?.StartDate} className='semi-bold-text color-dark-blue' emptyText="_" /> : "_"}
                                </Space>
                            </Col>
                            <Col xs={{ span: 12 }}>
                                <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                    <Text className='color-black'>{t("sanctioning_applications.tournament_end_date")}</Text>
                                    {selectedObject ? <DateTimeCell value={selectedObject?.FinishDate} className='semi-bold-text color-dark-blue' emptyText="_" /> : "_"}
                                </Space>
                            </Col>

                        </Row>

                        <Row className='m-t-10' gutter={[, Constant.SpaceConstant.VerticalGutter]} align={'middle'}>
                            <Col xs={{ span: 3 }}>
                                <Text className='color-black required'>{t("sanctioning_applications.rules")}</Text>
                            </Col>
                            <Col xs={{ span: 3 }}>
                                <Upload
                                    listType="text"
                                    showUploadList={false}
                                    beforeUpload={file => beforeUploadFile(file, fileTypes.Rules)}
                                    maxCount={1}
                                >
                                    <Button size="small" className='btn-solid-dark-blue'>{t("sanctioning_applications.choose_file")}</Button>
                                </Upload>
                            </Col>
                            <Col xs={{ span: 18 }}>
                                <Text className='semi-bold-text'>{(selectedObject && selectedObject.Rules && (selectedObject.RulesUrl || selectedObject.RulesBase64)) ? selectedObject.Rules : "_"}</Text>
                            </Col>

                            <Col xs={{ span: 3 }}>
                                <Text className='color-black required'>{t("sanctioning_applications.entry_form")}</Text>
                            </Col>
                            <Col xs={{ span: 3 }}>
                                <Upload
                                    listType="text"
                                    showUploadList={false}
                                    beforeUpload={file => beforeUploadFile(file, fileTypes.EntryForm)}
                                    maxCount={1}
                                >
                                    <Button size="small" className='btn-solid-dark-blue'>{t("sanctioning_applications.choose_file")}</Button>
                                </Upload>

                            </Col>
                            <Col xs={{ span: 18 }}>
                                <Text className='semi-bold-text'>{(selectedObject && selectedObject.EntryForm && (selectedObject.EntryFormUrl || selectedObject.EntryFormBase64)) ? selectedObject.EntryForm : "_"}</Text>
                            </Col>
                            <Col xs={{ span: 3 }}>
                                <Text className='color-black'>{t("sanctioning_applications.flyer")}</Text>
                            </Col>
                            <Col xs={{ span: 3 }}>
                                <Upload
                                    listType="text"
                                    showUploadList={false}
                                    beforeUpload={file => beforeUploadFile(file, fileTypes.Flyer)}
                                    maxCount={1}
                                >
                                    <Button size="small" className='btn-solid-dark-blue'>{t("sanctioning_applications.choose_file")}</Button>
                                </Upload>
                            </Col>
                            <Col xs={{ span: 16 }}>
                                <Space direction="horizontal" size={Constant.SpaceConstant.TextFieldGutter}>
                                    <Text className='semi-bold-text'>{(selectedObject && selectedObject.Flyer && (selectedObject.FlyerUrl || selectedObject.FlyerBase64)) ? selectedObject.Flyer : "_"}</Text>
                                    {selectedObject && selectedObject.Flyer && (selectedObject.FlyerUrl || selectedObject.FlyerBase64) &&
                                        <Button className='color-dark-red' type="text" icon={<DeleteOutlined />} onClick={removeFile} size="middle" />
                                    }
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Modal>
    )
}
export default TournamentSanctioningApplication
import React, { useState, useEffect } from 'react';
import { Modal, Table, Row, Col, Button, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import LoadingContext from '../../../components/loading/loadingContext'
import { useGlobalState } from './../../../utilities/globalState'
import { Constant, CommonService, TournamentService } from '../../../services/services';
import { EditIcon } from '../../../components/icons/icons';
import { GetTableConfigs, StatusCell, EditableCell, DateTimeCell } from '../../../components/customTable/customTable'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import MemberList from '../../positionTitle/memberList/memberList';

const { confirm } = Modal;

const EditMultipleTournaments = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [tournaments, setTournaments] = useState([]);
    const [isDirty, setIsDirty] = useState(false);
    const [currentPosition] = useGlobalState('currentPosition');

    useEffect(() => {
        if (props.visible) {
            setTournaments(props.objectData.map(x => Object.assign({}, x)))
        }
    }, [props.visible])

    const handleOnRecordChange = (value, record, dataIndex) => {
        if (record) {
            let temp = tournaments.map(x => Object.assign({}, x));
            let index = tournaments.indexOf(record)
            if (temp[index]) {
                temp[index][dataIndex] = value;

                setTournaments(temp)
                checkDirty(temp)
            }
        }
    }

    const checkDirty = (data) => {
        if (data && data.length > 0) {
            let dirty = false
            for (let i = 0; i < data.length; i++) {
                let original = props.objectData.find(o => o.Id == data[i].Id)
                if (!original) {
                    dirty = true
                    break
                } else {
                    if (!CommonService.shallowCompare(data[i], original)) {
                        dirty = true
                        break
                    }
                }

            }

            if (dirty) {
                setIsDirty(true)
            } else {
                setIsDirty(false)
            }
        }
    }


    const statusCodes = [
        {
            Value: Constant.StatusCode.Active,
            Description: t("common.active")
        },
        {
            Value: Constant.StatusCode.Inactive,
            Description: t("common.inactive")
        }
    ]

    const columns = [
        {
            title: t('tournament.club'),
            dataIndex: 'EntityAcronym',
            key: 'EntityAcronym',
            sorter: true,
            width: 90,
            hidden: currentPosition ? currentPosition.PositionTypeId != Constant.PositionType.StateExcutive : false
        },
        {
            title: t('tournament.zone'),
            dataIndex: 'StateZoneName',
            key: 'StateZoneName',
            sorter: true,
            width: 90,
            hidden: currentPosition ? currentPosition.PositionTypeId != Constant.PositionType.StateExcutive : false
        },
        {
            title: t('sanctioning_applications.title'),
            dataIndex: 'Title',
            key: 'Title',
            sorter: true,
            width: 200
        },
        {
            title: t('sanctioning_applications.start_date'),
            dataIndex: 'StartDate',
            key: 'StartDate',
            sorter: true,
            width: 110,
            defaultSortOrder: 'descend',
            render: (value, record) => (
                <DateTimeCell value={record.StartDate} />
            )
        },
        {
            title: t('sanctioning_applications.finish_date'),
            dataIndex: 'FinishDate',
            key: 'FinishDate',
            sorter: true,
            width: 110,
            render: (value, record) => (
                <DateTimeCell value={record.FinishDate} />
            )
        },
        {
            title: t('sanctioning_applications.approval_status'),
            dataIndex: 'DatesApprovalStatus',
            key: 'DatesApprovalStatus',
            sorter: true,
            width: 180,
            render: (value, record) => (
                <p dangerouslySetInnerHTML={{ __html: record.DatesApprovalStatus }}>{ }</p>
            )
        },
        {
            title: t('common.status'),
            dataIndex: 'StatusId',
            key: 'StatusId',
            editable: true,
            extraData: statusCodes,
            required: true,
            cellType: Constant.CellType.EnhancedSelect,
            width: 150,
            selectionKey: "Value",
            selectionDisplay: "Description",
            validateAtStart: true,
            handleOnChange: handleOnRecordChange,
            allowClear: false
        }
    ]

    const columnsMapping = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition,
                selectionKey: col.selectionKey,
                selectionDisplay: col.selectionDisplay,
                validateAtStart: col.validateAtStart,
                allowClear: col.allowClear,
                disabled: col.disabled
            }),
        };
    });

    const components = {
        body: {
            cell: EditableCell,
        }
    };

    const closeForm = () => {
        if (isDirty) {
            confirm({
                title: t("common.confirm"),
                icon: <ExclamationCircleOutlined />,
                content: t("common.submit_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    onSubmit()
                },
                onCancel() {
                    props.handleCancel()
                }

            });
        } else {
            props.handleCancel()
        }
    }

    const onSubmit = () => {
        showLoading()
        TournamentService.updateTournamentStatuses(tournaments)
            .finally(() => dismissLoading())
            .then(result => {
                CommonService.presentToast('success', t("tournament.tournament_update_successful"))
                props.handleCancel(true)
            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const onTableChange = (pagination) => {
    }


    return (
        <div>
            <Modal
                title={t("tournament.tournaments")}
                open={true}
                onCancel={closeForm}
                confirmLoading={false}
                maskClosable={false}
                centered
                width={1200}
                footer={[
                    <Button key={0} size="large" onClick={closeForm}>{t("common.cancel")}</Button>,
                    <Button key={1} size="large" type="primary" onClick={onSubmit}>{t("common.submit")}</Button>
                ]}
            >
                <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                    <Col xs={{ span: 24 }}>
                        <Table
                            components={components}
                            rowClassName={() => 'editable-row'}
                            {...GetTableConfigs(columnsMapping, tournaments, tournaments.length, "Id", onTableChange, 1, "", false)}
                        />
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}

export default EditMultipleTournaments
import React, { useEffect, useState } from 'react';
import './memberViewStateContacts.scss';
import { useTranslation } from 'react-i18next';
import {
    Constant,
    CommonService,
    MembershipService
} from '../../../services/services'
import { Table, Row, Col, Form, Card, Image } from 'antd';
import { GetTableConfigs_SimplePaginator } from '../../../components/customTable/customTable'
import LoadingContext from '../../../components/loading/loadingContext'
import PubSub from 'pubsub-js';

const style = {
    padding: '8px 0',
};

const styleValue = {
    color: '#2572cb',
    padding: '8px 0',
};

const styleHyperLink = {
    color: 'blue',
    textDecorationLine: 'underline',
};

const MemberViewStateContacts = (props) => {
    const { t } = useTranslation();
    const [formFilter] = Form.useForm();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext);
    const [totalItems, setTotalItems] = useState(0);
    const [memberStateExecutive, setMemberStateExecutive] = useState([]);
    const [memberStateDetail, setMemberStateDetail] = useState({});

    const [gridConfigOptions, setGridConfigOptionsValue] = useState({
        pageNumber: 1,
        pageSize: CommonService.getPageSize(),
        sort: "LastName-ascend",
    })

    const handleLinkPress = (url) => {
        window.open(url, '_blank');
    };

    const getMemberStateDetail = (isShowLoading = false) => {
        if (isShowLoading) {
            showLoading()
        }

        MembershipService.getMemberStateDetail()
            .finally(() => {
                if (isShowLoading) {
                    dismissLoading()
                }
            })
            .then(result => {
                if (result.data) {
                    setMemberStateDetail(result.data);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error)
            });
    }

    const search = (pageNumber, pageSize, sort, query, isShowLoading = false) => {
        if (isShowLoading) {
            showLoading();
        }

        let params = { ...query };
        if (!params) params = {};

        MembershipService.getMemberStateExecutive(pageNumber, pageSize, sort, params)
            .finally(() => {
                if (isShowLoading) {
                    dismissLoading();
                }
            })
            .then(result => {
                if (result.data) {
                    setMemberStateExecutive(result.data.ObjectList);
                    setTotalItems(result.data.TotalItems);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }


    const onSearch = (data) => {
        if (data) {
            search(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, null, true)
        }
        else {
            search(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, null, true)
        }
    }

    const columns = [
        {
            title: t('member.position_title'),
            dataIndex: 'PositionTitleName',
            key: 'PositionTitleName',
            sorter: false,
            width: 100,
        },
        {
            title: t('member.position_holder'),
            dataIndex: 'PositionHolderFullName',
            key: 'PositionHolderFullName',
            sorter: false,
            width: 100,
        },
        {
            title: t('member.phone_number'),
            dataIndex: 'PhoneNumber',
            key: 'PhoneNumber',
            sorter: false,
            width: 80,
        },
        {
            title: t('member.email'),
            dataIndex: 'Email',
            key: 'Email',
            sorter: false,
            width: 100,
        }
    ]

    const onTableChange = (pagination, filters, sorter, extra) => {
        let data = formFilter.getFieldsValue();

        var sort = CommonService.getSortString(sorter);
        if (sort || sort.length === 0) {
            sort = gridConfigOptions.sort;
        }
        search(pagination.current, pagination.pageSize, sort, data);

        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        });
    }

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight())
        }, 100)

        showLoading();
        Promise.all([
            onSearch(),
            getMemberStateDetail()
        ])
            .finally(() => {
                dismissLoading();
            })
            .then((values) => {
            });

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);

        return () => {
            MembershipService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    const renderLabelValue = (label, value, isHyperlink = false) => (
        <>
            <div className="member-state-details__label">{t(label)}</div>
            <div
                className={`member-state-details__value ${isHyperlink ? 'member-state-details__hyperlink' : ''}`}
                onClick={isHyperlink ? () => handleLinkPress(value) : undefined}
            >
                {value}
            </div>
        </>
    );

    return (
        <div className="member-view-state-contacts">
            <Row gutter={[24, 24]} className="member-state-details__row">
                <Col span={12}>
                    <Card title={t("member.state_name")} bordered={false} className="member-state-details__card">
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                {renderLabelValue("member.state_name", memberStateDetail.ClubStateName)}
                            </Col>
                            <Col span={12}>
                                {renderLabelValue("member.state_acronym", memberStateDetail.ClubStateAcronym)}
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                {renderLabelValue("member.state_website", memberStateDetail.ClubStateWebsite, true)}
                                {renderLabelValue("member.state_description", memberStateDetail.ClubStateDescription)}
                            </Col>
                            <Col span={12} className="member-state-details__image-container">
                                <Image src={memberStateDetail.LogoUrl} />
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col span={12}>
                    <Card title={t("member.main_state_details")} bordered={false} className="member-state-details__card">
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                {renderLabelValue("member.name_of_contact_person", memberStateDetail.MainClubContactName)}
                            </Col>
                            <Col span={12}>
                                {renderLabelValue("member.main_contact_phone_number", memberStateDetail.MainContactPhoneNumber)}
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                {renderLabelValue("member.main_contact_email", memberStateDetail.MainContactEmail)}
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                {renderLabelValue("member.main_address", memberStateDetail.MainAddress)}
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row className="m-t-10" gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                <Col xs={{ span: 24 }}>

                    <Card title={t("member.state_executive_committee")} bordered={false}>
                        <Table
                            {...GetTableConfigs_SimplePaginator(columns, memberStateExecutive, totalItems, "id", onTableChange, gridConfigOptions.pageNumber)}
                            rowKey="Id"
                        />

                    </Card>
                </Col>
            </Row>

        </div>
    )

}

export default MemberViewStateContacts;
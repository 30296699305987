import React, { useState, useEffect } from 'react';
import { Constant, CommonService, TrophyService } from '../../../services/services';
import { useTranslation } from 'react-i18next';
import { GetTableConfigs, StatusCell, ActionButtonsCell, EditableCell } from '../../../components/customTable/customTable'
import LoadingContext from '../../../components/loading/loadingContext'
import { EditIcon, EyeIcon, TrashIcon, FilterAltIcon, CloseIcon } from '../../../components/icons/icons';
import { Row, Col, Table, Button, Checkbox, Modal, Form, Drawer, Space, Input, Select, Typography, Empty, Dropdown, DatePicker } from 'antd';
import { ExclamationCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { useGlobalState } from './../../../utilities/globalState'
import AddUpdateTrophy from './../addUpdateTrophy/addUpdateTrophy'
import TrophyDetail from './../trophyDetail/trophyDetail'
import { useHistory } from "react-router-dom";
import EnhancedSelect from '../../../components/enhancedSelect/enhancedSelect';

import PubSub from 'pubsub-js'

const { confirm } = Modal;
const { Option } = Select;
const { Title } = Typography;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const defaultGridOption = {
    pageNumber: 1,
    pageSize: CommonService.getPageSize(),
    sort: "Name-ascend"
}

const TrophyManagement = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const emptyTableData = {
        ObjectList: [],
        TotalItems: 0
    }
    const [trophyData, setTrophyData] = useState(emptyTableData)
    const [gridConfigOptions, setGridConfigOptionsValue] = useState(defaultGridOption)
    const [currentPosition] = useGlobalState('currentPosition');
    const [formFilter] = Form.useForm();
    const [trophyFilter, setTrophyFilter] = useState({ StatusIds: Constant.StatusCode.Active, Rankings: 10 });
    const [visibleFilter, setVisibleFilter] = useState(false);
    const [filterData, setFilterData] = useState({})
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [visibleAddUpdateTrophy, setVisibleAddUpdateTrophy] = useState(false);
    const [selectedObject, setSelectedObject] = useState({})
    const [visibleTrophyDetail, setVisibleTrophyDetail] = useState(false);
    const [trophySelectionData, setTrophySelectionData] = useState({})
    const history = useHistory();

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
        }, 100)

        TrophyService.getTrophySelectionData()
            .then(result => {
                if (result.data) {
                    setTrophySelectionData(result.data)
                }
            })
            .catch(error => CommonService.handleErrorResponse(error))

        showLoading()
        Promise.all([
            TrophyService.getTrophies(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, trophyFilter),
            TrophyService.getTrophyFilterData()
        ])
            .finally(() => dismissLoading())
            .then(response => {
                if (response[0] && response[0].data) {
                    setTrophyData(response[0].data)
                }

                if (response[1] && response[1].data) {
                    setFilterData(response[1].data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);
        return () => {
            TrophyService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    const getTrophies = (pageNumber, pageSize, filter, sort) => {
        showLoading()
        if (!filter) {
            filter = { ...trophyFilter }
        }

        TrophyService.getTrophies(pageNumber, pageSize, sort, filter)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    setTrophyData(result.data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    const statusCodes = [
        {
            Value: Constant.StatusCode.Active,
            Description: t("common.active")
        },
        {
            Value: Constant.StatusCode.Inactive,
            Description: t("common.inactive")
        }
    ]

    const editTrophy = (record) => {
        if (record) {
            setSelectedObject(record)
            setVisibleAddUpdateTrophy(true)
        }
    }

    const viewTrophy = (record) => {
        if (record) {
            setSelectedObject(record)
            setVisibleTrophyDetail(true)
        }
    }

    const getActionButtonsInfo = (item) => {
        return [
            {
                Description: t("common.edit"),
                Icon: <EditIcon />,
                ColorClass: Constant.ColorClass.LightBlue,
                handleAction: editTrophy
            }
        ]
    }

    const getViewButtonsInfo = (item) => {
        return [
            {
                Description: t("common.view"),
                Icon: <EyeIcon />,
                ColorClass: Constant.ColorClass.LightBlue,
                handleAction: viewTrophy
            }
        ]
    }

    const exportTrophies = (fileType) => {
        showLoading()

        let rankings = trophyFilter.Rankings
        if (!rankings) {
            rankings = 10
        }

        TrophyService.getExportTrophies(selectedRowKeys, rankings, trophyFilter.DateFrom, trophyFilter.DateTo, trophyFilter.FinancialYearIds, fileType)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    if (fileType == Constant.FileType.Excel) {
                        const type = result.headers['content-type']
                        const blob = new Blob([result.data], { type: type })
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = `Trophies.xlsx`
                        link.click()
                    } else if (fileType == Constant.FileType.Csv) {
                        const type = result.headers['content-type']
                        const blob = new Blob([result.data], { type: type })
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = `Trophies.csv`
                        link.click()
                    }
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    const handleExport = (e) => {
        exportTrophies(e.key)
    };

    const exportItems = [
        {
            label: t("common.excel"),
            key: Constant.FileType.Excel
        },
        {
            label: t("common.csv"),
            key: Constant.FileType.Csv
        }
    ];
    const menuExport = {
        items: exportItems,
        onClick: handleExport
    };

    const isClubMember = () => {
        if ((currentPosition && currentPosition.PositionTypeId == Constant.PositionType.ClubMember) || history.location.pathname == Constant.Pages.ViewTrophy) {
            return true
        }
        return false
    }

    const canAccess = () => {
        if(currentPosition && (currentPosition.PositionTypeId != Constant.PositionType.ClubMember || history.location.pathname == Constant.Pages.ViewTrophy)){
            return true
        }

        return false
    }

    const columns = [
        {
            title: t('common.action'),
            render: (value, record) => {
                return <ActionButtonsCell btnList={getActionButtonsInfo(record)} data={record} />
            },
            align: "center",
            width: 90,
            hidden: isClubMember()
        },
        {
            title: t('trophy_management.trophy_name'),
            dataIndex: 'Name',
            key: 'Name',
            sorter: true,
            width: 130,
        },
        {
            title: t('trophy_management.trophy_type'),
            dataIndex: 'TrophyTypeName',
            key: 'TrophyTypeName',
            sorter: true,
            width: 150,
        },
        {
            title: t('trophy_management.rankings'),
            dataIndex: 'Rankings',
            key: 'Rankings',
            sorter: false,
            width: 80,
            render: (value, record) => (
                <div>{trophyFilter.Rankings > 0 ? trophyFilter.Rankings : "All"}</div>
            ),
        },
        {
            title: t('common.status'),
            dataIndex: 'StatusId',
            key: 'StatusId',
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.StatusId)}
                    description={CommonService.getStatusDescription(record.StatusId)} />
            ),
            sorter: true,
            width: 100,
        }
    ]

    const columnsMapping = columns.filter(c => !c.hidden).map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition
            }),
        };
    })

    const components = {
        body: {
            cell: EditableCell,
        }
    };

    const onTableChange = (pagination, filters, sorter, extra) => {
        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = defaultGridOption.sort;
        }

        getTrophies(pagination.current, pagination.pageSize, trophyFilter, sort)
        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        })
    }

    const showFilterLayout = () => {
        setVisibleFilter(true);
    }

    const closeFilterLayout = () => {
        formFilter.setFieldsValue(trophyFilter);
        setVisibleFilter(false);
    }

    const resetFilter = () => {
        formFilter.resetFields();
        setTrophyFilter({ StatusIds: Constant.StatusCode.Active });
    }

    const applyFilter = () => {
        setVisibleFilter(false);
        let filter = formFilter.getFieldsValue();
        setTrophyFilter(filter)
        setGridConfigOptionsValue({
            ...gridConfigOptions,
            pageNumber: 1
        })
        getTrophies(1, gridConfigOptions.pageSize, filter, gridConfigOptions.sort)
    }

    const addTrophy = () => {
        setSelectedObject({})
        setVisibleAddUpdateTrophy(true)

    }

    const deleteTrophies = () => {
        if (hasTrophySelected()) {
            confirm({
                title: t("common.confirm"),
                icon: <ExclamationCircleOutlined />,
                content: t("trophy_management.delete_trophy_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    showLoading()
                    TrophyService.deleteTrophies(selectedRowKeys)
                        .finally(() => dismissLoading())
                        .then(result => {
                            CommonService.presentToast('success', t("trophy_management.delete_successful"))
                            getTrophies(1, gridConfigOptions.pageSize, trophyFilter, gridConfigOptions.sort)
                            setGridConfigOptionsValue({
                                ...gridConfigOptions,
                                pageNumber: 1
                            })
                        })
                        .catch(error => CommonService.handleErrorResponse(error))
                },
                onCancel() {
                }

            });
        }
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys, newSelectedRows, info) => {
            if (newSelectedRows && trophyData && trophyData.ObjectList) {
                let tempList = trophyData.ObjectList.map(x => {
                    x.IsSelected = false
                    return x
                })
                for (let i = 0; i < newSelectedRows.length; i++) {
                    let trophy = tempList.find(p => p.Id == newSelectedRows[i].Id)
                    if (trophy) {
                        trophy.IsSelected = true;
                    }
                }

                if (newSelectedRows.length > 0 && info.type == "all") {
                    trophyData.selectAll = true
                } else {
                    trophyData.selectAll = false
                }

                trophyData.ObjectList = tempList

                setTrophyData({ ...trophyData })
            }
            setSelectedRowKeys(newSelectedRowKeys)
        },
        columnWidth: 100
    }

    const handleAddUpdateTrophy = (isReload) => {
        setVisibleAddUpdateTrophy(false)

        if (isReload) {
            getTrophies(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, trophyFilter, gridConfigOptions.sort)
        }
    }

    const handleCloseTrophyDetail = () => {
        setVisibleTrophyDetail(false)
    }

    const hasTrophySelected = () => {
        if (selectedRowKeys && selectedRowKeys.length > 0) {
            return true;
        }
        return false;
    }

    return (
        <>
            {
                canAccess() == true &&
                <>
                    <div id={Constant.FixItemsContainerId}>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                            <Title className='color-dark-blue' level={5}>{t("trophy_management.manage_trophy")}</Title>
                        </Row>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                            <Col flex={100} className="text-right">
                                <div>
                                    <Button onClick={showFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon />}>{t("common.filter")}</Button>
                                    <Dropdown className='m-l-10' menu={menuExport} trigger={['click']}>
                                        <Button className='btn-outline-blue btn-icon' icon={<DownloadOutlined />}>
                                            {t("common.export")}
                                        </Button>
                                    </Dropdown>
                                    {
                                        isClubMember() == false &&
                                        <>
                                            <Button className='m-l-10' danger onClick={deleteTrophies} disabled={!hasTrophySelected()} >{t("common.delete")}</Button>
                                            <Button className='btn-solid-dark-blue m-l-10' onClick={addTrophy}>{t("common.add_new")}</Button>
                                        </>
                                    }

                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Table
                                components={components}
                                rowClassName={() => 'editable-row'}
                                rowSelection={rowSelection}
                                {...GetTableConfigs(columnsMapping, trophyData?.ObjectList, trophyData?.TotalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                            />
                        </Col>
                    </Row>

                    <Drawer
                        title={t("common.filter")}
                        width={378}
                        onClose={closeFilterLayout}
                        open={visibleFilter}
                        closeIcon={false}
                        styles={{
                            body: {
                                paddingBottom: 80,
                            }
                        }}
                        extra={
                            <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                                <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                                <Button onClick={closeFilterLayout} icon={<CloseIcon />} type="link" />
                            </Space>
                        }
                        footer={
                            <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                                <Button onClick={closeFilterLayout}>{t("common.cancel")}</Button>
                                <Button onClick={applyFilter} type="primary">
                                    {t("common.apply")}
                                </Button>
                            </Space>
                        }>
                        <Form
                            layout="vertical"
                            {...layout}
                            form={formFilter}
                            name="formFilter"
                            key='formFilter'
                            initialValues={trophyFilter}
                        >
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="TrophyName"
                                        label={t("trophy_management.trophy_name")}
                                    >
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="TrophyTypeIds"
                                        label={t("trophy_management.trophy_type")}
                                    >
                                        <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={filterData && filterData.TrophyTypes ? filterData.TrophyTypes.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}>
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="DateFrom"
                                        label={t("fishing_activity.date_from")}
                                    >
                                        <DatePicker format={Constant.DateFormat} allowClear style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="DateTo"
                                        label={t("fishing_activity.date_to")}
                                    >
                                        <DatePicker format={Constant.DateFormat} allowClear style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="FinancialYearIds"
                                        label={t("fishing_activity.financial_year")}
                                    >
                                        <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={filterData && filterData.FinancialYears ? filterData.FinancialYears.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}>
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="Rankings"
                                        label={t("trophy_management.rankings")}
                                    >
                                        <EnhancedSelect allowClear={true} defaultValue={10} showSearch optionFilterProp="children"
                                            filterOption={(input, option) => (option?.children ?? '').toString().toLowerCase().includes(input.toLowerCase())}>
                                            {
                                                filterData && filterData.Rankings && filterData.Rankings.map((n, index) => <Option key={index} value={n}>{n != -1 ? n : 'All'}</Option>)
                                            }
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="StatusIds"
                                        label={t("common.status")}
                                    >
                                        <EnhancedSelect mode="multiple" allowClear={true} defaultValue={1}>
                                            {
                                                statusCodes && statusCodes.map((n, index) => <Option key={index} value={n.Value}>{n.Description}</Option>)
                                            }
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Drawer>
                    {
                        visibleAddUpdateTrophy &&
                        <AddUpdateTrophy visible={visibleAddUpdateTrophy} handleCancel={handleAddUpdateTrophy} objectData={selectedObject} trophySelectionData={trophySelectionData}></AddUpdateTrophy>
                    }
                    {
                        visibleTrophyDetail &&
                        <TrophyDetail visible={visibleTrophyDetail} handleCancel={handleCloseTrophyDetail} objectData={selectedObject} rankings={trophyFilter.Rankings} dateFrom={trophyFilter.DateFrom} dateTo={trophyFilter.DateTo} financialYearIds={trophyFilter.FinancialYearIds}></TrophyDetail>
                    }
                </>
            }

            {
                canAccess() == false &&
                <>
                    <Table
                        style={{ visibility: 'hidden', height: '0px' }}
                        components={components}
                        rowClassName={() => 'editable-row'}
                        rowSelection={rowSelection}
                        {...GetTableConfigs(columnsMapping, trophyData?.ObjectList, trophyData?.TotalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                    />
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_access")} />
                </>
            }
        </>
    )
}

export default TrophyManagement;
import React, { useState, useEffect } from 'react';
import { Constant, CommonService, MembershipService } from '../../../services/services';
import { useTranslation } from 'react-i18next';
import { GetTableConfigs, StatusCell, ActionButtonsCell, EditableCell } from '../../../components/customTable/customTable'
import LoadingContext from '../../../components/loading/loadingContext'
import { FilterAltIcon, CloseIcon } from '../../../components/icons/icons';
import { Row, Col, Table, Button, Checkbox, Modal, Form, Drawer, Space, Input, Select, InputNumber, Typography, Dropdown, Empty } from 'antd';
import { ExclamationCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { useGlobalState } from './../../../utilities/globalState'
import AddUpdateBoat from './../addUpdateBoat/addUpdateBoat'
import EnhancedSelect from '../../../components/enhancedSelect/enhancedSelect';

import PubSub from 'pubsub-js'

const { confirm } = Modal;
const { Option } = Select;
const { Title } = Typography;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const defaultGridOption = {
    pageNumber: 1,
    pageSize: CommonService.getPageSize(),
    sort: "BoatNo-ascend"
}

const BoatManagement = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const emptyTableData = {
        ObjectList: [],
        TotalItems: 0
    }
    const [boatData, setBoatData] = useState(emptyTableData)
    const [boatCategories, setBoatCategories] = useState([])
    const [fuelTypes, setFuelTypes] = useState([])
    const [selectedObject, setSelectedObject] = useState();
    const [gridConfigOptions, setGridConfigOptionsValue] = useState(defaultGridOption)
    const [currentPosition] = useGlobalState('currentPosition');
    const [visibleFilter, setVisibleFilter] = useState(false);
    const [formFilter] = Form.useForm();
    const [boatFilter, setBoatFilter] = useState({});
    const [updateBoats, setUpdateBoats] = useState([])
    const [visibleAddUpdateBoat, setVisibleAddUpdateBoat] = useState(false);

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
        }, 100)

        showLoading()
        Promise.all([
            MembershipService.getBoats(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, boatFilter),
            MembershipService.getBoatCategories(),
            MembershipService.getFuelTypes()
        ])
            .finally(() => dismissLoading())
            .then(response => {
                if (response[0] && response[0].data) {
                    setBoatData(response[0].data)
                }

                if (response[1] && response[1].data) {
                    setBoatCategories(response[1].data)
                }

                if (response[2] && response[2].data) {
                    setFuelTypes(response[2].data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);

        return () => {
            MembershipService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    const getBoats = (pageNumber, pageSize, filter, sort) => {
        showLoading()
        if (!filter) {
            filter = { ...boatFilter }
        }

        MembershipService.getBoats(pageNumber, pageSize, sort, filter)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    setBoatData(result.data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    const SelectedBoatChange = (value, record) => {
        if (record && boatData && boatData.ObjectList) {
            let tempList = [...boatData.ObjectList]
            let boat = tempList.find(p => p.Id == record.Id)
            if (boat) {
                boat.IsSelected = value;
            }

            boatData.ObjectList = tempList

            setBoatData({ ...boatData })
        }
    }

    const skipperFinanceChecks = [
        {
            Value: 1,
            Description: t("common.yes")
        },
        {
            Value: 2,
            Description: t("common.no")
        }
    ]

    const statusCodes = [
        {
            Value: Constant.StatusCode.Active,
            Description: t("common.active")
        },
        {
            Value: Constant.StatusCode.Inactive,
            Description: t("common.inactive")
        }
    ]

    const columns = [
        {
            key: 'Id',
            width: 50,
            sorter: false,
            render: (value, record) => {
                return (
                    <Checkbox checked={record.IsSelected} onChange={e => SelectedBoatChange(e.target.checked, record)} />
                )
            }
        },
        {
            title: t('boat.boat_no'),
            dataIndex: 'BoatNo',
            key: 'BoatNo',
            sorter: true,
            width: 130,
            defaultSortOrder: 'ascend'
        },
        {
            title: t('boat.boat_name'),
            dataIndex: 'Name',
            key: 'Name',
            sorter: true,
            width: 150
        },
        {
            title: t('boat.rego'),
            dataIndex: 'RegoNo',
            key: 'RegoNo',
            sorter: true,
            width: 130
        },
        {
            title: t('boat.length'),
            dataIndex: 'Length',
            key: 'Length',
            sorter: true,
            width: 150
        },
        {
            title: t('boat.boat_category'),
            dataIndex: 'BoatCategoryId',
            key: 'BoatCategoryId',
            render: (value, record) => {
                if (record.BoatCategoryId) {
                    let category = boatCategories.find(c => c.Id == record.BoatCategoryId)
                    if (category) {
                        return category.Name
                    }
                }
                return null
            },
            sorter: true,
            width: 145
        },
        {
            title: t('boat.skipper_1'),
            dataIndex: 'Skipper1',
            key: 'Skipper1',
            render: (value, record) => {
                if (record.Skipper1) {
                    return record.Skipper1.FirstName + " " + record.Skipper1.LastName
                }
                return null
            },
            sorter: true,
            width: 145
        },
        {
            title: t('boat.skipper_2'),
            dataIndex: 'Skipper2',
            key: 'Skipper2',
            render: (value, record) => {
                if (record.Skipper2) {
                    return record.Skipper2.FirstName + " " + record.Skipper2.LastName
                }
                return null
            },
            sorter: true,
            width: 145
        },
        {
            title: t('boat.fuel_type'),
            dataIndex: 'FuelTypeId',
            key: 'FuelTypeId',
            render: (value, record) => {
                if (record.FuelTypeId) {
                    let fuelType = fuelTypes.find(c => c.Id == record.FuelTypeId)
                    if (fuelType) {
                        return fuelType.Name
                    }
                }
                return null
            },
            sorter: true,
            width: 145
        },
        {
            title: t('boat.skipper_financial_check'),
            dataIndex: 'IsSkipperFinancialCheck',
            key: 'IsSkipperFinancialCheck',
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getBooleanColorClass(record.IsSkipperFinancialCheck)}
                    description={CommonService.getBooleanDescription(record.IsSkipperFinancialCheck)} />
            ),
            sorter: true,
            align: 'center',
            width: 145
        },
        {
            title: t('boat.make'),
            dataIndex: 'Make',
            key: 'Make',
            sorter: true,
            width: 100
        },
        {
            title: t('boat.model'),
            dataIndex: 'Model',
            key: 'Model',
            sorter: true,
            width: 100
        },
        {
            title: t('common.status'),
            dataIndex: 'StatusId',
            key: 'StatusId',
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.StatusId)}
                    description={CommonService.getStatusDescription(record.StatusId)} />
            ),
            sorter: true,
            width: 100
        }
    ]

    const columnsMapping = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition
            }),
        };
    })

    const components = {
        body: {
            cell: EditableCell,
        }
    };

    const onTableChange = (pagination, filters, sorter, extra) => {
        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = defaultGridOption.sort;
        }

        getBoats(pagination.current, pagination.pageSize, boatFilter, sort)
        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        })
    }

    const hasBoatSelected = () => {
        if (boatData && boatData.ObjectList && boatData.ObjectList.length > 0) {
            let selected = boatData.ObjectList.filter(p => p.IsSelected)
            if (selected && selected.length > 0) {
                return true
            }
        }
        return false
    }

    const showFilterLayout = () => {
        setVisibleFilter(true);
    }

    const closeFilterLayout = () => {
        formFilter.setFieldsValue(boatFilter);
        setVisibleFilter(false);
    }

    const resetFilter = () => {
        formFilter.resetFields();
        setBoatFilter({});
    }

    const applyFilter = () => {
        setVisibleFilter(false);
        let filter = formFilter.getFieldsValue();
        setBoatFilter(filter)
        setGridConfigOptionsValue({
            ...gridConfigOptions,
            pageNumber: 1
        })
        getBoats(1, gridConfigOptions.pageSize, filter, gridConfigOptions.sort)
    }

    const handleAddUpdateBoats = (isReload) => {
        setVisibleAddUpdateBoat(false)
        if (isReload) {
            getBoats(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, boatFilter, gridConfigOptions.sort)
        }
    }

    const addBoats = () => {
        setUpdateBoats([])
        setVisibleAddUpdateBoat(true)
    }

    const editBoats = () => {
        if (hasBoatSelected()) {
            let selected = boatData.ObjectList.filter(p => p.IsSelected)
            setUpdateBoats(selected)
            setVisibleAddUpdateBoat(true)
        }
    }

    const deleteBoats = () => {
        if (hasBoatSelected()) {
            confirm({
                title: t("common.confirm"),
                icon: <ExclamationCircleOutlined />,
                content: (<>{t("boat.delete_boat_confirm")} <br /> <i>{t("boat.delete_boat_instruction")}</i></>),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    let selected = boatData.ObjectList.filter(p => p.IsSelected)
                    let ids = selected.map((n, index) => n.Id)
                    showLoading()
                    MembershipService.deleteBoats(ids)
                        .finally(() => dismissLoading())
                        .then(result => {
                            getBoats(1, gridConfigOptions.pageSize, boatFilter, gridConfigOptions.sort)
                            setGridConfigOptionsValue({
                                ...gridConfigOptions,
                                pageNumber: 1
                            })
                        })
                        .catch(error => CommonService.handleErrorResponse(error))
                },
                onCancel() {
                }

            });
        }
    }

    const exportItems = [
        {
            label: t("common.excel"),
            key: Constant.FileType.Excel
        },
        {
            label: t("common.csv"),
            key: Constant.FileType.Csv
        }
    ];

    const handleExport = (e) => {
        exportBoats(e.key)
    };

    const menuExport = {
        items: exportItems,
        onClick: handleExport
    };

    const exportBoats = (fileType) => {
        showLoading()
        MembershipService.getBoatManagementExport(gridConfigOptions.sort, boatFilter, fileType)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    let fileName = 'Boat List'
                    if (fileType == Constant.FileType.Excel) {
                        const type = result.headers['content-type']
                        const blob = new Blob([result.data], { type: type })
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = `${fileName}.xlsx`
                        link.click()
                    } else if (fileType == Constant.FileType.Csv) {
                        const type = result.headers['content-type']
                        const blob = new Blob([result.data], { type: type })
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = `${fileName}.csv`
                        link.click()
                    }
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }


    return (
        <>
            {currentPosition && currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee &&
                <div>
                    <div id={Constant.FixItemsContainerId}>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                            <Title className='color-dark-blue' level={5}>{t("boat.manage_boats")}</Title>
                        </Row>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                            <Col flex={100} className="text-right">
                                <div>
                                    <Button onClick={showFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon />}>{t("common.filter")}</Button>
                                    <Dropdown className='m-l-10' menu={menuExport} trigger={['click']}>
                                        <Button className='btn-outline-blue btn-icon' icon={<DownloadOutlined />}>
                                            {t("common.export")}
                                        </Button>
                                    </Dropdown>
                                    <Button className='m-l-10' danger disabled={!hasBoatSelected()} onClick={deleteBoats} >{t("common.delete")}</Button>
                                    <Button className='btn-outline-dark-blue m-l-10' disabled={!hasBoatSelected()} onClick={editBoats}>{t("common.edit")}</Button>
                                    <Button className='btn-solid-dark-blue m-l-10' onClick={addBoats}>{t("common.add_new")}</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Table
                                components={components}
                                rowClassName={() => 'editable-row'}
                                {...GetTableConfigs(columnsMapping, boatData?.ObjectList, boatData?.TotalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                            />
                        </Col>
                    </Row>

                    <Drawer
                        title={t("common.filter")}
                        width={378}
                        onClose={closeFilterLayout}
                        open={visibleFilter}
                        closeIcon={false}
                        styles={{
                            body: {
                                paddingBottom: 80,
                            }
                        }}
                        extra={
                            <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                                <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                                <Button onClick={closeFilterLayout} icon={<CloseIcon />} type="link" />
                            </Space>
                        }
                        footer={
                            <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                                <Button onClick={closeFilterLayout}>{t("common.cancel")}</Button>
                                <Button onClick={applyFilter} type="primary">
                                    {t("common.apply")}
                                </Button>
                            </Space>
                        }>
                        <Form
                            layout="vertical"
                            {...layout}
                            form={formFilter}
                            name="formFilter"
                            key='formFilter'
                        >
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="BoatNo"
                                        label={t("boat.boat_no")}
                                    >
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="Name"
                                        label={t("boat.boat_name")}
                                    >
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="RegoNo"
                                        label={t("boat.rego")}
                                    >
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="Length"
                                        label={t("boat.length")}
                                    >
                                        <InputNumber style={{ width: '100%' }} allowClear precision={2} min={0} max={9999} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="BoatCategoryIds"
                                        label={t('boat.boat_category')}
                                    >
                                        <EnhancedSelect mode="multiple" allowClear={true}
                                            showSearch optionFilterProp="children"
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                        >
                                            {
                                                boatCategories && boatCategories.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                            }
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="Skipper1"
                                        label={t("boat.skipper_1")}
                                    >
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="Skipper2"
                                        label={t("boat.skipper_2")}
                                    >
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="FuelTypeIds"
                                        label={t("boat.fuel_type")}
                                    >
                                        <EnhancedSelect mode="multiple" allowClear={true}
                                            showSearch optionFilterProp="children"
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                        >
                                            {
                                                fuelTypes && fuelTypes.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                            }
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="Make"
                                        label={t("boat.make")}
                                    >
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="Model"
                                        label={t("boat.model")}
                                    >
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="SkipperFinancialChecks"
                                        label={t("boat.skipper_financial_check")}
                                    >
                                        <EnhancedSelect mode="multiple" allowClear={true}
                                            showSearch optionFilterProp="children"
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                        >
                                            {
                                                skipperFinanceChecks.map((n, index) => <Option key={index} value={n.Value}>{n.Description}</Option>)
                                            }
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="StatusIds"
                                        label={t("common.status")}
                                    >
                                        <EnhancedSelect mode="multiple" allowClear={true}
                                            showSearch optionFilterProp="children"
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                        >
                                            {
                                                statusCodes && statusCodes.map((n, index) => <Option key={index} value={n.Value}>{n.Description}</Option>)
                                            }
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Drawer>

                    {
                        visibleAddUpdateBoat &&
                        <AddUpdateBoat visible={visibleAddUpdateBoat} handleCancel={handleAddUpdateBoats} boatCategories={boatCategories} fuelTypes={fuelTypes} objectData={updateBoats}></AddUpdateBoat>
                    }
                </div>
            }
            {currentPosition && currentPosition.PositionTypeId != Constant.PositionType.ClubCommittee &&
                <>
                    <Table
                        style={{ visibility: 'hidden', height: '0px' }}
                        components={components}
                        rowClassName={() => 'editable-row'}
                        {...GetTableConfigs(columnsMapping, boatData?.ObjectList, boatData?.TotalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                    />
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_access")} />
                </>
            }
        </>
    )
}

export default BoatManagement;
import { notification } from 'antd';
import i18n from '../i18n';
import { StorageService, Constant } from "./services"

export const CommonService = {
    presentToast: function (type, message) {
        notification[type]({
            message: 'Notification',
            description: message,

        });
    },
    handleErrorResponse: function (error) {
        if (error.message === 'Operation canceled.') {
            return
        }
        if (!error.response || error.response.status === 500) {
            this.presentToast('error', i18n.t('common.common_server_error'))
            return
        }
        if (error.response.status === 400) {
            if (error.response.data.errors) {
                let msg = ""
                for (var prop in error.response.data.errors) {
                    msg += error.response.data.errors[prop][0] + ";"
                }
                this.presentToast('error', msg)
            } else if (error.response.data.error_description) {
                this.presentToast('error', error.response.data.error_description)
            } else if (error.response.data.Value) {
                this.presentToast('error', error.response.data.Value)
            }
            else {
                this.presentToast('error', error.response.data)
            }
        }
    },
    getSortString: function (sortObject) {
        if (!sortObject || !sortObject.column)
            return ''
        return `${sortObject.field}-${sortObject.order}`
    },
    getDateString: function (date) {
        if (date) {
            date = new Date(date)
            let day = date.getDate()
            if (day.toString().length == 1) {
                day = "0" + day
            }
            let month = date.getMonth() + 1
            if (month.toString().length == 1) {
                month = "0" + month
            }
            let value = day + '/' + month + '/' + date.getFullYear()
            return value
        }
        return ''
    },
    getFirstCharUsername: function () {
        let username = this.getUsernameLogin()
        if (username)
            return username.substring(0, 1)
        return ""
    },
    getUsernameLogin: function () {
        let authData = StorageService.getAuthData()
        if (authData)
            return authData.name
        return ""
    },
    getStatusColorClass: function (value) {
        if (value == Constant.StatusCode.Active ||
            value == true)
            return Constant.ColorClass.LightGreen
        return Constant.ColorClass.LightRed
    },
    getRevertStatusColorClass: function (value) {
        if (value == Constant.StatusCode.Active ||
            value == true)
            return Constant.ColorClass.LightRed
        return Constant.ColorClass.LightGreen
    },
    getStatusDescription: function (value, showYesNo, activeText = "", inactiveText = "") {
        if (value == Constant.StatusCode.Active ||
            value == true)
            return showYesNo ? i18n.t("common.yes") : (activeText ? activeText : i18n.t("common.active"))
        return showYesNo ? i18n.t("common.no") : (inactiveText ? inactiveText : i18n.t("common.inactive"))
    },
    getBooleanColorClass: function (value) {
        if (value)
            return Constant.ColorClass.LightGreen
        return Constant.ColorClass.LightRed
    },
    getBooleanDescription: function (value) {
        if (value)
            return i18n.t("common.yes")
        return i18n.t("common.no")
    },
    calculateTableBodyMaxHeight: function (fixItemsConstainerId = Constant.FixItemsContainerId, customOffset = 0, tableFooterHeight = 45) {
        let tableHeaderHeight = 0, fixItemsContainerHeight = 0
        let headingElement = document.getElementsByClassName("ant-table-header");
        if (headingElement && headingElement.length > 0) {
            tableHeaderHeight = headingElement[0].offsetHeight
        }

        let fixItemsElement = document.getElementById(fixItemsConstainerId)
        if (fixItemsElement) {
            fixItemsContainerHeight = fixItemsElement.offsetHeight + customOffset
        }
        return tableHeaderHeight + tableFooterHeight + fixItemsContainerHeight + 60 + "px"
    },
    getAllSubMenus: function (menus, result) {
        if (menus && menus.length > 0) {
            menus.forEach(item => {
                result.push(item)
                this.getAllSubMenus(item.SubMenus, result)
            })
        }
    },
    getAllParentMenus: function (allMenus, menu, result) {
        if (allMenus && allMenus.length > 0) {
            if (menu) {
                result.push(menu)
                if (menu.ParentId) {
                    let parrent = allMenus.find(p => p.Id == menu.ParentId)
                    this.getAllParentMenus(allMenus, parrent, result)
                }
            }
        }
    },
    getDateString: function (date) {
        if (date) {
            let dateFormat = new Date(date)
            var d = dateFormat.getDate();
            var m = dateFormat.getMonth() + 1; //Month from 0 to 11
            var y = dateFormat.getFullYear();
            return (d <= 9 ? '0' + d : d) + '/' + (m <= 9 ? '0' + m : m) + '/' + y;
        }
        return ""
    },
    getImgBase64: (img, callback) => {
        const isValidImgType = (img.type === 'image/jpeg' || img.type === 'image/png' || img.type === 'image/gif');
        if (!isValidImgType) {
            CommonService.presentToast('warning', i18n.t("entity_detail.upload_image_message"))
            return false;
        }
        const isLimit = img.size / 1024 / 1024 < 5;
        if (!isLimit) {
            CommonService.presentToast('warning', i18n.t("entity_detail.limit_file_message"))
            return false;
        }
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    },
    getFileBase64: (file, callback) => {
        const isLimit = file.size / 1024 / 1024 < 20;
        if (!isLimit) {
            CommonService.presentToast('warning', i18n.t("entity_detail.limit_file_message"))
            return false;
        }
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(file);
    },
    // Shallow comparison function
    shallowCompare: (obj1, obj2) => {
        // If the objects are the same reference, return true
        if (obj1 === obj2) return true;
        // If either object is null or undefined, return false
        if (obj1 == null || obj2 == null) return false;
        // Get the keys of both objects
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
        // If the number of keys is different, return false
        if (keys1.length !== keys2.length) return false;
        // Loop through the keys of the first object
        for (let key of keys1) {
            // If the second object doesn't have the same key, return false
            if (!obj2.hasOwnProperty(key)) return false;
            // If the values of the key are not equal, return false
            if (obj1[key] !== obj2[key]) return false;
        }
        // If no false condition is met, return true
        return true;
    },
    generatePassword: function () {
        var length = 8;
        var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        var retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    },
    getPageSize: function () {
        var authData = StorageService.getAuthData()
        if(authData)
            return authData.pageSize
        return Constant.PageSize
    },
    verifyEmail: function (email) {
        if (!email) {
            return false
        }

        let emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (emailReg.test(email) === false) {
            return false
        }
        return true
    },
    verifyTagNumber: function(tagNumber) {
        if (!tagNumber || tagNumber.length < 7)
            return false;
        const fistCharacter = tagNumber.slice(0, -6).replace(/_/g, '');
        if (!fistCharacter || fistCharacter.length != 1)
            return false;

        var tagNumbers = ["A", "a", "B", "b", "S", "s"];
        if (!tagNumbers.includes(fistCharacter))
            return false;
        
        const lastSixCharacter = tagNumber.slice(-6).replace(/_/g, '');
        if (!lastSixCharacter || lastSixCharacter.length != 6)
            return false;

        return true;
    }
}
import React, { useEffect, useState } from 'react';
import './stateDefaults.scss';
import { useTranslation } from 'react-i18next';
import {
    Constant,
    CommonService,
    SettingService
} from '../../../services/services'
import { Table, Button, Row, Col, Form, Input, Modal, Breadcrumb, Drawer, Space, Select, Tabs, Avatar, Flex } from 'antd';
import { GetTableConfigs, StatusCell, YesNoCell } from '../../../components/customTable/customTable'
import { FilterAltIcon, CloseIcon } from '../../../components/icons/icons'
import LoadingContext from '../../../components/loading/loadingContext'
import EnhancedSelect from '../../../components/enhancedSelect/enhancedSelect';
import PubSub from 'pubsub-js';
import { useGlobalState } from '../../../utilities/globalState'

const { TabPane } = Tabs;
const { Option } = Select;
const _ = require("lodash");

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const StateDefaults = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext);
    const [currentPosition] = useGlobalState('currentPosition');
    const [entityLogo, setEntityLogo] = useState("");

    // Filter Data
    const [statuses, setStatuses] = useState([]);

    // 1. State Point Score Zones
    const [pointScoreZonesForm] = Form.useForm();
    const [pointScoreZones, setPointScoreZones] = useState([]);
    const [totalPointScoreZones, setTotalPointScoreZones] = useState(0);
    const [openPointScoreZonesFilter, setOpenPointScoreZonesFilter] = useState(false);
    const [searchPointScoreZone, setSearchPointScoreZone] = useState({ StatusIds: [Constant.StatusCode.Active] });
    const [pointScoreZonesGridConfigOptions, setPointScoreZonesGridConfigOptionsValue] = useState({
        pageNumber: 1,
        pageSize: CommonService.getPageSize(),
        sort: "Name-ascend",
    });

    // 2. Special Events
    const [specialEventsForm] = Form.useForm();
    const [specialEvents, setSpecialEvents] = useState([]);
    const [totalSpecialEvents, setTotalSpecialEvents] = useState(0);
    const [openSpecialEventsFilter, setOpenSpecialEventsFilter] = useState(false);
    const [searchSpecialEvent, setSearchSpecialEvent] = useState({ StatusIds: [Constant.StatusCode.Active] });
    const [specialEventsGridConfigOptions, setSpecialEventsGridConfigOptionsValue] = useState({
        pageNumber: 1,
        pageSize: CommonService.getPageSize(),
        sort: "Name-ascend",
    });


    // 1. State Point Score Zones
    const searchPointScoreZones = (pageNumber, pageSize, sort, query, isShowLoading = false) => {
        if (isShowLoading) {
            showLoading();
        }

        let params = { ...query };
        if (!params) params = { StatusIds: [Constant.StatusCode.Active] };

        SettingService.getPointScoreZonesByParentId(pageNumber, pageSize, sort, params)
            .finally(() => {
                if (isShowLoading) {
                    dismissLoading();
                }
            })
            .then(result => {
                if (result.data) {
                    setPointScoreZones(result.data.ObjectList);
                    setTotalPointScoreZones(result.data.TotalItems);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const pointScoreZonesColumns = [
        {
            title: t('point_score_zone.zone_name'),
            dataIndex: 'Name',
            key: 'Name',
            sorter: true,
            defaultSortOrder: 'ascend',
            width: 100,
        },
        {
            title: t('point_score_zone.zone_description'),
            dataIndex: 'Description',
            key: 'Description',
            sorter: true,
            width: 160,
        },
        {
            title: t('common.status'),
            dataIndex: 'StatusId',
            key: 'StatusId',
            sorter: false,
            align: "center",
            width: 35,
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.StatusId)}
                    description={CommonService.getStatusDescription(record.StatusId, false)} />
            )
        }
    ]

    const onPointScoreZonesTableChange = (pagination, filters, sorter, extra) => {
        let data = pointScoreZonesForm.getFieldsValue();
        if (!data || (data && !data.StatusIds)) {
            data.StatusIds = [Constant.StatusCode.Active];
        }

        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = pointScoreZonesGridConfigOptions.sort;
        }
        searchPointScoreZones(pagination.current, pagination.pageSize, sort, data);

        setPointScoreZonesGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        });
    }

    const showPointScoreZonesFilterLayout = () => {
        setOpenPointScoreZonesFilter(true);
    }

    const closePointScoreZonesFilterLayout = () => {
        pointScoreZonesForm.setFieldsValue(searchPointScoreZone);
        setOpenPointScoreZonesFilter(false);
    }

    const refreshPointScoreZonesData = (isShowLoading = false) => {
        let data = pointScoreZonesForm.getFieldsValue();
        if (!data || (data && !data.StatusIds)) {
            data.StatusIds = [Constant.StatusCode.Active];
        }
        setSearchPointScoreZone(data);

        searchPointScoreZones(1, pointScoreZonesGridConfigOptions.pageSize, pointScoreZonesGridConfigOptions.sort, data, isShowLoading);

        setPointScoreZonesGridConfigOptionsValue(
            {
                ...pointScoreZonesGridConfigOptions,
                pageNumber: 1
            }
        );
    }

    const resetPointScoreZonesFilter = () => {
        pointScoreZonesForm.resetFields();
        setSearchPointScoreZone({ StatusIds: [Constant.StatusCode.Active] });
    }

    const applyPointScoreZonesFilter = () => {
        refreshPointScoreZonesData(true);
        setOpenPointScoreZonesFilter(false);
    }


    // 3. Special Events
    const searchSpecialEvents = (pageNumber, pageSize, sort, query, isShowLoading = false) => {
        if (isShowLoading) {
            showLoading();
        }

        let params = { ...query };
        if (!params) params = { StatusIds: [Constant.StatusCode.Active] };

        SettingService.getSpecialEventsByParentId(pageNumber, pageSize, sort, params)
            .finally(() => {
                if (isShowLoading) {
                    dismissLoading();
                }
            })
            .then(result => {
                if (result.data) {
                    setSpecialEvents(result.data.ObjectList);
                    setTotalSpecialEvents(result.data.TotalItems);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const specialEventsColumns = [
        {
            title: t('special_event.event_name'),
            dataIndex: 'Name',
            key: 'Name',
            sorter: true,
            defaultSortOrder: 'ascend',
            width: 100,
        },
        {
            title: t('special_event.event_description'),
            dataIndex: 'Description',
            key: 'Description',
            sorter: true,
            width: 160,
        },
        {
            title: t('common.status'),
            dataIndex: 'StatusId',
            key: 'StatusId',
            sorter: false,
            align: "center",
            width: 35,
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.StatusId)}
                    description={CommonService.getStatusDescription(record.StatusId, false)} />
            )
        }
    ]

    const onSpecialEventsTableChange = (pagination, filters, sorter, extra) => {
        let data = specialEventsForm.getFieldsValue();
        if (!data || (data && !data.StatusIds)) {
            data.StatusIds = [Constant.StatusCode.Active];
        }

        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = specialEventsGridConfigOptions.sort;
        }
        searchSpecialEvents(pagination.current, pagination.pageSize, sort, data);

        setSpecialEventsGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        });
    }

    const showSpecialEventsFilterLayout = () => {
        setOpenSpecialEventsFilter(true);
    }

    const closeSpecialEventsFilterLayout = () => {
        specialEventsForm.setFieldsValue(searchSpecialEvent);
        setOpenSpecialEventsFilter(false);
    }

    const refreshSpecialEventsData = (isShowLoading = false) => {
        let data = specialEventsForm.getFieldsValue();
        if (!data || (data && !data.StatusIds)) {
            data.StatusIds = [Constant.StatusCode.Active];
        }
        setSearchSpecialEvent(data);

        searchSpecialEvents(1, specialEventsGridConfigOptions.pageSize, specialEventsGridConfigOptions.sort, data, isShowLoading);

        setSpecialEventsGridConfigOptionsValue(
            {
                ...specialEventsGridConfigOptions,
                pageNumber: 1
            }
        );
    }

    const resetSpecialEventsFilter = () => {
        specialEventsForm.resetFields();
        setSearchSpecialEvent({ StatusIds: [Constant.StatusCode.Active] });
    }

    const applySpecialEventsFilter = () => {
        refreshSpecialEventsData(true);
        setOpenSpecialEventsFilter(false);
    }

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight())
        }, 100)

        showLoading();
        Promise.all([
            SettingService.getPointScoreZonesByParentId(pointScoreZonesGridConfigOptions.pageNumber, pointScoreZonesGridConfigOptions.pageSize, pointScoreZonesGridConfigOptions.sort, { StatusIds: [Constant.StatusCode.Active] }),
            SettingService.getSpecialEventsByParentId(specialEventsGridConfigOptions.pageNumber, specialEventsGridConfigOptions.pageSize, specialEventsGridConfigOptions.sort, { StatusIds: [Constant.StatusCode.Active] }),

            SettingService.getStatusCodes(),
            SettingService.getEntityLogoByParentId()
        ])
            .finally(() => {
                dismissLoading();
            })
            .then((response) => {
                if (response[0].data) {
                    setPointScoreZones(response[0].data.ObjectList);
                    setTotalPointScoreZones(response[0].data.TotalItems);
                }

                if (response[1].data) {
                    setSpecialEvents(response[1].data.ObjectList);
                    setTotalSpecialEvents(response[1].data.TotalItems);
                }

                if (response[2].data) {
                    setStatuses(response[2].data);
                }

                if (response[3].data) {
                    setEntityLogo(response[3].data);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);

        return () => {
            SettingService.cancelRequest();
            clearTimeout(timeoutFn);
        }
    }, [])

    return (
        <div className="state-defaults">
            <Breadcrumb>
                <Breadcrumb.Item>{t("menu.state_information_tables_for_day_logs_activity_entries")}</Breadcrumb.Item>
            </Breadcrumb>
            <Row gutter={[Constant.SpaceConstant.HorizontalGutter, Constant.SpaceConstant.VerticalGutter]}>
                <Col xs={{ span: 18 }}>
                    <Tabs defaultActiveKey="1">
                        <TabPane className='point-score-zone-management' tab={t("menu.point_score_zones")} key="state_point_score_zones">
                            <Row className="fix-item-container">
                                <Col xs={{ span: 24 }} className="text-right extra-btn-m-t">
                                    <div className="m-t-10">
                                        <Button onClick={showPointScoreZonesFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon className="anticon anticon-search" />}>{t("common.filter")}</Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="m-t-10 section-table" gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                                <Col xs={{ span: 24 }}>
                                    <Table
                                        {...GetTableConfigs(pointScoreZonesColumns, pointScoreZones, totalPointScoreZones, "Id", onPointScoreZonesTableChange, pointScoreZonesGridConfigOptions.pageNumber)}
                                    />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane className='special-event-management' tab={t("menu.special_events")} key="state_special_events">
                            <Row className="fix-item-container">
                                <Col xs={{ span: 24 }} className="text-right extra-btn-m-t">
                                    <div className="m-t-10">
                                        <Button onClick={showSpecialEventsFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon className="anticon anticon-search" />}>{t("common.filter")}</Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="m-t-10 section-table" gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                                <Col xs={{ span: 24 }}>
                                    <Table
                                        {...GetTableConfigs(specialEventsColumns, specialEvents, totalSpecialEvents, "Id", onSpecialEventsTableChange, specialEventsGridConfigOptions.pageNumber)}
                                    />
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                </Col>
                <Col xs={{ span: 6 }}>
                    <Row className="fix-item-container">
                        <Col xs={{ span: 24 }} className="text-center extra-btn-m-t">
                            <Space size='middle' className='right-container--left-component'>
                                {
                                    entityLogo &&
                                    <Avatar className='custom-state-logo' src={<img src={entityLogo} alt="avatar" />} />
                                }
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>


            {/* State Point Score Zones  */}
            <Drawer
                title={t("common.filter")}
                width={378}
                onClose={closePointScoreZonesFilterLayout}
                open={openPointScoreZonesFilter}
                closeIcon={false}
                styles={{
                    body: {
                        paddingBottom: 80,
                    }
                }}
                extra={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button type="link" onClick={resetPointScoreZonesFilter}>{t("common.reset_filter")}</Button>
                        <Button onClick={closePointScoreZonesFilterLayout} icon={<CloseIcon />} type="link" />
                    </Space>
                }
                footer={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button onClick={closePointScoreZonesFilterLayout}>{t("common.cancel")}</Button>
                        <Button onClick={applyPointScoreZonesFilter} type="primary">
                            {t("common.apply")}
                        </Button>
                    </Space>
                }>
                <Form
                    layout="vertical"
                    {...layout}
                    form={pointScoreZonesForm}
                    name="pointScoreZonesForm"
                    key='pointScoreZonesForm'
                    initialValues={{ StatusIds: [Constant.StatusCode.Active] }}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="Name"
                                label={t("point_score_zone.zone_name")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="Description"
                                label={t("point_score_zone.zone_description")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="StatusIds"
                                label={t("common.status")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={statuses ? statuses.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>

            {/* Special Events  */}
            <Drawer
                title={t("common.filter")}
                width={378}
                onClose={closeSpecialEventsFilterLayout}
                open={openSpecialEventsFilter}
                closeIcon={false}
                styles={{
                    body: {
                        paddingBottom: 80,
                    }
                }}
                extra={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button type="link" onClick={resetSpecialEventsFilter}>{t("common.reset_filter")}</Button>
                        <Button onClick={closeSpecialEventsFilterLayout} icon={<CloseIcon />} type="link" />
                    </Space>
                }
                footer={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button onClick={closeSpecialEventsFilterLayout}>{t("common.cancel")}</Button>
                        <Button onClick={applySpecialEventsFilter} type="primary">
                            {t("common.apply")}
                        </Button>
                    </Space>
                }>
                <Form
                    layout="vertical"
                    {...layout}
                    form={specialEventsForm}
                    name="specialEventsForm"
                    key='specialEventsForm'
                    initialValues={{ StatusIds: [Constant.StatusCode.Active] }}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="Name"
                                label={t("special_event.event_name")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="Description"
                                label={t("special_event.event_description")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="StatusIds"
                                label={t("common.status")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={statuses ? statuses.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </div>
    )

}

export default StateDefaults;
import React, { useState, useEffect } from 'react';
import { Modal, Table, Row, Col, Button, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import LoadingContext from '../../../components/loading/loadingContext'
import { useGlobalState } from './../../../utilities/globalState'
import { Constant, CommonService, MembershipService, SettingService } from '../../../services/services';
import { EditIcon } from '../../../components/icons/icons';
import { GetTableConfigs, StatusCell, EditableCell, TextAndButtonCell } from '../../../components/customTable/customTable'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import MemberList from '../../positionTitle/memberList/memberList';

const { confirm } = Modal;

const AddUpdateBoat = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [boats, setBoats] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedObject, setSelectedObject] = useState();
    const [isDirty, setIsDirty] = useState(false);
    const [currentPosition] = useGlobalState('currentPosition');
    const [allMembers, setAllMembers] = useState([]);
    const [visibleMemberList, setVisibleMemberList] = useState(false)
    const [editedSkipperType, setEditedSkipperType] = useState()

    useEffect(() => {
        if (props.visible) {
            if (!props.objectData || props.objectData.length == 0) {
                setBoats([getBlankLine()])
                setIsEdit(false)
            } else {
                setBoats(props.objectData.map(x => Object.assign({}, x)))
                setIsEdit(true)
            }

            showLoading()
            Promise.all([
                SettingService.getMembersByClub(),
            ])
                .finally(() => dismissLoading())
                .then(response => {
                    if (response[0] && response[0].data) {
                        setAllMembers(response[0].data)
                    }
                })
                .catch(error => CommonService.handleErrorResponse(error))
        }
    }, [props.visible])

    const getBlankLine = () => {
        let index = 0;
        if (boats && boats.length > 0) {
            index = boats.length
        }
        return {
            Id: index,
            BoatNo: "",
            Name: "",
            RegoNo: "",
            Length: null,
            BoatCategoryId: null,
            Skipper1: {},
            Skipper2: {},
            FuelTypeId: null,
            Make: "",
            Model: "",
            IsSkipperFinancialCheck: false,
            StatusId: 1
        };
    };

    const addBlankLine = () => {
        let lines = [
            ...boats,
            getBlankLine()
        ]

        setBoats(lines)
    }

    const handleOnRecordChange = (value, record, dataIndex) => {
        if (record) {
            let temp = boats.map(x => Object.assign({}, x));
            let index = boats.indexOf(record)
            if (temp[index]) {
                temp[index][dataIndex] = value;

                setBoats(temp)
                checkDirty(temp)
            }
        }
    }

    const checkDirty = (data) => {
        if (data && data.length > 0) {
            if (!isEdit) {
                let dirty = false
                for (let i = 0; i < data.length; i++) {
                    if (!CommonService.shallowCompare(data[i], {})) {
                        dirty = true
                        break
                    }
                }
                setIsDirty(dirty)
            } else {
                let dirty = false
                for (let i = 0; i < data.length; i++) {
                    let original = props.objectData.find(o => o.Id == data[i].Id)
                    if (!original) {
                        dirty = true
                        break
                    } else {
                        if (!CommonService.shallowCompare(data[i], original)) {
                            dirty = true
                            break
                        }
                    }

                }

                if (dirty) {
                    setIsDirty(true)
                } else {
                    setIsDirty(false)
                }
            }
        }
    }

    const editMembers = (record, skipperType) => {
        setSelectedObject(record)
        setEditedSkipperType(skipperType)
        setVisibleMemberList(true)
    }

    const onSkipper2Clear = (record) => {
        if (record) {
            let temp = boats.map(x => Object.assign({}, x));
            let index = boats.indexOf(record)
            if (temp[index]) {
                temp[index].Skipper2 = null;

                setBoats(temp)
                checkDirty(temp)
            }
        }
    }

    const statusCodes = [
        {
            Value: Constant.StatusCode.Active,
            Description: t("common.active")
        },
        {
            Value: Constant.StatusCode.Inactive,
            Description: t("common.inactive")
        }
    ]

    const addColumns = [
        {
            title: <span className='required'>{t('boat.boat_name')}</span>,
            dataIndex: 'Name',
            key: 'Name',
            editable: true,
            required: true,
            cellType: Constant.CellType.Input,
            width: 150,
            validateAtStart: true,
            fixed: 'left',
            handleOnChange: handleOnRecordChange
        },
        {
            title: <span className='required'>{t('boat.rego')}</span>,
            dataIndex: 'RegoNo',
            key: 'RegoNo',
            editable: true,
            required: true,
            cellType: Constant.CellType.Input,
            width: 150,
            validateAtStart: true,
            fixed: 'left',
            handleOnChange: handleOnRecordChange
        },
        {
            title: t('boat.length'),
            dataIndex: 'Length',
            key: 'Length',
            editable: true,
            required: false,
            cellType: Constant.CellType.NumberInput,
            width: 120,
            validateAtStart: true,
            handleOnChange: handleOnRecordChange
        },
        {
            title: <span className='required'>{t('boat.boat_category')}</span>,
            dataIndex: 'BoatCategoryId',
            key: 'BoatCategoryId',
            editable: true,
            extraData: props.boatCategories,
            required: true,
            cellType: Constant.CellType.EnhancedSelect,
            width: 150,
            selectionKey: "Id",
            selectionDisplay: "Name",
            validateAtStart: true,
            handleOnChange: handleOnRecordChange
        },
        {
            title: <span className='required'>{t('boat.skipper_1')}</span>,
            key: 'Skipper1',
            required: true,
            render: (value, record) => {
                let text = ""
                if (record.Skipper1 && record.Skipper1.Id) {
                    text = record.Skipper1.FirstName + " " + record.Skipper1.LastName
                }

                return <TextAndButtonCell value={text}
                    data={record} icon={<EditIcon />} includeWhiteSpaces={true} onClick={() => editMembers(record, Constant.MemberListType.Skipper1)} colorClass={Constant.ColorClass.LightBlue} />
            },
            width: 150
        },
        {
            title: t('boat.skipper_2'),
            key: 'Skipper2',
            render: (value, record) => {
                let text = ""
                if (record.Skipper2 && record.Skipper2.Id) {
                    text = record.Skipper2.FirstName + " " + record.Skipper2.LastName
                }

                return <TextAndButtonCell allowClear onClear={onSkipper2Clear} value={text}
                    data={record} icon={<EditIcon />} includeWhiteSpaces={true} onClick={() => editMembers(record, Constant.MemberListType.Skipper2)} colorClass={Constant.ColorClass.LightBlue} />
            },
            width: 150
        },
        {
            title: t('boat.fuel_type'),
            dataIndex: 'FuelTypeId',
            key: 'FuelTypeId',
            editable: true,
            extraData: props.fuelTypes,
            required: false,
            cellType: Constant.CellType.EnhancedSelect,
            width: 150,
            selectionKey: "Id",
            selectionDisplay: "Name",
            validateAtStart: true,
            handleOnChange: handleOnRecordChange
        },
        {
            title: t('boat.make'),
            dataIndex: 'Make',
            key: 'Make',
            editable: true,
            required: false,
            cellType: Constant.CellType.Input,
            width: 120,
            validateAtStart: true,
            handleOnChange: handleOnRecordChange
        },
        {
            title: t('boat.model'),
            dataIndex: 'Model',
            key: 'Model',
            editable: true,
            required: false,
            cellType: Constant.CellType.Input,
            width: 120,
            validateAtStart: true,
            handleOnChange: handleOnRecordChange
        },
        {
            title: t('boat.skipper_financial_check'),
            dataIndex: 'IsSkipperFinancialCheck',
            key: 'IsSkipperFinancialCheck',
            editable: true,
            required: false,
            cellType: Constant.CellType.Checkbox,
            width: 150,
            validateAtStart: true,
            handleOnChange: handleOnRecordChange,
            disabled: true
        },
        {
            title: t('common.status'),
            dataIndex: 'StatusId',
            key: 'StatusId',
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.StatusId)}
                    description={CommonService.getStatusDescription(record.StatusId)} />
            ),
            sorter: true,
            width: 100
        }
    ]

    const editColumns = [
        {
            title: t('boat.boat_no'),
            dataIndex: 'BoatNo',
            key: 'BoatNo',
            fixed: 'left',
            width: 150,
        },
        {
            title: <span className='required'>{t('boat.boat_name')}</span>,
            dataIndex: 'Name',
            key: 'Name',
            editable: true,
            required: true,
            cellType: Constant.CellType.Input,
            width: 150,
            validateAtStart: true,
            fixed: 'left',
            handleOnChange: handleOnRecordChange
        },
        {
            title: <span className='required'>{t('boat.rego')}</span>,
            dataIndex: 'RegoNo',
            key: 'RegoNo',
            editable: true,
            required: true,
            cellType: Constant.CellType.Input,
            width: 150,
            validateAtStart: true,
            fixed: 'left',
            handleOnChange: handleOnRecordChange
        },
        {
            title: t('boat.length'),
            dataIndex: 'Length',
            key: 'Length',
            editable: true,
            required: false,
            cellType: Constant.CellType.NumberInput,
            width: 120,
            validateAtStart: true,
            handleOnChange: handleOnRecordChange
        },
        {
            title: <span className='required'>{t('boat.boat_category')}</span>,
            dataIndex: 'BoatCategoryId',
            key: 'BoatCategoryId',
            editable: true,
            extraData: props.boatCategories,
            required: true,
            cellType: Constant.CellType.EnhancedSelect,
            width: 150,
            selectionKey: "Id",
            selectionDisplay: "Name",
            validateAtStart: true,
            handleOnChange: handleOnRecordChange
        },
        {
            title: <span className='required'>{t('boat.skipper_1')}</span>,
            key: 'Skipper1',
            required: true,
            render: (value, record) => {
                let text = ""
                if (record.Skipper1) {
                    text = record.Skipper1.FirstName + " " + record.Skipper1.LastName
                }

                return <TextAndButtonCell value={text}
                    data={record} icon={<EditIcon />} includeWhiteSpaces={true} onClick={() => editMembers(record, Constant.MemberListType.Skipper1)} colorClass={Constant.ColorClass.LightBlue} />
            },
            width: 150
        },
        {
            title: t('boat.skipper_2'),
            key: 'Skipper2',
            render: (value, record) => {
                let text = ""
                if (record.Skipper2) {
                    text = record.Skipper2.FirstName + " " + record.Skipper2.LastName
                }

                return <TextAndButtonCell allowClear onClear={onSkipper2Clear} value={text}
                    data={record} icon={<EditIcon />} includeWhiteSpaces={true} onClick={() => editMembers(record, Constant.MemberListType.Skipper2)} colorClass={Constant.ColorClass.LightBlue} />
            },
            width: 150
        },
        {
            title: t('boat.fuel_type'),
            dataIndex: 'FuelTypeId',
            key: 'FuelTypeId',
            editable: true,
            extraData: props.fuelTypes,
            required: false,
            cellType: Constant.CellType.EnhancedSelect,
            width: 150,
            selectionKey: "Id",
            selectionDisplay: "Name",
            validateAtStart: true,
            handleOnChange: handleOnRecordChange
        },
        {
            title: t('boat.make'),
            dataIndex: 'Make',
            key: 'Make',
            editable: true,
            required: false,
            cellType: Constant.CellType.Input,
            width: 120,
            validateAtStart: true,
            handleOnChange: handleOnRecordChange
        },
        {
            title: t('boat.model'),
            dataIndex: 'Model',
            key: 'Model',
            editable: true,
            required: false,
            cellType: Constant.CellType.Input,
            width: 120,
            validateAtStart: true,
            handleOnChange: handleOnRecordChange
        },
        {
            title: t('boat.skipper_financial_check'),
            dataIndex: 'IsSkipperFinancialCheck',
            key: 'IsSkipperFinancialCheck',
            editable: true,
            required: false,
            cellType: Constant.CellType.Checkbox,
            width: 150,
            validateAtStart: true,
            handleOnChange: handleOnRecordChange,
            disabled: true
        },
        {
            title: t('common.status'),
            dataIndex: 'StatusId',
            key: 'StatusId',
            editable: true,
            extraData: statusCodes,
            required: true,
            cellType: Constant.CellType.EnhancedSelect,
            width: 150,
            selectionKey: "Value",
            selectionDisplay: "Description",
            validateAtStart: true,
            handleOnChange: handleOnRecordChange,
            allowClear: false
        }
    ]

    const addColumnsMapping = addColumns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition,
                selectionKey: col.selectionKey,
                selectionDisplay: col.selectionDisplay,
                validateAtStart: col.validateAtStart,
                disabled: col.disabled
            }),
        };
    });

    const editColumnsMapping = editColumns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition,
                selectionKey: col.selectionKey,
                selectionDisplay: col.selectionDisplay,
                validateAtStart: col.validateAtStart,
                allowClear: col.allowClear,
                disabled: col.disabled
            }),
        };
    });

    const components = {
        body: {
            cell: EditableCell,
        }
    };

    const canSubmit = () => {
        if (boats && boats.length > 0) {
            let inValid = boats.filter(p => !p.Name || !p.RegoNo || !p.BoatCategoryId || p.BoatCategoryId <= 0 || !p.Skipper1 || !p.Skipper1.Id)
            if (inValid && inValid.length > 0) {
                return false
            }
            return true
        }
        return false
    }

    const getErrorMessage = () => {
        let inValid = boats.filter(p => !p.Name)
        if (inValid && inValid.length > 0) {
            return t("boat.boat_name_required")
        }

        inValid = boats.filter(p => !p.RegoNo)
        if (inValid && inValid.length > 0) {
            return t("boat.rego_required")
        }

        inValid = boats.filter(p => !p.BoatCategoryId)
        if (inValid && inValid.length > 0) {
            return t("boat.boat_category_is_required")
        }

        inValid = boats.filter(p => !p.BoatCategoryId || p.BoatCategoryId <= 0)
        if (inValid && inValid.length > 0) {
            return t("boat.boat_category_is_required")
        }

        inValid = boats.filter(p => !p.Skipper1 || !p.Skipper1.Id)
        if (inValid && inValid.length > 0) {
            return t("boat.skipper_1_required")
        }

        return null
    }

    const closeForm = () => {
        if (isDirty) {
            confirm({
                title: t("common.confirm"),
                icon: <ExclamationCircleOutlined />,
                content: t("common.submit_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    if (canSubmit()) {
                        onSubmit()
                    } else {
                        let message = getErrorMessage()
                        if (message) {
                            CommonService.presentToast('error', message)
                        }
                    }

                },
                onCancel() {
                    props.handleCancel()
                }

            });
        } else {
            props.handleCancel()
        }
    }

    const onSubmit = () => {
        if (canSubmit()) {
            if (isEdit) {
                showLoading()
                MembershipService.updateBoats(boats)
                    .finally(() => dismissLoading())
                    .then(result => {
                        CommonService.presentToast('success', t("boat.update_boat_successful"))
                        props.handleCancel(true)
                    })
                    .catch(error => CommonService.handleErrorResponse(error))
            } else {
                showLoading()
                MembershipService.addBoats(boats)
                    .finally(() => dismissLoading())
                    .then(result => {
                        CommonService.presentToast('success', t("boat.create_boat_successful"))
                        props.handleCancel(true)
                    })
                    .catch(error => CommonService.handleErrorResponse(error))
            }
        }
    }

    const onTableChange = (pagination) => {
    }

    const handleMemberListCancel = (objectData) => {
        setVisibleMemberList(false)
        if (objectData) {
            if ((objectData.Skipper1 && objectData.Skipper1.FinancialStatusId == Constant.FinancialStatus.Financial) ||
                (objectData.Skipper2 && objectData.Skipper2.FinancialStatusId == Constant.FinancialStatus.Financial)) {
                objectData.IsSkipperFinancialCheck = true
            } else {
                objectData.IsSkipperFinancialCheck = false
            }

            let temp = boats.map(x => Object.assign({}, x));
            let index = boats.indexOf(objectData)
            if (temp[index]) {
                setIsDirty(true)
                setBoats(temp);
            }
        }
    }

    return (
        <div>
            <Modal
                title={isEdit ? t("boat.edit_boat") : t("boat.create_boat")}
                open={true}
                onCancel={closeForm}
                confirmLoading={false}
                maskClosable={false}
                centered
                width={1200}
                footer={[
                    <Button key={0} size="large" onClick={closeForm}>{t("common.cancel")}</Button>,
                    <Button key={1} size="large" type="primary" disabled={!canSubmit()} onClick={onSubmit}>{isEdit ? t("common.submit") : t("common.insert")}</Button>
                ]}
            >
                {!isEdit &&
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                        <Col flex={100} className="text-right">
                            <div>
                                <Button className='btn-solid-dark-blue m-l-10' onClick={addBlankLine}>{t("common.add")}</Button>
                            </div>
                        </Col>
                    </Row>
                }
                <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                    <Col xs={{ span: 24 }}>
                        {
                            !isEdit &&
                            <Table
                                components={components}
                                rowClassName={() => 'editable-row'}
                                {...GetTableConfigs(addColumnsMapping, boats, boats.length, "Id", onTableChange, 1, "", false)}
                            />
                        }
                        {
                            isEdit &&
                            <Table
                                components={components}
                                rowClassName={() => 'editable-row'}
                                {...GetTableConfigs(editColumnsMapping, boats, boats.length, "Id", onTableChange, 1, "", false)}
                            />
                        }
                    </Col>
                </Row>
            </Modal>

            {
                visibleMemberList &&
                <MemberList handleCancel={handleMemberListCancel} viewOnly={false} allMembers={allMembers} objectData={selectedObject} memberListType={editedSkipperType}></MemberList>
            }
        </div>
    )
}

export default AddUpdateBoat
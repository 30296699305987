import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
//import NotFound from "../pages/notFound/notFound";
import { AuthService, Constant } from "../services/services"
import Login from "../pages/login/login";
import ForgetPassword from "../pages/forgetPassword/forgetPassword";
import Sample from "../pages/sample/sample";
import MainLayout from "../layouts/mainLayout/mainLayout";
import AnonymousLayout from "../layouts/anonymousLayout/anonymousLayout";
import MembershipManagement from "../pages/membership/membershipManagement/membershipManagement";
import PositionTitleManagement from "../pages/positionTitle/positionTitleManagement/positionTitleManagement"
import Home from "../pages/home/home"
import MemberViewClubContacts from "../pages/membership/memberViewStateClubContracts/memberViewClubContacts";
import MemberViewStateContacts from "../pages/membership/memberViewStateClubContracts/memberViewStateContacts";
import BoatCategoryManagement from "../pages/boatCategory/boatCategoryManagement/boatCategoryManagement";
import ClubStateDetail from "../pages/clubStateDetail/clubStateDetail";
import ContactsNotification from "../pages/entity/contactsNotification/contactsNotification";
import PointScoreZoneManagement from "../pages/pointScoreZone/pointScoreZoneManagement/pointScoreZoneManagement";
import SpecialEventManagement from "../pages/specialEvent/specialEventManagement/specialEventManagement";
import MembershipSearch from "../pages/membership/membershipSearch/membershipSearch";
import EditMemberDetails from "../pages/membership/editMemberDetails/editMemberDetails";
import BoatManagement from "../pages/boat/boatManagement/boatManagement"
import CreateDayLog from "../pages/fishingActivity/createDayLog/createDayLog";
import FishingLocationsManagement from "../pages/fishingLocation/fishingLocationManagement";
import DayLogList from "../pages/dayLogList/dayLogList";
import BoatSearch from "../pages/boatSearch/boatSearch";
import MemberFinancialDateManagement from "../pages/membership/editMemberFinancialDate/memberFinancialDateManagement";
import FamilyGroupManagement from "../pages/familyGroup/familyGroupManagement/familyGroupManagement";
import ExportActivity from "../pages/exportActivity/exportActivity";
import MemberReport from "../pages/membership/memberReports/memberStateClubReport/memberReport"
import TagCardSearch from "../pages/tagCardSearch/tagCardSearch"
import EoyMembershipInvoicing from "../pages/eoyMembershipInvoicing/eoyMembershipInvoicing"
import ActivityEntryTagCardApprovals from "../pages/activityEntryTagCardApprovals/activityEntryTagCardApprovals"
import TrophyManagement from "../pages/trophy/trophyManagement/trophyManagement"
import ClubDefaults from "../pages/dayLogDefaults/clubDefaults/clubDefaults"
import StateDefaults from "../pages/dayLogDefaults/stateDefaults/stateDefaults"
import EmailMemberIDLogIn from "../pages/communication/emailMemberIDLogIn/emailMemberIDLogIn"
import AnglerGroupManagement from "../pages/trophy/anglerGroup/anglerGroupManagement"
import ActivityEntriesList from "../pages/fishingActivity/activityEntriesList/activityEntriesManagement";
import StateApprovalsStatus from "../pages/approvalsStatus/stateApprovalsStatus"
import DateApprovals from "../pages/tournament/dateApprovals/dateApprovals";
import DateApplication from "../pages/tournament/dateApplication/dateApplication";
import TournamentList from "../pages/tournament/tournamentList/tournamentList"
import SanctioningApplications from "../pages/tournament/sanctioningApplications/sanctioningApplications"
import DocumentManagement from "../pages/document/documentManagement/documentManagement"
import ViewDocuments from "../pages/document/viewDocuments/viewDocuments"
import TagRPointScore from "../pages/pointScore/TagRPointScore/tagRPointScore";
import CapturePointScore from "../pages/pointScore/capturePointScore/capturePointScore";
import MRPointScore from "../pages/pointScore/MRPointScore/mRPointScore";
import LRPointScore from "../pages/pointScore/LRPointScore/lRPointScore";
import StateTournamentSanctioningApprovals from "../pages/stateTournamentSanctioningApprovals/stateTournamentSanctioningApprovals";
import DayLogSummary from "../pages/trophy/dayLogSummary/dayLogSummary"
import EmailMembers from "../pages/emailMembers/emailMembers";
import RecordManagement from "../pages/recordManagement/recordManagement"
import BoatGroupManagement from "../pages/trophy/boatGroup/boatGroupManagement";
import StateRecord from "../pages/recordManagement/stateRecord";
import ClubRecord from "../pages/recordManagement/clubRecord";
import ClubApprovalsStatus from "../pages/approvalsStatus/clubApprovalsStatus"
import Unauthorised from "../pages/unauthorised/unauthorised";

export default function AppRoutes() {

    function appRoute(pathName, Component, LayoutComponent, needAuthentication, param = null) {
        if (!needAuthentication) {
            return (
                <Route exact path={pathName} render={(props => <LayoutComponent {...props}><Component {...props} /></LayoutComponent>)} />
            )
        } else {
            return (
                <Route exact path={pathName} render={(props => AuthService.hasAuthorized()
                    ? <LayoutComponent {...props}><Component {...props} param={param} /></LayoutComponent>
                    : <Redirect to={{ pathname: Constant.Pages.Login, state: { from: props.location } }}
                    />)} />
            )
        }
    }

    return (
        <Switch>
            {appRoute(Constant.Pages.Login, Login, AnonymousLayout, false)}
            {appRoute(Constant.Pages.ForgetPassword, ForgetPassword, AnonymousLayout, false)}
            {appRoute(Constant.Pages.Home, Home, MainLayout, true)}
            {appRoute(Constant.Pages.MembershipManagement, MembershipManagement, MainLayout, true)}
            {appRoute(Constant.Pages.MembershipSearch, MembershipSearch, MainLayout, true)}
            {appRoute(Constant.Pages.EditMemberDetails, EditMemberDetails, MainLayout, true)}
            {appRoute(Constant.Pages.PositionTitleManagement, PositionTitleManagement, MainLayout, true)}
            {appRoute(Constant.Pages.MemberViewClubContacts, MemberViewClubContacts, MainLayout, true)}
            {appRoute(Constant.Pages.MemberViewStateContacts, MemberViewStateContacts, MainLayout, true)}
            {appRoute(Constant.Pages.ClubDetail, ClubStateDetail, MainLayout, true, Constant.Pages.ClubDetail)}
            {appRoute(Constant.Pages.StateDetail, ClubStateDetail, MainLayout, true, Constant.Pages.StateDetail)}
            {appRoute(Constant.Pages.BoatCategoryManagement, BoatCategoryManagement, MainLayout, true)}
            {appRoute(Constant.Pages.ContactsNotification, ContactsNotification, MainLayout, true)}
            {appRoute(Constant.Pages.PointScoreZoneManagement, PointScoreZoneManagement, MainLayout, true)}
            {appRoute(Constant.Pages.SpecialEventManagement, SpecialEventManagement, MainLayout, true)}
            {appRoute(Constant.Pages.BoatManagement, BoatManagement, MainLayout, true)}
            {appRoute(Constant.Pages.CreateDayLog, CreateDayLog, MainLayout, true)}
            {appRoute(Constant.Pages.Sample, Sample, MainLayout)}
            {appRoute(Constant.Pages.FishingLocationsManagement, FishingLocationsManagement, MainLayout, true)}
            {appRoute(Constant.Pages.BoatSearch, BoatSearch, MainLayout, true)}
            {appRoute(Constant.Pages.DayLogList, DayLogList, MainLayout, true)}
            {appRoute(Constant.Pages.MemberFinancialDateManagement, MemberFinancialDateManagement, MainLayout, true)}
            {appRoute(Constant.Pages.FamilyGroupManagement, FamilyGroupManagement, MainLayout, true)}
            {appRoute(Constant.Pages.MembershipInvoicing, EoyMembershipInvoicing, MainLayout, true)}
            {appRoute(Constant.Pages.ExportActivity, ExportActivity, MainLayout, true)}
            {appRoute(Constant.Pages.MemberReport, MemberReport, MainLayout, true)}
            {appRoute(Constant.Pages.TagCardSearch, TagCardSearch, MainLayout, true)}
            {appRoute(Constant.Pages.ActivityApprovals, ActivityEntryTagCardApprovals, MainLayout, true)}
            {appRoute(Constant.Pages.ViewTrophy, TrophyManagement, MainLayout, true)}
            {appRoute(Constant.Pages.TrophyManagement, TrophyManagement, MainLayout, true)}
            {appRoute(Constant.Pages.ClubDefaults, ClubDefaults, MainLayout, true)}
            {appRoute(Constant.Pages.StateDefaults, StateDefaults, MainLayout, true)}
            {appRoute(Constant.Pages.EmailMemberIDLogIn, EmailMemberIDLogIn, MainLayout, true)}
            {appRoute(Constant.Pages.AnglerGroupManagement, AnglerGroupManagement, MainLayout, true)}
            {appRoute(Constant.Pages.ActivityEntriesList, ActivityEntriesList, MainLayout, true)} 
            {appRoute(Constant.Pages.StateApprovalsStatus, StateApprovalsStatus, MainLayout, true)}
            {appRoute(Constant.Pages.DateApprovals, DateApprovals, MainLayout, true)}
            {appRoute(Constant.Pages.DateApplication, DateApplication, MainLayout, true)}
            {appRoute(Constant.Pages.SanctioningApplications, SanctioningApplications, MainLayout, true)}  
            {appRoute(Constant.Pages.TournamentList, TournamentList, MainLayout, true)}       
            {appRoute(Constant.Pages.DocumentManagement, DocumentManagement, MainLayout, true)}       
            {appRoute(Constant.Pages.ViewDocuments, ViewDocuments, MainLayout, true)}  
            {appRoute(Constant.Pages.TagRPointScore, TagRPointScore, MainLayout, true)}   
            {appRoute(Constant.Pages.CapturePointScore, CapturePointScore, MainLayout, true)}   
            {appRoute(Constant.Pages.TagRPointScore, TagRPointScore, MainLayout, true)} 
            {appRoute(Constant.Pages.MRPointScore, MRPointScore, MainLayout, true)} 
            {appRoute(Constant.Pages.LRPointScore, LRPointScore, MainLayout, true)}    
            {appRoute(Constant.Pages.ViewDocuments, ViewDocuments, MainLayout, true)}       
            {appRoute(Constant.Pages.SanctioningApprovals, StateTournamentSanctioningApprovals, MainLayout, true)}
            {appRoute(Constant.Pages.DayLogSummary, DayLogSummary, MainLayout, true)}  
            {appRoute(Constant.Pages.RecordManagement, RecordManagement, MainLayout, true)}
            {appRoute(Constant.Pages.BoatGroupManagement, BoatGroupManagement, MainLayout, true)} 
            {appRoute(Constant.Pages.EmailMembers, EmailMembers, MainLayout, true)}
            {appRoute(Constant.Pages.StateRecord, StateRecord, MainLayout, true)}
            {appRoute(Constant.Pages.ClubRecord, ClubRecord, MainLayout, true)}
            {appRoute(Constant.Pages.ClubApprovalsStatus, ClubApprovalsStatus, MainLayout, true)}
            {appRoute(Constant.Pages.Unauthorised, Unauthorised, MainLayout, true)}
        </Switch>
    );
}
import React from 'react';
import BasePointScore from '../BasePointScore/basePointScore';
import { Constant } from '../../../services/services';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { GetTableConfigs, EditableCell } from '../../../components/customTable/customTable'

const components = {
    body: {
        cell: EditableCell,
    },
};

const BasePointScoreTable = (props) => {

    return (
        <Table
            components={components}
            tableLayout='fixed'
            bordered
            {...GetTableConfigs(props.columns, props.pointData?.ObjectList, props.pointData?.TotalItems, "Id", null, null, null, false)}
        />
    )
}

export default BasePointScoreTable;
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Constant, CommonService, FishingActivityService, SettingService } from '../../services/services';
import LoadingContext from '../../components/loading/loadingContext'
import { Row, Col, Table, Button, Modal, Form, Drawer, Space, Input, Select, Typography, Empty, Card } from 'antd';
import { useGlobalState } from './../../utilities/globalState'
import { GetTableConfigs, StatusCell, ActionButtonsCell, DateTimeCell } from '../../components/customTable/customTable'
import PubSub from 'pubsub-js'
import { EyeIcon } from './../../components/icons/icons'

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const { Text, Title } = Typography;

const TagCardSearch = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [tagCardForm] = Form.useForm();
    const [currentPosition] = useGlobalState('currentPosition');
    const [entityDetail, setEntityDetail] = useState({})
    const [tagCardData, setTagCardData] = useState(null);
    const [tagCardList, setTagCardList] = useState([]);
    const [enableSelectionModal, setEnableSelectionModal] = React.useState(false);

    useEffect(() => {
        showLoading()
        Promise.all([
            SettingService.getStateEntityDetail()
        ])
            .finally(() => dismissLoading())
            .then(response => {
                if (response[0] && response[0].data) {
                    setEntityDetail(response[0].data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);
        return () => {
            SettingService.cancelRequest()
        }
    }, [])

    const resetForm = () => {
        tagCardForm.resetFields();
        setTagCardData(null)
        setTagCardList([])
    }

    const search = () => {
        let values = tagCardForm.getFieldsValue()
        showLoading()
        FishingActivityService.getActivityLinesByTagNumber(values.TagNumber)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data && result.data.length > 0) {
                    if (result.data.length == 1)
                        getActivityLinesById(result.data[0].Id)
                    else {
                        setTagCardList(result.data)
                        showTagNumberSelction()
                    }
                }
                else {
                    CommonService.presentToast('error', t('tag_card_search.tag_number_not_found'))
                }

            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const getActivityLinesById = (activityLineId) => {
        showLoading()
        FishingActivityService.getActivityLinesById(activityLineId)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    setTagCardData(result.data)
                }
            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const showTagNumberSelction = () => {
        setEnableSelectionModal(true)
    }

    const closeSelectionModal = () => {
        setEnableSelectionModal(false)
    }

    const activityHeaderSelect = (record) => {
        getActivityLinesById(record.Id)
        closeSelectionModal()
    }

    const columns = [
        {
            title: t('common.action'),
            key: 'Id',
            render: (value, record) => {
                return <ActionButtonsCell data={record} btnList={[
                    {
                        Description: t("common.view"),
                        Icon: <EyeIcon />,
                        ColorClass: Constant.ColorClass.LightBlue,
                        Hide: false,
                        NoMargin: true,
                        handleAction: activityHeaderSelect
                    }
                ]} />
            },
            align: 'center',
            width: 70,
        },
        {
            title: t("tag_card_search.act_id"),
            dataIndex: 'Id',
            key: 'Id',
            width: 100
        },
        {
            title: t("fishing_activity.tag_number"),
            dataIndex: 'TagNumber',
            key: 'TagNumber',
            width: 150
        },
        {
            title: t('fishing_activity.date'),
            dataIndex: 'ActivityDate',
            key: 'ActivityDate',
            sorter: false,
            ellipsis: true,
            width: 120,
            render: (value, record) => (
                <DateTimeCell value={record.ActivityDate} />
            )
        },
        {
            title: t("fishing_location.latitude"),
            dataIndex: 'GpsLatitude',
            key: 'GpsLatitude',
            width: 120
        },
        {
            title: t("fishing_location.longitude"),
            dataIndex: 'GpsLongitude',
            key: 'GpsLongitude',
            width: 120
        },
        {
            title: t("fishing_activity.length"),
            dataIndex: 'FishLength',
            key: 'FishLength',
            width: 120
        },
        {
            title: t("tag_card_search.est_weight"),
            dataIndex: 'FishWeight',
            key: 'FishWeight',
            width: 150
        }

    ]

    return (
        <>
            <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                <Col xs={{ span: 24 }}>
                    <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                        <Title className='color-dark-blue' level={5}>{t("tag_card_search.title")}</Title>
                    </Space>
                </Col>
            </Row>
            <Card>
                <Form
                    layout="vertical"
                    {...layout}
                    form={tagCardForm}
                    name="tagCardForm"
                    key='tagCardForm'
                    onFinish={search}

                >
                    <Row gutter={24} align={'bottom'}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="TagNumber"
                                label={t("fishing_activity.tag_number")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("fishing_activity.tag_number_is_required"),
                                    },
                                ]}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }} className='m-b-8'>
                            <Button onClick={resetForm} className='m-r-10'>{t("common.reset")}</Button>
                            <Button htmlType="submit" className='btn-outline-dark-blue'>{t("tag_card_search.search")}</Button>
                        </Col>
                    </Row>
                </Form>
                <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                    <Col xs={{ span: 24 }}>
                        <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                            <span className='color-medium-grey'>{t("tag_card_search.message")}</span>
                        </Space>
                    </Col>
                </Row>
            </Card>


            <Row gutter={[Constant.SpaceConstant.HorizontalGutter, Constant.SpaceConstant.VerticalGutter]} className='m-t-20'>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                    <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                        <Text className='color-black'>{t("fishing_activity.tag_number")}</Text>
                        <Text className='bold-text'>{tagCardData?.TagNumber ?? "_"}</Text>
                    </Space>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                    <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                        <Text className='color-black'>{t("fishing_activity.date")}</Text>
                        {tagCardData ? <DateTimeCell value={tagCardData.ActivityDate} className='semi-bold-text color-dark-blue' emptyText="_" /> : "_"}
                    </Space>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                    <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                        <Text className='color-black'>{t("fishing_activity.time")}</Text>
                        <Text className='semi-bold-text'>{tagCardData?.CaughtTime ?? "_"}</Text>
                    </Space>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                    <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                        <Text className='color-black'>{t("fishing_activity.fight_time")}</Text>
                        <Text className='semi-bold-text'>{tagCardData?.FightTime ?? "_"}</Text>
                    </Space>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                    <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                        <Text className='color-black'>{t("fishing_activity.location")}</Text>
                        <Text className='semi-bold-text'>{tagCardData?.FishingLocationName ?? "_"}</Text>
                    </Space>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                    <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                        <Text className='color-black'>{t("fishing_location.latitude")}</Text>
                        <Text className='semi-bold-text'>{tagCardData?.GpsLatitude ?? "_"}</Text>
                    </Space>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                        <Text className='color-black'>{t("fishing_location.longitude")}</Text>
                        <Text className='semi-bold-text'>{tagCardData?.GpsLongitude ?? "_"}</Text>
                    </Space>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                    <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                        <Text className='color-black'>{t("fishing_activity.species")}</Text>
                        <Text className='semi-bold-text'>{tagCardData?.FishSpeciesName ?? "_"}</Text>
                    </Space>
                </Col>
                
                <Col xs={{ span: 24 }} lg={{ span: 6}}>
                    <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                        <Text className='color-black'>{t("tag_card_search.est_weight")}</Text>
                        <Text className='semi-bold-text'>{tagCardData?.FishWeight ?? "_"}</Text>
                    </Space>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 3 }}>
                    <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                        <Text className='color-black'>{t("fishing_activity.length")}</Text>
                        <Text className='semi-bold-text'>{tagCardData?.FishLength ?? "_"}</Text>
                    </Space>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 3 }}>
                    <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                        <Text className='color-black'>{t("fishing_activity.ljfl_fl")}</Text>
                        {tagCardData ? <StatusCell
                            colorClass={CommonService.getStatusColorClass(tagCardData.IsLjflFl)}
                            description={CommonService.getStatusDescription(tagCardData.IsLjflFl, true)} /> : "_"
                        }
                    </Space>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 3 }}>
                    <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                        <Text className='color-black'>{t("tag_card_search.measure")}</Text>
                        {tagCardData ? <StatusCell
                            colorClass={CommonService.getStatusColorClass(tagCardData.IsMeasure)}
                            description={CommonService.getStatusDescription(tagCardData.IsMeasure, true)} /> : "_"
                        }
                    </Space>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                    <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                        <Text className='color-black'>{t("fishing_activity.method")}</Text>
                        <Text className='semi-bold-text'>{tagCardData?.FishingMethodDescription ?? "_"}</Text>
                    </Space>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                    <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                        <Text className='color-black'>{t("tag_card_search.line_class")}</Text>
                        <Text className='semi-bold-text'>{tagCardData?.LineClassName ?? "_"}</Text>
                    </Space>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 3 }}>
                    <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                        <Text className='color-black'>{t("fishing_activity.circle_hook")}</Text>
                        {tagCardData ? <StatusCell
                            colorClass={CommonService.getStatusColorClass(tagCardData.IsCircleHook)}
                            description={CommonService.getStatusDescription(tagCardData.IsCircleHook, true)} /> : "_"
                        }
                    </Space>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                    <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                        <Text className='color-black'>{t("fishing_activity.non_offset")}</Text>
                        {tagCardData ? <StatusCell
                            colorClass={CommonService.getStatusColorClass(tagCardData.IsNonOffset)}
                            description={CommonService.getStatusDescription(tagCardData.IsNonOffset, true)} /> : "_"
                        }
                    </Space>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                    <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                        <Text className='color-black'>{t("tag_card_search.angler_name")}</Text>
                        <Text className='semi-bold-text'>{tagCardData?.AnglerName ?? "_"}</Text>
                    </Space>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 18 }}>
                    <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                        <Text className='color-black'>{t("tag_card_search.angler_club")}</Text>
                        <Text className='semi-bold-text'>{tagCardData?.AnglerClubName ?? "_"}</Text>
                    </Space>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                    <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                        <Text className='color-black'>{t("boat.boat_name")}</Text>
                        <Text className='semi-bold-text'>{tagCardData?.BoatName ?? "_"}</Text>
                    </Space>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                    <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                        <Text className='color-black'>{t("tag_card_search.boat_club")}</Text>
                        <Text className='semi-bold-text'>{tagCardData?.BoatClubName ?? "_"}</Text>
                    </Space>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                        <Text className='color-black'>{t("tag_card_search.skipper")}</Text>
                        <Text className='semi-bold-text'>{tagCardData?.BoatSkipper ?? "_"}</Text>
                    </Space>
                </Col>
                {(currentPosition && currentPosition.PositionTypeId == Constant.PositionType.StateExcutive ||
                    currentPosition && currentPosition.PositionTypeId == Constant.PositionType.NationalExecutive) &&
                    <Col xs={{ span: 24 }}>
                        <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                            <Text className='color-black'>{t("member.comments")}</Text>
                            <Text className='semi-bold-text'>{tagCardData?.StateNotes ?? "_"}</Text>
                        </Space>
                    </Col>
                }
                {currentPosition && currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee &&
                    <Col xs={{ span: 24 }}>
                        <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                            <Text className='color-black'>{t("member.comments")}</Text>
                            <Text className='semi-bold-text'>{tagCardData?.Notes ?? "_"}</Text>
                        </Space>
                    </Col>
                }
            </Row>
            {
                <Modal
                    title={t("tag_card_search.select_tag_number")}
                    open={enableSelectionModal}
                    onCancel={closeSelectionModal}
                    confirmLoading={false}
                    maskClosable={false}
                    centered
                    width={1200}
                    footer={null}
                >
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Table
                                {...GetTableConfigs(columns, tagCardList, tagCardList.length, "Id")}
                                pagination={false}
                            />
                        </Col>
                    </Row>
                </Modal>
            }


        </>
    )

}

export default TagCardSearch
import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Button, Form, Space, Input, Typography, Upload, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import LoadingContext from '../../../components/loading/loadingContext'
import { Constant, CommonService, TournamentService } from '../../../services/services';
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { DateTimeCell } from '../../../components/customTable/customTable'
import AddImage from '../../../assets/icons/add-image.svg'
import { useGlobalState } from '../../../utilities/globalState'
import EnhancedSelect from '../../../components/enhancedSelect/enhancedSelect'

const { confirm } = Modal;
const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const fileTypes = {
    Rules: 1,
    EntryForm: 2,
    Flyer: 3,
    Result: 4
}

const EditTournament = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [isDirty, setIsDirty] = useState(false);
    const [editTournamentForm] = Form.useForm();
    const [selectedObject, setSelectedObject] = useState();
    const [currentPosition] = useGlobalState('currentPosition');

    useEffect(() => {
        if (props.visible && props.selectedObject)
            setSelectedObject(Object.assign({}, props.selectedObject))
    }, [props.visible])

    const canSubmit = () => {
        if (selectedObject && selectedObject.Title && (selectedObject.Rules || selectedObject.RulesBase64) && (selectedObject.EntryForm || selectedObject.EntryFormBase64)) {
            return true
        }

        return false
    }

    const removeLogo = () => {
        let temp = Object.assign({}, selectedObject);
        temp.SponsorLogoBase64 = ""
        temp.SponsorLogo = null
        temp.SponsorLogoUrl = null
        setSelectedObject(temp)
    }

    const removeFile = (fileType) => {
        let temp = Object.assign({}, selectedObject)

        if (fileType == fileTypes.Flyer) {
            temp.Flyer = null
            temp.FlyerBase64 = ""
            setSelectedObject(temp)
            setIsDirty(true)
        } else if (fileType == fileTypes.Result) {
            temp.Results = null
            temp.ResultsBase64 = ""
            setSelectedObject(temp)
            setIsDirty(true)
        }

    }

    const beforeUploadFile = (file, fileType) => {
        CommonService.getFileBase64(file, fileUrl => {
            let temp = Object.assign({}, selectedObject);
            if (fileType == fileTypes.Rules) {
                temp.RulesBase64 = fileUrl
                temp.Rules = file.name
            }
            else if (fileType == fileTypes.EntryForm) {
                temp.EntryFormBase64 = fileUrl
                temp.EntryForm = file.name
            }
            else if (fileType == fileTypes.Flyer) {
                temp.FlyerBase64 = fileUrl
                temp.Flyer = file.name
            }
            else if (fileType == fileTypes.Result) {
                temp.ResultsBase64 = fileUrl
                temp.Results = file.name
            }
            setIsDirty(true)
            setSelectedObject(temp)
        });
    }

    const beforeUploadPhoto = img => {
        CommonService.getImgBase64(img, imageUrl => {
            let temp = Object.assign({}, selectedObject);
            temp.SponsorLogoBase64 = imageUrl
            temp.SponsorLogo = img.name
            setSelectedObject(temp)
        });
    }

    const getErrorMessage = () => {
        if (!selectedObject.Title) {
            return t("sanctioning_applications.tournament_title_required")
        }

        if (!selectedObject.Rules && !selectedObject.RulesBase64) {
            return t("sanctioning_applications.rules_required")
        }

        if (!selectedObject.EntryForm && !selectedObject.EntryFormBase64) {
            return t("sanctioning_applications.entry_form_required")
        }

        return null
    }

    const closeForm = () => {
        if (isDirty) {
            confirm({
                title: t("common.confirm"),
                icon: <ExclamationCircleOutlined />,
                content: t("common.submit_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    if (canSubmit()) {
                        onSubmit()
                    } else {
                        let message = getErrorMessage()
                        if (message) {
                            CommonService.presentToast('error', message)
                        }
                    }

                },
                onCancel() {
                    props.handleCancel()
                }

            });
        } else {
            props.handleCancel()
        }
    }

    const onSubmit = () => {
        if (canSubmit()) {
            showLoading()
            TournamentService.updateTournament(selectedObject)
                .finally(() => dismissLoading())
                .then(result => {
                    CommonService.presentToast('success', t("sanctioning_applications.apply_for_tournament_date_successfully"))
                    props.handleCancel(true)
                })
                .catch(error => CommonService.handleErrorResponse(error))
        }
    }

    const onFormValuesChange = (changedValues, allValues) => {
        var data = editTournamentForm.getFieldsValue()
        let newData = Object.assign(selectedObject, data)
        setIsDirty(true)
        setSelectedObject(newData)
    }

    const getImageLogo = () => {
        if (selectedObject) {
            if (selectedObject.SponsorLogoBase64) {
                return selectedObject.SponsorLogoBase64
            } else if (selectedObject.SponsorLogoUrl) {
                return selectedObject.SponsorLogoUrl
            }

            return null
        }
    }

    const statusCodes = [
        {
            Value: Constant.StatusCode.Active,
            Description: t("common.active")
        },
        {
            Value: Constant.StatusCode.Inactive,
            Description: t("common.inactive")
        }
    ]

    return (
        <Modal
            title={t("tournament_list.edit_tournament_details")}
            open={true}
            onCancel={closeForm}
            confirmLoading={false}
            maskClosable={false}
            centered
            width={1200}
            footer={[
                <Button key={0} size="large" onClick={closeForm}>{t("common.cancel")}</Button>,
                <Button key={1} size="large" type="primary" disabled={!canSubmit()} onClick={onSubmit}>{t("common.submit")}</Button>
            ]}
        >
            <Row>
                <Col xs={{ span: 24 }}>
                    <Form
                        layout="vertical"
                        {...layout}
                        form={editTournamentForm}
                        name="editTournamentForm"
                        key='editTournamentForm'
                        onFinish={onSubmit}
                        initialValues={props.selectedObject}
                        onValuesChange={onFormValuesChange}>
                        <Row>
                            <Col xs={{ span: 24 }}>
                                <Form.Item
                                    label={t("sanctioning_applications.title")}
                                    name="Title" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("sanctioning_applications.tournament_title_required") }]}
                                >
                                    <Input allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 24 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="StatusId"
                                    label={t("common.status")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("common.status_is_required"),
                                        },
                                    ]}
                                >
                                    <EnhancedSelect showSearch optionFilterProp="label" allowClear={false}
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={statusCodes ? statusCodes.map(b => {
                                            return {
                                                label: b.Description,
                                                value: b.Value
                                            }
                                        }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>


                            {
                                currentPosition && currentPosition.PositionTypeId == Constant.PositionType.StateExcutive &&
                                <>
                                    <Col xs={{ span: 12 }}>
                                        <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                            <Text className='color-black'>{t("tournament.club")}</Text>
                                            {props.selectedObject ? props.selectedObject.EntityAcronym : "_"}
                                        </Space>
                                    </Col>
                                    <Col xs={{ span: 12 }}>
                                        <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                            <Text className='color-black'>{t("tournament.zone")}</Text>
                                            {props.selectedObject ? props.selectedObject.StateZoneName : "_"}
                                        </Space>
                                    </Col>
                                </>
                            }
                            <Col xs={{ span: 12 }}>
                                <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                    <Text className='color-black'>{t("sanctioning_applications.tournament_start_date")}</Text>
                                    {props.selectedObject ? <DateTimeCell value={props.selectedObject?.StartDate} className='semi-bold-text color-dark-blue' emptyText="_" /> : "_"}
                                </Space>
                            </Col>
                            <Col xs={{ span: 12 }}>
                                <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                    <Text className='color-black'>{t("sanctioning_applications.tournament_end_date")}</Text>
                                    {props.selectedObject ? <DateTimeCell value={props.selectedObject?.FinishDate} className='semi-bold-text color-dark-blue' emptyText="_" /> : "_"}
                                </Space>
                            </Col>
                        </Row>
                        <Row className='m-t-10' gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                            <Col xs={{ span: 3 }}>
                                <Text className='color-black required'>{t("sanctioning_applications.rules")}</Text>
                            </Col>
                            <Col xs={{ span: 3 }}>
                                <Upload
                                    listType="text"
                                    showUploadList={false}
                                    beforeUpload={file => beforeUploadFile(file, fileTypes.Rules)}
                                    maxCount={1}
                                >
                                    <Button size="small" className='btn-solid-dark-blue'>{t("sanctioning_applications.choose_file")}</Button>
                                </Upload>
                            </Col>
                            <Col xs={{ span: 18 }}>
                                <Text className='semi-bold-text'>{selectedObject?.Rules ?? "_"}</Text>
                            </Col>

                            <Col xs={{ span: 3 }}>
                                <Text className='color-black required'>{t("sanctioning_applications.entry_form")}</Text>
                            </Col>
                            <Col xs={{ span: 3 }}>
                                <Upload
                                    listType="text"
                                    showUploadList={false}
                                    beforeUpload={file => beforeUploadFile(file, fileTypes.EntryForm)}
                                    maxCount={1}
                                >
                                    <Button size="small" className='btn-solid-dark-blue'>{t("sanctioning_applications.choose_file")}</Button>
                                </Upload>
                            </Col>
                            <Col xs={{ span: 18 }}>
                                <Text className='semi-bold-text'>{selectedObject?.EntryForm ?? "_"}</Text>
                            </Col>

                            <Col xs={{ span: 3 }}>
                                <Text className='color-black'>{t("sanctioning_applications.flyer")}</Text>
                            </Col>
                            <Col xs={{ span: 3 }}>
                                <Upload
                                    listType="text"
                                    showUploadList={false}
                                    beforeUpload={file => beforeUploadFile(file, fileTypes.Flyer)}
                                    maxCount={1}
                                >
                                    <Button size="small" className='btn-solid-dark-blue'>{t("sanctioning_applications.choose_file")}</Button>
                                </Upload>
                            </Col>
                            <Col xs={{ span: 16 }}>
                                <Space direction="horizontal" size={Constant.SpaceConstant.TextFieldGutter}>
                                    <Text className='semi-bold-text'>{selectedObject?.Flyer ?? "_"}</Text>
                                    {selectedObject && selectedObject.Flyer &&
                                        <Button className='color-dark-red' type="text" onClick={() => removeFile(fileTypes.Flyer)} icon={<DeleteOutlined />} size="middle" />
                                    }

                                </Space>

                            </Col>
                        </Row>
                        <Row className="m-t-15">
                            <Col xs={{ span: 24 }}>
                                <Form.Item
                                    label={t("sanctioning_applications.tournament_description")}
                                    name="Description" colon={false} labelAlign="left"
                                >
                                    <TextArea allowClear rows={2} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }}>
                                <Form.Item
                                    label={t("tournament_list.sponsor")}
                                    name="SponsorName" colon={false} labelAlign="left"
                                >
                                    <Input allowClear />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 8 }} className='upload-logo'>
                                <Form.Item
                                    label={t('tournament_list.sponsor_logo')}
                                    colon={false} labelAlign="left"
                                    className='upload-item'
                                >
                                    <Upload
                                        className="avatar-uploader"
                                        listType="picture-card"
                                        showUploadList={false}
                                        beforeUpload={beforeUploadPhoto}
                                        accept="image/*"
                                    >
                                        {
                                            getImageLogo() ?
                                                <img alt={t('tournament_list.sponsor_logo')} className="logo" src={getImageLogo()} />
                                                : <img alt={t('tournament_list.sponsor_logo')} className="logo" src={AddImage} />
                                        }
                                    </Upload>
                                    {
                                        getImageLogo() &&
                                        <span className="remove-logo">
                                            <Button type="primary" shape="circle" icon={<DeleteOutlined />} onClick={removeLogo} size="middle" />
                                        </span>
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className='m-t-15'>
                            <Col xs={{ span: 3 }}>
                                <Text className='color-black'>{t("tournament_list.result")}</Text>
                            </Col>
                            <Col xs={{ span: 3 }}>
                                <Upload
                                    listType="text"
                                    showUploadList={false}
                                    beforeUpload={file => beforeUploadFile(file, fileTypes.Result)}
                                    maxCount={1}
                                >
                                    <Button size="small" className='btn-solid-dark-blue'>{t("sanctioning_applications.choose_file")}</Button>
                                </Upload>
                            </Col>
                            <Col xs={{ span: 16 }}>
                                <Space direction="horizontal" size={Constant.SpaceConstant.TextFieldGutter}>
                                    <Text className='semi-bold-text'>{selectedObject?.Results ?? "_"}</Text>
                                    {selectedObject && selectedObject.Results &&
                                        <Button className='color-dark-red' type="text" onClick={() => removeFile(fileTypes.Flyer)} icon={<DeleteOutlined />} size="middle" />
                                    }

                                </Space>

                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </Modal>
    )
}
export default EditTournament
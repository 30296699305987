import React, { useEffect, useState } from 'react';
import './contactsNotification.scss';
import { useTranslation } from 'react-i18next';
import {
    Constant,
    CommonService,
    SettingService
} from '../../../services/services'
import { GetTableConfigs } from '../../../components/customTable/customTable'
import { Button, Row, Col, Form, Input, Breadcrumb, Space, Badge, Table, Empty } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import LoadingContext from '../../../components/loading/loadingContext'
import { useGlobalState } from './../../../utilities/globalState'
import PubSub from 'pubsub-js';
const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const ContactsNotification = (props) => {
    const { t } = useTranslation();
    const [submitForm] = Form.useForm();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext);
    var [entitySetting, setEntitySetting] = useState({});
    const [currentPosition] = useGlobalState('currentPosition');

    const onSubmit = () => {
        // Add/Update
        showLoading();
        let data = submitForm.getFieldsValue();
        if (!entitySetting) {
            entitySetting = data;
        }
        else {
            entitySetting.MainContactName = data.MainContactName;
            entitySetting.MainContactPhone = data.MainContactPhone;
            entitySetting.MainContactEmail = data.MainContactEmail;
            entitySetting.MainContactAddress = data.MainContactAddress;
        }

        SettingService.addUpdateMainContactDetails(entitySetting)
            .finally(() => {
                dismissLoading();
            })
            .then(result => {
                if (result.data) {
                    CommonService.presentToast('success', entitySetting.Id > 0 ? t('member.main_contact_details_update_successful') : t('member.main_contact_details_create_successful'));
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const onCancel = () => {
        SettingService.getMainContactDetails()
            .finally(() => {
            })
            .then(result => {
                if (result.data) {
                    submitForm.setFieldsValue(result.data);
                    setEntitySetting(result.data);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight())
        }, 100)

        showLoading();
        Promise.all([
            SettingService.getMainContactDetails(),
        ])
            .finally(() => {
                dismissLoading();
            })
            .then((response) => {
                if (response[0].data) {
                    submitForm.setFieldsValue(response[0].data);
                    setEntitySetting(response[0].data);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);

        return () => {
            SettingService.cancelRequest();
            clearTimeout(timeoutFn);
        }
    }, [])

    const SubmitButton = ({ form }) => {
        const [submittable, setSubmittable] = React.useState(false);

        // Watch all values
        const values = Form.useWatch([], form);
        React.useEffect(() => {
            form
                .validateFields({
                    validateOnly: true,
                })
                .then(
                    () => {
                        setSubmittable(true);
                    },
                    () => {
                        setSubmittable(false);
                    },
                );
        }, [values]);
        return (
            <Button size="large" type="primary" htmlType="submit" disabled={!submittable}>{t("common.submit")}</Button>
        );
    }

    return (
        <>
            {currentPosition && currentPosition.PositionTypeId != Constant.PositionType.ClubMember &&
            <div className="contacts-notification">
                <Breadcrumb>
                    <Breadcrumb.Item>{t("menu.main_contact_details")}</Breadcrumb.Item>
                </Breadcrumb>
                <Row id={Constant.FixItemsContainerId} className="fix-item-container">
                    <Col xs={{ span: 24 }}>
                        <Form
                            layout="vertical"
                            {...layout}
                            form={submitForm}
                            name="submitForm"
                            key='submitForm'
                            onFinish={onSubmit}
                            initialValues={entitySetting}>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={t("member.name_of_contact_person")}
                                        rules={[{ required: true, message: t("member.name_of_contact_person_is_required") }]}
                                        name="MainContactName" colon={false} labelAlign="left">
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={t("member.main_contact_phone_number")}
                                        rules={[{ required: true, message: t("member.main_contact_phone_number_is_required") }]}
                                        name="MainContactPhone" colon={false} labelAlign="left">
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={t("member.main_contact_email")}
                                        rules={[{ required: true, message: t("member.main_contact_email_is_required") }, { type: "email", message: t("member.main_contact_email_invalid") }]}
                                        name="MainContactEmail" colon={false} labelAlign="left">
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={t("member.main_postal_address")}
                                        rules={[{ required: true, message: t("member.main_postal_address_is_required") }]}
                                        name="MainContactAddress" colon={false} labelAlign="left">
                                        <TextArea rows={4} allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Space>
                                        <Badge>
                                            <BellOutlined style={{ fontSize: 18 }} />
                                        </Badge>
                                        <Badge>
                                            <span>{t("member.notification_will_be_emailed_to_above_email")}</span>
                                        </Badge>
                                    </Space>
                                </Col>
                            </Row>
                            <Row align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter]}>
                                <Col xs={{ span: 12 }} className="text-right m-t-25">
                                    <Button size="large" className="m-r-5" onClick={onCancel}>{t("common.cancel")}</Button>
                                    <SubmitButton form={submitForm} />
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </div>
            }

            {currentPosition && currentPosition.PositionTypeId == Constant.PositionType.ClubMember &&
                <>
                    <Table
                        style={{ visibility: 'hidden', height: '0px' }}
                        {...GetTableConfigs([], [], 0, "Id", null, 1)}
                    />
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_access")} />
                </>
            }
        </>
    )

}

export default ContactsNotification;
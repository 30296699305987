import React, { useEffect, useState } from 'react';
import './membershipManagement.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
    Constant,
    CommonService,
    MembershipService
} from '../../../services/services'
import { Table, Button, Row, Col, Form, Tabs, Modal, Drawer, Space, Checkbox, Badge } from 'antd';
import { GetTableConfigs, ActionButtonsCell, EditableCell } from '../../../components/customTable/customTable'
import { TrashIcon, CloseIcon } from '../../../components/icons/icons'
import LoadingContext from '../../../components/loading/loadingContext'
import { useGlobalState } from '../../../utilities/globalState'

const { confirm } = Modal;
const { TabPane } = Tabs;
const _ = require("lodash");

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const components = {
    body: {
        cell: EditableCell,
    },
};

const CreateEditMembership = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { showLoading, dismissLoading } = React.useContext(LoadingContext);
    const [originalMembers, setOriginalMembers] = useState([]);
    const [members, setMembers] = useState([]);
    const [entities, setEntities] = useState([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [defaultPositionTitle, setDefaultPositionTitle] = useState({});
    const [refresh, setRefresh] = useState(false);
    const [openFieldsChange, setOpenFieldsChange] = useState(false);
    const [originalFieldsChange, setOriginalFieldsChange] = useState([]);
    const [fieldsChange, setFieldsChange] = useState([]);
    const [clubDetailFieldsChange, setClubDetailFieldsChange] = useState([]);
    const [memberDetailFieldsChange, setMemberDetailFieldsChange] = useState([]);
    const [contactFieldsChange, setContactFieldsChange] = useState([]);
    const [positionAccessFieldsChange, setPositionAccessFieldsChange] = useState([]);
    const [currentPosition] = useGlobalState('currentPosition');
    const [tabActiveKeys, setTabActiveKeys] = useState([]);
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [form] = Form.useForm();
   
    const onSubmit = () => {
        // Validate
        var allErrorMessages = [];
        var errorClubDetailsTabActiveKey = '';
        var errorMemberDetailsTabActiveKey = '';
        var errorMemberContactTabActiveKey = '';
        var errorMemberAccessTabActiveKey = '';

        setMembers(current =>
            current.map(obj => {
                var errorMessages = [];
                if (!obj.EntityId) {
                    errorMessages.push({
                        key: 'EntityId',
                        value: t('member.club_is_required')
                    })
                }

                if (!obj.LastName) {
                    errorMessages.push({
                        key: 'LastName',
                        value: t('member.last_name_is_required')
                    })
                }

                if (!obj.FirstName) {
                    errorMessages.push({
                        key: 'FirstName',
                        value: t('member.first_name_is_required')
                    })
                }

                if (!obj.FishingCategoryId) {
                    errorMessages.push({
                        key: 'FishingCategoryId',
                        value: t('member.fishing_category_is_required')
                    })
                }
                
                if (!obj.FinancialStatusId) {
                    errorMessages.push({
                        key: 'FinancialStatusId',
                        value: t('member.financial_status_is_required')
                    })
                }

                if (!obj.MemberStatusId) {
                    errorMessages.push({
                        key: 'MemberStatusId',
                        value: t('common.status_is_required')
                    })
                }

                if (!obj.FinancialTypeId) {
                    errorMessages.push({
                        key: 'FinancialTypeId',
                        value: t('member.fishing_category_is_required')
                    })
                    errorClubDetailsTabActiveKey = "create_edit_club_details_" + obj.Id;
                }

                if (!obj.FishingTypeId) {
                    errorMessages.push({
                        key: 'FishingTypeId',
                        value: t('member.fishing_type_is_required')
                    })
                    errorClubDetailsTabActiveKey = "create_edit_club_details_" + obj.Id;
                }

                if (obj.FishingTypeId === Constant.FishingType.Junior && !obj.DateOfBirth) {
                    errorMessages.push({
                        key: 'DateOfBirth',
                        value: t('member.dob_is_required')
                    })
                    errorClubDetailsTabActiveKey = "create_edit_club_details_" + obj.Id;
                }

                if (!obj.GenderId) {
                    errorMessages.push({
                        key: 'GenderId',
                        value: t('member.gender_is_required')
                    })
                    errorClubDetailsTabActiveKey = "create_edit_club_details_" + obj.Id;
                }

                if (!obj.FinancialEnd) {
                    errorMessages.push({
                        key: 'FinancialEnd',
                        value: t('member.financial_end_is_required')
                    })
                    errorClubDetailsTabActiveKey = "create_edit_club_details_" + obj.Id;
                }

                if (!obj.TitleId) {
                    errorMessages.push({
                        key: 'TitleId',
                        value: t('member.title_is_required')
                    })
                    errorMemberDetailsTabActiveKey = "create_edit_member_details_" + obj.Id;
                }

                if (!obj.MobilePhone) {
                    errorMessages.push({
                        key: 'MobilePhone',
                        value: t('member.mobile_is_required')
                    })
                    errorMemberDetailsTabActiveKey = "create_edit_member_details_" + obj.Id;
                }

                if (!obj.Email) {
                    errorMessages.push({
                        key: 'Email',
                        value: t('member.email_is_required')
                    })
                    errorMemberDetailsTabActiveKey = "create_edit_member_details_" + obj.Id;
                }

                if (!obj.StreetNo) {
                    errorMessages.push({
                        key: 'StreetNo',
                        value: t('member.street_number_is_required')
                    })
                    errorMemberContactTabActiveKey = "create_edit_member_contact_" + obj.Id;
                }

                if (!obj.StreetLine1) {
                    errorMessages.push({
                        key: 'StreetLine1',
                        value: t('member.street_name_is_required')
                    })
                    errorMemberContactTabActiveKey = "create_edit_member_contact_" + obj.Id;
                }

                if (!obj.StreetLine2) {
                    errorMessages.push({
                        key: 'StreetLine2',
                        value: t('member.street_type_is_required')
                    })
                    errorMemberContactTabActiveKey = "create_edit_member_contact_" + obj.Id;
                }

                if (!obj.Suburb) {
                    errorMessages.push({
                        key: 'Suburb',
                        value: t('member.suburb_is_required')
                    })
                    errorMemberContactTabActiveKey = "create_edit_member_contact_" + obj.Id;
                }

                if (!obj.State) {
                    errorMessages.push({
                        key: 'State',
                        value: t('member.state_is_required')
                    })
                    errorMemberContactTabActiveKey = "create_edit_member_contact_" + obj.Id;
                }

                if (!obj.PostCode) {
                    errorMessages.push({
                        key: 'PostCode',
                        value: t('member.post_code_is_required')
                    })
                    errorMemberContactTabActiveKey = "create_edit_member_contact_" + obj.Id;
                }

                if (!obj.UserName) {
                    errorMessages.push({
                        key: 'UserName',
                        value: t('login.username_required')
                    })
                    errorMemberAccessTabActiveKey = "create_edit_member_access_" + obj.Id;
                }

                if (obj.IsAddNew && !obj.Password) {
                    errorMessages.push({
                        key: 'Password',
                        value: t('login.password_required')
                    })
                    errorMemberAccessTabActiveKey = "create_edit_member_access_" + obj.Id;
                }
                
                // Data of Birth
                if (obj.DateOfBirth) {
                    var days = new moment().diff(moment(new Date().getFullYear() + "-06-30"), 'days');
                    var currentFinancial = moment((days > 0 ? new Date().getFullYear() : new Date().getFullYear() - 1) + "-07-01");

                    var dateOfBirth = moment();
                    if (typeof obj.DateOfBirth === 'string' || obj.DateOfBirth instanceof String) {
                        var length = obj.DateOfBirth.split('/').length;
                        if (length >= 3) {
                            dateOfBirth = moment(obj.DateOfBirth, Constant.DateFormat);
                        }
                    } else {
                        dateOfBirth = moment(obj.DateOfBirth.format(Constant.DateFormatYYYMMDD));
                    }

                    var yearOld = currentFinancial.diff(dateOfBirth, 'years');
                    if (obj.FishingTypeId === Constant.FishingType.Junior && yearOld >= 16) {
                        errorMessages.push({
                            key: 'DateOfBirth',
                            value: t('member.junior_must_be_less_then_16_years_old_message', {financial_year_start: currentFinancial.format(Constant.DateFormat)})
                        })
                        errorClubDetailsTabActiveKey = "create_edit_club_details_" + obj.Id;                               
                    }
                }
                
                if (errorClubDetailsTabActiveKey.length > 0) {
                    tabActiveKeys[obj.Id] = errorClubDetailsTabActiveKey;
                } 
                else if (errorMemberDetailsTabActiveKey.length > 0) {
                    tabActiveKeys[obj.Id] = errorMemberDetailsTabActiveKey;
                }
                else if (errorMemberContactTabActiveKey.length > 0) {
                    tabActiveKeys[obj.Id] = errorMemberContactTabActiveKey;
                }
                else if (errorMemberAccessTabActiveKey.length > 0) {
                    tabActiveKeys[obj.Id] = errorMemberAccessTabActiveKey;
                }

                allErrorMessages.push(...errorMessages);
                return {
                    ...obj,
                    required: errorMessages.length > 0 ? true : false,
                    ErrorMessages: errorMessages,
                };
            }),
        );

        if (allErrorMessages.length > 0) {            
            return;
        }

        var oldMembers = _.cloneDeep(members);
        members.forEach(member => {
            if (member.DateOfBirth) {
                if (typeof member.DateOfBirth === 'string' || member.DateOfBirth instanceof String) {
                    var length = member.DateOfBirth.split('/').length;
                    if (length >= 3) {
                        member.DateOfBirth = moment(member.DateOfBirth, Constant.DateFormat).format(Constant.DateFormatYYYMMDD);
                    }
                } else {
                    member.DateOfBirth = member.DateOfBirth.format(Constant.DateFormatYYYMMDD);
                }
            }

            if (member.MemberSince) {
                if (typeof member.MemberSince === 'string' || member.MemberSince instanceof String) {
                    var length = member.MemberSince.split('/').length;
                    if (length >= 3) {
                        member.MemberSince = moment(member.MemberSince, Constant.DateFormat).format(Constant.DateFormatYYYMMDD);
                    }
                } else {
                    member.MemberSince = member.MemberSince.format(Constant.DateFormatYYYMMDD);
                }
            }

            if (member.FinancialEnd) {
                var length = member.FinancialEnd.split('/').length;
                if (length >= 3) {
                    member.FinancialEnd = moment(member.FinancialEnd, Constant.DateFormat).format(Constant.DateFormatYYYMMDD);
                }
            }
        });

        // Add/Update
        showLoading();
        MembershipService.addUpdateMemberships(members)
            .finally(() => {
                dismissLoading();
            })
            .then(result => {
                if (result.data) {
                    CommonService.presentToast('success', props.isEdit ? t('member.update_successful') : t('member.create_successful'));
                    props.handleCancel(true);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
                setMembers(oldMembers);
            });
    }

    const canSubmit = (newMembers) => {
        if (newMembers && newMembers.length > 0) {
            let inValid = newMembers.filter(x => !x.EntityId || !x.LastName || !x.FirstName || !x.FishingCategoryId || !x.FinancialTypeId || !x.FishingTypeId || !x.FinancialEnd || !x.FinancialStatusId ||
                (x.FishingTypeId === Constant.FishingType.Junior && !x.DateOfBirth) ||  !x.TitleId || !x.GenderId || !x.MobilePhone || !x.Email || !x.StreetNo || !x.StreetLine1 || !x.StreetLine2 || !x.Suburb || !x.State || !x.PostCode || !x.UserName || !x.MemberStatusId);
            if (inValid && inValid.length > 0) {
                return false
            }
            return true;
        }
        return false;
    }

    const submitConfirmation = () => {
        function customizer(newMembers, oldMembers) {
            if (newMembers.length !== oldMembers.length)
                return true;

            for (var i = 0; i < newMembers.length; i ++) {
                
                var newDateOfBirth = '';
                if (newMembers[i].DateOfBirth) {
                    if (typeof newMembers[i].DateOfBirth === 'string' || newMembers[i].DateOfBirth instanceof String) {
                        var length = newMembers[i].DateOfBirth.split('/').length;
                        if (length >= 3) {
                            newDateOfBirth = moment(newMembers[i].DateOfBirth, Constant.DateFormat).format(Constant.DateFormatYYYMMDD);
                        } else {
                            newDateOfBirth = moment(newMembers[i].DateOfBirth, Constant.DateFormatYYYMMDD).format(Constant.DateFormatYYYMMDD);
                        }
                    } else {
                        newDateOfBirth = newMembers[i].DateOfBirth.format(Constant.DateFormatYYYMMDD);
                    }
                }

                var oldDateOfBirth = '';
                if (oldMembers[i].DateOfBirth && oldMembers[i].DateOfBirth.length > 0) {
                    if (typeof oldMembers[i].DateOfBirth === 'string' || oldMembers[i].DateOfBirth instanceof String) {
                        var length = oldMembers[i].DateOfBirth.split('/').length;
                        if (length >= 3) {
                            oldDateOfBirth = moment(oldMembers[i].DateOfBirth, Constant.DateFormat).format(Constant.DateFormatYYYMMDD);
                        } else {
                            oldDateOfBirth = moment(oldMembers[i].DateOfBirth, Constant.DateFormatYYYMMDD).format(Constant.DateFormatYYYMMDD);
                        }
                    } else {
                        oldDateOfBirth = oldMembers[i].DateOfBirth.format(Constant.DateFormatYYYMMDD);
                    }
                }

                var newMemberSince = '';
                if (newMembers[i].MemberSince) {
                    if (typeof newMembers[i].MemberSince === 'string' || newMembers[i].MemberSince instanceof String) {
                        var length = newMembers[i].MemberSince.split('/').length;
                        if (length >= 3) {
                            newMemberSince = moment(newMembers[i].MemberSince, Constant.DateFormat).format(Constant.DateFormatYYYMMDD);
                        } else {
                            newMemberSince = moment(newMembers[i].MemberSince, Constant.DateFormatYYYMMDD).format(Constant.DateFormatYYYMMDD);
                        }
                    } else {
                        newMemberSince = newMembers[i].MemberSince.format(Constant.DateFormatYYYMMDD);
                    }
                }

                var oldMemberSince = '';
                if (oldMembers[i].MemberSince && oldMembers[i].MemberSince.length > 0) {
                    if (typeof oldMembers[i].MemberSince === 'string' || oldMembers[i].MemberSince instanceof String) {
                        var length = oldMembers[i].MemberSince.split('/').length;
                        if (length >= 3) {
                            oldMemberSince = moment(oldMembers[i].MemberSince, Constant.DateFormat).format(Constant.DateFormatYYYMMDD);
                        } else {
                            oldMemberSince = moment(oldMembers[i].MemberSince, Constant.DateFormatYYYMMDD).format(Constant.DateFormatYYYMMDD);
                        }
                    } else {
                        oldMemberSince = oldMembers[i].MemberSince.format(Constant.DateFormatYYYMMDD);
                    }
                }

                var newFinancialEnd = '';
                if (newMembers[i].FinancialEnd) {
                    if (typeof newMembers[i].FinancialEnd === 'string' || newMembers[i].FinancialEnd instanceof String) {
                        var length = newMembers[i].FinancialEnd.split('/').length;
                        if (length >= 3) {
                            newFinancialEnd = moment(newMembers[i].FinancialEnd, Constant.DateFormat).format(Constant.DateFormatYYYMMDD);
                        } else {
                            newFinancialEnd = moment(newMembers[i].FinancialEnd, Constant.DateFormatYYYMMDD).format(Constant.DateFormatYYYMMDD);
                        }
                    } else {
                        newFinancialEnd = newMembers[i].FinancialEnd.format(Constant.DateFormatYYYMMDD);
                    }
                }

                var oldFinancialEnd = '';
                if (oldMembers[i].FinancialEnd && oldMembers[i].FinancialEnd.length > 0) {
                    if (typeof oldMembers[i].FinancialEnd === 'string' || oldMembers[i].FinancialEnd instanceof String) {
                        var length = oldMembers[i].FinancialEnd.split('/').length;
                        if (length >= 3) {
                            oldFinancialEnd = moment(oldMembers[i].FinancialEnd, Constant.DateFormat).format(Constant.DateFormatYYYMMDD);
                        } else {
                            oldFinancialEnd = moment(oldMembers[i].FinancialEnd, Constant.DateFormatYYYMMDD).format(Constant.DateFormatYYYMMDD);
                        }
                    } else {
                        oldFinancialEnd = oldMembers[i].FinancialEnd.format(Constant.DateFormatYYYMMDD);
                    }
                }

                if (newMembers[i].IsAddNew || oldMembers[i].IsAddNew) {
                    if (((newMembers[i].TitleId !== null || oldMembers[i].TitleId !== null) && newMembers[i].TitleId !== oldMembers[i].TitleId) ||
                        ((newMembers[i].GenderId !== null || oldMembers[i].GenderId !== null) && newMembers[i].GenderId !== oldMembers[i].GenderId) ||
                        ((newMembers[i].FirstName !== null || oldMembers[i].FirstName !== null) && newMembers[i].FirstName !== oldMembers[i].FirstName) ||
                        ((newMembers[i].LastName !== null || oldMembers[i].LastName !== null) && newMembers[i].LastName !== oldMembers[i].LastName) ||
                        ((newMembers[i].MobilePhone !== null || oldMembers[i].MobilePhone !== null) && newMembers[i].MobilePhone !== oldMembers[i].MobilePhone) ||
                        ((newMembers[i].Email !== null || oldMembers[i].Email !== null) && newMembers[i].Email !== oldMembers[i].Email) ||
                        (newDateOfBirth !== oldDateOfBirth) ||
                        ((newMembers[i].Comments !== null || oldMembers[i].Comments !== null) && newMembers[i].Comments !== oldMembers[i].Comments) ||
                        ((newMembers[i].FishingTypeId !== null || oldMembers[i].FishingTypeId !== null) && newMembers[i].FishingTypeId !== oldMembers[i].FishingTypeId) ||
                        ((newMembers[i].FishingCategoryId !== null || oldMembers[i].FishingCategoryId !== null) && newMembers[i].FishingCategoryId !== oldMembers[i].FishingCategoryId) ||
                        ((newMembers[i].FinancialTypeId !== null || oldMembers[i].FinancialTypeId !== null) && newMembers[i].FinancialTypeId !== oldMembers[i].FinancialTypeId) ||
                        (newFinancialEnd !== oldFinancialEnd) ||
                        ((newMembers[i].FamilyGroupId !== null || oldMembers[i].FamilyGroupId !== null) && newMembers[i].FamilyGroupId !== oldMembers[i].FamilyGroupId) ||
                        ((newMembers[i].Flagged !== null || oldMembers[i].Flagged !== null) && newMembers[i].Flagged !== oldMembers[i].Flagged) ||
                        ((newMembers[i].StreetNo !== null || oldMembers[i].StreetNo !== null) && newMembers[i].StreetNo !== oldMembers[i].StreetNo) ||
                        ((newMembers[i].StreetLine1 !== null || oldMembers[i].StreetLine1 !== null) && newMembers[i].StreetLine1 !== oldMembers[i].StreetLine1) ||
                        ((newMembers[i].StreetLine2 !== null || oldMembers[i].StreetLine2 !== null) && newMembers[i].StreetLine2 !== oldMembers[i].StreetLine2) ||
                        ((newMembers[i].Suburb !== null || oldMembers[i].Suburb !== null) && newMembers[i].Suburb !== oldMembers[i].Suburb) ||
                        ((newMembers[i].State !== null || oldMembers[i].State !== null) && newMembers[i].State !== oldMembers[i].State) ||
                        ((newMembers[i].PostCode !== null || oldMembers[i].PostCode !== null) && newMembers[i].PostCode !== oldMembers[i].PostCode) ||
                        ((newMembers[i].CountryCode !== null || oldMembers[i].CountryCode !== null) && newMembers[i].CountryCode !== oldMembers[i].CountryCode)) {
                        return true;
                    }
                }
                else {
                    if (((newMembers[i].TitleId !== null || oldMembers[i].TitleId !== null) && newMembers[i].TitleId !== oldMembers[i].TitleId) ||
                        ((newMembers[i].GenderId !== null || oldMembers[i].GenderId !== null) && newMembers[i].GenderId !== oldMembers[i].GenderId) ||
                        ((newMembers[i].FirstName !== null || oldMembers[i].FirstName !== null) && newMembers[i].FirstName !== oldMembers[i].FirstName) ||
                        ((newMembers[i].LastName !== null || oldMembers[i].LastName !== null) && newMembers[i].LastName !== oldMembers[i].LastName) ||
                        ((newMembers[i].MobilePhone !== null || oldMembers[i].MobilePhone !== null) && newMembers[i].MobilePhone !== oldMembers[i].MobilePhone) ||
                        ((newMembers[i].Email !== null || oldMembers[i].Email !== null) && newMembers[i].Email !== oldMembers[i].Email) ||
                        (newDateOfBirth !== oldDateOfBirth) ||
                        (newMemberSince !== oldMemberSince) ||
                        ((newMembers[i].Comments !== null || oldMembers[i].Comments !== null) && newMembers[i].Comments !== oldMembers[i].Comments) ||
                        ((newMembers[i].FishingTypeId !== null || oldMembers[i].FishingTypeId !== null) && newMembers[i].FishingTypeId !== oldMembers[i].FishingTypeId) ||
                        ((newMembers[i].FishingCategoryId !== null || oldMembers[i].FishingCategoryId !== null) && newMembers[i].FishingCategoryId !== oldMembers[i].FishingCategoryId) ||
                        ((newMembers[i].FinancialTypeId !== null || oldMembers[i].FinancialTypeId !== null) && newMembers[i].FinancialTypeId !== oldMembers[i].FinancialTypeId) ||
                        ((newMembers[i].FinancialStatusId !== null || oldMembers[i].FinancialStatusId !== null) && newMembers[i].FinancialStatusId !== oldMembers[i].FinancialStatusId) ||
                        (newFinancialEnd !== oldFinancialEnd) ||
                        ((newMembers[i].FamilyGroupId !== null || oldMembers[i].FamilyGroupId !== null) && newMembers[i].FamilyGroupId !== oldMembers[i].FamilyGroupId) ||
                        ((newMembers[i].Flagged !== null || oldMembers[i].Flagged !== null) && newMembers[i].Flagged !== oldMembers[i].Flagged) ||
                        ((newMembers[i].MemberStatusId !== null || oldMembers[i].MemberStatusId !== null) && newMembers[i].MemberStatusId !== oldMembers[i].MemberStatusId) ||
                        ((newMembers[i].UserName !== null || oldMembers[i].UserName !== null) && newMembers[i].UserName !== oldMembers[i].UserName) ||
                        ((newMembers[i].StreetNo !== null || oldMembers[i].StreetNo !== null) && newMembers[i].StreetNo !== oldMembers[i].StreetNo) ||
                        ((newMembers[i].StreetLine1 !== null || oldMembers[i].StreetLine1 !== null) && newMembers[i].StreetLine1 !== oldMembers[i].StreetLine1) ||
                        ((newMembers[i].StreetLine2 !== null || oldMembers[i].StreetLine2 !== null) && newMembers[i].StreetLine2 !== oldMembers[i].StreetLine2) ||
                        ((newMembers[i].Suburb !== null || oldMembers[i].Suburb !== null) && newMembers[i].Suburb !== oldMembers[i].Suburb) ||
                        ((newMembers[i].State !== null || oldMembers[i].State !== null) && newMembers[i].State !== oldMembers[i].State) ||
                        ((newMembers[i].PostCode !== null || oldMembers[i].PostCode !== null) && newMembers[i].PostCode !== oldMembers[i].PostCode) ||
                        ((newMembers[i].CountryCode !== null || oldMembers[i].CountryCode !== null) && newMembers[i].CountryCode !== oldMembers[i].CountryCode)) {
                        return true;
                    }

                    if (newMembers[i].MemberPositions !== null && oldMembers[i].MemberPositions !== null &&
                        newMembers[i].MemberPositions.length !== oldMembers[i].MemberPositions.length) {
                        return true;
                    }

                    if (newMembers[i].MemberPositions !== null && oldMembers[i].MemberPositions !== null &&
                        newMembers[i].MemberPositions.length === oldMembers[i].MemberPositions.length) {
                        var newMemberPositions = _.sortBy(newMembers[i].MemberPositions, [function(m) { return m.Id; }]);
                        var oldMemberPositions = _.sortBy(oldMembers[i].MemberPositions, [function(m) { return m.Id; }]);

                        for (var j = 0; j < newMemberPositions.length; j ++) {
                            if (((newMemberPositions[j].PositionTitleId !== null || oldMemberPositions[j].PositionTitleId !== null) && newMemberPositions[j].PositionTitleId !== oldMemberPositions[j].PositionTitleId) ||
                                ((newMemberPositions[j].EntityId !== null || oldMemberPositions[j].EntityId !== null) && newMemberPositions[j].EntityId !== oldMemberPositions[j].EntityId)) {
                                return true;
                            }
                        }
                    }
                }
            }

            return false;
        }
        
        var newMembers = _.sortBy(members, [function(m) { return m.Id; }]);
        var oldMembers = _.sortBy(originalMembers, [function(m) { return m.Id; }]);
        var changed = _.isEqualWith(newMembers, oldMembers, customizer);
        if (changed) {
            confirm({
                title: t("common.submit_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    onSubmit();
                },
                onCancel() {
                    props.handleCancel(true);
                }
            });
        }
        else {
            props.handleCancel(false);
        }
    }

    const onClose = () => {
        submitConfirmation();
    }

    const addMember = () => {
        var id = -(members.length + 1);
        var currentEntityId = null;
        var currentEntityName = null;
        if (entities && entities.length > 0) {
            var clubEntities = entities.filter(x => x.EntityTypeId === Constant.EntityType.Club)
            if (clubEntities && clubEntities.length === 1) {
                currentEntityId = clubEntities[0].Id;
                currentEntityName = clubEntities[0].Name;
            }
        }
        var newMembers = [...members, newMember(id, defaultPositionTitle, currentEntityId, currentEntityName)];
        for (let index = 0; index < newMembers.length; index++) {
            if (newMembers[index].DateOfBirth) {
                if (typeof newMembers[index].DateOfBirth === 'string' || newMembers[index].DateOfBirth instanceof String) {
                    var length = newMembers[index].DateOfBirth.split('/').length;
                    if (length >= 3) {
                        newMembers[index].DateOfBirth = moment(newMembers[index].DateOfBirth, Constant.DateFormat).format(Constant.DateFormat);
                    }
                } else {
                    newMembers[index].DateOfBirth = newMembers[index].DateOfBirth.format(Constant.DateFormat);
                }
            }

            if (newMembers[index].MemberSince) {
                if (typeof newMembers[index].MemberSince === 'string' || newMembers[index].MemberSince instanceof String) {
                    var length = newMembers[index].MemberSince.split('/').length;
                    if (length >= 3) {
                        newMembers[index].MemberSince = moment(newMembers[index].MemberSince, Constant.DateFormat).format(Constant.DateFormat);
                    }
                } else {
                    newMembers[index].MemberSince = newMembers[index].MemberSince.format(Constant.DateFormat);
                }
            }

            if (newMembers[index].FinancialEnd) {
                var length = newMembers[index].FinancialEnd.split('/').length;
                if (length >= 3) {
                    newMembers[index].FinancialEnd = moment(newMembers[index].FinancialEnd, Constant.DateFormat).format(Constant.DateFormat);
                }
            }
        }
        setMembers(newMembers);
        setExpandedRowKeys([...expandedRowKeys, id]);
    }

    const deleteMember = (record) => {
        if (!record.IsAddNew && record.Id > 0) {
            confirm({
                title: t("member.delete_member_confirm"),
                content: t("member.delete_warm_message"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    showLoading();
    
                    MembershipService.deleteMemberships([record.Id])
                    .finally(() => {
                        dismissLoading();
                    })
                    .then(result => {
                        const newMembers = members.filter((m) => m.Id !== record.Id);
                        setMembers(newMembers);
                        setRefresh(true);
                    })
                    .catch(error => {
                        CommonService.handleErrorResponse(error);
                    });
                }
            });
        } else {
            const newMembers = members.filter((m) => m.Id !== record.Id);
            setMembers(newMembers);
        }
    }
    
    const handleOnChange = (value, record, dataIndex) => {
        if (record) {
            var index = members.findIndex(x => x.Id === record.Id);
            _.update(members[index], dataIndex, function (n) { 
                return value;
            });

            updateFieldsChange(members[index]);
        }

        setEnableSubmit(canSubmit(members));
    }

    const handleFirstNameAndLastNameOnChange = (value, record, dataIndex) => {
        if (record) {
            var index = members.findIndex(x => x.Id === record.Id);
            _.update(members[index], dataIndex, function (n) { 
                return value;
            });

            updateFieldsChange(members[index]);

            if (dataIndex === 'FirstName' || dataIndex === 'LastName') {
                if (members[index].IsAddNew) {
                    var userName = '';
                    if (members[index].FirstName && members[index].LastName) {  
                        var firstName = members[index].FirstName.toLowerCase().trim().replace(/\s/g, '');
                        var lastName = members[index].LastName.toLowerCase().trim().replace(/\s/g, '');
                        if (firstName.length >= 40) {
                            firstName = firstName.substring(0, 20);
                        }

                        if (lastName.length >= 40) {
                            lastName = lastName.substring(0, 20);
                        }

                        userName = firstName + "" + lastName;
                    } else if (members[index].FirstName) {  
                        userName = members[index].FirstName.toLowerCase().trim().replace(/\s/g, '');
                    } else if (members[index].LastName) {  
                        userName = members[index].LastName.toLowerCase().trim().replace(/\s/g, '');
                    }

                    if (userName.length >= 40) {
                        userName = userName.substring(0, 39);
                    }

                    if (userName.length > 0) {
                        getNextUserName(index, userName, 0);
                    }
                }
            }
        }

        setEnableSubmit(canSubmit(members));
    }

    const getNextUserName = (index, userName, sequence) => {
        if (index < 0 && members.length > 0)
            return;

        MembershipService.getNextUserName(members[index].Id, userName, sequence)
            .finally(() => {
            })
            .then(result => {
                var newUserName = userName;
                console.log(userName);
                if (result.data && result.data.length > 0) {
                    newUserName = result.data;
                }
                members[index].UserName = newUserName;
                setMembers(current =>
                    current.map(obj => {
                        if (obj.Id === members[index].Id) {
                            return {
                                ...obj,
                                UserName: newUserName
                            };
                        }
                        return obj;
                    }),
                );
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const handleFinancialEndOnChange = (value, record, dataIndex) => {
        if (record) {
            var index = members.findIndex(x => x.Id === record.Id);
            _.update(members[index], dataIndex, function (n) { 
                return value;
            });

            var financialStatusId = members[index].FinancialStatusId;
            if (members[index].FinancialEnd) {
                var financialEnd = null;
                var length = members[index].FinancialEnd.split('/').length;
                if (length >= 3) {
                    financialEnd = moment(members[index].FinancialEnd, Constant.DateFormat);
                } else {
                    financialEnd = moment(members[index].FinancialEnd)
                }

                if (!financialEnd) {
                    financialStatusId = Constant.FinancialStatus.UnFinancial;
                } else {
                    var currentDate = moment();
                    var days = currentDate.diff(financialEnd, 'days');
                    if (days <= 0) {
                        financialStatusId = Constant.FinancialStatus.Financial;
                    } else {
                        financialStatusId = Constant.FinancialStatus.UnFinancial;
                    }
                }
            } else {
                financialStatusId = Constant.FinancialStatus.UnFinancial;
            }

            if (!financialStatusId) {
                financialStatusId = Constant.FinancialStatus.UnFinancial;
            }

            members[index].FinancialStatusId = financialStatusId;

            var memberStatusId = members[index].MemberStatusId;
            if (financialStatusId === Constant.FinancialStatus.Financial) {
                memberStatusId = Constant.MemberStatusCode.Active;
            } else if (financialStatusId === Constant.FinancialStatus.UnFinancial) {
                memberStatusId = Constant.MemberStatusCode.Inactive;
            }
            members[index].MemberStatusId = memberStatusId;

            setMembers(current =>
                current.map(obj => {
                    if (obj.Id === members[index].Id) {
                        return {
                            ...obj,
                            FinancialStatusId: financialStatusId,
                            MemberStatusId: memberStatusId
                        };
                    }
                    return obj;
                }),
            );

            updateFieldsChange(members[index]);
        }

        setEnableSubmit(canSubmit(members));
    }


    const handleFishingTypeOnChange = (value, record, dataIndex) => {
        if (record) {
            var index = members.findIndex(x => x.Id === record.Id);
            _.update(members[index], dataIndex, function (n) { 
                return value;
            });

            updateFieldsChange(members[index]);
            
            // Title
            if (dataIndex === 'TitleId') {
                var genderId = members[index].GenderId;
                if (members[index].TitleId === Constant.Title.Mr) {
                    genderId = Constant.Gender.Male;
                } 
                else if (members[index].TitleId === Constant.Title.Miss || members[index].TitleId === Constant.Title.Mrs || members[index].TitleId === Constant.Title.Ms) {
                    genderId = Constant.Gender.Female;
                }
                members[index].GenderId = genderId;
                
                setMembers(current =>
                    current.map(obj => {
                        if (obj.Id === members[index].Id) {
                            return {
                                ...obj,
                                GenderId: genderId
                            };
                        }
                        return obj;
                    }),
                );
            }
           
            var days = new moment().diff(moment(new Date().getFullYear() + "-06-30"), 'days');
            var currentFinancial = moment((days > 0 ? new Date().getFullYear() : new Date().getFullYear() - 1) + "-07-01");           
           
            if (members[index].FishingTypeId === Constant.FishingType.Senior || 
                members[index].FishingTypeId === Constant.FishingType.Junior) {
                var fishingCategoryId = members[index].FishingCategoryId;
                if (members[index].DateOfBirth) {
                    var dateOfBirth = moment(members[index].DateOfBirth.format(Constant.DateFormatYYYMMDD));
                    var yearOld = currentFinancial.diff(dateOfBirth, 'years');
                    if (yearOld < 11) {
                        // Small Fry Male/Small Fry Female
                        if (members[index].GenderId === Constant.Gender.Male) {
                            fishingCategoryId = Constant.FishingCategory.SmallFryMale;
                        } else if (members[index].GenderId === Constant.Gender.Female) {
                            fishingCategoryId = Constant.FishingCategory.SmallFryFemale;
                        }
                    } 
                    else if (yearOld >= 11 && yearOld < 16) {
                        // Junior Male/Junior Female
                        if (members[index].GenderId === Constant.Gender.Male) {
                            fishingCategoryId = Constant.FishingCategory.JuniorMale;
                        } else if (members[index].GenderId === Constant.Gender.Female) {
                            fishingCategoryId = Constant.FishingCategory.JuniorFemale;
                        }
    
                    } 
                    else if (yearOld >= 16) {
                        // Male/Female
                        if (members[index].GenderId === Constant.Gender.Male) {
                            fishingCategoryId = Constant.FishingCategory.Men;
                        } else if (members[index].GenderId === Constant.Gender.Female) {
                            fishingCategoryId = Constant.FishingCategory.Women;
                        }
                    }
    
                    members[index].FishingCategoryId = fishingCategoryId;
                } else {
                    if (members[index].FishingTypeId === Constant.FishingType.Senior) {
                        if (members[index].GenderId === Constant.Gender.Male) {
                            fishingCategoryId = Constant.FishingCategory.Men;
                        } else if (members[index].GenderId === Constant.Gender.Female) {
                            fishingCategoryId = Constant.FishingCategory.Women;
                        } else {
                            fishingCategoryId = Constant.FishingCategory.Men;
                        }
                    } else {
                        if (members[index].GenderId === Constant.Gender.Male) {
                            fishingCategoryId = Constant.FishingCategory.JuniorMale;
                        } else if (members[index].GenderId === Constant.Gender.Female) {
                            fishingCategoryId = Constant.FishingCategory.JuniorFemale;
                        } else {
                            fishingCategoryId = Constant.FishingCategory.JuniorMale;
                        }
                    }
                    members[index].FishingCategoryId = fishingCategoryId;
                }

                setMembers(current =>
                    current.map(obj => {
                        if (obj.Id === members[index].Id) {
                            var errorMessages = [];

                            if (obj.DateOfBirth) {
                                var dateOfBirth = moment(obj.DateOfBirth.format(Constant.DateFormatYYYMMDD));
                                var yearOld = currentFinancial.diff(dateOfBirth, 'years');
                                if (obj.FishingTypeId === Constant.FishingType.Junior && yearOld >= 16) {
                                    errorMessages.push({
                                        key: 'DateOfBirth',
                                        value: t('member.junior_must_be_less_then_16_years_old_message', {financial_year_start: currentFinancial.format(Constant.DateFormat)})
                                    })                              
                                }
                            }

                            return {
                                ...obj,
                                required: obj.FishingTypeId === Constant.FishingType.Junior || errorMessages.length > 0 ? true : false,
                                FishingCategoryId: fishingCategoryId,
                                ErrorMessages: errorMessages,
                            };
                        }

                        return obj;
                    }),
                );
            }
            else if (members[index].FishingTypeId === Constant.FishingType.NonFishing) {
                setMembers(current =>
                    current.map(obj => {
                        if (obj.Id === members[index].Id) {
                            return {
                                ...obj,
                                required: false,
                                FishingCategoryId: Constant.FishingCategory.NonFishing,
                                ErrorMessages: [],
                            };
                        }

                        return obj;
                    }),
                );
            }
        }

        setEnableSubmit(canSubmit(members));
    }

    const handleClubOnChange = (value, record, dataIndex) => {
        setMembers(current =>
            current.map(obj => {
                if (obj.Id === record.Id) {
                    var memberPosition = _.find(obj.MemberPositions, function(m) { return m.EntityId === value && m.MemberId === record.Id; });
                    if (!memberPosition && defaultPositionTitle) {
                        var newMemberPositions = obj.MemberPositions.filter((m) => m.EntityId !== record.EntityId);
                        return {
                            ...obj,
                            EntityId: value,
                            MemberPositions: [...newMemberPositions, {
                                Id: -(_.random(0, 10000)),
                                MemberId: record.Id,
                                PositionTitleId: defaultPositionTitle.PositionTitleId,
                                EntityId: value,
                                PositionTypeId: defaultPositionTitle.PositionTypeId
                            }]
                        };
                    } else {
                        return {
                            ...obj,
                            EntityId: value
                        };
                    }
                }
                return obj;
            }),
        );
    }

    const handleFamilyGroupOnChange = (value, record, dataIndex) => {
        if (record) {
            var index = members.findIndex(x => x.Id === record.Id);
            _.update(members[index], dataIndex, function (n) { 
                return value;
            });
        }
    }

    const getStateClubTitle = () => {
        if (currentPosition != null && (currentPosition.EntityTypeId === Constant.EntityType.Gamebase || currentPosition.EntityTypeId === Constant.EntityType.National)) {
            return t('member.national_association');
        } else if (currentPosition != null && currentPosition.EntityTypeId === Constant.EntityType.State) {
            return t('member.state_association');
        } else {
            return t('member.club');
        }
    }

    const getMemberTitle = () => {
        return currentPosition != null && currentPosition.StateAssociationAcronym != null && currentPosition.StateAssociationAcronym.length > 0 ? currentPosition.StateAssociationAcronym + " #" : t('member.member_number');
    }

    const isClub = () => {
        return currentPosition != null && currentPosition.EntityTypeId === Constant.EntityType.Club;
    }

    var clubColumns = [
        {
            title: t('common.action'),
            key: 'Id',
            render: (value, record) => {
                return <ActionButtonsCell data={record} btnList={[
                    {
                        Description: t("common.delete"),
                        Icon: <TrashIcon />,
                        ColorClass: Constant.ColorClass.LightRed,
                        Hide: false,
                        NoMargin: true,
                        handleAction: deleteMember
                    }
                ]} />
            },
            align: 'center',
            width: 40,
        },
        {
            title: <span>{ getMemberTitle() }</span>,
            dataIndex: 'MemberId',
            key: 'MemberId',
            sorter: false,
            width: 55,
        },
        {
            title: <span>{ getStateClubTitle() }</span>,
            dataIndex: 'EntityName',
            key: 'EntityName',
            sorter: false,
            width: 110,
        },
        {
            title: <span className='required'>{ t('member.last_name') }</span>,
            dataIndex: 'LastName',
            key: 'LastName',
            editable: true,
            required: true,
            cellType: Constant.CellType.Input,
            width: 80,
            handleOnChange: handleFirstNameAndLastNameOnChange,
        },
        {
            title: <span className='required'>{ t('member.first_name') }</span>,
            dataIndex: 'FirstName',
            key: 'FirstName',
            editable: true,
            required: true,
            cellType: Constant.CellType.Input,
            width: 80,
            handleOnChange: handleFirstNameAndLastNameOnChange,
        },
        {
            title: t('member.family_group'),
            dataIndex: 'FamilyGroupId',
            key: 'FamilyGroupId',
            editable: true,
            extraData: props.familyGroups,
            required: false,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "Name",
            width: 80,
            handleOnChange: handleFamilyGroupOnChange,
        },
        {
            title: <span className='required'>{ t('member.fishing_category') }</span>,
            dataIndex: 'FishingCategoryId',
            key: 'FishingCategoryId',
            editable: true,
            extraData: props.fishingCategories,
            required: true,
            disabled: true,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "Name",
            selectionSort: "Id",
            width: 90,
            handleOnChange: handleOnChange,
        },
        {
            title: <span className='required'>{ t('member.financial_status') }</span>,
            dataIndex: 'FinancialStatusId',
            key: 'FinancialStatusId',
            editable: true,
            extraData: props.financialStatuses,
            required: true,
            disabled: true,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "Name",
            selectionSort: "Id",
            width: 90,
            handleOnChange: handleOnChange,
        },
        {
            title: <span className='required'>{ t('common.status') }</span>,
            dataIndex: 'MemberStatusId',
            key: 'MemberStatusId',
            editable: true,
            extraData: props.memberStatuses,
            required: true,
            cellType: Constant.CellType.StatusSelection,
            selectionKey: "Id",
            selectionDisplay: "Name",
            width: 65,
            allowClear: false,
            handleOnChange: handleOnChange
        }
    ]

    var stateColumns = [
        {
            title: t('common.action'),
            key: 'Id',
            render: (value, record) => {
                return <ActionButtonsCell data={record} btnList={[
                    {
                        Description: t("common.delete"),
                        Icon: <TrashIcon />,
                        ColorClass: Constant.ColorClass.LightRed,
                        Hide: false,
                        NoMargin: true,
                        handleAction: deleteMember
                    }
                ]} />
            },
            align: 'center',
            width: 32,
        },
        {
            title: <span>{ getMemberTitle() }</span>,
            dataIndex: 'MemberId',
            key: 'MemberId',
            sorter: false,
            width: 40,
        },
        {
            title: <span className='required'>{ t('member.club') }</span>,
            dataIndex: 'EntityId',
            key: 'EntityId',
            editable: true,
            required: true,
            extraData: entities,
            cellType: Constant.CellType.ClubSelection,
            selectionKey: "Id",
            selectionDisplay: "Name",
            width: 110,
            handleOnChange: handleClubOnChange,
        },
        {
            title: <span className='required'>{ t('member.last_name') }</span>,
            dataIndex: 'LastName',
            key: 'LastName',
            editable: true,
            required: true,
            cellType: Constant.CellType.Input,
            width: 60,
            handleOnChange: handleFirstNameAndLastNameOnChange,
        },
        {
            title: <span className='required'>{ t('member.first_name') }</span>,
            dataIndex: 'FirstName',
            key: 'FirstName',
            editable: true,
            required: true,
            cellType: Constant.CellType.Input,
            width: 60,
            handleOnChange: handleFirstNameAndLastNameOnChange,
        },
        {
            title: t('member.family_group'),
            dataIndex: 'FamilyGroupId',
            key: 'FamilyGroupId',
            editable: true,
            extraData: props.familyGroups,
            required: false,
            cellType: Constant.CellType.FamilyGroupSelection,
            selectionKey: "Id",
            selectionDisplay: "Name",
            width: 60,
            handleOnChange: handleFamilyGroupOnChange,
        },
        {
            title: <span className='required'>{ t('member.fishing_category') }</span>,
            dataIndex: 'FishingCategoryId',
            key: 'FishingCategoryId',
            editable: true,
            extraData: props.fishingCategories,
            required: true,
            disabled: true,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "Name",
            selectionSort: "Id",
            width: 70,
            handleOnChange: handleOnChange,
        },
        {
            title: <span className='required'>{ t('member.financial_status') }</span>,
            dataIndex: 'FinancialStatusId',
            key: 'FinancialStatusId',
            editable: true,
            extraData: props.financialStatuses,
            required: true,
            disabled: true,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "Name",
            selectionSort: "Id",
            width: 66,
            handleOnChange: handleOnChange,
        },
        {
            title: <span className='required'>{ t('common.status') }</span>,
            dataIndex: 'MemberStatusId',
            key: 'MemberStatusId',
            editable: true,
            extraData: props.memberStatuses,
            required: true,
            cellType: Constant.CellType.StatusSelection,
            selectionKey: "Id",
            selectionDisplay: "Name",
            width: 50,
            allowClear: false,
            handleOnChange: handleOnChange
        }
    ]

    var columns = isClub() ? clubColumns : stateColumns;
    const memberColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                disabled: col.disabled,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition,
                selectionKey: col.selectionKey,
                selectionDisplay: col.selectionDisplay,
                validateAtStart: col.validateAtStart,
                allowClear: col.allowClear,
                selectionSort: col.selectionSort,
            }),
        };
    })

    const onTabClick = (key, evt) => {
    }

    const expandedRowRender = (record, index, indent, expanded) => {
        return <Tabs defaultActiveKey={tabActiveKeys[record.Id]} activeKey={tabActiveKeys[record.Id]} onTabClick={onTabClick}>
            <TabPane tab={t("member.membership_details")} key={"create_edit_club_details_" + record.Id}>
                <Row>
                    <Col xs={{ span: 24 }}>
                        {_getTableClubDetails(record)}
                    </Col>
                </Row>
            </TabPane>
            <TabPane tab={t("member.member_details")} key={"create_edit_member_details_" + record.Id}>
                <Row>
                    <Col xs={{ span: 24 }}>
                    {_getTableMemberDetails(record)}
                    </Col>
                </Row>
            </TabPane>
            <TabPane tab={t("member.address")} key={"create_edit_member_contact_" + record.Id}>
                <Row>
                    <Col xs={{ span: 24 }}>
                        {_getTableMemberContact(record)}
                    </Col>
                </Row>
            </TabPane>
            <TabPane tab={t("member.position_access")} key={"create_edit_position_access_" + record.Id}>
                <Row>
                    <Col xs={{ span: 24 }}>
                        {_getTablePositionAccess(record)}
                    </Col>
                </Row>
            </TabPane>
            <TabPane tab={t("member.member_access")} key={"create_edit_member_access_" + record.Id}>
                <Row>
                    <Col xs={{ span: 24 }}>
                        {_getTableMemberAccess(record)}
                    </Col>
                </Row>
            </TabPane>
        </Tabs>
    }
    
    // 1. CLUB DETAILS
    const _getTableClubDetails = (member) => {
        var clubDetailsColumns = [
            {
                title: <span className='required'>{ t('member.financial_type') }</span>,
                dataIndex: 'FinancialTypeId',
                key: 'FinancialTypeId',
                editable: true,
                extraData: props.financialTypes,
                required: true,
                cellType: Constant.CellType.EnhancedSelect,
                selectionKey: "Id",
                selectionDisplay: "Name",
                selectionSort: "Id",
                width: 100,
                handleOnChange: handleOnChange,
            },
            {
                title: <span className='required'>{ t('member.fishing_type') }</span>,
                dataIndex: 'FishingTypeId',
                key: 'FishingTypeId',
                editable: true,
                extraData: props.fishingTypes,
                required: true,
                validateAtStart: true,
                cellType: Constant.CellType.EnhancedSelect,
                selectionKey: "Id",
                selectionDisplay: "Name",
                selectionSort: "Id",
                width: 80,
                handleOnChange: handleFishingTypeOnChange,
            },
            {
                title: <span className={member.FishingTypeId === Constant.FishingType.Junior ? 'required' : ''}>{ t('member.dob') }</span>,
                dataIndex: 'DateOfBirth',
                key: 'DateOfBirth',
                editable: true,
                required: true,
                validateAtStart: true,
                cellType: Constant.CellType.MemberDateOfBirth,
                width: 80,
                handleOnChange: handleFishingTypeOnChange,
            },
            {
                title: <span className='required'>{ t('member.gender') }</span>,
                dataIndex: 'GenderId',
                key: 'GenderId',
                editable: true,
                extraData: props.genders,
                required: true,
                validateAtStart: true,
                cellType: Constant.CellType.EnhancedSelect,
                selectionKey: "Id",
                selectionDisplay: "Name",
                selectionSort: "Id",
                width: 60,
                handleOnChange: handleFishingTypeOnChange,
            },
            {
                title: <span className='required'>{ t('member.financial_end') }</span>,
                dataIndex: 'FinancialEnd',
                key: 'FinancialEnd',
                editable: true,
                required: true,
                extraData: props.financialYears,
                validateAtStart: true,
                cellType: Constant.CellType.EnhancedSelect,
                selectionKey: "Code",
                selectionDisplay: "Name",
                width: 80,
                handleOnChange: handleFinancialEndOnChange,
            },
            {
                title: t('member.flag_member'),
                dataIndex: 'Flagged',
                key: 'Flagged',
                editable: true,
                cellType: Constant.CellType.Checkbox,
                align: "center",
                width: 80,
                handleOnChange: handleOnChange,
            },
            {
                title: t('member.comments'),
                dataIndex: 'Comments',
                key: 'Comments',
                editable: true,
                cellType: Constant.CellType.Input,
                width: 100,
                handleOnChange: handleOnChange,
            }
        ];

        const editableColumns = clubDetailsColumns.map((col) => {
            if (!col.editable) {
                return col;
            }
    
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    required: col.required,
                    cellType: col.cellType,
                    handleOnChange: col.handleOnChange,
                    extraData: col.extraData,
                    errorTextPosition: col.errorTextPosition,
                    selectionKey: col.selectionKey,
                    selectionDisplay: col.selectionDisplay,
                    validateAtStart: col.validateAtStart,
                    allowClear: col.allowClear,
                    selectionSort: col.selectionSort
                }),
            };
        });

        return <Table components={components} 
                tableLayout='fixed'
                rowClassName={() => 'editable-row'}
                className='custom-member-table' 
                columns={editableColumns} 
                dataSource={[member]} 
                pagination={false} 
                rowKey='Id' />;
    }

    // 2. MEMBER DETAILS
    const _getTableMemberDetails = (member) => {
        var memberDetailsColumns = [     
            {
                title: t('member.member_start_date'),
                dataIndex: 'MemberSince',
                key: 'MemberSince',
                editable: true,
                cellType: Constant.CellType.Date,
                width: 70,
                handleOnChange: handleOnChange,
            },
            {
                title: <span className='required'>{ t('member.title') }</span>,
                dataIndex: 'TitleId',
                key: 'TitleId',
                editable: true,
                extraData: props.titles,
                required: true,
                cellType: Constant.CellType.EnhancedSelect,
                selectionKey: "Id",
                selectionDisplay: "Name",
                selectionSort: "Id",
                width: 50,
                handleOnChange: handleFishingTypeOnChange,
            },
            {
                title: <span className='required'>{ t('member.mobile') }</span>,
                dataIndex: 'MobilePhone',
                key: 'MobilePhone',
                editable: true,
                required: true,
                cellType: Constant.CellType.Input,
                width: 80,
                handleOnChange: handleOnChange,
            },
            {
                title: t('member.home_phone'),
                dataIndex: 'HomePhone',
                key: 'HomePhone',
                editable: true,
                cellType: Constant.CellType.Input,
                width: 80,
                handleOnChange: handleOnChange,
            },
            {
                title: <span className='required'>{ t('member.email') }</span>,
                dataIndex: 'Email',
                key: 'Email',
                editable: true,
                required: true,
                cellType: Constant.CellType.Input,
                width: 120,
                handleOnChange: handleOnChange,
            }
        ];

        const editableColumns = memberDetailsColumns.map((col) => {
            if (!col.editable) {
                return col;
            }
    
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    required: col.required,
                    cellType: col.cellType,
                    handleOnChange: col.handleOnChange,
                    extraData: col.extraData,
                    errorTextPosition: col.errorTextPosition,
                    selectionKey: col.selectionKey,
                    selectionDisplay: col.selectionDisplay,
                    validateAtStart: col.validateAtStart,
                    allowClear: col.allowClear,
                    selectionSort: col.selectionSort
                }),
            };
        });

        return <Table components={components} 
                tableLayout='fixed'
                rowClassName={() => 'editable-row'}
                className='custom-member-table' 
                columns={editableColumns} 
                dataSource={[member]} 
                pagination={false}
                rowKey='Id' />;
    }

    // 3. CONTACT DETAIL
    const handleMemberContactOnChange = (value, record, dataIndex) => {
        if (record) {
            var index = members.findIndex(x => x.Id === record.Id);
            if (index >= 0) {
                _.update(members[index], dataIndex, function (n) { 
                    return value;
                });

                updateFieldsChange(members[index]);
            }
        }

        setEnableSubmit(canSubmit(members));
    }

    const _getTableMemberContact = (member) => {
        var memberContactColumns = [
            {
                title: <div><span className='required'>{ t('member.street_number_short') }</span> <br /> <span className='sub-title'>{ t('member.street_number_placeholder') }</span></div>,
                dataIndex: 'StreetNo',
                key: 'StreetNo',
                editable: true,
                required: true,
                cellType: Constant.CellType.Input,
                width: 80,
                handleOnChange: handleMemberContactOnChange,
            },
            {
                title: <div><span className='required'>{ t('member.street_name_short') }</span> <br /> <span className='sub-title'>{ t('member.street_name_placeholder') }</span></div>,
                dataIndex: 'StreetLine1',
                key: 'StreetLine1',
                editable: true,
                required: true,
                cellType: Constant.CellType.Input,
                width: 100,
                handleOnChange: handleMemberContactOnChange,
            },
            {
                title: <div><span className='required'>{ t('member.street_type_short') }</span> <span className='sub-title'>{ t('member.street_type_placeholder2') }</span> <br /> <span className='sub-title'>{ t('member.street_type_placeholder') }</span></div>,
                dataIndex: 'StreetLine2',
                key: 'StreetLine2',
                editable: true,
                required: true,
                cellType: Constant.CellType.Input,
                width: 130,
                handleOnChange: handleMemberContactOnChange,
            },
            {
                title: <span className='required'>{ t('member.suburb') }</span>,
                dataIndex: 'Suburb',
                key: 'Suburb',
                editable: true,
                required: true,
                cellType: Constant.CellType.Input,
                width: 90,
                handleOnChange: handleMemberContactOnChange,
            },
            {
                title: <span className='required'>{ t('member.state') }</span>,
                dataIndex: 'State',
                key: 'State',
                extraData: props.states,
                editable: true,
                required: true,
                cellType: Constant.CellType.EnhancedSelect,
                selectionKey: "Acronym",
                selectionDisplay: "Acronym",
                selectionSort: "Id",
                width: 80,
                handleOnChange: handleMemberContactOnChange,
            },
            {
                title: <span className='required'>{ t('member.post_code') }</span>,
                dataIndex: 'PostCode',
                key: 'PostCode',
                editable: true,
                required: true,
                cellType: Constant.CellType.Input,
                width: 70,
                handleOnChange: handleMemberContactOnChange,
            },
            {
                title: <div><span className='required'>{ t('member.country') }</span> <br /> <span className='sub-title2'>{ t('member.country_placeholder') }</span></div>,
                dataIndex: 'CountryCode',
                key: 'CountryCode',
                editable: true,
                cellType: Constant.CellType.Input,
                width: 80,
                handleOnChange: handleMemberContactOnChange,
            }
        ];

        const editableColumns = memberContactColumns.map((col) => {
            if (!col.editable) {
                return col;
            }
    
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    required: col.required,
                    cellType: col.cellType,
                    handleOnChange: col.handleOnChange,
                    extraData: col.extraData,
                    errorTextPosition: col.errorTextPosition,
                    selectionKey: col.selectionKey,
                    selectionDisplay: col.selectionDisplay,
                    validateAtStart: col.validateAtStart,
                    allowClear: col.allowClear,
                    selectionSort: col.selectionSort
                }),
            };
        });

        return <Table components={components}
                tableLayout='fixed'
                rowClassName={() => 'editable-row'}
                className='custom-member-table' 
                columns={editableColumns} 
                dataSource={[member]}
                pagination={false} 
                rowKey='Id' />;
    }

    

    // 4. POSITION & ACCESS
    const addPosition = (member) => {
        setMembers(current =>
            current.map(obj => {
                if (obj.Id === member.Id) {
                    return {
                        ...obj,
                        MemberPositions: [...obj.MemberPositions, {
                            Id: -(_.random(0, 10000)),
                            MemberId: member.Id,
                            PositionTitleId: null,
                            EntityId: null,
                            PositionTypeId: null
                        }]
                    };
                }
                return obj;
            }),
        );
    }

    const deletePosition = (record) => {
        setMembers(current =>
            current.map(obj => {
                if (obj.Id === record.MemberId) {
                    return {
                        ...obj,
                        MemberPositions: obj.MemberPositions.filter((m) => m.Id !== record.Id)
                    };
                }
                return obj;
            }),
        );
    }

    const handlePositionTitleOnChange = (value, record, dataIndex) => {
        if (record) {
            setMembers(current =>
                current.map(obj => {
                    if (obj.Id === record.MemberId) {
                        var index = obj.MemberPositions.findIndex(x => x.Id === record.Id); 
                        _.update(obj.MemberPositions[index], dataIndex, function (n) { 
                            return value;
                        });

                        var positionTitle = _.find(props.positionTitles, function(x) { return x.PositionTitleId === record.PositionTitleId; });
                        if (positionTitle) {
                            _.update(obj.MemberPositions[index], 'PositionTypeId', function (n) { 
                                return positionTitle.PositionTypeId;
                            });
                        }
                    }

                    return obj;
                }),
            );
        }

        setEnableSubmit(canSubmit(members));
    }

    const handleEntityOnChange = (value, record, dataIndex) => {
        if (record) {
            setMembers(current =>
                current.map(obj => {
                    if (obj.Id === record.MemberId) {
                        var index = obj.MemberPositions.findIndex(x => x.Id === record.Id); 
                        _.update(obj.MemberPositions[index], dataIndex, function (n) { 
                            return value;
                        });
                    }

                    return obj;
                }),
            );
            record.EntityId = value;
        }

        setEnableSubmit(canSubmit(members));
    }

    const _getTablePositionAccess = (member) => {
        var positionAccessColumns = [
            {
                title: <span className='required'>{ t('member.position_title') }</span>,
                dataIndex: 'PositionTitleId',
                key: 'PositionTitleId',
                editable: true,
                extraData: props.positionTitles,
                required: true,
                disabled: true,
                cellType: Constant.CellType.EnhancedSelect,
                selectionKey: "PositionTitleId",
                selectionDisplay: "PositionTitleName",
                width: 80,
                positionTypeId: currentPosition.PositionTypeId,
                handleOnChange: handlePositionTitleOnChange,
            },
            {
                title: <span className='required'>{ t('member.entity_name') }</span>,
                dataIndex: 'EntityId',
                key: 'EntityId',
                editable: true,
                extraData: entities,
                required: true,
                cellType: Constant.CellType.EnhancedSelect,
                selectionKey: "Id",
                selectionDisplay: "Name",
                width: 160,
                entityId: currentPosition.EntityId,
                disabled: true,
                handleOnChange: handleEntityOnChange,
            },
            // {
            //     title: t('common.action'),
            //     key: 'Id',
            //     render: (value, record) => {
            //         return <ActionButtonsCell data={record} btnList={[
            //             {
            //                 Description: t("common.delete"),
            //                 Icon: <TrashIcon />,
            //                 ColorClass: Constant.ColorClass.LightRed,
            //                 Hide: record.EntityId === currentPosition.EntityId ? true : false,
            //                 NoMargin: true,
            //                 handleAction: deletePosition
            //             }
            //         ]} />
            //     },
            //     align: 'center',
            //     width: 30
            // },
        ];

        const editableColumns = positionAccessColumns.map((col) => {
            if (!col.editable) {
                return col;
            }
    
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    required: col.required,
                    disabled: col.disabled,
                    cellType: col.cellType,
                    handleOnChange: col.handleOnChange,
                    extraData: col.extraData,
                    errorTextPosition: col.errorTextPosition,
                    selectionKey: col.selectionKey,
                    selectionDisplay: col.selectionDisplay,
                    validateAtStart: col.validateAtStart,
                    entityId: col.entityId,
                    positionTypeId: col.positionTypeId,
                    allowClear: col.allowClear
                }),
            };
        });

        return (<>
                {/* <Row id={Constant.FixItemsContainerId} className="fix-item-container">
                    <Col xs={{ span: 24 }} className="text-right extra-btn-m-t">
                        <div>
                            <Button className="m-l-10 secondary-btn" type="primary" onClick={()=>{addPosition(member)}}>
                                {t("common.add")}
                            </Button>
                        </div>
                    </Col>
                </Row> */}

                <Row className="m-t-10" gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                    <Col xs={{ span: 24 }}>
                        <Table components={components}
                            tableLayout='fixed' 
                            rowClassName={() => 'editable-row'}
                            className='custom-member-table custom-table position-access-table'
                            locale={{ emptyText: "" }} 
                            columns={editableColumns} 
                            dataSource={member.MemberPositions}
                            rowKey='Id' />
                    </Col>
                </Row>
            </>);
    }

    // 5. MEMBER ACCESS
    const handleMemberAccessOnChange = (value, record, dataIndex) => {
        if (record) {
            var index = members.findIndex(x => x.Id === record.Id);
            if (index >= 0) {
                _.update(members[index], dataIndex, function (n) { 
                    return value;
                });
            }
        }

        setEnableSubmit(canSubmit(members));
    }

    const _getTableMemberAccess = (member) => {
        var memberAccessColumns = [
            {
                title: <span className='required'>{ t('login.username') }</span>,
                dataIndex: 'UserName',
                key: 'UserName',
                editable: true,
                required: true,
                cellType: Constant.CellType.Input,
                width: 100,
                handleOnChange: handleMemberAccessOnChange,
            },
            {
                title: <span className={member.IsAddNew ? 'required' : ''}>{ t('login.password') }</span>,
                dataIndex: 'Password',
                key: 'Password',
                editable: member.IsAddNew ? true : false,
                required: member.IsAddNew ? true : false,
                cellType: Constant.CellType.InputPassword,
                width: 100,
                handleOnChange: handleMemberAccessOnChange,
            }
        ];
        
        const editableColumns = memberAccessColumns.map((col) => {
            if (!col.editable) {
                return col;
            }
    
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    required: col.required,
                    cellType: col.cellType,
                    handleOnChange: col.handleOnChange,
                    extraData: col.extraData,
                    errorTextPosition: col.errorTextPosition,
                    selectionKey: col.selectionKey,
                    selectionDisplay: col.selectionDisplay,
                    validateAtStart: col.validateAtStart,
                    allowClear: col.allowClear
                }),
            };
        });

        return <Table components={components}
                tableLayout='fixed'
                rowClassName={() => 'editable-row'}
                className='custom-member-table' 
                columns={editableColumns} 
                dataSource={[member]}
                pagination={false} 
                rowKey='Id' />;
    }

    useEffect(() => {
        showLoading();

        var positionTitle = null;
        if (props.positionTitles && props.positionTitles.length > 0) {
            // if (currentPosition.PositionTypeId === Constant.PositionType.ClubMember || currentPosition.PositionTypeId === Constant.PositionType.ClubCommittee) {
            //     positionTitle = _.find(props.positionTitles, function(x) { return (x.PositionTypeId === Constant.PositionType.ClubMember || x.PositionTypeId === Constant.PositionType.ClubCommittee) && x.IsDefault; });
            // } else {
            //     positionTitle = _.find(props.positionTitles, function(x) { return x.PositionTypeId === currentPosition.PositionTypeId && x.IsDefault; });
            // }

            positionTitle = _.find(props.positionTitles, function(x) { return x.IsDefault; });

            if (!positionTitle || typeof (positionTitle) === 'undefined') {
                positionTitle = _.find(props.positionTitles, function(x) { return x.PositionTypeId === currentPosition.PositionTypeId; });
            }

            if (!positionTitle) {
                positionTitle = props.positionTitles[0];
            }
            setDefaultPositionTitle(positionTitle);
        }

        setClubDetailFieldsChange([
            {
                title: t('member.financial_type'),
                key: 'FinancialTypeId',
                selected: false
            },
            {
                title: t('member.fishing_type'),
                key: 'FishingTypeId',
                selected: false
            },
            {
                title: t('member.dob'),
                key: 'DateOfBirth',
                selected: false
            },
            {
                title: t('member.financial_end'),
                key: 'FinancialEnd',
                selected: false
            },
            {
                title: t('member.member_start_date'),
                key: 'MemberSince',
                selected: false
            },
            {
                title: t('member.flag_member'),
                key: 'Flagged',
                selected: false
            },
            {
                title: t('member.comments'),
                key: 'Comments',
                selected: false
            }
        ]);


        setMemberDetailFieldsChange([
            {
                title: t('member.financial_status'),
                key: 'FinancialStatusId',
                selected: false
            },
            {
                title: t('member.title'),
                key: 'TitleId',
                selected: false
            },
            {
                title: t('member.gender'),
                key: 'GenderId',
                selected: false
            },
            {
                title: t('member.mobile'),
                key: 'MobilePhone',
                selected: false
            },
            {
                title: t('member.home_phone'),
                key: 'HomePhone',
                selected: false
            },
            {
                title: t('member.email'),
                key: 'Email',
                selected: false
            }
        ]);

        setContactFieldsChange([
            {
                title: t('member.street_number'),
                key: 'StreetNo',
                selected: false
            },
            {
                title: t('member.street_name'),
                key: 'StreetLine1',
                selected: false
            },
            {
                title: t('member.street_type'),
                key: 'StreetLine2',
                selected: false
            },
            {
                title: t('member.suburb'),
                key: 'Suburb',
                selected: false
            },
            {
                title: t('member.state'),
                key: 'State',
                selected: false
            },
            {
                title: t('member.post_code'),
                key: 'PostCode',
                selected: false
            },
            {
                title: t('member.country'),
                key: 'CountryCode',
                selected: false
            }
        ]);

        setPositionAccessFieldsChange([
            {
                title: t('member.position_title'),
                key: 'PositionTitleId',
                selected: false
            },
            {
                title: t('member.entity_name'),
                key: 'EntityId',
                selected: false
            }
        ]);

        setRefresh(false);

        Promise.all([
            MembershipService.getEntitiesByPositionTypeId(-1)
        ])
        .finally(() => {            
            dismissLoading();
        })
        .then((values) => {
            if (values && values.length > 0) {
                var newEntities = values[0].data;
                setEntities(newEntities);
                var newMembers = [];
                if (props.isCopy) {
                    newMembers = [...newMembers, ...props.selectedMembers];
                    setExpandedRowKeys(props.selectedMembers.map(x => x.Id));
                }
                else if (!props.isEdit) {
                    var id = -(newMembers.length + 1);
                    var currentEntityId = null;
                    var currentEntityName = null;
                    if (newEntities && newEntities.length > 0) {
                        var clubEntities = newEntities.filter(x => x.EntityTypeId === Constant.EntityType.Club)
                        if (clubEntities && clubEntities.length === 1) {
                            currentEntityId = clubEntities[0].Id;
                            currentEntityName = clubEntities[0].Name;
                        }
                    }
                    newMembers.push(newMember(id, positionTitle, isClub() ? currentPosition.EntityId : currentEntityId, isClub() ? currentPosition.EntityName : currentEntityName));
                    setExpandedRowKeys([id]);
                } else {
                    newMembers = [...newMembers, ...props.selectedMembers];
                    setExpandedRowKeys(props.selectedRowKeys);
                }
                setMembers(newMembers);
                setMembers(current =>
                    current.map(obj => {
                        return {
                            ...obj,
                            DateOfBirth: obj.DateOfBirth ? moment(obj.DateOfBirth).format(Constant.DateFormat) : null,
                            MemberSince: obj.MemberSince ? moment(obj.MemberSince).format(Constant.DateFormat) : null,
                            FinancialEnd: obj.FinancialEnd ? moment(obj.FinancialEnd).format(Constant.DateFormat) : null,
                            // MemberPositions: [...obj.MemberPositions, {
                            //     Id: -(_.random(0, 10000)),
                            //     MemberId: obj.Id,
                            //     PositionTitleId: null,
                            //     EntityId: null,
                            //     PositionTypeId: null
                            // }]
                        };
                    }),
                );
                setOriginalMembers(_.cloneDeep(newMembers));
                setEnableSubmit(canSubmit(newMembers));
            }
        });

        return () => {
        }
    }, [])

    const newMember = (id, positionTitle, entityId, entityName) => {
        return {
            IsAddNew: true,
            Id: id,
            EntityId: entityId,
            EntityName: entityName,
            TitleId: null,
            GenderId: null,
            FirstName: null,
            LastName: null,
            AddressId: null,
            HomePhone: null,
            WorkPhone: null,
            FaxPhone: null,
            MobilePhone: null,
            DateOfBirth: null,
            MemberSince: moment(),
            Comments: null,
            Email: null,
            FishingTypeId: null,
            FishingCategoryId: null,
            FinancialTypeId: null,
            FinancialStatusId: Constant.FinancialStatus.UnFinancial,
            FinancialEnd: null,
            FamilyGroupId: null,
            Flagged: false,
            EmergencyPerson: null,
            EmergencyPhone: null,
            MemberStatusId: Constant.MemberStatusCode.Active,
            Password: CommonService.generatePassword(),
            MemberPositions: currentPosition ? [
                {
                    Id: -(_.random(0, 10000)),
                    MemberId: id,
                    PositionTitleId: positionTitle != null ? positionTitle.PositionTitleId : currentPosition.PositionTitleId,
                    EntityId: entityId,
                    PositionTypeId: positionTitle != null ? positionTitle.PositionTypeId : currentPosition.PositionTypeId
                }] : []
        };
    }

    const showFieldsChange = () => {  
        return props.isEdit && members.length > 1;
    }

    const showFieldsChangeApplyLayout = (e) => {  
        setOriginalFieldsChange(fieldsChange);
        setOpenFieldsChange(true);
    }
  
    const closeFieldsChangeApplyLayout = () => {
        setOpenFieldsChange(false);
    }

    const onClubDetailFieldsChangeChange = (e) => {
        var index = clubDetailFieldsChange.findIndex(x => x.key === e.target.value);
        if (index >= 0) {
            _.update(clubDetailFieldsChange[index], 'selected', function (n) { 
                return e.target.checked;
            });
        }

        if (e.target.checked) {
            setFieldsChange(_.uniq([...fieldsChange, e.target.value]));
        } else {
            var newFieldsChange = fieldsChange.filter(x => x !== e.target.value);
            setFieldsChange(newFieldsChange);
        }
    }

    const onMemberDetailFieldsChangeChange = (e) => {
        var index = memberDetailFieldsChange.findIndex(x => x.key === e.target.value);
        if (index >= 0) {
            _.update(memberDetailFieldsChange[index], 'selected', function (n) { 
                return e.target.checked;
            });
        }

        if (e.target.checked) {
            setFieldsChange(_.uniq([...fieldsChange, e.target.value]));
        } else {
            var newFieldsChange = fieldsChange.filter(x => x !== e.target.value);
            setFieldsChange(newFieldsChange);
        }
    }

    const onContactFieldsChangeChange = (e) => {
        var index = contactFieldsChange.findIndex(x => x.key === e.target.value);
        if (index >= 0) {
            _.update(contactFieldsChange[index], 'selected', function (n) { 
                return e.target.checked;
            });
        }

        if (e.target.checked) {
            setFieldsChange(_.uniq([...fieldsChange, e.target.value]));
        } else {
            var newFieldsChange = fieldsChange.filter(x => x !== e.target.value);
            setFieldsChange(newFieldsChange);
        }
    }

    const onPositionAccessFieldsChangeChange = (e) => {
        var index = positionAccessFieldsChange.findIndex(x => x.key === e.target.value);
        if (index >= 0) {
            _.update(positionAccessFieldsChange[index], 'selected', function (n) { 
                return e.target.checked;
            });
        }

        if (e.target.checked) {
            setFieldsChange(_.uniq([...fieldsChange, e.target.value]));
        } else {
            var newFieldsChange = fieldsChange.filter(x => x !== e.target.value);
            setFieldsChange(newFieldsChange);
        }
    }

    const cancelFieldsChange = () => {
        clubDetailFieldsChange.forEach(fieldChange => {
            fieldChange.selected = false;
        });

        memberDetailFieldsChange.forEach(fieldChange => {
            fieldChange.selected = false;
        });

        contactFieldsChange.forEach(fieldChange => {
            fieldChange.selected = false;
        });

        positionAccessFieldsChange.forEach(fieldChange => {
            fieldChange.selected = false;
        });

        originalFieldsChange.forEach(fieldChange => {
            var clubDetailIndex = clubDetailFieldsChange.findIndex(x => x.key === fieldChange);
            if (clubDetailIndex >= 0) {
                _.update(clubDetailFieldsChange[clubDetailIndex], 'selected', function (n) { 
                    return true;
                });
            }

            var memberDetailIndex = memberDetailFieldsChange.findIndex(x => x.key === fieldChange);
            if (memberDetailIndex >= 0) {
                _.update(memberDetailFieldsChange[memberDetailIndex], 'selected', function (n) { 
                    return true;
                });
            }

            var contactIndex = contactFieldsChange.findIndex(x => x.key === fieldChange);
            if (contactIndex >= 0) {
                _.update(contactFieldsChange[contactIndex], 'selected', function (n) { 
                    return true;
                });
            }

            var positionAccessIndex = positionAccessFieldsChange.findIndex(x => x.key === fieldChange);
            if (positionAccessIndex >= 0) {
                _.update(positionAccessFieldsChange[positionAccessIndex], 'selected', function (n) { 
                    return true;
                });
            }
        });

        setFieldsChange(originalFieldsChange);
        setOpenFieldsChange(false);
    }

    const applyFieldsChange = () => {
        if (members.length > 1) {
            updateFieldsChange(members[0]);
        }
        setOpenFieldsChange(false);
    }

    const updateFieldsChange = (memberChange) => {
        if (fieldsChange.length > 0 && members.length > 1) {
            fieldsChange.forEach(fieldChange => {
                setMembers(current =>
                    current.map(obj => {
                        obj[fieldChange] = memberChange[fieldChange];
                        return obj;
                    }),
                );
            });
        }
    }

    return (
        <div>
            <Modal
                title={props.isEdit ? t("member.edit_members") : t("member.insert_members")}
                centered
                forceRender
                open={true}
                onOk={onSubmit}
                confirmLoading={false}
                onCancel={onClose}
                okText={props.isEdit ? t("common.submit") : t("common.insert")}
                cancelText={t("common.cancel")}
                maskClosable={false}
                width={1350}
                footer={[
                    <Button key="back" onClick={onClose}>
                      {t("common.cancel")}
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} disabled={!enableSubmit} onClick={onSubmit}>
                      {props.isEdit ? t("common.submit") : t("common.insert")}
                    </Button>
                  ]}
            >
                <Form
                    {...layout}
                    name='insertEditMember'
                    key='insertEditMember'
                    onFinish={onSubmit}
                    component={false}
                    form={form}>
                    <Row id={Constant.FixItemsContainerId} className="fix-item-container">
                        <Col xs={{ span: 12 }} className="text-left extra-btn-m-t">
                            <div className="m-t-10" hidden={!showFieldsChange()}>
                                <Badge count={fieldsChange.length} color='blue'>
                                    <Button className='btn-outline-blue btn-icon' onClick={showFieldsChangeApplyLayout}>
                                        {t("member.fields_change_apply")}
                                    </Button>
                                </Badge>
                            </div>
                        </Col>
                        <Col xs={{ span: 12 }} className="text-right extra-btn-m-t">
                            <div className="m-t-10">
                                <Button className="m-l-10 secondary-btn" type="primary" onClick={addMember}>
                                    {t("common.add")}
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    <Row className="m-t-10" gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Table
                                components={components}
                                rowClassName={() => 'editable-row'}
                                tableLayout='fixed'
                                columns={memberColumns} 
                                dataSource={members} 
                                expandable={{ expandedRowRender, rowExpandable: () => true, expandedRowKeys: expandedRowKeys, columnWidth: 18 }}
                                pagination={false}
                                rowKey="Id"
                            />
                        </Col>
                    </Row>
                </Form>

                
            </Modal>

            {/* Fields Change Layout  */}
            <Drawer
                title={t("common.fields_change")}
                width={500}
                onClose={closeFieldsChangeApplyLayout}
                open={openFieldsChange}
                closeIcon={false}
                styles={{
                    body: {
                        paddingBottom: 80,
                    }
                }}
                extra={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button onClick={closeFieldsChangeApplyLayout} icon={<CloseIcon />} type="link" />
                    </Space>
                }
                footer={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button onClick={cancelFieldsChange}>{t("common.cancel")}</Button>
                        <Button onClick={applyFieldsChange} type="primary">
                            {t("common.ok")}
                        </Button>
                    </Space>
                }>
                <Form 
                    layout="vertical" 
                    {...layout}
                    name="formFieldsChange"
                    key='formFieldsChange'
                    component={false}>
                    <Tabs className='fields-change-tab' defaultActiveKey="1" key='fieldsChangeTab'>
                        <TabPane tab={t("member.club_details")} key="fields_change_club_details">
                            {clubDetailFieldsChange.map((item) => (
                                <Row key={item.key}>
                                    <Col xs={{ span: 24 }}>
                                        <Checkbox onChange={onClubDetailFieldsChangeChange} value={item.key} checked={item.selected}>{item.title}</Checkbox>
                                    </Col>
                                </Row>
                            ))}
                        </TabPane>
                        <TabPane tab={t("member.member_details")} key="fields_change_member_details">
                            {memberDetailFieldsChange.map((item) => (
                                <Row key={item.key}>
                                    <Col xs={{ span: 24 }}>
                                        <Checkbox onChange={onMemberDetailFieldsChangeChange} value={item.key} checked={item.selected}>{item.title}</Checkbox>
                                    </Col>
                                </Row>
                            ))}
                        </TabPane>
                        <TabPane tab={t("member.contact")} key="fields_change_contact">
                            {contactFieldsChange.map((item) => (
                                <Row key={item.key}>
                                    <Col xs={{ span: 24 }}>
                                        <Checkbox onChange={onContactFieldsChangeChange} value={item.key} checked={item.selected}>{item.title}</Checkbox>
                                    </Col>
                                </Row>
                            ))}
                        </TabPane>
                        <TabPane tab={t("member.position_access")} key="fields_change_position_access">
                            {positionAccessFieldsChange.filter(x => x.key !== 'Id').map((item) => (
                                <Row key={item.key}>
                                    <Col xs={{ span: 24 }}>
                                        <Checkbox onChange={onPositionAccessFieldsChangeChange} value={item.key} checked={item.selected}>{item.title}</Checkbox>
                                    </Col>
                                </Row>
                            ))}
                        </TabPane>
                    </Tabs>
                </Form>
            </Drawer>
        </div>
    )
}

export default CreateEditMembership
import React, { useState, useEffect } from 'react';
import { Constant, CommonService, RecordService, StorageService } from '../../services/services';
import { useTranslation } from 'react-i18next';
import { GetTableConfigs, StatusCell, ActionButtonsCell, EditableCell } from '../../components/customTable/customTable'
import LoadingContext from '../../components/loading/loadingContext'
import { EditIcon, EyeIcon, TrashIcon, FilterAltIcon, CloseIcon } from '../../components/icons/icons';
import { Row, Col, Table, Button, Checkbox, Modal, Form, Drawer, Space, Input, Select, Typography, Empty, Dropdown, DatePicker } from 'antd';
import { ExclamationCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { useGlobalState } from './../../utilities/globalState'
import CreateEditRecord from './createEditRecord'
import RecordDetail from './viewRecord'
import ReActiveRecord from './reActiveRecord'
import { useHistory } from "react-router-dom";
import EnhancedSelect from '../../components/enhancedSelect/enhancedSelect';

import PubSub from 'pubsub-js'

const { confirm } = Modal;
const { Option } = Select;
const { Title } = Typography;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const defaultGridOption = {
    pageNumber: 1,
    pageSize: CommonService.getPageSize(),
    // sort: "RecordDate-ascend"
}

const RecordManagement = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const emptyTableData = {
        ObjectList: [],
        TotalItems: 0
    }
    const [recordData, setRecordData] = useState(emptyTableData)
    const [gridConfigOptions, setGridConfigOptionsValue] = useState(defaultGridOption)
    const [currentPosition] = useGlobalState('currentPosition');
    const [formFilter] = Form.useForm();
    const [recordFilter, setRecordFilter] = useState({ StatusIds: [Constant.StatusCode.Active] });
    const [visibleFilter, setVisibleFilter] = useState(false);
    const [filterData, setFilterData] = useState({})
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [visibleAddUpdateRecord, setVisibleAddUpdateRecord] = useState(false);
    const [visibleReActiveRecords, setVisibleReActiveRecords] = useState(false);
    const [selectedObject, setSelectedObject] = useState({})
    const [visibleRecordDetail, setVisibleRecordDetail] = useState(false);
    const [recordSelectionData, setRecordSelectionData] = useState({});
    const [fishSpecies, setFishSpecies] = useState([]);
    const [recordCategories, setRecordCategories] = useState([]);
    const [recordEnvironments, setRecordEnvironments] = useState([]);
    const [lineClasses, setLineClasses] = useState([]);
    const [fishingLocations, setFishingLocations] = useState([]);
    const [ageCategories, setAgeCategories] = useState([]);
    const [entities, setEntities] = useState([]);
    const [genders, setGenders] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const history = useHistory();

    useEffect(() => {
        if (currentPosition && currentPosition.PositionTypeId === Constant.PositionType.ClubMember) {
            history.push('/unauthorised');
            return;
        }
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
        }, 100)

        showLoading()
        Promise.all([
            RecordService.getRecords(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, recordFilter, false),
            RecordService.getRecordFilterData()
        ])
            .finally(() => dismissLoading())
            .then(response => {
                if (response[0] && response[0].data) {
                    setRecordData(response[0].data)
                    setRecordFilter({ StatusIds: [Constant.StatusCode.Active] });
                }

                if (response[1] && response[1].data) {
                    setRecordCategories(response[1].data.RecordCategories);
                    setRecordEnvironments(response[1].data.Environments);
                    setFishSpecies(response[1].data.FishingSpecies);
                    setLineClasses(response[1].data.LineClasses);
                    setGenders(response[1].data.Genders);
                    setEntities(response[1].data.Clubs);
                    setFishingLocations(response[1].data.FishingLocations);
                    setStatuses(response[1].data.Statuses);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);
        return () => {
            RecordService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    const getRecords = (pageNumber, pageSize, filter, sort) => {
        showLoading()
        if (!filter) {
            filter = { ...recordFilter }
        }

        RecordService.getRecords(pageNumber, pageSize, sort, filter, false)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    setRecordData(result.data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    const statusCodes = [
        {
            Value: Constant.StatusCode.Active,
            Description: t("common.active")
        },
        {
            Value: Constant.StatusCode.Inactive,
            Description: t("common.inactive")
        }
    ]

    const editRecord = (record) => {
        if (record) {
            setSelectedObject(record)
            setVisibleAddUpdateRecord(true)
        }
    }

    const viewRecord = (record) => {
        if (record) {
            setSelectedObject(record)
            setVisibleRecordDetail(true)
        }
    }

    const getActionButtonsInfo = (item) => {
        return [
            {
                Description: t("common.edit"),
                Icon: <EditIcon />,
                ColorClass: Constant.ColorClass.LightBlue,
                handleAction: editRecord
            }
        ]
    }

    const getViewButtonsInfo = (item) => {
        return [
            {
                Description: t("common.view"),
                Icon: <EyeIcon />,
                ColorClass: Constant.ColorClass.LightBlue,
                handleAction: viewRecord
            }
        ]
    }

    const exportRecords = (fileType) => {
        showLoading();
        RecordService.getExportRecords(gridConfigOptions.sort, formFilter.getFieldsValue(), fileType, false)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    if (fileType == Constant.FileType.Excel) {
                        const type = result.headers['content-type']
                        const blob = new Blob([result.data], { type: type })
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = `Records_Report_List.xlsx`
                        link.click()
                    } else if (fileType == Constant.FileType.Csv) {
                        const type = result.headers['content-type']
                        const blob = new Blob([result.data], { type: type })
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = `Records_Report_List.csv`
                        link.click()
                    }
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    const handleExport = (e) => {
        exportRecords(e.key)
    };

    const exportItems = [
        {
            label: t("common.excel"),
            key: Constant.FileType.Excel
        },
        {
            label: t("common.csv"),
            key: Constant.FileType.Csv
        }
    ];
    const menuExport = {
        items: exportItems,
        onClick: handleExport
    };

    const isClubMember = () => {
        if ((currentPosition && currentPosition.PositionTypeId == Constant.PositionType.ClubMember) || history.location.pathname == Constant.Pages.ViewRecord) {
            return true
        }
        return false
    }

    const isClub = () => {
        if (typeof (currentPosition.PositionTypeId) === 'undefined') {
            currentPosition = StorageService.getCurrentPosition();
        }

        if (currentPosition && currentPosition.EntityTypeId === Constant.EntityType.Club) {
            return true;
        } else {
            return false;
        }
    }

    const columns = [
        {
            title: t('common.action'),
            render: (value, record) => {
                return <ActionButtonsCell btnList={getActionButtonsInfo(record)} data={record} />
            },
            align: "center",
            width: 50,
            hidden: isClubMember()
        },
        {
            title: t('record_management.view'),
            render: (value, record) => {
                return <ActionButtonsCell btnList={getViewButtonsInfo(record)} data={record} />
            },
            align: "center",
            width: 50,
        },
        {
            title: t('fishing_activity.species'),
            dataIndex: 'FishSpeciesName',
            key: 'FishSpeciesName',
            sorter: true,
            width: 130,
        },
        {
            title: t('member.club'),
            dataIndex: 'ClubEntityAcronym',
            key: 'ClubEntityAcronym',
            sorter: true,
            width: 80,
            hidden: isClub()
        },
        {
            title: t('record_management.record_category'),
            dataIndex: 'RecordCategoryName',
            key: 'RecordCategoryName',
            sorter: true,
            width: 80,
        },
        {
            title: t('fishing_activity.line_class'),
            dataIndex: 'LineClassName',
            key: 'LineClassName',
            sorter: true,
            width: 80,
        },
        {
            title: t('record_management.record_environment'),
            dataIndex: 'RecordEnvironmentName',
            key: 'RecordEnvironmentName',
            sorter: true,
            width: 100,
        },
        {
            title: t('fishing_activity.weight'),
            dataIndex: 'FishWeight',
            key: 'FishWeight',
            sorter: true,
            cellType: Constant.CellType.DecimalInput,
            width: 80,
        },
        {
            title: t('record_management.record_date'),
            dataIndex: 'RecordDate',
            key: 'RecordDate',
            sorter: true,
            fixed: 'left',
            render: (value, record) => {
                if (record.RecordDate) {
                    let date = new Date(record.RecordDate)
                    var day = date.getDate();
                    var month = date.getMonth() + 1; //Month from 0 to 11
                    var year = date.getFullYear();

                    return `${day}/${month}/${year}`
                }

                return null
            },
            width: 100
        },
        {
            title: t('record_management.fishing_location'),
            dataIndex: 'LocationName',
            key: 'LocationName',
            sorter: true,
            width: 80,
        },
        {
            title: t('member.first_name'),
            dataIndex: 'FirstName',
            key: 'FirstName',
            sorter: true,
            width: 80,
        },
        {
            title: t('member.surname'),
            dataIndex: 'Surname',
            key: 'Surname',
            sorter: true,
            width: 80,
        },
        {
            title: t('common.status'),
            dataIndex: 'StatusId',
            key: 'StatusId',
            sorter: false,
            align: 'center',
            width: 50,
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.StatusId)}
                    description={CommonService.getStatusDescription(record.StatusId, false)} />
            )
        }
    ]

    const columnsMapping = columns.filter(c => !c.hidden).map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition
            }),
        };
    })

    const components = {
        body: {
            cell: EditableCell,
        }
    };

    const onTableChange = (pagination, filters, sorter, extra) => {
        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = defaultGridOption.sort;
        }

        getRecords(pagination.current, pagination.pageSize, recordFilter, sort)
        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        })
    }

    const showFilterLayout = () => {
        setVisibleFilter(true);
    }

    const closeFilterLayout = () => {
        formFilter.setFieldsValue(recordFilter);
        setVisibleFilter(false);
    }

    const resetFilter = () => {

        formFilter.resetFields();
        setRecordFilter({ StatusIds: [Constant.StatusCode.Active] });
    }

    const applyFilter = () => {
        setVisibleFilter(false);
        let filter = formFilter.getFieldsValue();
        if (!filter || (filter && !filter.StatusIds)) {
            filter.StatusIds = [Constant.StatusCode.Active];
        }
        setRecordFilter(filter)
        setGridConfigOptionsValue({
            ...gridConfigOptions,
            pageNumber: 1
        })
        getRecords(1, gridConfigOptions.pageSize, filter, gridConfigOptions.sort)
    }

    const addRecord = () => {
        setSelectedObject({})
        setVisibleAddUpdateRecord(true)

    }

    const reActiveRecords = () => {
        setVisibleReActiveRecords(true);
    }

    const deleteRecords = () => {
        if (hasRecordSelected()) {
            confirm({
                title: t("common.confirm"),
                icon: <ExclamationCircleOutlined />,
                content: t("record_management.archive_record_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    showLoading()
                    RecordService.deleteRecords(selectedRowKeys)
                        .finally(() => dismissLoading())
                        .then(result => {
                            getRecords(1, gridConfigOptions.pageSize, recordFilter, gridConfigOptions.sort)
                            setGridConfigOptionsValue({
                                ...gridConfigOptions,
                                pageNumber: 1
                            })
                            
                            if (recordData && recordData.ObjectList) {
                                const updatedList = recordData.ObjectList.map(x => ({
                                    ...x,
                                    IsSelected: false
                                }))
                                recordData.ObjectList = updatedList
                                recordData.selectAll = false
                                setRecordData({ ...recordData })
                            }
                            setSelectedRowKeys([])
                            setSelectedRecords([])
                            CommonService.presentToast('success', t('record_management.update_record_successful'));

                        })
                        .catch(error => CommonService.handleErrorResponse(error))
                },
                onCancel() {
                }

            });
        }
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys, newSelectedRows, info) => {
            if (newSelectedRows && recordData && recordData.ObjectList) {
                let tempList = recordData.ObjectList.map(x => {
                    x.IsSelected = false
                    return x
                })
                for (let i = 0; i < newSelectedRows.length; i++) {
                    let record = tempList.find(p => p.Id == newSelectedRows[i].Id)
                    if (record) {
                        record.IsSelected = true;
                    }
                }

                if (newSelectedRows.length > 0 && info.type == "all") {
                    recordData.selectAll = true
                } else {
                    recordData.selectAll = false
                }

                recordData.ObjectList = tempList

                setRecordData({ ...recordData })
            }
            setSelectedRowKeys(newSelectedRowKeys)
            setSelectedRecords(newSelectedRows)   
        },

        // getCheckboxProps: (record) => ({
        //     disabled: record.StatusId === Constant.StatusCode.Inactive,
        // }),
        columnWidth: 100
    }

    const handleAddUpdateRecord = (objectData) => {
        setVisibleAddUpdateRecord(false)

        if (objectData) { 
            getRecords(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, recordFilter, gridConfigOptions.sort)
        }
    }

    const handleReActiveRecords = (objectData) => {
        setVisibleReActiveRecords(false)

        if (objectData) {
            getRecords(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, recordFilter, gridConfigOptions.sort)

            if (recordData && recordData.ObjectList) {
                const updatedList = recordData.ObjectList.map(x => ({
                    ...x,
                    IsSelected: false
                }))
                recordData.ObjectList = updatedList
                recordData.selectAll = false
                setRecordData({ ...recordData })
            }
            setSelectedRowKeys([])
            setSelectedRecords([])
        }
    }

    const handleCloseRecordDetail = () => {
        setVisibleRecordDetail(false)
    }

    const hasRecordSelected = () => {
        if (selectedRowKeys && selectedRowKeys.length > 0) {
            return true;
        }
        return false;
    }

    return (
        <>
            <div id={Constant.FixItemsContainerId}>
                <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                    <Title className='color-dark-blue' level={5}>{t("record_management.manage_records")}</Title>
                </Row>
                <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                    <Col flex={100} className="text-right">
                        <div>
                            <Button onClick={showFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon />}>{t("common.filter")}</Button>
                            <Dropdown className='m-l-10' menu={menuExport} trigger={['click']}>
                                <Button className='btn-outline-blue btn-icon' icon={<DownloadOutlined />}>
                                    {t("common.export")}
                                </Button>
                            </Dropdown>
                            {
                                isClubMember() == false &&
                                <>
                                    <Button className='m-l-10' danger onClick={deleteRecords} disabled={!hasRecordSelected()} >{t("common.archive")}</Button>
                                    <Button id='btnEdit' disabled={!hasRecordSelected()} className={selectedRecords.length > 0 ? "m-l-10 secondary-btn btn-outline-dark-blue" : "m-l-10 secondary-btn"} onClick={reActiveRecords}>
                                            {t("common.edit")}
                                    </Button>
                                    <Button className='btn-solid-dark-blue m-l-10' onClick={addRecord}>{t("common.add_new")}</Button>
                                </>
                            }

                        </div>
                    </Col>
                </Row>
            </div>
            <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                <Col xs={{ span: 24 }}>
                    <Table
                        components={components}
                        rowClassName={() => 'editable-row'}
                        rowSelection={rowSelection}
                        {...GetTableConfigs(columnsMapping, recordData?.ObjectList, recordData?.TotalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                    />
                </Col>
            </Row>

            <Drawer
                title={t("common.filter")}
                width={378}
                onClose={closeFilterLayout}
                open={visibleFilter}
                closeIcon={false}
                initialValues={{ StatusIds: [Constant.StatusCode.Active] }}
                styles={{
                    body: {
                        paddingBottom: 80,
                    }
                }}
                extra={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                        <Button onClick={closeFilterLayout} icon={<CloseIcon />} type="link" />
                    </Space>
                }
                footer={
                    <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                        <Button onClick={closeFilterLayout}>{t("common.cancel")}</Button>
                        <Button onClick={applyFilter} type="primary">
                            {t("common.apply")}
                        </Button>
                    </Space>
                }>
                <Form
                    layout="vertical"
                    {...layout}
                    form={formFilter}
                    name="formFilter"
                    key='formFilter'
                    initialValues={{ StatusIds: [Constant.StatusCode.Active] }}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="FishSpecyIds"
                                label={t("fishing_activity.species")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={fishSpecies ? fishSpecies.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="RecordCategoryIds"
                                label={t("record_management.record_category")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={recordCategories ? recordCategories.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="EnvironmentIds"
                                label={t("record_management.record_environment")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={recordEnvironments ? recordEnvironments.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="FirstName"
                                label={t("member.first_name")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="Surname"
                                label={t("member.surname")}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="GenderIds"
                                label={t("member.gender")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={genders ? genders.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* <Row gutter={24}>
                        <Col span={24}>
                        <Form.Item
                                name="AgeCategoryIds"
                                label={t("record_management.age_category")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={ageCategories ? ageCategories.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row> */}
                    {
                        currentPosition && (currentPosition.PositionTypeId == Constant.PositionType.StateExcutive || currentPosition.PositionTypeId == Constant.PositionType.NationalExecutive) &&
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="ClubIds"
                                    label={t("fishing_activity.club")}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={entities ? entities.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                    }
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="LineClassIds"
                                label={t("fishing_activity.line_class")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={lineClasses ? lineClasses.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}
                                >
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="StatusIds"
                                label={t("common.status")}
                            >
                                <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                    maxTagCount='responsive'
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={statuses ? statuses.map(b => {
                                        return {
                                            label: b.Name,
                                            value: b.Id
                                        }
                                    }) : []}>
                                </EnhancedSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
            {
                visibleAddUpdateRecord &&
                <CreateEditRecord visible={visibleAddUpdateRecord} handleCancel={handleAddUpdateRecord}
                    objectData={selectedObject}
                    entities={entities}
                    fishSpecies={fishSpecies}
                    lineClasses={lineClasses}
                    recordCategories={recordCategories}
                    recordEnvironments={recordEnvironments}
                    fishingLocations={fishingLocations}
                >

                </CreateEditRecord>
            }
            {
                visibleReActiveRecords &&
                <ReActiveRecord visible={visibleReActiveRecords} handleCancel={handleReActiveRecords}
                    selectedRowKeys={selectedRowKeys}
                    selectedRecords={selectedRecords}
                />
            }
            {
                visibleRecordDetail &&
                <RecordDetail visible={visibleRecordDetail}
                    handleCancel={handleCloseRecordDetail}
                    objectData={selectedObject}
                    isState={currentPosition != null && currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee}></RecordDetail>
            }
        </>
    )
}

export default RecordManagement;
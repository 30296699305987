import React, { useEffect, useState } from 'react';
import './specialEventManagement.scss';
import { useTranslation } from 'react-i18next';
import {
    Constant,
    CommonService,
    SettingService
} from '../../../services/services'
import { Table, Button, Row, Col, Form, Modal } from 'antd';
import { GetTableConfigs, EditableCell, ActionButtonsCell } from '../../../components/customTable/customTable'
import { TrashIcon } from '../../../components/icons/icons'
import LoadingContext from '../../../components/loading/loadingContext'

const { confirm } = Modal;
const _ = require("lodash");

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const components = {
    body: {
        cell: EditableCell,
    },
};

const CreateEditSpecialEvent = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { showLoading, dismissLoading } = React.useContext(LoadingContext);
    const [originalSpecialEvents, setOriginalSpecialEvents] = useState([]);
    const [specialEvents, setSpecialEvents] = useState([]);
    const [allSpecialEvents, setAllSpecialEvents] = useState([]);
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [form] = Form.useForm();

    const onSubmit = () => {
        // Validate
        var allErrorMessages = [];
        setSpecialEvents(current =>
            current.map(obj => {
                var errorMessages = [];
                if (!obj.Name) {
                    errorMessages.push({
                        key: 'Name',
                        value: t('special_event.event_name_is_required')
                    })
                }

                if (!obj.StatusId) {
                    errorMessages.push({
                        key: 'StatusId',
                        value: t('common.status_is_required')
                    })
                }

                // Exists
                var existSpecialEvents = allSpecialEvents.filter(x => x.Id !== obj.Id && x.Name === obj.Name);
                if (existSpecialEvents !== null && existSpecialEvents.length > 0) {
                    errorMessages.push({
                        key: 'Name',
                        value: t('special_event.special_event_exist')
                    })
                }

                allErrorMessages.push(...errorMessages);
                return {
                    ...obj,
                    ErrorMessages: errorMessages,
                };
            }),
        );

        if (allErrorMessages.length > 0) {
            return;
        }

        // Add/Update
        showLoading();
        SettingService.addUpdateSpecialEvents(specialEvents)
            .finally(() => {
                dismissLoading();
            })
            .then(result => {
                if (result.data) {
                    CommonService.presentToast('success', props.isEdit ? t('special_event.special_events_update_successful') : t('special_event.special_events_create_successful'));
                    props.handleCancel(true);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const canSubmit = (newSpecialEvents) => {
        if (newSpecialEvents && newSpecialEvents.length > 0) {
            let inValid = newSpecialEvents.filter(x => !x.Name);
            if (inValid && inValid.length > 0) {
                return false
            }
            return true;
        }
        return false;
    }

    const submitConfirmation = () => {
        function customizer(newSpecialEvents, oldSpecialEvents) {
            if (newSpecialEvents.length !== oldSpecialEvents.length)
                return true;

            for (var i = 0; i < newSpecialEvents.length; i ++) {
                if (((newSpecialEvents[i].Name !== null || oldSpecialEvents[i].Name !== null) && newSpecialEvents[i].Name !== oldSpecialEvents[i].Name) ||
                    ((newSpecialEvents[i].Description !== null || oldSpecialEvents[i].Description !== null) && newSpecialEvents[i].Description !== oldSpecialEvents[i].Description)) {
                    return true;
                }
            }

            return false;
        }
        
        var newSpecialEvents = _.sortBy(specialEvents, [function(m) { return m.Id; }]);
        var oldSpecialEvents = _.sortBy(originalSpecialEvents, [function(m) { return m.Id; }]);
        var changed = _.isEqualWith(newSpecialEvents, oldSpecialEvents, customizer);
        if (changed) {
            confirm({
                title: t("common.submit_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    onSubmit();
                },
                onCancel() {
                    props.handleCancel(true);
                }
            });
        }
        else {
            props.handleCancel(false);
        }
    }

    const onClose = () => {
        submitConfirmation();
    }

    const addSpecialEvent = () => {
        var recordId = (specialEvents.length + 1);
        var id = -recordId;
        setSpecialEvents([...specialEvents, newSpecialEvent(null, id)]);
    }

    const deleteSpecialEvent = (record) => {
        if (!record.IsAddNew && record.Id > 0) {
            confirm({
                title: t("special_event.delete_special_event_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    showLoading();
    
                    SettingService.deleteSpecialEvents([record.Id])
                    .finally(() => {
                        dismissLoading();
                    })
                    .then(result => {
                        const newSpecialEvents = specialEvents.filter((m) => m.Id !== record.Id);
                        setSpecialEvents(newSpecialEvents);
                        setRefresh(true);
                    })
                    .catch(error => {
                        CommonService.handleErrorResponse(error);
                    });
                }
            });
        } else {
            const newSpecialEvents = specialEvents.filter((m) => m.Id !== record.Id);
            setSpecialEvents(newSpecialEvents);
        }
    }

    
    const handleOnChange = (value, record, dataIndex) => {
        if (record) {
            var index = specialEvents.findIndex(x => x.Id === record.Id);
            _.update(specialEvents[index], dataIndex, function (n) { 
                return value;
            });

            if (dataIndex === 'Name') {
                setSpecialEvents(current =>
                    current.map(obj => {
                        var errorMessages = [];
                        if (obj.Id === record.Id) {
                            var existSpecialEvents = specialEvents.filter(x => x.Id !== obj.Id && x.Name === obj.Name);
                            if (existSpecialEvents !== null && existSpecialEvents.length > 0) {
                                errorMessages.push({
                                    key: 'Name',
                                    value: t('special_event.special_event_exist')
                                })
                            }
                        }
                        return {
                            ...obj,
                            ErrorMessages: errorMessages,
                        };
                    }),
                );
            }
        }
        setEnableSubmit(canSubmit(specialEvents));
    }

    var columns = [
        {
            title: t('common.record'),
            dataIndex: 'RecordId',
            key: 'RecordId',
            editable: false,
            required: false,
            width: 25,
        },
        {
            title: <span className='required'>{ t('special_event.event_name') }</span>,
            dataIndex: 'Name',
            key: 'Name',
            editable: true,
            required: true,
            duplicated: true,
            cellType: Constant.CellType.Input,
            width: 70,
            handleOnChange: handleOnChange,
        },
        {
            title: t('special_event.event_description'),
            dataIndex: 'Description',
            key: 'Description',
            editable: true,
            required: false,
            cellType: Constant.CellType.Input,
            width: 120,
            handleOnChange: handleOnChange,
        },
        {
            title: <span className='required'>{ t('common.status') }</span>,
            dataIndex: 'StatusId',
            key: 'StatusId',
            editable: true,
            extraData: props.statuses,
            required: true,
            cellType: Constant.CellType.StatusSelection,
            selectionKey: "Id",
            selectionDisplay: "Name",
            width: 45,
            handleOnChange: handleOnChange
        },
        {
            title: t('common.action'),
            key: 'Id',
            render: (value, record) => {
                return <ActionButtonsCell data={record} btnList={[
                    {
                        Description: t("common.delete"),
                        Icon: <TrashIcon />,
                        ColorClass: Constant.ColorClass.LightRed,
                        Hide: false,
                        NoMargin: true,
                        handleAction: deleteSpecialEvent
                    }
                ]} />
            },
            align: 'center',
            width: 25
        },
    ]

    const specialEventColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                duplicated: col.duplicated,
                disabled: col.disabled,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition,
                selectionKey: col.selectionKey,
                selectionDisplay: col.selectionDisplay,
                validateAtStart: col.validateAtStart
            }),
        };
    })

    useEffect(() => {
        showLoading();

        var newSpecialEvents = [];
        if (!props.isEdit) {
            var recordId = (specialEvents.length + 1);
            var id = -recordId;
            newSpecialEvents.push(newSpecialEvent(null, id));
        } else {
            for (var i = 0; i < props.selectedSpecialEvents.length; i ++) {
                // props.selectedSpecialEvents[i].RecordId = i + 1;
                props.selectedSpecialEvents[i].RecordId = props.selectedSpecialEvents[i].Id;
            }
            newSpecialEvents = [...newSpecialEvents, ...props.selectedSpecialEvents];
        }
        setSpecialEvents(newSpecialEvents);
        setOriginalSpecialEvents(_.cloneDeep(newSpecialEvents));
        setEnableSubmit(canSubmit(newSpecialEvents));
        setRefresh(false);

        Promise.all([
            SettingService.getAllSpecialEvents()
        ])
        .finally(() => {
            dismissLoading();
        })
        .then((values) => {
            if (values && values.length > 0) {
                setAllSpecialEvents(values[0].data);
            }
        });

        return () => {
        }
    }, [])

    const newSpecialEvent = (recordId, id) => {
        return {
            IsAddNew: true,
            RecordId: recordId,
            Id: id,
            Name: null,
            Description: null,
            StatusId: Constant.StatusCode.Active
        };
    }

    return (
        <div>
            <Modal
                title={ props.isEdit ? t('special_event.edit_special_events') : t('special_event.insert_special_events') }
                centered
                forceRender
                open={true}
                onOk={onSubmit}
                confirmLoading={false}
                onCancel={onClose}
                okText={props.isEdit ? t("common.submit") : t("common.insert")}
                cancelText={t("common.cancel")}
                maskClosable={false}
                width={860}
                footer={[
                    <Button key="back" onClick={onClose}>
                      {t("common.cancel")}
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} disabled={!enableSubmit} onClick={onSubmit}>
                      {props.isEdit ? t("common.submit") : t("common.insert")}
                    </Button>
                  ]}
            >
                <Form
                    {...layout}
                    name='specialEvent'
                    key='specialEvent'
                    onFinish={onSubmit}
                    component={false}
                    form={form}>
                    <Row id={Constant.FixItemsContainerId} className="fix-item-container">
                        <Col xs={{ span: 24 }} className="text-right extra-btn-m-t">
                            <div className="m-t-10">
                                <Button className="m-l-10 secondary-btn" type="primary" onClick={addSpecialEvent}>
                                    {t("common.add")}
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    <Row className="m-t-10" gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Table
                                components={components}
                                rowClassName={() => 'editable-row'}
                                tableLayout='fixed'
                                columns={specialEventColumns} 
                                dataSource={specialEvents} 
                                pagination={false}
                                rowKey="Id"
                            />
                        </Col>
                    </Row>
                </Form>

                
            </Modal>
        </div>
    )
}

export default CreateEditSpecialEvent
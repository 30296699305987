import React, { useState, useEffect } from 'react';
import { Constant, CommonService, TrophyService } from '../../../services/services';
import { useTranslation } from 'react-i18next';
import { GetTableConfigs, GetTableConfigs_SimplePaginator, ActionButtonsCell } from '../../../components/customTable/customTable'
import LoadingContext from '../../../components/loading/loadingContext'
import { FilterAltIcon, CloseIcon, EditIcon, EyeIcon, TrashIcon } from '../../../components/icons/icons';
import { Row, Col, Table, Button, Modal, Form, Drawer, Space, Input, Typography, Empty } from 'antd';
import PubSub from 'pubsub-js'
import AddUpdateAnglerGroup from './addUpdateAnglerGroup'
import { useGlobalState } from './../../../utilities/globalState'

const { confirm } = Modal;
const { Text, Title } = Typography;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};


const AnglerGroupManagement = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [filterForm] = Form.useForm();
    const [viewMembersForm] = Form.useForm();

    const [totalItems, setTotalItems] = useState(0);
    const [anglerGroups, setAnglerGroups] = useState([]);
    const [searchAnglerGroup, setSearchAnglerGroup] = useState({ StatusIds: Constant.StatusCode.Active });
    const [selectedAnglerGroup, setSelectedAnglerGroup] = useState([]);
    const [isEdit, setIsEdit] = useState(false);

    const [visibleModal, setVisibleModal] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [openViewMembers, setOpenViewMembers] = useState(false);
    const [anglerGroup, setAnglerGroup] = useState({});
    const [members, setMembers] = useState([]);
    const [originalMembers, setOriginalMembers] = useState([]);
    const [currentPosition] = useGlobalState('currentPosition');

    const [gridConfigOptions, setGridConfigOptionsValue] = useState({
        pageNumber: 1,
        pageSize: CommonService.getPageSize(),
        sort: "Name-ascend",
    })

    const search = (pageNumber, pageSize, sort, query, isShowLoading = false) => {
        if (isShowLoading) {
            showLoading();
        }

        let params = { ...query };
        if (!params) params = { StatusIds: [Constant.StatusCode.Active] };

        TrophyService.getAnglerGroups(pageNumber, pageSize, sort, params)
            .finally(() => {
                if (isShowLoading) {
                    dismissLoading();
                }
            })
            .then(result => {
                if (result.data) {
                    setAnglerGroups(result.data.ObjectList);
                    setTotalItems(result.data.TotalItems);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    const statusCodes = [
        {
            Value: Constant.StatusCode.Active,
            Description: t("common.active")
        },
        {
            Value: Constant.StatusCode.Inactive,
            Description: t("common.inactive")
        }
    ]

    const getActionButtonsInfo = (item) => {
        return [
            {
                Description: t("common.edit"),
                Icon: <EditIcon />,
                ColorClass: Constant.ColorClass.LightBlue,
                handleAction: editAnglerGroup
            },
            {
                Description: t("common.delete"),
                Icon: <TrashIcon />,
                ColorClass: Constant.ColorClass.LightRed,
                handleAction: deleteAnglerGroup
            }
        ]
    }

    const getViewButtonsInfo = (item) => {
        return [
            {
                Description: t("common.view"),
                Icon: <EyeIcon />,
                ColorClass: Constant.ColorClass.LightBlue,
                handleAction: viewMembers
            }
        ]
    }

    const columns = [
        {
            title: t('common.action'),
            render: (value, record) => {
                return <ActionButtonsCell btnList={getActionButtonsInfo(record)} data={record} />
            },
            align: "center",
            width: 70
        },
        {
            title: t('angler_group.view_members'),
            render: (value, record) => {
                return <ActionButtonsCell btnList={getViewButtonsInfo(record)} data={record} />
            },
            align: "center",
            width: 70
        },
        {
            title: t('angler_group.member_group_name'),
            dataIndex: 'Name',
            key: 'Name',
            sorter: true,
            width: 250
        }
    ]

    const onTableChange = (pagination, filters, sorter, extra) => {
        let data = filterForm.getFieldsValue();
        setSearchAnglerGroup(data);

        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = gridConfigOptions.sort;
        }
        search(pagination.current, pagination.pageSize, sort, data);

        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        });
    }

    // Filters
    const showFilterLayout = () => {
        setOpenFilter(true);
    }

    const closeFilterLayout = () => {
        filterForm.setFieldsValue(searchAnglerGroup);
        setOpenFilter(false);
    }

    const resetFilter = () => {
        filterForm.resetFields();
        setSearchAnglerGroup({ StatusIds: Constant.StatusCode.Active });
    }

    const applyFilter = () => {

        refreshData(true);

        setOpenFilter(false);
    }

    const refreshData = (isShowLoading = false) => {

        let data = filterForm.getFieldsValue();
        if (!data || (data && !data.StatusIds)) {
            data.StatusIds = [Constant.StatusCode.Active];
        }
        setSearchAnglerGroup(data);

        search(1, gridConfigOptions.pageSize, gridConfigOptions.sort, data, isShowLoading);

        setGridConfigOptionsValue(
            {
                ...gridConfigOptions,
                pageNumber: 1
            }
        );

    }

    const handleCancel = (refresh) => {
        setVisibleModal(false);
        if (refresh) {
            refreshData();
        }
    }

    const addAnglerGroup = () => {
        setIsEdit(false);
        setSelectedAnglerGroup({});
        setVisibleModal(true);
    }

    const editAnglerGroup = (record) => {
        setIsEdit(true);
        setSelectedAnglerGroup(record);
        setVisibleModal(true);
    }

    const deleteAnglerGroup = (record) => {
        confirm({
            title: t("angler_group.delete_angler_group_confirm"),
            okText: t("common.yes"),
            cancelText: t("common.no"),
            onOk() {
                showLoading();
                TrophyService.deleteAnglerGroup(record.Id)
                    .finally(() => {
                        dismissLoading();
                    })
                    .then(result => {
                        refreshData();
                    })
                    .catch(error => {
                        CommonService.handleErrorResponse(error);
                    });
            }
        });
    }

    // View Members
    const viewMembers = (record) => {
        setAnglerGroup(record);
        setMembers(record.Members);
        setOriginalMembers(record.Members);
        setOpenViewMembers(true);
    }

    const closeViewMembersLayout = () => {
        setOpenViewMembers(false);
    }

    const memberColumns = [
        {
            title: t('angler_group.id'),
            dataIndex: 'MemberNumber',
            key: 'MemberNumber',
            sorter: false,
            width: 50
        },
        {
            title: t('fishing_activity.angler'),
            dataIndex: 'Name',
            key: 'Name',
            sorter: false,
            width: 100
        },
        {
            title: t('angler_group.club'),
            dataIndex: 'ClubAcronym',
            key: 'ClubAcronym',
            sorter: false,
            width: 50
        }
    ]

    const handleMemberIDOnChange = (value) => {
        if (value && value.length > 0) {
            const newMembers = originalMembers.filter((m) => m.MemberNumber.includes(value));
            setMembers(newMembers);
        }
        else {
            setMembers(originalMembers);
        }
    }

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
        }, 100)

        showLoading()
        Promise.all([
            TrophyService.getAnglerGroups(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, {}),
        ])
            .finally(() => {
                dismissLoading();

                setSearchAnglerGroup({ StatusIds: [Constant.StatusCode.Active] });
            })
            .then(response => {
                if (response[0].data) {
                    setAnglerGroups(response[0].data.ObjectList);
                    setTotalItems(response[0].data.TotalItems);
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);
        return () => {
            TrophyService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    return (
        <>
            {
                currentPosition && (currentPosition.PositionTypeId == Constant.PositionType.StateExcutive ||
                    currentPosition.PositionTypeId == Constant.PositionType.NationalExecutive
                ) &&
                <div>
                    <div id={Constant.FixItemsContainerId}>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                            <Col xs={{ span: 24 }}>
                                <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                    <Title className='color-dark-blue' level={5}>{t("menu.angler_point_score_groups")}</Title>
                                </Space>
                            </Col>
                        </Row>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                            <Col flex={100} className="text-right">
                                <div>
                                    <Button onClick={showFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon />}>{t("common.filter")}</Button>
                                    <Button className='btn-solid-dark-blue m-l-10' onClick={addAnglerGroup}>{t("common.add_new")}</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Table
                                {...GetTableConfigs(columns, anglerGroups, totalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                            />
                        </Col>
                    </Row>

                    {/* Filter */}
                    <Drawer
                        title={t("common.filter")}
                        width={378}
                        onClose={closeFilterLayout}
                        open={openFilter}
                        closeIcon={false}
                        styles={{
                            body: {
                                paddingBottom: 80,
                            }
                        }}
                        extra={
                            <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                                <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                                <Button onClick={closeFilterLayout} icon={<CloseIcon />} type="link" />
                            </Space>
                        }
                        footer={
                            <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                                <Button onClick={closeFilterLayout}>{t("common.cancel")}</Button>
                                <Button onClick={applyFilter} type="primary">
                                    {t("common.apply")}
                                </Button>
                            </Space>
                        }>
                        <Form
                            layout="vertical"
                            {...layout}
                            form={filterForm}
                            name="filterForm"
                            key='filterForm'
                        >
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="Name"
                                        label={t("angler_group.member_group_name")}
                                    >
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Drawer>

                    {/* View Members */}
                    <Drawer
                        title={t("angler_group.members")}
                        width={512}
                        onClose={closeViewMembersLayout}
                        open={openViewMembers}
                        closeIcon={false}
                        styles={{
                            body: {
                                paddingBottom: 80,
                            }
                        }}
                        extra={
                            <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                                <Button onClick={closeViewMembersLayout} icon={<CloseIcon />} type="link" />
                            </Space>
                        }>
                        <Form
                            layout="vertical"
                            {...layout}
                            form={viewMembersForm}
                            name="viewMembersForm"
                            key='viewMembersForm'
                        >
                            <Row gutter={24}>
                                <Col span={24}>
                                <Form.Item
                                        label={t("angler_group.angler_group")}
                                    >
                                        <Text>{anglerGroup?.Name}</Text>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="Name"
                                        label={t("angler_group.member_id")}
                                    >
                                        <Input onChange={(e) => handleMemberIDOnChange(e.target.value)} allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col xs={{ span: 24 }}>
                                    <Table
                                        {...GetTableConfigs_SimplePaginator(memberColumns, members, members.length, "Id", null, null)}
                                        pagination={false}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </Drawer>

                    {/* Add/Update Angler Groups */}
                    {
                        visibleModal &&
                        <AddUpdateAnglerGroup
                            handleCancel={handleCancel}
                            isEdit={isEdit}
                            selectedAnglerGroup={selectedAnglerGroup} />
                    }
                </div>
            }
            {currentPosition && currentPosition.PositionTypeId != Constant.PositionType.StateExcutive &&
                currentPosition.PositionTypeId != Constant.PositionType.NationalExecutive &&
                <div className='m-t-10'>
                    <Table
                        style={{ visibility: 'hidden', height: '0px' }}
                        {...GetTableConfigs_SimplePaginator(memberColumns, [], members.length, "Id", null, null)}
                    />
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_access")} />
                </div >
            }

        </>
    )
}

export default AnglerGroupManagement;
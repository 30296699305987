import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Empty, Form, Button, Select, Upload, Modal, AutoComplete } from 'antd';
import { Constant, SettingService, CommonService, StorageService } from '../../services/services';
import { useTranslation } from 'react-i18next';
import PubSub from 'pubsub-js'
import LoadingContext from '../../components/loading/loadingContext'
import { useGlobalState } from '../../utilities/globalState'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import AddImage from './../../assets/icons/add-image.svg';
import './clubStateDetail.scss';
import ConfirmLeavePage from "../../components/confirmLeavePage/confirmLeavePage"
import EnhancedSelect from '../../components/enhancedSelect/enhancedSelect';

const { TextArea } = Input;
const { Option } = Select;
const { confirm } = Modal;

function ClubStateDetail(props) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [currentPosition] = useGlobalState('currentPosition')
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [zones, setZones] = useState([])
    const [states, setStates] = useState([])
    const [stateEntities, setStateEntities] = useState([])
    const [nationalEntities, setNationalEntities] = useState([])
    const [entityDetail, setEntityDetail] = useState({})
    const [imageBase64, setImageBase64] = useState();
    const [imageName, setImageName] = useState();
    const [imageUrl, setImageUrl] = useState();
    const [confirmLeave, setConfirmLeave] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);

        showLoading()

        Promise.all([
            SettingService.getEntityDetail(),
            SettingService.getZones(),
            SettingService.getStates(),
            SettingService.getStateEntities(),
            SettingService.getNationalEntities()
        ])
            .finally(() => dismissLoading())
            .then(response => {
                if (response[0] && response[0].data) {
                    form.setFieldsValue(response[0].data)
                    setEntityDetail(response[0].data)

                    if (response[0].data.LogoUrl) {
                        setImageUrl(response[0].data.LogoUrl)
                        setImageName(response[0].data.Logo)
                    }
                } else {
                    CommonService.presentToast("warning", t("forget_password.user_not_found"))
                }

                if (response[1] && response[1].data) {
                    setZones(response[1].data)
                }

                if (response[2] && response[2].data) {
                    setStates(response[2].data)
                }

                if (response[3] && response[3].data) {
                    setStateEntities(response[3].data)
                }

                if (response[4] && response[4].data) {
                    setNationalEntities(response[4].data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })

        return () => {
            SettingService.cancelRequest()
        }
    }, [])

    const onSubmit = () => {
        let data = form.getFieldsValue()
        if (data) {
            let objData = {
                ...entityDetail
            }
            Object.assign(objData, data)
            objData.LogoBase64 = imageBase64
            objData.Logo = imageName
            showLoading()
            SettingService.updateEntityDetail(objData)
                .finally(() => dismissLoading())
                .then(result => {
                    if (result && result.data) {
                        form.setFieldsValue(result.data)
                        setEntityDetail(result.data)
                        setConfirmLeave(false)
                    }
                })
                .catch(error => {
                    CommonService.handleErrorResponse(error);
                })
        }

    }

    const beforeUploadPhoto = img => {
        CommonService.getImgBase64(img, imageUrl => {
            setImageBase64(imageUrl);
            setImageName(img.name)
        });
    }

    const onCancel = () => {
        confirm({
            title: t("common.confirm"),
            icon: <ExclamationCircleOutlined />,
            content: t("entity_detail.reset_warning"),
            okText: t("common.yes"),
            cancelText: t("common.no"),
            onOk() {
                setImageBase64(null);
                setImageName(entityDetail.Logo)
                setImageUrl(entityDetail.LogoUrl)
                form.setFieldsValue(entityDetail);
                setConfirmLeave(false)
            },
            onCancel() {
            }

        });
    }

    const removeLogo = () => {
        setImageBase64(null);
        setImageName(null)
        setImageUrl(null)
        setEntityDetail({
            ...entityDetail
        })
    }
    const onPropertyChanged = () => {
        let data = form.getFieldsValue()
        if (data) {
            let objData = {
                ...entityDetail
            }

            Object.assign(objData, data)
            //objData.Logo = imageBase64

            if (!CommonService.shallowCompare(objData, entityDetail)) {
                setConfirmLeave(true)
            } else {
                setConfirmLeave(false)
            }
        }
    }

    return (
        <div className="club-state-detail-page">
            <ConfirmLeavePage
                shouldPrompt={confirmLeave}
            >
                {({ leavePage, stayOnPage, isDialogActive }) => (
                    <Modal
                        title={t("common.warning")}
                        open={isDialogActive}
                        onOk={leavePage}
                        onCancel={stayOnPage}
                    >
                        <p>
                            {t("entity_detail.leave_warning")}
                        </p>
                    </Modal>
                )}
            </ConfirmLeavePage>
            {currentPosition && props.param == Constant.Pages.ClubDetail && currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee &&
                <div>
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                        <h5 className='color-dark-blue'>{t("entity_detail.club_details")}</h5>
                    </Row>
                    <Form
                        form={form}
                        name="formDetail"
                        layout="vertical"
                        onFinish={onSubmit}
                    >
                        <Row gutter={[Constant.SpaceConstant.HorizontalGutter, Constant.SpaceConstant.VerticalGutter]}>
                            <Col xs={{ span: 12 }}>
                                <Form.Item
                                    label={t("entity_detail.club_name")}
                                    name="EntityName" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("entity_detail.club_name_required") }]}
                                >
                                    <Input maxLength={100} onChange={onPropertyChanged} disabled={true} />
                                </Form.Item>
                                <Form.Item
                                    label={t("entity_detail.club_acronym")}
                                    name="EntityAcronym" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("entity_detail.club_acronym_required") }]}
                                >
                                    <Input maxLength={100} onChange={onPropertyChanged} disabled={true} />
                                </Form.Item>

                                <Form.Item
                                    label={t("entity_detail.state_entity")}
                                    name="ParentEntityId"
                                    rules={[{ required: true, message: t("entity_detail.state_entity_required") }]}
                                >
                                    <EnhancedSelect onChange={onPropertyChanged}
                                        showSearch optionFilterProp="children"
                                        disabled={true}
                                        filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                    >
                                        {
                                            stateEntities.map(n => <Option key={n.EntityID} value={n.EntityID}>{n.EntityName}</Option>)
                                        }
                                    </EnhancedSelect>

                                </Form.Item>
                                <Form.Item
                                    label={t("entity_detail.state_zone")}
                                    name="ZoneID"
                                >
                                    <EnhancedSelect allowClear={true} onChange={onPropertyChanged}
                                        showSearch optionFilterProp="children"
                                        disabled={true}
                                        filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                    >
                                        {
                                            zones.map(n => <Option key={n.Id} value={n.Id}>{n.Name}</Option>)
                                        }
                                    </EnhancedSelect>

                                </Form.Item>
                                <Form.Item
                                    label={t("entity_detail.address")}
                                    name="EntityStreetNo" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("entity_detail.address_required") }]}
                                >
                                    <Input maxLength={100} onChange={onPropertyChanged} />
                                </Form.Item>
                                <Form.Item
                                    label={t("entity_detail.suburb")}
                                    name="EntitySuburb" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("entity_detail.suburb_required") }]}
                                >
                                    <Input maxLength={100} onChange={onPropertyChanged} />
                                </Form.Item>
                                <Form.Item
                                    label={t("entity_detail.post_code")}
                                    name="EntityPostCode" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("entity_detail.post_code_required") }]}
                                >
                                    <Input maxLength={100} onChange={onPropertyChanged} />
                                </Form.Item>
                                <Form.Item
                                    label={t("entity_detail.state")}
                                    name="EntityState" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("entity_detail.state_required") }]}
                                >
                                    <EnhancedSelect onChange={onPropertyChanged}
                                        showSearch optionFilterProp="children"
                                        filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                    >
                                        {
                                            states.map(n => <Option key={n.Acronym} value={n.Acronym}>{n.Acronym}</Option>)
                                        }
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 12 }}>
                                <Form.Item
                                    label={t('entity_detail.club_logo')}
                                    colon={false} labelAlign="left"
                                    className='upload-item'
                                >
                                    <Upload
                                        className="avatar-uploader"
                                        listType="picture-card"
                                        showUploadList={false}
                                        beforeUpload={beforeUploadPhoto}
                                        accept="image/*"
                                    >
                                        {
                                            imageBase64 ?
                                                <img alt={t('entity_detail.club_logo')} className="entity-logo" src={imageBase64} />
                                                : (imageUrl ? <img alt={t('entity_detail.club_logo')} className="entity-logo" src={imageUrl} />
                                                    : <img alt={t('entity_detail.club_logo')} className="entity-logo" src={AddImage} />
                                                )
                                        }
                                    </Upload>
                                    {(imageBase64 || imageUrl) &&
                                        <span className="remove-logo">
                                            <Button type="primary" shape="circle" icon={<DeleteOutlined />} onClick={removeLogo} size="middle" />
                                        </span>
                                    }
                                </Form.Item>
                                <Form.Item
                                    label={t("entity_detail.club_website")}
                                    name="Website" colon={false} labelAlign="left"
                                >
                                    <Input maxLength={100} onChange={onPropertyChanged} />
                                </Form.Item>
                                <Form.Item
                                    label={t("entity_detail.club_description")}
                                    name="Description" colon={false} labelAlign="left"
                                >
                                    <TextArea rows={2} maxLength={500} onChange={onPropertyChanged} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                            <Col flex={100} className="text-right">
                                <div>
                                    <Button className='btn-outline-medium-grey' onClick={onCancel}>{t("common.cancel")}</Button>
                                    <Button size="large" type="primary" className='m-l-10' htmlType="submit">{t("common.submit")}</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div >
            }
            {currentPosition && props.param == Constant.Pages.StateDetail && (currentPosition.PositionTypeId == Constant.PositionType.StateExcutive) &&
                <div>
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                        <h5 className='color-dark-blue'>{t("entity_detail.association_details")}</h5>
                    </Row>
                    <Form
                        form={form}
                        name="formDetail"
                        layout="vertical"
                        onFinish={onSubmit}
                    >
                        <Row gutter={[Constant.SpaceConstant.HorizontalGutter, Constant.SpaceConstant.VerticalGutter]}>
                            <Col xs={{ span: 12 }}>
                                <Form.Item
                                    label={t("entity_detail.association_name")}
                                    name="EntityName" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("entity_detail.association_name_required") }]}
                                >
                                    <Input maxLength={100} onChange={onPropertyChanged} disabled={true} />
                                </Form.Item>
                                <Form.Item
                                    label={t("entity_detail.association_acronym")}
                                    name="EntityAcronym" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("entity_detail.association_acronym_required") }]}
                                >
                                    <Input maxLength={100} onChange={onPropertyChanged} disabled={true} />
                                </Form.Item>
                                <Form.Item
                                    label={t("entity_detail.national_entity")}
                                    name="ParentEntityId"
                                    rules={[{ required: true, message: t("entity_detail.national_entity_required") }]}
                                >
                                    <EnhancedSelect disabled={true} onChange={onPropertyChanged}
                                        showSearch optionFilterProp="children"
                                        filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                    >
                                        {
                                            nationalEntities.map(n => <Option key={n.EntityID} value={n.EntityID}>{n.EntityName}</Option>)
                                        }
                                    </EnhancedSelect>

                                </Form.Item>
                                <Form.Item
                                    label={t("entity_detail.address")}
                                    name="EntityStreetNo" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("entity_detail.address_required") }]}
                                >
                                    <Input maxLength={100} onChange={onPropertyChanged} />
                                </Form.Item>
                                <Form.Item
                                    label={t("entity_detail.suburb")}
                                    name="EntitySuburb" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("entity_detail.suburb_required") }]}
                                >
                                    <Input maxLength={100} onChange={onPropertyChanged} />
                                </Form.Item>
                                <Form.Item
                                    label={t("entity_detail.post_code")}
                                    name="EntityPostCode" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("entity_detail.post_code_required") }]}
                                >
                                    <Input maxLength={100} onChange={onPropertyChanged} />
                                </Form.Item>
                                <Form.Item
                                    label={t("entity_detail.state")}
                                    name="EntityState" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("entity_detail.state_required") }]}
                                >
                                    <EnhancedSelect onChange={onPropertyChanged}
                                        showSearch optionFilterProp="children"
                                        filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                    >
                                        {
                                            states.map(n => <Option key={n.Acronym} value={n.Acronym}>{n.Acronym}</Option>)
                                        }
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 12 }}>
                                <Form.Item
                                    label={t('entity_detail.association_logo')}
                                    colon={false} labelAlign="left"
                                    className='upload-item'
                                >
                                    <Upload
                                        className="avatar-uploader"
                                        listType="picture-card"
                                        showUploadList={false}
                                        beforeUpload={beforeUploadPhoto}
                                        accept="image/*"
                                    >
                                        {
                                            imageBase64 ?
                                                <img alt={t('entity_detail.club_logo')} className="entity-logo" src={imageBase64} />
                                                : (imageUrl ? <img alt={t('entity_detail.club_logo')} className="entity-logo" src={imageUrl} />
                                                    : <img alt={t('entity_detail.club_logo')} className="entity-logo" src={AddImage} />
                                                )
                                        }
                                    </Upload>
                                    {(imageBase64 || imageUrl) &&
                                        <span className="remove-logo">
                                            <Button type="primary" shape="circle" icon={<DeleteOutlined />} onClick={removeLogo} size="middle" />
                                        </span>
                                    }
                                </Form.Item>
                                <Form.Item
                                    label={t("entity_detail.association_website")}
                                    name="Website" colon={false} labelAlign="left"
                                >
                                    <Input maxLength={100} onChange={onPropertyChanged} />
                                </Form.Item>
                                <Form.Item
                                    label={t("entity_detail.association_description")}
                                    name="Description" colon={false} labelAlign="left"
                                >
                                    <TextArea rows={2} maxLength={500} onChange={onPropertyChanged} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                            <Col flex={100} className="text-right">
                                <div>
                                    <Button className='btn-outline-medium-grey' onClick={onCancel}>{t("common.cancel")}</Button>
                                    <Button size="large" type="primary" className='m-l-10' htmlType="submit">{t("common.submit")}</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div >
            }
            {currentPosition && ((currentPosition.PositionTypeId != Constant.PositionType.ClubCommittee &&
                currentPosition.PositionTypeId != Constant.PositionType.StateExcutive) ||
                (props.param == Constant.Pages.StateDetail && currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee) ||
                (props.param == Constant.Pages.ClubDetail && currentPosition.PositionTypeId == Constant.PositionType.StateExcutive)) &&
                < div >
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_access")} />
                </div >
            }
        </div>
    )
}

export default ClubStateDetail
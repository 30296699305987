import React, { useState, useEffect } from 'react';
import './viewDocuments.scss';
import { Constant, CommonService, StorageService, DocumentService } from '../../../services/services';
import { useTranslation } from 'react-i18next';
import { GetTableConfigs, StatusCell, ActionButtonsCell, DateTimeCell, YesNoCell } from '../../../components/customTable/customTable'
import LoadingContext from '../../../components/loading/loadingContext'
import { FilterAltIcon, EyeIcon, TrashIcon, CloseIcon, EditIcon } from '../../../components/icons/icons';
import { Row, Col, Table, Button, DatePicker, Modal, Form, Drawer, Space, Input, Card, Typography, Empty, Upload } from 'antd';
import { useGlobalState } from '../../../utilities/globalState'
import EnhancedSelect from '../../../components/enhancedSelect/enhancedSelect';
import ViewDocumentItem from './viewDocumentItem';
import PubSub from 'pubsub-js'

const { Title, Text } = Typography;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const ViewDocuments = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext);
    const [sourceForm] = Form.useForm();
    const [entities, setEntities] = useState([]);
    const [documentCategories, setDocumentCategories] = useState([]);
    const [tournaments, setTournaments] = useState([]);
    const [documentSource, setDocumentSource] = useState({DocumentSource: -1});
    const [documentSourceOptions, setDocumentSourceOptions] = useState([]);

    var [currentPosition] = useGlobalState('currentPosition');

    const onSubmit = () => {
        let data = sourceForm.getFieldsValue();
        if (!documentSource) {
            documentSource = data;
        }
        else {
            documentSource.DocumentSource = data.DocumentSource;
        }

        showLoading()
        Promise.all([
            DocumentService.getDocumentsCategories(documentSource.DocumentSource),
            DocumentService.getTournaments(documentSource.DocumentSource),
        ])
        .finally(() => {
            dismissLoading();
        })
        .then(response => {
            if (response[0].data) {
                setDocumentCategories(response[0].data);
            }

            if (response[1].data) {
                setTournaments(response[1].data);
            }
        })
        .catch(error => {
            CommonService.handleErrorResponse(error);
        })
    }

    const columns = [
        {
            title: t('document.title'),
            dataIndex: 'Title',
            key: 'Title',
            sorter: true,
            defaultSortOrder: 'ascend',
            width: 140,
            fixed: 'left'
        },
        {
            title: t('document.file_name'),
            dataIndex: 'FileName',
            key: 'FileName',
            sorter: true,
            width: 150,
            fixed: 'left'
        },
        {
            title: t('document.date'),
            dataIndex: 'DocumentDate',
            key: 'DocumentDate',
            sorter: true,
            width: 70,
            fixed: 'left',
            render: (value, record) => (
                <DateTimeCell value={record.DocumentDate} />
            )
        }
    ]

    const mainColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                disabled: col.disabled,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition,
                selectionKey: col.selectionKey,
                selectionDisplay: col.selectionDisplay,
                validateAtStart: col.validateAtStart,
                allowClear: col.allowClear
            }),
        };
    })

    const getAcronym = () => {
        if (typeof (currentPosition.PositionTypeId) === 'undefined') {
            currentPosition = StorageService.getCurrentPosition();
        }

        if (currentPosition && currentPosition.Acronym) {
            return '(' + currentPosition.Acronym + ')';
        } else {
            return '';
        }
    }
    
    const getStateAssociationAcronym = () => {
        if (typeof (currentPosition.PositionTypeId) === 'undefined') {
            currentPosition = StorageService.getCurrentPosition();
        }

        if (currentPosition && (currentPosition.EntityTypeId === Constant.EntityType.Gamebase || currentPosition.EntityTypeId === Constant.EntityType.National || currentPosition.EntityTypeId === Constant.EntityType.State)) {
            return currentPosition.Acronym;
        } else if (currentPosition && currentPosition.EntityTypeId === Constant.EntityType.Club) {
            return currentPosition.StateAssociationAcronym;
        } else {
            return '';
        }
    }

    const isStateAssociation = () => {
        if (typeof (currentPosition.PositionTypeId) === 'undefined') {
            currentPosition = StorageService.getCurrentPosition();
        }

        if (currentPosition && (currentPosition.EntityTypeId === Constant.EntityType.Gamebase || currentPosition.EntityTypeId === Constant.EntityType.National || currentPosition.EntityTypeId === Constant.EntityType.State)) {
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
        }, 100)

        showLoading()
        Promise.all([
            DocumentService.getDocumentsCategories(documentSource.DocumentSource),
            DocumentService.getTournaments(documentSource.DocumentSource),
            DocumentService.getFilterDatas(true)
        ])
        .finally(() => {
            dismissLoading();
        })
        .then(response => {
            if (response[0].data) {
                setDocumentCategories(response[0].data);
            }

            if (response[1].data) {
                setTournaments(response[1].data);
            }

            if (response[2].data) {
                setEntities(response[2].data.Entities);

                var newDocumentSourceOptions = [];
                newDocumentSourceOptions.push(
                    {
                        label: '',
                        options: [
                            {
                                label: t("document.my_documents", { acronym: getAcronym() }),
                                value: -1,
                            },
                        ],
                    });
                
                newDocumentSourceOptions.push({
                        label: '',
                        options: [
                            {
                                label: t("document.all_clubs_shared_documents", { state_association_acronym: getStateAssociationAcronym() }),
                                value: -2,
                            },
                        ],
                    });

                if (!isStateAssociation()) {
                    newDocumentSourceOptions.push(
                        {
                            label: '',
                            options: [
                                {
                                    label: t("document.shared_documents", { state_association_acronym: getStateAssociationAcronym() }),
                                    value: -3,
                                },
                            ],
                        });
                }

                newDocumentSourceOptions.push({
                        label: t("document.specific_clubs_shared_documents", { state_association_acronym: getStateAssociationAcronym() }),
                        options: response[2].data.Entities && response[2].data.Entities.length > 0 ? response[2].data.Entities.map((n, index) => {
                            return {
                                label: n.Name,
                                value: n.Id,
                            }
                        }) : [],
                    });

                setDocumentSourceOptions(newDocumentSourceOptions);
                sourceForm.setFieldsValue(documentSource);
            }
        })
        .catch(error => {
            CommonService.handleErrorResponse(error);
        })

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);
        return () => {
            DocumentService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    return (
        <>
            <div>
                <div id={Constant.FixItemsContainerId}>
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                        <Title className='color-dark-blue' level={5}>{t("document.view_documents")}</Title>
                    </Row>

                    <Card style={{ width: "100%" }} className='document-card-view'>
                        <Row className='p-t-10 p-b-10 text-center'>
                            <Col span={5} className="text-center"></Col>
                            <Col span={14} className="text-center">
                                <Title className='color-dark-blue' level={5} style={{fontSize: '16px'}}>{t("document.documents_available_from_various_Sources")}</Title>
                            </Col>
                            <Col span={5} className="text-center"></Col>
                        </Row>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                            <Col span={5} className="text-center"></Col>
                            <Col span={14} className="text-center">
                                <Form
                                    name='sourceForm'
                                    key='sourceForm'
                                    layout="inline"
                                    onFinish={onSubmit}
                                    form={sourceForm}
                                    style={{ width: '100%' }}>
                                    <Form.Item
                                        label=''
                                        name="DocumentSource" colon={false} labelAlign="left"
                                        className='document-source-select'>
                                        <EnhancedSelect  showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={documentSourceOptions}
                                        >
                                        </EnhancedSelect>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button className="m-l-10 secondary-btn btn-solid-dark-blue" type="primary" htmlType="submit">
                                            {t("common.submit")}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col span={5} className="text-center"></Col>
                        </Row>
                    </Card>
                </div>
                <Row className="m-t-5" gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                    {documentCategories && documentCategories.length > 0 &&
                        documentCategories.map((line, index) => (
                            <Row key={index} className="m-t-10" style={{ width: "100%" }} gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                                <Col xs={{ span: 24 }}>
                                    <ViewDocumentItem
                                        id={index}
                                        isDocument={true}
                                        documentCategory={line}
                                        />
                                </Col>
                            </Row>
                        ))
                    }

                    {tournaments && tournaments.length > 0 &&
                        <Row className="m-t-10" style={{ width: "100%" }} gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                            <Col xs={{ span: 24 }}>
                                <ViewDocumentItem
                                    id={100000000}
                                    isDocument={false}
                                    tournaments={tournaments}
                                    />
                            </Col>
                        </Row>
                    }
                </Row>
            </div>
        </>

    )

}

export default ViewDocuments;
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import './login.scss';
import { Button, Form, Row, Col, Input, Typography, Space, Select } from 'antd';
import { LockIcon, UserIcon } from '../../components/icons/icons'
import logo from './../../assets/images/logo.png';
import { Constant, AuthService, CommonService, StorageService, UserService } from '../../services/services'
import { useGlobalState } from '../../utilities/globalState'
import LoadingContext from './../../components/loading/loadingContext'
import EnhancedSelect from '../../components/enhancedSelect/enhancedSelect';

const { Text } = Typography;
const { Option } = Select;


const screenTypes = {
    login: 1,
    positionSelection: 2,
    changePassword: 3
}

function Login(props) {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [userPositions, setUserPositions] = useGlobalState('userPositions');
    const [, setCurrentPosition] = useGlobalState('currentPosition');
    const [screenType, setScreenType] = useState(screenTypes.login)
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)

    const onLogin = (data) => {
        showLoading()
        AuthService.login(data.Username, data.Password)
            .finally(() => {
                dismissLoading()
            })
            .then(result => {
                if (result) {
                    if (result.positions && result.positions.length > 0) {
                        setUserPositions(result.positions)

                        if (result.positions.length == 1) {
                            if (!result.positions[0].Menus || result.positions[0].Menus.length == 0) {
                                CommonService.presentToast("warning", t("login.no_menu"))
                                return
                            }
                            setCurrentPosition(result.positions[0])
                            StorageService.setCurrentPosition(result.positions[0])

                            if (result.changePasswordRequired == true) {
                                setScreenType(screenTypes.changePassword)
                            } else {
                                history.push(Constant.Pages.Home);
                            }
                        } else {
                            setScreenType(screenTypes.positionSelection)
                        }
                    }
                    else {
                        CommonService.presentToast("warning", t("login.no_entity"))
                    }
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    };

    const goToForgetPassword = () => {
        history.push(Constant.Pages.ForgetPassword)
    }

    const onPositionSelect = (data) => {
        let authData = StorageService.getAuthData()

        let position = userPositions.find(e => e.Id == data.Id)
        if (position) {
            if(authData && position.PositionTypeId == Constant.PositionType.ClubMember && authData.fishingTypeId == Constant.FishingType.NonFishing) {
                CommonService.presentToast("warning", t("login.invalid_fishing_type"))
                setScreenType(screenTypes.login)
                return
            }

            if (!position.Menus || position.Menus.length == 0) {
                CommonService.presentToast("warning", t("login.no_menu"))
                setScreenType(screenTypes.login)
                return
            }
            setCurrentPosition(position)
            StorageService.setCurrentPosition(position)
            
            if (authData && authData.changePasswordRequired == true) {
                setScreenType(screenTypes.changePassword)
            } else {
                history.push(Constant.Pages.Home);
            }
        }
    }

    const onChangePassword = (data) => {
        showLoading()
        UserService.changePassword(data.Password, data.ConfirmPassword)
            .finally(() => dismissLoading())
            .then(() => {
                history.push(Constant.Pages.Home);
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    return (
        <div className={`login-page`}>
            {screenType == screenTypes.login &&
                <div>
                    <Space direction="vertical" className='full-width m-b-35' size={0}>
                        <img src={logo} />
                        <Text className='color-black'>{t("login.login_message")}</Text>
                    </Space>

                    <Form
                        name="loginForm"
                        layout="vertical"
                        initialValues={props.objectData}
                        onFinish={onLogin}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    className='m-b-0'
                                    name="Username" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("login.username_required") }]}
                                >
                                    <Input size="large" placeholder={t("login.username")} prefix={<UserIcon />} allowClear />
                                </Form.Item>
                            </Col>
                            <Col span={24} className='text-left m-b-10'>
                                <Text className='color-blue'>{t("login.username_format")}</Text>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="Password" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("login.password_required") }]}
                                >
                                    <Input.Password size="large" placeholder={t("login.password")} prefix={<LockIcon />} allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Space direction="vertical" className='full-width m-t-20'>
                            <Button className='blue-linear-gradient-bg' size="large" htmlType="submit" type="primary" block>
                                {t("login.login")}
                            </Button>
                            <Button type="link" onClick={goToForgetPassword} block>
                                {t("login.forgot_password")}
                            </Button>
                        </Space>
                    </Form>
                </div>
            }
            {screenType == screenTypes.positionSelection &&
                <div>
                    <Space direction="vertical" className='full-width m-b-35' size={0}>
                        <img src={logo} />
                        <Text className='color-black'>You have access for more than 1 entity.</Text>
                        <Text className='color-black'>Please select which one you would like to access</Text>
                    </Space>

                    <Form
                        name="loginForm"
                        layout="vertical"
                        initialValues={props.objectData}
                        onFinish={onPositionSelect}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    className='m-b-0'
                                    name="Id" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("login.username_required") }]}
                                >
                                    <EnhancedSelect
                                        showSearch optionFilterProp="children"
                                        filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                    >
                                        {
                                            userPositions.map((n, index) => <Option key={index} value={n.Id}>{n.PositionTitleName + " - " + n.EntityName}</Option>)
                                        }
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Space direction="vertical" className='full-width m-t-20'>
                            <Button className='blue-linear-gradient-bg' size="large" htmlType="submit" type="primary" block>
                                {t("login.submit")}
                            </Button>
                        </Space>
                    </Form>

                </div>
            }
            {screenType == screenTypes.changePassword &&
                <div>
                    <Space direction="vertical" className='full-width m-b-35' size={0}>
                        <img src={logo} />
                        <Text className='color-black'>{t("login.change_password")}</Text>
                    </Space>

                    <Form
                        name="loginForm"
                        layout="vertical"
                        initialValues={props.objectData}
                        onFinish={onChangePassword}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    className='m-b-10'
                                    name="Password" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("login.password_required") }]}
                                >
                                    <Input.Password size="large" placeholder={t("login.password")} prefix={<LockIcon />} allowClear />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="ConfirmPassword" colon={false} labelAlign="left"
                                    rules={[({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            let password = getFieldValue('Password')
                                            if (value != password) {
                                                return Promise.reject(t("login.confirm_password_mismatch"));
                                            }
                                            return Promise.resolve();
                                        },
                                    })]}
                                >
                                    <Input.Password size="large" placeholder={t("login.confirm_password")} prefix={<LockIcon />} allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Space direction="vertical" className='full-width m-t-20'>
                            <Button className='blue-linear-gradient-bg' size="large" htmlType="submit" type="primary" block>
                                {t("login.submit")}
                            </Button>
                        </Space>
                    </Form>
                </div>
            }
        </div>
    )
}

export default Login;
import axios from 'axios';
import { axiosPortal } from './authService'

const source = [];
let baseURL = "api/Record"

export const RecordService = {
    getRecords: function (pageNumber, pageSize, sort, params, isClubRecords) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/Records?PageNumber=${pageNumber ? pageNumber : 1}&isClubRecords=${isClubRecords}`

        if (sort) {
            url += `&Sort=${sort}`
        }
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (params) {
            if (params.FishSpecyIds) {
                url += `&FishSpecyIds=${params.FishSpecyIds}`;
            }
            if (params.RecordCategoryIds) {
                url += `&RecordCategoryIds=${params.RecordCategoryIds}`;
            }
            if (params.EnvironmentIds) {
                url += `&EnvironmentIds=${params.EnvironmentIds}`;
            }
            if (params.FirstName) {
                url += `&FirstName=${params.FirstName}`;
            }
            if (params.Surname) {
                url += `&Surname=${params.Surname}`;
            }
            if (params.GenderIds) {
                url += `&GenderIds=${params.GenderIds}`;
            }
            if (params.AgeCategoryIds) {
                url += `&AgeCategoryIds=${params.AgeCategoryIds}`;
            }
            if (params.ClubIds) {
                url += `&ClubIds=${params.ClubIds}`;
            }
            if (params.LineClassIds) {
                url += `&LineClassIds=${params.LineClassIds}`;
            }
            if (params.StatusIds) {
                url += `&StatusIds=${params.StatusIds}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getStateRecords: function (pageNumber, pageSize, sort, params) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/StateRecords?PageNumber=${pageNumber ? pageNumber : 1}`

        if (sort) {
            url += `&Sort=${sort}`
        }
        if (pageSize) {
            url += `&PageSize=${pageSize}`
        }

        if (params) {
            if (params.FishSpecyIds) {
                url += `&FishSpecyIds=${params.FishSpecyIds}`;
            }
            if (params.RecordCategoryIds) {
                url += `&RecordCategoryIds=${params.RecordCategoryIds}`;
            }
            if (params.EnvironmentIds) {
                url += `&EnvironmentIds=${params.EnvironmentIds}`;
            }
            if (params.FirstName) {
                url += `&FirstName=${params.FirstName}`;
            }
            if (params.Surname) {
                url += `&Surname=${params.Surname}`;
            }
            if (params.GenderIds) {
                url += `&GenderIds=${params.GenderIds}`;
            }
            if (params.AgeCategoryIds) {
                url += `&AgeCategoryIds=${params.AgeCategoryIds}`;
            }
            if (params.ClubIds) {
                url += `&ClubIds=${params.ClubIds}`;
            }
            if (params.LineClassIds) {
                url += `&LineClassIds=${params.LineClassIds}`;
            }
            if (params.StatusIds) {
                url += `&StatusIds=${params.StatusIds}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getRecordFilterData: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/RecordFilterData`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    addRecord: function (record) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/Record`

        return axiosPortal.post(url, record, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    updateRecord: function (record) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/Record`

        return axiosPortal.put(url, record, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    updateRecordStatus: function (records) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/UpdateRecordStatus`

        return axiosPortal.put(url, records, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    deleteRecords: function (recordIds) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/DeleteRecords`

        return axiosPortal.post(url, recordIds, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getExportRecords: function (sort, params, fileType, isState, isClubRecord = false)  {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/ExportRecords?fileType=${fileType}&isStateRecord=${isState}&isClubRecords=${isClubRecord}`
        if (sort) {
            url += `&Sort=${sort}`
        }

        if (params) {
            if (params.FishSpecyIds) {
                url += `&FishSpecyIds=${params.FishSpecyIds}`;
            }
            if (params.RecordCategoryIds) {
                url += `&RecordCategoryIds=${params.RecordCategoryIds}`;
            }
            if (params.EnvironmentIds) {
                url += `&EnvironmentIds=${params.EnvironmentIds}`;
            }
            if (params.FirstName) {
                url += `&FirstName=${params.FirstName}`;
            }
            if (params.Surname) {
                url += `&Surname=${params.Surname}`;
            }
            if (params.GenderIds) {
                url += `&GenderIds=${params.GenderIds}`;
            }
            if (params.AgeCategoryIds) {
                url += `&AgeCategoryIds=${params.AgeCategoryIds}`;
            }
            if (params.ClubIds) {
                url += `&ClubIds=${params.ClubIds}`;
            }
            if (params.LineClassIds) {
                url += `&LineClassIds=${params.LineClassIds}`;
            }
            if (params.StatusIds) {
                url += `&StatusIds=${params.StatusIds}`;
            }
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token,
            responseType: 'blob'
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    cancelRequest: function () {
        if (source && source.length > 0) {
            for (var i = 0; i < source.length; i++) {
                source[i].cancel('Operation canceled.')
            }
            source.splice(0, source.length)
        }
    },
}
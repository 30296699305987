import React, { useState, useEffect } from 'react';
import { Constant, CommonService, FishingActivityService, SettingService } from '../../services/services';
import { useTranslation } from 'react-i18next';
import LoadingContext from '../../components/loading/loadingContext'
import { useGlobalState } from './../../utilities/globalState'
import { GetTableConfigs, EditableCell, StatusCell } from '../../components/customTable/customTable'
import { Row, Col, Table, Button, Modal, Form, Drawer, Space, Input, Select, Typography, Empty } from 'antd';
import { CloseIcon, EyeIcon, FilterAltIcon } from '../../components/icons/icons';
import ConfirmLeavePage from "../../components/confirmLeavePage/confirmLeavePage"
import PubSub from 'pubsub-js'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import EnhancedSelect from '../../components/enhancedSelect/enhancedSelect';
import './activityEntryTagCardApprovals.scss'

const { Option } = Select;
const { Search } = Input;
const { Text, Title } = Typography;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};
const defaultGridOption = {
    pageNumber: 1,
    pageSize: CommonService.getPageSize(),
    sort: "DayLogId-ascend"
}
const { confirm } = Modal;

const ActivityEntryTagCardApprovals = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const emptyTableData = {
        ObjectList: [],
        TotalItems: 0
    }
    const [activityApprovalsForm] = Form.useForm();
    const [currentPosition] = useGlobalState('currentPosition');
    const [entityDetail, setEntityDetail] = useState({})
    const [tagApprovals, setTagApprovals] = useState([]);
    const [activityLineData, setActivityLineData] = useState(emptyTableData);
    const [clubData, setClubData] = useState([]);
    const [dayLogFilter, setDayLogFilter] = useState({});
    const [enableSelectionModal, setEnableSelectionModal] = React.useState(false);
    const [enableFilterModal, setEnableFilterModal] = React.useState(false);
    const [gridConfigOptions, setGridConfigOptionsValue] = useState(defaultGridOption)
    const [formFilter] = Form.useForm();
    const [selectionDayLogFilter, setSelectionDayLogFilter] = useState();
    const [confirmLeave, setConfirmLeave] = useState(false)
    const [isDirty, setIsDirty] = useState(false);
    const [selectedActivityLineData, setSelectedActivityLineData] = useState([]);
    const [sortedInfo, setSortedInfo] = useState({});

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
        }, 200)
        Promise.all([
            SettingService.getStateEntityDetail(),
            FishingActivityService.getTagApproval()
        ])
            .then(response => {
                if (response[0] && response[0].data) {
                    setEntityDetail(response[0].data)
                }

                if (response[1] && response[1].data) {
                    setTagApprovals(response[1].data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })

        getActivityLinesForActivityApproval(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, dayLogFilter)

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);
        return () => {
            SettingService.cancelRequest()
            clearTimeout(timeoutFn);
        }


    }, [])

    useEffect(() => {
        if (currentPosition && currentPosition.PositionTypeId == Constant.PositionType.StateExcutive) {
            getClubsByState()
        }

    }, [currentPosition])

    const updateChangedValue = (value, record, dataIndex) => {
        setIsDirty(true)
        let activityLinesTemp = activityLineData
        let selectedRecords = activityLinesTemp.ObjectList.filter(x => x.Id == record.Id)
        if (selectedRecords && selectedRecords.length > 0) {
            let index = activityLinesTemp.ObjectList.indexOf(selectedRecords[0])
            if (activityLinesTemp.ObjectList[index]) {
                activityLinesTemp.ObjectList[index][dataIndex] = value;
                setActivityLineData(activityLinesTemp)
                setConfirmLeave(true)
            }
        }

        let selectedActivityLines = selectedActivityLineData.filter(x => x.Id == record.Id)
        if (!selectedActivityLines || selectedActivityLines.length == 0)
            selectedActivityLineData.push(record)
        else {
            selectedActivityLines[0][dataIndex] = value
        }
        setSelectedActivityLineData(selectedActivityLineData)
    }

    const handleOnChange = (value, record, dataIndex) => {
        if (record) {
            // setIsDirty(true)
            // let temp = activityLineData.map(x => Object.assign({}, x));
            // let index = activityLineData.indexOf(record)
            // if (temp[index]) {
            //     temp[index][dataIndex] = value;

            //     setActivityLineData(temp)
            //     setConfirmLeave(true)
            // }
            updateChangedValue(value, record, dataIndex)
        }
    }

    const canSubmit = () => {
        if (isDirty && selectedActivityLineData && selectedActivityLineData.length > 0) {
            return true;
        }
        return false;
    }

    const getClubsByState = () => {
        showLoading()
        FishingActivityService.getClubsByState()
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    setClubData(result.data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    const columns = [
        {
            title: t("activity_approvals.log_id"),
            dataIndex: 'DayLogId',
            key: 'DayLogId',
            width: 200,
            fixed: 'left',
            sorter: true,
            sortOrder: sortedInfo && sortedInfo.columnKey === 'DayLogId' ? sortedInfo.order : null

        },
        {
            title: t("fishing_activity.asn"),
            dataIndex: 'ActivitySheetNumber',
            key: 'ActivitySheetNumber',
            width: 80,
            fixed: 'left',
            sorter: true,
            sortOrder: sortedInfo && sortedInfo.columnKey === 'ActivitySheetNumber' ? sortedInfo.order : null
        },
        {
            title: t("fishing_activity.club"),
            dataIndex: 'ClubAcronym',
            key: 'ClubAcronym',
            width: 120,
            sorter: true,
            sortOrder: sortedInfo && sortedInfo.columnKey === 'ClubAcronym' ? sortedInfo.order : null,
            hidden: currentPosition && currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee ? true : false

        },
        {
            title: t("fishing_activity.date"),
            dataIndex: 'ActivityDate',
            key: 'ActivityDate',
            width: 80,
            fixed: 'left',
            sorter: true,
            sortOrder: sortedInfo && sortedInfo.columnKey === 'ActivityDate' ? sortedInfo.order : null,
            hidden: currentPosition && currentPosition.PositionTypeId != Constant.PositionType.ClubCommittee ? true : false,
            render: (value, record) => {
                if (record.ActivityDate) {
                    let date = new Date(record.ActivityDate)
                    var day = date.getDate();
                    var month = date.getMonth() + 1; //Month from 0 to 11
                    var year = date.getFullYear();

                    return `${day}/${month}/${year}`
                }

                return null
            }
        },
        {
            title: t("fishing_activity.species"),
            dataIndex: 'FishSpeciesName',
            key: 'FishSpeciesName',
            width: 300,
            sorter: true,
            sortOrder: sortedInfo && sortedInfo.columnKey === 'FishSpeciesName' ? sortedInfo.order : null
        },
        {
            title: t("fishing_activity.weight"),
            dataIndex: 'FishWeight',
            key: 'FishWeight',
            width: 100,
            sorter: true,
            sortOrder: sortedInfo && sortedInfo.columnKey === 'FishWeight' ? sortedInfo.order : null
        },
        {
            title: t('fishing_activity.weight_measure'),
            dataIndex: 'IsWeightMeasure',
            key: 'IsWeightMeasure',
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.IsWeightMeasure)}
                    description={CommonService.getStatusDescription(record.IsWeightMeasure, true)} />
            ),
            width: 80,
            hidden: currentPosition && currentPosition.PositionTypeId != Constant.PositionType.ClubCommittee ? true : false
        },
        {
            title: t("fishing_activity.line_class"),
            dataIndex: 'LineClassWeight',
            key: 'LineClassWeight',
            width: 80,
            sorter: true,
            sortOrder: sortedInfo && sortedInfo.columnKey === 'LineClassWeight' ? sortedInfo.order : null
        },
        {
            title: t("fishing_activity.angler"),
            dataIndex: 'AnglerName',
            key: 'AnglerName',
            width: 200
        },
        {
            title: t("activity_approvals.angler_club"),
            dataIndex: 'AnglerClubAcronym',
            key: 'AnglerClubAcronym',
            width: 120,
            hidden: currentPosition && currentPosition.PositionTypeId != Constant.PositionType.ClubCommittee ? true : false
        },
        {
            title: t("fishing_activity.fishing_category"),
            dataIndex: 'FishingCategoryName',
            key: 'FishingCategoryName',
            width: 120,
            sorter: true,
            sortOrder: sortedInfo && sortedInfo.columnKey === 'FishingCategoryName' ? sortedInfo.order : null,
            hidden: currentPosition && currentPosition.PositionTypeId != Constant.PositionType.ClubCommittee ? true : false
        },
        {
            title: t("activity_approvals.club_ps_zone"),
            dataIndex: 'ClubZoneName',
            key: 'ClubZoneName',
            width: 150,
            sorter: true,
            sortOrder: sortedInfo && sortedInfo.columnKey === 'ClubZoneName' ? sortedInfo.order : null,
            hidden: currentPosition && currentPosition.PositionTypeId != Constant.PositionType.ClubCommittee ? true : false
        },
        {
            title: t("fishing_activity.tag_number"),
            dataIndex: 'TagNumber',
            key: 'TagNumber',
            width: 130,
            sorter: true,
            sortOrder: sortedInfo && sortedInfo.columnKey === 'TagNumber' ? sortedInfo.order : null,
            hidden: currentPosition && currentPosition.PositionTypeId != Constant.PositionType.ClubCommittee ? true : false
        },
        {
            title: t("fishing_activity.tag_capt"),
            dataIndex: 'TagCaptureName',
            key: 'TagCaptureName',
            width: 110,
            sorter: true,
            sortOrder: sortedInfo && sortedInfo.columnKey === 'TagCaptureName' ? sortedInfo.order : null
        },
        {
            title: t("fishing_activity.club_points"),
            dataIndex: 'ClubPoints',
            key: 'ClubPoints',
            width: 100,
            editable: true,
            cellType: Constant.CellType.NumberInput,
            handleOnChange: handleOnChange,
            hidden: currentPosition && currentPosition.PositionTypeId != Constant.PositionType.ClubCommittee ? true : false
        },
        {
            title: t("fishing_activity.club_approval"),
            dataIndex: 'ClubApprovalId',
            key: 'ClubApprovalId',
            width: 140,
            editable: true,
            cellType: Constant.CellType.Selection,
            extraData: tagApprovals,
            handleOnChange: handleOnChange,
            selectionKey: "Id",
            selectionDisplay: "Name",
            hidden: currentPosition && currentPosition.PositionTypeId != Constant.PositionType.ClubCommittee ? true : false
        },
        {
            title: t("fishing_activity.club_comments"),
            dataIndex: 'Notes',
            key: 'Notes',
            width: 300,
            editable: true,
            cellType: Constant.CellType.Input,
            handleOnChange: handleOnChange,
            hidden: currentPosition && currentPosition.PositionTypeId != Constant.PositionType.ClubCommittee ? true : false
        },
        {
            title: t("fishing_activity.state_points"),
            dataIndex: 'StatePoints',
            key: 'StatePoints',
            width: 100,
            editable: true,
            cellType: Constant.CellType.NumberInput,
            handleOnChange: handleOnChange,
            hidden: currentPosition && currentPosition.PositionTypeId != Constant.PositionType.StateExcutive && currentPosition.PositionTypeId != Constant.PositionType.NationalExecutive ? true : false
        },
        {
            title: t("fishing_activity.state_approval"),
            dataIndex: 'StateApprovalId',
            key: 'StateApprovalId',
            width: 120,
            editable: true,
            cellType: Constant.CellType.Selection,
            extraData: tagApprovals,
            handleOnChange: handleOnChange,
            selectionKey: "Id",
            selectionDisplay: "Name",
            hidden: currentPosition && currentPosition.PositionTypeId != Constant.PositionType.StateExcutive && currentPosition.PositionTypeId != Constant.PositionType.NationalExecutive ? true : false
        },
        {
            title: t("fishing_activity.state_comments"),
            dataIndex: 'StateNotes',
            key: 'StateNotes',
            width: 300,
            editable: true,
            cellType: Constant.CellType.Input,
            handleOnChange: handleOnChange,
            hidden: currentPosition && currentPosition.PositionTypeId != Constant.PositionType.StateExcutive && currentPosition.PositionTypeId != Constant.PositionType.NationalExecutive ? true : false
        },
        {
            title: t("fishing_activity.non_mem"),
            dataIndex: 'NonMember',
            key: 'NonMember',
            width: 70,
            editable: true,
            cellType: Constant.CellType.Checkbox,
            align: "center",
            handleOnChange: handleOnChange,
            hidden: currentPosition && currentPosition.PositionTypeId != Constant.PositionType.StateExcutive && currentPosition.PositionTypeId != Constant.PositionType.NationalExecutive ? true : false
        },
        {
            title: t("fishing_activity.drop_fish"),
            dataIndex: 'IsDropFish',
            key: 'IsDropFish',
            width: 70,
            editable: true,
            cellType: Constant.CellType.Checkbox,
            align: "center",
            handleOnChange: handleOnChange,
            hidden: currentPosition && currentPosition.PositionTypeId != Constant.PositionType.StateExcutive && currentPosition.PositionTypeId != Constant.PositionType.NationalExecutive ? true : false
        },
        {
            title: t("fishing_activity.too_late"),
            dataIndex: 'IsTooLate',
            key: 'IsTooLate',
            width: 70,
            editable: true,
            cellType: Constant.CellType.Checkbox,
            align: "center",
            handleOnChange: handleOnChange,
            hidden: currentPosition && currentPosition.PositionTypeId != Constant.PositionType.StateExcutive && currentPosition.PositionTypeId != Constant.PositionType.NationalExecutive ? true : false
        },
        {
            title: t("fishing_activity.no_tag_card"),
            dataIndex: 'IsNoTagCard',
            key: 'IsNoTagCard',
            width: 80,
            editable: true,
            cellType: Constant.CellType.Checkbox,
            align: "center",
            handleOnChange: handleOnChange,
            hidden: currentPosition && currentPosition.PositionTypeId != Constant.PositionType.StateExcutive && currentPosition.PositionTypeId != Constant.PositionType.NationalExecutive ? true : false
        },
        {
            title: t("fishing_activity.tackl"),
            dataIndex: 'IsTackle',
            key: 'IsTackle',
            width: 70,
            editable: true,
            cellType: Constant.CellType.Checkbox,
            align: "center",
            handleOnChange: handleOnChange,
            hidden: currentPosition && currentPosition.PositionTypeId != Constant.PositionType.StateExcutive && currentPosition.PositionTypeId != Constant.PositionType.NationalExecutive ? true : false
        },
        {
            title: t("fishing_activity.fish_too_small"),
            dataIndex: 'IsFishTooSmall',
            key: 'IsFishTooSmall',
            width: 80,
            editable: true,
            cellType: Constant.CellType.Checkbox,
            align: "center",
            handleOnChange: handleOnChange,
            hidden: currentPosition && currentPosition.PositionTypeId != Constant.PositionType.StateExcutive && currentPosition.PositionTypeId != Constant.PositionType.NationalExecutive ? true : false
        },
        {
            title: t("fishing_activity.oob"),
            dataIndex: 'IsOob',
            key: 'IsOob',
            width: 70,
            editable: true,
            cellType: Constant.CellType.Checkbox,
            align: "center",
            handleOnChange: handleOnChange,
            hidden: currentPosition && currentPosition.PositionTypeId != Constant.PositionType.StateExcutive && currentPosition.PositionTypeId != Constant.PositionType.NationalExecutive ? true : false
        },
        {
            title: t("fishing_activity.other"),
            dataIndex: 'IsOther',
            key: 'IsOther',
            width: 70,
            editable: true,
            cellType: Constant.CellType.Checkbox,
            align: "center",
            handleOnChange: handleOnChange,
            hidden: currentPosition && currentPosition.PositionTypeId != Constant.PositionType.StateExcutive && currentPosition.PositionTypeId != Constant.PositionType.NationalExecutive ? true : false
        }
    ]

    const columnsMapping = columns.filter(c => !c.hidden).map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition,
                selectionKey: col.selectionKey,
                selectionDisplay: col.selectionDisplay,
            })
        };
    })

    const expandedColumns = [
        {
            title: t("fishing_activity.date"),
            dataIndex: 'ActivityDate',
            key: 'ActivityDate',
            width: 100,
            render: (value, record) => {
                if (record.ActivityDate) {
                    let date = new Date(record.ActivityDate)
                    var day = date.getDate();
                    var month = date.getMonth() + 1; //Month from 0 to 11
                    var year = date.getFullYear();

                    return `${day}/${month}/${year}`
                }

                return null
            }
        },
        {
            title: t("fishing_activity.date_entered"),
            dataIndex: 'DateEntered',
            key: 'DateEntered',
            width: 150,
            render: (value, record) => {
                if (record.DateEntered) {
                    let date = new Date(record.DateEntered)
                    var day = date.getDate();
                    var month = date.getMonth() + 1; //Month from 0 to 11
                    var year = date.getFullYear();

                    return `${day}/${month}/${year}`
                }

                return null
            }
        },
        {
            title: t("fishing_activity.tag_number"),
            dataIndex: 'TagNumber',
            key: 'TagNumber',
            width: 150
        },
        {
            title: t("activity_approvals.angler_club"),
            dataIndex: 'AnglerClubAcronym',
            key: 'AnglerClubAcronym',
            width: 150
        },
        {
            title: t("fishing_activity.fishing_category"),
            dataIndex: 'FishingCategoryName',
            key: 'FishingCategoryName',
            width: 160
        },
        // {
        //     title: t("fishing_activity.club"),
        //     dataIndex: 'ClubAcronym',
        //     key: 'ClubAcronym',
        //     width: 250,
        //     hidden: currentPosition && currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee ? true : false

        // },
        {
            title: t("activity_approvals.state_ps_zone", { state_association_acronym: entityDetail.EntityAcronym }),
            dataIndex: 'StatePointScoreZoneDescription',
            key: 'StatePointScoreZoneDescription',
            width: 170
        },
        {
            title: "",
            width: 150
        },
        {
            title: "",
            width: 150
        },
        {
            title: "",
            width: 150
        }
       
        
    ]

    const expandedColumnsMapping = expandedColumns.filter(c => !c.hidden).map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition,
                selectionKey: col.selectionKey,
                selectionDisplay: col.selectionDisplay
            }),
        };
    })

    const components = {
        body: {
            cell: EditableCell,
        }
    };

    const expandedRowRender = (record, index, indent, expanded) => {
        return <Table components={components}
            //rowClassName={() => 'editable-row'}
            columns={expandedColumns}
            dataSource={[record]}
            pagination={false}
            rowKey='Id' />;
    }

    const getActivityLinesForActivityApproval = (pageNumber, pageSize, sort, filter, isFromModal = false) => {
        showLoading()
        if (!filter) {
            filter = { ...dayLogFilter }
        }

        FishingActivityService.getActivityLinesForActivityApproval(pageNumber, pageSize, sort, filter)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    setIsDirty(false)
                    setConfirmLeave(false)
                    setSelectedActivityLineData([])
                    setActivityLineData(result.data);
                }
                setTimeout(() => {
                    document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
                }, 500)
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    const onSelectionTableChange = (pagination, filters, sorter, extra) => {
        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = defaultGridOption.sort;
        }
        onCancelActivityAppovals(t("activity_approvals.updated_activity_confirm"), pagination.current, pagination.pageSize, sort, dayLogFilter, sorter)
    }

    const closeFilterLayout = () => {
        setEnableFilterModal(false)
    }

    const showFilterModal = () => {
        setEnableFilterModal(true)

    }

    const onSaveActivityAppovals = () => {
        setConfirmLeave(false)
        updateApprovalStatus(gridConfigOptions.pageNumber,
            gridConfigOptions.pageSize,
            gridConfigOptions.sort,
            dayLogFilter)
    }
    const onCancelActivityAppovals = (message, pageNumber, pageSize, sort, filter, sortedInfo) => {
        if (isDirty) {
            confirm({
                title: t("common.confirm"),
                icon: <ExclamationCircleOutlined />,
                content: message,
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    setConfirmLeave(false)
                    updateApprovalStatus(pageNumber, pageSize, sort, filter)
                    setGridConfigOptionsValue({
                        pageNumber: pageNumber,
                        pageSize: pageSize,
                        sort: sort
                    })
                    setSortedInfo(sortedInfo)
                    setDayLogFilter(filter)
                },
                onCancel() {
                    setActivityLineData([])
                    setConfirmLeave(false)
                    getActivityLinesForActivityApproval(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, dayLogFilter)
                    formFilter.setFieldsValue({
                        ClubIds: dayLogFilter.ClubIds,
                        DayLogId: dayLogFilter.DayLogId,
                        ActivitySheetNumber: dayLogFilter.ActivitySheetNumber
                    })
                }

            });
        } else {
            setActivityLineData([])
            setConfirmLeave(false)
            setSortedInfo(sortedInfo)
            setDayLogFilter(filter)
            setGridConfigOptionsValue({
                pageNumber: pageNumber,
                pageSize: pageSize,
                sort: sort
            })
            getActivityLinesForActivityApproval(pageNumber, pageSize, sort, filter)
        }
    }

    const applyFilter = () => {
        setEnableFilterModal(false)
        let filter = formFilter.getFieldsValue();
        onCancelActivityAppovals(t("activity_approvals.updated_activity_confirm"), gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, filter, sortedInfo)
    }

    const resetFilter = () => {
        formFilter.resetFields();
        setSelectionDayLogFilter({});
        setSelectedActivityLineData([])
    }

    const updateApprovalStatus = (pageNumber, pageSize, sort, filter) => {
        if (selectedActivityLineData.length > 0) {
            showLoading()
            FishingActivityService.updateApprovalStatus(selectedActivityLineData)
                .finally(() => dismissLoading())
                .then(result => {
                    CommonService.presentToast("success", t("activity_approvals.day_log_update_successful"))
                    setSelectedActivityLineData([]);
                    getActivityLinesForActivityApproval(pageNumber, pageSize, sort, filter)
                    setConfirmLeave(false)
                })
                .catch(error => {
                    CommonService.handleErrorResponse(error);
                })
        }
    }

    return (
        <>
            {currentPosition && (currentPosition.PositionTypeId == Constant.PositionType.StateExcutive ||
                currentPosition.PositionTypeId == Constant.PositionType.NationalExecutive ||
                currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee) &&
                <div className='activity-approvals'>
                    <ConfirmLeavePage
                        shouldPrompt={confirmLeave}
                    >
                        {({ leavePage, stayOnPage, isDialogActive }) => (
                            <Modal
                                title={t("common.warning")}
                                open={isDialogActive}
                                onOk={leavePage}
                                onCancel={stayOnPage}
                            >
                                <p>
                                    {t("entity_detail.leave_warning")}
                                </p>
                            </Modal>
                        )}
                    </ConfirmLeavePage>
                    <div id={Constant.FixItemsContainerId}>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                            <Col xs={{ span: 24 }}>
                                <Space direction="vertical" size={Constant.SpaceConstant.TextFieldGutter}>
                                    <Title className='color-dark-blue' level={5}>{t("activity_approvals.title")}</Title>
                                </Space>
                            </Col>
                        </Row>
                        <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                            <Col flex={100} className="text-right">
                                <div>
                                    <Button onClick={showFilterModal} className='btn-outline-blue btn-icon' icon={<FilterAltIcon />}>{t("common.filter")}</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='m-t-10'>
                        <Col xs={{ span: 24 }}>
                            {
                                currentPosition && currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee &&
                                <Table
                                    components={components}
                                    // expandable={{
                                    //     expandedRowRender: (record) => expandedRowRender(record),
                                    //     rowExpandable: () => true
                                    // }}
                                    {...GetTableConfigs(columnsMapping, activityLineData.ObjectList, activityLineData.TotalItems, "Id", onSelectionTableChange, gridConfigOptions.pageNumber, "")}
                                />
                            }
                             {
                                currentPosition && currentPosition.PositionTypeId != Constant.PositionType.ClubCommittee &&
                                <Table
                                    components={components}
                                    expandable={{
                                        expandedRowRender: (record) => expandedRowRender(record),
                                        rowExpandable: () => true
                                    }}
                                    {...GetTableConfigs(columnsMapping, activityLineData.ObjectList, activityLineData.TotalItems, "Id", onSelectionTableChange, gridConfigOptions.pageNumber, "")}
                                />
                            }

                        </Col>
                    </Row>
                    <Row align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter]}>
                        <Col xs={{ span: 24 }} className="text-right m-t-10">
                            <Button size="large" className="m-r-5" onClick={() => onCancelActivityAppovals(t("common.submit_confirm"), gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, dayLogFilter, sortedInfo)}>{t("common.cancel")}</Button>
                            <Button size="large" type="primary" onClick={onSaveActivityAppovals} disabled={!canSubmit()}>{t("common.save")}</Button>
                        </Col>
                    </Row>
                </div>
            }

            {
                enableFilterModal &&
                <Drawer
                    title={t("common.filter")}
                    width={378}
                    onClose={closeFilterLayout}
                    open={true}
                    closeIcon={false}
                    styles={{
                        body: {
                            paddingBottom: 80,
                        }
                    }}
                    extra={
                        <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                            <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                            <Button onClick={closeFilterLayout} icon={<CloseIcon />} type="link" />
                        </Space>
                    }
                    footer={
                        <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                            <Button onClick={closeFilterLayout}>{t("common.cancel")}</Button>
                            <Button onClick={applyFilter} type="primary">
                                {t("common.apply")}
                            </Button>
                        </Space>
                    }>
                    <Form
                        layout="vertical"
                        {...layout}
                        form={formFilter}
                        name="formFilter"
                        key='formFilter'
                        initialValues={selectionDayLogFilter}
                    >

                        {
                            currentPosition && (currentPosition.PositionTypeId == Constant.PositionType.StateExcutive ||
                                currentPosition.PositionTypeId == Constant.PositionType.NationalExecutive) &&
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="EntityId"
                                        label={t("fishing_activity.club")}
                                    >
                                        <EnhancedSelect allowClear={true}
                                            showSearch optionFilterProp="children"
                                            filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                        >
                                            {
                                                clubData && clubData.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                            }
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                        }

                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="DayLogId"
                                    label={t("fishing_activity.day_log_id")}
                                >
                                    <Input allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="ActivitySheetNumber"
                                    label={t("activity_approvals.activity_sheet")}
                                >
                                    <Input allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Drawer>
            }
            {currentPosition && currentPosition.PositionTypeId != Constant.PositionType.StateExcutive &&
                currentPosition.PositionTypeId != Constant.PositionType.NationalExecutive &&
                currentPosition.PositionTypeId != Constant.PositionType.ClubCommittee &&
                <div className='m-t-10'>
                    <Table
                        style={{ visibility: 'hidden', height: '0px' }}
                        {...GetTableConfigs(columnsMapping, activityLineData.ObjectList, activityLineData.length, "Id")}
                    />
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_access")} />
                </div >
            }
        </>
    )
}

export default ActivityEntryTagCardApprovals
import React, { useEffect, useState } from 'react';
import './tagCardFormView.scss';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
    Constant,
    CommonService,
    FishingActivityService
} from '../../services/services'
import { Button, Row, Col, Form, Modal, Skeleton } from 'antd';
import LoadingContext from '../loading/loadingContext'
import TagCardFormViewItem from '../tagCardFormView/tagCardFormViewItem';

const { confirm } = Modal;
const _ = require("lodash");

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const TagCardFormView = (props) => {
    const { t } = useTranslation();
    const [cardViewForm] = Form.useForm();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext);
    const [loading, setLoading] = useState(false);

    var [activityLines, setActivityLines] = useState([]);
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const onSaveActivityLines = () => {
        if (activityLines === null) {
            return;
        }
        
        // Validate
        var allErrorMessages = [];
        setActivityLines(current =>
            current.map(obj => {
                var errorMessages = [];
                if (!obj.TagCaptureId) {
                    errorMessages.push({
                        key: 'TagCaptureId',
                        value: t('fishing_activity.tag_capt_is_required')
                    })
                }

                if (obj.TagCaptureId === Constant.TagCaptureCode.T_R && !obj.TagNumber) {
                    errorMessages.push({
                        key: 'TagNumber',
                        value: t('fishing_activity.tag_number_is_required')
                    })
                }

                if (obj.TagNumber && obj.TagNumber.length > 0 && !CommonService.verifyTagNumber(obj.TagNumber)) {
                    errorMessages.push({
                        key: 'TagNumber',
                        value: t('fishing_activity.tag_number_is_invalid')
                    })
                }

                if (obj.OldTagNumber && obj.OldTagNumber.length > 0 && !CommonService.verifyTagNumber(obj.OldTagNumber)) {
                    errorMessages.push({
                        key: 'OldTagNumber',
                        value: t('fishing_activity.old_tag_number_is_invalid')
                    })
                }

                if (!obj.FishingMethodId) {
                    errorMessages.push({
                        key: 'FishingMethodId',
                        value: t('fishing_activity.method_is_required')
                    })
                }

                if (!obj.CaughtTime) {
                    errorMessages.push({
                        key: 'CaughtTime',
                        value: t('fishing_activity.time_is_required')
                    })
                }

                if (!obj.FishSpeciesId) {
                    errorMessages.push({
                        key: 'FishSpeciesId',
                        value: t('fishing_activity.species_is_required')
                    })
                }

                if (!obj.LineClassId) {
                    errorMessages.push({
                        key: 'LineClassId',
                        value: t('fishing_activity.line_class_is_required')
                    })
                }

                if (!obj.AnglerId) {
                    errorMessages.push({
                        key: 'AnglerId',
                        value: t('fishing_activity.angler_is_required')
                    })
                }
                
                if (!obj.FishingLocationId) {
                    errorMessages.push({
                        key: 'FishingLocationId',
                        value: t('fishing_activity.location_is_required')
                    })
                }

                if (!obj.GpsLatitude) {
                    errorMessages.push({
                        key: 'GpsLatitude',
                        value: t('fishing_activity.gps_latitude_is_required')
                    })
                }

                if (!obj.GpsLongitude) {
                    errorMessages.push({
                        key: 'GpsLongitude',
                        value: t('fishing_activity.gps_longitude_is_required')
                    })
                }

                if (!obj.StatePointScoreZoneId) {
                    errorMessages.push({
                        key: 'StatePointScoreZoneId',
                        value: t('fishing_activity.state_zone_is_required')
                    })
                }

                if (!obj.IsFlyfishing) {
                    errorMessages.push({
                        key: 'IsFlyfishing',
                        value: t('fishing_activity.flyfishing_is_required')
                    })
                }

                if (!obj.IsSaltwater) {
                    errorMessages.push({
                        key: 'IsSaltwater',
                        value: t('fishing_activity.saltwater_is_required')
                    })
                }
                allErrorMessages.push(...errorMessages);
                return {
                    ...obj,
                    required: errorMessages.length > 0 ? true : false,
                    ErrorMessages: errorMessages,
                };
            }),
        );

        if (allErrorMessages.length > 0) {
            CommonService.presentToast('error', allErrorMessages.map(x => x.value).join(', '));
            return;
        }

        activityLines.forEach(activityLine => {
            if (activityLine.IsFlyfishing === 1) {
                activityLine.IsFlyfishing = true;
            } else if (activityLine.IsFlyfishing === 2) {
                activityLine.IsFlyfishing = false;
            }

            if (activityLine.IsSaltwater === 1) {
                activityLine.IsSaltwater = true;
            } else if (activityLine.IsSaltwater === 2) {
                activityLine.IsSaltwater = false;
            }

            if (activityLine.IsInsideClubWaters === 1) {
                activityLine.IsInsideClubWaters = true;
            } else if (activityLine.IsInsideClubWaters === 2) {
                activityLine.IsInsideClubWaters = false;
            }

            if (activityLine.ClubEventId == -1) {
                activityLine.ClubEventId = null;
            }

            if (activityLine.StateEventId == -1) {
                activityLine.StateEventId = null;
            }

            if (activityLine.DateEntered) {
                var newDateEntered = activityLine.DateEntered;
                if (typeof newDateEntered === 'string' || newDateEntered instanceof String) {
                    var length = newDateEntered.split('/').length;
                    if (length >= 3) {
                        activityLine.DateEntered = dayjs(newDateEntered, Constant.DateFormatYYYMMDD);
                        activityLine.DateEnteredExtra = activityLine.DateEntered;
                    }
                } else {
                    activityLine.DateEntered = newDateEntered.format(Constant.DateFormatYYYMMDD);
                    activityLine.DateEnteredExtra = activityLine.DateEntered;
                }
            }
        });

        // Add/Update
        showLoading();
        var newActivityHeader = props.activityHeader;
        newActivityHeader.ActivityLines = activityLines;
        if (newActivityHeader.ClubEventId == -1) {
            newActivityHeader.ClubEventId = null;
        }

        if (newActivityHeader.StateEventId == -1) {
            newActivityHeader.StateEventId = null;
        }

        if (newActivityHeader.ActivityDate) {
            if (typeof newActivityHeader.ActivityDate === 'string' || newActivityHeader.ActivityDate instanceof String) {
                var length = newActivityHeader.ActivityDate.split('/').length;
                if (length >= 3) {
                    newActivityHeader.ActivityDate = dayjs(newActivityHeader.ActivityDate, Constant.DateFormatYYYMMDD);
                }
            } else {
                newActivityHeader.ActivityDate = newActivityHeader.ActivityDate.format(Constant.DateFormatYYYMMDD);
            }
        }

        FishingActivityService.addUpdateActivityHeader(newActivityHeader)
            .finally(() => {
                dismissLoading();
            })
            .then(result => {
                if (result.data) {
                    CommonService.presentToast('success', isEdit ? t('fishing_activity.activity_lines_update_successful') : t('fishing_activity.activity_lines_create_successful'));
                    
                    if(isEdit){
                        props.handleCancel()
                    } else {
                        props.handleActivityLines(props.activityHeaderId);
                    }
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            });
    }

    const onCancel = () => {
        props.handleCancel();
    }

    const deleteActivityLine = (record) => {
        if (!record.IsAddNew && record.Id > 0) {
            confirm({
                title: t("fishing_activity.delete_activity_lines_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    showLoading();

                    FishingActivityService.deleteActivityLines([record.Id])
                    .finally(() => {
                        dismissLoading();
                    })
                    .then(result => {
                        const newActivityLines = activityLines.filter((m) => m.Id !== record.Id);
                        setActivityLines(newActivityLines);
                    })
                    .catch(error => {
                        CommonService.handleErrorResponse(error);
                    });
                }
            });
        } else {
            const newActivityLines = activityLines.filter((m) => m.Id !== record.Id);
            setActivityLines(newActivityLines);
        }
    }

    const canSubmit = (activityLines) => {
        if (activityLines && activityLines.length > 0) {
            let inValid = activityLines.filter(x => !x.TagCaptureId || !x.FishingMethodId || !x.CaughtTime || !x.FishSpeciesId || !x.StatePointScoreZoneId || !x.IsFlyfishing || !x.IsSaltwater);
            if (inValid && inValid.length > 0) {
                return false
            }
            return true;
        }
        return false;
    }

    useEffect(() => {
        setLoading(true);
        setActivityLines(props.activityLines);
        // setEnableSubmit(canSubmit(props.activityLines));
        var existActivityLine = _.find(props.activityLines, function(x) { return x.IsAddNew; });
        setIsEdit(existActivityLine != null ? false : true);
        setTimeout(() => {
            setLoading(false);
        }, 100);

        return () => {
        }
    }, [props.activityLines])

    const SubmitButton = ({ form }) => {
        const [submittable, setSubmittable] = React.useState(false);
      
        // Watch all values
        const values = Form.useWatch([], form);
        React.useEffect(() => {
          form
            .validateFields({
              validateOnly: true,
            })
            .then(
              () => {
                setSubmittable(true);
              },
              () => {
                setSubmittable(false);
              },
            );
        }, [values]);
        return (
            <Button size="large" type="primary" htmlType="submit" disabled={!submittable}>{t("fishing_activity.save_day_log")}</Button>
        );
    }

    return (
        <Skeleton loading={loading} active paragraph={{ rows: 1, width: "100%" }}>
            <Form
                {...layout}
                name='cardViewForm'
                key='cardViewForm'
                onFinish={onSaveActivityLines}
                form={cardViewForm}>
                <Row className="fix-item-container">
                    <Col xs={{ span: 24 }} className="text-left extra-btn-m-t">
                        <div className="m-t-10">
                            <span>{t("fishing_activity.note_save_message")}</span>
                        </div>
                    </Col>
                </Row>

                {activityLines && activityLines.length > 0 &&
                    activityLines.map((line, index) => (
                        <Row key={index} className="m-t-10" gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                            <Col xs={{ span: 24 }}>
                                <TagCardFormViewItem
                                    key={index}
                                    deleteActivityLine={deleteActivityLine}
                                    tagCaptures={props.tagCaptures} 
                                    fishingMethods={props.fishingMethods} 
                                    anglers={props.anglers} 
                                    anglerOptions={props.anglerOptions}
                                    fishingLocations={props.fishingLocations} 
                                    fishSpecies={props.fishSpecies} 
                                    lineClasses={props.lineClasses} 
                                    fishingCategories={props.fishingCategories} 
                                    tagApprovals={props.tagApprovals} 
                                    clubZones={props.clubZones} 
                                    statePointScoreZones={props.statePointScoreZones} 
                                    clubEvents={props.clubEvents} 
                                    stateEvents={props.stateEvents} 
                                    yesNos={props.yesNos}
                                    activityLine={line}
                                    activityHeaderId={props.activityHeaderId}
                                    activityHeader={props.activityHeader}
                                    disabledStateView={props.disabledStateView} 
                                    />
                            </Col>
                        </Row>
                    ))
                }

                <Row align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter]}>
                    <Col xs={{ span: 24 }} className="text-right m-t-25">
                        <Button size="large" className="m-r-5" onClick={onCancel}>{t("common.cancel")}</Button>
                        <SubmitButton form={cardViewForm} />
                    </Col>
                </Row>
            </Form>
        </Skeleton>
    )
}

export default TagCardFormView
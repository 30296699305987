import React, { useState, useEffect } from 'react';
import { Constant, CommonService, FishingActivityService, StorageService } from '../../services/services';
import { useTranslation } from 'react-i18next';
import { GetTableConfigs, StatusCell, ActionButtonsCell, EditableCell } from '../../components/customTable/customTable'
import LoadingContext from '../../components/loading/loadingContext'
import { FilterAltIcon, CloseIcon, EditIcon, TrashIcon, EyeIcon } from '../../components/icons/icons';
import { Row, Col, Table, Button, DatePicker, Modal, Form, Drawer, Space, Input, Select, InputNumber, Dropdown, Typography } from 'antd';
import { DownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useGlobalState } from '../../utilities/globalState'
import { useHistory } from "react-router-dom";
import EnhancedSelect from '../../components/enhancedSelect/enhancedSelect';

import PubSub from 'pubsub-js'

const { confirm } = Modal;
const { Option } = Select;
const { Title } = Typography;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const defaultGridOption = {
    pageNumber: 1,
    pageSize: CommonService.getPageSize(),
    sort: "ActivityDate-descend"
}

const DayLogList = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const emptyTableData = {
        ObjectList: [],
        TotalItems: 0
    }
    const [dayLogData, setDayLogData] = useState(emptyTableData)
    var [gridConfigOptions, setGridConfigOptionsValue] = useState(defaultGridOption)
    const [currentPosition] = useGlobalState('currentPosition');
    const [visibleFilter, setVisibleFilter] = useState(false);
    const [formFilter] = Form.useForm();
    const [dayLogFilter, setDayLogFilter] = useState({ StatusIds: Constant.StatusCode.Active });
    const [financialYears, setFinancialYears] = useState([]);
    const [clubSpecialEvents, setClubSpecialEvents] = useState([]);
    const [stateSpecialEvents, setStateSpecialEvents] = useState([]);
    const [currentUser, setCurrentUser] = useState();
    const [filterData, setFilterData] = useState({})
    const history = useHistory();

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 24))
        }, 100)

        let authData = StorageService.getAuthData()
        if (authData && authData.name) {
            setCurrentUser(authData.name)
        }

        FishingActivityService.getClubsByState()
            .then(result => {
                if(result.data){
                    setFilterData({
                        Entities: result.data
                    })
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })

        FishingActivityService.getActivityHeaderExportFilterData()
            .then(result => {
                if(result.data){
                    setFilterData(result.data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })

        // if (history.location && history.location.state) {
        //     if (history.location.state.Filter) {
        //         dayLogFilter = history.location.state.Filter
        //         setDayLogFilter(history.location.state.Filter)
        //         formFilter.setFieldsValue(dayLogFilter)
        //     }

        //     if (history.location.state.GridConfigOptions) {
        //         gridConfigOptions = history.location.state.GridConfigOptions
        //         setGridConfigOptionsValue(history.location.state.GridConfigOptions)
        //     }
        // }

        setTimeout(() => {
            PubSub.publish(Constant.PubSupType.PageChanged)
        }, 100);

        if (history.location && history.location.state
            && history.location.state.GridConfigOptions
            && history.location.state.Filter) {
            setGridConfigOptionsValue(history.location.state.GridConfigOptions)
            setDayLogFilter(history.location.state.Filter)
            formFilter.setFieldsValue(history.location.state.Filter)

            showLoading()

            Promise.all([
                FishingActivityService.getDayLogList(history.location.state.GridConfigOptions.pageNumber, history.location.state.GridConfigOptions.pageSize, history.location.state.GridConfigOptions.sort, history.location.state.Filter)
            ])
                .finally(() => dismissLoading())
                .then(response => {
                    if (response[0] && response[0].data) {
                        setDayLogData(response[0].data)
                    }
                })
                .catch(error => {
                    CommonService.handleErrorResponse(error);
                })
        } else {
            showLoading()

            Promise.all([
                FishingActivityService.getDayLogList(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, dayLogFilter),
            ])
                .finally(() => dismissLoading())
                .then(response => {
                    if (response[0] && response[0].data) {
                        setDayLogData(response[0].data)
                    }
                })
                .catch(error => {
                    CommonService.handleErrorResponse(error);
                })
        }

        return () => {
            FishingActivityService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    const getDayLogList = (pageNumber, pageSize, filter, sort) => {
        showLoading()
        if (!filter) {
            filter = { ...dayLogFilter }
        }

        FishingActivityService.getDayLogList(pageNumber, pageSize, sort, filter)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    setDayLogData(result.data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    const statusCodes = [
        {
            Value: Constant.StatusCode.Active,
            Description: t("common.active")
        },
        {
            Value: Constant.StatusCode.Inactive,
            Description: t("common.inactive")
        }
    ]

    const editDaylog = (record) => {
        if (record) {
            history.push(Constant.Pages.CreateDayLog, {
                DayLogId: record.Id,
                GridConfigOptions: gridConfigOptions,
                Filter: dayLogFilter,
                PreviousUrl: Constant.Pages.DayLogList,
                ViewOnly: false
            });
        }
    }

    const viewDaylog = (record) => {
        if (record) {
            history.push(Constant.Pages.CreateDayLog, {
                DayLogId: record.Id,
                GridConfigOptions: gridConfigOptions,
                Filter: dayLogFilter,
                PreviousUrl: Constant.Pages.DayLogList,
                ViewOnly: true
            });
        }
    }

    const deleteDaylog = (record) => {
        if (record) {
            doDeleteDayLog(record.Id)
        }
    }

    const handleExport = (e) => {
        exportDayLogs(e.key)
    };

    const exportItems = [
        {
            label: t("common.excel"),
            key: Constant.FileType.Excel
        },
        {
            label: t("common.csv"),
            key: Constant.FileType.Csv
        }
    ];
    const menuExport = {
        items: exportItems,
        onClick: handleExport
    };

    const visibleViewBtn = (item) => {
        if (!currentPosition || !item || currentPosition.PositionTypeId != Constant.PositionType.ClubMember) {
            return false
        }
        if (item.CreationBy == currentUser && item.ApprovedAllLines) {
            return true
        }
        if (!item.CanView)
            return false
        return false
    }

    const getActionButtonsInfo = (item) => {
        return [
            {
                Description: t("common.edit"),
                Icon: <EditIcon />,
                ColorClass: Constant.ColorClass.LightBlue,
                Hide: currentPosition && currentPosition.PositionTypeId == Constant.PositionType.ClubMember && item && item.ApprovedAllLines,
                handleAction: editDaylog
            },
            {
                Description: t("common.delete"),
                Icon: <TrashIcon />,
                ColorClass: Constant.ColorClass.LightRed,
                Hide: currentPosition && ((currentPosition.PositionTypeId == Constant.PositionType.ClubMember && item && item.HasLineApproved) || currentPosition.PositionTypeId == Constant.PositionType.StateExcutive),
                handleAction: deleteDaylog
            },
            {
                Description: t("common.view"),
                Icon: <EyeIcon />,
                ColorClass: Constant.ColorClass.LightBlue,
                Hide: currentPosition && (currentPosition.PositionTypeId != Constant.PositionType.ClubMember || (currentPosition.PositionTypeId == Constant.PositionType.ClubMember && item && !item.ApprovedAllLines)),
                handleAction: viewDaylog
            }
        ]
    }

    const columns = [
        {
            title: t('common.action'),
            key: 'Id',
            render: (value, record) => {
                return <ActionButtonsCell btnList={getActionButtonsInfo(record)} data={record} />
            },
            align: "center",
            width: 90
        },
        {
            title: t('fishing_activity.day_log_id'),
            dataIndex: 'DayLogId',
            key: 'DayLogId',
            sorter: true,
            width: 130
        },
        {
            title: t('fishing_activity.asn'),
            dataIndex: 'ActivitySheetNumber',
            key: 'ActivitySheetNumber',
            sorter: true,
            width: 100
        },
        {
            title: t('fishing_activity.created_by'),
            dataIndex: 'CreationBy',
            key: 'CreationBy',
            sorter: true,
            width: 120
        },
        {
            title: t('fishing_activity.boat_name'),
            dataIndex: 'BoatName',
            key: 'BoatName',
            sorter: true,
            width: 150
        },
        {
            title: t('fishing_activity.captain'),
            dataIndex: 'CaptainName',
            key: 'CaptainName',
            sorter: true,
            width: 150
        },
        {
            title: t('fishing_activity.date'),
            dataIndex: 'ActivityDate',
            key: 'ActivityDate',
            sorter: true,
            render: (value, record) => {
                if (record.ActivityDate) {
                    let date = new Date(record.ActivityDate)
                    var day = date.getDate();
                    var month = date.getMonth() + 1; //Month from 0 to 11
                    var year = date.getFullYear();

                    return `${day}/${month}/${year}`
                }

                return null
            },
            width: 150
        },
        {
            title: t('fishing_activity.financial_year'),
            dataIndex: 'FinancialYear',
            key: 'FinancialYear',
            sorter: false,
            width: 150
        },
        {
            title: t('fishing_activity.pob'),
            dataIndex: 'PersonOnBoard',
            key: 'PersonOnBoard',
            sorter: true,
            width: 90
        },
        {
            title: t('fishing_activity.how'),
            dataIndex: 'HoursOnWater',
            key: 'HoursOnWater',
            sorter: true,
            width: 90
        },
        {
            title: t('fishing_activity.entries'),
            dataIndex: 'NoOfActivityEntries',
            key: 'NoOfActivityEntries',
            sorter: true,
            width: 90
        },
        {
            title: t('fishing_activity.club_event'),
            dataIndex: 'ClubEventName',
            key: 'ClubEventName',
            sorter: true,
            width: 180
        },
        {
            title: t('fishing_activity.state_event'),
            dataIndex: 'StateEventName',
            key: 'StateEventName',
            sorter: true,
            width: 180
        },
        {
            title: t('common.status'),
            dataIndex: 'StatusId',
            key: 'StatusId',
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.StatusId)}
                    description={CommonService.getStatusDescription(record.StatusId)} />
            ),
            sorter: true,
            width: 100
        }
    ]

    const columnsMapping = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition
            }),
        };
    })

    const stateColumns = [
        {
            title: t('common.action'),
            key: 'Id',
            render: (value, record) => {
                return <ActionButtonsCell btnList={getActionButtonsInfo(record)} data={record} />
            },
            align: "center",
            width: 90
        },
        {
            title: t('fishing_activity.club'),
            dataIndex: 'ClubName',
            key: 'ClubName',
            sorter: true,
            width: 150
        },
        {
            title: t('fishing_activity.day_log_id'),
            dataIndex: 'DayLogId',
            key: 'DayLogId',
            sorter: true,
            width: 130
        },
        {
            title: t('fishing_activity.asn'),
            dataIndex: 'ActivitySheetNumber',
            key: 'ActivitySheetNumber',
            sorter: true,
            width: 100
        },
        {
            title: t('fishing_activity.created_by'),
            dataIndex: 'CreationBy',
            key: 'CreationBy',
            sorter: true,
            width: 120
        },
        {
            title: t('fishing_activity.boat_name'),
            dataIndex: 'BoatName',
            key: 'BoatName',
            sorter: true,
            width: 150
        },
        {
            title: t('fishing_activity.captain'),
            dataIndex: 'CaptainName',
            key: 'CaptainName',
            sorter: true,
            width: 150
        },
        {
            title: t('fishing_activity.date'),
            dataIndex: 'ActivityDate',
            key: 'ActivityDate',
            sorter: true,
            render: (value, record) => {
                if (record.ActivityDate) {
                    let date = new Date(record.ActivityDate)
                    var day = date.getDate();
                    var month = date.getMonth() + 1; //Month from 0 to 11
                    var year = date.getFullYear();

                    return `${day}/${month}/${year}`
                }

                return null
            },
            width: 150
        },
        {
            title: t('fishing_activity.financial_year'),
            dataIndex: 'FinancialYear',
            key: 'FinancialYear',
            sorter: false,
            width: 150
        },
        {
            title: t('fishing_activity.pob'),
            dataIndex: 'PersonOnBoard',
            key: 'PersonOnBoard',
            sorter: true,
            width: 90
        },
        {
            title: t('fishing_activity.how'),
            dataIndex: 'HoursOnWater',
            key: 'HoursOnWater',
            sorter: true,
            width: 90
        },
        {
            title: t('fishing_activity.entries'),
            dataIndex: 'NoOfActivityEntries',
            key: 'NoOfActivityEntries',
            sorter: true,
            width: 90
        },
        {
            title: t('fishing_activity.state_event'),
            dataIndex: 'StateEventName',
            key: 'StateEventName',
            sorter: true,
            width: 180
        },
        {
            title: t('common.status'),
            dataIndex: 'StatusId',
            key: 'StatusId',
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.StatusId)}
                    description={CommonService.getStatusDescription(record.StatusId)} />
            ),
            sorter: true,
            width: 100
        }
    ]

    const stateColumnsMapping = stateColumns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition
            }),
        };
    })

    const components = {
        body: {
            cell: EditableCell,
        }
    };

    const onTableChange = (pagination, filters, sorter, extra) => {
        var sort = CommonService.getSortString(sorter);
        if (!sort || sort.length === 0) {
            sort = defaultGridOption.sort;
        }

        getDayLogList(pagination.current, pagination.pageSize, dayLogFilter, sort)
        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sort
        })
    }

    const showFilterLayout = () => {
        setVisibleFilter(true);
    }

    const closeFilterLayout = () => {
        formFilter.setFieldsValue(dayLogFilter);
        setVisibleFilter(false);
    }

    const resetFilter = () => {
        formFilter.resetFields();
        setDayLogFilter({ StatusIds: Constant.StatusCode.Active });
    }

    const applyFilter = () => {
        setVisibleFilter(false);
        let filter = formFilter.getFieldsValue();
        setDayLogFilter(filter)
        setGridConfigOptionsValue({
            ...gridConfigOptions,
            pageNumber: 1
        })
        getDayLogList(1, gridConfigOptions.pageSize, filter, gridConfigOptions.sort)
    }

    const exportDayLogs = (fileType) => {
        showLoading()

        FishingActivityService.getExportActivityHeaders(gridConfigOptions.sort, dayLogFilter, fileType)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    if (fileType == Constant.FileType.Excel) {
                        const type = result.headers['content-type']
                        const blob = new Blob([result.data], { type: type })
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = `Day_Log_List.xlsx`
                        link.click()
                    } else if (fileType == Constant.FileType.Csv) {
                        const type = result.headers['content-type']
                        const blob = new Blob([result.data], { type: type })
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = `Day_Log_List.csv`
                        link.click()
                    }
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error);
            })
    }

    const doDeleteDayLog = (id) => {
        confirm({
            title: t("common.confirm"),
            icon: <ExclamationCircleOutlined />,
            content: t("fishing_activity.delete_day_log_confirm"),
            okText: t("common.yes"),
            cancelText: t("common.no"),
            onOk() {
                showLoading()
                FishingActivityService.deleteDayLog(id)
                    .finally(() => dismissLoading())
                    .then(result => {
                        getDayLogList(1, gridConfigOptions.pageSize, dayLogFilter, gridConfigOptions.sort)
                        setGridConfigOptionsValue({
                            ...gridConfigOptions,
                            pageNumber: 1
                        })
                    })
                    .catch(error => CommonService.handleErrorResponse(error))
            },
            onCancel() {
            }
        });
    }

    const getColumnMapping = () => {
        if (currentPosition && (currentPosition.PositionTypeId == Constant.PositionType.ClubMember ||
            currentPosition.PositionTypeId == Constant.PositionType.ClubCommittee)) {
            return columnsMapping
        } else if (currentPosition && (currentPosition.PositionTypeId == Constant.PositionType.StateExcutive ||
            currentPosition.PositionTypeId == Constant.PositionType.NationalExecutive)) {
            return stateColumnsMapping
        }

        return null
    }

    return (
        <>
            <div>
                <div id={Constant.FixItemsContainerId}>
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                        <Title className='color-dark-blue' level={5}>{t("fishing_activity.list_day_log_reports")}</Title>
                    </Row>
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                        <Col flex={100} className="text-right">
                            <div>
                                <Button onClick={showFilterLayout} className='btn-outline-blue btn-icon' icon={<FilterAltIcon />}>{t("common.filter")}</Button>
                                <Dropdown className='m-l-10' menu={menuExport} trigger={['click']}>
                                    <Button className='btn-outline-blue btn-icon' icon={<DownloadOutlined />}>
                                        {t("common.export")}
                                    </Button>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>
                </div>

                <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                    <Col xs={{ span: 24 }}>
                        <Table
                            components={components}
                            rowClassName={() => 'editable-row'}
                            {...GetTableConfigs(getColumnMapping(), dayLogData?.ObjectList, dayLogData?.TotalItems, "Id", onTableChange, gridConfigOptions.pageNumber)}
                        />
                    </Col>
                </Row>

                <Drawer
                    title={t("common.filter")}
                    width={378}
                    onClose={closeFilterLayout}
                    open={visibleFilter}
                    closeIcon={false}
                    styles={{
                        body: {
                            paddingBottom: 80,
                        }
                    }}
                    extra={
                        <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                            <Button type="link" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                            <Button onClick={closeFilterLayout} icon={<CloseIcon />} type="link" />
                        </Space>
                    }
                    footer={
                        <Space direction="horizontal" size="middle" style={{ display: 'flex', float: 'right' }}>
                            <Button onClick={closeFilterLayout}>{t("common.cancel")}</Button>
                            <Button onClick={applyFilter} type="primary">
                                {t("common.apply")}
                            </Button>
                        </Space>
                    }>
                    <Form
                        layout="vertical"
                        {...layout}
                        form={formFilter}
                        name="formFilter"
                        key='formFilter'
                        initialValues={{ StatusIds: [Constant.StatusCode.Active] }}
                    >
                        {
                            currentPosition && (currentPosition.PositionTypeId == Constant.PositionType.StateExcutive || currentPosition.PositionTypeId == Constant.PositionType.NationalExecutive) &&
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="ClubIds"
                                        label={t("fishing_activity.club")}
                                    >
                                        <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                            maxTagCount='responsive'
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={filterData && filterData.Entities ? filterData.Entities.map(b => {
                                                return {
                                                    label: b.Name,
                                                    value: b.Id
                                                }
                                            }) : []}>
                                        </EnhancedSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                        }

                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="DayLogId"
                                    label={t("fishing_activity.day_log_id")}
                                >
                                    <Input allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="ActivitySheetNumber"
                                    label={t("fishing_activity.activity_sheet_number")}
                                >
                                    <Input allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="CreatedBys"
                                    label={t("fishing_activity.created_by")}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={filterData && filterData.CreatedBys ? filterData.CreatedBys.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Name
                                            }
                                        }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="BoatIds"
                                    label={t("fishing_activity.boat_name")}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={filterData && filterData.Boats ? filterData.Boats.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}
                                    >
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="BoatCaptainIds"
                                    label={t("fishing_activity.captain")}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={filterData && filterData.BoatCaptains ? filterData.BoatCaptains.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="PersonOnBoard"
                                    label={t("fishing_activity.pob")}
                                >
                                    <InputNumber style={{ width: '100%' }} allowClear min={0} max={9999} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="HoursOnWater"
                                    label={t("fishing_activity.how")}
                                >
                                    <InputNumber style={{ width: '100%' }} allowClear min={0} max={9999} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="NoOfActivityEntries"
                                    label={t("fishing_activity.entries")}
                                >
                                    <InputNumber style={{ width: '100%' }} allowClear min={0} max={9999} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="ClubEventIds"
                                    label={t('fishing_activity.club_special_event')}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={filterData && filterData.ClubEvents ? filterData.ClubEvents.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="StateEventIds"
                                    label={t('fishing_activity.state_special_event')}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={filterData && filterData.StateEvents ? filterData.StateEvents.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="ActivityDateFrom"
                                    label={t('fishing_activity.date_from')}
                                >
                                    <DatePicker />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="ActivityDateTo"
                                    label={t('fishing_activity.date_to')}
                                >
                                    <DatePicker />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="FinancialYearIds"
                                    label={t('fishing_activity.financial_year')}
                                >
                                    <EnhancedSelect mode="multiple" showSearch optionFilterProp="label" allowClear={true}
                                        maxTagCount='responsive'
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={filterData && filterData.FinancialYears ? filterData.FinancialYears.map(b => {
                                            return {
                                                label: b.Name,
                                                value: b.Id
                                            }
                                        }) : []}>
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="StatusIds"
                                    label={t("common.status")}
                                >
                                    <EnhancedSelect mode="multiple" allowClear={true}
                                        showSearch optionFilterProp="children"
                                        filterOption={(input, option) => (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())}
                                    >
                                        {
                                            statusCodes && statusCodes.map((n, index) => <Option key={index} value={n.Value}>{n.Description}</Option>)
                                        }
                                    </EnhancedSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Drawer>
            </div>
        </>
    )
}

export default DayLogList;
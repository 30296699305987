import React, { useState, useEffect } from 'react';
import { Modal, Table, Row, Col, Button, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import LoadingContext from '../../../components/loading/loadingContext'
import { useGlobalState } from './../../../utilities/globalState'
import { GetTableConfigs, ActionButtonsCell, TextAndButtonCell, EditableCell } from '../../../components/customTable/customTable'
import { TrashIcon, CloseIcon, EditIcon } from '../../../components/icons/icons'
import MemberList from '../../positionTitle/memberList/memberList';
import { MembershipService, SettingService, CommonService, Constant } from '../../../services/services'
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

const AddUpdateFamilyGroup = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [familyGroups, setFamilyGroups] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedObject, setSelectedObject] = useState();
    const [allMembers, setAllMembers] = useState([]);
    const [currentMembers, setCurrentMembers] = useState([]);
    const [visibleMemberList, setVisibleMemberList] = useState(false)
    const [isDirty, setIsDirty] = useState(false);
    const [currentPosition] = useGlobalState('currentPosition');
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [statuses, setStatuses] = useState([]);

    useEffect(() => {
        if (props.visible) {
            if (!props.objectData || props.objectData.length == 0) {
                setFamilyGroups([getBlankLine()])
                setIsEdit(false)
            } else {
                let data = props.objectData.map(x => Object.assign({}, x))
                for (let i = 0; i < data.length; i++) {
                    let contactMembers = []
                    if (data[i].PrimaryMember && data[i].PrimaryMember.Id > 0) {
                        contactMembers.push(data[i].PrimaryMember)
                    }
                    if (data[i].OtherMembers && data[i].OtherMembers.length > 0) {
                        contactMembers.push(...data[i].OtherMembers)
                    }
                    for (let index = 0; index < contactMembers.length; index++) {
                        contactMembers[index].Index = index;
                    }
                    data[i].ContactMembers = contactMembers
                }

                setFamilyGroups(data)
                setIsEdit(true)
            }

            showLoading()
            Promise.all([
                SettingService.getMembersByClub(),
                SettingService.getStatusCodes()
            ])
                .finally(() => dismissLoading())
                .then(response => {
                    if (response[0] && response[0].data) {
                        setAllMembers(response[0].data)
                    }
                    if (response[1].data) {
                        setStatuses(response[1].data);
                    }
                })
                .catch(error => CommonService.handleErrorResponse(error))
        }
    }, [props.visible])

    const getBlankLine = () => {
        let index = 0;
        if (familyGroups && familyGroups.length > 0) {
            index = familyGroups.length
        }
        return {
            Id: index,
            Name: "",
            ContactMembers: [getBlankContactCustomer()],
            StatusId: Constant.StatusCode.Active
        };
    };

    const addBlankLine = () => {
        let lines = [
            ...familyGroups,
            getBlankLine()
        ]
        setFamilyGroups(lines)
    }

    const handleOnRecordChange = (value, record, dataIndex) => {
        if (record) {
            let temp = familyGroups.map(x => Object.assign({}, x));
            let index = familyGroups.indexOf(record)
            if (temp[index]) {
                temp[index][dataIndex] = value;

                setFamilyGroups(temp)
                checkDirty(temp)
            }
        }
    }

    const checkDirty = (data) => {
        if (data && data.length > 0) {
            if (!isEdit) {
                let dirty = false
                for (let i = 0; i < data.length; i++) {
                    if (!CommonService.shallowCompare(data[i], {})) {
                        dirty = true
                        break
                    }
                }
                setIsDirty(dirty)
            } else {
                let dirty = false
                for (let i = 0; i < data.length; i++) {
                    let original = props.objectData.find(o => o.Id == data[i].Id)
                    if (!original) {
                        dirty = true
                        break
                    } else {
                        if (!CommonService.shallowCompare(data[i], original)) {
                            dirty = true
                            break
                        }
                    }

                }

                if (dirty) {
                    setIsDirty(true)
                } else {
                    setIsDirty(false)
                }
            }
        }
    }

    const editMembers = (parentRecord, record) => {
        let object = {
            familyGroup: parentRecord,
            member: record,
            memberIndex: parentRecord.ContactMembers.indexOf(record)
        }
        setSelectedObject(object)
        setVisibleMemberList(true)
        setCurrentMembers(allMembers.filter(x => !x.FamilyGroupId || x.FamilyGroupId === parentRecord.Id))
    }

    const deleteFamilyGroup = (record) => {
        let selectedObjs = familyGroups.filter(m => m != record);
        setFamilyGroups(selectedObjs);
    }

    const deleteContactMember = (contactMember, familyGroup) => {
        let temp = familyGroups.map(x => Object.assign({}, x));
        let index = familyGroups.indexOf(familyGroup)
        if (temp[index]) {
            setIsDirty(true)
            let contactMembers = temp[index].ContactMembers.filter(c => c != contactMember)
            temp[index].ContactMembers = contactMembers;
            setFamilyGroups(temp);
        }
    }

    const addFamilyGroupColumns = [
        {
            title: t('common.action'),
            key: 'Id',
            render: (value, record) => {
                return <ActionButtonsCell data={record} btnList={[
                    {
                        Description: t("common.delete"),
                        Icon: <TrashIcon />,
                        ColorClass: Constant.ColorClass.LightRed,
                        Hide: false,
                        NoMargin: true,
                        handleAction: deleteFamilyGroup
                    }
                ]} />
            },
            align: 'center',
            width: 70,
        },
        {
            title: <span className='required'>{t('family_group.family_group_name')}</span>,
            dataIndex: 'Name',
            key: 'Name',
            editable: true,
            required: true,
            cellType: Constant.CellType.Input,
            validateAtStart: true,
            handleOnChange: handleOnRecordChange,
            width: 450
        },
        {
            title: <span className='required'>{t('common.status')}</span>,
            dataIndex: 'StatusId',
            key: 'StatusId',
            editable: true,
            extraData: statuses,
            required: true,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "Name",
            width: 100,
            disabled: !isEdit,
            allowClear: false,
            handleOnChange: handleOnRecordChange
        }
    ]

    const editFamilyGroupColumns = [
        {
            title: <span className='required'>{t('family_group.family_group_name')}</span>,
            dataIndex: 'Name',
            key: 'Name',
            editable: true,
            required: true,
            cellType: Constant.CellType.Input,
            validateAtStart: true,
            handleOnChange: handleOnRecordChange,
            width: 450
        },
        {
            title: <span className='required'>{t('common.status')}</span>,
            dataIndex: 'StatusId',
            key: 'StatusId',
            editable: true,
            extraData: statuses,
            required: true,
            cellType: Constant.CellType.EnhancedSelect,
            selectionKey: "Id",
            selectionDisplay: "Name",
            width: 100,
            disabled: !isEdit,
            allowClear: false,
            handleOnChange: handleOnRecordChange
        }
    ]

    const mappingColumns = isEdit ? editFamilyGroupColumns : addFamilyGroupColumns

    const familyGroupColumnsMapping = mappingColumns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                validateAtStart: col.validateAtStart,
                disabled: col.disabled,
                extraData: col.extraData,
                selectionKey: col.selectionKey,
                selectionDisplay: col.selectionDisplay,
                allowClear: col.allowClear
            }),
        };
    })

    const handleMemberListCancel = (objectData) => {
        setVisibleMemberList(false)
        if (objectData && objectData.familyGroup && objectData.member) {
            let temp = familyGroups.map(x => Object.assign({}, x));
            let index = familyGroups.indexOf(objectData.familyGroup)
            if (temp[index]) {
                setIsDirty(true)
                let tempContact = temp[index].ContactMembers.map(x => Object.assign({}, x));
                let existedMember = tempContact.filter(x => x.Id == objectData.member.Id)
                if (existedMember && existedMember.length > 0) {
                    CommonService.presentToast('error', t("family_group.contact_member_validation_message"))
                }
                else {
                    tempContact[objectData.memberIndex] = objectData.member
                    temp[index].ContactMembers = [...tempContact]
                    setFamilyGroups(temp);
                }

            }
        }
    }

    const getContactPersonIndex = (parentRecord, record) => {
        if (parentRecord.ContactMembers) {
            let index = parentRecord.ContactMembers.indexOf(record)
            return index
        }
        return -1
    }
    const getContactMembersTable = (parentRecord) => {
        let memberColumns = [
            {
                title: t('common.action'),
                key: 'Index',
                render: (value, record) => {
                    return <ActionButtonsCell data={record} btnList={[
                        {
                            Description: t("common.delete"),
                            Icon: <TrashIcon />,
                            ColorClass: Constant.ColorClass.LightRed,
                            Hide: false,
                            NoMargin: true,
                            Hide: getContactPersonIndex(parentRecord, record) == 0 ? true : false,
                            handleAction: () => deleteContactMember(record, parentRecord)
                        }
                    ]} />
                },
                align: 'center',
                width: 80,
                sorter: false
            },
            {
                dataIndex: 'IsPrimaryContact',
                key: 'IsPrimaryContact',
                width: 100,
                sorter: false,
                render: (value, record) => {
                    let index = getContactPersonIndex(parentRecord, record)
                    if (index == 0) {
                        return <span className='required'>{t('family_group.primary_contact')}</span>
                    }
                    else if (index > 0) {
                        return <span>{t('family_group.member_index', { index: index + 1 })}</span>
                    }
                    return ""

                }
            },
            {
                title: t('family_group.member'),
                key: 'Member',
                render: (value, record) => {
                    let text = ""
                    if (record) {
                        if (record.FirstName) {
                            text = record.FirstName
                        }
                        if (record.LastName) {
                            text += " " + record.LastName
                        }
                    }

                    return <TextAndButtonCell value={text}
                        data={record} icon={<EditIcon />} includeWhiteSpaces={true} onClick={() => editMembers(parentRecord, record)} colorClass={Constant.ColorClass.LightBlue} />
                },
                width: 150
            },
            {
                title: t('family_group.fishing_category_name'),
                dataIndex: 'FishingCategoryName',
                key: 'FishingCategoryName',
                width: 150,
                sorter: false
            },
            {
                title: t('family_group.fishing_membership_type'),
                dataIndex: 'FishingTypeName',
                key: 'FishingTypeName',
                width: 150,
                sorter: false
            },
            {
                title: t('member.email'),
                dataIndex: 'Email',
                key: 'Email',
                width: 150,
                sorter: false
            },
            {
                title: t('family_group.phone'),
                dataIndex: 'MobilePhone',
                key: 'MobilePhone',
                width: 120,
                sorter: false
            },
            {
                title: t('entity_detail.address'),
                dataIndex: 'AddressValue',
                key: 'AddressValue',
                width: 250,
                sorter: false
            }
        ]

        const editableMemberColumns = memberColumns.map((col) => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    required: col.required,
                    cellType: col.cellType,
                    handleOnChange: col.handleOnChange,
                    extraData: col.extraData,
                    errorTextPosition: col.errorTextPosition,
                    selectionKey: col.selectionKey,
                    selectionDisplay: col.selectionDisplay,
                    validateAtStart: col.validateAtStart
                }),
            };
        });

        return <Table components={components}
            rowClassName={() => 'editable-row'}
            //className='custom-member-table' 
            columns={editableMemberColumns}
            dataSource={parentRecord.ContactMembers}
            pagination={false}
            rowKey='Index' />;

    }

    const getBlankContactCustomer = (record) => {
        let index = 0;
        if (record && record.ContactMembers.length > 0) {
            index = record.ContactMembers.length
        }
        let obj = {
            Index: index,
            ContactType: true ? t("family_group.primary_contact") : t("family_group.member_index", { index: index + 1 })
        }
        return obj
    }

    const addBlankContactMember = (record) => {
        let temp = familyGroups.map(x => Object.assign({}, x));
        let index = familyGroups.indexOf(record)
        if (temp[index]) {
            setIsDirty(true)
            let contactMembers = [
                ...temp[index].ContactMembers,
                getBlankContactCustomer()
            ]
            temp[index].ContactMembers = contactMembers;
            setFamilyGroups(temp);
        }
    }

    const expandedRowRender = (record, index, indent, expanded) => {
        return <>
            <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                <Col flex={100} className="text-right">
                    <div>
                        <Button className='btn-solid-dark-blue m-l-10' onClick={() => addBlankContactMember(record)}>{t("family_group.add_contact_member")}</Button>
                    </div>
                </Col>
            </Row>
            {getContactMembersTable(record)}
        </>
    }

    const components = {
        body: {
            cell: EditableCell,
        }
    };

    const onSubmit = () => {
        if (canSubmit()) {
            if (isEdit) {
                showLoading()
                let data = populateAddUpdateData()
                MembershipService.updateFamilyGroups(data)
                    .finally(() => dismissLoading())
                    .then(result => {
                        CommonService.presentToast('success', t("family_group.update_group_successful"))
                        props.handleCancel(true)
                    })
                    .catch(error => CommonService.handleErrorResponse(error))
            } else {
                showLoading()
                let data = populateAddUpdateData()
                MembershipService.addFamilyGroups(data)
                    .finally(() => dismissLoading())
                    .then(result => {
                        CommonService.presentToast('success', t("family_group.create_group_successful"))
                            props.handleCancel(true)
                    })
                    .catch(error => CommonService.handleErrorResponse(error))
            }
        }
    }

    const populateAddUpdateData = () => {
        let data = familyGroups.map(x => Object.assign({}, x));
        for (let i = 0; i < data.length; i++) {
            if (data[i].ContactMembers && data[i].ContactMembers.length > 0) {
                data[i].PrimaryMember = data[i].ContactMembers[0]
                data[i].OtherMembers = data[i].ContactMembers.filter(m => m != data[i].PrimaryMember)
            }
        }

        return data
    }

    const getErrorMessage = () => {
        let inValid = familyGroups.filter(p => !p.Name)
        if (inValid && inValid.length > 0) {
            return t("family_group.family_group_name_required")
        }

        inValid = familyGroups.filter(p => !p.ContactMembers || p.ContactMembers.some(x => !x.Id))

        if (inValid && inValid.length > 0) {
            return t("family_group.primary_contact_required")
        }

        return null
    }

    const closeForm = () => {
        if (isDirty) {
            confirm({
                title: t("common.confirm"),
                icon: <ExclamationCircleOutlined />,
                content: t("common.submit_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    if (canSubmit()) {
                        onSubmit()
                    } else {
                        let message = getErrorMessage()
                        if (message) {
                            CommonService.presentToast('error', message)
                        }
                    }

                },
                onCancel() {
                    props.handleCancel()
                }

            });
        } else {
            props.handleCancel()
        }
    }

    const canSubmit = () => {
        if (familyGroups && familyGroups.length > 0) {
            let inValid = familyGroups.filter(p => !p.Name || !p.ContactMembers || p.ContactMembers.some(x => !x.Id))
            if (inValid && inValid.length > 0) {
                return false
            }
            return true
        }
        return false
    }


    return (
        <div>
            <Modal
                title={isEdit ? t("family_group.edit_family_groups") : t("family_group.insert_family_groups")}
                open={true}
                onCancel={closeForm}
                confirmLoading={false}
                maskClosable={false}
                centered
                width={1200}
                footer={[
                    <Button key={0} size="large" onClick={closeForm}>{t("common.cancel")}</Button>,
                    <Button key={1} size="large" type="primary" disabled={!canSubmit()} onClick={onSubmit}>{isEdit ? t("common.submit") : t("common.insert")}</Button>
                ]}
            >
                {!isEdit &&
                    <Row gutter={[, Constant.SpaceConstant.VerticalGutter]} className='p-t-10 p-b-10'>
                        <Col flex={100} className="text-right">
                            <div>
                                <Button className='btn-solid-dark-blue m-l-10' onClick={addBlankLine}>{t("common.add")}</Button>
                            </div>
                        </Col>
                    </Row>
                }
                <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                    <Col xs={{ span: 24 }}>
                        <Table
                            components={components}
                            rowClassName={() => 'editable-row'}
                            {...GetTableConfigs(familyGroupColumnsMapping, familyGroups, familyGroups.length, "Id", null)}
                            expandable={{
                                expandedRowRender: (record) => expandedRowRender(record),
                                rowExpandable: () => true
                            }}
                            pagination={false}
                            rowKey="Id"

                        />
                    </Col>
                </Row>
            </Modal>
            {
                visibleMemberList &&
                <MemberList handleCancel={handleMemberListCancel} viewOnly={false} allMembers={currentMembers} objectData={selectedObject} memberListType={Constant.MemberListType.FamilyGroup}></MemberList>
            }
        </div>
    )
}

export default AddUpdateFamilyGroup